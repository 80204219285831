import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { notification, Select, Checkbox, Switch } from "antd";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import commonstyles from "../../../styles/Management_Common.module.css";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import bannerImg from "../../../assets/images/banner1.svg";
import bannerImg2 from "../../../assets/images/banner2.svg";
import bannerImg3 from "../../../assets/images/banner3.svg";
import listingBanner from "../../../assets/images/listingBanner.svg";
import modalBanner from "../../../assets/images/modalBanner.svg";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = Select;

export default function ModularForm(props) {
    return (
        <>
            {props?.modulars?.length > 0 && props?.modulars?.map((modular, indx) => (
                <div key={indx} className={styles.management_details}>
                    <div className={styles.management_details_contents}>
                        <div className="d-flex justify-content-between">
                            <div className={`mb-4 ${styles.modularHeading}`}>
                                <label>
                                    Modular Product Listing
                                </label>
                            </div>
                            <div className={`mb-4 ${styles.modularSwitch}`}>
                                <label>
                                    Featured Product
                                </label>
                                {/* <div className={`${styles.onoffswitch} d-inline-block align-top ${styles.modularOnOffSwitch}`}> */}
                                    {/* <input
                                        type="checkbox"
                                        name="status"
                                        className={styles.onoffswitch_checkbox}
                                        onChange={(e) => props.handleFormInput(e, modular, indx)}
                                        checked={modular.status}
                                        id="statusSwitch"
                                    />
                                    <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
                                        <span className={styles.onoffswitch_inner}></span>
                                        <span className={styles.onoffswitch_switch}></span>
                                    </label> */}
                                    <Switch
                                        style={{ marginLeft: "6px", marginTop: "-2px"}}
                                        checked={modular?.status}
                                        onChange={(e) => props.toggleStatusOnChange(e, modular, indx)}
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Product Name{" "}
                                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span>
                                </label>

                                <InputComponent
                                    // error={props.mainLeftBannerErrors.name}
                                    className="form-control-lg mb-3"
                                    formControl={{
                                        placeholder: "Type product name",
                                        name: "name",
                                        value: modular.name,
                                        onChange: (e) => props.handleFormInput(e, modular, indx),
                                    }}
                                />
                                {/* <FormErrorText error={props.mainLeftBannerErrors.name} /> */}
                            </div>
                            <div className="col-lg-6 mb-4 banner_region_select">
                                <label className={commonstyles.secondry_title}>
                                    Warehouse{" "}
                                    {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span> */}
                                </label>
                                <Select
                                    mode="multiple"
                                    showSearch={false}
                                    dropdownMatchSelectWidth={false}
                                    filterOption={false}
                                    placeholder="Search regions"
                                    // loading={props.isFetchingTemp}
                                    bordered={false}
                                    name="regionId"
                                    value={modular.regionId}
                                    disabled={props.regions?.length === 1}
                                    onChange={(value) => props.searchSelectOnChange(value, 'regionId', modular, indx)}
                                    className={`${commonstyles.form_control} height-auto form-control form-control-lg p-1`}
                                >
                                    <option value="All">All Region</option>
                                    {Array.isArray(props.remainingRegions)
                                        ? props.remainingRegions.map((region) => (
                                            <option key={region.name} value={region.name}>
                                                {region.name}
                                            </option>
                                        ))
                                        : null}
                                </Select>
                                {/* <FormErrorText error={props.mainLeftBannerErrors.regionId} /> */}
                            </div>
                            {/* <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Starting Price (Selling Price){" "}
                                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span>
                                </label>
                                <InputComponent
                                    // error={props.mainLeftBannerErrors.name}
                                    className="form-control-lg mb-3"
                                    formControl={{
                                        placeholder: "Type your price",
                                        name: "sellingPrice",
                                        value: modular.sellingPrice,
                                        onChange: (e) => props.handleFormInput(e, modular, indx),
                                    }}
                                />
                            </div> */}
                            {/* <div className="col-lg-6 mb-4 banner_region_select">
                                <label className={commonstyles.secondry_title}>
                                    MRP Price{" "}
                                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span>
                                </label>
                                <InputComponent
                                    className="form-control-lg mb-3"
                                    formControl={{
                                        placeholder: "Type your price",
                                        name: "mrpPrice",
                                        value: modular.mrpPrice,
                                        onChange: (e) => props.handleFormInput(e, modular, indx),
                                    }}
                                />
                            </div> */}
                            <div className="col-lg-12 mb-4">
                                <div className={`${styles.management_details_contents} ${styles.schema_markup}`}>
                                    <label>Schema markup</label>
                                    <textarea
                                    className="form-control theme-form-control form-control-lg"
                                    name="seoMarkup"
                                    rows="3"
                                    value={modular.seoMarkup}
                                    onChange={(e) => props.handleFormInput(e, modular, indx)}
                                    placeholder="Type schema markup"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Specification{" "}
                                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span>
                                </label>
                                <CKEditor
                                    // error={formErrors.headerContent}
                                    editor={ClassicEditor}
                                    name="specification"
                                    data={modular.specification || ""}
                                    onChange={(event, editor) => props.ckEditorOnchange(event, editor, "specification", indx)}
                                    // config={{ extraPlugins: [uploadPlugin] }}
                                />
                            </div>
                            <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Product details{" "}
                                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                        *
                                    </span>
                                </label>
                                <CKEditor
                                    // error={formErrors.headerContent}
                                    editor={ClassicEditor}
                                    name="productDetails"
                                    data={modular.productDetails || ""}
                                    onChange={(event, editor) => props.ckEditorOnchange(event, editor, "productDetails", indx)}
                                    // config={{ extraPlugins: [uploadPlugin] }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="d-flex align-items-right justify-content-between">
                                    <div className={commonstyles.abandoned_email}>
                                        <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                            <Checkbox name="isActive" onChange={(e) => props.handleCheckboxChange(e, modular, indx)}>Image compressor</Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="d-flex align-items-right justify-content-between">
                                    <label className={commonstyles.secondry_title}>
                                        Add Image{" "}
                                        {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                            *
                                        </span> */}
                                    </label>
                                    {/* <div className={styles.banner_add_image}>
                                        <p> Add Image</p>
                                    </div> */}
                                </div>
                                <div className={`${commonstyles.banner_img_box_outline}`}>
                                    <div className={`${commonstyles.management_details_contents} mb-2 mt-2`}>
                                        <div
                                            className={`${commonstyles.management_details_form} ${commonstyles.add_image_card
                                                } d-flex align-items-center justify-content-center`}
                                        >
                                            <input
                                                name="image"
                                                // className="d-none"
                                                type="file"
                                                accept="image/*"
                                                id="imageInput"
                                                value=""
                                                onChange={(e) => props.handleImageInput(e, modular, indx)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Preview order
                                </label>
                                <div className={`${commonstyles.banner_img_box_outline} `}>
                                    {modular?.images?.length > 0 && modular?.images?.map((imgs, index) => (
                                        <div className={`${commonstyles.banner_img_preview}`}>
                                            <img
                                                class={commonstyles.previewImgs}
                                                key={index}
                                                src={imgs?.name ? URL.createObjectURL(imgs) : imgs}
                                                alt={imgs?.name}
                                            />
                                            <img onClick={() => props.removeImages(imgs, index, modular, indx)} class={commonstyles.previewImgsdeleteicon} src={bannerimgdelete} alt="delete" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={styles.management_details_contents}>
            <h4 className="theme-form-section-title">
              Search Engine Optimization
            </h4>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <div className={styles.management_details_form}>
                  <label for="">Meta Title</label>
                  <InputComponent
                    className={`form-control form-control-lg ${styles.form_control}`}
                    // error={formErrors.metaTitle}
                    formControl={{
                      placeholder: "",
                      name: "metaTitle",
                      value: modular.metaTitle,
                      onChange: (e) => props.handleFormInput(e, modular, indx),
                    }}
                  />
                  {/* <FormErrorText error={formErrors.metaTitle} /> */}
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div className={styles.management_details_form}>
                  <label for="">Meta Keywords</label>
                  <InputComponent
                    className={`form-control form-control-lg ${styles.form_control}`}
                    // error={formErrors.metaKeywords}
                    formControl={{
                      placeholder: "",
                      name: "metaKeywords",
                      value: modular.metaKeywords,
                      onChange: (e) => props.handleFormInput(e, modular, indx),
                    }}
                  />
                  {/* <FormErrorText error={formErrors.metaKeywords} /> */}
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className={styles.management_details_form}>
                  <label for="">Meta Description</label>
                  <textarea
                    className={`form-control form-control-lg ${styles.form_control}`}
                    // error={formErrors.metaDescription}
                    // formControl={{
                    rows="10"
                    placeholder=""
                    name="metaDescription"
                    value={modular.metaDescription}
                    onChange={(e) => props.handleFormInput(e, modular, indx)}
                    // }}
                  />
                  {/* <FormErrorText error={formErrors.metaDescription} /> */}
                </div>
              </div>
              

            </div>
          </div>
                        <div className="d-flex align-items-right justify-content-end">
                            {!props.regionId && indx > 0 &&<div
                                className={styles.banner_delete_btn}
                                onClick={() => props.removeModular(indx)}
                            >
                                <p>Remove</p>
                            </div>}
                            {!props.regionId && <div
                                className={styles.title_text_value}
                                onClick={() => props.addModular()}
                            >
                                <img src={Plus} alt="" />
                                <span className="theme-btn-link">Add Modular Furniture</span>
                            </div>}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}