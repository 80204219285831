// import styles from "../../styles/ProductManagement.module.css";
import { useState, useRef, useEffect } from "react";
import { notification, Tooltip } from "antd";
import { Link } from "react-router-dom";
import AddFAQComponent from "./AddFAQComponent";
//import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
// import EditProductComponent from "./EditProductComponent";
// import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import plusIcon from "../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
import SearchComponent from "../SearchManagement/SearchComponent";
import { filter } from "lodash";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

export default function FAQManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const [parentCategories, setParentCategories] = useState([]);
  const [faqList, setFaqList] = useState([]);
  /* const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState); */
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });
  const [isDeleteModule, setIsDeleteModule] = useState(false);
  const [seletedWareHuse, setSeletedWareHuse] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [selectedProduct, setSelectedProduct] = useState({});

  const timeoutId = useRef();

  const history = useHistory();

  // const { state } = history.location;

  useEffect(() => {
    // setPage(state ? state : 6);
    // setTimeout(() => {
    // setIsLoading(true);
    getFAQList(pageSize, nextPage);
    return () => {
      history["isFAQ"] = false;
    };
    // }, 500);
  }, [nextPage, pageSize]);
  /**
   * @author Rajkumar
   * @param {pageSize, nextPage, search}
   * TO GET FAQ LIST
   */
  function getFAQList(pageSize, nextPage, search) {
    // setIsLoading(false);
    setIsLoading({ delete: false, list: true });
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search,
    };

    if (!filterOptions.search) {
      filterOptions.search = search;
    }

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/faq/getFaq", filterOptions)
      .then((res) => {
        //   console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading(false);
          setIsLoader(false);
          setIsLoading({ delete: false, list: false });
          setFaqList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

  function handleSeach(e) {
    const { value } = e.target;
    setSearch(value);

    console.log(value);
    if (!value) {
      getFAQList(pageSize, nextPage, value);
    }
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      setFaqList(value, 1);
    } else if (type === "nextPage") {
      setNextPage(value);
      setFaqList(pageSize, value);
    }
  }

  /* useState(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
          }
        })
        .catch((er) => {
          const error = {
            type: "error",
            message: "Error",
            description: "Failed to get data",
          };
          showNotification(error);
        });
    }

    function showNotification({ type, message, description }) {
      let options = {
        message: message || "Message",
        description: description,
        duration: 5,
      };
      notification[type](options);
    }

    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
    getParentCategories();
    getRegionList();
    // getProducts(filter);
  }, []); */

  /* function getProducts(filterParam) {
    setIsLoading({ ...IsLoading, list: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .post("/admin/catalog/productList/", filterParam)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // console.log(res.data.data.products);
          // const reslt = res.data.data[0];
          setProducts(res.data.data.products);
          setPagination(res.data.pagination);
          // setPagination({ ...pagination, total: reslt.totalProducts });
        }
      })
      .catch((er) => {
        console.log("getProducts error");
        console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get product data",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, list: false });
      });
  } */
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /* function editProduct(product) {
    console.log(product)
    setFilter(intialFilterState);
    const selectedItem = JSON.parse(JSON.stringify(product));
    setSelectedProduct(selectedItem);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  } */

  /* function closeEditProduct(isEdited) {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
    if (isEdited) {
      getProducts(filter);
    }
  }
 */
  /* function showdelete(product) {
    setSelectedProduct(product);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  } */

  /* function cancelDelete() {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }
 */
  /* function removeProduct() {
    setIsLoading({ ...IsLoading, delete: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteProduct/${selectedProduct._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setFilter(intialFilterState);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getProducts(filter);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete product",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete product",
        });
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, delete: false });
        setChildComponentVisibility({
          ...childComponentVisibility,
          delete: false,
        });
      });
  } */

  if (childComponentVisibility.addProduct) {
    return (
      <AddFAQComponent
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <></>
      /*  <EditProductComponent
        product={selectedProduct}
        handleClose={closeEditProduct}
      /> */
    );
  } /* else if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
      product="product"
        handleClose={() =>
          {setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          })
          getProducts()}
        }
      />
    );
  } */
  function addFAQ() {
    history.push(`/Content/FAQ/addFAQ`);
  }

  function editFAQ(faq) {
    //console.log(faq);
    history.push({
      pathname: `/Content/FAQ/editFAQ`,
      state: faq,
    });
  }
  /**
   * @author Rajkumar
   * @param {faq}
   * DELETE FAQ FUNCTION
   */
  function deleteFAQ(faq) {
    setSeletedWareHuse(faq._id);
    setIsDeleteModule(true);
  }

  function searchClick() {
    getFAQList(pageSize, nextPage, search);
  }


  function cancelDelete() {
    setSeletedWareHuse("");
    setIsDeleteModule(false);
  }

  function confirmDelete() {
    if (seletedWareHuse) {
      AxiosInstance(PORTS.DASHBOARD_SERVICE, "multipart/form-data")
        .get(`/faq/faqRemove/${seletedWareHuse}`)
        .then((res) => {
          if (res.data.status === 200) {
            cancelDelete();
            showNotification({
              type: "success",
              message: "Success",
              description: "FAQ deleted successfully",
            });
            getFAQList(pageSize, nextPage);
          } else if (res.data.status === 400) {
            showNotification({
              type: "error",
              message: "Deleting FAQ failed",
              description: res.data?.msg || "Error occured",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Deleting FAQ failed",
            description: "Error occured",
          });
        });
    }
  }

  return (
    <div className={`${commonstyles.management_contents} pl-0 pr-0`}>
      {/* <SearchComponent /> */}
      {/* <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          FAQ Management
        </h1>
      </div> */}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "FAQ-FAQ-view") === "FAQ-FAQ-view" && (
          <div className={commonstyles.management_contents_search}>
            <div
              className={`${commonstyles.management_contents_search_bar} max-100 input-group`}
            >
              <input
                type="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search by Category name...."
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => handleSeach(e)}
              />
              <img src={Search} alt="Search icon" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            {permissionsArr.length > 0 &&
              permissionsArr.find((x) => x === "FAQ-FAQ-add") ===
              "FAQ-FAQ-add" && (
                <div className={`${commonstyles.management_contents_upload}`}>
                  {/* <ButtonComponent
            className="theme-btn-outlined rnd px-3 mr-3"
          >
            Export
          </ButtonComponent> */}
                  {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: () => {
                setFilter(intialFilterState);
                setChildComponentVisibility({
                  ...childComponentVisibility,
                  bulkUpload: true,
                });
                window.scrollTo(0, 0);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Bulk Upload
          </ButtonComponent> */}

                  <span
                    className="white_space"
                    style={{ cursor: "pointer" }}
                    onClick={addFAQ}
                  >
                    <img src={Plus} alt="" />
                    Add FAQ
                  </span>
                </div>
              )}
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "FAQ-FAQ-view") === "FAQ-FAQ-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            <table className="table table-borderless">
              <thead>
                <tr>
                  {/* <th scope="col"></th> 
              <th scope="col">ID</th>*/}
                  <th scope="col">Icon</th>
                  {/* <th scope="col">Question</th> */}
                  <th scope="col" className="table-width">
                    Category name
                  </th>
                  <th scope="col">Operate</th>
                  {/*  <th></th> */}
                </tr>
              </thead>
              <tbody>
                {IsLoading.list === true ? (
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching FAQ details" />
                    </td>
                  </tr>
                ) : Array.isArray(faqList) && faqList.length > 0 ? (
                  faqList.map((faq, indx, arr) => (
                    <tr key={faq._id}>
                      <td>
                        {faq.icon ? (
                          <img
                            src={faq.icon}
                            className={commonstyles.admin_table_img}
                            alt="FAQ image"
                          />
                        ) : (
                          "-"
                        )}
                      </td>

                      {/*<td>{faq.faqs.forEach(element => {
                        <h3>{console.log(element.question)}, </h3>
                    })}</td>
                   <td>{faq.question?faq.question:'NA'} </td> */}
                      <td
                        style={{
                          textTransform: "capitalize",
                          whiteSpace: "unset",
                          wordBreak: "break-all",
                        }}
                      >
                        {faq.categoryName ? faq.categoryName : "NA"}{" "}
                      </td>

                      {/*  <td></td> */}
                      {permissionsArr.length > 0 &&
                        permissionsArr.find((x) => x === "FAQ-FAQ-edit") ===
                        "FAQ-FAQ-edit" && (
                          <td>
                            <Tooltip
                              placement="topRight"
                              title="Edit FAQ"
                            // className="tooltip_list"
                            >
                              {/* <Link to={`/catalog/products/edit/${product.slug}`}> */}
                              <img
                                src={adminProductEdit}
                                className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                                onClick={() => editFAQ(faq)}
                                alt="Edit action icon"
                                action="Edit FAQ"
                              // title="Edit FAQ"
                              />
                            </Tooltip>
                            {/* </Link> */}
                            <Tooltip
                              placement="topRight"
                              title="Delete FAQ"
                            // className="tooltip_list"
                            >
                              <img
                                src={deleteIcon}
                                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                                onClick={() => deleteFAQ(faq)}
                                alt="Delete product action icon"
                              // title="Delete FAQ"
                              />
                            </Tooltip>
                          </td>
                        )}
                      {/* Edit button ends*/}
                    </tr>
                  ))
                ) : (
                  <tr className="bg-transparent">
                    <td style={{ padding: "0px", textAlign: "left" }}>
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      {!IsLoading.list && Array.isArray(faqList) && faqList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "FAQ-FAQ-view") ===
        "FAQ-FAQ-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
      {/* Product delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: isDeleteModule,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
      {/* Product delete confirmation modal ends*/}
    </div>
  );
}
