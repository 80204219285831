import React, { useState } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import styles from "../../../styles/ZoneList.module.css";
// import ButtonComponent from './ButtonComponent';
import excelIcon from "../../../assets/images/excel_icon.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import { upperCase } from "lodash";

export const ExportCSV = ({csvData,reg,pincode, fileName}) => {
    // console.log({csvData,reg,pincode,fileName});
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.csv';
    const fileExtension = '.xlsx';

    let key,pin, ele, sheetsObject = {}, arr=[],uniqueNames=[], wsv;

    pincode.forEach((element,indx) => {
        pin = Object.keys(element);
        // console.log(pin);
        key = pin[0];
        // console.log(key);
        arr.push(key);
        // console.log(arr);
        uniqueNames = arr.filter((val,id,array) => array.indexOf(val) == id);
        // console.log(uniqueNames);
        // console.log(element[key]);
        wsv = XLSX.utils.json_to_sheet(element[key]);
        // console.log(wsv);
        // ele=element[key]
        // console.log(ele);

        sheetsObject[key] = {...wsv}
        // console.log([sheetsObject]);
    });

    const exportToCSV = (csvData,reg,pincode, fileName) => {
       
        const ws = XLSX.utils.json_to_sheet(csvData);
        // const ws2 = XLSX.utils.json_to_sheet([ele]);
        // sheetsObject[key] = ws2
        // console.log(ws);
        // console.log(ws2);
        //   ws["A1"].v = upperCase(ws["A1"].v);
        //   ws["B1"].v = upperCase(ws["B1"].v);
        //   ws["C1"].v = upperCase(ws["C1"].v);
        //   ws["D1"].v = upperCase(ws["D1"].v);
        //   ws["E1"].v = upperCase(ws["E1"].v);

        const wb = { Sheets: { 'Shipping': ws, /* pin:wsv */ ...sheetsObject }, SheetNames: ['Shipping',...uniqueNames /* ...pin, ...SheetsObject */] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);

    }

    return (
        // <ButtonComponent
        //     className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
        //     attributes={{
        //         onClick:(e) => exportToCSV(csvData,reg,pincode,fileName),
        //     }}
        //   >
        //    <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
        //     Export
        // </ButtonComponent>
        <img src={excelIcon} className={styles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportToCSV(csvData,reg,pincode,fileName) }/>
    )
}

