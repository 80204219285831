import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import AddDiscount from "../DiscountManagement/AddDiscount";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../Order Management/OrderDetails";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import { Checkbox } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  message as massages,
  Select,
  notification,
  Empty,
  Switch,
} from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import excel from "../../../assets/images/excel.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeView from "../../../assets/images/view_icon.PNG";

import { upperCase } from "lodash";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Name", value: "name" },
  { _id: 3, status: 1, name: "Email", value: "email" },
  { _id: 6, status: 1, name: "Region", value: "region" },
  { _id: 4, status: 1, name: "Enquiry", value: "enquiry" },
  { _id: 1, status: 1, name: "Date", value: "Date" },
  { _id: 5, status: 1, name: "Action", value: "Action" },
];

const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  // { _id: 8, name: "This Week", value: 2 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
/* const bulkAction = [
    { _id: 1, isChecked: true, name: 'Enabled', value: true },
    { _id: 2, isChecked: true, name: "Disabled", value: false },
    
  ]; */

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

let globalType = 0;

export default function ModularManagementComponent() {
  const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const history = useHistory();
  const pdfExportComponent = useRef(null);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [categoryId, setCategoryId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filteredResult, setFilteredResult] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [Type, setType] = useState(0);
  const [toggle, setToggle] = useState("Enquiry");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [startQuantity, setStartQuantity] = useState("");
  const [endQuantity, setEndQuantity] = useState("");
  // const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);

  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [columns, setColumns] = useState([
    /* {
        visible: false,
        key: "select",
        render: (text, record, index) => (
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            checked={record.isChecked}
            onChange={(e) => childCheckbox(e, record)}
          ></input>
        ),
      }, */
    {
      title: "Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.name}</td>
      ),
    },
    {
      title: "Email",
      visible: false,
      key: "email",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.email}</td>
      ),
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.regionName}</td>
      ),
    },
    {
      title: "Enquiry",
      visible: false,
      key: "enquiry",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record?.query !== undefined ? record.query : "-"}</td>
      ),
    },
    {
      title: "Date",
      visible: false,
      key: "Date",
      render: (text, record, index) => (
        <span>{moment(record.updatedAt).format("MMM Do YYYY, h:mm A")}</span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find(
          (x) => x === "SiteSettings-ModularEnquiries-edit"
        ) === "SiteSettings-ModularEnquiries-edit" && (

          <td>
            <Tooltip
              placement="topRight"
              title="View enquiry"
            >
              <Link to={{ pathname: `/site-settings/modular/${record._id}`, state: globalType }}>
                <img
                  src={eyeView}
                  className={`${commonstyles.admin_table_edit_img} mr-2`}
                  // onClick={() => editRegion(record)}
                  alt="View enquiry details"
                  // title="View enquiry details"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </Tooltip>

          </td>
        ),
    },
  ]);
  const [selectedOrder, setSelectedOrder] = useState({});
  function showDeleteConfirmation(order) {
    setSelectedOrder(order);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  }

  // const [selectedOrder, setSelectedOrder] = useState([]);
  /* const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
  }; */

  useEffect(() => {
    if (history?.isBulk) {
      globalType = 1;
      setType(1);
      setToggle("Bulk Enquiry");
    } else {
      globalType = 0;
      setType(0);
      setToggle("Enquiry");
    }
    set_new_header(headers);
    getWareHouseList(pageSize, nextPage, "");
    getRegionList();
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    };
  }, []);

  /* const toggleStatusOnChange = (e, record) => {
        if (localStorage.getItem("orderList")) {
          let orderListListClone = JSON.parse(
            localStorage.getItem("orderList")
          );
          orderListListClone.forEach((item) => {
            if (record._id === item._id) {
              item.enable = !item.enable;
              sentStatus(item);
            }
          });
          localStorage.setItem("orderList", JSON.stringify(orderListListClone));
          setOrderList(orderListListClone);
        }
      };
    
      const sentStatus = (showRoom) => {
        AxiosInstance(PORTS.INVOICE, "application/json")
          .get(`/admin/region/updatestatusRegion/${showRoom._id}/${showRoom.enable}`)
          .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: res.data.msg,
              });
            }
          })
          .catch((er) => {
            console.log(er);
          });
      }; */

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };


  /**
  * @author Mithun Prabhu
  * @param {pageSize, nextPage, filterarr}
  * To get enquiry details
  */
  function getWareHouseList(pageSize, nextPage, filterarr) {
    setIsLoading(true);
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }

    setFilterOptionsGlobal(filterOptions);

    let bulkEnquiryAPI = `/admin/bulkEnquiryList`;
    let enquiryAPI = `/admin/enquiryList`;

    AxiosInstance(PORTS.INVOICE, "application/json")
      .post(globalType == 1 ? bulkEnquiryAPI : enquiryAPI, filterOptions)
      .then((res) => {
        // console.log(res.data);
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res?.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              // console.log(item);
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("orderList", JSON.stringify(res.data.data));
            setOrderList(res.data.data);
          } else {
            setOrderList([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getWareHouseList(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getWareHouseList(pageSize, value, filter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** EDIT REGION FUNCTION *** //
  /*  function editRegion(customer) {
    history.push({
      pathname: "/site-settings/modular/add",
      state: customer,
    });
  } */

  // *** DELETE REGION FUNCTION *** //
  /* function deleteRegion(region) {
    const sureDelete = window.confirm("Are you sure want to delete???");
    if (sureDelete === true) {
      AxiosInstance(PORTS.INVOICE, "application/json")
        .get(`/admin/region/delete/${region._id}`)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Region details deleted successfully",
            });
            getWareHouseList(pageSize, nextPage, "");
          } else {
            showNotification({
              type: "error",
              message: "Error",
              description: "Failed to delete region",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
  } */

  function handleInput(e) {
    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getWareHouseList(pageSize, 1, filter);
    setFilteredResult(filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      });
  }

  /*  function getCategoryList() {
        AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
            .get("/admin/catalog/getCategoryName")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    setCategories(res.data.daaata);
                }
            })
            .catch((er) => {
                console.log(er);
            });
    } */
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
    };
    debounceSearch(updatedFilter);
  };

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };


  /**
 * @author Mithun Prabhu
 * @param {oldIndex, newIndex}
 * This is for rearranging array of columns items according to checking nd unchecking
 */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };


  /**
 * @author Mithun Prabhu
 * @param {arr, oldIndex, newIndex}
 * This is for rearranging array of columns items according to checking nd unchecking
 */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };


  /**
 * @author Mithun Prabhu
 * @param {items}
 * This is for fetching new items after checking and unchecking columns
 */
  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };


  /**
 * @author Mithun Prabhu
 * @param {e, id}
 * This is component for checking and unchecking columns in list page
 */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    // console.log("items", itemClone);
    setItems(itemClone);
    fetchNewOrder(items);
  };


  /**
 * @author Mithun Prabhu
 * @param {Menu}
 * This is component for removing/adding columns with onClick event
 */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );
  // *** BUILD STATUS CLICK HANDLER *** //
  /* const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };
  
  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedOrder")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (selectedOrder.length > 0) {
        let data = {
          orderIds: selectedOrder,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedOrder");
          const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
          setOrderList(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }; */


  /**
  * @author Mithun Prabhu
  * @param {e, data}
  * This is common filter handler for all filters
  */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        /*  if (item.value === "CategoryId") {
                    updatedFilter.CategoryId = "";
                    setCategoryId("");
                } */
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        /* if (item.value === "DeliveryStatus") {
                    updatedFilter.orderStatus = "";
                    setOrderStatus("");
                } */
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /* Export Warehouse here */
  const exportToCSV = async (e) => {
    // const filterOptions = {};

    // /* Search export filter */
    // if (filteredResult && filteredResult.search) {
    //   filterOptions.search = filteredResult.search;
    // } else if (search !== "") {
    //   if (filteredResult && filteredResult.search !== "") {
    //     filterOptions.search = search;
    //   }
    // }
    // /* Region export filter */
    // if (filteredResult && filteredResult.regionId) {
    //   filterOptions.regionId = filteredResult.regionId;
    // } else if (regionId !== "") {
    //   if (filteredResult && filteredResult.regionId !== "") {
    //     filterOptions.regionId = regionId;
    //   }
    // }
    // if (filteredResult && filteredResult.filterDays) {
    //   filterOptions.filterDays = Number(filteredResult.filterDays);
    // } else if (filteredDate.filterDays !== "") {
    //   if (filteredResult && filteredResult.filterDays !== "") {
    //     filterOptions.filterDays = Number(filteredDate.filterDays);
    //   }
    // }
    // /* Start date export filter */
    // if (filteredResult && filteredResult.startDate) {
    //   filterOptions.startDate = filteredResult.startDate;
    // } else if (filteredDate.startDate !== "") {
    //   if (filteredResult && filteredResult.startDate !== "") {
    //     filterOptions.startDate = filteredDate.startDate;
    //   }
    // }
    // /* End date export filter */
    // if (filteredResult && filteredResult.endDate) {
    //   filterOptions.endDate = filteredResult.endDate;
    // } else if (filteredDate.endDate !== "") {
    //   if (filteredResult && filteredResult.endDate !== "") {
    //     filterOptions.endDate = filteredDate.endDate;
    //   }
    // }
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;

    let enquiry = '/admin/exportEnquiryList';
    let bulkEnquiry = "/admin/exportBulkEnquiryList";
    AxiosInstance(PORTS.INVOICE, "application/json")
      .post(globalType == 1 ? bulkEnquiry : enquiry, params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          // console.log(ws);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          // ws["F1"].v = upperCase(ws["F1"].v);
          // ws["G1"].v = upperCase(ws["G1"].v);
          /*  ws["H1"].v = upperCase(ws["H1"].v);
            ws["I1"].v = upperCase(ws["I1"].v);
            ws["J1"].v = upperCase(ws["J1"].v);
            ws["K1"].v = upperCase(ws["K1"].v);
            ws["L1"].v = upperCase(ws["K1"].v); */
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");

          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Enquiry-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  };

  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,

      filterDays: filterDays,

    };
    debounceSearch(updatedFilter);
  }

  const toggleClickHandler = (couponType) => {
    let filter = {};
    setToggle(couponType);
    if (couponType == "Enquiry") {
      globalType = 0;
      setType(0);
      setPageSize(10);
      history["isBulk"] = false;
    } else if (couponType == "Bulk Enquiry") {
      globalType = 1;
      setType(1);
      setPageSize(10);
    }
    setSearch("");
    filter.search = "";
    getWareHouseList(pageSize, nextPage, filter);
  };

  const changeSelectedType = () => {
    history["isBulk"] = false;
    history.push('/site-settings');
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={changeSelectedType}
          />
          Enquiry
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find(
            (x) => x === "SiteSettings-ModularEnquiries-view"
          ) === "SiteSettings-ModularEnquiries-view" && (
            <div>
              <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img
                  className={commonstyles.export_icon}
                  src={pdf_icon}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => exportToPDF(e)}
                />
              </Tooltip>
              <span
                className={commonstyles.management_header_downloads_text}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              >
                <Tooltip
                  placement="topRight"
                  title="Download excel"
                >
                  <img className={commonstyles.export_icon} src={excel} />
                </Tooltip>
                Export
                <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
        {/* <div class={commonstyles.management_contents_upload}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/site-settings/region/add")}
                > <img src={Plus} alt="" /> 
                  Add New Region
                </span>
              </div> */}
      </div>
      <div
        className={`${commonstyles.management_header_addtax} ${commonstyles.management_header}`}
      >
        <button
          className={`${commonstyles.taxButton} 
         ${toggle == "Enquiry" && commonstyles.taxButton_active} 
           d-inline-block`}
          onClick={() => toggleClickHandler("Enquiry")}
        >
          {/* <img src={Plus} alt="" /> */}
          Modular Enquiry
        </button>

        <div className={commonstyles.management_contents_upload}>
          <button
            className={`${commonstyles.taxButton} 
                     ${toggle == "Bulk Enquiry" &&
              commonstyles.taxButton_active
              } 
                       d-inline-block`}
            onClick={() => toggleClickHandler("Bulk Enquiry")}
          >
            {/* <img src={Plus} alt="" /> */}
            Bulk Enquiry
          </button>
        </div>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find(
          (x) => x === "SiteSettings-ModularEnquiries-view"
        ) === "SiteSettings-ModularEnquiries-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >

            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >

              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Name,Email...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: handleInput,
                  ["aria-label"]: "Search category",
                  ["aria-describedby"]: "search-category",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            {/*<div className="action ml-3">
                        <div className="action_header">
                            Action
                            <img src={arrowDown} alt="image" />
                        </div>
                        <div className="action_contents">
                           {bulkActionFilter.length > 0 &&
                                bulkActionFilter.map((item) => (  
                            <div
                                className="action_contents_header"
                                key={item._id}
                                // onClick={() => bulkStatusClickHandler(item)}
                            >
                            <h2 className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"}`}>
                                {item.name}
                            </h2>
                            {item.isChecked && (        
                             <span
                                className="action_contents_btn"                
                                // onClick={() => applyBulkActionHandler(item)}                
                              >               
                              Apply
                            </span>
                            )}
                            </div>
                        ))}
                        </div>
                    </div> */}
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}

      {permissionsArr.length > 0 &&
        permissionsArr.find(
          (x) => x === "SiteSettings-ModularEnquiries-view"
        ) === "SiteSettings-ModularEnquiries-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Enquiry Report for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {/* {!isLoading && orderList.length > 0 && (
                    <input
                        type="checkbox"
                        className={`${commonstyles.parentCheckbox}`}
                    ></input>
                )} */}
              {isLoading === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching Modular Enquiry details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                <AntTableComponent
                  className="order_table"
                  tableProps={{
                    columns: columns,
                    dataSource: orderList,
                    pagination: false,
                    // onChange: handleTableChange,
                    components: {
                      header: {
                        row: (props) => renderHeader(props),
                      },
                      body: {
                        row: (props) => renderBody(props),
                      },
                    },
                  }}
                />
              )}
            </div>
          </PDFExport>
        )}
      {!isLoading && Array.isArray(orderList) && orderList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find(
          (x) => x === "SiteSettings-ModularEnquiries-view"
        ) === "SiteSettings-ModularEnquiries-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
    </div>
  );
}
