

import styles from "../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useState, useRef } from "react";
import dots from "../../../assets/images/column_dots.svg";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Plus from "../../../assets/images/plus_white_icon.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Switch,
  Menu,
  message as massages,
  Select,
  Tooltip,
} from "antd";
import moment from "moment";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import arrowDown from "../../../assets/images/arrow_down.svg";
import Pagination from "../../modules/ReusableComponent/Pagination";
import EditCustomerComponent from "../CustomerManagement/EditCustomerComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import DefaultImage from "../../../assets/images/profile_dummy.svg";
import { Link, useParams } from "react-router-dom";

import { arrayMove } from "react-movable";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Title", value: "Title" },
  { _id: 3, status: 1, name: "Slug", value: "Slug" },
  { _id: 4, status: 1, name: "Status", value: "Status" },
  { _id: 5, status: 1, name: "Action", value: "action" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "Region" },
  { id: 2, isChecked: false, name: "Date", value: "Date" },
];
const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: 1 },
  { _id: 2, isChecked: false, name: "Disable", value: 2 },
];
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
const ratingObj = [
  { _id: 1, name: 1, value: 1 },
  { _id: 2, name: 2, value: 2 },
  { _id: 3, name: 3, value: 3 },
  { _id: 4, name: 4, value: 4 },
  { _id: 5, name: 5, value: 5 },
];
let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function StoreWiseList() {
  const [startDates, setStartDates]=useState("")
  const [endDates, setEndDates]=useState("")
  const accessregion = localStorage.getItem('accessregion');
  const pdfExportComponent = useRef(null);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [weekplanexport, setWeekPlanExport] = useState([]);

  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [regions, setRegions] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  // const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [sortorder, setSortorder] = useState(0);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [visible, setVisible] = useState(false);
  const [filterDays, setFilterDays] = useState("");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [categoryLandingDetail, setCategoryLandingDetail] = useState([]);
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Title",
      visible: false,
      key: "Title",
      sorter: (a, b) => a.title - b.title,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.title}</span>
      ),
    },
    {
      title: "Slug",
      visible: false,
      key: "Slug",
      render: (text, record, index) => <span>{record.slug}</span>,
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },

    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) => (
        <span>
          <Tooltip
            placement="topRight"
            title="Edit landing page"
          // className="tooltip_list"
          >
            <Link
              to={{
                pathname: `/Content/landingStore/${record._id}`,
                state: { id: record._id },
              }}
            >
              <img
                src={adminProductEdit}
                className={`${commonstyles.admin_table_edit_img} mr-2`}
                // onClick={() => editCustomer(customer)}
                alt="Edit action icon"
                // title="Edit landing page"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title="Delete landing page"
            // className="tooltip_list"
          >
            <img
              src={adminProductDelete}
              className={commonstyles.admin_table_edit_img}
              onClick={() => showDeleteConfirmation(record)}
              alt="Delete action icon"
              // title="Delete landing page"
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </span>
      ),
    },
  ]);

  const sentStatus = (showRoom) => {
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`/storePage/${showRoom._id}/${showRoom.enable}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    localStorage.setItem(
      "categoryLandingDetail",
      JSON.stringify(categoryLandingDetail)
    );
    return () => {
      localStorage.removeItem("categoryLandingDetail");
    };
    },[categoryLandingDetail,accessregion]);

  const toggleStatusOnChange = (e, record) => {
    if (localStorage.getItem("categoryLandingDetail")) {
      let warehouseListClone = JSON.parse(
        localStorage.getItem("categoryLandingDetail")
      );
      warehouseListClone.forEach((item) => {
        if (record._id === item._id) {
          item.enable = !item.enable;
          sentStatus(item);
        }
      });
      localStorage.setItem(
        "categoryLandingDetail",
        JSON.stringify(warehouseListClone)
      );
      setCategoryLandingDetail(warehouseListClone);
    }
  };
  /**
   * @author Rajkumar
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(
      localStorage.getItem("categoryLandingDetail")
    );
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem(
      "categoryLandingDetail",
      JSON.stringify(cloneOrderList)
    );
    setCategoryLandingDetail(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
   * @author Rajkumar
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedCustomerr");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(
      localStorage.getItem("categoryLandingDetail")
    );
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem(
      "categoryLandingDetail",
      JSON.stringify(cloneOrderList)
    );
    setCategoryLandingDetail(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getCategoryWiseLanding(pageSize, nextPage, "");

    getRegionList();
    localStorage.removeItem("selectedCustomerr");
    return () => {
      localStorage.removeItem("categoryLandingDetail");
      localStorage.removeItem("selectedCustomerr");
    };
  }, []);

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Region", value: "", _id: "" };
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
            if (!checkDuplicates) {
              filters.push({ id: 1, isChecked: false, name: "Region", value: "Region" })
            }
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };
  /**
   * @author Rajkumar
   * @param { pageSize, nextPage, searchNow,filterarr, type}
   * To get category wise landing
   */
  function getCategoryWiseLanding(
    pageSize,
    nextPage,
    searchNow,
    filterarr,
    type
  ) {
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search: searchNow,
    };

    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }

    if (filterarr && filterarr.filterDays !== "") {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterDays !== "") {
        filterOptions.filterDays = Number(filterDays);
      }
    }

    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }

    const newStartDate = startDates.split("-").reverse().join("-");
    const newEndDate = endDates.split("-").reverse().join("-");
    if(filterarr && filterarr?.filterDays==="custom"){
      filterOptions.startDate = newStartDate?newStartDate:"";
      filterOptions.endDate = newEndDate?newEndDate:"";
    }

    // setIsLoading(true);
    AxiosInstance(PORTS.CMS, "application/json")
      .post(`/storePage/listPage`, filterOptions)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setCategoryLandingDetail(res.data.data);
          setPagination(res.data.pagination);
        } else {
          setCategoryLandingDetail([]);
          setPagination({});
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => { });
  }

  // console.log(categoryLandingDetail);

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      sortOrder: sortorder,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCategoryWiseLanding(value, 1, search, updatedFilter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCategoryWiseLanding(pageSize, value, search, updatedFilter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function closeEditProduct(success) {
    if (success) {
      getCategoryWiseLanding(pageSize, nextPage);
    }
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  //   if (showAddCustomerVisibility) {
  //     return (
  //       <AddCustomer
  //         changeAddCustomerVisibility={() => setshowAddCustomerVisibility(false)}
  //       />
  //     );
  //   }

  function searchHandler(e) {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
      };
      getCategoryWiseLanding(pageSize, 1, e.target.value, updatedFilter);
    }
  }

  // TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  //TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter =  JSON.parse(JSON.stringify([...selectFilter]));
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "Region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }

        if (item.value === "Date") {
          const clonefilteredDate = { ...filteredDate };
          clonefilteredDate.startDate = "";
          clonefilteredDate.endDate = "";
          updatedFilter.startDate = "";
          updatedFilter.endDate = "";
          updatedFilter.filterDays = 0;
          setFilterDays("");
          setFilterDaysName("Date");
          setFilteredDate(clonefilteredDate);
        }
        getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter);
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value;
      setRegionId(value);
      setRegionName(value?.name);
    }

    getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    setStartDates(dateString[0])
    setEndDates(dateString[1])
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Region") === "Region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="regionId"
                    value={regionId}
                    onChange={filterOnChange}
                  >
                    <option value="">All Region</option>
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <option key={region._id} value={region._id}>
                          {region.name}
                        </option>
                      ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
              {selectedFilters.length > 0 &&
            selectedFilters.find((x) => x === "Date") === "Date" && (
              <div className="card_dropdown show col-md-6">
                <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                  {filterDaysName}<img alt="" src={arrowDown} width={10} height={20} />
                </div>
                <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                  {Array.isArray(filterdayArr)
                    ? filterdayArr.map((day) => (
                      <span key={day._id} onClick={() => filterRegionChange("filterDays", day)}>
                        <label>{day.name}</label>
                      </span>
                    ))
                    : null}
                </div>
              </div>
            )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && 
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  function showDeleteConfirmation(customer) {
    console.log(customer);
    setSelectedCustomer(customer);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }
  /**
   * @author Rajkumar
   * To delete customer
   */
  function deleteCustomer() {
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`removeStorePage/${selectedCustomer._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Store wise landing page deleted successfully",
          });
          getCategoryWiseLanding(pageSize, nextPage);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to remove category wise landing page",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  function cancelDelete() {
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  /**
   * @author Rajkumar
   * @param {data}
   * BUILD STATUS CLICK HANDLER
   */
  // *** BUILD STATUS CLICK HANDLER *** //
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedCustomerr")) {
      let selectedCustomerr = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (selectedCustomerr.length > 0) {
        let data = {
          pageIds: selectedCustomerr,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    AxiosInstance(PORTS.CMS, "application/json")
      .post("storePage/bulkUpdateStatus", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getCategoryWiseLanding(pageSize, nextPage, "");

          localStorage.removeItem("selectedCustomerr");
          const cloneOrderList = JSON.parse(
            localStorage.getItem("categoryLandingDetail")
          );
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem(
            "categoryLandingDetail",
            JSON.stringify(cloneOrderList)
          );
          setCategoryLandingDetail(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortOrder: sorter.order === "descend" ? 1 : 0,
    };
    setSortorder(sorter.order === "descend" ? 1 : 0);
    // setNextPage(1);
    getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter, true);
  };

  function searchClick() {
    let updatedFilter = {};
    getCategoryWiseLanding(pageSize, nextPage, search, updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={`${commonstyles.management_contents} pl-0 pr-0`}>
      <div className={commonstyles.management_header}>
        <div
          className={` ${commonstyles.management_contents_upload} ${commonstyles.customer_add_btn} `}
        >
          <Link to="/Content/landingStore/add">
            <span style={{ cursor: "pointer" }}>
              <img src={Plus} alt="" />
              Add Store wise landing page
            </span>
          </Link>
        </div>
      </div>

      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by Title, Slug...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: (e) => searchHandler(e),
              ["aria-label"]: "Search customers",
              ["aria-describedby"]: "search-customers",
            }}
          />
          <img src={Search} alt="" />
        </div>
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        {/* <Dropdown
            // overlay={filterMenu}
            className="usermanage_table_filter_columns ml-3"
          >
            <div
              className="ant-dropdown-link"
              onTouchMove={(e) => e.preventDefault()}
            >
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown> */}
        <div className="action ml-3">
          <div className="action_header">
            Action
            <img src={arrowDown} alt="image" />
          </div>
          <div className="action_contents">
            {bulkActionFilter.length > 0 &&
              bulkActionFilter.map((item) => (
                <div
                  className="action_contents_header"
                  key={item._id}
                  onClick={() => bulkStatusClickHandler(item)}
                >
                  <h2
                    className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                      }`}
                  >
                    {item.name}
                  </h2>
                  {item.isChecked && (
                    <span
                      className="action_contents_btn"
                      onClick={() => applyBulkActionHandler(item)}
                    >
                      Apply
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Dropdown
          overlay={filterMenu}
          onVisibleChange={handleVisibleChange} visible={visible}
          className="usermanage_table_filter_columns ml-3"
        >
          <div className="ant-dropdown-link">
            Filter <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu}
          className="usermanage_table_filter_columns ml-3"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Columns <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
      </div>

      <div className={`${commonstyles.management_table} table-responsive`}>
        {IsLoading === true ? (
          <div
            style={{
              marginLeft: "50vh",
              marginTop: "10vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching category wise landing page details..." />
              </td>
            </tr>
          </div>
        ) : (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`categoryLandingDetail for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div>
              <input
                type="checkbox"
                className={`${commonstyles.parentCheckbox}`}
                onChange={parentCheckbox}
              ></input>
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: categoryLandingDetail,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </div>
          </PDFExport>
        )}
      </div>
      {!IsLoading &&
        Array.isArray(categoryLandingDetail) &&
        categoryLandingDetail.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}

      {/* Store delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p
              className="theme-txt-detail"
              style={{ textTransform: "capitalize" }}
            >
              Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Store delete confirmation modal ends*/}
    </div>
  );
}