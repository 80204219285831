import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { Link } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import AddDiscount from "../DiscountManagement/AddDiscount";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../Order Management/OrderDetails";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import {
  Dropdown,
  Menu,
  message as massages,
  notification,
  Tooltip,
} from "antd";
import { arrayMove } from "react-movable";
import SearchComponent from "../SearchManagement/SearchComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import excel from "../../../assets/images/excel.svg";
import deliveryStyle from "../../../styles/Delivery.module.css";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Plus from "../../../assets/images/plus_white_icon.svg";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Order Id", value: "order_id" },
  { _id: 3, status: 1, name: "Customer", value: "customer" },
  { _id: 4, status: 1, name: "Delivery Person", value: "delivery_person" },
  { _id: 5, status: 1, name: "Delivery Mobile", value: "delivery_mobile" },
  { _id: 6, status: 1, name: "Qty", value: "qty" },
  { _id: 7, status: 1, name: "Order Placed On", value: "order_placed_on" },
  { _id: 8, status: 1, name: "Status", value: "status" },
  // { _id: 9, status: 1, name: "Operate", value: "operate" },
  { _id: 10, status: 0, name: "Region", value: "region" },
  { _id: 11, status: 0, name: "Customer Mobile", value: "customer_mobile" },
  { _id: 12, status: 0, name: "Shipment Date", value: "shipment_date" },
  { _id: 13, status: 0, name: "Delivered Date", value: "delivered_date" },
  { _id: 14, status: 0, name: "Rescheduled Date", value: "rescheduled_date" },
  { _id: 15, status: 0, name: "Enable/Disable", value: "enable_disable" },
];

const initialFilterState = {
  pagesize: 10,
  nextpage: 1
}

const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  {
    id: 2,
    isChecked: false,
    name: "Orderplaced Date",
    value: "orderplaced_date",
  },
  { id: 3, isChecked: false, name: "Shipment Date", value: "shipment_date" },
  { id: 4, isChecked: false, name: "Delivered Date", value: "deliver_date" },
  {
    id: 5,
    isChecked: false,
    name: "Resheduled Date",
    value: "rescheduled_date",
  },
  { id: 6, isChecked: false, name: "Order status", value: "order_status" },
];

const bulkAction = [
  // { _id: 4, isChecked: true, name: "Out for delivery", value: 7 },
  { _id: 5, isChecked: true, name: "Delivered successfully", value: 3 },
  // { _id: 6, isChecked: false, name: "Delivery attempted", value: 8 },
];

const orderStatusArr = [
  { key: 2, name: "Shipped", value: "2" },
  { key: 3, name: "Delivered", value: "3" },
  { key: 7, name: "Out for delivery", value: "7" },
  { key: 8, name: "Delivery Attempted", value: "8" },
];

const original_columns = [
  "Order Id",
  "Customer",
  "Delivery Person",
  "Delivery Mobile",
  "Payment Method",
  "Qty",
  "Order Placed On",
  "Status",
  "Customer Mobile",
  "Shipment Date",
  "Delivered Date",
  "Rescheduled Date",
  "Region",
  "Enable/Disable",
];

const column_value = {
  order_id: true,
  customer: true,
  delivery_person: true,
  delivery_mobile: true,
  payment_method: true,
  qty: true,
  order_placed_on: true,
  status: true,
  operate: true,
};

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

export default function DeliveryManagementList() {
  const accessregion = localStorage.getItem('accessregion');
  const [orderList, setOrderList] = useState([]);
  const [originalColumn, setOriginalColumn] = useState(original_columns);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const timeoutId = useRef();
  const [regions, setRegions] = useState([]);
  const [filterDaysOrder, setFilterDaysOrder] = useState("");
  const [filterDaysShip, setFilterDaysShip] = useState("");
  const [filterDaysDeliver, setFilterDaysDeliver] = useState("");
  const [filterDaysReschedule, setFilterDaysReschedule] = useState("");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const placeholder = [
    moment(firstDay).format("DD-MM-YYYY"),
    moment(lastDay).format("DD-MM-YYYY"),
  ];
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
  const [filteredResult, setFilteredResult] = useState("");
  const [orderStatusName, setOrderStatusName] = useState("All Status");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const pdfExportComponent = useRef(null);
  const [orderPlaced, setOrderPlaced] = useState([]);
  const [shippedDate, setShippedDate] = useState([]);
  const [deliveredDate, setDeliveredDate] = useState([]);
  const [rescheduledDate, setRescheduledDate] = useState([]);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [filterDaysNameOrder, setFilterDaysNameOrder] = useState("Order placed date");
  const [filterDaysNameShip, setFilterDaysNameShip] = useState("Shipping date");
  const [filterDaysNameDeliver, setFilterDaysNameDeliver] = useState("Delivered date");
  const [filterDaysNameReschedule, setFilterDaysNameReschdule] = useState("Rescheduled date");

  const [customerOrder, setSortCustomer] = useState();
  const [deliveryPersonOrder, setSortDeliveryPerson] = useState();
  const [qtyOrder, setSortQty] = useState();
  const [orderPlacedOrder,setSortPlaced] = useState();

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Order Id",
      visible: false,
      key: "order_id",
      render: (text, record, index) => <span>{record.orderId}</span>,
      sorter: (a, b) => a.orderId - b.orderId,
    },
    {
      title: "Customer",
      visible: false,
      key: "customer",
      render: (text, record, index) => (
        <span>{record.shippingAddress?.name}</span>
      ),
      sorter:true
    },
    {
      title: "Delivery Person",
      visible: false,
      sorter:true,
      key: "delivery_person",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.deliveryAgent?.name?.split("__")[0]
            ? record.deliveryAgent?.name?.split("__")[0]
            : ""}{" "}
          {record.deliveryAgent?.name?.split("__")[1]
            ? record.deliveryAgent?.name?.split("__")[1]
            : ""}
        </span>
      ),
    },
    {
      title: "Delivery Mobile",
      visible: false,
      key: "delivery_mobile",
      render: (text, record, index) => (
        <span> +91 {record.deliveryAgent?.phone}</span>
      ),
    },
    {
      title: "Qty",
      visible: false,
      sorter:true,
      key: "qty",
      render: (text, record, index) => <span>{record.products?.quantity}</span>,
    },
    {
      title: "Order Placed On",
      visible: false,
      sorter:true,
      key: "order_placed_on",
      render: (text, record, index) => (
        <span>{moment(record.createdAt).format("MMM Do YYYY, h:mm:ss A")}</span>
      ),
    },
    {
      title: "Customer Mobile",
      visible: false,
      key: "customer_mobile",
      render: (text, record, index) => (
        <span>+91 {record?.shippingAddress?.phone} </span>
      ),
    },
    {
      title: "Shipment Date",
      visible: false,
      key: "shipment_date",
      render: (text, record, index) => (
        <span>
          {record.products?.shipedOn
            ? moment(record.products?.shipedOn).format("MMM Do YYYY, h:mm:ss A")
            : "-"}
        </span>
      ),
    },
    {
      title: "Delivered Date",
      visible: false,
      key: "delivered_date",
      render: (text, record, index) => (
        <span>
          {record.products?.shipedOn && record.products?.status == 3
            ? moment(record.products?.shipedOn).format("MMM Do YYYY, h:mm:ss A")
            : "-"}
        </span>
      ),
    },
    {
      title: "Rescheduled Date",
      visible: false,
      key: "rescheduled_date",
      render: (text, record, index) => (
        <span>
          {record.products?.rescheduledOn
            ? moment(record.products?.rescheduledOn).format(
              "MMM Do YYYY, h:mm:ss A"
            )
            : "-"}
        </span>
      ),
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      render: (text, record, index) => <span>{record.regionName}</span>,
    },
    {
      title: "Enable/Disable",
      visible: false,
      key: "enable_disable",
      render: (text, record, index) => (
        <span>{record.deliveryAgent?.enable ? "Enable" : "Disable"}</span>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "status",
      render: (text, record, index) => (
        <span
          class={
            record.products?.status == 1
              ? deliveryStyle.status_pending
              : record.products?.status === 2
                ? deliveryStyle.status_pending
                : record.products?.status === 3
                  ? deliveryStyle.status_active
                  : record.products?.status === 4
                    ? deliveryStyle.status_inactive
                    : record.products?.status === 5
                      ? deliveryStyle.status_inactive
                      : record.products?.status === 6
                        ? deliveryStyle.status_pending
                        : record.products?.status === 7
                          ? deliveryStyle.status_pending
                          : deliveryStyle.status_inactive
          }
        >
          {record.products?.status === 1
            ? "Processing"
            : record.products?.status === 2
              ? "Shipped"
              : record.products?.status === 3
                ? "Delivered"
                : record.products?.status === 4
                  ? "Cancelled"
                  : record.products?.status === 5
                    ? "Failed"
                    : record.products?.status === 6
                      ? "On-Hold"
                      : record.products?.status === 7
                        ? "Out For Delivery"
                        : "Delivery attempted"}{" "}
        </span>
      ),
    },
    {
      title: "Operate",
      visible: false,
      key: "operate",
      render: (text, record, index) => (
        <span>
          <Tooltip
            placement="topRight"
            title="Edit delivery"
          // className="tooltip_list"
          >
            <Link to={`/catalog/deliveryList/viewDelivery/${record.deliveryAgent?._id}`}>
              <img
                src={adminProductEdit}
                className={commonstyles.admin_table_edit_img}
                // onClick={() => editCustomer(record)}
                alt="Edit action icon"
                style={{ cursor: "pointer" }}
              />{" "}
            </Link>
          </Tooltip>
        </span>
      ),
    },
  ]);
  const [selectedOrder, setSelectedOrder] = useState({});


  /**
 * @author RAJKUMAR
 * @param {Event,dateString}
 * DATE CHANGE HANDLER
 */
  const dateRangeOnchangeHandler = (e, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);

    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      filterDaysOrder: filterDaysOrder,
      filterDaysDelivered: filterDaysDeliver,
      filterDaysRescheduled: filterDaysReschedule,
      filterDaysShipped: filterDaysShip,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    setOrderPlaced(dateString);
    debounceSearch(updatedFilter);
  };


  /**
 * @author RAJKUMAR
 * @param {}
 * DATE CHANGE HANDLER WHILE SHIPING 
 */
  const dateRangeOnchangeHandlerShip = (e, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDateShipped = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDateShipped = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);

    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      filterDaysOrder: filterDaysOrder,
      filterDaysDelivered: filterDaysDeliver,
      filterDaysRescheduled: filterDaysReschedule,
      filterDaysShipped: filterDaysShip,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    setShippedDate(dateString);
    debounceSearch(updatedFilter);
  };

  /**
   * @author RAJKUMAR
   * @param {}
   * DATE CHANGE HANDLER WHILE DELIVERED 
   */
  const dateRangeOnchangeHandlerDelivered = (e, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDateDelivered = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.startDateDelivered = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);

    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      filterDaysOrder: filterDaysOrder,
      filterDaysDelivered: filterDaysDeliver,
      filterDaysRescheduled: filterDaysReschedule,
      filterDaysShipped: filterDaysShip,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    setDeliveredDate(dateString);
    debounceSearch(updatedFilter);
  };

  /**
* @author RAJKUMAR
* @param {}
* DATE CHANGE HANDLER WHILE RESCHECULE 
*/
  const dateRangeOnchangeHandlerReschedule = (e, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDateRescheduled = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDateRescheduled = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);

    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      filterDaysOrder: filterDaysOrder,
      filterDaysDelivered: filterDaysDeliver,
      filterDaysRescheduled: filterDaysReschedule,
      filterDaysShipped: filterDaysShip,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    setRescheduledDate(dateString);
    debounceSearch(updatedFilter);
  };

  /* Export */
  const exportToCSV = async (e) => {
    

   
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.INVOICE, "application/json")
      .post(`/warehouse/exportagent`, params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".csv";
          const ws = XLSX.utils.json_to_sheet(und_res);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");

          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Delivery-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.csv`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  // const [selectedOrder, setSelectedOrder] = useState([]);
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        let obj = {};
        obj.orderId = record._id;
        obj.suborderId = record.products?._id;
        cloneSelectedOrder.push(obj);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item.orderId
        );
      }
    } else if (checked) {
      let obj = {};
      obj.orderId = record._id;
      obj.suborderId = record.products?._id;
      cloneSelectedOrder.push(obj);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          let obj = {};
          obj.orderId = item._id;
          obj.suborderId = item.products?._id;
          cloneSelectedOrder.push(obj);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        let obj = {};
        obj.orderId = item._id;
        obj.suborderId = item.products?._id;
        cloneSelectedOrder.push(obj);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getOrderDetails(pageSize, nextPage, initialFilterState);
    getRegionList();
    localStorage.removeItem("selectedOrder");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    };
    return () => {
      localStorage.removeItem("orderList");
      localStorage.removeItem("selectedOrder");
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getOrderDetails(pageSize, nextPage, filterarr,type) {
    if (!type) {
      setIsLoading(true);
    }
    
    filterarr.pagesize = pageSize;
    filterarr.nextpage = nextPage;
    // const filterarr = {
    //   pagesize: pageSize,
    //   nextpage: nextPage,

    // };
    // if (filterarr && filterarr.search) {
    //   filterOptions.search = filterarr.search;
    // } else if (search !== "") {
    //   if (filterarr && filterarr.search !== "") {
    //     filterOptions.search = search;
    //   }
    // }

    if (localStorage.getItem('accessregion')) {
      filterarr.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterarr.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterarr.regionId = regionId;
      }
    }
    if (filterarr && filterarr.orderStatus) {
      filterarr.orderStatus = parseInt(filterarr.orderStatus);
    } else if (orderStatus !== "") {
      if (filterarr && filterarr.orderStatus !== "") {
        filterarr.orderStatus = parseInt(orderStatus);
      }
    }

    if (filterarr.filterDaysOrder) {
      filterarr.filterDaysOrder = Number(filterarr.filterDaysOrder);
    } else {
      filterarr.filterDaysOrder = null;
    }
    if (filterarr.filterDaysShipped) {
      filterarr.filterDaysShipped = Number(filterarr.filterDaysShipped);
    } else {
      filterarr.filterDaysShipped = null;
    }
    if (filterarr.filterDaysDelivered) {
      filterarr.filterDaysDelivered = Number(filterarr.filterDaysDelivered);
    } else {
      filterarr.filterDaysDelivered = null;
    }
    if (filterarr.filterDaysRescheduled) {
      filterarr.filterDaysRescheduled = Number(filterarr.filterDaysRescheduled);
    } else {
      filterarr.filterDaysRescheduled = null;
    }
    if (filterarr && filterarr.startDate && filterarr.endDate) {
      filterarr.startDate = filterarr.startDate;
      filterarr.endDate = filterarr.endDate;
    } else {

      delete filterarr.startDate;
      delete filterarr.endDate;
      // if (filterarr && filterarr.startDate !== null && filterarr.endDate !== null) {
      //   filterarr.startDate = orderPlaced[0];
      //   filterarr.endDate = orderPlaced[1];
      // }
    }

    if (filterarr && filterarr.startDateShipped && filterarr.endDateShipped) {
      filterarr.startDateShipped = filterarr.startDateShipped;
      filterarr.endDateShipped = filterarr.endDateShipped;
    } else {

      delete filterarr.startDateShipped;
      delete filterarr.endDateShipped;
      // if (
      //   filterarr &&
      //   filterarr.startDateShipped !== null &&
      //   filterarr.endDateShipped !== null
      // ) {
      //   filterarr.startDateShipped = orderPlaced[0];
      //   filterarr.endDateShipped = orderPlaced[1];
      // }
    }
    if (
      filterarr &&
      filterarr.startDateDelivered &&
      filterarr.endDateDelivered
    ) {
      filterarr.startDateDelivered = filterarr.startDateDelivered;
      filterarr.endDateDelivered = filterarr.endDateDelivered;
    } else {

      delete filterarr.startDateDelivered;
      delete filterarr.endDateDelivered;
      // if (
      //   filterarr &&
      //   filterarr.startDateDelivered !== null &&
      //   filterarr.endDateDelivered !== null
      // ) {
      //   filterarr.startDateDelivered = orderPlaced[0];
      //   filterarr.endDateDelivered = orderPlaced[1];
      // }
    }
    if (
      filterarr &&
      filterarr.startDateRescheduled &&
      filterarr.endDateRescheduled
    ) {
      filterarr.startDateRescheduled = filterarr.startDateRescheduled;
      filterarr.endDateRescheduled = filterarr.endDateRescheduled;
    } else {

      delete filterarr.startDateRescheduled;
      delete filterarr.endDateRescheduled;
      // if (
      //   filterarr &&
      //   filterarr.startDateRescheduled !== null &&
      //   filterarr.endDateRescheduled !== null
      // ) {
      //   filterarr.startDateRescheduled = orderPlaced[0];
      //   filterarr.endDateRescheduled = orderPlaced[1];
    }

    if (filterarr.regionId == "") delete filterarr.regionId;

    if (filterarr.orderStatus == "") delete filterarr.orderStatus;



    Object.keys(filterarr).forEach(key => {

      if (typeof filterarr[key] == "object" && filterarr[key] == null) {
          delete filterarr[key];
      }
    });
    setFilterOptionsGlobal(filterarr);
    AxiosInstance(PORTS.INVOICE, "application/json")
      .post("warehouse/agent", filterarr)
      .then((res) => {
        // console.log(res);
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("orderList", JSON.stringify(res.data.data));
            setOrderList(res.data.data);
          } else {
            localStorage.removeItem(orderList);
            setOrderList([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = {
      search: search,
      nextpage: nextPage,
      pagesize: pageSize,
      regionId: regionId,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      customerOrder : customerOrder,
      deliveryPersonOrder : deliveryPersonOrder,
      qtyOrder : qtyOrder,
      orderPlacedOrder : orderPlacedOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOrderDetails(value, 1, filter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOrderDetails(pageSize, value, filter,true);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function editCustomer(user) {
    setSelectedCustomer(user);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  }

  function closeEditProduct() {
    getOrderDetails(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  if (childComponentVisibility.addProduct) {
    return (
      <AddDiscount
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditOrderComponent
        customer={selectedCustomer}
        handleClose={closeEditProduct}
      />
    );
  }

  function handleInput(e) {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        regionId: regionId,
      orderStatus: orderStatus,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDaysDelivered: filterDaysDeliver,
      filterDaysShipped: filterDaysShip,
      filterDaysOrder: filterDaysOrder,
      filterDaysRescheduled: filterDaysReschedule,
      customerOrder:customerOrder,
      deliveryPersonOrder:deliveryPersonOrder,
      qtyOrder:qtyOrder,
      orderPlacedOrder:orderPlacedOrder
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getOrderDetails(pageSize, 1, filter,true);
    setFilteredResult(filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDaysDelivered: filterDaysDeliver,
      filterDaysShipped: filterDaysShip,
      filterDaysOrder: filterDaysOrder,
      filterDaysRescheduled: filterDaysReschedule,
      customerOrder:customerOrder,
      deliveryPersonOrder:deliveryPersonOrder,
      qtyOrder:qtyOrder,
      orderPlacedOrder:orderPlacedOrder
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDaysNameOrder") {
      updatedFilter.filterDaysOrder = value.value;
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDaysOrder(value.value);
      setFilterDaysNameOrder(value.name);
    }
    if (name === "filterDaysNameShip") {
      updatedFilter.filterDaysShipped = value.value;
      updatedFilter.startDateShipped = "";
      updatedFilter.endDateShipped = "";
      setFilterDaysShip(value.value);
      setFilterDaysNameShip(value.name);
    }
    if (name === "filterDaysNameDeliver") {
      updatedFilter.filterDaysDelivered = value.value;
      updatedFilter.startDateDelivered = "";
      updatedFilter.endDateDelivered = "";
      setFilterDaysDeliver(value.value);
      setFilterDaysNameDeliver(value.name);
    }
    if (name === "filterDaysNameReschedule") {
      updatedFilter.filterDaysRescheduled = value.value;
      updatedFilter.startDateRescheduled = "";
      updatedFilter.endDateRescheduled = "";
      setFilterDaysReschedule(value.value);
      setFilterDaysNameReschdule(value.name);
    }
    if (name === "orderStatus") {
      updatedFilter.orderStatus = value.value;
      setOrderStatus(value.value);
      setOrderStatusName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    // console.log(props);
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
        search: search,
        nextpage: 1,
        regionId: regionId,
        orderStatus: orderStatus,
        startDate: orderPlaced[0]
          ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDate: orderPlaced[1]
          ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateShipped: shippedDate[0]
          ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateShipped: shippedDate[1]
          ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateDelivered: deliveredDate[0]
          ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateDelivered: deliveredDate[1]
          ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateRescheduled: rescheduledDate[0]
          ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateRescheduled: rescheduledDate[1]
          ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        filterDaysDelivered: filterDaysDeliver,
        filterDaysShipped: filterDaysShip,
        filterDaysOrder: filterDaysOrder,
        filterDaysRescheduled: filterDaysReschedule,
        customerOrder:customerOrder,
        deliveryPersonOrder:deliveryPersonOrder,
        qtyOrder:qtyOrder,
        orderPlacedOrder:orderPlacedOrder
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "order_status") {
          updatedFilter.orderStatus = "";
          setOrderStatus("");
          setOrderStatusName("All Status");
        }
        if (item.value === "orderplaced_date") {
          updatedFilter.startDate = "";
          updatedFilter.endDate = "";
          updatedFilter.filterDaysOrder=""
          setFilterDaysOrder("")
          setFilterDaysNameOrder("Order placed date")
          setOrderPlaced([]);
        }
        if (item.value === "shipment_date") {
          updatedFilter.startDateShipped = "";
          updatedFilter.endDateShipped = "";
          updatedFilter.filterDaysShipped=""
          setFilterDaysShip("")
          setFilterDaysNameShip("Shipping date")
          setShippedDate([]);
        }
        if (item.value === "deliver_date") {
          updatedFilter.startDateDelivered = "";
          updatedFilter.endDateDelivered = "";
          updatedFilter.filterDaysDelivered = ""
          setFilterDaysDeliver("")
          setFilterDaysNameDeliver("Delivered date")
          setDeliveredDate([]);
        }
        if (item.value === "rescheduled_date") {
          updatedFilter.startDateRescheduled = "";
          updatedFilter.endDateRescheduled = "";
          updatedFilter.filterDaysRescheduled = ""
          setFilterDaysReschedule("")
          setFilterDaysNameReschdule("")
          setRescheduledDate([]);
        }
      }
    });
    debounceSearch(updatedFilter);
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "orderplaced_date") === "orderplaced_date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysNameOrder}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() => filterRegionChange("filterDaysNameOrder", day)}
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "orderplaced_date") ===
              "orderplaced_date" && filterDaysOrder === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <label>Ordered Date</label>
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "orderplaced_date",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "shipment_date") === "shipment_date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysNameShip}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() => filterRegionChange("filterDaysNameShip", day)}
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "shipment_date") ===
              "shipment_date" && filterDaysShip === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <label>Shipment Date</label>
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandlerShip,
                      name: "shipment_date",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "deliver_date") === "deliver_date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysNameDeliver}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() => filterRegionChange("filterDaysNameDeliver", day)}
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "deliver_date") ===
              "deliver_date" && filterDaysDeliver === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <label>Delivered Date</label>
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandlerDelivered,
                      name: "deliver_date",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "rescheduled_date") === "rescheduled_date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysNameReschedule}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() => filterRegionChange("filterDaysNameReschedule", day)}
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "rescheduled_date") ===
              "rescheduled_date" && filterDaysReschedule === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <label>Rescheduled Date</label>
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandlerReschedule,
                      name: "rescheduled_date",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "order_status") ===
              "order_status" && (
                <div className="card_dropdown show col-md-12">
                  <label>Order status</label>
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {orderStatusName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(orderStatusArr)
                      ? orderStatusArr.map((status) => (
                        <span
                          key={status.key}
                          onClick={() =>
                            filterRegionChange("orderStatus", status)
                          }
                        >
                          <label>{status.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  // *** BUILD STATUS CLICK HANDLER *** //
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedOrder")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (selectedOrder.length > 0) {
        let data = {
          orders: selectedOrder,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    // setIsLoading(true);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("orders/warehouse/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedOrder");
          const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
          setOrderList(cloneOrderList);
          getOrderDetails(pageSize, nextPage, initialFilterState);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function cancelDelete() {
    setSelectedOrder({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }

  const removeOrder = () => {
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`orders/deleteOrder/${selectedOrder._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getOrderDetails(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => { });
  };
  function addDelivery() {
    history.push(`/catalog/deliveryList/add`);
  }

  function listDelivery() {
    history.push(`/catalog/deliveryList/delivery`);
  }

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      orderStatus: orderStatus,
      startDate: orderPlaced[0]
        ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: orderPlaced[1]
        ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateShipped: shippedDate[0]
        ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateShipped: shippedDate[1]
        ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateDelivered: deliveredDate[0]
        ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateDelivered: deliveredDate[1]
        ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      startDateRescheduled: rescheduledDate[0]
        ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDateRescheduled: rescheduledDate[1]
        ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDaysDelivered: filterDaysDeliver,
      filterDaysShipped: filterDaysShip,
      filterDaysOrder: filterDaysOrder,
      filterDaysRescheduled: filterDaysReschedule,
      customerOrder:customerOrder,
      deliveryPersonOrder:deliveryPersonOrder,
      qtyOrder:qtyOrder,
      orderPlacedOrder:orderPlacedOrder
    };
    debounceSearch(updatedFilter);
  }

  function handleTableChange(pagination, filters, sorter, extra) {
    let updatedFilter = {
      search:search,
      nextpage: nextPage,
      pagesize: pageSize,
        regionId: regionId,
        orderStatus: orderStatus,
        filterDaysOrder: filterDaysOrder,
        filterDaysDelivered: filterDaysDeliver,
        filterDaysRescheduled: filterDaysReschedule,
        filterDaysShipped: filterDaysShip,
        startDate: orderPlaced[0]
          ? moment(orderPlaced[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDate: orderPlaced[1]
          ? moment(orderPlaced[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateShipped: shippedDate[0]
          ? moment(shippedDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateShipped: shippedDate[1]
          ? moment(shippedDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateDelivered: deliveredDate[0]
          ? moment(deliveredDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateDelivered: deliveredDate[1]
          ? moment(deliveredDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        startDateRescheduled: rescheduledDate[0]
          ? moment(rescheduledDate[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
        endDateRescheduled: rescheduledDate[1]
          ? moment(rescheduledDate[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          : "",
    };

    if (sorter?.columnKey === "customer") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.customerOrder = sortValue;
      setSortCustomer(sortValue);
      setSortDeliveryPerson();
      setSortPlaced();
      setSortQty();
      
    }
    else if (sorter?.columnKey === "delivery_person") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.deliveryPersonOrder = sortValue;
      setSortCustomer();
      setSortDeliveryPerson(sortValue);
      setSortPlaced();
      setSortQty();
    } else if (sorter?.columnKey === "qty") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.qtyOrder = sortValue;
      setSortCustomer();
      setSortDeliveryPerson();
      setSortPlaced();
      setSortQty(sortValue);
    }else if (sorter?.columnKey === "order_placed_on") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.orderPlacedOrder = sortValue;
      setSortCustomer();
      setSortDeliveryPerson();
      setSortPlaced(sortValue);
      setSortQty();
     
    }
    
    getOrderDetails(pageSize, nextPage, updatedFilter, true);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Delivery Schedule
        </h1>
        <div>
          <span className={commonstyles.management_header_downloads_text}>
            <Tooltip placement="topRight" title="Download pdf">
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToPDF(e)}
              />
            </Tooltip>
          </span>
          <span
            className={commonstyles.management_header_downloads_text}
            style={{ cursor: "pointer" }}
            onClick={(e) => exportToCSV(e)}
          >
            <Tooltip placement="topRight" title="Download excel">
              <img className={commonstyles.export_icon} src={excel} />
            </Tooltip>
          </span>
          <span className={commonstyles.management_header_downloads_text_ex}>
            Export
            <img className="ml-1" src={exportIcon} alt="icon" />
          </span>
        </div>
      </div>
      <div className={commonstyles.management_contents_upload}>
        <span
          style={{ cursor: "pointer" }}
          className={`${commonstyles.outline_btn}`}
          onClick={listDelivery}
        >
          Delivery Management
        </span>
        <span
          style={{ cursor: "pointer" }}
          className={`${commonstyles.outline_btn}`}
          onClick={addDelivery}
        >
          Add Delivery Person
        </span>
      </div>
      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by order ID...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: handleInput,
              ["aria-label"]: "Search category",
              ["aria-describedby"]: "search-category",
            }}
          />
          <img src={Search} alt="" />
        </div>
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        <div className="action ml-3">
          <div className="action_header">
            Action
            <img src={arrowDown} alt="image" />
          </div>
          <div className="action_contents">
            {bulkActionFilter.length > 0 &&
              bulkActionFilter.map((item) => (
                <div
                  className="action_contents_header"
                  key={item._id}
                  onClick={() => bulkStatusClickHandler(item)}
                >
                  <h2
                    className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                      }`}
                  >
                    {item.name}
                  </h2>
                  {item.isChecked && (
                    <span
                      className="action_contents_btn"
                      onClick={() => applyBulkActionHandler(item)}
                    >
                      Apply
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Dropdown
          overlay={filterMenu}
          onVisibleChange={handleVisibleChange} visible={visible}
          className="usermanage_table_filter_columns ml-3"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Filter <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu}
          className="usermanage_table_filter_columns ml-3"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Columns <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
        {/* <ButtonComponent
                        className="theme-btn-outlined rnd px-3 ml-3"
                        attributes={{
                            // onClick: (e) => exportToCSV(e),
                        }}
                    >
                        Export
                    </ButtonComponent> */}
      </div>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Delivery Report ${new Date().getFullYear()}`}
        author="Damro Team"
      >
        <div className={`${commonstyles.management_table} table-responsive`}>
          {!isLoading && orderList.length > 0 && (
            <input
              type="checkbox"
              className={`${commonstyles.parentCheckbox}`}
              onChange={parentCheckbox}
            ></input>
          )}
          {isLoading === true ? (
            <div
              style={{
                marginLeft: "70vh",
                marginTop: "10vh",
              }}
            >
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching delivery details..." />
                </td>
              </tr>
            </div>
          ) : (
            <AntTableComponent
              className="order_table"
              tableProps={{
                columns: columns,
                dataSource: orderList,
                pagination: false,
                onChange: handleTableChange,
                components: {
                  header: {
                    row: (props) => renderHeader(props),
                  },
                  body: {
                    row: (props) => renderBody(props),
                  },
                },
              }}
            />
          )}
        </div>
      </PDFExport>
      {!isLoading && Array.isArray(orderList) && orderList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}

      {/* Order delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.delete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeOrder}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
}
