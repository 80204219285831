import React, { useState, useEffect } from "react";
import styles from "../../../../styles/AddDiscount.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styless from "../../../../styles/AddProduct.module.css";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import { notification, Space, Spin, Select } from "antd";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import moment from "moment";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import DatePicker from "react-datepicker";

import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import { useHistory } from "react-router";

import {
  onlyNumbers,
  onlyThreeNumbers,
  minCharwithnospacecount,
  onlyAlphabetsWithSpace,
  phoneRegx,
} from "../../../../utils/RegularExp";

const { Option } = Select;

const initialState = {
  taxPercentage: 18,
};

export default function OfferManagement() {
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedParentCategories, setSelectedParentCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taxDetails, setTaxDetails] = useState(initialState);
  const [ruleStatus, setRuleStatus] = useState("");
  const [formErrors, setFormErrors] = useState({
    taxName: false,
    startDate: false,
    endDate: false,
    percentage: false,
    regionId: false,
  });

  const rules = {
    percentage: [(val, data) => (!val ? "Percentage " : true)],
  };
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    addDiscount();
  }, []);

  function discountList() {
    history.push("/tax");
    history['isAdd'] = false;
  }

  //   function handleRegionChange(e) {
  //     taxDetails.regionId = e;
  //     setTaxDetails({ ...taxDetails });
  //   }

  function handleOfferChange(e) {
    const { name, value, checked } = e.target;
    initialState.taxPercentage = value;
    taxDetails.taxPercentage = value;
    setTaxDetails({ ...taxDetails });
    addDiscount();
  }
  /**
   * @author Rajkumar
   * TO ADD DISCOUNT
   */
  function addDiscount() {
    // const result = runValidationChecks(rules, undefined, { ...initialState });

    // if (Object.keys(result).length) {
    //   setFormErrors(result);
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description: Object.values(result) + "is required",
    //   });
    //   return;
    // }
    // if (
    //   taxDetails.taxName.trim().length < 3 ||
    //   taxDetails.taxName.trim().length > 30
    // ) {
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description:
    //       "Code/Name must be more than 1 characters and less than 30 characters",
    //   });
    //   return;
    // }
    const { state } = history.location;
    setLoading(false);
    setProducts(state?.productNames);
    setRuleStatus(state.ruleName)
    // setSelectedRegions(state.regionsId);
    setTaxDetails({ ...taxDetails, taxPercentage: state?._id? parseFloat(state._id) : 0 })
    let regionsWithId = [];
    let regionsName = [];
    state.regions?.forEach((item, index) => {
      state.regionsId?.forEach((subItem, indx) => {
        if (index === indx) {
          let obj = {};
          obj._id = subItem;
          obj.name = item;
          regionsWithId.push(obj);
          regionsName.push(obj.name)
        }
      })
    })
    setSelectedRegions(regionsName);
    setRegions(regionsWithId);
    // setSelectedParentCategories(state.categoriesId);
    let categoriesWithId = [];
    let categoriesName = []
    state.categories?.forEach((item, index) => {
      state?.categoriesId?.forEach((subItem, indx) => {
        if (index === indx) {
          let obj = {};
          obj._id = subItem;
          obj.name = item;
          categoriesWithId.push(obj);
          categoriesName.push(obj.name)
        }
      })
    });
    setSelectedParentCategories(categoriesName)
    setParentCategories(categoriesWithId);
    // showNotification({
    //   type: "success",
    //   message: "Success",
    //   description: "Tax rate information displayed successfully",
    // });
  };

  function handleSelectChange(value) {
    if (value.length > 0) {
      setSelectedParentCategories(value);
      let params = {
        regionId: selectedRegions, categoryId: value
      }
      getProductsByCategory(params);
    }
  }

  function regionSelectOnchange(value) {
    if (value.length > 0) {
      setSelectedRegions(value);
      let params = {
        regionId: value, categoryId: selectedParentCategories
      }
      getProductsByCategory(params);
    }
  }
  /**
   * @author Rajkumar
   * @param {params}
   * TO GET PRODUCTS BY CATEGORY
   */
  function getProductsByCategory(params) {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/tax/getProductsByCategory", params)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          // setIsLoading(false);
          setProducts(res.data?.data[0]?.name);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          // setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={discountList}
          />
          Tax Rate Information
        </h1>
        {/* <div>
          <div className="">
          
            </div>
            <div className={styles.taxdeletebtn}>
            <ButtonComponent
                    className="rnd "
                    
                  >
                    Delete
                  </ButtonComponent>
            </div>
        </div> */}
      </div>
      {loading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching GST details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div
          className={`${styles.management_details} ${styles.management_details_card_box}`}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="card-box">
                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_contents_header}>
                    <h4>
                      Tax name
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </h4>
                  </div>
                  <div className={styles.management_details_form}>
                    <InputComponent
                      className="form-control-lg"
                      error={formErrors.taxName}
                      formControl={{
                        name: "taxName",
                        value: ruleStatus,
                        readOnly: true
                      }}
                    />
                    <FormErrorText error={formErrors.taxName} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card-box">
                <div className={styles.management_details_contents}>
                  <h4>State</h4>
                  <div className={styles.management_details_form}>
                    <div className={styles.gst_tax_non_editable_select}>
                    <Select
                      mode="multiple"
                      name="regionId"
                      value={selectedRegions}
                      style={{ width: "100%" }}
                      placeholder="Select multiple state"
                      optionLabelProp="label"
                      className="form_add_select"
                      onChange={regionSelectOnchange}
                    >
                      {/* {regions.map(
                        (region) => (
                          (
                            <Option value={region._id} label={region.name}>
                              <div className="demo-option-label-item">
                                {region.name}
                              </div>
                            </Option>
                          )
                        )
                      )} */}
                    </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card-box">
                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_contents}>
                    <h4>Categories</h4>
                    <div className={styles.management_details_form}>
                    <div className={styles.gst_tax_non_editable_select}>
                      <Select
                        mode="multiple"
                        value={selectedParentCategories}
                        style={{ width: "100%" }}
                        placeholder="Select one category"
                        optionLabelProp="label"
                        className="form_add_select"
                        // onChange={handleSelectChange}
                      >
                        {/* {parentCategories.map((category) => (
                          <Option value={category._id} label={category.name}>
                            <div className="demo-option-label-item">
                              {category.name}
                            </div>
                          </Option>
                        ))} */}
                      </Select>
                      </div>
                    </div>
                  </div>
                  <div className={styles.management_details_contents}>
                    <h4>Product</h4>
                    <div className={styles.management_details_form}>
                    <div className={styles.gst_tax_non_editable_select}>
                      <Select
                        mode="multiple"
                        value={products}
                        style={{ width: "100%" }}
                        placeholder="Select one category"
                        optionLabelProp="label"
                        className="form_add_select"
                      >
                        {/* {products?.map?.length > 0 && products?.map((product) => (
                          <Option value={product} label={product}>
                            <div className="demo-option-label-item">
                              {product}
                            </div>
                          </Option>
                        ))} */}
                      </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card-box">
                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_contents}>
                    <h4>
                      Percentage
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </h4>
                  </div>
                  <div className={styles.management_details_form}>
                    <InputComponent
                      className="form-control-lg"
                      formControl={{
                        type: "text",
                        name: "percentage",
                        value: taxDetails.taxPercentage,
                        readOnly: true
                      }}
                      error={formErrors.percentage}
                    />
                    <FormErrorText error={formErrors.percentage} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 text-right">
                <div className={styles.taxsave_btn}>
                  <ButtonComponent
                    className="rnd mr-3 tranpr_bgbtn"
                    attributes={{
                      onClick: () => history.push(`/tax`),
                    }}
                  >
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    className="rnd"
                    attributes={{ onClick: addDiscount, disabled: loading }}
                    style={{ cursor: "pointer" }}
                  >
                    {loading ? "Saving" : "Save Rate"}
                  </ButtonComponent>
                </div>  
              </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
