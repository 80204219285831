import { useEffect, useRef, useState } from "react";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Tooltip,
} from "antd";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import plusIcon from "../../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../../assets/images/trash.svg";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
// import EditCategoryComponent from "./EditCategoryComponent";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { arrayMove } from "react-movable";

import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import { upperCase } from "lodash";
import * as XLSX from "xlsx";
import exportIcon from "../../../../assets/images/export_icon.svg";
import moment from "moment";

const defaultPageSize = 10;
const pagesizes = [10, 20, 50];
let initialFilterState = { pagesize: 10, nextpage: 1, status: 0, search: "" };

function getReadableDate(date) {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
  };
  return new Date(date).toLocaleString("en-US" /* , options */);
}

let initialRenderCount = 1;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Product Name", value: "Product Name" },
  { _id: 3, status: 1, name: "Image", value: "Image" },
  { _id: 4, status: 1, name: "SKU", value: "SKU" },
  { _id: 5, status: 1, name: "Pending Reviews", value: "Pending Reviews" },
  { _id: 6, status: 1, name: "Total Reviews", value: "Total Reviews" },
  // { _id: 6, status: 1, name: "Created At", value: "createdat" },
  { _id: 7, status: 1, name: "Last updated At", value: "lastupdatedat" },
  {
    _id: 8,
    status: 1,
    name: "Operate",
    value: "operate",
  },
];
const filters = [{ id: 1, isChecked: false, name: "Status", value: "Status" }];

export default function CategoryManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();

  const [ratingsData, setRatingsData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [visibility, setVisibility] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [search, setSearch] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);

  const [selectFilter, setSelectFilter] = useState(filters);
  const [statuus, setStatuus] = useState("");
  const [orderName, setrderName] = useState("All");
  const pdfExportComponent = useRef(null);
  const [weekplanexport, setWeekPlanExport] = useState([]);
  let stateStatus =
    localStorage.getItem("role") === "Admin"
      ? [
        {
          _id: 0,
          name: "All",
          value: 0,
        },
        {
          _id: 1,
          name: "Pending",
          value: 3,
        },
        {
          _id: 2,
          name: "Approved",
          value: 1,
        },
        {
          _id: 3,
          name: "Rejected",
          value: 2,
        },
      ]
      : [
        {
          _id: 0,
          name: "All",
          value: 0,
        },
        {
          _id: 1,
          name: "Pending",
          value: 3,
        },
        {
          _id: 2,
          name: "Approved",
          value: 1,
        },
        {
          _id: 3,
          name: "Rejected",
          value: 2,
        },
      ];

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Product Name",
      visible: false,
      key: "Product Name",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.name}</span>
      ),
    },
    {
      title: "Image",
      visible: false,
      key: "Image",
      render: (text, record, index) => (
        <td>
          {record.feature_image ? (
            <img
              src={record.feature_image}
              className={commonstyles.admin_table_img}
              alt="Product image"
            />
          ) : (
            "-"
          )}
        </td>
      ),
    },
    {
      title: "SKU",
      visible: false,
      key: "SKU",
      render: (text, record, index) => <td>{record.sku}</td>,
    },
    {
      title: "Pending Reviews",
      visible: false,
      key: "Pending Reviews",
      render: (text, record, index) => <td>{record.pendingReviews}</td>,
    },
    {
      title: "Total Reviews",
      visible: false,
      key: "Total Reviews",
      render: (text, record, index) => <td>{record.totalReviews}</td>,
    },

    {
      title: "Last updated At",
      visible: false,
      key: "lastupdatedat",
      render: (text, record, index) => (
        <span>{getReadableDate(record.updatedAt)}</span>
      ),
    },
    {
      title: "Operate",
      visible: false,
      key: "operate",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        ( permissionsArr.find((x) => x === "Marketing-Rating-view") === "Marketing-Rating-view" ||
        permissionsArr.find((x) => x === "Marketing-Rating-edit") === "Marketing-Rating-edit" ) && (
          // initialFilterState.status == 0 && (
          <span>
            <Tooltip placement="topRight" title="Edit ratings">
              <Link
                to={{
                  pathname: `/rating/${record._id}`,
                  state: index,
                }}
              >
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer`}
                  // onClick={() => editCategory(record)}
                  alt="Edit category"
                // title="Edit ratings"
                />
              </Link>
            </Tooltip>
          </span>
        ),
      // ),
    },
  ]);
  /**
   * @author Rajkumar
   * @param {name, value}
   * To change region
   */
  function filterRegionChange(name, value) {
    let updatedFilter = {
      pagesize: initialFilterState.pagesize,
      nextpage: initialFilterState.nextpage,
      status: value,
      search: initialFilterState.search,
    };

    if (name === "status") {
      updatedFilter.status = parseFloat(value.value);
      initialFilterState.status = parseFloat(value.value);
      setStatuus(value.value);
      setrderName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  function debounceSearch(filter) {
    filter.nextpage = 1;
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getCategories(filter);
    // }, 500);
  }

  /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("categoryList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("categoryList", JSON.stringify(cloneOrderList));
    setRatingsData(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("categoryList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    localStorage.setItem("categoryList", JSON.stringify(cloneOrderList));
    setRatingsData(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getCategories(initialFilterState);
    localStorage.removeItem("selectedCustomerr");
    return () => {
      localStorage.removeItem("categoryList");
      localStorage.removeItem("selectedCustomerr");
      initialFilterState = { pagesize: 10, nextpage: 1, status: 0, search: "" };
    };
  }, []);

  useEffect(() => {
    if (history?.isReviews) {
      initialFilterState = { pagesize: 10, nextpage: 1, status: 0, search: "" };
      getCategories(initialFilterState);
    }
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCategory(values);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewCategory = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCategory(items);
  };

  /**
   * @author Mithun Prabhu
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      pagesize: initialFilterState.pagesize,
      nextpage: initialFilterState.nextpage,
      status: initialFilterState.status,
      search: initialFilterState.search,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }

      if (item.value === "Status") {
        updatedFilter.status = 0;
        initialFilterState.status = 0;
        setStatuus("");
        setrderName("All");
      }

      debounceSearch(updatedFilter);
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>

            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Status") === "Status" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {orderName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(stateStatus)
                      ? stateStatus.map((delivery) => (
                        <span
                          key={delivery._id}
                          onClick={() =>
                            filterRegionChange("status", delivery)
                          }
                        >
                          <label>{delivery.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {Menu}
   * This is component for removing/adding columns with onClick event
   */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {value}
   * CALLING GET CATEGORY RECORDS
   */
  function getCategories(value) {
    if (initialRenderCount == 1) {
      delete initialFilterState.status;
    }

    initialRenderCount += 1;
    setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.INVOICE, "application/json")
      .post("products/pendingReview", initialFilterState)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });

            localStorage.setItem("categoryList", JSON.stringify(res.data.data));
            setRatingsData(res.data.data);
            setPagination(res.data.pagination);
          } else {
            setRatingsData([]);
          }
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          const { status } = er.response;
          // if(status === 403) {
          data.message = "Error";
          data.description = "Failed to get data";
          // } else if (status === 400){
          //   data.message
          // }
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      // setPageSize(value);
      // setNextPage(1);
      initialFilterState.pagesize = value;
      initialFilterState.nextpage = 1;

      getCategories(initialFilterState);
    } else if (type === "nextPage") {
      // setNextPage(value);
      initialFilterState.nextpage = value;

      getCategories(initialFilterState);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function searchHandler(e) {
    const { value } = e.target;

    initialFilterState.search = value;
    setSearch(value);

    if (!value) {
      initialFilterState.search = "";
      getCategories(initialFilterState);
    }
  }

  function searchClick() {
    getCategories(initialFilterState);
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Rating & Reviews
        </h1>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                className={`${commonstyles.form_control} br-7 with-icon rnd theme-txt-xsm form-control`}
                aria-label="Search"
                placeholder="Search by Product name, SKU..."
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => searchHandler(e)}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            {/* <h1 className={ `${commonstyles.sample1} ${style.sample} sample`} >Sample</h1> */}
            <div className={`${commonstyles.management_contents_search} mb-0`}>
              <Dropdown
                overlay={filterMenu}
                className="usermanage_table_filter_columns ml-3"
              >
                <div
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Filter <img src={arrowDown} width={10} height={20} />
                </div>
              </Dropdown>
              <Dropdown
                overlay={menu}
                className="usermanage_table_filter_columns ml-3"
              >
                <div
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Column <img src={arrowDown} width={10} height={20} />
                </div>
              </Dropdown>
            </div>
            <div>
              {/* <ButtonComponent
            className="theme-btn-outlined rnd mr-3"
            attributes={{
              onClick: () => {},
            }}
          >
            Export
          </ButtonComponent> */}
            </div>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading.list === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching ratings and reviews details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`OfferList for ${new Date().getFullYear()}`}
                author="Damro Team"
              >
                <div>
                  <input
                    type="checkbox"
                    className={`${commonstyles.parentCheckbox}`}
                    onChange={parentCheckbox}
                  ></input>
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: ratingsData,
                      pagination: false,
                      // onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </div>
              </PDFExport>
            )}
          </div>
        )}
      {!IsLoading.list && Array.isArray(ratingsData) && ratingsData.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Rating-view") ===
        "Marketing-Rating-view" && (
          <Pagination
            pageSizes={pagesizes}
            pageSize={initialFilterState.pagesize}
            nextPage={initialFilterState.nextpage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}

      {/* Category delete confirmation modal ends*/}
    </div>
  );
}
