import styles from "../../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
// import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
// import adminProductDelete from "../../../assets/images/admin_delete.svg";
import React, { useEffect, useRef, useState } from "react";
// import AddCustomer from "./AddCustomer";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Tooltip,
} from "antd";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import { formatServerValidtionErrors } from "../../../../utils";
import Plus from "../../../../assets/images/plus_white_icon.svg";
import { useHistory } from "react-router-dom";
import discountstyles from "../../../../styles/AdminDiscount.module.css";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import EditOrderComponent from "../../Order Management/OrderDetails";
import moment from "moment";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import { Link, useParams } from "react-router-dom";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import adminProductDelete from "../../../../assets/images/admin_delete.svg";
import SearchComponent from "../../SearchManagement/SearchComponent";
import GstList from "../Tax Management/GstList";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Tax Identifier", value: "Tax Identifier" },
  { _id: 3, status: 1, name: "Status", value: "Status" },
  { _id: 4, status: 1, name: "Percentage", value: "Percentage" },
  { _id: 5, status: 1, name: "Start Date", value: "Start Date" },
  { _id: 6, status: 1, name: "End Date", value: "End Date" },
  { _id: 7, status: 1, name: "Action", value: "action" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Active", value: true },
  { _id: 2, isChecked: false, name: "Inactive", value: false },
];

const filters = [];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function TransactionManagementComponent() {
  const history = useHistory();
const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const pdfExportComponent = useRef(null);
  const [taxList, setTaxList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [regionId, setRegionId] = useState("");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [visible, setVisible] = useState(false);
  const [payMethods, setPayMethods] = useState([
    { _id: 1, name: "CC Avenue", value: "CCAvenue" },
    { _id: 2, name: "payU", value: "payU" },
  ]);
  const [deliveryStatus, setDeliveryStatus] = useState([
    { _id: 1, name: "Pending", value: 0 },
    { _id: 2, name: "Completed", value: 1 },
  ]);
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedTax, setSelectedTax] = useState({});
  const [regionName, setRegionName] = useState("All Region");

  const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [isDeleteModule, setIsDeleteModule] = useState(false);
  const [seletedWareHuse, setSeletedWareHuse] = useState("");
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [ruleOrder, setruleOrder] = useState();
  const [percentageOrder, setpercentageOrder] = useState();
  const [currentTab, setCurrentTab] = useState("gst")

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Tax Identifier",
      visible: false,
      key: "Tax Identifier",

      render: (text, record, index) => <td>{record.taxName}</td>,
      sorter: true,
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <td>
          <span
            className={
              record.enable == 1
                ? discountstyles.status_active
                : discountstyles.status_inactive
            }
          >
            {record.enable == 1 ? "Active" : "Not Active"}
          </span>
        </td>
      ),
    },
    {
      title: "Percentage",
      visible: false,
      key: "Percentage",
      sorter: true,
      render: (text, record, index) => <td>{record.percentage}</td>,
    },
    {
      title: "Start Date",
      visible: false,
      key: "Start Date",
      render: (text, record, index) => (
        <td>{moment.utc(record.startDate).format("YYYY-MM-DD")}</td>
      ),
    },
    {
      title: "End Date",
      visible: false,
      key: "End Date",
      render: (text, record, index) => (
        <td>{moment.utc(record.endDate).format("YYYY-MM-DD")}</td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Tax-edit") ===
        "Sales-Tax-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit tax"
            // className="tooltip_list"
            >
              <Link to={`/tax/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={commonstyles.admin_table_edit_img}
                  // onClick={() => editCustomer(user)}
                  alt="Edit action icon"
                  style={{ cursor: "pointer" }}
                // title="Edit tax"
                />{" "}
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete tax"
            // className="tooltip_list"
            >
              <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deleteCustomer(record)}
                alt="Edit action icon"
                style={{ cursor: "pointer" }}
              // title="Delete tax"
              />
            </Tooltip>
          </td>
        ),
    },
  ]);
  /**
   * @author Rajkumar
   * @param {data}
   * BUILD STATUS CLICK HANDLER
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedTax")) {
      let selectedTax = JSON.parse(localStorage.getItem("selectedTax"));
      if (selectedTax.length > 0) {
        let data = {
          taxIds: selectedTax,
          status: item.value,
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the options below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the options below",
      });
    }
  };

  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/tax/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          getTransactionDetails(pageSize, nextPage);
          setIsLoading(false);

          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedTax");
          const cloneOrderList = JSON.parse(localStorage.getItem("taxList"));
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("taxList", JSON.stringify(cloneOrderList));
          setTaxList(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      // pagesize: pageSize,
      search: search,
      nextpage: 1,
      regionId: regionId,
      taxNameOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  function filterOnChange(name, value) {
    // setIsLoading(true);
    // const { name, value } = e.target;
    let updatedFilter = {
      // pagesize: pageSize,
      search: search,
      nextpage: 1,
      regionId: value._id,
      taxNameOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    debounceSearch(updatedFilter);
  }
  /**
   * @author Rajkumar
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("taxList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedTax")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedTax"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedTax", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("taxList", JSON.stringify(cloneOrderList));
    setTaxList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
   * @author Rajkumar
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedTax");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("taxList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedTax")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedTax"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedTax", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("taxList", JSON.stringify(cloneOrderList));
    setTaxList(cloneOrderList);
  };

  useEffect(() => {
    if (history?.isAdd) {
      setToggle(false);
    } else {
      setToggle(true);
    }
    set_new_header(headers);
    getTransactionDetails(pageSize, nextPage);
    localStorage.removeItem("selectedTax");
    return () => {
      localStorage.removeItem("taxList");
      localStorage.removeItem("selectedTax");
      filters?.forEach(item => {
        item.isChecked = false;
      })
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  // TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  //TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewTransaction(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewTransaction = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : 0;
    itemClone[1].status = e.target.checked ? 1 : 1;
    setItems(itemClone);
    fetchNewTransaction(items);
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            if(res.data.daaata?.length > 1) {
              let obj = { name: "All Region", value: "", _id: "" }
              res.data?.daaata?.unshift(obj);
              setRegions(res.data.daaata);
              if (!accessregion) {
                let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
                if (!checkDuplicates) {
                  filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
                }
              };
            }
          }
        })
        .catch((er) => {
          return er;
        });
    }
    getRegionList();
  }, []);

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {regionName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span key={region._id} onClick={() => filterOnChange("regionId", region)}>
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            
          </div>
        </div>
      </div>
    </Menu>
  );
  /**
   * @author Rajkumar
   * @param {pageSize, nextPage, filterarr}
   * TO GET TRANSACTION DETAILS
   */
  function getTransactionDetails(pageSize, nextPage, filterarr, type) {

    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.taxNameOrder !== "") {
      filterOptions.taxNameOrder = Number(filterarr.taxNameOrder);
    } else if (ruleOrder !== "" && ruleOrder !== null) {
      if (filterarr && ruleOrder !== "") {
        filterOptions.taxNameOrder = Number(ruleOrder);
      }
    }
    if (filterarr && filterarr.percentageOrder !== "") {
      filterOptions.percentageOrder = Number(filterarr.percentageOrder);
    } else if (percentageOrder !== "" && percentageOrder !== null) {
      if (filterarr && percentageOrder !== "") {
        filterOptions.percentageOrder = Number(percentageOrder);
      }
    }
    

    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/tax/listTax", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem("taxList", JSON.stringify(res.data.data));
          setIsLoading(false);
          setTaxList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
    };
    if (sorter?.columnKey === "Tax Identifier") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.taxNameOrder = sortValue;
      setruleOrder(sortValue);
      setpercentageOrder();
    } else if (sorter?.columnKey === "Percentage") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.percentageOrder = sortValue;
      setpercentageOrder(sortValue);
      setruleOrder();
    }
    getTransactionDetails(pageSize, nextPage, updatedFilter, true);
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      taxNameOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getTransactionDetails(value, 1, updatedFilter, type);
    } else if (type === "nextPage") {
      setNextPage(value);
      getTransactionDetails(pageSize, value, updatedFilter, type);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function editCustomer(user) {
    setSelectedCustomer(user);

    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  }

  function closeEditProduct() {
    getTransactionDetails(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  function deleteCustomer(user) {
    setSeletedWareHuse(user._id);
    setIsDeleteModule(true);
  }

  function handleInput(e, type) {
    const { value } = e.target;
    console.log(value);
    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        regionId: regionId,
        taxNameOrder: ruleOrder,
        percentageOrder: percentageOrder
      };
      debounceSearch(updatedFilter);
    }
    
  }

  function debounceSearch(filter) {
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getTransactionDetails(pageSize, 1, filter,true);

    // }, 500);
  }

  
  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    
  };

  const exportToCSV = async (e) => {

    let url = "";
    let method = "";
    if (toggle) {
      url = "admin/tax/exportLineTaxtDetails";
      method = "post";
    } else {
      url = "admin/tax/exportTax";
      method = "get";
    }
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
    [method](url)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            currentTab==="additionTax"?`Additional tax-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`:`GST tax-export-${moment(new Date())
                .format("YYYY-MM-DD")
                .split("-")
                .join("")}.xlsx`
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const toggleClickHandler = (type) => {
    setCurrentTab(type)
    setToggle(!toggle);
    getTransactionDetails(pageSize, nextPage);
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      taxNameOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    debounceSearch(updatedFilter);
  }

  function cancelDelete() {
    setSeletedWareHuse("");
    setIsDeleteModule(false);
    getTransactionDetails(pageSize, nextPage);
  }

  function confirmDelete() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/tax/deletetax/${seletedWareHuse}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: "Tax deleted successfully",
          });
          getTransactionDetails(pageSize, nextPage);
        } else if (res.data.status === 400) {
          cancelDelete();
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        cancelDelete();
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });

  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <div className={styles.management_contents}>
        <SearchComponent />
      </div>
      <div className={`${commonstyles.management_header}`}>
        <h1 className={commonstyles.management_header_heading}>Tax</h1>
        {/* <ButtonComponent
          className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
          attributes={{
            onClick: (e) => {
              exportToCSV(e);
              // setFilter(intialFilterState);
            },
          }}
        >
          <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
          Export
        </ButtonComponent> */}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Tax-view") ===
          "Sales-Tax-view" && (
            <div>
              <span className={commonstyles.management_header_downloads_text}>
                {/* <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip> */}
                <Tooltip placement="topRight" title="Download excel">
                  <img
                    src={excelIcon}
                    className={commonstyles.management_header_downloads_icon}
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToCSV(e)}
                  />
                </Tooltip>
              </span>
              <span
                className={commonstyles.management_header_downloads_text_ex}
              >
                Export <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
      </div>
      {!toggle && (
        <div className={`${commonstyles.management_header}`}>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Sales-Tax-add") ===
            "Sales-Tax-add" && (
              <ButtonComponent className={`${commonstyles.addtax_btn}`}>
                <Link to={`/tax/additionaltax`} style={{ color: "white" }}>
                  <span style={{ cursor: "pointer" }}>
                    {/*  <img src={Plus} alt="" /> */}
                    Add Additional Tax
                  </span>
                </Link>
              </ButtonComponent>
            )}
        </div>
      )}
      <div
        className={`${commonstyles.management_header_addtax} ${commonstyles.management_header}`}
      >
        {/* <ButtonComponent className={`${commonstyles.addtax_btn}`}>
          <Link to={`/tax/add`} style={{ color: "white" }}>
            <span style={{ cursor: "pointer" }}>
              <img src={Plus} alt="" />
              Add Tax
            </span>
          </Link>
        </ButtonComponent> */}
        <button
          className={`${commonstyles.taxButton} 
            ${toggle && commonstyles.taxButton_active} 
              d-inline-block`}
          onClick={() => toggleClickHandler("gst")}
        >
          GST
        </button>
        <button
          className={`${commonstyles.taxButton} 
            ${!toggle && commonstyles.taxButton_active} 
              d-inline-block`}
          onClick={() => toggleClickHandler("additionTax")}
        >
          Additional Tax
        </button>
        {/* {!toggle&&<ButtonComponent className={`${commonstyles.addtax_btn}`}>
          <Link to={`/tax/additionaltax`} style={{ color: "white" }}>
            <span style={{ cursor: "pointer" }}>
              <img src={Plus} alt="" />
             Add Additional Tax
            </span>
          </Link>
        </ButtonComponent>} */}
      </div>

      {!toggle ? (
        <>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Sales-Tax-view") ===
            "Sales-Tax-view" && (
              <div
                className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
                style={{ marginTop: "20px" }}
              >
                <div
                  className={`${commonstyles.management_contents_search_bar} input-group`}
                >
                  <InputComponent
                    className="with-icon rnd theme-txt-xsm"
                    formControl={{
                      placeholder: "Search by Tax Identifier...",
                      name: "search",
                      style: { height: "38px" },
                      value: search,
                      onChange: handleInput,
                      ["aria-label"]: "Search category",
                      ["aria-describedby"]: "search-category",
                    }}
                  />
                  <img src={Search} alt="" />
                </div>
                <ButtonComponent
                  className="theme-btn rnd pl-3 pr-4 ml-2"
                  attributes={{
                    onClick: () => {
                      searchClick();
                    },
                  }}
                >
                  Search
                </ButtonComponent>
                <div className="action ml-3">
                  <div className="action_header">
                    Action
                    <img src={arrowDown} alt="image" />
                  </div>
                  <div className="action_contents">
                    {bulkActionFilter.length > 0 &&
                      bulkActionFilter.map((item) => (
                        <div
                          className="action_contents_header"
                          key={item._id}
                          onClick={() => bulkStatusClickHandler(item)}
                        >
                          <h2
                            className={`action_contents_header_h2 ${item.isChecked &&
                              "action_contents_header_h2_active"
                              }`}
                          >
                            {item.name}
                          </h2>
                          {item.isChecked && (
                            <span
                              className="action_contents_btn"
                              onClick={() => applyBulkActionHandler(item)}
                            >
                              Apply
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                {selectFilter?.length > 0 && <Dropdown
                  overlay={filterMenu}
                  onVisibleChange={handleVisibleChange} visible={visible}
                  className="usermanage_table_filter_columns ml-3"
                >
                  <div
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Filter <img src={arrowDown} width={10} height={20} />
                  </div>
                </Dropdown>}
                <Dropdown
                  overlay={menu}
                  className="usermanage_table_filter_columns ml-3"
                >
                  <div
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Column <img src={arrowDown} width={10} height={20} />
                  </div>
                </Dropdown>
              </div>
            )}
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Sales-Tax-view") ===
            "Sales-Tax-view" && (
              <div
                className={`${commonstyles.management_table} table-responsive`}
              >
                {isLoading === true ? (
                  <div
                    style={{
                      marginLeft: "70vh",
                      marginTop: "10vh",
                    }}
                  >
                    <tr className="bg-transparent">
                      <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching additional tax details..." />
                      </td>
                    </tr>
                  </div>
                ) :
                  (
                    <PDFExport
                      ref={pdfExportComponent}
                      paperSize="auto"
                      margin={40}
                      fileName={`TaxList for ${new Date().getFullYear()}`}
                      author="Damro Team"
                    >
                      <div>
                        <input
                          type="checkbox"
                          className={`${commonstyles.parentCheckbox}`}
                          onChange={parentCheckbox}
                        ></input>
                        <AntTableComponent
                          className="order_table"
                          tableProps={{
                            columns: columns,
                            dataSource: taxList,
                            pagination: false,
                            onChange: handleTableChange,
                            components: {
                              header: {
                                row: (props) => renderHeader(props),
                              },
                              body: {
                                row: (props) => renderBody(props),
                              },
                            },
                          }}
                        />
                      </div>
                    </PDFExport>
                  )}
              </div>
            )}
          {!isLoading && Array.isArray(taxList) && taxList.length
            ? permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Sales-Tax-view") ===
            "Sales-Tax-view" && (
              <Pagination
                pageSizes={pageSizes}
                pageSize={pageSize}
                nextPage={nextPage}
                data={pagination}
                handlePaginationChanges={handlePaginationChanges}
              />
            )
            : null}
        </>
      ) : (
        <GstList />
      )}

      <ConfirmationBox
        modal={{
          show: isDeleteModule,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
}