import { useEffect, useState } from "react";
import { notification, Select } from "antd";
// import AddCategoryComponent from "./AddCategoryComponent";
import blackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import markerIcon from "../../../../assets/images/markerIcon.svg";
import commonstyles from "../../../../styles/Management_Common.module.css";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ImageMarker from "react-image-marker";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import CloseIconSmall from "../../../../assets/images/Close.svg";
import MinimizeIcon from "../../../../assets/images/Minimize.svg";
import exclusionIcon from "../../../../assets/images/exclusion.svg";
import validationRules from "../../../../utils/validationRules";
import RelatedDecorSelectFormComponent from "../../../modules/ProductManagement/RelatedDecorSelectFormComponent";
import ProductSearchSelectComponent from "../../../modules/ProductManagement/ProductSearchSelectComponent";
import CategorySearchSelectComponent from "../../../modules/ProductManagement/CategorySearchSelectComponent";
import WYSIWYGEditor from "../../../modules/ReusableComponent/WYSIWYGEditor";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import React from "react";
import {
  alphanumericwithdashHyphen,
  alphanumericwithdashHyphenWithLimit,
  minChar,
} from "../../../../utils/RegularExp";
import RelatedProductSelectFormComponent from "../../../modules/ProductManagement/RelatedProductSelectFormComponent";
import moment from "moment-timezone";

const pageSizes = [5, 10, 15];
const defaultPageSize = 10;
const { Option } = Select;
function getReadableDate(date) {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
  };
  return new Date(date).toLocaleString("en-US" /* , options */);
}

export default function CreateDecorComponent(props) {
  const initialdecorDetailsForm = () => {
    return {
      parentId: [],
      description: "",
      category: "",
      similarDecors: [],
      // warranty: "",
    };
  };
  function initialState() {
    return {
      // enable: true,
      title: "",
      slug: "",
      description: "",
      category: "",
      regionId: "",
      similarDecors: [],
      data: [],
      featurePieces: []
    };
  }
  const initialFilterState = { search: "", pagesize: 10, nextpage: 1 };
  const [filter, setFilter] = useState(initialFilterState);
  const [newDecor, setnewDecor] = useState(initialState());
  const [categoryData, setCategoryData] = useState([]);
  const [decorDetailsForm, setdecorDetailsForm] = useState(
    initialdecorDetailsForm()
  );
  const [regions, setRegions] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [productId, setproductId] = useState([]);
  const [currentMarker, setcurrentMarker] = useState([]);
  const [similarDecors, setSimilarDecors] = useState([]);
  const [decorUpdate, setdecorUpdate] = useState({});
  const [files, setFiles] = useState({
    image: null,
    icon: null,
    menuImage: null,
  });
  const [filePreview, setFilePreview] = useState({
    image: null,
    icon: null,
    menuImage: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const rules = {
    title: [validationRules.required],
    slug: [validationRules.required],
    description: [validationRules.required],
    category: [validationRules.required],
    regionId: [validationRules.required],
    files: [(val, data) => (!files.image ? "Required" : true)],
  };
  const [displayStatus, setDisplayStatus] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = history.location;
  const [regionalProducts, setRegionalProducts] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const { state } = history.location;
    if (state?._id || id) {
      getParentProducts();
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    getParentProducts();
  }, []);

  const [products, setProducts] = useState([]);

  /**
  * @author Mithun Prabhu
  * @param {}
  * GET ALL PARENT PRODUCT DETAILS
  */
  const getParentProducts = () => {
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .get("/admin/catalog/getSimpleProductList/")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setProducts(res.data.data);
        }
      })
      .catch((er) => {
        console.log("getParentProducts error", er);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getRegionList();
    if (id) {
      fetch_details(id);
    } else {
      getDecorList(filter, "");
    }
    // getProductListByRegionId([]);
  }, [filter.pagesize, filter.nextpage]);

  // useEffect(() => {
  //   if(newDecor?.featurePieces?.length>0 && !newDecor?.featurePieces[0]?._id) {
  //     getProductListByRegionId([], newDecor);
  //   }
  // }, [newDecor])

  const editorOptions = {
    wrapperClassName: `${commonstyles.form_control} ${formErrors.description && "theme-error-input"
      } form-control-lg p-1 h-auto`,
    toolbarClassName: "bg-light rounded-lg theme-txt-sm",
    editorClassName: "px-2 mt-3 mb-1 ",
    placeholder: "Type description",
    toolbar: {
      options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "emoji",
        "link",
        "textAlign",
        "colorPicker",
        // "embedded",
        // "remove",
        "history",
      ],

      blockType: {
        inDropdown: true,
        options: [
          "Normal",
          "H1",
          "H2",
          "H3",
          "H4",
          "H5",
          "H6",
          "Blockquote",
          "Code",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },

      fontSize: {
        icon: undefined,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: undefined,
      },

      fontFamily: {
        options: [
          "Arial",
          "Georgia",
          "Impact",
          "Spartan",
          "Tahoma",
          "Times New Roman",
          "Verdana",
        ],
        className: "theme-txt-sm",
        component: undefined,
        dropdownClassName: "",
      },
    },
  };

  function fetch_details(id) {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get(`/admin/catalog/decorDetails/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let data = res.data.data;
          setdecorUpdate(data)
          getDecorList(filter, data);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        res.data.daaata.unshift({name:"All Region",_id:"0486fab6a2c4851da688ed95"})
        setRegions(res.data.daaata);
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }


  /**
  * @author Mithun Prabhu
  * @param {Event}
  * GET IMAGE INPUT
  */
  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;
    if (name) {
      if (filePreview[name]) {
        URL.revokeObjectURL(filePreview[name]);
      }
      const url = URL.createObjectURL(inputFiles[0]);
      setFiles({ ...files, [name]: inputFiles[0] });
      setFilePreview({ ...filePreview, [name]: url });
    }
  }
  function AddMarkers(marker) {
    setMarkers([...markers, marker]);
  }

  /**
  * @author Mithun Prabhu
  * @param {EVENT}
  * HANDLE INPUT VALUES WITH ERROR MESSAGES
  */
  function handleInput(e) {
    // const { name, value, checked } = e.target;

    let { name, value, checked } = e.target;
    let modifiedValue = value.trim();
    //ERRORS
    if (name === "title") {
      if (!e.target.value.match(minChar) || !e.target.value === "") {
        formErrors.title = "Enter minimum 3 letters";
      } else {
        formErrors.title = "";
      }
    }

    if (name === "slug") {
      value = value.trim();
      // alert(value.length>3 && "yes")
      if (!value.match(alphanumericwithdashHyphenWithLimit)) {
        // alert('error')
        formErrors.slug = "Enter minimum 3 letters or Incorrect match";
      } else {
        formErrors.slug = "";
      }
    }

    if (name === "title") {
      if (modifiedValue) {
        setnewDecor({ ...newDecor, [name]: value });
      } else {
        setnewDecor({ ...newDecor, [name]: "" });
      }
    } else if (name === "slug") {
      setnewDecor({ ...newDecor, [name]: value });
    }
  }

  /**
  * @author Mithun Prabhu
  * @param {}
  * CONVERTING ALL JSON OBJECTS STRINGS
  */
  function createRequestData() {
    const data = JSON.parse(JSON.stringify(newDecor));
    if (state?._id || id) {
      data._id = state?._id || id;
    }
    if (data.title) {
      if (!minChar.test(data.title) || !data.title === "") {
        formErrors.title = "Enter minimum 3 letters";
      } else {
        formErrors.title = "";
      }
    }
    if (data.slug) {
      if (
        !alphanumericwithdashHyphenWithLimit.test(data.slug) ||
        !data.slug === ""
      ) {
        setFormErrors({
          ...formErrors,
          slug: "Enter min 3 letters or Incorrect match",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, slug: "" });
      }
    }
    data.similarDecors = Array.isArray(data.similarDecors)
      ? data.similarDecors.map((prdct) => prdct._id)
      : [];
    data.featurePieces = Array.isArray(data.featurePieces)
    ? data.featurePieces.map((prdct) => prdct._id)
    : [];
    data.slug = data.slug.trim();
    let arr = [];
    const unique = [
      ...new Map(
        currentMarker.map((item) => [item["position"], item])
      ).values(),
    ];
    markers.forEach((value, key) => {
      unique.forEach((cvalue, ckey) => {
        let datas = cvalue.position.split(",");
        if (
          String(value["top"]) === datas[0] &&
          String(value["left"]) === datas[1]
        ) {
          arr.push({
            productId: cvalue.productId,
            top: Number(value["top"]),
            left: Number(value["left"]),
          });
        }
      });
    });
    data.data = arr;
    return data;
  }
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  /**
  * @author Mithun Prabhu
  * @param {}
  * SUBMIT WHOLE DATA INTO API
  */
  function submit() {
    setDisplayStatus(true);
    setFormErrors({});
    const data = createRequestData();
    const result = runValidationChecks(rules, undefined, { ...data, files });
    if (Object.keys(result).length) {
      setDisplayStatus(false);
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    if (data.data.length == 0) {
      setDisplayStatus(false);
      showNotification({
        type: "warning",
        message: "No decor products selected",
        description: "Please select product by clicking image",
      });
      return;
    }
    data['featurePieces'] = [];
    let filesData = {};
    filesData["image"] = files["image"];
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("image", files["image"]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // accept: "*/*",
      },
    };
    AxiosInstance(PORTS.CATALOG_SERVICE)
      .post(
        `/admin/catalog/${state?._id || id ? "updateDecor" : "addDecor"}`,
        formData,
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          setDisplayStatus(false);
          showNotification({
            type: "success",
            message: "Success",
            //description: res.data.msg,
            description:
              state?._id || id
                ? "Collection details updated successfully"
                : "Collection details added successfully",
          });
          // props.handleClose(true);
          history.push(`/catalog/decors`);
        } else if (res.data.status === 400) {
          // const errors = formatServerValidtionErrors(res.data.error);
          // setFormErrors(errors);
          showNotification({
            type: "error",
            message: "Adding decor failed",
            description: res.data?.msg || "Error occured",
          });
        }
        setDisplayStatus(false);
      })
      .catch((er) => {
        setDisplayStatus(false);
        showNotification({
          type: "error",
          message: "Adding decor failed",
          description: "Error occured",
        });
      });
  }

  const collapse = (id) => {
    document.getElementById(`viewProduct_${id}`).style = "display:block";
    document.getElementById(`hideProduct_${id}`).style = "display:none";
  };

  const expand = (id) => {
    document.getElementById(`viewProduct_${id}`).style = "display:none";
    document.getElementById(`hideProduct_${id}`).style = "display:block";
  };

  const remove = (id) => {
    let data = id.split(",");
    let markerarr = markers;
    markerarr = markerarr.filter((val) => !((parseFloat(val.top) === parseFloat(data[0])) && (parseFloat(val.left) === parseFloat(data[1]))));
    setMarkers(markerarr);
  };

  function handleParentIdUpdate(val, id) {
    productId[id] = val;
    setproductId(productId);
    const list = [...currentMarker];
    list[id] = val;
    setcurrentMarker([...currentMarker, { position: id, productId: val }]);
  }

  function handleCategoryIdUpdate(value) {
    setnewDecor({ ...newDecor, category: value });
  }
  function handleRegionIdUpdate(value) {
    setnewDecor({ ...newDecor, regionId: value });
  }

  function handleEditorUpdate(htmlContent) {
    const values = newDecor;
    values.description = htmlContent;
    setnewDecor(values);
  }

  function getProductListByRegionId(ids = [], newDecordata) {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getProductsList/", { regionId: ids })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegionalProducts(res.data.data);
          if(id && res?.data?.data?.length > 0) {
            let arr = [];
            res.data.data.forEach(item => {
              newDecordata?.featurePieces?.forEach(subItem => {
                if(item?._id === subItem) {
                  arr.push(item);
                }
              })
            })
            setnewDecor({ ...newDecor, featurePieces: arr });
          }
        }
      })
      .catch((er) => {
        return er
      });
  }

  const [initialStates, setInitialStates] = useState(initialState());

  useEffect(() => {
    setnewDecor(initialStates);
  },[initialStates])


  function getDecorList(filter, params) {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getDecorList", filter)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let data = res.data.data,
            arr = [];
          setSimilarDecors(data);
          if (params._id) {
            setMarkers(params.decor);
            setFilePreview({ image: params.image });
            if (params.similarDecors.length > 0) {
              data.map((item) => {
                params.similarDecors.map((subItm) => {
                  if (subItm === item._id) {
                    arr.push(item);
                  }
                });
              });
            }
            let initialState = {
              title: params.title,
              slug: params.slug,
              description: params.description || "",
              category: params.category,
              regionId: params.regionId,
              similarDecors: arr || [],
              featurePieces: params.featurePieces || []
            };
            setFiles({ ...files, image: params.image });
            setInitialStates(initialState);
            // setTimeout(() => {
            // }, 200)
            let productIds = [];
            let mar_arr = [];
            let current_arr = [];
            params.decor.map((prd, index) => {
              let val = Number(prd.top) + "," + Number(prd.left);
              productIds[val] = prd.productId;
              let obj = {};
              let obj1 = {};
              obj.top = prd.top;
              obj.left = prd.left;
              mar_arr.push(obj);
              obj1.position = prd.top + "," + prd.left;
              obj1.productId = prd.productId;
              current_arr.push(obj1);
            });
            setMarkers(mar_arr);
            setcurrentMarker(current_arr);
            setproductId(productIds);
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  /**
 * @author Mithun Prabhu
 * @param {action, decor, formField}
 * UPDATE SELECTED PRODUCTS FOR PARTICULAR DECOR
 */
  const CustomMarker = (props) => {
    let data = props;
    return (
      <>
        <div
          className={`${commonstyles.management_details_form}`}
          id={`hideProduct_${data.top + "," + data.left}`}
          style={{ width: "120%" }}
        >
          <div className={`${commonstyles.popup_justify}`}>
            <div>
              <label htmlFor="" className={commonstyles.secondry_title}>
                Choose Product
              </label>
            </div>
            <div>
              <img
                src={MinimizeIcon}
                className={`${commonstyles.close_icon} mr-1 cursor-pointer`}
                alt="CloseIcon"
                title="Minimize"
                onClick={() => {
                  collapse(data.top + "," + data.left);
                }}
              />
              <img
                src={CloseIconSmall}
                className={`${commonstyles.close_icon} cursor-pointer`}
                alt="CloseIcon"
                title="Remove"
                onClick={() => {
                  remove(data.top + "," + data.left);
                }}
              />
            </div>
          </div>
          <ProductSearchSelectComponent
            type="decor"
            isLoading={isLoading}
            products={products}
            error={formErrors.parentId}
            value={productId[data.top + "," + data.left] || ""}
            id={props.top ? props.top + "," + props.left : ""}
            handleParentIdUpdate={handleParentIdUpdate}
          />
          <FormErrorText error={formErrors.parentId} />
        </div>
        <div
          className={` col-lg-12 ${commonstyles.dispNone}`}
          id={`viewProduct_${data.top + "," + data.left}`}
        >
          <img
            src={markerIcon}
            alt="test"
            onClick={() => {
              expand(data.top + "," + data.left);
            }}
          />
        </div>
      </>
    );
  };

  /**
  * @author Mithun Prabhu
  * @param {action, decor, formField}
  * UPDATE SELECTED DECORS
  */
  function updateSelectedDecors(action, decor, formField) {
    const maxLength = { youmaylike: 4, similarDecors: 4, boughtTogether: 2, featurePieces: 10 };

    const updatedValues = newDecor[formField];
    if (["add", "remove"].includes(action)) {
      const index = updatedValues.findIndex((v) => v._id === decor._id);
      if (action === "add" && updatedValues.length < maxLength[formField]) {
        if (index === -1) {
          updatedValues.push(decor);
          setnewDecor({ ...newDecor, [formField]: updatedValues });
        }
      } else {
        if (index > -1) {
          updatedValues.splice(index, 1);
          setnewDecor({ ...newDecor, [formField]: updatedValues });
        }
      }
    }
  }
  function updateComponentVisibility(e) { }
  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={blackLeftArrow}
            onClick={() => history.push(`/catalog/decors`)}
            className="cursor-pointer"
            title="Back"
            alt="Back action image"
          />
          {state?._id || id ? "Edit Collection" : "Add Collection"}
        </h1>
        <div className={`edit_category_update_user_date`}>
            {decorUpdate?.lastUpdatedBy && (
              <p>
                Last update by: <span>{decorUpdate?.lastUpdatedBy}</span>
              </p>
            )}
            {decorUpdate?.updatedAt && (
              <p>
                Last update on:{" "}
                <span>
                  {moment(decorUpdate?.updatedAt).format(
                    "DD/MM/YYYY | hh:mm A"
                  )}
                </span>
              </p>
            )}
          </div>
        <div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              // onClick: props.handleClose,
              onClick: () => history.push(`/catalog/decors`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd pl-3 pr-4"
            attributes={{
              onClick: submit,
              disabled: displayStatus,
            }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching decor details..." />
            </td>
          </tr>
        </div>
      ) : (
        <React.Fragment>
          {/* <h1 className={ `${commonstyles.sample1} ${style.sample} sample`} >Sample</h1> */}
          <div className={commonstyles.management_details}>
            <div className={commonstyles.management_details_contents}>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <label>
                    Title{" "}
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control-lg"
                    error={formErrors.title}
                    formControl={{
                      placeholder: "Type Collection Name",
                      name: "title",
                      value: newDecor.title,
                      onChange: handleInput,
                    }}
                  />
                  <FormErrorText error={formErrors.title} />
                </div>
                <div className="col-lg-6 mb-4">
                  <label>
                    URL Slug{" "}
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control-lg"
                    error={formErrors.slug}
                    formControl={{
                      placeholder: "Type URL Slug",
                      name: "slug",
                      value: newDecor.slug,
                      onChange: handleInput,
                    }}
                  />
                  <FormErrorText error={formErrors.slug} />
                </div>
                <div className="col-lg-12 mb-4">
                  <div
                    className={`${commonstyles.management_details_form} ml-0`}
                  >
                    <label htmlFor="">
                      Description{" "}
                      {/* <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span> */}
                    </label>
                    {(state?._id || id) && newDecor.description && (
                      <WYSIWYGEditor
                        content={newDecor.description || ""}
                        options={editorOptions}
                        onEditorStateChange={handleEditorUpdate}
                      />
                    )}
                    {!((state?._id || id) && newDecor.description) && (
                      <WYSIWYGEditor
                        content={newDecor.description || ""}
                        options={editorOptions}
                        onEditorStateChange={handleEditorUpdate}
                      />
                    )}
                    {/* <FormErrorText error={formErrors.description} /> */}
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <h4 className="mr-4">Similar decors</h4>
                  <div
                    className={`${commonstyles.management_details_form} ml-0`}
                  >
                    <RelatedDecorSelectFormComponent
                      title="Similar Collections"
                      formField="similarDecors"
                      isVisible={true}
                      handleComponentVisibility={updateComponentVisibility}
                      decors={similarDecors}
                      selecteddecors={newDecor.similarDecors}
                      updateSelecteddecors={updateSelectedDecors}
                    />
                  </div>
                </div>
                {/* <RelatedProductSelectFormComponent
                  title="featured products"
                  formField="featurePieces"
                  isVisible={true}
                  isToggle={true}
                  handleComponentVisibility={updateComponentVisibility}
                  products={regionalProducts}
                  selectedProducts={newDecor.featurePieces}
                  updateSelectedProducts={updateSelectedDecors}
                /> */}
              </div>

              <div className="row ">
                <div className="col-lg-6 mb-5">
                  <div
                    className={`${commonstyles.management_details_form} ml-0`}
                  >
                    <label htmlFor="">
                      Category{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <CategorySearchSelectComponent
                      error={formErrors.category}
                      value={newDecor.category || ""}
                      handleCategoryIdUpdate={handleCategoryIdUpdate}
                    />
                  </div>
                  <FormErrorText error={formErrors.category} />
                </div>
                {/* Region select*/}
                <div className="col-lg-6 mb-4">
                  <div
                    className={`${commonstyles.management_details_form} ml-0`}
                  >
                    <label htmlFor="">
                      Warehouse{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      className={`${commonstyles.form_control}  ${formErrors.regionId && "theme-error-input"
                        } form-control form-control-lg p-1`}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      bordered={false}
                      name="regionId"
                      showSearch
                      placeholder="Search Region"
                      value={newDecor.regionId}
                      onChange={handleRegionIdUpdate}
                    >
                      {Array.isArray(regions)
                        ? regions.map((region) => (
                          <Option key={region._id} value={region._id}>
                            {region.name}
                          </Option>
                        ))
                        : null}
                    </Select>
                  </div>
                  {/* Region select ends */}
                  <FormErrorText error={formErrors.regionId} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <h4>
                    Add Image{" "}
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </h4>
                  <div
                    className={`${commonstyles.management_details_contents}`}
                  >
                    <div
                      className={`${commonstyles.management_details_form} ${commonstyles.add_image_card
                        } ${formErrors.files ? "theme-error-blk" : ""
                        } d-flex align-items-center justify-content-center  ml-0`}
                    >
                      <div
                        className={`${commonstyles.upload_img} border-dote text-center`}
                      >
                        <img className="mb-2 mt-2" src={exclusionIcon} alt="" />
                        <div>
                          <label
                            htmlFor="imageInput"
                            className={`${commonstyles.btn} px-2 cursor-pointer`}
                          >
                            Add image
                          </label>
                          <input
                            name="image"
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="imageInput"
                            onInput={handleImageInput}
                          />
                        </div>
                        <span>or drop a file to Upload</span>
                      </div>
                    </div>
                    <FormErrorText error={formErrors.files} />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className={commonstyles.decor_banner_img}>
                    {filePreview.image ? (
                      <ImageMarker
                        src={filePreview.image}
                        className={commonstyles.imagemarkerWidth}
                        markers={markers}
                        onAddMarker={(marker) => AddMarkers(marker)}
                        markerComponent={CustomMarker}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}