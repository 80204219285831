import styles from "../../../../styles/OrderDetails.module.css";
import React, { useEffect, useRef, useState } from "react";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import BrownSofa from "../../../../assets/images/brown_sofa.png";
import infoIcon from "../../../../assets/images/refund_breakup_info_icon.png";
// import Setty from "../../../../assets/images/Group 1001.png";
// import CofeeTable from "../../../../assets/images/Group 11770.png";
import SearchComponent from "../../SearchManagement/SearchComponent";
import circleOne from "../../../../assets/images/circle_one.svg";
import circleTwo from "../../../../assets/images/circle_two.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
// import infoIcon from "../../../../assets/images/info_icon.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import { notification, Dropdown, Menu, Tooltip } from "antd";
import { Accordion, Card, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import RefundCouponModal from "./RefundCouponModal";
import validationRules from "../../../../utils/validationRules";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { useHistory, useParams } from "react-router";

const refundReasons = [
    { id: 0, isChecked: false, name: "We are sorry! your request is not a Valid reason", value: "We are sorry! your request is not a Valid reason" },
    { id: 1, isChecked: false, name: "Your refund request has been accepted", value: "Your refund request has been accepted" },
    { id: 2, isChecked: false, name: "Your partial order refund request is accepted", value: "Your partial order refund request is accepted" },
    { id: 3, isChecked: false, name: "Something went wrong! can't process your refund", value: "Something went wrong! can't process your refund" },
    { id: 4, isChecked: false, name: "Sorry! this product is not returnable", value: "Sorry! this product is not returnable" },
    { id: 5, isChecked: false, name: "Sorry! we reject this refund please contact if any query", value: "Sorry! we reject this refund please contact if any query" },
    { id: 6, isChecked: false, name: "Rejected", value: "Rejected" }
];

export default function RefundDetailComponent() {
    const history = useHistory();
    const { id } = useParams();
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refundDetailsData, setRefundDetailsData] = useState([]);
    const [allAmounts, setAllAmounts] = useState({
        couponAmount: '',
        refundCharges: '',
        subTotal: '',
        standardSubTotal: '',
    });
    const [couponDetails, setCouponDetails] = useState([]);

    useEffect(() => {
        refundDetails();
    }, []);
    /**
   * @author Rajkumar
   * FETCH REFUND DETAILS
   */
    const refundDetails = () => {
        setIsFetching(true);
        AxiosInstance(PORTS.INVOICE, "application/json").get(`orders/getRefundDetails/${id}`).then((res) => {
            if (res.data.status === 200) {
                setIsFetching(false);
                setIsLoading(false);
                let cloneAllAmounts = { ...allAmounts };
                if(!res.data?.data[0]?.refundType) {
                    res.data.data[0].refundType = res.data?.data[0]?.requestType
                }
                if (res.data?.data[0]?.status === 2) {
                    let prds = res.data?.data[0]?.products;
                    let prd_refundDetails = res.data?.data[0]?.refundDetails;
                    let product_details = [];
                    if (prds?.length > 0) {
                        prds.forEach(item => {
                            if (prd_refundDetails?.products?.length > 0) {
                                prd_refundDetails?.products?.forEach(subItem => {
                                    if (item?.suborderId === subItem?.productId) {
                                        item.isChecked = true;
                                        item['choosenReason'] = subItem?.reason;
                                        item['reason'] = subItem?.reason;
                                        item['description'] = subItem?.description? subItem?.description : item?.description
                                        item.adminDescription = subItem?.description;
                                        item.refundAmount = subItem?.price;
                                        item['customQuantity'] = parseFloat(subItem?.approvedQuantity);
                                    }
                                })
                            }
                            let obj = {};
                            obj.suborderId = item.suborderId
                            obj.quantity = item.quantity
                            product_details.push(obj);
                            item.order.products.couponDiscount = parseFloat(item.order?.products?.couponDiscount)
                            item.order.products.subtotal = parseFloat(item.order?.products?.subtotal)
                            let actSellPrice = item.order?.products?.sellPrice ? item.order?.products?.sellPrice : item.order?.products?.subtotal
                            item.order.products.subtotalPerQty = (parseFloat(actSellPrice) / parseFloat(item.order?.products?.quantity)) * parseFloat(item?.quantity);
                        });
                    };
                    let params = {
                        orderId: res.data?.data[0]?.orderId,
                        products: product_details
                    }
                    AxiosInstance(PORTS.INVOICE, "application/json").post(`/orders/getCouponAmount`, params).then((res) => {
                        if (res.data.status === 200) {
                            cloneAllAmounts.couponAmount = res?.data?.data?.reduce(function (prev, current) {
                                return prev + +current.couponAmount
                            }, 0);
                            setCouponDetails(res?.data?.data);
                            cloneAllAmounts.refundCharges = prd_refundDetails?.refundCharges ? parseFloat(prd_refundDetails?.refundCharges) : 0;
                            cloneAllAmounts.subTotal = parseFloat(prd_refundDetails?.grandTotal);
                            cloneAllAmounts.standardSubTotal = parseFloat(cloneAllAmounts?.subTotal);                        
                            setAllAmounts(cloneAllAmounts);
                        } else if (res.data.status === 400) {
                            setIsFetching(false);
                        }
                    })
                } else {
                    let product_details = [];
                    let prds = res.data?.data[0]?.products;
                    if (prds?.length > 0) {
                        prds.forEach(item => {
                            item['customQuantity'] = parseFloat(item?.quantity);
                            item.isChecked = true;
                            item['choosenReason'] = [...refundReasons];
                            item['reason'] = [];
                            item.adminDescription = '';
                            item.order.products.couponDiscount = parseFloat(item.order?.products?.couponDiscount)
                            item.order.products.subtotal = parseFloat(item.order?.products?.subtotal)
                            // if(res.data?.data[0]?.requestType === 1) {
                            if (item?.order?.products?.sellPrice > 0) {
                                item.refundAmount = (parseFloat(item.order?.products?.sellPrice)) * parseFloat(item?.quantity);
                                item.order.products.subtotalPerQty = (parseFloat(item.order?.products?.sellPrice)) * parseFloat(item?.quantity);
                            } else {
                                item.refundAmount = (parseFloat(item.order?.products?.actPrice)) * parseFloat(item?.quantity);
                                item.order.products.subtotalPerQty = (parseFloat(item.order?.products?.actPrice)) * parseFloat(item?.quantity);
                            }
                            // } else {
                            //     item.refundAmount = (parseFloat(item.order?.products?.subtotal) / parseFloat(item.order?.products?.quantity)) * parseFloat(item?.quantity);
                            //     item.order.products.subtotalPerQty = (parseFloat(item.order?.products?.subtotal) / parseFloat(item.order?.products?.quantity)) * parseFloat(item?.quantity);
                            // }
                            let obj = {};
                            obj.suborderId = item.suborderId
                            obj.quantity = item.quantity
                            product_details.push(obj);
                        });
                    };
                    let params = {
                        orderId: res.data?.data[0]?.orderId,
                        products: product_details
                    }
                    fetchCouponDetails(params, prds, res.data?.data[0]);
                }
                setRefundDetailsData(res.data.data[0]);
            } else if (res.data.status === 400) {
                // setIsFetching(false);
            }
        })
            .catch((er) => {
                // setIsFetching(false);
                setIsLoading(false);
                showNotification({
                    type: "error",
                    message: "Failed",
                    description: "Error occured",
                });
            })
            .finally(() => {
                // setIsFetching(false);
                setIsLoading(false);
            });
    }
    /**
     * @author Rajkumar
     * @param {params, prds, res_data, _subTotal}
     * FETCH COUPON DETAILS
     */
    function fetchCouponDetails(params, prds, res_data, _subTotal) {
        let cloneAllAmounts = { ...allAmounts };
        AxiosInstance(PORTS.INVOICE, "application/json").post(`/orders/getCouponAmount`, params).then((res) => {
            if (res.data.status === 200) {
                setCouponDetails(res?.data?.data);
                cloneAllAmounts.couponAmount = res?.data?.data?.reduce(function (prev, current) {
                    return prev + +current.couponAmount
                }, 0);
                let totalSubtotalAmount = prds.reduce(function (prev, current) {
                    return prev + +current.order?.products?.subtotal
                }, 0);
                let totalSubtotalAmountPerQty = prds.reduce(function (prev, current) {
                    return prev + +current.order?.products?.subtotalPerQty
                }, 0);
                if (res_data?.requestType === 0 && res_data?.status === 1) {
                    cloneAllAmounts.refundCharges = cloneAllAmounts?.refundCharges? cloneAllAmounts.refundCharges : (res_data?.refundDetails?.refundCharges ?
                        res_data?.refundDetails?.refundCharges : 0);
                } else {
                    cloneAllAmounts.refundCharges = cloneAllAmounts?.refundCharges? cloneAllAmounts.refundCharges : (res_data?.refundCharges?.refundCharges ?
                        res_data?.refundCharges?.refundCharges : 0);
                }
                if (_subTotal) {
                    cloneAllAmounts.subTotal = _subTotal - cloneAllAmounts?.couponAmount;                                    
                } else {
                    cloneAllAmounts.subTotal = (totalSubtotalAmountPerQty - cloneAllAmounts?.couponAmount -
                        cloneAllAmounts?.refundCharges)
                }
                cloneAllAmounts.standardSubTotal = (totalSubtotalAmount - cloneAllAmounts?.couponAmount -
                    cloneAllAmounts?.refundCharges);
                setAllAmounts(cloneAllAmounts);
            } else if (res.data.status === 400) {
                setIsFetching(false);
            }
        })
            .catch((er) => {
                setIsFetching(false);
                setIsLoading(false);
                showNotification({
                    type: "error",
                    message: "Failed",
                    description: "Error occured",
                });
            })
            .finally(() => {
                setIsFetching(false);
                setIsLoading(false);
            });
    }
    /**
     * @author Rajkumar
     * @param {type, message, description}
     * SHOW NOTIFICATION FUNCTION
     */
    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }
    /**
     * @author Rajkumar
     * @param {e, currentPrd}
     * ON INPUT CHANGES
     */
    const inputChangeHandler = (e, currentPrd) => {
        const { name, value } = e.target;
        let cloneAllAmounts = { ...allAmounts };
        if (name === "quantity") {
            let value_ = parseFloat(value)
            const cloneRefundDetailsData = { ...refundDetailsData };
            let product_details = [];
            let checkedRefundDetailsData = cloneRefundDetailsData?.products?.filter(x => x.isChecked === true);
            let totalSubtotalAmountPerQty = checkedRefundDetailsData?.reduce(function (prev, current) {
                return prev + +current.order?.products?.subtotalPerQty
            }, 0);
            cloneRefundDetailsData?.products?.forEach(item => {
                let obj = {};
                obj.suborderId = item.suborderId
                obj.quantity = (item._id === currentPrd._id) ? parseFloat(value) ? parseFloat(value) : 0 : item.quantity;
                product_details.push(obj);
                if (item._id === currentPrd._id) {
                    let returnAmount = (( parseFloat(item.order.products.subtotal) - parseFloat(item.order.products.discount) ) / parseFloat(item.order.products.quantity)) * parseFloat(item.quantity);
                    let updatedSubtotal = parseFloat(totalSubtotalAmountPerQty) - parseFloat(returnAmount);
                    if ((item?.quantity > value_) || item?.quantity === value_) {
                        item.customQuantity = value;
                        item.isChecked = true;
                        if (item.order.products.sellPrice) {
                            item.refundAmount = (parseFloat(item.order?.products?.sellPrice)) * parseFloat(value);
                        } else {
                            item.refundAmount = (parseFloat(item.order?.products?.actPrice)) * parseFloat(value);
                        }
                        let params = {
                            orderId: cloneRefundDetailsData?.orderId,
                            products: product_details
                        }
                        let _subTotal = (updatedSubtotal + parseFloat(item.refundAmount) -
                            cloneAllAmounts.refundCharges);
                        fetchCouponDetails(params, cloneRefundDetailsData?.products, cloneRefundDetailsData, _subTotal);
                    } else {
                        item.refundAmount = 0;
                        // item.isChecked = cloneRefundDetailsData?.status === 2? true : false;
                        item.customQuantity = "";
                        cloneAllAmounts.subTotal = (updatedSubtotal + parseFloat(item.refundAmount) -
                            cloneAllAmounts.refundCharges);
                        // cloneAllAmounts.subTotal = cloneAllAmounts.subTotal > 0? cloneAllAmounts.subTotal : 0
                        setAllAmounts(cloneAllAmounts);
                    }
                }
            });
            setRefundDetailsData(cloneRefundDetailsData);
        } else {
            let cloneAllAmounts = { ...allAmounts };
            const cloneRefundDetailsData = { ...refundDetailsData };
            cloneAllAmounts[name] = value;
            let totalProductSubtotalAmount = cloneRefundDetailsData?.products.reduce(function (prev, current) {
                return prev + +current.refundAmount
            }, 0);
            cloneAllAmounts.subTotal = (totalProductSubtotalAmount - cloneAllAmounts.couponAmount -
                cloneAllAmounts.refundCharges);
            setAllAmounts(cloneAllAmounts);
        }
    }
    /**
   * @author Rajkumar
   * @param {e, prd}
   * PRODUCT WISE AMOUNT INPUT HANDLER
   */
    const productInputChanges = (e, prd) => {
        const { value } = e.target;
        let con_val = parseFloat(value);
        const cloneRefundDetailsData = { ...refundDetailsData };
        let cloneAllAmounts = { ...allAmounts };
        let totalSubtotalAmount = cloneRefundDetailsData?.products.reduce(function (prev, current) {
            return prev + +current.order.products.subtotal
        }, 0);
        let totalSubtotalAmountPerQty = cloneRefundDetailsData?.products.reduce(function (prev, current) {
            return prev + +current.order?.products?.subtotalPerQty
        }, 0);
        cloneRefundDetailsData?.products?.forEach(item => {
            if (item.suborderId === prd.suborderId) {
                let returnAmount;
                // if(cloneRefundDetailsData?.requestType === 1) {
                if (item.order.products.sellPrice) {
                    returnAmount = (parseFloat(item.order?.products?.sellPrice)) * parseFloat(item?.quantity);
                } else {
                    returnAmount = (parseFloat(item.order?.products?.actPrice)) * parseFloat(item?.quantity);
                }
                // } else {
                //     returnAmount = (parseFloat(item.order?.products?.subtotal) / parseFloat(item.order?.products?.quantity)) * parseFloat(item?.quantity);
                // }
                let updatedSubtotal = parseFloat(totalSubtotalAmountPerQty) - parseFloat(returnAmount);
                if (returnAmount > con_val || returnAmount === con_val) {
                    item.isChecked = con_val ? true : (cloneRefundDetailsData?.status === 2? true : false);
                    item.refundAmount = con_val;
                    cloneAllAmounts.subTotal = (updatedSubtotal + con_val - cloneAllAmounts.couponAmount -
                        cloneAllAmounts.refundCharges);
                }
                if (!value.trim()) {
                    item.isChecked = cloneRefundDetailsData?.status === 2? true : false;
                    item.refundAmount = 0;
                    cloneAllAmounts.subTotal = (updatedSubtotal - cloneAllAmounts.couponAmount -
                        cloneAllAmounts.refundCharges);
                }
            }
        });
        setAllAmounts(cloneAllAmounts);
        setRefundDetailsData(cloneRefundDetailsData);
    }

    //  *** CHECKBOX ONCHANGE HANDLER *** //
    const productCheckboxHandler = (e, prd) => {
        const { checked } = e.target;
        const cloneRefundDetailsData = { ...refundDetailsData };
        let cloneAllAmounts = { ...allAmounts };
        let product_details = [];
        let _subTotal = 0;
        cloneRefundDetailsData?.products?.forEach(item => {
            if (item.suborderId === prd.suborderId) {
                item.isChecked = checked;
                let refundAmd = (( (parseInt(item.order.products.subtotal) - parseFloat(item.order.products.discount) ) / parseInt(item.order.products.quantity)) * parseInt(item.quantity));
                let returnAmount;
                // if(cloneRefundDetailsData?.requestType === 1) {
                if (item.order.products.sellPrice) {
                    returnAmount = (parseFloat(item.order?.products?.sellPrice)) * parseFloat(item?.quantity);
                } else {
                    returnAmount = (parseFloat(item.order?.products?.actPrice)) * parseFloat(item?.quantity);
                }
                // } else {
                //     returnAmount = (parseFloat(item.order?.products?.subtotal) / parseFloat(item.order?.products?.quantity)) * parseFloat(item?.quantity);
                // }
                item.refundAmount = checked ? parseFloat(returnAmount) : 0;
                if (checked) {
                    if (item.isChecked) {
                        _subTotal = (cloneAllAmounts.subTotal + refundAmd);
                        item.customQuantity = item.customQuantity? item.customQuantity : 1
                    }
                } else {
                    if (cloneRefundDetailsData?.products?.length === 1) {
                        cloneAllAmounts.couponAmount = 0
                        cloneAllAmounts.subTotal = (cloneAllAmounts.subTotal - refundAmd - cloneAllAmounts.couponAmount);
                        setAllAmounts(cloneAllAmounts);
                    } else {
                        if (item.isChecked === false) {
                            _subTotal = (cloneAllAmounts.subTotal - refundAmd);
                            cloneAllAmounts.subTotal = _subTotal - cloneAllAmounts.couponAmount
                            setAllAmounts(cloneAllAmounts);
                        }
                    }
                }
            }
            if (item.isChecked) {
                let obj = {};
                obj.suborderId = item.suborderId
                obj.quantity = item.quantity
                product_details.push(obj);
            }
        });
        let params = {
            orderId: cloneRefundDetailsData?.orderId,
            products: product_details
        }
        fetchCouponDetails(params, cloneRefundDetailsData?.products, cloneRefundDetailsData, _subTotal);
        setRefundDetailsData(cloneRefundDetailsData);
    }

    // *** SUBMIT APPROVAL HANDLER *** //
    const onSubmitApproval = () => {
        setIsFetching(true);
        const params = getParams();
        if (params?.grandTotal > 0) {
            let check = params?.products?.find(x => ((!x.price || x.price === 0) && x.reason.length === 0) ? true : false);
            if (check) {
                showNotification({
                    type: "error",
                    message: "Error",
                    description: "Please Choose reason for unselected products",
                });
            } else {
                getSelectedProductDetails(params, 'refund');
            }
        } else {
            showNotification({
                type: "error",
                message: "Error",
                description: "Total refund amount should be greater than zero",
            });
        }
    }
    /**
   * @author Mithun Prabhu
   * @param {params, type}
   * TO GET SELECTED PRODUCTS
   */
    function getSelectedProductDetails(params, type) {
        AxiosInstance(PORTS.INVOICE, "application/json").post(`/orders/getCouponAmount`, params?.params_).then((res) => {
            if (res.data.status === 200) {
                delete params.params_;
                params?.products?.forEach(item => {
                    res?.data?.data?.forEach(subItem => {
                        if (item.productId === subItem.subOrderId) {
                            item['couponAmount'] = subItem.couponAmount
                            item['productPrice'] = subItem.productPrice
                        }
                    })
                })
                if (params?.products?.length > 0) {
                    params.products.forEach(item => {
                        item.approvedQuantity = item?.approvedQuantity? item?.approvedQuantity : 0
                        item.couponAmount = item.couponAmount? item.couponAmount : 0
                    })
                }
                if (type !== "approveAmount" && params?.products?.length > 0) {
                    params.products.forEach(item => {
                        delete item?.status
                        delete item?.requestedQuantity
                    })
                }
                if (type === "refund") {
                    subApproval(params);
                } else if (type === "refundCoupon") {
                    refundCouponSubmit(params);
                } else if (type === "approveAmount") {
                    approveCouponSubmit(params);
                }
            }
        })
    }

    const subApproval = (params) => {
        AxiosInstance(PORTS.INVOICE, "application/json").post(`orders/requestApproval`, params).then((res) => {
            if (res.data.status === 200) {
                setIsFetching(false);
                showNotification({
                    type: "success",
                    message: "Success",
                    description: res.data.msg,
                });
                history.push("/refund");
            } else if (res.data.status === 400) {
                setIsFetching(false);
            }
        }).catch((er) => {
            setIsFetching(false);
            showNotification({
                type: "error",
                message: "Failed",
                description: "Error occured",
            });
        })
            .finally(() => {
                setIsFetching(false);
            });
    }

    const getParams = () => {
        const cloneRefundDetailsData = { ...refundDetailsData };
        let cloneAllAmounts = { ...allAmounts };
        const new_obj = [];
        let product_details = [];
        cloneRefundDetailsData?.products?.forEach(item => {
            let obj = {};
            obj.productId = item.order?.products?._id;
            obj.price = parseFloat(item.refundAmount);
            obj.reason = item.reason;
            obj.approvedQuantity = parseFloat(item.customQuantity);
            obj.requestedQuantity = parseFloat(item.quantity);
            obj.status = (item.isChecked && item.customQuantity) ? 1 : 3
            if (item.isChecked) {
                let obj1 = {};
                obj1.suborderId = item.suborderId
                obj1.quantity = item.quantity;
                product_details.push(obj1);
            }
            // item.choosenReason?.forEach(sub => {
            //     if (sub.isChecked) {
            //         obj.reason.push(sub.value);
            //     }
            // })
            obj.description = item.adminDescription;
            new_obj.push(obj);
        });
        let params_ = {
            orderId: cloneRefundDetailsData?.orderId,
            products: product_details
        }
        let params = {
            refundId: id,
            products: new_obj,
            couponDiscount: cloneAllAmounts.couponAmount ? parseFloat(cloneAllAmounts.couponAmount) : 0,
            otherCharges: 0,
            refundCharges: parseFloat(cloneAllAmounts.refundCharges),
            grandTotal: parseFloat(cloneAllAmounts.subTotal),
            shippingCharges: 0,
            params_: params_
        };
        return params;
    }


    // *** REFUND COUPON FUNCTIONALITY START *** //
    const [isCouponModal, setISCouponModal] = useState(false);
    const [refundCouponDetails, setRefundCouponDetails] = useState({
        coupon: '',
        validity: 30,
        limit: 1
    });
    const rules = {
        validity: [validationRules.required],
        limit: [validationRules.required],
    }
    const [errorRefundCoupon, setErrorRefundCoupon] = useState({});
    /**
   * @author Rajkumar
   * OPEN COUPON MODAL
   */
    const couponModalHandler = () => {
        if (!isCouponModal) {
            const cloneRefundCouponDetails = { ...refundCouponDetails };
            cloneRefundCouponDetails.coupon = allAmounts.subTotal;
            setRefundCouponDetails(cloneRefundCouponDetails);
        } else {
            setRefundCouponDetails([]);
        }
        setISCouponModal(!isCouponModal);
        setErrorRefundCoupon({});
    }

    const refundCouponOnChange = (e) => {
        const { name, value } = e.target;
        const cloneRefundCouponDetails = { ...refundCouponDetails };
        if (name === "limit" && value !== '' && value !== '0') {
            cloneRefundCouponDetails.coupon = Number(cloneRefundCouponDetails.coupon) / Number(value)
        } else {
            cloneRefundCouponDetails.coupon = allAmounts.subTotal;
        }
        cloneRefundCouponDetails[name] = value;
        setRefundCouponDetails(cloneRefundCouponDetails);
    }

    const submitRefundCoupon = () => {
        const result = validate(undefined, refundCouponDetails);
        if (Object.keys(result).length) {
            setErrorRefundCoupon(result);
            showNotification({
                type: "warning",
                message: "There were few errors",
                description: "Resolve the errors and try again",
            });
            return;
        }
        const params = getParams();
        params.couponDetails = {
            offerAmount: Number(refundCouponDetails.coupon),
            endDate: Number(refundCouponDetails.validity),
            usesPerCustomer: Number(refundCouponDetails.limit)
        }
        getSelectedProductDetails(params, 'refundCoupon');
        setIsFetching(true);
    }

    // *** VALIDATE FUNCTION *** //
    function validate(name, data) {
        data = data || {};
        const result = {};
        //Validate only field
        if (name) {
            const fieldRules = rules[name];
            if (Array.isArray(fieldRules)) {
                for (const rule in fieldRules) {
                    const val = rule(data[name]);
                    if (isString(val) || isObject(val)) {
                        setErrorRefundCoupon({ ...errorRefundCoupon, [name]: val });
                    } else if (name in errorRefundCoupon) {
                        const { name, ...restOfErros } = errorRefundCoupon;
                        setErrorRefundCoupon(restOfErros);
                    }
                }
            }
            //all data validation
        } else {
            for (const field in rules) {
                for (const rule of rules[field]) {
                    const val = rule(data[field], data);
                    if (typeof val === "string" || isObject(val)) {
                        result[field] = val;
                        break;
                    }
                }
            }
        }
        return result;
    }
    // *** REFUND COUPON FUNCTIONALITY END *** //

    const refundSubmit = () => {
        const params = getParams();
        setIsFetching(true);
        getSelectedProductDetails(params, 'approveAmount');
    }

    const approveCouponSubmit = (params) => {
        AxiosInstance(PORTS.INVOICE, "application/json").post(`orders/approveAmount`, params).then((res) => {
            if (res.data.status === 200) {
                setIsFetching(false);
                setISCouponModal(false);
                showNotification({
                    type: "success",
                    message: "Success",
                    description: res.data.msg,
                });
                history.push("/cancelledorders");
            } else if (res.data.status === 400) {
                setIsFetching(false);
            }
        }).catch((er) => {
            setIsFetching(false);
            showNotification({
                type: "error",
                message: "Failed",
                description: "Error occured",
            });
        })
            .finally(() => {
                setIsFetching(false);
            });
    }

    const refundCouponSubmit = (params) => {
        AxiosInstance(PORTS.INVOICE, "application/json").post(`orders/approveCoupon`, params).then((res) => {
            if (res.data.status === 200) {
                setIsFetching(false);
                setISCouponModal(false);
                showNotification({
                    type: "success",
                    message: "Success",
                    description: res.data.msg,
                });
            } else if (res.data.status === 400) {
                setIsFetching(false);
            }
        }).catch((er) => {
            setIsFetching(false);
            showNotification({
                type: "error",
                message: "Failed",
                description: "Error occured",
            });
        })
            .finally(() => {
                setIsFetching(false);
            });
    }

    /**
     * @author Rajkumar
     * @param { refundDetailsData } value
     * choose refund reject reason functionality
    */
    const commonFilterHandler = (e, data, prd) => {
        const { name, value, checked } = e.target;
        const cloneRefundDetailsData = { ...refundDetailsData };
        cloneRefundDetailsData?.products?.forEach(item => {
            if (item.suborderId === prd.suborderId) {
                if (checked) {
                    item['reason'].push(data)
                } else {
                    item.reason = item?.reason?.filter(x => x !== data);
                }
                if (name === "adminDescription") {
                    item.adminDescription = value
                }
            }
        });
        setRefundDetailsData(cloneRefundDetailsData);
    }

    return (
        <div className={`${styles.management_contents} pt-4`}>
            <SearchComponent />
            {isLoading === true ? (
                <div style={{ marginLeft: "40%" }}>
                    <tr className="bg-transparent">
                        <td colSpan="100%">
                            <LoadingIndicator loadingText="Fetching refund details..." />
                        </td>
                    </tr>
                </div>
            ) : (
                <div>
                    <RefundCouponModal
                        isCouponModal={isCouponModal}
                        couponCancel={couponModalHandler}
                        refundCouponDetails={refundCouponDetails}
                        refundCouponOnChange={refundCouponOnChange}
                        errorRefundCoupon={errorRefundCoupon}
                        submitRefundCoupon={submitRefundCoupon}
                    />
                    <div className={`${styles.management_header} mt-5`}>
                        <div className={styles.management_header_title}>
                            <h1 className="mb-0">
                                <img
                                    src={BlackLeftArrow}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.goBack()}
                                // onClick={() => history.push("/refund")}
                                />
                                Refund Details<span className={styles.management_header_sub_text}></span>
                            </h1>
                            {/* <span className={styles.management_header_info}>
                        Updated by Girish, June 18, 9:24 AM
                    </span> */}
                        </div>
                        <div className={styles.management_header_btn}>
                            <div className={`${styles.management_header_order_id} mb-3`}>Refund Order ID: {refundDetailsData?.refundId}</div>
                            <div className={styles.management_header_order_id}>Order Id: # {refundDetailsData?.orders?.orderId}</div>
                        </div>
                    </div>
                    <div className={styles.management_details}>
                        <div className={styles.management_details_contents}>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <h4>Products</h4> */}
                                    <div className={`${styles.management_details_form} mb-4`}>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr className={`${styles.product_details_heading}`}>
                                                    <th>
                                                        <h3 className={styles.secondry_title}>Product</h3>
                                                    </th>
                                                    <th>
                                                        <h3 className={styles.secondry_title}>Quantity</h3>
                                                    </th>
                                                    <th>
                                                        <h3 className={styles.secondry_title}>Total</h3>
                                                    </th>
                                                    <th>
                                                        <h3 className={styles.secondry_title}>Status</h3>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {refundDetailsData?.products?.length > 0 && refundDetailsData?.products?.map(prd => (
                                                    <tr className={`${styles.product_details_contents} ${styles.product_details}`} key={prd.suborderId}>
                                                        <td>
                                                            <div className={`${styles.product_details_img}`}>
                                                                {(refundDetailsData?.requestType !== 1 && refundDetailsData?.status !== 1) && refundDetailsData?.status !== 2 && <input checked={prd.isChecked} type='checkbox' onChange={(e) => productCheckboxHandler(e, prd)} className={`${styles.product_details_check_box}`} />}
                                                                <img
                                                                    src={prd.order?.products?.feature_image}
                                                                    alt=""
                                                                    className={styles.product_img}
                                                                />
                                                                <div className={styles.product_name}>
                                                                    <div className={styles.product_name_value}>
                                                                        <div className={styles.product_title}>
                                                                            {prd.order?.products?.productName}
                                                                        </div>
                                                                        <span>Rs. {prd.order?.products?.subtotal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                {prd.order?.products?.quantity}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                {/* Rs. {((Number(prd.order?.products?.quantity)) * (Number(prd.order?.products?.subtotal))).toLocaleString(undefined, { maximumFractionDigits: 2 })} */}
                                                                Rs. {prd.order?.products?.subtotal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                {prd.order?.products?.status === 1
                                                                    ? "Processing"
                                                                    : prd.order?.products?.status === 2
                                                                        ? "Shipped"
                                                                        : prd.order?.products?.status === 3
                                                                            ? "Delivered"
                                                                            : prd.order?.products?.status === 4
                                                                                ? "Cancelled"
                                                                                : prd.order?.products?.status === 5
                                                                                    ? "Failed"
                                                                                    : prd.order?.products?.status === 6
                                                                                        ? "On-Hold"
                                                                                        : "Out For Delivery"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className={`${styles.product_accoridon}`}>
                                            {refundDetailsData?.products?.length > 0 && refundDetailsData?.products?.map(prd => (
                                                <div key={prd.suborderId}>
                                                    <div id="accordion">
                                                        <Accordion defaultActiveKey="0">
                                                            <Card className={`${styles.accordion_card}`}>
                                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" >
                                                                    <Card.Header className={`${styles.accordion_card_header}`}>
                                                                        <div className={`${styles.accordion_card_header_count}`}>
                                                                            <span>1</span>
                                                                            <img src={circleOne} alt="circle_one" />
                                                                        </div>
                                                                        <div className={`${styles.accordion_card_product}`}>
                                                                            <div className={`${styles.accordion_card_product_img}`}>
                                                                                <img
                                                                                    src={prd.order?.products?.feature_image}
                                                                                    alt=""
                                                                                    className={styles.product_img}
                                                                                />
                                                                            </div>
                                                                            <div className={`${styles.accordion_card_product_name}`}>
                                                                                <h5 className={`${styles.accordion_card_product_title}`}>Product</h5>
                                                                                <p className={`${styles.accordion_card_product_description}`}>{prd.order?.products?.productName}</p>
                                                                            </div>
                                                                            <div className={`${styles.accordion_card_product_quantity}`}>
                                                                                <h5 className={`${styles.accordion_card_product_title}`}>Quantity</h5>
                                                                                <p className={`${styles.accordion_card_product_description}`}>{prd.quantity}</p>
                                                                            </div>
                                                                            <div className={`${styles.accordion_card_product_total}`}>
                                                                                <h5 className={`${styles.accordion_card_product_title}`}>Total</h5>
                                                                                <p className={`${styles.accordion_card_product_description}`}>Rs. {(prd.quantity * prd.order?.products?.actPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                                            </div>
                                                                        </div>
                                                                        {/* Use accordion_arrow_rotate class for Arrow rotate  */}
                                                                        <img src={arrowDown} className={`${styles.accordion_arrow}`} alt='arrow' />
                                                                    </Card.Header>
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse eventKey="0" >
                                                                    <Card.Body className={`${styles.accordion_card_body}`}>
                                                                        <div className={`${styles.accordion_card_product_content}`}>
                                                                            <h5 className={`${styles.accordion_card_product_title}`}>Reason to Return</h5>
                                                                            <div className={`${styles.accordion_card_product_content_img}`}>
                                                                                {prd.productImage?.length > 0 && prd.productImage.map((prdImage, indx) => (
                                                                                    <img key={indx} src={prdImage.link} alt='product images' />
                                                                                ))}
                                                                            </div>
                                                                            <div className={`${styles.accordion_card_product_content_reson}`}>
                                                                                {(Array.isArray(prd?.reason) && prd?.reason?.length > 0) ? prd?.reason?.map((reason, indx) => (
                                                                                    <span key={indx} className={`${styles.accordion_card_product_content_reson_text}`}>
                                                                                        <span className={`${styles.accordion_card_product_content_reson_dot}`}></span>
                                                                                        {reason}
                                                                                    </span>
                                                                                )) : (prd?.reason)
                                                                                }
                                                                            </div>
                                                                            <div className={`${styles.accordion_card_product_content_reson_description}`}>
                                                                                {prd.description}
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    </div>
                                                    {(refundDetailsData?.requestType !== 1 && refundDetailsData?.status !== 1) && refundDetailsData?.status !== 2 &&
                                                        <div className={`${styles.select_refund_reason}`}>
                                                            <h3 className={`${styles.select_refund_reason_title}`}>Select a reason to refund</h3>
                                                            <form>
                                                                <div className="card_dropdown col-md-12 pl-0 pr-0">
                                                                    <div className={`${styles.select_refund_reason_checkbox} filter_heading card_dropdown_heading`} >
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={(e) => commonFilterHandler(e, "We are sorry! your request is not a Valid reason", prd)}
                                                                            ></input>
                                                                            <span>We are sorry! your request is not a Valid reason</span>
                                                                        </label>
                                                                        <img src={arrowDown} width={10} height={20} />
                                                                    </div>
                                                                    <div style={{ width: "100%" }} className={`${styles.select_refund_reason_checkbox} card_dropdown_contents`}>
                                                                        {Array.isArray(prd?.choosenReason)
                                                                            ? prd.choosenReason.map((reason) => (
                                                                                <span key={reason.id}>
                                                                                    {reason.id !== 0 &&
                                                                                        <>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                // checked={reason.isChecked}
                                                                                                onChange={(e) => commonFilterHandler(e, reason.value, prd)}
                                                                                            ></input>
                                                                                            <label>{reason.name}</label>
                                                                                        </>
                                                                                    }
                                                                                </span>
                                                                            ))
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <textarea
                                                                        name="adminDescription"
                                                                        value={prd?.adminDescription}
                                                                        onChange={(e) => commonFilterHandler(e, '', prd)}
                                                                        class="form-control"
                                                                        id="exampleFormControlTextarea1" rows="3"
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                            {/* <div style={{ marginTop: "101px" }}>
                                        <div className={styles.Refund_Reason_head}>
                                            Select a reason to Refund
                                        </div>
                                        <select className={styles.Refund_Reason_select}>
                                            <option></option>
                                        </select>
                                        <textarea
                                            className={styles.Refund_Reason_textarea}
                                            value="Type your reason"
                                        />
                                    </div> */}
                                            <hr />
                                            <div className={`${styles.refund_breakup}`}>
                                                <h4 className={`${styles.refund_breakup_title}`}>Refund Breakup</h4>
                                                <table class="table table-borderless">
                                                    <thead>
                                                        <tr className={`${styles.refund_breakup_heading}`}>
                                                            <th>
                                                                <h3 className={styles.secondry_title}>Title</h3>
                                                            </th>
                                                            <th>
                                                                <h3 className={styles.secondry_title}>Quantity</h3>
                                                            </th>
                                                            <th>
                                                                <h3 className={styles.secondry_title}>Amount</h3>
                                                            </th>
                                                            <th>
                                                                <h3 className={styles.secondry_title}>Return Amount</h3>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {refundDetailsData?.products?.length > 0 && refundDetailsData?.products?.map(prd => (
                                                            <tr className={`${styles.refund_breakup_contents}`} key={prd.suborderId}>
                                                                <td>
                                                                    {(refundDetailsData?.requestType !== 1 && refundDetailsData?.status !== 1) && refundDetailsData?.status !== 2 &&
                                                                        <input checked={prd.isChecked} type='checkbox' onChange={(e) => productCheckboxHandler(e, prd)} className={`${styles.refund_breakup_checkbox}`} />
                                                                    }
                                                                    <span>{prd.order?.products?.productName}</span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        <input disabled={refundDetailsData?.status === 1? true : !prd.isChecked} name="quantity" value={prd.customQuantity} type="text" onChange={(e) => inputChangeHandler(e, prd)}></input>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        Rs. {
                                                                            (prd.order.products.sellPrice) ?
                                                                                (parseFloat(prd.order?.products?.sellPrice)) * parseFloat(prd?.quantity)
                                                                                : (parseFloat(prd.order?.products?.actPrice)) * parseFloat(prd?.quantity)
                                                                            // : ((parseInt(prd.order?.products?.subtotal) / parseInt(prd.order?.products?.quantity)) * parseInt(prd.quantity)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        <input disabled={refundDetailsData?.status === 1? true : !prd.isChecked} value={prd.refundAmount} onChange={(e) => productInputChanges(e, prd)} type="text" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {allAmounts?.couponAmount > 0 && allAmounts?.couponAmount !== '0' && allAmounts?.couponAmount &&
                                                            <tr className={`${styles.refund_breakup_contents}`}>
                                                                <td colSpan='2'>
                                                                    <span>Coupon discount</span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        Rs. {allAmounts?.couponAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        Rs. {allAmounts?.couponAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {refundDetailsData?.refundCharges?.refundCharges !== 0 && refundDetailsData?.refundCharges?.refundCharges !== '0' &&
                                                            <tr className={`${styles.refund_breakup_contents}`}>
                                                                <td colSpan='2'>
                                                                    <span>{refundDetailsData?.requestType ? 'Cancellation Charges' : 'Reverse Logistics Charges'}</span>
                                                                    {/* <span>Redeemed points</span>
                                                            <img src={infoIcon} className={`${styles.refund_breakup_info_img}`} /> */}
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>

                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className={styles.product_value}>
                                                                        <input disabled={(refundDetailsData?.status === 1 || refundDetailsData?.status === 2)? true : false} name="refundCharges" value={allAmounts.refundCharges} type="text" onChange={inputChangeHandler}></input>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        }
                                                    {/* {refundDetailsData?.refundCharges?.logisticsCharge!==0&&refundDetailsData?.refundCharges?.logisticsCharge!=='0' &&
                                                    <tr className={`${styles.refund_breakup_contents}`}>
                                                        <td colSpan='2'>
                                                            <span>Reverse logistics charges</span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                <input name="logisticsCharge" value={allAmounts.logisticsCharge} type="text" onChange={inputChangeHandler}></input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                } */}
                                                {refundDetailsData?.requestType === 1 && refundDetailsData?.orders?.shippingAmount!==0&&refundDetailsData?.orders?.shippingAmount!=='0' &&
                                                    <tr className={`${styles.refund_breakup_contents} ${styles.refund_border_bottom}`}>
                                                        <td colSpan='2'>
                                                            <span>Shipping charges</span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                Rs. {refundDetailsData?.orders?.shippingAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={styles.product_value}>
                                                                <input disabled name="shippingAmount" value={refundDetailsData?.orders?.shippingAmount} type="text" onChange={inputChangeHandler}></input>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                                        <tr className={`${styles.refund_breakup_contents} ${styles.refund_border_bottom}`}>
                                                            <td colSpan='2'>
                                                                <span>Sub Total</span>
                                                            </td>
                                                            {/* {allAmounts.standardSubTotal&&<td>
                                                        <span className={styles.product_value}>
                                                            Rs. {allAmounts.standardSubTotal}
                                                        </span>
                                                    </td>} */}
                                                            {allAmounts.subTotal && <td>
                                                                <span className={`${styles.product_value} ${styles.color_red}`}>
                                                                    Rs. {Math.floor(allAmounts.subTotal * 100) / 100}
                                                                </span>
                                                            </td>}
                                                        </tr>
                                                        <tr className={`${styles.refund_breakup_contents} ${styles.refund_border_bottom} ${styles.refund_border_text_bold}`}>
                                                            <td colSpan='3'>
                                                                <span>Total Refund Amount</span>
                                                                <Tooltip
                                                                    placement="topRight"
                                                                    title="The refund amount refers to the final amount that a customer will receive as a refund for a returned item(s) or cancelled item(s)."
                                                                >
                                                                    <img src={infoIcon} className={`${styles.refund_breakup_info_img}`} />
                                                                </Tooltip>
                                                            </td>
                                                            {allAmounts.subTotal && <td>
                                                                <span className={styles.product_value}>
                                                                    Rs. {
                                                                    refundDetailsData?.requestType === 1 && 
                                                                    refundDetailsData?.orders?.shippingAmount? 
                                                                    Math.floor((allAmounts.subTotal + refundDetailsData?.orders?.shippingAmount) * 100) / 100 
                                                                    :
                                                                    Math.floor(allAmounts.subTotal * 100) / 100 }
                                                                </span>
                                                            </td>}
                                                        </tr>
                                                        {refundDetailsData?.status === 2 &&
                                                            <div className={`${styles.refund_customer_method} ${styles.refund_table_border_bottom}`}>
                                                                <div>
                                                                    <p className={`${styles.refund_customer_method_title}`}>Customer preferred refund method as</p>
                                                                    <button className={`btn ${styles.refund_customer_method_btn}`}>Return as {refundDetailsData?.refundType === 1 ? 'Money' : 'Coupon'}</button>
                                                                </div>

                                                                {/* <div className={`${styles.refund_customer_method_text}`}>
                                                        <span>
                                                            Refund Manually
                                                            <img src={infoIcon} className={`${styles.refund_table_info_icon}`} alt="info" />
                                                        </span>
                                                        <span className={`${styles.refund_table_amount_border} ml-4`}>Rs. 8675</span>
                                                    </div> */}
                                                            </div>}
                                                        <div className={`${styles.refund_super_admin} mt-4`}>

                                                            {refundDetailsData.status === 0 &&
                                                                <div>
                                                                    <span className={`${styles.refund_super_admin_title}`}>Super Admin</span>
                                                                    <button style={{ cursor: "pointer" }} onClick={onSubmitApproval} className={`btn ${styles.refund_super_admin_btn}`}>Send for Approval</button>
                                                                </div>
                                                            }

                                                            <div>
                                                                {refundDetailsData?.status === 2 && refundDetailsData?.refundType === 0 && <button onClick={couponModalHandler} className={`btn ${styles.refund_super_admin_btn}`}>Add Coupon Code</button>}
                                                                {refundDetailsData?.status === 2 && refundDetailsData?.refundType === 1 && <button onClick={refundSubmit} className={`btn ${styles.refund_super_admin_btn}`}>Refund</button>}
                                                            </div>
                                                        </div>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div >
    )
}