import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum } from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";

const { Option } = Select;
console.log('hi');
const bannerOneNew = {
    name: "",
    slug: "",
    image: "",
    discount:"",
    regionId: [],
    categoryId: [],
    type:"",
    sort:"",
};
const bannerOne = JSON.parse(JSON.stringify(bannerOneNew));


export default function BannerNew() {
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });

  const [payuform, setPayuForm] = useState([]);
  const [bannerOneform, setBannerOneform] = useState([bannerOne]);

  const [regionList, setRegionList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getRegionList();
  }, []);


  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        //console.log(res);
        if (res.data.status === 200) {
          res.data.daaata.unshift({
            name: "",
            _id: "",
          });
          setRegionList(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }


  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  function handleChange(e) {
    const { name, checked } = e.target;

  }

  function addNew() {
    //console.log("add");
      const updatedValue = JSON.parse(JSON.stringify(bannerOneform));
      const newBanner = JSON.parse(JSON.stringify(bannerOne));
      updatedValue.push(newBanner);
      setBannerOneform(updatedValue);
    
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            // attributes={{
            //   onClick: submit,
            // }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details}>
      {bannerOneform.map((banner, indx) => (
            <React.Fragment key={indx}>
              <div className={styles.management_details_contents}>
                <h4>Main Left Side Banners</h4>
                <div className="row">
                <div className="col-lg-6 mb-4">
                        <label className={commonstyles.secondry_title}>
                            Name{" "}
                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                            </span>
                        </label>

                        <InputComponent
                            className="form-control-lg mb-3"
                            formControl={{
                            placeholder: "Type banner name",
                            name: "name",
                            value: banner?.name,
                            // onChange: handleFormInput,
                            }}
                        />
                        {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
                    </div>
                    
                    {/* Region start */}
                    <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Warehouse
                    </label>
                        <div className={styles.management_details_form}>
                            <Select
                            mode="multiple"
                            name="region"
                            style={{ width: "100%" }}
                            placeholder="Select one state"
                            // onChange={(e) => handleRegionChange(e)}
                            optionLabelProp="label"
                            value={banner?.regionId}
                            >
                            {regionList.map((region) => (
                                <Option value={region._id} label={region.name}>
                                <div className="demo-option-label-item">
                                    {region.name}
                                </div>
                                </Option>
                            ))}
                            </Select>
                        </div>
                    </div>
                    {/* region End */}
                    {/* Slug */}
                    <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Slug
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <InputComponent
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "Type slug",
                        name: "slug",
                        value: banner?.slug,
                        // onChange: handleFormInput,
                        }}
                    />
                    </div>
                    {/* Slug ends */}
                   
                    {/* discount */}
                    <div className="col-lg-6 mb-4">
                        <label className="theme-form-label">
                        Discount  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative"}}>*</span>
                        </label>
                        <select
                            name="discount"
                            className={`form-control form-control-lg mb-3
                                
                                ${commonstyles.form_control
                            }`}
                            value={banner?.discount}
                            style={{ cursor: "pointer" }}
                            // onChange={handleFormInput}
                            >
                            <option value=""></option>
                            <option value="10">10%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                            <option value="60">60%</option>
                            <option value="70">70%</option>
                            <option value="80">80%</option>
                            <option value="90">90%</option>
                            <option value="100">100%</option>
                        </select>
                    </div>
                    {/* discount end */}
                    
                    
                </div>
                <div className={styles.payment_btn}>
                <button className={`btn ${styles.btn_filled}`} onClick={addNew}>
                    Add Banner for New Warehouse
                </button>
        </div>
              </div>
              </React.Fragment>
          ))}
        
      </div>
    </div>
  );
}