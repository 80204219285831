import React, { useEffect, useState } from "react";
import ModalComponent from "../../../modules/ReusableComponent/ModalComponent";
import styles from "../../../../styles/OrderDetails.module.css";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";

export default function RefundCouponModal(props) {
    return (
        <div>
            {/* Refund coupon code modal  */}
            <ModalComponent
                data={{
                    show: props.isCouponModal,
                    size: "md",
                    centered: true,
                }}
                handleCancel={props.couponCancel}
                // handleConfirm={removeOrder}
                title="Delete product"
                confirmText="Delete"
            >
                <div>
                    <label className={styles.secondry_title}>
                        Coupon value in INR
                    </label>
                    <InputComponent
                        className="form-control-lg"
                        // error={props.errorRefundCoupon.coupon}
                        formControl={{
                            type: "number",
                            placeholder: "8.675",
                            name: "coupon",
                            disabled: true,
                            value: props.refundCouponDetails?.coupon,
                            // onChange: addressHandleInput,
                        }}
                    />
                    {/* <FormErrorText error={props.errorRefundCoupon.coupon} /> */}
                    <label className={styles.secondry_title}>
                        Coupon validity
                    </label>
                    <InputComponent
                        className="form-control-lg"
                        error={props.errorRefundCoupon.validity}
                        formControl={{
                            type: "number",
                            placeholder: "30",
                            name: "validity",
                            value: props.refundCouponDetails?.validity,
                            onChange: props.refundCouponOnChange,
                        }}
                    />
                    <FormErrorText error={props.errorRefundCoupon.validity} />
                    <label className={styles.secondry_title}>
                        Usage limit per user
                    </label>
                    <InputComponent
                        className="form-control-lg"
                        error={props.errorRefundCoupon.limit}
                        formControl={{
                            type: "number",
                            placeholder: "1",
                            name: "limit",
                            value: props.refundCouponDetails?.limit,
                            onChange: props.refundCouponOnChange,
                        }}
                    />
                    <FormErrorText error={props.errorRefundCoupon.limit} />
                    <div style={{ marginTop: "10px" }} className="text-right mb-5">
                        <button
                            className={`btn ${styles.btn_filled}`}
                            onClick={props.submitRefundCoupon}
                        >
                            Add coupon
                        </button>
                    </div>
                </div>
            </ModalComponent>
            {/* Refund coupon code modal ends*/}
        </div>
    )
}