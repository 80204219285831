import React from 'react';
import { Select,Empty } from 'antd';  
const { Option } = Select;
const AntSelectComponent = (props) => {
    return (
        <Select {...props.selectProps}  notFoundContent={props.fetching && props.selectOptions.length === 0&&<Empty/>}>
            {props.selectOptions && props.selectOptions.length>0 && props.selectOptions.map((item,index)=>(
                <Option key={index} value={item.value+'*&^'+item.name}>{item.name}</Option>
            ))}
        </Select>
    );
}
 
export default AntSelectComponent;