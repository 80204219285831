import styles from "../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import dots from "../../../assets/images/column_dots.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
// import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import arrowDown from "../../../assets/images/arrow_down.svg";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import React, { useEffect, useState, useRef } from "react";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
// import AddCustomer from "./AddCustomer";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import * as XLSX from "xlsx";
import { filter, upperCase } from "lodash";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  TimePicker,
  Tooltip,
} from "antd";
import Pagination from "../../modules/ReusableComponent/Pagination";
import EditDiscountComponent from "../DiscountManagement/EditDiscountNew";
import { formatServerValidtionErrors } from "../../../utils";
import Plus from "../../../assets/images/plus_white_icon.svg";
import { useHistory } from "react-router-dom";
import discountstyles from "../../../styles/AdminDiscount.module.css";
import AddDiscount from "./AddDiscount";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import moment from "moment";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../utils";
import { Link, useParams } from "react-router-dom";
import SearchComponent from "../SearchManagement/SearchComponent";
import plusIcon from "../../../assets/images/plus_white_icon.svg";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  // { _id: 2, status: 1, name: "Name", value: "Rule Name" },
  { _id: 3, status: 1, name: "Coupon Code", value: "Coupon Code" },
  { _id: 5, status: 1, name: "Coupon Type", value: "Coupon Type" },
  { _id: 8, status: 1, name: "Status", value: "Status" },
  { _id: 4, status: 1, name: "Coupon Amount", value: "Coupon Amount" },
  { _id: 6, status: 1, name: "Offer Applicable", value: "Offer Applicable" },
  { _id: 7, status: 1, name: "Created At", value: "Created At" },
  { _id: 9, status: 1, name: "Operate", value: "Operate" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "region" },
  { id: 3, isChecked: false, name: "Date", value: "date" },
  { id: 2, isChecked: false, name: "Status", value: "status" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Change to Approved", value: 1 },
  { _id: 2, isChecked: false, name: "Change to Pending", value: 0 },
  { _id: 3, isChecked: false, name: "Change to Rejected", value: 2 },
  { _id: 4, isChecked: false, name: "Delete", value: 3 },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

let globalType = 0;

export default function DiscountManagementComponent() {
const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [offerList, setOfferList] = useState([]);
  const pdfExportComponent = useRef(null);

  const [startDates, setStartDates]=useState("")
  const [endDates, setEndDates]=useState("")

  const [search, setSearch] = useState("");
  const [Type, setType] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);

  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);

  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const [IsLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [deliveryStatusName, setDeliveryStatusName] = useState(
    "All Delivery Status"
  );
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [regions, setRegions] = useState([]);
  const [sortOrder, setSortorder] = useState("");
  const [createdAtSort, setCreatedAtSort] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [selectFilter, setSelectFilter] = useState(filters);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [status, setStatus] = useState("");
  const [toggle, setToggle] = useState("All Coupon");
  const [visible, setVisible] = useState(false);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [deliveryStatus, setDeliveryStatus] = useState([
    { _id: 3, name: "Pending", value: 3 },
    { _id: 1, name: "Approved", value: 1 },
    { _id: 2, name: "Rejected", value: 2 },
  ]);

  // const [regionName, setRegionName] = useState("All Region");

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    // {
    //   title: "Rule Name",
    //   visible: false,
    //   key: "Rule Name",
    //   render: (text, record, index) => <td>{record.ruleName}</td>,
    // },
    {
      title: "Coupon Code",
      visible: false,
      key: "Coupon Code",
      sorter: (a, b) =>
        a?.couponCode - b?.couponCode,
      render: (text, record, index) => <span>{record?.couponCode}</span>,
    },
    {
      title: "Coupon Type",
      visible: false,
      key: "Coupon Type",
      render: (text, record, index) => (
        <td>{record.offerType === 0 ? "Percentage" : "Fixed Amount"}</td>
      ),
    },
    {
      title: "Coupon Amount",
      visible: false,
      key: "Coupon Amount",
      render: (text, record, index) => (
        <td>
          {record.offerType === 1
            ? convertToReadablePrice(record.offerAmount)
            : record.offerAmount + "%"}
        </td>
      ),
    },

    {
      title: "Offer Applicable",
      visible: false,
      key: "Offer Applicable",
      render: (text, record, index) => <td>{record.offerApplicable}</td>,
    },
    {
      title: "Created At",
      visible: false,
      key: "Created At",
      sorter: (a,b)=> a.createdAt - b.createdAt,
      render: (text, record, index) => (
        <td>{moment.parseZone(record.createdAt).format("DD/MM/YYYY , hh:mm A")}</td>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <td>
          <span
            class={
              record.ruleStatus == 1
                ? discountstyles.status_active
                : record.ruleStatus == 0
                ? discountstyles.status_pending
                : discountstyles.status_inactive
            }
          >
            {record.ruleStatus == 0
              ? "Pending"
              : record.ruleStatus == 1
              ? "Approved"
              : record.ruleStatus == 2 && "Rejected"}
          </span>
        </td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Operate",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Discount-Discount-edit") ===
          "Discount-Discount-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit discount"
              // className="tooltip_list"
            >
              <Link
                to={{
                  pathname: `/discount/${record._id}`,
                  state: globalType,
                }}
              >
                <img
                  src={adminProductEdit}
                  className={commonstyles.admin_table_edit_img}
                  // onClick={() => editCustomer(user)}
                  alt="Edit action icon"
                  style={{ cursor: "pointer" }}
                  // title="Edit discount"
                />{" "}
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete discount"
              // className="tooltip_list"
            >
              <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => showDeleteConfirmation(record)}
                alt="Edit action icon"
                style={{ cursor: "pointer" }}
                // title="Delete discount"
              />
            </Tooltip>
          </td>
        ),
    },
  ]);

  /**
   * @author Mithun Prabhu
   * @param {date}
   * To get date in readable format i.e 5/9/2022, 5:13:39 PM...
   */
  function getReadableDate(date) {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      time: "numeric",
    };
    return new Date(date).parseZone("en-US" /* , options */);
  }

  /**
   * @author Mithun Prabhu
   * @param {coupon}
   * To take out particuar coupon abd confirm to delete
   */
  function showDeleteConfirmation(coupon) {
    setSelectedCustomer(coupon);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  /**
   * @author Mithun Prabhu
   * @param {onClick event}
   * To set selected coupon to empty and confirm delete set to false
   */
  function cancelDelete() {
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("offerList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOffer")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOffer"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOffer", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("offerList", JSON.stringify(cloneOrderList));
    setOfferList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOffer");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("offerList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOffer")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOffer"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOffer", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("offerList", JSON.stringify(cloneOrderList));
    setOfferList(cloneOrderList);
  };

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, Type}
   * To get state of banner and normal coupon when we toggle and initial load
   */
  useEffect(() => {
    if (history?.isBanner) {
      globalType = 1;
      setType(1);
      setToggle("Banner Coupon");
    } else {
      globalType = 0;
      setType(0);
      setToggle("All Coupon");
    }
    set_new_header(headers);
    getOffers(pageSize, nextPage,"", history?.isBanner ? 1 : Type);
    getRegionList();
    localStorage.removeItem("selectedOffer");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
      }
    };
    return () => {
      localStorage.removeItem("offerList");
      localStorage.removeItem("selectedOffer");
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * CALLING GET COUPON  RECORDS
   */
  function getOffers(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
      // setIsLoading({ delete: false, list: true });
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      type: type ? type : Type,
    };

    if (filterarr && filterarr.type) {
      filterOptions.type = filterarr.type;
    } else if (filterarr.type !== "") {
      if (filterarr && filterarr.type !== "") {
        filterOptions.type = filterarr.type;
      }
    }
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }

    if (filterarr && filterarr.status !== "") {
      filterOptions.status = Number(filterarr.status);
    } else if (status !== "") {
      if (filterarr && filterarr.status !== "") {
        filterOptions.status = Number(status);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(
          filterarr.sortOrder ? filterarr.sortOrder : ""
        );
      }
    }
    if (filterarr && filterarr.createdAtSort !== "") {
      filterOptions.createdAtSort = Number(filterarr.createdAtSort);
    } else if (filteredDate.createdAtSort !== "") {
      if (filterarr && filterarr.createdAtSort !== "") {
        filterOptions.createdAtSort = Number(
          filterarr.createdAtSort ? filterarr.createdAtSort : ""
        );
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }

    if(type==true && filterarr?.tableFilter==true){
      filterOptions.filterDays = (filterDays && filterDays!="custom")?filterDays:null
    }

    const newStartDate = startDates.split("-").reverse().join("-");
    const newEndDate = endDates.split("-").reverse().join("-");

    if(filterarr && filterarr?.filterDays==="custom"){
      filterOptions.startDate = newStartDate?newStartDate:"";
      filterOptions.endDate = newEndDate?newEndDate:"";
    }

    globalType = filterOptions.type;
    if (filterOptions.sortOrder === "") delete filterOptions.sortOrder;
    if (!filterOptions.createdAtSort && filterOptions.createdAtSort !== 0) delete filterOptions.createdAtSort;

    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("offers/getCoupon", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem("offerList", JSON.stringify(res.data.data));
          setOfferList(res.data.data);
          setPagination(res.data.pagination);
        } else {
          setOfferList([]);
          setPagination({});
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = {};

    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOffers(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOffers(pageSize, value, filter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInput(e, type) {
    // setNextPage(1);
    // setSearch(e.value);
    const { value } = e.target;
    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: e.target?.value || "",
        nextpage: 1,
        type: Type,
      };
      getOffers(pageSize, nextPage, updatedFilter);
    }
  }

  function debounceSearch(filter) {
    // clearTimeout(timeoutId.current);
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getOffers(pageSize, 1, filter);
    // }, 500);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        return er;
      });
  }
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      status: status,
      filterDays: filterDays,
      type: Type,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (name === "status") {
      updatedFilter.status = value.value;
      setStatus(value.value);
      setDeliveryStatusName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }

    debounceSearch(updatedFilter);
  }

  /**
   * @author Mithun Prabhu
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    setStartDates(dateString[0])
    setEndDates(dateString[1])
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      status: status,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
      type: Type,
    };
    debounceSearch(updatedFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {ONCLICK}
   * TO CALL EXPORT PDF USING USErEF
   */
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {event}
   * TO CALL EXPORT CSV API
   */
  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("offers/exportOffers",params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach((item) => {
            if (item["End Date"]) {
              item["End Date"] = moment(
                item["End Date"],
                "DD-MM-YYYY HH:mm:ss"
              ).format("MMM Do YYYY, h:mm:ss A");
            }
            if (item["Start Date"]) {
              item["Start Date"] = moment(
                item["Start Date"],
                "DD-MM-YYYY HH:mm:ss"
              ).format("MMM Do YYYY, h:mm:ss A");
            }
          });
          /* data.map((item) => {
            item["Module Name"] = capitalize(item["Module Name"]);
          }); */
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);

          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `offer-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOffer(values);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewOffer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  /**
   * @author Mithun Prabhu
   * @param {e, id}
   * This is component for checking and unchecking columns in list page
   */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOffer(items);
  };

  /**
   * @author Mithun Prabhu
   * @param {Menu}
   * This is component for removing/adding columns with onClick event
   */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      status: status,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      type: Type,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
          // setRegionName("All Region");
        }

        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        if (item.value === "status") {
          updatedFilter.status = "";
          setStatus("");
          setDeliveryStatusName("All Delivery Status");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <span
                            key={region._id}
                            onClick={() =>
                              filterRegionChange("regionId", region)
                            }
                          >
                            <label>{region.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}

            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterRegionChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "status") === "status" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {deliveryStatusName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(deliveryStatus)
                      ? deliveryStatus.map((delivery) => (
                          <span
                            key={delivery._id}
                            onClick={() =>
                              filterRegionChange("status", delivery)
                            }
                          >
                            <label>{delivery.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
   * @author Mithun Prabhu
   * @param {data}
   * This is for checking through different action objects and calling API for submit
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {item}
   * This is for checking through different action objects and calling API for submit
   */
  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedOffer")) {
      let selectedOffer = JSON.parse(localStorage.getItem("selectedOffer"));
      if (selectedOffer.length > 0) {
        if (Number(item.value) == 3) {
          let data = {
            offerId: selectedOffer,
          };
          submitBulkAction(data);
        } else {
          let data = {
            offerId: selectedOffer,
            status: Number(item.value),
          };
          submitBulkAction(data);
        }
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {data}
   * API call for bulk delete and bulk update coupons.
   */
  const submitBulkAction = (data) => {
    let API = "offers/bulkUpdateCouponDiscount";
    let Delete = "offers/bulkDeleteCouponDiscount";
    let url =
      data.status != 1 && data.status != 2 && data.status != 0 ? Delete : API;

    setIsLoading(true);

    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post(url, data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: "Coupon status updated successfully",
          });
          localStorage.removeItem("selectedOffer");
          // const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
          // cloneOrderList.forEach((item) => {
          //   item.isChecked = false;
          // });
          getOffers(pageSize, nextPage, "");
          getRegionList();
          // localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
          // setOfferList(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @author Mithun Prabhu
   * @param {onClick}
   * function for coming back list page when we cluck cancel button in editdiscount page
   */
  function closeEditProduct() {
    getOffers(pageSize, nextPage);
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  /**
   * @author Mithun Prabhu
   * @param {onClick}
   * API call for delete coupon
   */
  function deleteCustomer() {
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .get(`offers/coupon/${selectedCustomer._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Offer deleted successfully",
          });
          getOffers(pageSize, nextPage, "");
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  if (childComponentVisibility.addProduct) {
    return (
      <AddDiscount
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditDiscountComponent
        customer={selectedCustomer}
        handleClose={closeEditProduct}
      />
    );
  }

  /**
   * @author Mithun Prabhu
   * @param {pagination, filters, sorter}
   * To give updatedObjects for API call
   */
  const handleTableChange = (pagination, filters,  sorter) => {
    console.log(  sorter.columnKey)
    var sortValue = "";
    var createdAtSort = "";
    if(sorter.columnKey === "Coupon Code"){
     sortValue = sorter.order === "ascend" ? 0 : sorter.order === "descend" ? 1 : "";
    }
    if(sorter.columnKey === "Created At"){
      createdAtSort = sorter.order === "ascend" ? 0 : sorter.order === "descend" ? 1 : "";
    }
    setCreatedAtSort(createdAtSort,"createdAtSort")
    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      regionId: regionId,
      filterDays: filterDays,
      // orderStatus: orderStatus,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortOrder: sortValue,
      createdAtSort: createdAtSort,
      type: Type,
      tableFilter:true
    };

    // setNextPage(1);
    getOffers(pageSize, nextPage, updatedFilter, true);
  };

  /**
   * @author Mithun Prabhu
   * @param {couponType}
   * To given updated API according shifted tab
   */
  const toggleClickHandler = (couponType) => {
    setFilterDays("")
    setFilterDays("")
    setStatus("")
    setRegionName("All Region")
    setFilterDaysName("Date")
    setDeliveryStatusName("All Delivery Status")
    setSelectedFilters([]);
    const filtersData = []
    filters.map(filter=> filtersData.push({...filter, isChecked:false}))
    setSelectFilter(filtersData)
    setFilteredDate({
      startDate: "",
      endDate: "",
    })
    let filter = {};
    setToggle(couponType);
    if (couponType == "All Coupon") {
      globalType = 0;
      filter.type = 0;
      setType(0);
      setPageSize(10);
      history["isBanner"] = false;
    } else if (couponType == "Banner Coupon") {
      globalType = 1;
      filter.type = 1;
      setType(1);
      setPageSize(10);
      history["isBanner"] = true;
    }
    setSearch("");
    filter.search = "";
    getOffers(pageSize, nextPage, filter , couponType == "Banner Coupon" && 1 );
  };

  /**
   * @author Mithun Prabhu
   * @param {onClick}
   * To give updatedObjects for API call
   */
  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      status: status,
      filterDays: filterDays,
      type: Type,
    };
    debounceSearch(updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          {toggle == "All Coupon" ? "General Coupon" : "Banner Coupon"}
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Discount-Discount-view") ===
            "Discount-Discount-view" && (
            <div>
              <span className={commonstyles.management_header_downloads_text}>
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
                <Tooltip placement="topRight" title="Download excel">
                  <img
                    src={excelIcon}
                    className={commonstyles.management_header_downloads_icon}
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToCSV(e)}
                  />
                </Tooltip>
              </span>
              <span
                className={commonstyles.management_header_downloads_text_ex}
              >
                Export <img src={exportIcon} alt="icon" />
              </span>
            </div>
          )}
      </div>
      {toggle == "All Coupon" ? (
        <div className={`${commonstyles.management_header}`}>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Discount-Discount-add") ===
              "Discount-Discount-add" && (
              <ButtonComponent className={`${commonstyles.addtax_btn}`}>
                <Link to={`/discount/add`} style={{ color: "white" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    className="outline_btn_add"
                  >
                    {/* <img src={Plus} alt="" /> */}
                    Add General Coupon
                  </span>
                </Link>
              </ButtonComponent>
            )}
        </div>
      ) : (
        <div className={`${commonstyles.management_header}`}>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Discount-Discount-add") ===
              "Discount-Discount-add" && (
              <ButtonComponent className={`${commonstyles.addtax_btn}`}>
                <Link to={`/discount/addBanner`} style={{ color: "white" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    className="outline_btn_add"
                  >
                    {/* <img src={Plus} alt="" /> */}
                    Add Banner Coupon
                  </span>
                </Link>
              </ButtonComponent>
            )}
        </div>
      )}

      <div
        className={`${commonstyles.management_header_addtax} ${commonstyles.management_header}`}
      >
        <button
          className={`${commonstyles.taxButton} 
         ${toggle == "All Coupon" && commonstyles.taxButton_active} 
           d-inline-block`}
          onClick={() => toggleClickHandler("All Coupon")}
        >
          {/* <img src={Plus} alt="" /> */}
          General Coupon
        </button>

        <div className={commonstyles.management_contents_upload}>
          <button
            className={`${commonstyles.taxButton} 
                     ${
                       toggle == "Banner Coupon" &&
                       commonstyles.taxButton_active
                     } 
                       d-inline-block`}
            onClick={() => toggleClickHandler("Banner Coupon")}
          >
            {/* <img src={Plus} alt="" /> */}
            Banner Coupon
          </button>
        </div>
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Discount-Discount-view") ===
          "Discount-Discount-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <input
                type="search"
                className={`${commonstyles.form_control} form-control`}
                aria-label="Search"
                placeholder="Search by Coupon code..."
                aria-describedby="search-addon"
                value={search}
                onChange={(e) => handleInput(e)}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            {/* {localStorage.getItem("role") == "super_admin" && ( */}
            <div className="action ml-3">
              <div className="action_header">
                Action
                <img src={arrowDown} alt="image" />
              </div>
              <div className="action_contents">
                {bulkActionFilter.length > 0 &&
                  bulkActionFilter.map((item) => (
                    <div
                      className="action_contents_header"
                      key={item._id}
                      onClick={() => bulkStatusClickHandler(item)}
                    >
                      <h2
                        className={`action_contents_header_h2 ${
                          item.isChecked && "action_contents_header_h2_active"
                        }`}
                      >
                        {item.name}
                      </h2>
                      {item.isChecked && (
                        <span
                          className="action_contents_btn"
                          onClick={() => applyBulkActionHandler(item)}
                        >
                          Apply
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            {/* )} */}

            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div className="ant-dropdown-link">
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Discount-Discount-view") ===
          "Discount-Discount-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`OfferList for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {IsLoading == true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching discount details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                <div>
                  <input
                    type="checkbox"
                    className={`${commonstyles.parentCheckbox}`}
                    onChange={parentCheckbox}
                  ></input>
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: offerList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>

            {!IsLoading && Array.isArray(offerList) && offerList.length ? (
              <Pagination
                pageSizes={pageSizes}
                pageSize={pageSize}
                nextPage={nextPage}
                data={pagination}
                handlePaginationChanges={handlePaginationChanges}
              />
            ) : null}
          </PDFExport>
        )}

      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
        title="Confirm the action"
        confirmText="Delete"
      >
        {/* <p className="theme-txt-detail">{selectedCustomer.couponCode}</p> */}
        <div className="container-fluid" >
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
                Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
}
