import React, { useState, useEffect, useRef } from "react";
import styles from "../../../../styles/PaymentNew.module.css";
import { useHistory, useLocation } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Accordion, Card, Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { isObject, isString, isEqual } from "lodash";
import {
    onlyAlphabetsWithSpace,
    phoneRegx,
    passwordReg,
    minChar,
    onlyNumbers,
    minCharwithnospacecount,
    onlyAlphabets,
} from "../../../../utils/RegularExp";
import validationRules from "../../../../utils/validationRules";
import {
    formatServerValidtionErrors,
    runValidationChecks,
} from "../../../../utils";
import commonstyles from "../../../../styles/Management_Common.module.css";
import { DatePicker, notification, Select } from "antd";
import moment from "moment";
import { TimePicker } from "antd";
import WYSIWYGEditor from "../../../modules/ReusableComponent/WYSIWYGEditor";

const format = 'h:mm a';

export default function AddTemplateComponent(props) {
    const history = useHistory();
    const { state } = history.location;

    return (
        <div className={styles.management_contents}>
            <div className={styles.management_header}>
                <h1>
                    <img
                        src={BlackLeftArrow}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => {
                            history.push({
                                pathname: `/communications/newstemplate`,
                                state: props.selectedTab,
                            });
                        }}
                    />
                    {state?._id ? "Edit Template Information" : "Add Template Information"}
                </h1>
                <div className={styles.management_contents_upload}>
                    {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
                </div>
            </div>
            {props.isLoading === true ? (
                <tr className="bg-transparent d-flex justify-content-center">
                    <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching news letter template details..." />
                    </td>
                </tr>
            ) : (
                <div className={styles.management_details}>
                    <div className={styles.management_details_contents}>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Template Name
                                    <span className={`${commonstyles.asterik} text-danger`}> * </span>
                                </label>
                                <InputComponent
                                    error={props.formErrors?.title}
                                    className="form-control-lg"
                                    formControl={{
                                        placeholder: "News Letter...",
                                        type: 'text',
                                        value: props.editForm?.title,
                                        name: "title",
                                        onChange: props.inputOnChange,
                                    }}
                                />
                                <FormErrorText error={props.formErrors?.title} />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Template Subject
                                    <span className={`${commonstyles.asterik} text-danger`}> * </span>
                                </label>
                                <InputComponent
                                    error={props.formErrors?.subject}
                                    className="form-control-lg"
                                    formControl={{
                                        placeholder: "News Letter...",
                                        type: 'text',
                                        value: props.editForm?.subject,
                                        name: "subject",
                                        onChange: props.inputOnChange,
                                    }}
                                />
                                <FormErrorText error={props.formErrors?.subject} />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Start Date
                                    <span className={`${commonstyles.asterik} text-danger`}> * </span>
                                </label>
                                <DatePicker
                                    disabledDate={props.disabledDate}
                                    value={props.editForm?.startDate ? moment(props.editForm?.startDate, 'YYYY-MM-DD') : ''}
                                    onChange={(dateString) => props.onChangeDateHandler(dateString, "startDate")}
                                    style={{
                                        width: "100%",
                                        border: props.formErrors.startDate ? "#ff7575" : "1px solid #d9d9d9",
                                        boxShadow: props.formErrors.startDate ? "0 0 3px 1px #ff7575" : ''
                                    }}
                                />
                                <FormErrorText error={props.formErrors?.startDate} />
                            </div>
                            <div className="col-lg-6 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Start Time
                                    <span className={`${commonstyles.asterik} text-danger`}> * </span>
                                </label>
                                <TimePicker
                                    placeholder="01:00"
                                    value={props.editForm?.startTime && moment(props.editForm?.startTime, 'h:mm a')}
                                    onChange={props.onChangeTimeHandler}
                                    format={format}
                                    style={{
                                        width: "100%",
                                        border: props.formErrors.startTime ? "#ff7575" : "1px solid #d9d9d9",
                                        boxShadow: props.formErrors.startTime ? "0 0 3px 1px #ff7575" : ''
                                    }}
                                />
                                <FormErrorText error={props.formErrors?.startTime} />
                            </div>
                            <div className="col-lg-12 mb-4">
                                <label className={commonstyles.secondry_title}>
                                    Email Content
                                    <span className={`${commonstyles.asterik} text-danger`}> * </span>
                                </label>
                                {(!state?._id || (state?._id && props?.editForm?.content)) && <div className={`${commonstyles.management_details_form} ml-0 pl-0 pr-0`}>
                                    <WYSIWYGEditor
                                        content={props?.editForm?.content ? props?.editForm?.content : ''}
                                        options={props.editorOptions}
                                        onEditorStateChange={props.handleEditorUpdate}
                                    />
                                    <FormErrorText error={props.formErrors?.content} />
                                </div>}
                            </div>
                            <div className={`col-lg-12 ${styles.email_template_admin} text-right`}>
                                <span className={styles.email_template_btn_span}>
                                    <span><button onClick={props.saveChanges} className='theme-btn mr-3'>Save Changes</button></span>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            )}

        </div>)
}
