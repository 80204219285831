import styles from "../../../styles/AddProduct.module.css";
import EmbeddedDecorTableComponent from "./EmbeddedDecorTableComponent";

export default function RelatedDecorSelectFormComponent(props) {
  return (
    <div className="col-lg-12 mb-4">
      {props.isVisible ? (
        <EmbeddedDecorTableComponent
          decors={props.decors}
          selecteddecors={props.selecteddecors}
          handleSelection={props.updateSelecteddecors}
          formField={props.formField}
        />
      ) : null}
    </div>
  );
}
