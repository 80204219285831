import { useEffect, /* useRef, */ useState } from "react";
import { Select } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import InputComponent from "../ReusableComponent/InputComponent";
import SelectComponent from "../ReusableComponent/SelectComponent";
import FormErrorText from "./FormErrorText";
const { Option } = Select;
// const initial={
//   warrenty1:"",
//   warrenty2:""
// }

export default function ProductOptionSearchSelectComponent(props) {
  // console.log(props);
  // const [form, setForm]= useState(initial)
  const [isLoading, setIsLoading] = useState(false);

  function handleChangs(e){
    let { name, value } = e.target;
    // console.log(name, value);
    if(name==="warrenty1"){
      value=value.trim();
      props.handle(value,props.form.warrenty2);
    }else{
      props.handle(props.form.warrenty1,value);
    }

    if(name==="width"){
      value=value.trim();
      props.handleDimension(value,props.form.depth?props.form.depth:'',props.form.height?props.form.height:'');
    }
    // else if(name==="depth"){
    //   value=value.trim();
    //   props.handleDimension(props.form.width,value,props.form.height);
    // }else if(name==="height"){
    //   value=value.trim();
    //   props.handleDimension(props.form.width,props.form.depth,value);
    // }
    /* const updatedValues = { ...form };
    updatedValues[name] = value;
    setForm(updatedValues);
    console.log(form); */
  }
  return (
    <>
    {props.selectedId=== "60c0e08cb6b47120e857ab36" ? 
    <div className="row">
    <div className="col-lg-6" ref={props.currentRef}>
        <InputComponent
          // error={props.formErrors.warrenty1}
          className="form-control-lg"
          formControl={{
            placeholder: "Type number",
            name: "warrenty1",
            value:props.form?.warrenty1,
            onChange:handleChangs,

          }}
        />
        {/* <FormErrorText error={props.formErrors.warrenty1} /> */}
    </div>
    <div className="col-lg-6">
      <SelectComponent
          className={`form-control form-control-md ${styles.form_control}`}
           attributes={{
              // error:props.formErrors.warrenty2,
              name: "warrenty2",
              value:props.form?.warrenty2,
              onChange:handleChangs,
           }}
          >
          <option value="" disabled>Select an option</option>
          <option value="Days">Days</option>
          <option value="Weeks">Weeks</option>
          <option value="Months">Months</option>
          <option value="Years">Years</option>
      </SelectComponent>
      {/* <FormErrorText error={props.formErrors.warrenty2} /> */}
    </div>
  </div> : (props.selectedId=== "60c0d5bbb6b47120e857ab32") ? 
   <div className="row" ref={props.currentRefDim}>
   <div className="col-lg-12">
        {/* <label className="ml-3 mr-3 theme-form-label"> Width:{" "}
             <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}> * </span>
        </label> */}
       <InputComponent
         // error={props.formErrors.width}
         className="form-control-lg"
         formControl={{
           placeholder: "Type number",
           name: "width",
           value:props.form?.width,
           onChange:handleChangs,

         }}
       />
       {/* <FormErrorText error={props.formErrors.width} /> */}
   </div>
   {/* <div className="col-lg-4">
   <label className="ml-3 mr-3 theme-form-label"> Depth:{" "}
        </label>
       <InputComponent
         className="form-control-lg"
         formControl={{
           placeholder: "Type number",
           name: "depth",
           value:props.form?.depth,
           onChange:handleChangs,
         }}
       />
   </div> */}
   {/* <div className="col-lg-4">
   <label className="ml-3 mr-3 theme-form-label"> Height:{" "}
        </label>
       <InputComponent
         className="form-control-lg"
         formControl={{
           placeholder: "Type number",
           name: "height",
           value:props.form?.height,
           onChange:handleChangs,
         }}
       />
   </div> */}
   
 </div> :
    <Select
      showSearch
      mode="tags"
      placeholder="Search"
      loading={isLoading}
      onChange={props.handleParentIdUpdate}
      bordered={false}
      value={props.searchKey === ''? undefined: props.searchKey}
      className={`${commonstyles.form_control}  ${
        props.error && "theme-error-input"
      } form-control form-control-lg p-1`}
      filterOption={(input, option) =>
        option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
      }
    >
      {props.products.map((product) => (
        <Option value={product._id} key={product._id}>
          {product.value}
        </Option>
      ))}
    </Select>
  }
  </>
  );
}
