import React, {useEffect, useState} from 'react';
import { AxiosInstance, PORTS } from "../../../utils/apiService";


export default function OrderManagementComponent(props) {
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        getRegionList();
    }, []);

    function getRegionList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
          .get("/admin/region/getRegionList")
          .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
                if (localStorage.getItem('accessregion')) {
                    let arr = [];
                    res.data.daaata.forEach(element => {
                        if (element._id === localStorage.getItem('accessregion')) {
                            arr.push(element);
                        }
                    });
                    setRegions(arr);
                } else {
                    let obj = { name: "All Region", value: "", _id: "" }
                    res.data?.daaata?.unshift(obj);
                    setRegions(res.data.daaata);
                }
            }
          })
          .catch((er) => {
            console.log(er);
          });
    }

    return (
        <>
            {Array.isArray(regions) ? regions.map((region) => (
                <span key={region._id} onClick={() => props.filterRegionChange("regionId", region)}>
                    <label>{region.name}</label>
                </span>
                ))
            : null}
        </>
    )
}