import styles from "../../../styles/AddProduct.module.css";
import { Image } from "antd";
import { fallbackImagePlaceholder as fallbackImg } from "../../../utils";
import CloseIconSmall from "../../../assets/images/close_icon_small.svg";
// <div onClick={handleClose} className="inline-block">
{
  /* </div> */
}

const closeIconStyle = {
  position: "absolute",
  top: "-4px",
  right: "7px",
  width: "17px",
  zIndex: "1",
  height: "14px",
};

export default function ImagePreviewChip({ url, handleClose, hideCloseIcon }) {
  let title = "";
  if (typeof url === "string") {
    title = url.startsWith("blob") ? "Local file" : url;
  }

  return (
    <div className="d-inline-block position-relative my-1">
      {!hideCloseIcon&&<img
        src={CloseIconSmall}
        className={`${styles.close_icon} position-absolute cursor-pointer `}
        style={closeIconStyle}
        alt="CloseIcon"
        title="Remove"
        onClick={handleClose}
      />}
      <img
        width={"auto"}
        height={95}
        // title={title}
        src={url}
        // preview={false}
        alt="Preview"
        className={styles.added_image}
        // fallback={fallbackImg}
      />
    </div>
  );
}
