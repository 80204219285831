import styles from "../../../styles/SiteSettings.module.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserPermission from "../../../assets/images/settings_user_permission_img.svg";
import UserNotification from "../../../assets/images/settings_notification_img.svg";
import UserRegion from "../../../assets/images/settings_regions_img.svg";
import UserShipping from "../../../assets/images/settings_shipping_img.svg";
import UserPayment from "../../../assets/images/settings_payment_img.svg";
import UserGenaral from "../../../assets/images/settings_genaral_img.svg";
import ReversalCharges from "../../../assets/images/ReversalCharges.svg";
import Enquiry from "../../../assets/images/Enquiry.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";

export default function SiteSettings() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    history["isBulk"] = false;
    history["isRole"] = false;
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [
    UserPermission,
    UserNotification,
    UserRegion,
    UserShipping,
    UserPayment,
    UserGenaral,
  ]);

  function changeRoute(path) {
    history.push(`/site-settings${path}`);
  }

  return isLoading === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching site setting details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>Site settings</h1>
      </div>
      <div className={styles.management_details}>
        <div className={styles.management_details_contents}>
          <div className={styles.management_details_form}>
            <div className="row">
              <div
                className="col-lg-4 d-flex align-items-stretch mb-4"
                style={{ cursor: "pointer" }}
                onClick={() => changeRoute(`/user`)}
              >
                <div className={`${styles.media} ${styles.media_card}`}>
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={UserPermission}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">Users and permissions</h5>
                      <p style={{ fontSize: "11px" }}>
                        Super Admin can create roles, assign responsibilities to
                        region-wise admins & other user roles to ensure smooth
                        handling of Damro’s day to day operations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 d-flex align-items-stretch mb-4"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/site-settings/zone")}
              >
                <div className={`${styles.media} ${styles.media_card}`}>
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={UserShipping}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">Zone</h5>
                      <p style={{ fontSize: "11px" }}>
                        Zones plays an important role in handling delivery
                        charges and region-based pin-code wise delivery. Super
                        admin can create Zone, add cities, define shipping
                        charges based on cart value & zone associated. Shipping
                        days can be added and the same will be displayed to
                        customer in product detailed pages based on the pin-code
                        selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex align-items-stretch mb-4">
                <div
                  className={`${styles.media} ${styles.media_card} active`}
                  style={{ cursor: "pointer" }}
                  onClick={() => changeRoute(`/modular`)}
                >
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={Enquiry}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">Modular Enquiries</h5>
                      <p style={{ fontSize: "11px" }}>
                        View list of modular enquiries submitted by users and
                        filter the enquiries by region, date.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 d-flex align-items-stretch mb-4"
                onClick={() => changeRoute(`/region`)}
                style={{ cursor: "pointer" }}
              >
                <div className={`${styles.media} ${styles.media_card}`}>
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={UserRegion}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">Warehouse</h5>
                      <p style={{ fontSize: "11px" }}>
                        Admin can create a warehouse and link with a Daxer company
                        ID to sync products associated with a particular warehouse.
                        After creating a warehouse it has to be linked with one or
                        more zones associated with it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex align-items-stretch mb-4">
                <div
                  className={`${styles.media} ${styles.media_card}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => changeRoute(`/payment`)}
                >
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={UserPayment}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">Payments</h5>
                      <p style={{ fontSize: "11px" }}>
                        Manage PayU & CCAvenue Status and provision to add
                        payment gateway for a region
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex align-items-stretch mb-4">
                <div
                  className={`${styles.media} ${styles.media_card} active`}
                  style={{ cursor: "pointer" }}
                  // onClick={() => changeRoute(`/general`)}
                  onClick={() => history.push("/site-settings/profile")}
                >
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={UserGenaral}
                      alt="Generic placeholder image"
                    />
                    <div className={`media-body ${styles.media_body}`}>
                      <h5 className="mt-0">General</h5>
                      <p style={{ fontSize: "11px" }}>
                        Admin can modify their profile information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 d-flex align-items-stretch mb-4">
                <div className={`${styles.media} ${styles.media_card}`}>
                <div className="d-flex">
                  <img
                    className="mr-3"
                    src={UserNotification}
                    alt="Generic placeholder image"
                  />
                  <div
                    className={`media-body ${styles.media_body}`}
                    onClick={() => changeRoute(`/notification`)}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="mt-0">Notification</h5>
                    <p style={{ fontSize: "11px" }}>
                     Admin can send general notifications to users.
                     System will send order status & delivery notifications
                     to users based on order events automatically. 
                    </p>
                  </div>
                </div>
                </div>  
              </div> */}
              <div className="col-lg-4 d-flex align-items-stretch mb-4">
                <div className={`${styles.media} ${styles.media_card}`}>
                  <div className="d-flex">
                    <img
                      className="mr-3"
                      src={ReversalCharges}
                      alt="Generic placeholder image"
                    />
                    <div
                      className={`media-body ${styles.media_body}`}
                      onClick={() => changeRoute(`/refund`)}
                      style={{ cursor: "pointer" }}
                    >
                      <h5 className="mt-0">Reverse Logistics Charges</h5>
                      <p style={{ fontSize: "11px" }}>
                        Reversal logistics charges will be applicable for users
                        who are cancelling their orders after the delivery is
                        completed. Admin can define region-wise delivery charges
                        and it can be changed during the manual refund process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
