import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte, reject } from "lodash";
import { isAuthenticated, runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  noSpace,
  alphaNumwithSpace,
  alphanumericwithdashHyphen,
  minChar,
  minCharWithNoMaxLimit,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import { isObject, isString, isEqual, upperFirst } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import { MAIN_SITE } from "../../../utils/apiService"

const initialFormState = {
  title: "",
  slug: "",
  headerContent: "",
  footerContent: "",
  coupon: "",
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
  enable: true,
};

export default function AddPromotionalPage() {
  const rules = {
    title: [(val, data) => (!val ? "Title " : true)],
    // slug: [(val, data) => (!val ? "Slug " : true)],
    // regionId: [(val, data) => (data.regionId ? true : "Region ")],
    coupon: [(val, data) => (!val ? "coupon " : true)],
    headerContent: [(val, data) => (!val ? "Header content " : true)],
    footerContent: [(val, data) => (!val ? "Footer content " : true)],
    metaTitle: [(val, data) => (!val ? "Meta title " : true)],
    metaKeywords: [(val, data) => (!val ? "Meta keywords " : true)],
    metaDescription: [(val, data) => (!val ? "Meta description " : true)],
  };

  const history = useHistory();

  const { state } = history.location;

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editForm, setEditForm] = useState(
    JSON.parse(JSON.stringify(initialFormState))
  );
  const [formErrors, setFormErrors] = useState({});
  const [topContent, setTopContent] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [galleryImgs, setGalleryImgs] = useState([]);
  const [link, setLink] = useState("");

  const globalLinkToMainSite = `${MAIN_SITE}/offer/${editForm.slug}`;

  useEffect(() => {
    setFormErrors({});
    getCouponList();
    if (state?.id) {
      getpageDetails(state?.id);
    } else {
      setEditForm(initialFormState);
    }
    return () => {
      history["isPromotionalPage"] = true;
    };
  }, []);

  function getpageDetails(id) {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/promotionalPage/getpageDetails/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const val = res.data.data[0];
          setTopContent(val?.headerContent);
          setGalleryImgs(val?.gallery);
          setEditForm(val);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        setIsLoading(false);
        return er;
      });
  }

  function getCouponList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/promotionalPage/getCouponList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setCouponList(res.data.data);
        }
      })
      .catch((er) => {
        return er;
      });
  }
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * To show notification 
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInputSlug(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) {
      return false;
    } else {
      if (!alphanumericwithdashHyphen.test(event.target.value)) {
        formErrors.slug = "Enter only alphabet and number";
      }
      setEditForm({
        ...editForm,
        slug: event.target.value.toLowerCase().split(" ").join(""),
      });
    }
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;

    const updatedValues = { ...editForm, [name]: value };
    // if (name === "regionId") {
    //   setRegionId(value);
    //   getLandingList();
    // }
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    if (name === "coupon") {
      if (value) {
        couponList?.forEach((item) => {
          if (item?._id === value) {
            updatedValues.slug = item?.couponCode?.replace(" ", "-");
          }
        });
      } else {
        updatedValues.slug = "";
      }
    }
    setEditForm(updatedValues);
    if (name === "metaDescription" && !minChar.test(e.target.value)) {
      if (!formErrors.metaDescription) {
        formErrors.metaDescription = {};
      }
      formErrors.metaDescription = "Enter minimum 3 characters";
    } else {
      if (formErrors.metaDescription) {
        formErrors.metaDescription = "";
      }
    }
    // console.log(value);
    if (["title"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      if (!alphaNumwithSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaTitle"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaTitle = "Enter only alphabet and number";
      // }
 
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaKeywords"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaKeywords = "Enter only alphabet and number";
      // }
     
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaDescription"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaDescription = "Enter only alphabet and number";
      // }
     
      setEditForm({ ...editForm, [name]: value });
    }
  }

  // *** SAVE CHANGES *** //
  function submit() {
    setIsUpdating(true);
    let data = JSON.parse(JSON.stringify(editForm));
    data.slug = data?.slug?.trim();
    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      setIsUpdating(false);
      if (galleryImgs?.length === 0) {
        result.image = "Required gallery image";
        setFormErrors(result);
        return;
      }
      return;
    }
    if (galleryImgs?.length === 0) {
      result.image = "Required gallery image";
      setFormErrors(result);
      setIsUpdating(false);
      return;
    }
    
    if (state?.id) {
      data.id = data._id;
    }
    delete data?._id;
    delete data?.gallery;
    delete data?.updatedAt;
    delete data?.__v;
    delete data?.createdAt;
    let arr = [];
    galleryImgs.forEach((field, indx) => {
      if (field?._id) {
        arr.push(field);
      }
    });
    data["gallery"] = arr;

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    galleryImgs.forEach((field, indx) => {
      if (field?.name) {
        formData.append("gallery", field);
      }
    });

    let url = "";
    if (state?.id) {
      url = `/promotionalPage/updatePage`;
    } else {
      url = `/promotionalPage/addPage`;
    }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post(url, formData)
      .then((res) => {
        if (res.data.status === 200) {
          setIsUpdating(true);
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: editForm?._id
              ? "Coupon wise landing page updated successfully"
              : "Coupon wise landing page added successfully",
          });
          history.push(`/Content`);
          setIsUpdating(false);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res?.data?.msg,
          });
          setIsUpdating(false);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
        setIsUpdating(false);
      });
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            console.log(file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    console.log(editor);
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleImageInput = (e) => {
    const { name, files: inputFiles } = e.target;
    const file_name = inputFiles[0].name.toLowerCase();
    if (name) {
      if (!file_name.match(/\.(jpg|jpeg|png)$/)) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload only jpg,jpeg and png format images",
        });
        return;
      } else if (inputFiles[0].size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "There were few errors",
          description: "Please upload file less than 3MB size",
        });
        return;
      } else {
        const cloneGalleryImgs = [...galleryImgs];
        if (cloneGalleryImgs?.length < 10) {
          cloneGalleryImgs?.push(inputFiles[0]);
          setGalleryImgs(cloneGalleryImgs);
        } else {
          showNotification({
            type: "error",
            message: "There were few errors",
            description:
              "You can't able to add more than one image for this banner",
          });
          return;
        }
      }
    }
  };

  function removeImages(img, img_indx) {
    let arr = galleryImgs?.filter((x, indx) => indx !== img_indx);
    setGalleryImgs(arr);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          Promotional Landing Page Management
        </h1>
      </div>
      {isLoading === true ? (
        <div
          style={{
            marginLeft: "50vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching landing page details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div>
          <div
            className={`${styles.management_header} ${styles.banner_city_list}`}
          >
            <div className={`${styles.banner_citys}`}>
              {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                *
                            </span>
                            <FormErrorText error={formErrors.regionId} />
                            <div className={`${styles.banner_citys_update}`}>
                                {editForm?.userName && (
                                    <p style={{ textTransform: "capitalize" }}>
                                        Last updated by: <span>{editForm?.userName}</span>
                                    </p>
                                )}
                                {editForm?.updatedAt && (
                                    <p>
                                        Last updated on:{" "}
                                        <span>
                                            {moment(editForm?.updatedAt).format(
                                                "DD/MM/YYYY | hh:mm A"
                                            )}
                                        </span>
                                    </p>
                                )}
                            </div> */}
            </div>

            <div className={styles.management_contents_upload}>
              <div className={`${styles.onoffswitch} d-inline-block align-top`}>
                <input
                  type="checkbox"
                  name="enable"
                  className={styles.onoffswitch_checkbox}
                  onChange={handleFormInput}
                  checked={editForm?.enable}
                  id="statusSwitch"
                />
                <label
                  className={styles.onoffswitch_label}
                  htmlFor="statusSwitch"
                >
                  <span className={styles.onoffswitch_inner}></span>
                  <span className={styles.onoffswitch_switch}></span>
                </label>
              </div>
              <ButtonComponent
                className="rnd mr-3 outline_cancel_btn"
                attributes={{
                  onClick: () => history.push(`/Content`),
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className="rnd"
                attributes={{
                  onClick: submit,
                }}
              >
                {isUpdating ? "Saving..." : "Save"}
              </ButtonComponent>
            </div>
          </div>

          <div
            className={`${styles.management_details} ${styles.landing_category_management}`}
          >
            <div className={styles.management_details_contents}>
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Page title
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.title}
                      formControl={{
                        placeholder: "",
                        name: "title",
                        value: editForm?.title,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.title) {
                              formErrors.title = {};
                            }
                            formErrors.title = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.title) {
                              formErrors.title = "";
                            }
                          }

                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.title} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Coupon
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <select
                    className={"" + styles.form_control}
                    error={formErrors.coupon}
                    name="coupon"
                    value={editForm.coupon}
                    onChange={handleFormInput}
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <option></option>
                    {couponList.map((coupon) => (
                      <>
                        <option
                          value={coupon._id}
                          label={upperFirst(coupon.ruleName)}
                        >
                          {coupon.couponCode}
                        </option>
                      </>
                    ))}
                  </select>
                  <FormErrorText error={formErrors.coupon} />
                </div>
                {/* Slug */}
                <div className="col-lg-12 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Slug
                    <a href={globalLinkToMainSite} target="_blank">
                      {editForm.slug && `   ${globalLinkToMainSite}`}
                     
                    </a>
                
                  </label>
                  <InputComponent
                    error={formErrors.slug}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type slug",
                      name: "slug",
                      value: editForm?.slug,
                      disabled: true,
                      onChange: (e) => {
                        if (!minChar.test(e.target.value)) {
                          if (!formErrors.slug) {
                            formErrors.slug = {};
                          }
                          formErrors.slug = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.slug) {
                            formErrors.slug = "";
                          }
                        }
                        handleInputSlug(e);
                      },
                    }}
                  />
                  {/* <FormErrorText error={formErrors.slug} /> */}
                </div>
                {/* Slug ends */}
                <div className="col-lg-12 mb-4">
                  <div
                    className={`${styles.management_details_form} rich_text_hidden`}
                  >
                    <label for="">
                      Top Content
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>

                    {/* {page?._id && editForm.content && ( */}
                    <CKEditor
                      error={formErrors.headerContent}
                      editor={ClassicEditor}
                      data={topContent || ""}
                      config={{ extraPlugins: [uploadPlugin] }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };
                        values.headerContent = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.headerContent) {
                            formErrors.headerContent = {};
                          }
                          formErrors.headerContent = "Header content required";
                        } else {
                          if (formErrors.headerContent) {
                            formErrors.headerContent = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />
                    <FormErrorText error={formErrors.headerContent} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div
                    className={`${styles.management_details_form} rich_text_hidden`}
                  >
                    <label for="">
                      Bottom Content
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <CKEditor
                      error={formErrors.footerContent}
                      editor={ClassicEditor}
                      config={{ extraPlugins: [uploadPlugin] }}
                      data={editForm?.footerContent || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };
                        // console.log(data);
                        // console.log(values);
                        values.footerContent = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.footerContent) {
                            formErrors.footerContent = {};
                          }
                          formErrors.footerContent = "Footer content required";
                        } else {
                          if (formErrors.footerContent) {
                            formErrors.footerContent = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />
                    <FormErrorText error={formErrors.footerContent} />
                  </div>
                </div>
                {/* <div className="col-lg-12 mb-4"> */}
                <div className="col-lg-6 mb-4">
                  <div className="d-flex align-items-right justify-content-between">
                    <label className={commonstyles.secondry_title}>
                      Gallery{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                  </div>
                  <div className={`${commonstyles.banner_img_box_outline}`}>
                    <div
                      className={`${commonstyles.management_details_contents} mb-2 mt-2`}
                    >
                      <div
                        className={`${commonstyles.management_details_form} ${commonstyles.add_image_card} d-flex align-items-center justify-content-center`}
                      >
                        <input
                          name="image"
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          value=""
                          onChange={(e) => handleImageInput(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <FormErrorText error={formErrors.image} />
                </div>
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Preview image
                  </label>
                  <div className={`${commonstyles.banner_img_box_outline} `}>
                    {galleryImgs?.length > 0 &&
                      galleryImgs?.map((imgs, index) => (
                        <div className={`${commonstyles.banner_img_preview}`}>
                          <img
                            class={commonstyles.previewImgs}
                            key={index}
                            src={
                              imgs?.name
                                ? URL.createObjectURL(imgs)
                                : imgs?.link
                            }
                            alt={imgs?.name}
                          />
                          <img
                            onClick={() => removeImages(imgs, index)}
                            class={commonstyles.previewImgsdeleteicon}
                            src={bannerimgdelete}
                            alt="delete"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className={styles.management_details_contents}>
              <h4 className="theme-form-section-title">
                Search Engine Optimization
              </h4>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Title{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaTitle}
                      formControl={{
                        placeholder: "",
                        name: "metaTitle",
                        // error: formErrors.metaTitle,
                        value: editForm?.metaTitle,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaTitle) {
                              formErrors.metaTitle = {};
                            }
                            formErrors.metaTitle = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaTitle) {
                              formErrors.metaTitle = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaTitle} />
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Keywords{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaKeywords}
                      formControl={{
                        placeholder: "",
                        name: "metaKeywords",
                        value: editForm?.metaKeywords,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaKeywords) {
                              formErrors.metaKeywords = {};
                            }
                            formErrors.metaKeywords =
                              "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaKeywords) {
                              formErrors.metaKeywords = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaKeywords} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Description{" "}
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaDescription}
                      // formControl={{
                      placeholder=""
                      name="metaDescription"
                      value={editForm?.metaDescription}
                      onChange={(e) => handleFormInput(e)}
                    //   maxLength="40"
                    // }}
                    />
                    <FormErrorText error={formErrors.metaDescription} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
