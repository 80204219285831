import { notification, Space, Spin, Alert } from "antd";
import { isNumber } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/AddCustomer.module.css";
import { formatServerValidtionErrors } from "../../../utils";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import validationRules from "../../../utils/validationRules";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import IndianFlag from "../../../assets/images/indian_flag.svg";
import Exclusion from "../../../assets/images/exclusion.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import {
  emailRegx,
  minChar,
  onlyAlphabetsWithSpace,
  phoneRegx,
} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import { Link, useParams, useHistory } from "react-router-dom";
import { runValidationChecks } from "../../../utils";

const customerFields = [
  "_id",
  "name",
  "email",
  "password",
  "phone",
  "profileImg",
];

const rules = {
  name: [(val, data) => (!val ? "Name " : true)],
  email: [(val, data) => (!val ? "Email " : true)],
  phone: [(val, data) => (!val ? "Mobile " : true)],
};

export default function EditProductComponent(props) {
  const [ruleStatus, setRuleStatus] = useState(true);
  const [customer, setCustomer] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setisLoading] = useState({ detail: false, submit: false });
  const [profileImage, setProfileImage] = useState(null);
  const [banner, setBanner] = useState(null);
  const [file, setFile] = useState("");

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    //Request API to get complete user details;


    /**
   * @author Mithun Prabhu
   * @param {}
   * GET CUSTOMER DETAILS
   */
    function getCustomerDetails() {
      setisLoading({ detail: true, submit: false });

      AxiosInstance(PORTS.USER_SERVICE)
        .get(`viewCustomerDetails/${id}`)
        .then((res) => {
          if (res.data.status) {
            const customer = res.data.data[0];
            setCustomer(customer);
            setRuleStatus(customer.status === 1 ? true : false);
            if (customer.profileImg) {
              setBanner(customer.profileImg);
            }
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to get data",
          });
        })
        .finally(() => {
          setisLoading({ detail: false, update: false });
        });
    }
    getCustomerDetails();
  }, []);

  function handleInput(e) {
    const { name, value, files, checked } = e.target;

    if (["ruleStatus"].includes(name)) {
      console.log(name, checked);
      setRuleStatus(checked);
    }

    if (name === "profileImg") {
      // removeImagePreview();
      setFile(files[0]);

      if (banner) {
        URL.revokeObjectURL(banner);
      }
      const generatedUrl = URL.createObjectURL(files[0]);
      setBanner(generatedUrl);
    } else if (name === "phone") {
      const parsed = parseInt(value);
      if (!isNaN(parsed)) {
        setCustomer({ ...customer, [name]: String(parsed) });
      } else if (isNaN(parsed) && !value) {
        setCustomer({ ...customer, [name]: "" });
      }
    } else if (name === "name") {
      if (value.length == 1) {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please enter name more than 1 letter",
        });
        setCustomer({ ...customer, [name]: "" });
      }
      setCustomer({ ...customer, [name]: value });
    } else if (name === "email") {
      setCustomer({ ...customer, [name]: value });
    }
  }

  function removeImagePreview() {
    if (banner) {
      URL.revokeObjectURL(banner);
    }
  }

  function removeProfileImage() {
    if (customer.profileImg) {
      setCustomer({ ...customer, profileImg: "" });
    }

    if (banner) {
      setFile(null);
    }
    setBanner(null);
  }

  function createRequestData() {
    const data = {};
    // customerFields.forEach((f) => {
    //   data[f] = customer[f];
    // });
    data._id = customer._id;
    data.name = customer.name;
    data.email = customer.email;
    data.phone = customer.phone;
    data.profileImg = customer.profileImg;
    data.status = ruleStatus == true ? 1 : 2;

    return data;
  }

  function updateCustomer() {
    const data = createRequestData();

    setisLoading({ detail: false, update: true });
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    if (customer.profileImg !== "" || file) {
      formData.append("profileImg", file);
    }

    const result = runValidationChecks(rules, undefined, { ...data });
    console.log(formData);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      setisLoading({ detail: false, update: false });
      return;
    }

    if (!onlyAlphabetsWithSpace.test(customer.name)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabets",
      });
      setisLoading({ detail: false, update: false });
      return;
    }

    AxiosInstance(PORTS.USER_SERVICE)
      .post("editCustomerDetails", formData)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Customer details updated successfully",
          });
          history.push(`/customer`);
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.errs);

          showNotification({
            type: "error",
            message: "There were few errors",
            description: res?.data?.msg || "Error occured",
          });
          setFormErrors(errors);
          return;
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed to update customer",
          description: er?.message || "Error occured",
        });
      })
      .finally(() => {
        setisLoading({ detail: false, update: false });
      });
  }


  /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  return (
    <div className={styles.management_contents}>
      {/* Header */}
      <div
        className={`${styles.management_header} d-flex justify-content-between`}
      >
        <h1 className={styles.management_header_heading}>
          <Link to={`/customer`}>
            <img
              src={BlackLeftArrow}
              // onClick={`/customer`}
              alt=""
              className="cursor-pointer"
              title="back"
            />
          </Link>
          Edit Customer
        </h1>
        <div className="management_contents_upload">
          {/* <div className={`${styles.onoffswitch} d-inline-block align-top`}>
              <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deleteCustomer()}
                alt="Edit action icon"
                width="33px"
                style={{ cursor: "pointer" }}
              />
            </div> */}
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleInput(e)}
              checked={ruleStatus}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/customer`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: isLoading.update || isLoading.detail,
              onClick: updateCustomer,
            }}
          >
            {isLoading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/* Header ends*/}

      {isLoading.detail ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching customer details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-8">
            <div className={styles.management_details}>
              <div className={styles.management_details_contents}>
                <h4 className={commonstyles.medium_title}>Customer overview</h4>
                <div className={`${styles.management_details_form}`}>
                  <div className="row">
                    {/* Name */}
                    <div className="col-lg-6">
                      <label>
                        Name
                        <span
                          className="text-danger"
                          style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                        >
                          *
                        </span>
                      </label>
                      <InputComponent
                        className="form-control-lg"
                        error={formErrors.name}
                        formControl={{
                          placeholder: "Type customer name",
                          name: "name",
                          value: customer.name,
                          onChange: (e) => {
                            if (!minChar.test(e.target.value)) {
                              if (!formErrors.name) {
                                formErrors.name = {};
                              }
                              formErrors.name = "Enter minimum 3 characters";
                            } else {
                              if (formErrors.name) {
                                formErrors.name = "";
                              }
                            }
                            handleInput(e);
                          },
                          maxLength: 20,
                        }}
                      />
                      <FormErrorText error={formErrors.name} />
                    </div>
                    {/* Name ends*/}
                    {/* Email */}
                    <div className="col-lg-6">
                      <label>
                        Email
                        <span
                          className="text-danger"
                          style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                        >
                          *
                        </span>
                      </label>
                      <InputComponent
                        className="form-control-lg"
                        error={formErrors.email}
                        formControl={{
                          placeholder: "Type customer email",
                          name: "email",
                          value: customer.email,
                          onChange: (e) => {
                            if (!emailRegx.test(e.target.value)) {
                              if (!formErrors.email) {
                                formErrors.email = {};
                              }
                              formErrors.email = "Enter only valid email";
                            } else {
                              if (formErrors.email) {
                                formErrors.email = "";
                              }
                            }

                            handleInput(e);
                          },
                        }}
                      />
                      <FormErrorText error={formErrors.email} />
                    </div>
                    {/* Email ends*/}

                    {/* Mobile */}
                    <div className={`${styles.mobile_number} col-lg-6 mt-4`}>
                      <div className={"form-group"}>
                        <label>
                          Mobile{" "}
                          <span
                            className="text-danger"
                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                          >
                            *
                          </span>
                        </label>
                        <div className={commonstyles.adduser_input_countycode}>
                          <span>+91</span>
                          <InputComponent
                            maxLength="10"
                            className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                            error={formErrors.phone}
                            formControl={{
                              type: "number",
                              placeholder: "Type customer mobile",
                              name: "phone",
                              value: customer.phone,
                              onChange: (e) => {
                                if (!phoneRegx.test(e.target.value)) {
                                  if (!formErrors.phone) {
                                    formErrors.phone = {};
                                  }
                                  formErrors.phone = "Enter valid phone number";
                                } else {
                                  if (formErrors.phone) {
                                    formErrors.phone = "";
                                  }
                                }
                                handleInput(e);
                              },
                            }}
                          />
                        </div>
                        <FormErrorText error={formErrors.phone} />
                      </div>
                    </div>
                    {/* Mobile ends*/}
                  </div>
                </div>

                <div className="container">
                  <div className={`${styles.management_details_form} row mt-4`}>
                    <div className="">
                      {/* Profile image ends*/}
                      <div className="col-lg-12 my-4">
                        <div className={styles.upload_img_header}>
                          <h4 className={commonstyles.medium_title}>
                            Profile image
                          </h4>
                          {/* <a href="#">Add Image/Video URL</a> */}
                        </div>
                        {/* <div
                          className={`${commonstyles.management_details_form} ${styles.add_image_card}`}
                        > */}
                        {banner ? (
                          <ImagePreviewChip
                            url={banner}
                            handleClose={removeProfileImage}
                          />
                        ) : (
                          <div
                            className={` ${styles.upload_img} text-center pt-4`}
                          >
                            {/* <img src={Exclusion} alt="" /> */}
                            <div>
                              <label
                                htmlFor="iamgeFileInput"
                                className={`${styles.btn} px-2 `}
                                style={{ cursor: "pointer" }}
                              >
                                Add file
                                {/* <button className={styles.btn}>Add files</button> */}
                                <input
                                  className="d-none"
                                  name="profileImg"
                                  type="file"
                                  accept="image/jpeg, image/png, image/svg+xml"
                                  id="iamgeFileInput"
                                  onChange={(e) => handleInput(e)}
                                />
                              </label>
                            </div>
                            <span>or drop a file to Upload</span>
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                      {/* Profile image ends*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
