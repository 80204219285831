import { Image } from "antd";
import { useState } from "react";
import styles from "../../../styles/AddProduct.module.css";
import CloseIconSmall from "../../../assets/images/close_icon_small.svg";
import videoThumbPlaceholderImg from "../../../assets/images/video_thumb_placeholder.png";
// import axios from "axios";

const closeIconStyle = {
  position: "absolute",
  top: "-4px",
  right: "7px",
  width: "17px",
  zIndex: "1",
  height: "14px",
};

// const vimeoRegex =
//   /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

// const youTubeRegex =
//   /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

export function VideoPreviewChip({ url, name, handleClose }) {
  const [videoThumb, setVideoThumb] = useState(videoThumbPlaceholderImg);

  // useEffect(() => {
  //   const isVimeo = vimeoRegex.test(url);

  //   if (isVimeo) {
  //     let temp = url.split("").reverse().join("");
  //     let indx = temp.indexOf("/");
  //     temp = temp.substring(0, 9);
  //     temp = temp.split("").reverse().join("");
  //     // const videoID =
  //     const vimeoURL = `http://vimeo.com/api/v2/video/${temp}.json`;
  //     fetchVimeoThumbnail(vimeoURL);
  //   }

  //   function fetchVimeoThumbnail(vimeoURL) {
  //     axios({
  //       method: "get",
  //       url: vimeoURL,
  //       responseType: "application/json",
  //     })
  //       .then((res) => {
  //         setVideoThumb(res.data[0].thumbnail_medium);
  //       })
  //       .catch((er) => console.log("axios error"));
  //   }
  // });
  function handleClick() {
    if (url) {
      window.open(url, "_blank");
    }
  }
  const title = url ? `Go to ${url}` : `Local file: ${name}`;

  return (
    <div className="d-inline-block position-relative my-1">
      <img
        src={CloseIconSmall}
        className={`${styles.close_icon} position-absolute cursor-pointer `}
        style={closeIconStyle}
        alt="CloseIcon"
        title="Remove"
        onClick={handleClose}
      />
      <Image
        width={"auto"}
        height={95}
        src={videoThumb}
        preview={false}
        alt="Preview"
        title={title}
        className={`${styles.added_image} ${url ? "cursor-pointer" : ""}`}
        placeholder={
          <Image
            preview={false}
            src={videoThumbPlaceholderImg}
            width={"auto"}
            height={95}
          />
        }
        fallback={videoThumbPlaceholderImg}
        onClick={handleClick}
      />
    </div>
  );
}
