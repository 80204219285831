import React from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../../styles/FetchUser.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import Plus from "../../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { Link } from "react-router-dom";
import SearchComponent from "../../SearchManagement/SearchComponent";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import { arrayMove } from "react-movable";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import {
  Dropdown,
  Menu,
  message as massages,
  Select,
  notification,
  Tooltip,
} from "antd";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import dots from "../../../../assets/images/column_dots.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import LineTax from "../Tax Management/TaxList";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import moment from "moment";
import { filter } from "lodash";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "RuleName", value: "RuleName" },
  { _id: 3, status: 1, name: "Tax Percentage", value: "Tax Percentage" },
  { _id: 5, status: 1, name: "Regions", value: "Regions" },
  { _id: 6, status: 1, name: "Categories", value: "Categories" },
  { _id: 7, status: 1, name: "Action", value: "action" },
];

function GstList() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();
  const [IsLoading, setIsLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [nextPage, setNextPage] = useState(1);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [roleType, setRoleType] = useState("");

  const [ruleOrder, setruleOrder] = useState();
  const [percentageOrder, setpercentageOrder] = useState();

  const [columns, setColumns] = useState([
    // {
    //   visible: false,
    //   key: "select",
    //   render: (text, record, index) => (
    //     <input
    //       style={{ cursor: "pointer" }}
    //       type="checkbox"
    //     //   checked={record.isChecked}
    //     //   onChange={(e) => childCheckbox(e, record)}
    //     className={`${commonstyles.childCheckbox}`}
    //     ></input>
    //   ),
    // },
    {
      title: "RuleName",
      visible: false,
      key: "RuleName",
      sorter: true,
      render: (text, record, index) => <span>{record.ruleName}</span>,
    },
    {
      title: "Tax Percentage",
      visible: false,
      key: "Tax Percentage",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            {record?._id? <span>{record?._id} %</span> : '-'}
          </>
        )
      }
    },
    {
      title: "Regions",
      visible: false,
      key: "Regions",
      render: (text, record, index) => (
        <span>
          {record.regions[0]}{" "}
          {"," + record?.regions[1] ? record?.regions[1] : ""} ...
        </span>
      ),
    },
    {
      title: "Categories",
      visible: false,
      key: "Categories",
      render: (text, record, index) => (
        <span>
          {record.categories[0]}{" "}
          {"," + record?.categories[1] ? record?.categories[1] : ""} ...
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Sales-Tax-edit") ===
        "Sales-Tax-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit tax"
            // className="tooltip_list"
            >
              <img
                src={adminProductEdit}
                className={commonstyles.admin_table_edit_img}
                onClick={() => editRole(record)}
                alt="Edit action icon"
                style={{ cursor: "pointer" }}
              // title="Edit tax"
              />
            </Tooltip>
          </td>
        ),
    },
  ]);

  useEffect(() => {
    set_new_header(headers);
    getGstList(pageSize, nextPage, "");


  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  const editRole = (list) => {
    history.push({
      pathname: `/tax/add`,
      state: list,
    });
  };
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  function getGstList(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filterarr && filterarr.ruleOrder !== "") {
      filterOptions.ruleOrder = Number(filterarr.ruleOrder);
    } else if (ruleOrder !== "" && ruleOrder !== null) {
      if (filterarr && ruleOrder !== "") {
        filterOptions.ruleOrder = Number(ruleOrder);
      }
    }
    if (filterarr && filterarr.percentageOrder !== "") {
      filterOptions.percentageOrder = Number(filterarr.percentageOrder);
    } else if (percentageOrder !== "" && percentageOrder !== null) {
      if (filterarr && percentageOrder !== "") {
        filterOptions.percentageOrder = Number(percentageOrder);
      }
    }

    // if (filterOptions.ruleOrder) {
    //   delete filterOptions.percentageOrder;
    // } else {
    //   delete filterOptions.ruleOrder;
    // }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/tax/getLineTaxtDetails", filterOptions)
      .then((res) => {
        if (res && res.data && (res.status === "200" || res.status === 200)) {
          setIsLoading(false);
          setRoleList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function searchHandler(e) {
    // setSearch(e.target.value);
    // setNextPage(1);
    // let updatedFilter = {
    //   nextpage: 1,
    //   search: e.target.value,
    // };
    // debounceSearch(updatedFilter);
    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
        ruleOrder: ruleOrder,
        percentageOrder: percentageOrder
      };
      debounceSearch(updatedFilter);
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    // // console.log(sorter);
    let updatedFilter = {
      search: search,
      // nextpage: nextPage,
      // regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      // filterDays: filterDays,
      // startDate: filteredDate?.startDate,
      // endDate: filteredDate?.endDate,
      // sortOrder: sorter.order === "descend" ? 1 : 0
    };
    if (sorter?.columnKey === "RuleName") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.ruleOrder = sortValue;
      setruleOrder(sortValue);
      setpercentageOrder();
    } else if (sorter?.columnKey === "Tax Percentage") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.percentageOrder = sortValue;
      setpercentageOrder(sortValue);
      setruleOrder();
    }
    getGstList(pageSize, 1, updatedFilter, true);
  };

  function debounceSearch(filter) {
    setNextPage(1);
    getGstList(pageSize, 1, filter,true);
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      // regionId: regionId,
      ruleOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getGstList(value, 1, updatedFilter, type);
    } else if (type === "nextPage") {
      setNextPage(value);
      getGstList(pageSize, value, updatedFilter, type);
    }
  }

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      ruleOrder: ruleOrder,
      percentageOrder: percentageOrder
    };
    debounceSearch(updatedFilter);
  }

  return (
    <>
      <div className={`${commonstyles.management_table} table-responsive`}>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Tax-view") ===
          "Sales-Tax-view" && (
            <div
              className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
              style={{ marginTop: "20px" }}
            >
              <div
                className={`${commonstyles.management_contents_search_bar} input-group`}
              >
                <input
                  type="search"
                  className={`${commonstyles.form_control} form-control`}
                  aria-label="Search"
                  placeholder="Search by rule name,tax percentage..."
                  aria-describedby="search-addon"
                  value={search}
                  onChange={searchHandler}
                />
                <img src={Search} alt="" />
              </div>
              <ButtonComponent
                className="theme-btn rnd pl-3 pr-4 ml-2"
                attributes={{
                  onClick: () => {
                    searchClick();
                  },
                }}
              >
                Search
              </ButtonComponent>
              <Dropdown
                overlay={menu}
                className="usermanage_table_filter_columns ml-3"
              >
                <div
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Column <img src={arrowDown} width={10} height={20} />
                </div>
              </Dropdown>
            </div>
          )}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Tax-view") ===
          "Sales-Tax-view" && (
            <div className={styles.administrator_table}>
              <div className={styles.administrator_table_tabs}></div>

              <div className={"table-responsive " + styles.management_table}>
                {/* {!IsLoading?.list && roleList.length > 0 && (
              <input
                type="checkbox"
                className={`${commonstyles.parentCheckbox}`}
              // onChange={parentCheckbox}
              ></input>
            )} */}
                {IsLoading === true ? (
                  <div
                    style={{
                      marginLeft: "70vh",
                      marginTop: "10vh",
                    }}
                  >
                    <tr className="bg-transparent">
                      <td colSpan="100%">
                        <LoadingIndicator loadingText="Fetching GST details..." />
                      </td>
                    </tr>
                  </div>
                ) : (
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: roleList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
        {IsLoading === false && Array.isArray(roleList) && roleList.length
          ? permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Sales-Tax-view") ===
          "Sales-Tax-view" && (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          ) :
          null
        }
      </div>
    </>)


}


export default GstList;
