import styles from "../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import React, { useEffect, useState } from "react";
// import AddCustomer from "./AddCustomer";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Space, Spin } from "antd";
import Pagination from "../../modules/ReusableComponent/Pagination";
import EditCustomerComponent from "../CustomerManagement/EditCustomerComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import DefaultImage from "../../../assets/images/profile_dummy.svg";
import { Link, useParams } from "react-router-dom";
import Plus from "../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
// import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
// import BulkUploadComponent from "./BulkUploadComponent";
import SearchComponent from "../SearchManagement/SearchComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

export default function CustomerManagementComponent() {
  const [mailList, setMailList] = useState([]);

  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });

  // const [isLoading, setIsLoading] = useState(true);

  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });

  useEffect(() => {
    let searchNow = "";
    getMailList(pageSize, nextPage, searchNow);
  }, []);

  // const { _id } = useParams();

  const { history } = useHistory();

  /**
   * @author Mithun Prabhu
   * @param {Event}
   * SET SEARCH AS INPUT SEARCH CHANGES AND CALL MAIL LIST API
   */
  function searchHandler(e) {
    setSearch(e.target.value);
    setNextPage(1);
    getMailList(pageSize, 1, e.target.value);
  }


   /**
   * @author Mithun Prabhu
   * @param {pageSize,nextPage,searchNow}
   * GET ALL MAIL DETAILS AND DISPLAY RECORDS
   */
  function getMailList(pageSize, nextPage, searchNow) {
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search: searchNow,
    };

    setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/mail/listmail", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setMailList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getMailList(value, 1, search);
    } else if (type === "nextPage") {
      setNextPage(value);
      getMailList(pageSize, value, search);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {mail}
   * SHOW DELETE CONFIRMATION MODAL AND CONFIRM DELETE CALLS AND DELETE PARTICULAR RECORD
   */
  function deleteCustomer() {
    // const sureDelete = window.confirm("Are you sure want to delete???");
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/mail/deletemail/${selectedCustomer._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Mail deleted successfully",
          });
          getMailList(pageSize, nextPage);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to remove customer",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /**
   * @author Mithun Prabhu
   * @param {mail}
   * SHOW DELETE CONFIRMATION MODAL AND CONFIRM NOT TO DELETE
   */
  function cancelDelete() {
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

   /**
   * @author Mithun Prabhu
   * @param {mail}
   * SHOW DELETE CONFIRMATION MODAL AND CONFIRM DELETE
   */
  function showDeleteConfirmation(mail) {
    setSelectedCustomer(mail);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

 
  return (
    <div className={commonstyles.management_contents}>
      <div className={styles.management_contents}>
        <SearchComponent />
      </div>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <Link to={`/site-settings`}>
            <img src={BlackLeftArrow} alt="" />
          </Link>
          Mail Management
        </h1>
      </div>
      <div className={commonstyles.management_contents_search}>
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by Template Name...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: (e) => searchHandler(e),
              ["aria-label"]: "Search customers",
              ["aria-describedby"]: "search-customers",
            }}
          />
          <img src={Search} alt="" />
        </div>
        {/* <div>
          <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: () => {
                // setFilter(intialFilterState);
                setChildComponentVisibility({
                  ...childComponentVisibility,
                  bulkUpload: true,
                });
                window.scrollTo(0, 0);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Bulk Upload
          </ButtonComponent>
        </div> */}
        <div className={commonstyles.management_contents_upload}>
          <Link to="/site-settings/notification/add">
            <span style={{ cursor: "pointer" }}>
              <img src={Plus} alt="" />
              Add Mail
            </span>
          </Link>
        </div>
      </div>

      <div className={`${commonstyles.management_table} table-responsive`}>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Template Name</th>
              <th scope="col">Mail From</th>
              <th scope="col">Sender Name</th>
              <th scope="col">Description</th>
              <th scope="col">Subject</th>
              <th scope="col">Operate</th>
            </tr>
          </thead>
          <tbody>
            {IsLoading.list === true ? (
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching mail details..." />
                </td>
              </tr>
            ) : Array.isArray(mailList) && mailList.length ? (
              mailList.map((mail) => (
                <tr key={mail._id}>
                  <td style={{ textTransform: "capitalize" }}>
                    {mail.templatename}
                  </td>
                  <td>{mail.fromemail}</td>
                  <td>{mail.sendername}</td>
                  <td>{mail.description}</td>
                  <td>{mail.subject}</td>
                  <td>
                    <Link to={`/site-settings/notification/${mail._id}`}>
                      <img
                        src={adminProductEdit}
                        className={`${commonstyles.admin_table_edit_img} mr-2`}
                        // onClick={() => editCustomer(customer)}
                        alt="Edit action icon"
                        title="Edit mail"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                    <img
                      src={adminProductDelete}
                      className={commonstyles.admin_table_edit_img}
                      onClick={() => showDeleteConfirmation(mail)}
                      alt="Delete action icon"
                      title="Delete mail"
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-transparent text-info">
                <td colSpan="3">No data found!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!IsLoading.list && Array.isArray(mailList) && mailList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Category delete confirmation modal ends*/}
    </div>
  );
}
