import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/FetchUser.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Plus from "../../../assets/images/plus_white_icon.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import AddUser from "./AddUser";
import EditUserComponent from "./EditUser";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Link } from "react-router-dom";
import SearchComponent from "../SearchManagement/SearchComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import { arrayMove } from "react-movable";
import {
  Dropdown,
  Menu,
  message as massages,
  Select,
  notification,
  Tooltip,
} from "antd";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import dots from "../../../assets/images/column_dots.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import moment from "moment";
import { AllModules_, buttons, treeData_ } from "../../modules/ReusableComponent/PermissionObject";
import { upperFirst } from "lodash";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
    // { _id: 1, status: 1, name: "Select", value: "select" },
    { _id: 2, status: 1, name: "Roles", value: "roles" },
    { _id: 1, status: 1, name: "Access", value: "access" },
    { _id: 3, status: 1, name: "Action", value: "action" }
];

const filters = [
    { id: 1, isChecked: false, name: "Roles", value: "roles" }
];

const status_filter = [
    { _id: 1, name: "Full", value: 1 },
    { _id: 0, name: "Limited", value: 0 }
];

function RoleManagement() {
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [IsLoading, setIsLoading] = useState({ delete: false, list: true });
    const [roleList, setRoleList] = useState([]);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [pagination, setPagination] = useState({});
    const [nextPage, setNextPage] = useState(1);
    const [headers, setHeaders] = useState(originalHeaders);
    const [items, setItems] = useState(originalHeaders);
    const [search, setSearch] = useState("");
    const [selectFilter, setSelectFilter] = useState(filters);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [rolesFilter, setRolesFilter] = useState(status_filter);
    const [roleType, setRoleType] = useState("");
    const [paymentName, setPaymentName] = useState("All")
    const [seletedRole, setSeletedRole] = useState("");
    const [isDeleteModule, setIsDeleteModule] = useState(false);
    const [columns, setColumns] = useState([
        // {
        //   visible: false,
        //   key: "select",
        //   render: (text, record, index) => (
        //     <input
        //       style={{ cursor: "pointer" }}
        //       type="checkbox"
        //     //   checked={record.isChecked}
        //     //   onChange={(e) => childCheckbox(e, record)}
        //     ></input>
        //   ),
        // },
        {
          title: "Roles",
          visible: false,
          key: "roles",
          render: (text, record, index) => <span>{upperFirst(record.name)}</span>
        },
        {
          title: "Access",
          visible: false,
          key: "access",
          render: (text, record, index) => <span>{record?.roleType? 'Full' : 'Limited'}</span>,
        },
        {
          title: "Action",
          visible: false,
          key: "action",
          render: (text, record, index) => (
            <span>
             <Tooltip
              placement="topRight"
              title="Edit role"
             >
              <img
                src={adminProductEdit}
                className={commonstyles.admin_table_edit_img}
                onClick={() => editRole(record)}
                alt="Edit action icon"
                // title="Edit"
                style={{ cursor: "pointer" }}
              />
              </Tooltip>
              <Tooltip
              placement="topRight"
              title="Delete Role"
            > <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deletedUser(record)}
                alt="Delete action icon"
                style={{ cursor: "pointer", padding:"0 10px" }}
              /></Tooltip>
            </span>
          ),
        },
    ]);

    useEffect(() => {
        set_new_header(headers);
        userRole(pageSize, nextPage, "");
        AllModules_.forEach(item => {
            item.data.forEach(subItem => {
                subItem.add = false;
                subItem.edit = false;
                subItem.view = false;
            })
        });
    }, []);

    const set_new_header = (response) => {
        var newArr = [];
        const data = response;
        for (let i = 0; i < data.length; i++) {
          const columnsClone = [...columns];
          let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
          if (columnsClone[objIndex] !== undefined) {
            columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
            newArr.push(columnsClone[objIndex]);
          }
        }
        setColumns(newArr);
    };

    const editRole = (list) => {
        history.push({
            pathname: `/site-settings/role/add`,
            state: list,
        });
    }

    //TO SHOW TABLE HEADERS BASED ON STATUS
    const renderHeader = (props) => {
        return (
        <tr>
            {columns.map((item, idx) => {
            if (item.visible) return props.children[idx];
            })}
        </tr>
        );
    };

    // * TO SHOW TABLE DATA BASED ON HEADER STATUS
    const renderBody = (props) => {
        if (props.children.length > 0) {
        return (
            <tr className={props.className}>
            {columns.map((item, idx) => {
                if (item.visible) {
                return props.children[idx];
                }
            })}
            </tr>
        );
        } else {
        return (
            <tr className="bg-transparent text-info">
            <td colSpan="3">No data found!</td>
            </tr>
        );
        }
    };

      const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0? 1 : oldIndex;
    newIndex = newIndex === 0? 1 : newIndex;
        setItems(arrayMove(items, oldIndex, newIndex));
        const itemClone = [...items];
        let values = rearrange_array(itemClone, oldIndex, newIndex);
        fetchNewOrder(values);
    };

    const rearrange_array = (arr, oldIndex, newIndex) => {
        if (newIndex >= arr.length) {
        let i = newIndex - arr.length + 1;
        while (i--) {
            arr.push(undefined);
        }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };
    
    const fetchNewOrder = async (items) => {
        let newStatus = [];
        items.map((item, index) => {
        let obj = {};
        obj.order = index;
        obj.status = item.status;
        obj.value = item.value;
        obj._id = item._id;
        newStatus.push(obj);
        });
        setItems(newStatus);
        set_new_header(newStatus);
    };
    
    const on_field_check_handler = (e, id) => {
        const itemClone = [...items];
        let objIndex = items.findIndex((obj) => obj._id === id);
        itemClone[objIndex].status = e.target.checked ? 1 : id === 2? 1 : 0;
        setItems(itemClone);
        fetchNewOrder(items);
    };

    function deletedUser(userDetail) {
        setSeletedRole(userDetail._id);
        setIsDeleteModule(true);
      }
    
      function cancelDelete() {
        setSeletedRole("");
        setIsDeleteModule(false);
      }

    const menu = (
        <Menu className="columns_list">
        <MovableListComponent
            listProps={{
            values: items,
            onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
            renderList: ({ children, props }) => (
                <ul {...props} style={{ listStyleType: "none" }}>
                {children}
                </ul>
            ),
            renderItem: ({ value, props }) => (
                <li {...props} key={value._id} className="columns_list_item">
                {value.value !== "select" && (
                    <>
                    <input
                        type="checkbox"
                        checked={value.status === 1 ? true : false}
                        onChange={(event) =>
                        on_field_check_handler(event, value._id)
                        }
                    />
                    <span class="checkmark" style={{ cursor: "move" }}></span>
                    <span>
                        {value.value
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                            return str.toUpperCase();
                        })}
                        <img src={dots} alt="image" />
                    </span>
                    </>
                )}
                </li>
            ),
            }}
        />
        </Menu>
    );


    /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * Role list fetched and displayed
   */
    function userRole(pageSize, nextPage, filterarr, type) {
        const filterOptions = {
            pagesize: pageSize,
            nextpage: nextPage,
        };
        if(!type) {
            setIsLoading({ delete: false, list: true });
        }
        if (filterarr && filterarr.search) {
            filterOptions.search = filterarr.search;
        } else if (search !== "") {
            if (filterarr && filterarr.search !== "") {
                filterOptions.search = search;
            }
        }
        if (filterarr && filterarr.roleType !== "") {
            filterOptions.roleType = parseFloat(filterarr.roleType);
        } else if (roleType !== "") {
            filterOptions.roleType = parseFloat(roleType);
        }
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
            .post("admin/role/listRole", filterOptions)
            .then((res) => {
                if (res && res.data && (res.status === "200"||res.status === 200)) {
                    setIsLoading(false);
                    setRoleList(res.data.data);
                    setPagination(res.data.pagination);
                }
            })
            .catch((er) => {
                const data = { type: "error" };
                if (er.response) {
                    data.message = "Error";
                    data.description = "Failed to get data";
                    showNotification(data);
                }
            })
            .finally(() => {
                setIsLoading({ delete: false, list: false });
            });
    }

    function showNotification({ type, message, description }) {
        let options = {
            message: message || "Message",
            description: description,
            duration: 5,
        };
        notification[type](options);
    }

    function searchHandler(e) {
        const { value } = e.target;
    
        setSearch(value);
        if (!value) {
          const updatedFilter = {
            search:  "",
            nextpage: 1
          };
          debounceSearch(updatedFilter);
        }
    }

    function debounceSearch(filter) {
        setNextPage(1);
        userRole(pageSize, 1, filter);
    }

    function filterOnChange(name, value) {
        // const { name, value } = e.target;
        let updatedFilter = {
          search: search,
          nextpage: 1,
          roleType: roleType,
        };
        updatedFilter.roleType = value._id;
        setRoleType(value._id);
        setPaymentName(value?.name);
        debounceSearch(updatedFilter);
    }

    
  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
     
    
    };
    debounceSearch(updatedFilter);
  }

    const filterMenu = (
        <Menu className="filter_dropdown">
            <div className="card">
            <div className="card_contents">
                <div className="card_info">Filter by</div>
                <div className="row no-gutters">
                    <div className="card_dropdown col-md-6">
                        <div className="filter_heading card_dropdown_heading">
                        Select Filter <img src={arrowDown} width={10} height={20} />
                        </div>
                        <div className="card_dropdown_contents">
                        {Array.isArray(selectFilter)
                            ? selectFilter.map((filter) => (
                                <span key={filter._id}>
                                <input
                                    type="checkbox"
                                    checked={filter.isChecked}
                                    onChange={(e) => commonFilterHandler(e, filter)}
                                ></input>
                                <label>{filter.name}</label>
                                </span>
                            ))
                            : null}
                        </div>
                    </div>
                    {selectedFilters.length > 0 &&
                    selectedFilters.find((x) => x === "roles") === "roles" && (
                        <div className="card_dropdown show col-md-6">
                            {/* <select
                                className="form_control"
                                name="roleType"
                                value={roleType}
                                onChange={filterOnChange}
                            >
                                <option value="">All</option>
                                {Array.isArray(rolesFilter)
                                ? rolesFilter.map((x) => (
                                    <option key={x._id} value={x._id}>
                                        {x.name}
                                    </option>
                                    ))
                                : null}
                            </select> */}
                            <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                                {paymentName}<img alt="" src={arrowDown} width={10} height={20} />
                            </div>
                            <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                                {Array.isArray(rolesFilter)
                                ? rolesFilter.map((pay) => (
                                    <span key={pay._id} onClick={() => filterOnChange("paymentMethod", pay)}>
                                        <label>{pay.name}</label>
                                    </span>
                                ))
                                : null}
                            </div>
                        </div>
                    )}
                </div>
                </div>
            </div>
        </Menu>
    );
    
     /**
   * @author Rajkumar
   * @param {event}
   * To get filters of all parent check box
   */
    const commonFilterHandler = (e, data) => {
        const { checked } = e.target;
        let cloneSelectedFilters = [...selectedFilters];
        const cloneSelectFilter = [...selectFilter];
        let updatedFilter = {
          search: search,
          nextpage: 1,
        };
        cloneSelectFilter.forEach((item) => {
          if (item.id === data.id) {
            item.isChecked = checked;
            if (checked) {
              cloneSelectedFilters.push(item.value);
            } else {
              cloneSelectedFilters = cloneSelectedFilters.filter(
                (item) => data.value !== item
              );
            }
          }
          if (!item.isChecked && item.id === data.id) {
            if (item.value === "roleType") {
              updatedFilter.roleType = "null";
              setRoleType("");
                setPaymentName("");
            }
            debounceSearch(updatedFilter);
          }
        });
        setSelectedFilters(cloneSelectedFilters);
        setSelectFilter(cloneSelectFilter);
    };

    function handlePaginationChanges(value, type) {
        if (type === "pageSize") {
          setPageSize(value);
          setNextPage(1);
          userRole(value, 1, search);
        } else if (type === "nextPage") {
          setNextPage(value);
          userRole(pageSize, value, search);
        }
    }

    function confirmDelete() {
        AxiosInstance(PORTS.USER_SERVICE, "application/json")
          .delete(`/admin/role/${seletedRole}`)
          .then((res) => {
            if (res.data.status === 200) {
              console.log("res.data",res.data)
              cancelDelete();
              showNotification({
                type: "success",
                message: "Success",
                description: "User details deleted successfully",
              });
              userRole(pageSize, nextPage, "");
            } else {
              cancelDelete();
              showNotification({
                type: "error",
                message: "Error",
                description: "First, delete the users assigned to this role, and then delete the roles.",
              });
            }
          })
          .catch((er) => {
            showNotification({
              type: "error",
              message: "Failed",
              description: "Error occured",
            });
          });
      }

    const handleVisibleChange = (flag) => {
        setVisible(flag);
      };

    return (
        <>
            <div className={`${styles.administrator} ${styles.sitesetting_list_bg}`} style={{border:"none"}}>
              <div className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}>
                    <div
                        className={`${commonstyles.management_contents_search_bar} input-group`}
                    >
                        <input
                            type="search"
                            className={`${commonstyles.form_control} form-control`}
                            aria-label="Search"
                            placeholder="Search by Role name.."
                            aria-describedby="search-addon"
                            value={search}
                            onChange={searchHandler}
                        />
                        <img src={Search} alt="" />
                    </div>
                    <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
                        <Dropdown
                            overlay={filterMenu}
                            onVisibleChange={handleVisibleChange} visible={visible}
                            className="usermanage_table_filter_columns ml-3"
                        >
                            <div
                                className="ant-dropdown-link"
                                onClick={(e) => e.preventDefault()}
                            >
                                Filter <img src={arrowDown} width={10} height={20} />
                            </div>
                        </Dropdown>
                        <Dropdown
                            overlay={menu}
                            className="usermanage_table_filter_columns ml-3"
                        >
                            <div
                                className="ant-dropdown-link"
                                onClick={(e) => e.preventDefault()}
                            >
                                Columns <img src={arrowDown} width={10} height={20} />
                            </div>
                        </Dropdown>
                    
                </div>
                <div className={styles.administrator_table}>
                    <div className={styles.administrator_table_tabs}>
                    </div>

                    <div className={"table-responsive " + styles.management_table}>
                        {/* {!IsLoading?.list && roleList.length > 0 && (
                            <input
                                type="checkbox"
                                className={`${commonstyles.parentCheckbox}`}
                                // onChange={parentCheckbox}
                            ></input>
                        )} */}
                        {IsLoading?.list === true ? (
                            <tr className="bg-transparent">
                                <td colSpan="100%">
                                    <LoadingIndicator loadingText="Fetching order details..." />
                                </td>
                            </tr>
                        ) : (
                            <AntTableComponent
                                className="order_table"
                                tableProps={{
                                    columns: columns,
                                    dataSource: roleList,
                                    pagination: false,
                                    // onChange: handleTableChange,
                                    components: {
                                        header: {
                                            row: (props) => renderHeader(props),
                                        },
                                        body: {
                                            row: (props) => renderBody(props),
                                        },
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
                <ConfirmationBox
                            modal={{
                            show: isDeleteModule,
                            size: "md",
                            centered: true,
                            }}
                            handleCancel={cancelDelete}
                            handleConfirm={confirmDelete}
                            title="Confirm the action"
                            confirmText="Delete"
                        >
                            <div className="container-fluid">
                            <div className="row">
                                <div className="col-7">
                                <span className="theme-txt-subtitle">
                                Are you sure want to delete?
                                </span>
                                </div>
                            </div>
                            </div>
                        </ConfirmationBox>
            </div>
            {Array.isArray(roleList) && roleList.length ? (
                <Pagination
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    nextPage={nextPage}
                    data={pagination}
                    handlePaginationChanges={handlePaginationChanges}
                />
            ) : null}
        </>
        
    )
    
}

export default RoleManagement;