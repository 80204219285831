// import styles from "../../styles/ProductManagement.module.css";
import { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom"
import AddFAQComponent from "./AddFAQComponent";
//import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
// import EditProductComponent from "./EditProductComponent";
// import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import plusIcon from "../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { useHistory } from "react-router";
const intialFilterState = {
  categoryId: "",
  regionId: "",
  searchKey: "",
  status: "",
  pagesize: 10,
  nextpage: 1,
};

export default function ContactUsManagementComponent() {
  const [parentCategories, setParentCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState);
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [selectedProduct, setSelectedProduct] = useState({});
  const timeoutId = useRef();

  const history = useHistory();

  /* useState(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
          }
        })
        .catch((er) => {
          const error = {
            type: "error",
            message: "Error",
            description: "Failed to get data",
          };
          showNotification(error);
        });
    }

    function showNotification({ type, message, description }) {
      let options = {
        message: message || "Message",
        description: description,
        duration: 5,
      };
      notification[type](options);
    }

    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
    getParentCategories();
    getRegionList();
    // getProducts(filter);
  }, []); */

  useEffect(() => {
    //getProducts(filter);
  }, [
    filter.pagesize,
    filter.nextpage,
    filter.categoryId,
    filter.status,
    filter.regionId,
  ]);

  function debounceSearch(filter) {
    // console.log(filter);
    clearTimeout(timeoutId.current);
    setFilter({ ...filter });
    timeoutId.current = setTimeout(() => {
      //getProducts(filter);
    }, 500);
  }

   function handleInput(e) {
    const { name, value } = e.target;
    const updatedFilter = { ...filter };
    updatedFilter[name] = value;

    if (name === "searchKey") {
      updatedFilter["nextpage"] = 1;
      updatedFilter["searchKey"] = value;
      debounceSearch(updatedFilter);
    } else {
      setFilter(updatedFilter);
    }
    // } else {
    //   getProducts(updatedFilter);
    // }
  } 

  /* function getProducts(filterParam) {
    setIsLoading({ ...IsLoading, list: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .post("/admin/catalog/productList/", filterParam)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // console.log(res.data.data.products);
          // const reslt = res.data.data[0];
          setProducts(res.data.data.products);
          setPagination(res.data.pagination);
          // setPagination({ ...pagination, total: reslt.totalProducts });
        }
      })
      .catch((er) => {
        console.log("getProducts error");
        console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get product data",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, list: false });
      });
  } */
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setFilter({ ...filter, pagesize: value, nextpage: 1 });
    } else if (type === "nextPage") {
      setFilter({ ...filter, nextpage: value });
    }
  }

  /* function editProduct(product) {
    console.log(product)
    setFilter(intialFilterState);
    const selectedItem = JSON.parse(JSON.stringify(product));
    setSelectedProduct(selectedItem);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  } */

  /* function closeEditProduct(isEdited) {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
    if (isEdited) {
      getProducts(filter);
    }
  }
 */
  /* function showdelete(product) {
    setSelectedProduct(product);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  } */

  /* function cancelDelete() {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }
 */
  /* function removeProduct() {
    setIsLoading({ ...IsLoading, delete: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteProduct/${selectedProduct._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setFilter(intialFilterState);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getProducts(filter);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete product",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete product",
        });
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, delete: false });
        setChildComponentVisibility({
          ...childComponentVisibility,
          delete: false,
        });
      });
  } */

  /* if (childComponentVisibility.addProduct) {
    return (
      <AddFAQComponent
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
        <></>
      <EditProductComponent
        product={selectedProduct}
        handleClose={closeEditProduct}
      /> 
    );
  }  else if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
      product="product"
        handleClose={() =>
          {setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          })
          getProducts()}
        }
      />
    );
  } */
  function addContact() {
    history.push(`/contactus/add`);
  } 

  function editContact(contact) {
    history.push({
      pathname: `/contactus/edit`,
      state: contact,
    });
  }

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Contact Us
        </h1>
      </div>
      <div className={commonstyles.management_contents_search}>
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            aria-describedby="search-addon"
            name="searchKey"
            placeholder="Search by ID, Contact... "
            value={filter.searchKey}
            autoComplete="off"
            onChange={handleInput}
          />
          <img src={Search} alt="Search icon" />
        </div>
        <div className={commonstyles.management_contents_upload}>
          {/* <ButtonComponent
            className="theme-btn-outlined rnd px-3 mr-3"
          >Export
          </ButtonComponent> */}
          {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: () => {
                setFilter(intialFilterState);
                setChildComponentVisibility({
                  ...childComponentVisibility,
                  bulkUpload: true,
                });
                window.scrollTo(0, 0);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Bulk Upload
          </ButtonComponent> */}
        
          <span style={{ cursor: "pointer" }} onClick={addContact}>
            <img src={Plus} alt="" />
            Add Contact
          </span>
        </div>
      </div> 
      <div className={`${commonstyles.management_table} table-responsive`}>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">ID</th>
              <th scope="col">Contact</th>
              
              <th scope="col">Operate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* {IsLoading.list === true ? (
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching products" />
                </td>
              </tr>
            ) : Array.isArray(products) && products.length ? (
              products.map((product) => ( */}
                <tr /* key={product._id} */>
                  {/* Check */}
                  <td>
                    {/* <div
                      className={` ${commonstyles.custom_control} ${commonstyles.custom_checkbox} custom-control custom-checkbox text-center`}
                    >
                      <input
                        disabled
                        type="checkbox"
                        className={` ${commonstyles.custom_control_input} custom-control-input`}
                        id={`checkBox${product._id}`}
                      />
                      <label
                        className={`${commonstyles.custom_control_label} custom-control-label cursor-pointer`}
                        htmlFor={`checkBox${product._id}`}
                      />
                    </div> */}
                  </td>
                  {/* Checkbox ends */}
                  {/* ID*/}
                  <td>1234{/* {product.tallyId?product.tallyId:'-'} */}</td>
                  <td>DEMRO,
                      Address: bangalore- 580001.</td>
                  <td>
                    {/* <Link to={`/catalog/products/edit/${product.slug}`}> */}
                    <img
                      src={adminProductEdit}
                      className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                      onClick={() => editContact()}
                      alt="Edit action icon"
                      action="Edit product"
                    />
                    {/* </Link> */}
                    

                    <img
                      src={deleteIcon}
                      className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                      //onClick={() => showdelete(product)}
                      alt="Delete product action icon"
                      title="Delete product"
                    />
                  </td>
                  {/* Edit button ends*/}
                </tr>
             {/*  ))
            ) : (
                <>
              <NoProductsTableRowComponent
                products={products}
                filter={filter}
              /> 
              </>
            )}*/}
          </tbody>
        </table>
      </div>
      {!IsLoading.list && Array.isArray(products) && products.length ? (
        <Pagination
          pageSizes={[10, 20, 50]}
          pageSize={filter.pagesize}
          nextPage={filter.nextpage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}
      {/* Product delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.delete,
          size: "md",
          centered: true,
        }}
        //handleCancel={cancelDelete}
        //handleConfirm={removeProduct}
        title="Delete product"
        confirmText="Delete"
        loading={IsLoading.delete}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-auto">
              {selectedProduct.image && (
                <img
                  src={selectedProduct.image}
                  className={commonstyles.admin_table_img}
                  alt="Product image"
                  style={{ width: "80px", height: "auto" }}
                />
              )}
            </div>
            <div className="col-7">
              <span className="theme-txt-subtitle">{selectedProduct.name}</span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
      {/* Product delete confirmation modal ends*/}
    </div>
  );
}
