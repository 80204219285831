import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum } from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Tabs } from "antd";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import moment from "moment-timezone";

const avenueNew = {
  enable: true,
  regionList: "",
  apiKey: "",
  apiSecret: "",
  accessCode: "",
  subAccountId: "",
};
const avenue = JSON.parse(JSON.stringify(avenueNew));

const payuNew = {
  enable: true,
  regionList: "",
  apiKey: "",
  apiSecret: "",
};
const payU = JSON.parse(JSON.stringify(payuNew));

const rules = {
  avenueform: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validateAvenueformItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
  payuform: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validatePayUformItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
};

function validateAvenueformItem(accumulator, avenueItem, indx) {
  const error = {};
  ["regionId", "apiKey", "apiSecret", "accessCode", "subAccountId"].forEach(
    (field) => {
      if (!avenueItem[field]) {
        error[field] = "Required";
      }
      if (!field === "regionId" || !alphaNum.test(avenueItem[field])) {
        error[field] = "Enter only Alphabet and number";
      }
    }
  );
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

function validatePayUformItem(accumulator, avenueItem, indx) {
  const error = {};
  ["regionId", "apiKey", "apiSecret"].forEach((field) => {
    if (!avenueItem[field]) {
      error[field] = "Required";
    }
    if (!field === "regionId" || !alphaNum.test(avenueItem[field])) {
      error[field] = "Enter only Alphabet and number";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

export default function PaymentNew() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });

  const [payuform, setPayuForm] = useState([]);
  const [avenueform, setAvenueform] = useState([]);

  const [regionList, setRegionList] = useState([]);
  const [lastUpdatedBy, setLastUpdatedBy] = useState({
    cc: undefined,
    payu: undefined
  });

  const [currentTab, setCurrentTab] = useState("avenue");
  const [isLoading, setIsLoading] = useState(true);
  const { TabPane } = Tabs;

  const selectedRegionIds = Array.isArray(avenueform)
    ? avenueform.map((stock) => stock.regionId)
    : [];
  const selectedRegionIdsPayU = Array.isArray(payuform)
    ? payuform.map((stock) => stock.regionId)
    : [];
  useEffect(() => {
    getRegionList();
    getPaymentDetails();
  }, [avenueform?._id, payuform?._id]);
  /**
   * @author Rajkumar
   * TO GET PAYMENT DETAILS
   */
  function getPaymentDetails() {
    // setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/payment/paymentConfig`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setPayuForm(res.data.data.payu);
          setAvenueform(res.data.data.ccavenue);
          if(res?.data?.data?.payuLastUpdatedBy || res?.data?.data?.ccLastUpdatedBy){
            setLastUpdatedBy({ cc: res?.data?.data?.ccLastUpdatedBy, payu: res?.data?.data?.payuLastUpdatedBy})
          }
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }
  /**
   * @author Rajkumar
   * TO GET REGION LIST
   */
  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.data.status === 200) {
          res.data.daaata.unshift({
            name: "",
            _id: "",
          });
          setRegionList(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function submit(e) {
    let data;
    if (currentTab === "avenue") {
      data = avenueform.map((item) => {
        return {
          _id: item._id,
          enable: item.enable,
          regionId: item.regionId,
          apiKey: item.apiKey,
          apiSecret: item.apiSecret,
          accessCode: item.accessCode,
          subAccountId: item.subAccountId,
        };
      });
      const result = runValidationChecks(
        { avenueform: rules.avenueform },
        undefined,
        { avenueform: data }
      );
      if (Object.keys(result).length) {
        setFormErrors(result);
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
        return;
      }
    } else {
      data = payuform.map((item) => {
        return {
          _id: item._id,
          enable: item.enable,
          regionId: item.regionId,
          apiKey: item.apiKey,
          apiSecret: item.apiSecret,
        };
      });
      const result = runValidationChecks(
        { payuform: rules.payuform },
        undefined,
        { payuform: data }
      );
      if (Object.keys(result).length) {
        setFormErrors(result);
        showNotification({
          type: "warning",
          message: "There were few errors",
          description: "Resolve the errors and try again",
        });
        return;
      }
    }

    const apiEndPoint =
      currentTab === "avenue"
        ? "/admin/config/ccPaymentConfig"
        : "/admin/config/paymentConfigPayU";

    AxiosInstance(PORTS.REGION_SERVICE)
      .post(apiEndPoint, { data })
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Payment updated successfully",
          });
        }
        history.push(`site-settings`);
      })
      .catch((er) => {
        console.log(er);
      });
  }
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleAvenueFormChange(e, indx) {
    let { name, value, checked } = e.target;
    value = value.trim();
    if (name === "enable") {
      avenueform[indx][name] = checked;
      setAvenueform([...avenueform]);
    }
    if (
      [
        "apiKey",
        "apiSecret",
        "accessCode",
        "regionId",
        "subAccountId",
      ].includes(name)
    ) {
      avenueform[indx][name] = value;
      setAvenueform([...avenueform]);

      if (!name === "regionId" || !alphaNum.test(value)) {
        if (!formErrors.avenueform) {
          formErrors.avenueform = {};
        }
        if (formErrors.avenueform && !formErrors.avenueform[indx]) {
          formErrors.avenueform[indx] = {};
        }
        formErrors.avenueform[indx][name] =
          "Enter only Alphabet, Number and min 3 & mix 50 character";
      } else {
        if (
          // !value &&
          formErrors.avenueform &&
          formErrors.avenueform[indx] &&
          formErrors.avenueform[indx][name]
        ) {
          formErrors.avenueform[indx][name] = "";
        }
      }
    }
  }

  function handlePayuChangeHandler(e, indx) {
    let { name, value, checked } = e.target;
    value = value.trim();
    if (["enable"].includes(name)) {
      payuform[indx][name] = checked;
      setPayuForm([...payuform]);
    }
    if (["apiKey", "apiSecret", "regionId"].includes(name)) {
      payuform[indx][name] = value;
      setPayuForm([...payuform]);
      if (!name === "regionId" || !alphaNum.test(value)) {
        if (!formErrors.payuform) {
          formErrors.payuform = {};
        }
        if (formErrors.payuform && !formErrors.payuform[indx]) {
          formErrors.payuform[indx] = {};
        }
        formErrors.payuform[indx][name] =
          "Enter only Alphabet, Number and min 3 & mix 50 character";
      } else {
        if (
          formErrors.payuform &&
          formErrors.payuform[indx] &&
          formErrors.payuform[indx][name]
        ) {
          formErrors.payuform[indx][name] = "";
        }
      }
    }
  }

  function callback(key) {
    setCurrentTab(key);
  }

  function addNew() {
    if (currentTab === "avenue") {
      const updatedValue = JSON.parse(JSON.stringify(avenueform));
      const newAvenue = JSON.parse(JSON.stringify(avenue));
      updatedValue.push(newAvenue);
      setAvenueform(updatedValue);
    } else {
      const updatedValue = JSON.parse(JSON.stringify(payuform));
      const newPayU = JSON.parse(JSON.stringify(payU));
      updatedValue.push(newPayU);
      setPayuForm(updatedValue);
    }
  }

  const [deletingId, setDeletingId] =  useState('');
  
  function cancelDelete() {
    setDeletingId('')
  }

  function removeCategory() {
    AxiosInstance(PORTS.REGION_SERVICE)
      .get(`/admin/config/PaymentDelete/${deletingId}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg
          });
          getPaymentDetails();
          setDeletingId('')
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const removePayment = (RemoveItem) => {
    if (RemoveItem?._id) {
      setDeletingId(RemoveItem._id);
    } else {
      if (currentTab === "avenue") {
        const updatedValue = JSON.parse(JSON.stringify(avenueform));
        updatedValue.pop();
        setAvenueform(updatedValue);
      } else {
        const updatedValue = JSON.parse(JSON.stringify(payuform));
        updatedValue.pop();
        setPayuForm(updatedValue);
      }
    }
  }

  return isLoading === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching payment details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/site-settings")}
          />
          Payment
        </h1>
        {
          currentTab === "avenue" ? 
          <div className={`${styles.banner_citys_update}`}>
          {lastUpdatedBy?.cc?.updatedBy?.name  && (
                <p>
                  Last update by: <span>{lastUpdatedBy?.cc?.updatedBy?.name}</span>
                </p>
              )}
              {lastUpdatedBy?.cc?.updatedAt && (
                <p>
                  Last update on:{" "}
                  <span>
                    {moment(lastUpdatedBy?.cc?.updatedAt).format(
                      "DD/MM/YYYY | hh:mm A"
                    )}
                  </span>
                </p>
              )}
          </div> : <div className={`${styles.banner_citys_update}`}>
          {lastUpdatedBy?.payu?.updatedBy?.name  && (
                <p>
                  Last update by: <span>{lastUpdatedBy?.payu?.updatedBy?.name}</span>
                </p>
              )}
              {lastUpdatedBy?.payu?.updatedAt && (
                <p>
                  Last update on:{" "}
                  <span>
                    {moment(lastUpdatedBy?.payu?.updatedAt).format(
                      "DD/MM/YYYY | hh:mm A"
                    )}
                  </span>
                </p>
              )}
          </div>
        }
       
                <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              onClick: submit,
            }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details}>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "SiteSettings-Payments-edit") ===
            "SiteSettings-Payments-edit" && (
            <div className="product-tabsbox">
              <Tabs onChange={callback} type="card">
                <TabPane tab="CCAvenue" key="avenue">
                  {currentTab === "avenue" &&
                    avenueform.map((avenue, indx) => (
                      <React.Fragment key={indx}>
                        <div className={styles.management_details_contents}>
                          <div className="row">
                            <div className="col-lg-6 mb-4">
                              <div
                                className={`${styles.management_details_form} ${styles.label_toggle}`}
                              >
                                <label for="" className="">
                                  {/* Most Desirable */}
                                  Status
                                </label>
                                <div className={styless.onoffswitch}>
                                  <input
                                    type="checkbox"
                                    name="enable"
                                    className={styless.onoffswitch_checkbox}
                                    id={"myonoffswitch1" + indx}
                                    checked={avenue.enable}
                                    onChange={(e) =>
                                      handleAvenueFormChange(e, indx)
                                    }
                                  />
                                  <label
                                    className={styless.onoffswitch_label}
                                    for={"myonoffswitch1" + indx}
                                  >
                                    <span
                                      className={styless.onoffswitch_inner}
                                    ></span>
                                    <span
                                      className={styless.onoffswitch_switch}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                Warehouse ID
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <SelectComponent
                                  className={`form-control form-control-md ${styles.form_control}`}
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].regionId
                                  }
                                  attributes={{
                                    name: "regionId",
                                    value: avenue.regionId,
                                    onChange: (e) =>
                                      handleAvenueFormChange(e, indx),
                                  }}
                                >
                                  {Array.isArray(regionList)
                                    ? regionList.map((region) => (
                                        <option
                                          key={region._id}
                                          value={region._id}
                                          disabled={selectedRegionIds.includes(
                                            region._id
                                          )}
                                        >
                                          {region.name}
                                        </option>
                                      ))
                                    : null}
                                </SelectComponent>
                                <FormErrorText
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].regionId
                                      ? "Required"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Merchant Id
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].apiKey
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "apiKey",
                                    value: avenue.apiKey,
                                    onChange: (e) =>
                                      handleAvenueFormChange(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].apiKey
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Working Key
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].apiSecret
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "apiSecret",
                                    value: avenue.apiSecret,
                                    onChange: (e) =>
                                      handleAvenueFormChange(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].apiSecret
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Access Code
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].accessCode
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "accessCode",
                                    value: avenue.accessCode,
                                    onChange: (e) =>
                                      handleAvenueFormChange(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].accessCode
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Sub Account Id
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].subAccountId
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "subAccountId",
                                    value: avenue.subAccountId,
                                    onChange: (e) =>
                                      handleAvenueFormChange(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.avenueform &&
                                    formErrors.avenueform[indx] &&
                                    formErrors.avenueform[indx].subAccountId
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginLeft: '815px' }} className="col-lg-6 mb-4">
                              <ButtonComponent
                                  className="rnd"
                                  attributes={{
                                    onClick: ()=>{removePayment(avenue)},
                                  }}
                                >
                                Remove
                              </ButtonComponent>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </TabPane>
                <TabPane tab="PayU" key="payu">
                  {currentTab === "payu" &&
                    payuform.map((pay, indx) => (
                      <React.Fragment key={indx}>
                        <div className={styles.management_details_contents}>
                          <div className="row">
                            <div className="col-lg-6 mb-4">
                              <div
                                className={`${styles.management_details_form} ${styles.label_toggle}`}
                              >
                                <label for="" className="">
                                  {/* Most Desirable */}
                                  Status
                                </label>
                                <div className={styless.onoffswitch}>
                                  <input
                                    type="checkbox"
                                    name="enable"
                                    className={styless.onoffswitch_checkbox}
                                    id={"myonoffswitch1" + indx}
                                    checked={pay.enable}
                                    onChange={(e) =>
                                      handlePayuChangeHandler(e, indx)
                                    }
                                  />
                                  <label
                                    className={styless.onoffswitch_label}
                                    for={"myonoffswitch1" + indx}
                                  >
                                    <span
                                      className={styless.onoffswitch_inner}
                                    ></span>
                                    <span
                                      className={styless.onoffswitch_switch}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                Warehouse ID
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <SelectComponent
                                  className={`form-control form-control-md ${styles.form_control}`}
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].regionId
                                  }
                                  attributes={{
                                    name: "regionId",
                                    value: pay.regionId,
                                    onChange: (e) =>
                                      handlePayuChangeHandler(e, indx),
                                  }}
                                >
                                  {Array.isArray(regionList)
                                    ? regionList.map((region) => (
                                        <option
                                          key={region._id}
                                          value={region._id}
                                          disabled={selectedRegionIdsPayU.includes(
                                            region._id
                                          )}
                                        >
                                          {region.name}
                                        </option>
                                      ))
                                    : null}
                                </SelectComponent>
                                <FormErrorText
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].regionId
                                      ? "Required"
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Key
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].apiKey
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "apiKey",
                                    value: pay.apiKey,
                                    onChange: (e) =>
                                      handlePayuChangeHandler(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].apiKey
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                              <div className={styles.management_details_form}>
                                <label for="">
                                  Salt
                                  <span
                                    className="text-danger"
                                    style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                                  >
                                    *
                                  </span>
                                </label>
                                <InputComponent
                                  className={`form-control form-control-lg ${styles.form_control}`}
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].apiSecret
                                  }
                                  formControl={{
                                    placeholder: "",
                                    name: "apiSecret",
                                    value: pay.apiSecret,
                                    onChange: (e) =>
                                      handlePayuChangeHandler(e, indx),
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    formErrors.payuform &&
                                    formErrors.payuform[indx] &&
                                    formErrors.payuform[indx].apiSecret
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginLeft: '815px' }} className="col-lg-6 mb-4">
                              <ButtonComponent
                                  className="rnd"
                                  attributes={{
                                    onClick: ()=>{removePayment(pay)},
                                  }}
                                >
                                Remove
                              </ButtonComponent>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </TabPane>
              </Tabs>
            </div>
          )}
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "SiteSettings-Payments-add") ===
            "SiteSettings-Payments-add" && (
            <div className={styles.payment_btn}>
              <button className={`btn ${styles.btn_filled}`} onClick={addNew}>
                Add Payment
              </button>
            </div>
          )}

            {/* Payment delete confirmation modal starts*/}
            <ConfirmationBox
              modal={{
                show: deletingId? true : false,
                size: "md",
                centered: true,
              }}
              handleCancel={cancelDelete}
              handleConfirm={removeCategory}
              title="Confirm the action"
              confirmText="Delete"
            >
              <div className="container-fluid">
                <div className="row">
                  <p className="theme-txt-detail">
                  Are you sure want to delete?
                  </p>
                </div>
              </div>
            </ConfirmationBox>
            {/* Payment delete confirmation modal ends*/}
      </div>
    </div>
  );
}
