import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import validationRules from "../../../utils/validationRules";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import Compressor from 'compressorjs'; 
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg"
import {
  userNameWithSpace,
  minCharWithNoMaxLimit,
  onlyLatLong
} from "../../../utils/RegularExp";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = Select;

// const colorOption = {
let vehiccle = "";
// };

const vehicle = JSON.parse(JSON.stringify(vehiccle));

const initialStateCodes = {
  "JAMMU AND KASHMIR": 1,
  "HIMACHAL PRADESH": 2,
  "PUNJAB": 3,
  "CHANDIGARH": 4,
  "UTTARAKHAND": 5,
  "HARYANA": 6,
  "DELHI": 7,
  "RAJASTHAN": 8,
  "UTTAR PRADESH": 9,
  "BIHAR": 10,
  "SIKKIM": 11,
  "ARUNACHAL PRADESH": 12,
  "NAGALAND": 13,
  "MANIPUR": 14,
  "MIZORAM": 15,
  "TRIPURA": 16,
  "MEGHALAYA": 17,
  "ASSAM": 18,
  "WEST BENGAL": 19,
  "JHARKHAND": 20,
  "ODISHA": 21,
  "CHATTISGARH": 22,
  "MADHYA PRADESH": 23,
  "GUJARAT": 24,
  "DADRA AND NAGAR HAVELI": 26, // "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)": 26,
  "MAHARASHTRA": 27,
  "ANDHRA PRADESH": 28, //"ANDHRA PRADESH(BEFORE DIVISION)": 28,
  "KARNATAKA": 29,
  "GOA": 30,
  "LAKSHADWEEP": 31,
  "KERALA": 32,
  "TAMIL NADU": 33,
  "PUDUCHERRY": 34,
  "ANDAMAN AND NICOBAR ISLANDS": 35,
  "TELANGANA": 36,
  "ANDHRA PRADESH (NEW)": 37,//  "ANDHRA PRADESH (NEW)": 37,
  "LADAKH (NEWLY ADDED)": 38,
  "OTHER TERRITORY": 97,
  "CENTRE JURISDICTION": 99

}

function AddCity() {
  const initialFormState = {
    name: "",
    regionId: "",
    state: "",
    enable: true,
    latitude: "",
    longitude: "",
    sortValue: null,
    topContent: "",
    bottomContent:"",
    images:[]
  };
  const rules = {
    name: [validationRules.required],
    regionId: [validationRules.required],
    latitude: [validationRules.required],
    state: [validationRules.required],
    longitude: [validationRules.required],
  };
  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const { state } = history.location;
  const [isLoading, setIsLoading] = useState(true);
  const [stateCode, setStateCode] = useState(initialStateCodes);
  const [regions, setRegions] = useState("")



  useEffect(() => {
    const { state } = history.location;
    if (state?._id) {
      setEditForm({
        name: state.name || "",
        regionId: state.regionId || "",
        state: state.state || "",
        enable: state.enable ,
        latitude: state.latitude || "",
        longitude: state.longitude || "",
        sortValue: state.sortValue || null,
        topContent: state.topContent || "",
        bottomContent: state.bottomContent || "",
        images:state.images || ""
      })
      console.log("stae.enable",state.enable);
      
      setIsLoading(false)
    }else{
      setIsLoading(false)
    }
    getRegionList()
    
  }, [history.location]);

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let i =1
          const dataValue = res.data.daaata.map(data=>{
            return {...data, id:i++} 
          })
          setRegions(dataValue);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    if (name === "name") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {

        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {

        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "latitude") {
      if (!e.target.value.match(onlyLatLong) || !e.target.value === "") {
        console.log("if part")
        setFormErrors({
          ...formErrors,
          [name]: "Enter only number",
        });
      } else {
        console.log("else part")
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "longitude") {
      if (!e.target.value.match(onlyLatLong) || !e.target.value === "") {
        console.log("if part")
        setFormErrors({
          ...formErrors,
          [name]: "Enter only number",
        });
      } else {
        console.log("else part")
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };

    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
  }
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }


  // image upload
  // Image upload handler with preview - modified for single image
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    
    // Check if we already have an image
    if (editForm?.images?.length >= 1) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Only one image allowed. Please remove existing image first.",
      });
      return;
    }

    // Take only the first file if multiple are selected
    const file = files[0];
    if (!file) return;

    const fileName = file.name.toLowerCase();
    
    // Validate file type
    if (!fileName.match(/\.(jpg|jpeg|png)$/)) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload only jpg, jpeg, and png format images",
      });
      return;
    }
    
    // Validate file size (3MB limit)
    if (file.size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload file less than 3MB size",
      });
      return;
    }

    // Compress and create preview
    new Compressor(file, {
      quality: 0.6,
      success: (compressedResult) => {
        const imageUrl = URL.createObjectURL(compressedResult);
        
        setEditForm(prevForm => ({
          ...prevForm,
          images: [
            {
              key: `image-${Date.now()}-${0}`,
              url: imageUrl,
              order: 0,
              file: compressedResult
            }
          ]
        }));

        // Clear the input value to allow uploading the same file again after removal
        // event.target.value = '';
      },
      error: (err) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error processing image",
        });
        console.error(err);
      }
    });
  };


  const removeImage = (indexToRemove) => {
    setEditForm(prevForm => {
      // Revoke object URL to prevent memory leaks
      if (prevForm.images[indexToRemove]?.url) {
        URL.revokeObjectURL(prevForm.images[indexToRemove].url);
      }
      
      return {
        ...prevForm,
        images: []
      };
    });
  };
  function onSubmite() {
    // let data = editForm;
    let data = { ...editForm };


    if (data.name) {
      if (!userNameWithSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabet and minimum 3",
        });

        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }
    if (data.state) {
      if (!data.state === "") {
        setFormErrors({
          ...formErrors,
          state: "Please select the state",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, state: "" });
      }
    }

    if (data.latitude) {
      if (!data.latitude === "") {
        setFormErrors({
          ...formErrors,
          latitude: "Please enter the latitude",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, latitude: "" });
      }
    }
    if (data.longitude) {
      if (!data.longitude === "") {
        setFormErrors({
          ...formErrors,
          longitude: "Please enter the longitude",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, longitude: "" });
      }
    }
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    const formData = new FormData();
   // Handle add enable field 
   formData.append('enable', data.enable === true ? true : false);
  
   // Handle sortValue as number
   if (data.sortValue) {
     formData.append('sortValue', Number(data.sortValue));
   }
 
   // Add other non-file fields
  
   Object.keys(data).forEach(key => {
     if (!['images', 'enable', 'sortValue'].includes(key) && data[key] !== undefined) {
       formData.append(key, data[key]);
     }
   });

       // Add image file if exists
       if (data.images && data.images.length > 0) {
        const imageData = data.images[0];
         if(imageData.file !== undefined){
          formData.append('images', imageData.file);
        }else{
          // formData.append('images', []);
        }
            
     
      }else{
        console.log("imageData",data.images);
        formData.append('images', "");
      }


    

      setLoading({ ...loading, update: true });
      if (state?._id) {
        formData.append('id', state._id);
      }
      const requestMethod = state?._id ? "put" : "post";
   
    


      AxiosInstance(PORTS.REGION_SERVICE, 
        { headers: { "content-type": "multipart/form-data" } })

        [requestMethod](`/cities`,  
          formData
          // state?._id ? {...formData,id: state?._id} : formData
        )
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: state?._id
                ? "City updated successfully"
                : "City added successfully",
            });
            setTimeout(() => {
              history.push("/site-settings/region");
              setEditForm(initialFormState);
            }, 1000);
          } else {
            showNotification({
              type: "error",
              message: "Error",
              description:
                res.data?.msg || "City name already exists",
            });
          }
        })
        .catch((er) => {
          return er;
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    
  }

  function handleInput(e, name) {
    const updatedValues = { ...editForm };

    if (name === "state") {
    updatedValues.state = e;
    if (!e.match(userNameWithSpace) || !e === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: ""});
      }
    }else if (name === "regionId") {
      updatedValues.regionId = e;
          if (!e.match(userNameWithSpace) || !e === "") {
            setFormErrors({
              ...formErrors
            });
          } else {
            setFormErrors({ ...formErrors, [name]: ""});
          }
    }
    else{
      updatedValues.regionId = e
    }
    setEditForm(updatedValues);
  }


  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push({
              pathname: "/site-settings/region",
              state: {tab:true},
            })}
          />
          {state?._id ? "Edit City" : "Add City "}
        </h1>
        <div className={styles.management_contents_upload}>
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings/region`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: onSubmite,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching city details..." />
          </td>
        </tr>
      ) : (
        <div className={commonstyles.management_details}>
          <div
            className={`${commonstyles.management_details_contents} ${commonstyles.management_details_form}`}
          >
            <div className="row">
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    Name
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.name}
                    formControl={{
                      placeholder: "Eg, Bangaluru",
                      name: "name",
                      value: editForm?.name,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label className={styles.label_title}>
                    Warhouse
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>

                  <Select className="input_width_100 font_semibold br-10"
                    value={editForm?.regionId}
                    onChange={(value) => handleInput(value, "regionId")}
                    style={{
                      cursor: "pointer",
                      border: formErrors.regionId ? "#ff7575" : "1px solid #d9d9d9",
                      boxShadow: formErrors.regionId ? "0 0 3px 1px #ff7575" : ''
                    }}
                  >
                    <option value="" disabled selected>Select region</option>
                    {/* <option value="">Select regionId</option> */}
                    {regions.length >0 && regions.map((region) => (
                        <option key={region.id} value={region._id} label={region.name}  >
                          {region.name}
                        </option>
                    ))}
                  </Select>
                  <FormErrorText error={formErrors.regionId} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                  latitude<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.latitude}
                    formControl={{
                      placeholder: "Eg, 20.12920",
                      name: "latitude",
                      value: editForm?.latitude,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.latitude} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                  longitude<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.longitude}
                    formControl={{
                      placeholder: "Eg, 20.12920",
                      name: "longitude",
                      value: editForm?.longitude,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.longitude} />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label className={styles.label_title}>
                    State
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>

                  <Select className="input_width_100 font_semibold br-10"
                    value={editForm?.state}
                    onChange={(value) => handleInput(value, "state")}
                    style={{
                      cursor: "pointer",
                      border: formErrors.state ? "#ff7575" : "1px solid #d9d9d9",
                      boxShadow: formErrors.state ? "0 0 3px 1px #ff7575" : ''
                    }}
                  >
                    <option value="" disabled selected>Select state</option>
                    {/* <option value="">Select state</option> */}
                    {Object.entries(stateCode).sort().map((state) => (
                      <>

                        <option value={state[0]} label={state}  >
                          {state[0]}
                        </option>
                      </>
                    ))}
                  </Select>
                  <FormErrorText error={formErrors.state} />
                </div>
              </div>
              {/* <div className="row"> */}
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>
              Upload 
              {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span> */}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="form-control"
              disabled={editForm.images && editForm.images.length > 0}
            />
              {editForm.images && editForm.images.length > 0 ? (
        <small className="text-muted">
            Please remove existing image to upload new ones
        </small>
    )
    :(
      <small className="text-muted">
      Please upload file less than 3MB size
  </small>
    )
    }
            {/* <FormErrorText error={currentForm.errors.images} /> */}
          </div>

          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>Preview Images</label>
            <div className={commonstyles.banner_img_box_outline}>
              {editForm?.images?.map((img, index) => (
                <div key={img.key} className={commonstyles.banner_img_preview}>
                  <img
                    src={img.url}
                    alt={`preview-${index}`}
                    className={commonstyles.previewImgs}
                  />
                  <img
                    src={bannerimgdelete}
                    alt="delete"
                    className={commonstyles.previewImgsdeleteicon}
                    onClick={() => removeImage(index)}
                  />
                  {/* <div className={styles.imgpreview_index}>{index + 1}</div> */}
                </div>
              ))}
            </div>
          </div>
        {/* </div> */}
                <div className="col-lg-12 mb-4">
                <div className={styles.management_details_form}>
                  <label for="">
                   Top Content
                  </label>
                  <CKEditor
                    error={formErrors.topContent}
                    editor={ClassicEditor}
                    data={
                      editForm?.topContent
                    }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      const values = { ...editForm };

                      values.topContent = data;
                      if (!minCharWithNoMaxLimit.test(data)) {
                        if (!formErrors.topContent) {
                          formErrors.topContent = {};
                        }
                        formErrors.topContent = "Content required";
                      } else {
                        if (formErrors.topContent) {
                          formErrors.topContent = "";
                        }
                      }
                      setEditForm(values);
                    }}
                  />
                  <FormErrorText error={formErrors.topContent} />
                </div>
              </div>
              
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Bottom Content
                    </label>
                    <CKEditor
                      error={formErrors.bottomContent}
                      editor={ClassicEditor}
                      data={
                        editForm?.bottomContent
                      }
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };

                        values.bottomContent = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.bottomContent) {
                            formErrors.bottomContent = {};
                          }
                          formErrors.bottomContent = "Content required";
                        } else {
                          if (formErrors.bottomContent) {
                            formErrors.bottomContent = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />
                    <FormErrorText error={formErrors.bottomContent} />
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddCity;
