import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { addressRegx, alphaNum, alphanumericwithdashHyphen, alphanumericwithdashHyphenWithLimit, emailRegx, passwordReg, phoneNumber, phoneRegx, userNameWithSpace } from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
const { Option } = Select;

export default function AddDeliveryComponent() {

    const initialFormState = {
        enable: true,
        name: "",
        phone: "",
        image: "",
        address:"",
        email:"",
        regionId: "",
      };
      const rules = {
        name: [validationRules.required],
        phone: [validationRules.required],
        image: [(val, data) => (!url && !file ? "Delivery image required" : true)],
        address: [validationRules.required],
        regionId: [validationRules.required],
        email:[validationRules.required],
      };

  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);

  //file
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            // res.data.daaata.unshift({
            //   name: "All",
            //   _id: "All",
            // });
            setRegions(res.data.daaata);
          } 
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);

  function handleRegionChange(value) {
    setEditForm({ ...editForm,regionId: value });
  }

  function handleImageInput(e) {
    const { name, files } = e.target;
    if (files[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
    }else{
        setFile(e.target.files[0]);
        if (url) {
          URL.revokeObjectURL(url);
        }
        const generatedUrl = URL.createObjectURL(e.target.files[0]);
        setUrl(generatedUrl);
        editForm.image = generatedUrl;
    }
    
  }

  function removeFile() {
    if (url) {
      editForm.image = "";
      setFile(null);
      setUrl(null);
    }
  }

  useEffect(() => {
    const { state } = history.location;
    if (state?._id) {
      getDeliveryDetails(state._id);
    }else{
      setIsLoading(false);
    }
  }, []);

  

   const [editZoneId, setEditZoneId] = useState('');


    /**
   * @author Mithun Prabhu
   * @param {id}
   * GET DELIVERY DETAILS OF PARTICULAR ID
   */
   function getDeliveryDetails(id) {
    //console.log(id);
   setLoading({ details: true, update: false });
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`/deliveryAgent/agentDetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEditForm(val);
          setUrl(val.personImage);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

   /**
   * @author Mithun Prabhu
   * @param {event}
   * INPUT HANDLE CHANGE
   */
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    if(name==="regionId"){
      if (!e.target.value === "") {
        setFormErrors({...formErrors,regionId : "Required"})
      }else{
        setFormErrors({...formErrors,regionId : ""})
      }
    }
    if(name==="name"){
      value=value.charAt(0).toUpperCase() + value.slice(1)
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({...formErrors,name : "Enter only alphabet and minimum 3"})
      }else{
        setFormErrors({...formErrors,name : ""})
      }
    }
    if(name==="phone"){
        value=value.trim();
        if (!e.target.value.match(phoneNumber) || !e.target.value === "") {
          setFormErrors({...formErrors,phone : "Enter only valid number"})
        }else{
          setFormErrors({...formErrors,phone : ""})
        }
      }
      if(name==="address"){
        if (!e.target.value.match(addressRegx) || !e.target.value === "") {
          setFormErrors({...formErrors,address : "Enter a valid address"})
        }else{
          setFormErrors({...formErrors,address : ""})
        }
      }
      if(name==="email"){
        value=value.trim();
        if (!e.target.value.match(emailRegx) || !e.target.value === "") {
          setFormErrors({...formErrors,email : "Enter a valid address"})
        }else{
          setFormErrors({...formErrors,email : ""})
        }
      }
    const updatedValues = { ...editForm };
    if (["enable"].includes(name)) {
        updatedValues[name] = checked;
      } else {
        updatedValues[name] = value;
      }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }

   /**
   * @author Mithun Prabhu
   * @param {event}
   * SUBMIT DELIVERY UPDATE OF ONE FORM
   */
  function submit(e) {
    let data = {
        name: editForm.name.trim(),
        address: editForm.address,
        email: editForm.email.trim(),
        enable: editForm.enable,
        phone: editForm.phone,
        regionId : editForm.regionId,
      };
    // console.log(data);

    //validation
    if(data.name){
        if (!userNameWithSpace.test(data.name) || !data.name === "") {
          setFormErrors({...formErrors,name : "Enter only alphabet and minimum 3"})
          return false
        }else{
          setFormErrors({...formErrors,name : ""})
        }
      }
      if(data.phone){
        if (!phoneNumber.test(data.phone) || !data.phone === "") {
          setFormErrors({...formErrors,phone : "Enter only valid number"})
          return false
        }else{
          setFormErrors({...formErrors,phone : ""})
        }
      }
      if(data.address){
        if (!addressRegx.test(data.address) || !data.address === "") {
          setFormErrors({...formErrors,address : "Enter a valid address"})
          return false
        }else{
          setFormErrors({...formErrors,address : ""})
        }
      }
      if(data.email){
        if (!emailRegx.test(data.email) || !data.email === "") {
          setFormErrors({...formErrors,email : "Enter a valid emaid id"})
          return false
        }else{
          setFormErrors({...formErrors,email : ""})
        }
      }
    
    data.id=state?._id
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("personImage", file);
    // console.log(formData);

    // const result = validate(undefined, data);
    const result = runValidationChecks(rules, undefined, { ...data, file });

    // console.log(result);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
    //  .post("/deliveryAgent/createAgent", formData)
        .post(`/deliveryAgent/${state?._id ? "updateAgent " : "createAgent "}`, formData)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: state?._id
            ? "Delivery agent updated successfully"
            : "Delivery agent added successfully",
          });
          history.push(`/catalog/deliveryList/delivery`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding delivery agent failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding delivery agent failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      }); 
      
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
  const { state } = history.location;

  // console.log(editForm);
  return ( 
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/catalog/deliveryList/delivery")}
          />
          {state?._id ? "Edit Delivery" : "Add Delivery"}
        </h1>
        <div className={styles.management_contents_upload}>
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/catalog/deliveryList/delivery`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching delivery details..." />
          </td>
        </tr>
      ) : (
      <div className={styles.management_details}>
              <div className={styles.management_details_contents}>
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <label className={commonstyles.secondry_title}>
                            Name{" "}
                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                            </span>
                        </label>

                        <InputComponent
                            error={formErrors.name}
                            className="form-control-lg mb-3"
                            formControl={{
                            placeholder: "Type product name",
                            name: "name",
                            value: editForm?.name,
                            onChange: handleFormInput,
                            }}
                        />
                        <FormErrorText error={formErrors.name}/>
                    </div>
                    
                    {/* Region start */}
                    <div className="col-lg-6 mb-4">
                        <label className="theme-form-label">
                            Warehouse <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative"}}>*</span>
                        </label>
                        <select
                            name="regionId"
                            className={`form-control form-control-lg mb-3
                                ${formErrors.regionId && "theme-error-input"} ${
                                commonstyles.form_control
                            }`}
                            value={editForm?.regionId}
                            style={{ cursor: "pointer" }}
                            onChange={handleFormInput}
                            >
                           <option value="" disabled>Select a region</option>
                            {Array.isArray(regions)
                              ? regions.map((region) => (
                                  <option key={region._id} value={region._id}>
                                    {region.name}
                                  </option>
                                ))
                              : null}

                        </select>
                        <FormErrorText error={formErrors.regionId} />
                    </div>
                    {/* region End */}
                    {/* Phone */}
                    <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Phone
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <div className={commonstyles.adduser_input_countycode}>  
                      <span>+91</span>
                    <InputComponent
                        error={formErrors.phone}
                        className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                        formControl={{
                        placeholder: "Type phone",
                        name: "phone",
                        value: editForm?.phone,
                        onChange: handleFormInput,
                        }}
                    />
                    </div>
                    <FormErrorText error={formErrors.phone} />
                    </div>
                    {/* Phone ends */}
                   {/* Address */}
                   <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Address
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <textarea
                      className={`${commonstyles.form_control} 
                      ${formErrors.address && "theme-error-input"} form-control form-control-lg mb-3`}
                      // error={formErrors.address}
                      rows="7"
                      cols="20"
                      placeholder="Type address"
                      name="address"
                      value={editForm?.address}
                      onChange={handleFormInput}
                      // maxLength="50"
                    />
                    {/* <InputComponent
                        error={formErrors.address}
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "Type address",
                        name: "address",
                        value: editForm?.address,
                        onChange: handleFormInput,
                        }}
                    /> */}
                    <FormErrorText error={formErrors.address} />
                    </div>
                    {/* Address ends */}
                    {/* Email */}
                   <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Email ID
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <InputComponent
                        error={formErrors.email}
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "Type email id",
                        name: "email",
                        value: editForm?.email,
                        onChange: handleFormInput,
                        }}
                    />
                    <FormErrorText error={formErrors.email} />
                    </div>
                    {/* Email ends */}
                    {/* image upload */}
                    <div className="col-lg-6 mb-4">
                        <div className={commonstyles.management_details_contents}>
                        <div className={styless.upload_img_header}>
                            <label className={commonstyles.secondry_title}>
                            Person Image
                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                *
                            </span>
                            </label>
                        </div>

                        <div className={commonstyles.management_details_contents}>
                            <div
                            // className={`${commonstyles.management_details_form} ${styless.add_featureImage_card}`}
                            >
                            {url ? (
                                <>
                                <ImagePreviewChip
                                    url={url}
                                    className={styless.added_image}
                                    width="60px"
                                    height="60px"
                                    handleClose={removeFile}
                                />
                                </>
                            ) : (
                                <div className={` ${styless.upload_img} text-center w-100`}>
                                <div>
                                    <label
                                    htmlFor="categoryFileInput"
                                    className={`${styless.btn} px-2 `}
                                    >
                                    Add file
                                    <InputComponent
                                        error={formErrors.image}
                                        className="form-control-lg mb-3"
                                        formControl={{
                                            type: "file",
                                            name: "url",
                                            style:{cursor: "pointer" },
                                            accept: "image/jpeg, image/png, image/svg+xml",
                                            id: "categoryFileInput",
                                            onChange: (e) => handleImageInput(e),
                                        }}
                                    />
                                    <FormErrorText error={formErrors.image} />
                                    </label>
                                </div>
                                {/* <span>or drop a file to Upload</span> */}
                                </div>
                            )}
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* image upload end */}
                </div>
              </div>
            {/* </React.Fragment>
          ))}
          ))} */}
        {/* <div className={styles.payment_btn}>
          <button className={`btn ${styles.btn_filled}`} onClick={addFaq}>
            Add FAQ
          </button>
        </div> */}
      </div>
      )}
    </div>
  );
}
