import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { Select, notification } from "antd";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMove } from "react-movable";
import { useHistory } from "react-router";
import moment from "moment";
import Dummyimage from "../../../../src/assets/images/dummyimage.png"
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";

const { Option } = Select;

const CategorySpaces = (props) => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [regionId, setRegionId] = useState("");
  const [desirableData, setDesirableData] = useState();
  const [regionDesirableData, setRegionDesirableData] = useState({ title: "", subTitle: "", duration: "", Url: "collections" });
  const [desirableHandpickedErrors, setDesirableHandpickedErrors] = useState({ title: '', subTitle: '', duration: '', Url: "", product: '' });
  const [productList, setproductList] = useState([]);
  const [productList2, setproductList2] = useState([]);
  const [productOption, setProductOption] = useState([{ value: "", key: "", label: "", sortIndexValue: 0 }]);
  const [galleryImgs, setGalleryImgs] = useState([]);
  const [updatedDetail, setUpdatedDetail] = useState({ updatedAt: "", userName: "" });
  const [loading, setLoading] = useState({ details: false, update: false });
  const [confirmClear, setConfirmClear] = useState(false);

  useEffect(() => {
    getRegionWiseLanding()
    getProductList()
    if (!props.moduleName || props.moduleName === undefined) {
      setTimeout(() => {
        history.push("/Content")
      }, 200);
    }
  }, [])

  useEffect(() => {
    if (desirableData && Array.isArray(desirableData)) {
      for (const region of desirableData) {
        if (region?.product?.length)
        if (region && regionId === "" && region.regionId == null) {
          // setRegionDesirableData(region)
          setRegionDesirableData({
            ...region,
            Url: "collections" // Always set to collections
          })
          let productArray = []
          region.product.filter((category)=>!!(category.categoryspace)).map((item, index) => {
            productArray.push({
              image: item.categoryspaceimage,
              value: item._id,
              key: item._id, label: item.name,
              sortIndexValue: item?.sortIndexValue ? item?.sortIndexValue : index
            })
          })
          setGalleryImgs(productArray?.length ? productArray : []);
          setProductOption(productArray?.length ? productArray : [])
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          break;
        } else if (region && regionId && region.regionId == regionId) {
          // setRegionDesirableData(region)
          setRegionDesirableData({
            ...region,
            Url: "collections" // Always set to collections
          })
          let productArray = []
          region.product.map((category,i)=>{
            if (category?.regionCollection?.length) {
              category.regionCollection.map((reg,j)=>{
                if(reg.regionId === regionId) {
                  console.log({product:region.product[i]})
                  console.log({regionCollection:region.product[i].regionCollection[j]})
                  region.product[i].categoryspace = region.product[i].regionCollection[j].categoryspace;
                  region.product[i].categoryspaceimage = region.product[i].regionCollection[j].categoryspaceimage;
                }
              })
            }
          })
          region.product.filter((category)=>!!(category.categoryspace)).map((item, index) => {
            productArray.push({
              image: item.categoryspaceimage,
              value: item._id, key: item._id,
              label: item.name,
              sortIndexValue: item?.sortIndexValue ? item?.sortIndexValue : index

            })
          })
          console.log({productArray})
          setGalleryImgs(productArray?.length ? productArray : []);
          setProductOption(productArray?.length ? productArray : [])
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          break;
        } else {
          setRegionDesirableData({
            title: "", subTitle: "", duration: "",
            Url: "collections"
          })
          setGalleryImgs([]);
          setProductOption([])
          setUpdatedDetail()
        }
      }
    }

    if (productList?.length)
      if (regionId === "") {
        setproductList2([...productList])
      } else {
        const regionProduct = [...productList]
        let productArray = [];
        regionProduct.map((category,i)=>{
          if (category?.regionCollection?.length) {
            category.regionCollection.map((reg,j)=>{
              if(reg.regionId === regionId) {
                regionProduct[i].categoryspace = !!(regionProduct[i].regionCollection[j].categoryspace);
                regionProduct[i].categoryspaceimage = regionProduct[i].regionCollection[j].categoryspaceimage;
              }
            })
          }
        })
        setproductList2([...regionProduct])
      }
  }, [desirableData, regionId]);

  function getRegionWiseLanding() {
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/getRegionLandingseparate/${props.state}`)
      .then((res) => {
        if (res.data.status === 200) {

          let regionData = res.data.data[0].region;

          // Check if duration exceeds 1000 and convert it
          regionData = regionData.map(region => {
            if (region.duration >= 1000) {
              region.duration = (region.duration / 1000).toString();;
            }
            return region;
          });
          setDesirableData(regionData);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // categorylist
  function getProductList() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getCategoryList", { "pagesize": 500, "nextpage": 1 })
      .then((res) => {
        if (res && res.data && res.status == "200") {
          // Process each product to ensure icon parameter exists and is not empty
          const processedProducts = res.data.data.map(product => {

            // Check if icon property exists
            if (!product.hasOwnProperty('icon')) {
              return { ...product, icon: Dummyimage };
            }

            // Check if icon is empty
            if (!product.categoryspaceimage || product.categoryspaceimage.trim() === '') {
              return { ...product, icon: Dummyimage };
            }

            return product;
          }).filter(product => {
            // Not include second sub categories
            return product?.level !== 2
          });
          setproductList(processedProducts);
          setproductList2([...processedProducts])
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function searchSelectOnChange(value, data) {
    console.log({data})
    const selected = {
      label: data.label,
      key: data.key,
      value: data.value,
      image: data.image,
      sortIndexValue: data.sortIndexValue
    }

    if (productOption.length >= 10) {
      showNotification({
        type: "warning",
        message: "Warning",
        description: "You can only select up to 12 categories."
      });
      return;
    }

    if (selected) {
      setDesirableHandpickedErrors((prevErrors) => ({
        ...prevErrors,
        product: ''
      }))
    } else {
      setDesirableHandpickedErrors((prevErrors) => ({
        ...prevErrors,
        product: 'Please select the Product'
      }))
    }
    if (galleryImgs && productOption) {
      setGalleryImgs([...galleryImgs, { ...selected, sortIndexValue: galleryImgs.length }]);
      setProductOption([...productOption, { ...selected, sortIndexValue: galleryImgs.length }]);
    } else {
      setGalleryImgs([{ ...selected, sortIndexValue: galleryImgs.length }]);
      setProductOption([{ ...selected, sortIndexValue: galleryImgs.length }]);
    }
  }

  function removeImages(img, img_indx) {
    let arr = galleryImgs?.filter((x, indx) => indx !== img_indx)
      .map((item, index) => ({
        ...item,
        sortIndexValue: index
        //  item.sortIndexValue
      }));
    if(arr.length) {
      setGalleryImgs(arr);
      setProductOption(arr);
    }
  }

  // new
  const onSortEnd = (oldIndex, newIndex) => {
    const updateSortOrder = (array, oldIndex, newIndex) => {
      const arr = [...array];
      const old = { ...arr[oldIndex] };
      arr[oldIndex] = { ...arr[newIndex] };
      arr[newIndex] = old;

      // swap
      const tempSortIndexValue = arr[oldIndex].sortIndexValue;
      arr[oldIndex].sortIndexValue = arr[newIndex].sortIndexValue;
      arr[newIndex].sortIndexValue = tempSortIndexValue;

      return arr;
    };

    // Apply the updates to both arrays
    const updatedGalleryImgs = updateSortOrder([...galleryImgs], oldIndex, newIndex);
    const updatedProductOptions = updateSortOrder([...productOption], oldIndex, newIndex);

    // Update the state with the modified arrays
    setGalleryImgs(updatedGalleryImgs);
    setProductOption(updatedProductOptions);
    console.log("Updated product options", updatedProductOptions);
  };

  const filterOnChangeforRegion = (e) => {
    const { name, value } = e.target;
    if (name === "regionId") {
      setRegionId(value);
      setDesirableHandpickedErrors({ title: '', subTitle: '', duration: '', Url: "", product: '' })
      setProductOption([])
    }
  };

  const handleFormInput = (name, data) => {
    switch (name) {
      case 'title':
        setDesirableHandpickedErrors((prevErrors) => ({
          ...prevErrors,
          title: data === "" ? 'Please enter the title' : ''
        }));
        setRegionDesirableData({
          title: data,
          subTitle: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "",
          Url: regionDesirableData && regionDesirableData.Url ? regionDesirableData.Url : "",
          duration: regionDesirableData && regionDesirableData.duration ? regionDesirableData.duration : "",

        });
        break;
      case 'subTitle':
        setDesirableHandpickedErrors((prevErrors) => ({
          ...prevErrors,
          subTitle: data === "" ? 'Please enter the Sub title' : ''
        }));
        setRegionDesirableData({
          title: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "",
          Url: regionDesirableData && regionDesirableData.Url ? regionDesirableData.Url : "",
          duration: regionDesirableData && regionDesirableData.duration ? regionDesirableData.duration : "",
          subTitle: data
        });
        break;

      case 'duration':
        setDesirableHandpickedErrors((prevErrors) => ({
          ...prevErrors,
          duration: data === "" ? 'Please enter the Duration' : ''
        }));
        setRegionDesirableData({
          title: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "",
          Url: regionDesirableData && regionDesirableData.Url ? regionDesirableData.Url : "",
          subTitle: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "",
          duration: data
        });
        break;
      default:
        setDesirableHandpickedErrors(() => ({
          title: '',
          subTitle: '',
          duration: '',
        }));
        setRegionDesirableData({ title: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "", subTitle: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "", duration: regionDesirableData && regionDesirableData.duration ? regionDesirableData.duration : "" });
        break;
    }
  };

  function handleDeselect(value, option) {
    console.log("galleryimg", { galleryImgs }, option);

    const temp1 = [...galleryImgs].filter(item => item.value !== option.value)
    const temp = temp1.map((product, index) => ({
      ...product,
      sortIndexValue: index
    }))
    console.log("temp", temp);
    setGalleryImgs([...temp])
    setProductOption([...temp])
    // setGalleryImgs((prevImgs) => ));
    // setProductOption((prevOptions) => prevOptions.filter(item => item.value !== value));
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function submit() {
    const newErrors = {
      title: '',
      subTitle: '',
      Url: '',
      duration: '',
      product: ''
    };

    // Get the current title and subtitle safely using optional chaining
    const currentTitle = regionDesirableData?.title ?? "";
    const currentSubTitle = regionDesirableData?.subTitle ?? "";
    const currentDuration = regionDesirableData?.duration ?? "";

    // Validate all fields
    newErrors.title = !currentTitle.trim() ? 'Please enter the title' : '';
    newErrors.subTitle = !currentSubTitle.trim() ? 'Please enter the sub title' : '';
    newErrors.duration = !currentDuration.trim() ? 'Please enter the duration' : '';
    newErrors.product = !productOption?.length ? 'Please select the categories' : '';

    // Update error state
    setDesirableHandpickedErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some(error => error !== '');
    if (hasErrors) {
      return;
    }

    if (regionDesirableData?.duration && regionDesirableData.duration <= 1000) {
      regionDesirableData.duration = regionDesirableData.duration * 1000; // Convert to milliseconds
    }

    const sortedProducts = productOption.map((item, index) => ({
      productId: item.key,
      sortIndexValue: index  // Use the array index as the final sort value
    }));



    // let data = productOption && productOption?.map((item) => item.key);
    const finalData = {
      "id": props.state,
      "region": {
        "regionId": regionId,
        "title": regionDesirableData.title,
        "duration": regionDesirableData.duration,
        "subTitle": regionDesirableData.subTitle,
        "Url": "collections",
        "product": sortedProducts
        // data
      }
    };

    setIsLoading(true);

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/addEditRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Please try again",
          });
        }
      })
      .catch((er) => {
        console.log(er);
        showNotification({
          type: "Error",
          message: "Error",
          description: "Please try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function cancelClear() {
    setConfirmClear(false)
  }
  function clearRegionData() {
    setConfirmClear(false)
    const finalData = {
      "id": props.state,
      "regionId": regionId,
    }
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/clearRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Please try again",
          });
        }
      })
      .catch((er) => {
        console.log(er)
        showNotification({
          type: "Error",
          message: "Error",
          description: "Please try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          <span>{props.moduleName == "ourCollection" ? "Our Collections" : props.moduleName}</span>
        </h1>
      </div>
      <div className={`${styles.management_header} ${styles.banner_city_list}`}>
        <div className={`${styles.banner_citys}`}>
          <SelectComponent
            className="form-control-md"
            attributes={{
              name: "regionId",
              value: regionId,
              onChange: filterOnChangeforRegion,
            }}
          >
            {props.filterRegions?.length > 1 && <option value="">All Region</option>}
            {Array.isArray(props.filterRegions)
              ? props.filterRegions?.map((region) => (
                <option key={region._id} value={region._id}>
                  {region.name}
                </option>
              ))
              : null}
          </SelectComponent>
          {updatedDetail &&
            <div className={`${styles.banner_citys_update}`}>
              {updatedDetail.userName && (
                <p>
                  Last updated by: <span>{updatedDetail.userName}</span>
                </p>
              )}
              {updatedDetail.updatedAt && (
                <p>
                  Last update on:{" "}
                  <span>
                    {moment(updatedDetail.updatedAt).format(
                      "DD/MM/YYYY | hh:mm A"
                    )}
                  </span>
                </p>
              )}
            </div>
          }
        </div>
        <div className={styles.management_contents_upload}>
          <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => setConfirmClear(true),
            }}
          >
            Clear
          </ButtonComponent>
          <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => history.push(`/Content`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details_contents}>
        <label className={commonstyles.secondry_title}>Title<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>
          *
        </span> </label>
        <InputComponent
          error={desirableHandpickedErrors.title}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type here",
            name: "title",
            maxLength: 70,
            value: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "",
            onChange: (e) =>
              handleFormInput("title", e.target.value),
          }}
        />
        <FormErrorText error={desirableHandpickedErrors.title} />
        <label className={commonstyles.secondry_title}>Sub Title<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>
          *
        </span></label>
        <InputComponent
          error={desirableHandpickedErrors.subTitle}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type here",
            name: "subTitle",
            maxLength: 150,
            value: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "",
            onChange: (e) =>
              handleFormInput('subTitle', e.target.value),
          }}
        />
        {/* url */}
        <label className={commonstyles.secondry_title}>Url<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>
          *
        </span></label>
        <InputComponent
          error={desirableHandpickedErrors.Url}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type Url Here",
            name: "Url",
            maxLength: 150,
            value: "collections",
            disabled: true,
            readOnly: true,
          }}
        />
        <FormErrorText error={desirableHandpickedErrors.subTitle} />
        {/* duration */}
        <label className={commonstyles.secondry_title}>Carousel Duration (in Seconds)<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>
          *
        </span></label>
        <InputComponent
          error={desirableHandpickedErrors.duration}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type here Duration",
            name: "duration",
            maxLength: 2,
            type: "number",
            maxLength: 150,
            value: regionDesirableData && regionDesirableData.duration >= 1000 ? regionDesirableData.duration / 1000 : regionDesirableData.duration || '',
            onChange: (e) => {
              const value = e.target.value;


              // Only allow numbers and restrict to 2 digits
              if (value === '' || (/^\d{1,2}$/.test(value) && parseInt(value) > 0)) {
                handleFormInput('duration', value);
              }
            },

          }}
        />
        <FormErrorText error={desirableHandpickedErrors.duration} />
        <div className="row">
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>Categories<span className="text-danger" style={{ fontSize: "20px", bottom: -8, position: "relative" }}>
              *
            </span></label>
            <Select
              error={desirableHandpickedErrors.product}
              className="productSelect"
              mode="multiple"
              name="region"
              placeholder="Select categories"
              optionLabelProp="label"
              value={productOption ? productOption : []}
              filterOption={(input, option) =>
                option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onDeselect={(value, option) => handleDeselect(value, option)}
              onSelect={(value, option) => searchSelectOnChange(value, option)}
            >
              {productList2?.length && productList2.map((product) => (
                <Option
                  value={product._id}
                  label={product.name}
                  key={product._id}
                  image={product.categoryspaceimage}
                  sortIndexValue={product.sortIndexValue}
                  disabled={!product.categoryspace}
                >
                  <div className="demo-option-label-item">{product.name}</div>
                </Option>
              ))}
            </Select>
            <FormErrorText error={desirableHandpickedErrors.product} />
          </div>
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>
              Preview Image Order
            </label>
            <SortableList
              onSortEnd={onSortEnd}
              className={styles.sortingimg_root}
            >
              <div className={commonstyles.banner_img_box_outline} style={{ paddingBottom: "20px" }}>
                {galleryImgs?.length > 0 &&
                  galleryImgs?.map((imgs, index) => (
                    <div className={commonstyles.banner_img_preview} key={`${index}_${imgs.image}_${imgs.name}`}>
                      <SortableItem key={imgs.key}>
                        <div>
                          <img
                            style={{ pointerEvents: "none" }}
                            className={commonstyles.previewImgs}
                            key={imgs.key}
                            src={imgs.image ? imgs.image : Dummyimage}
                            alt="profileImg"
                            imgProps={{ draggable: false }}
                          />
                          <span className={styles.imgpreview_index}>
                            {/* {index + 1} */}
                            {imgs.sortIndexValue + 1}
                          </span>
                          <img
                            onClick={() => removeImages(imgs, imgs.sortIndexValue)}
                            className={commonstyles.previewImgsdeleteicon}
                            src={bannerimgdelete}
                            alt="delete"
                          />
                        </div>
                      </SortableItem>
                    </div>
                  ))}
              </div>
            </SortableList>
          </div>
        </div>
      </div>
      <ConfirmationBox
        modal={{
          show: confirmClear,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelClear}
        handleConfirm={clearRegionData}Dummyimage
        title="Confirm the action"
        confirmText="Clear"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
              Are you sure want to clear?
            </p>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
};

export default CategorySpaces;
