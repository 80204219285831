import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import commonstyles from "../../../../styles/Management_Common.module.css";
import moment from "moment";
import EditSmsComponent from "../Communications/EditSmsComponent";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { notification } from "antd";
import { lowerCase, upperFirst } from "lodash";
import ReactHtmlParser from "react-html-parser";

const user_grps = [
    { id: 0, name: "Order Value", value: 0 },
    { id: 1, name: "Product Purchased", value: 1 },
];

let selectedTab = {
    id: 5,
    key: 5,
    name: "SMS Templates",
    link: "communications/smstemplates",
    isActive: false,
}

export default function EditSmsTemplate() {
    const history = useHistory();
    const { state } = history.location;
    const [isImporting, setIsImporting] = useState(false);
    const initialFormState = {
        templateName: "",
        message: "",
        startDate: "",
        startTime: "",
        customersPhone: []
    };
    const [editForm, setEditForm] = useState(initialFormState);
    const rules = {
        templateName: [(val, data) => (!val ? "Required Template Name" : true)],
        message: [(val, data) => (!val ? "Required message" : true)],
        startDate: [(val, data) => (!val ? "Required Start date" : true)],
        startTime: [(val, data) => (!val ? "Required start time" : true)],
        // customersPhone: [(val) => (val.length === 0 ? "Required at least one option" : true)],
        message :[(val) => (val.length < 3 ? "Required message" : true)]
    };
    const [formErrors, setFormErrors] = useState({});

    const editorOptions = {
        wrapperClassName: `${commonstyles.form_control} ${formErrors.content && "theme-error-input"} form-control-lg p-1 h-auto`,
        toolbarClassName: "bg-light rounded-lg theme-txt-sm",
        editorClassName: "px-2 mt-3 mb-1 ",
        placeholder: "Type content",
        toolbar: {
            options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "emoji",
            "link",
            "textAlign",
            "colorPicker",
            "history",
            ],
    
            blockType: {
                inDropdown: true,
                options: [
                    "Normal",
                    "H1",
                    "H2",
                    "H3",
                    "H4",
                    "H5",
                    "H6",
                    "Blockquote",
                    "Code",
                ],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: undefined,
            },
    
            fontSize: {
                icon: undefined,
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: undefined,
            },
    
            fontFamily: {
                options: [
                    "Arial",
                    "Georgia",
                    "Impact",
                    "Spartan",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                ],
                className: "theme-txt-sm",
                component: undefined,
                dropdownClassName: "",
            },
        },
    };

    const [isLoading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState([]);
    const [regionId, setRegionId] = useState("");
    const [userGroups, setUserGroups] = useState(user_grps);
    const [selectedUserGrpID, setSelectedUserGrpID] = useState(3);
    const [templates, setTemplates] = useState([]);
    const [selectedTempId, setSelectedTempId] = useState(null);
    const [isFetchingTemp, setIsFetchingTemp] = useState(false);
    const [customerDetails, setCustomerDetals] = useState([]);
    const [selectedUSers, setSelectedUSers] = useState([]);
    const [orderValue, setOrderValue] = useState(0);
    const [selectedPrdId, setSelectedPrdId] = useState(null);
    const [productList, setProductList] = useState([]);
    const timeoutId = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const [emailkeywordList, setEmailkeywordList] = useState([]);
    const inputFileElRef = useRef(null);
    const [file, setFile] = useState(null);
    const [isInitialId, setIsInitialId] = useState(true);
    const [allUsers, setAllUsers]=useState([]);
    const [allUsersData, setAllUsersData] = useState([])

    // ** DISABLE POST DAYS *** //
    function disabledDate(current) {
        return current < moment().startOf('day');
    }

    useEffect(() => {
        if(state?._id) {
            getEmailTemplateDetails(state._id);
            setIsInitialId(false);
        }
        // getRegionList();
        getTemplateList('');
        getEamilkeywords('');
        getCustomerDetails('');
    }, [])
    /**
   * @author Rajkumar
   * @param {id}
   * FETCHING EMAIL TEMPLATE DETAILS
   */
    function getEmailTemplateDetails(id, data_, type) {
        AxiosInstance(PORTS.INVOICE, "application/json")
            .get(`/admin/getSMSTemplatesDetails/${id}`)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    let data = res?.data?.data;
                    let arr = [];
                    data.customersPhone?.forEach(item => {
                        arr.push(item?._id);
                    })
                    const initialFormState = {
                        templateName: data.templateName,
                        message: data.message,
                        startDate: moment(data.setDate).format("YYYY-MM-DD"),
                        startTime: moment(data.setDate).format("hh:mm:ss A"),
                        customersPhone: arr
                    };
                    setSelectedTempId(id);
                    setEditForm(initialFormState);
                    if(type) {
                        if(data?.length === 0) {
                            setCustomerDetals(data?.customersPhone);
                        } else {
                            let array3 = data_.concat(data.customersPhone);
                            array3 = [...new Set([...data_,...data.customersPhone])]
                            setCustomerDetals(array3);
                        }
                    }
                }
            })
            .catch((er) => {
                console.log(er);
            });
    }
    /**
   * @author Rajkumar
   * @param {}
   * FETCHING REGION LIST
   */
    function getRegionList() {
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("/admin/region/getRegionList")
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setRegions(res.data.daaata);
                }
            })
            .catch((er) => {
                console.log(er);
            });
    }
    /**
   * @author Rajkumar
   * @param {search}
   * FETCHING EMAIL TEMPLATE LIST
   */
    function getTemplateList(search) {
        let params = {
            search: search
        }
        setIsFetchingTemp(true);
        setIsLoading(true)
        AxiosInstance(PORTS.INVOICE, "application/json")
            .post("/admin/searchSMSTemplate", params)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setTemplates(res.data.data);
                    setIsLoading(false)
                    setIsFetchingTemp(false);
                }
            })
            .catch((er) => {
                console.log(er);
                setIsFetchingTemp(false);
                setIsLoading(false);
            });
    }
    /**
   * @author Rajkumar
   * @param {id}
   * FETCHING EMAIL KEYWORDS LIST
   */
    function getEamilkeywords(search) {
        let params = {
            search: search
        }
        setIsFetchingTemp(true);
        AxiosInstance(PORTS.INVOICE, "application/json")
            .post("/admin/searchMailFields", params)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    if(res.data?.data?.length > 0) {
                        setEmailkeywordList(res.data.data);
                    } else {
                        setEmailkeywordList([]);
                    }
                    setIsFetchingTemp(false);
                }
            })
            .catch((er) => {
                console.log(er);
                setIsFetchingTemp(false);
            });
    }
    /**
   * @author Rajkumar
   * @param {search}
   * FETCHING PRODUCTS LIST 
   */
    function getProductList(search) {
        let params = {
            search: search,
            regionId: regionId
        }
        if(!params?.regionId) delete params.regionId;
        setIsFetchingTemp(true);
        AxiosInstance(PORTS.INVOICE, "application/json")
            .post("/admin/getOrderedProducts", params)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setProductList(res.data.data);
                    setIsFetchingTemp(false);
                }
            })
            .catch((er) => {
                console.log(er);
                setIsFetchingTemp(false);
            });
    }
    /**
   * @author Rajkumar
   * @param {filterarr}
   * FETCHING CUSTOMER DETIALS LIST
   */
    function getCustomerDetails(filterarr) {
        let params = {
            userGroup: selectedUserGrpID
        }
        if (filterarr && (filterarr.selectedUserGrpID||filterarr.selectedUserGrpID===0)) {
            params.userGroup = Number(filterarr.selectedUserGrpID);
        } else if (selectedUserGrpID||selectedUserGrpID===0) {
            params.userGroup = Number(selectedUserGrpID);
        }
        if (filterarr && (filterarr.productId||filterarr.productId===0)) {
            params.productId = filterarr.productId;
        } else if (selectedPrdId||selectedPrdId===0) {
            params.productId = selectedPrdId;
        }
        if (filterarr?.regionId) {
            params.regionId = filterarr?.regionId;
        } else if(regionId) {
            params.regionId = regionId;
        }
        if (filterarr && (filterarr.orderValue||filterarr.orderValue===0)) {
            params.orderValue = Number(filterarr.orderValue);
        } else if(filterarr.orderValue===0&&filterarr.selectedUserGrpID==="0"){
            params.orderValue = 0;
        }
        AxiosInstance(PORTS.INVOICE, "application/json")
            .post("/admin/getCustomerDetails", params)
            .then((res) => {
                if (res.data.status === '200' || res.data.status === 200) {
                    setIsFetchingTemp(false);
                    let modifiedData = [];
                    if(params?.userGroup === 2||params?.userGroup === '2') {
                        modifiedData = [...res.data.data]
                    } else {
                        res.data?.data?.forEach(item => {
                            modifiedData.push(item?.customerData[0]);
                        })
                    }
                    if(params?.userGroup===3 || params?.userGroup==="3"){
                        setAllUsers(res.data.data)
                    }
                    setAllUsersData(res.data.data)
                    if(state?._id) {
                        getEmailTemplateDetails(state._id, modifiedData, true);
                    } else {
                        setCustomerDetals(modifiedData);
                    }
                }
            })
            .catch((er) => {
                console.log(er);
                setIsFetchingTemp(false);
            });
    }

    // *** EMAIL CONTENT EDITER ONCHANGE *** //
    function handleEditorUpdate(htmlContent) {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        cloneEditForm.message = htmlContent;
        setEditForm(cloneEditForm);
        cloneFormErrors['message'] = '';
        setFormErrors(cloneFormErrors);

    }

    // ** HANDLE INPUT CHANGE *** //
    const handleFormInput = (e) => {
        const { name, value } = e.target;
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        if (value.trim()) {
            cloneEditForm[name] = value;
            cloneFormErrors[name] = '';
            setFormErrors(cloneFormErrors);
        } else {
            cloneEditForm[name] = '';
        }
        setEditForm(cloneEditForm);
    }

    // *** ON CHANGE SEARCH & SELECT *** //
    const searchSelectOnChange = (value, name) => {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        if(name === "selectedTempId") {
            setSelectedTempId(value);
            setIsInitialId(true);
            getEmailTemplateDetails(value);
        }
        if(name === "selectedUSers") {
            // if()
            cloneEditForm['customersPhone'] = value;
            cloneFormErrors['customersPhone'] = '';
            setEditForm(cloneEditForm);
            setFormErrors(cloneFormErrors);
        }
        if(name === "selectedPrdId") {
            setSelectedPrdId(value);
            let params = {
                productId: value
            }
            getCustomerDetails(params);
        }
        if(name === "searchEamilKeyWords") {
            let copyText = value;
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText);
            showNotification({
                type: "success",
                message: "Success",
                description: "Keywork copied successfully",
            });
        }
    }

    // *** ON CHANGE SEARCH & SELECT *** //
    const onSearchHandler = (value, name) => {
        if(value.trim()) {
            if(name === "selectedPrdId") {
                if(name === "selectedPrdId") {
                    getProductList(value);
                } else {
                    getTemplateList(value);
                }
            } else if(name === "searchEamilKeyWords") {
                if(name === "searchEamilKeyWords") {
                    getEamilkeywords(value);
                } else {
                    getEamilkeywords(value);
                }
            }
        } else {
            if(name === "selectedPrdId") {
                if(name === "selectedPrdId") {
                    getProductList("");
                } else {
                    getTemplateList("");    
                }
            } else if(name === "searchEamilKeyWords") {
                if(name === "searchEamilKeyWords") {
                    getEamilkeywords("");
                } else {
                    getEamilkeywords("");
                }
            }
        }
    }

    // *** REGION WISE FILTER *** //
    const filterOnChange = (e) => {
        const cloneEditForm = {...editForm};
        const { name, value } = e.target;
        if (name === "regionId") {
            setRegionId(value);
            const obj = { 
                regionId: value,
                selectedUserGrpID: selectedUserGrpID
            };
            if(selectedUserGrpID==="0"||selectedUserGrpID===0) {
                obj.orderValue = 0;
            }
            getCustomerDetails(obj)
        }
        if (name === "selectedUserGrpID") {
            setCustomerDetals([]);
            cloneEditForm['customersPhone'] = [];
            setEditForm(cloneEditForm);
            setSelectedUSers([]);
            setSelectedUserGrpID(value);
            let params = {
                selectedUserGrpID: value
            }
            if(value === '0') {
                params.orderValue = 0;
            } else {
                setOrderValue(0);
            }
            if(value === "1") {
                getProductList("");
            } else {
                setSelectedPrdId("");
                setProductList(null);
            }
            getCustomerDetails(params);
        }
    }

    // *** ORDER VALUE ONCHANGE *** //
    function inputOnChange(e) {
        const {name, value} = e.target;
        const updatedFilter = {};
        if(value.trim()) {
            if (isNaN(value)) return
            setOrderValue(value);
            updatedFilter.orderValue = value;
        } else {
            setOrderValue('');
            updatedFilter.orderValue = 0;
        }
        debounceSearch(updatedFilter);
    }

    // *** DEBOUNCESEARCH HANDLE *** //
    function debounceSearch(filter) {
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
            getCustomerDetails(filter);
        }, 500);
    }

    // *** ONCHANGE HANDLER FOR DATE *** //
    const onChangeDateHandler = (dateString, item) => {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        let converted_Date = moment(dateString).format('YYYY-MM-DD');
        if (converted_Date === "Invalid date") {
            cloneEditForm.startDate = '';
        } else {
            cloneEditForm.startDate = converted_Date;
            cloneFormErrors['startDate'] = '';
            setFormErrors(cloneFormErrors);
        }
        setEditForm(cloneEditForm);
    }

    // *** ONCHANGE HANDLER FOR TIME *** //
    const onChangeTimeHandler = (time, type) => {
        const cloneEditForm = {...editForm};
        const cloneFormErrors = {...formErrors};
        // cloneEditForm.startTime = moment(type, "HH:mm").format('hh:mm:00 A');
        cloneEditForm.startTime = moment(type, "hh:mm a").format("HH:mm");
        cloneFormErrors['startTime'] = '';
        setEditForm(cloneEditForm);
        setFormErrors(cloneFormErrors);
    }

    // *** VALIDATE FUNCTION *** //
    function validate(name, data) {
        data = data || {};
        const result = {};
        //Validate only field
        if (name) {
        const fieldRules = rules[name];
        if (Array.isArray(fieldRules)) {
            for (const rule in fieldRules) {
            const val = rule(data[name]);
            if (isString(val) || isObject(val)) {
                setFormErrors({ ...formErrors, [name]: val });
            } else if (name in formErrors) {
                const { name, ...restOfErros } = formErrors;
                setFormErrors(restOfErros);
            }
            }
        }
        //all data validation
        } else {
        for (const field in rules) {
            for (const rule of rules[field]) {
            const val = rule(data[field], data);
            if (typeof val === "string" || isObject(val)) {
                result[field] = val;
                break;
            }
            }
        }
        }
        return result;
    }

    // *** SHOW NOTIFICATION FUNCTION *** //
    function showNotification({ type, message, description }) {
        let options = {
        message: message || "Message",
        description: description,
        duration: 5,
        };
        notification[type](options);
    }

    // *** SUBMIT CHANGES FUNCTION *** //
    const saveChanges = () => {
        if(!isSaving) {
            let localDate = editForm.startDate;
            let localTime = editForm.startTime;
            let stringDate = moment(localDate + " " + localTime, "YYYY-MM-DD HH:mm");
            editForm.startDate = stringDate.toISOString();
            let data = JSON.parse(JSON.stringify(editForm));
            const result = validate(undefined, data);
            if(editForm.customersPhone && editForm.customersPhone.length<1){
                allUsersData.map((userData)=>{
                    if(userData?.customerData && userData?.customerData[0]?._id){
                        data.customersPhone.push(userData.customerData[0]._id)
                    } else{
                        data.customersPhone.push(userData._id)
                    }
                })
            }
            if((selectedUserGrpID.toString())==="3"){
                allUsers.map((userData)=>{
                    if(userData?.customerData && userData?.customerData[0]?._id){
                        data.customersPhone.push(userData.customerData[0]._id)
                    } else{
                        data.customersPhone.push(userData._id)
                    }
                })
            }
            data.customersPhone=[...new Set(data?.customersPhone)]
            if (Object.keys(result).length) {
                setFormErrors(result);
                showNotification({
                    type: "warning",
                    message: "There were few errors",
                    description: "Resolve the errors and try again",
                });
                if (editForm.message) {
                    let des = ReactHtmlParser(editForm.message)
                    let testContent = des[0]?.props?.children[0];
                    if(testContent?.trim()) {
                    } else {
                        result.message = "Required message"
                        setFormErrors(result);
                        return;
                    }
                }
                return;
            }
            let url = "";
            if(state?._id || selectedTempId) {
                data._id = selectedTempId;
                data.startTime = moment(data.startTime, "HH:mm").format('hh:mm:ss A');;
                url = "/admin/updateSMSTemplate"
            } else {
                url = "/admin/addSMSTemplate"
            }
            setIsSaving(true);
            AxiosInstance(PORTS.INVOICE, "application/json")
                .post(url, data)
                .then((res) => {
                    if (res.data.status === '200' || res.data.status === 200) {
                        showNotification({
                            type: "success",
                            message: "Success",
                            description: res.data.msg,
                        });
                        setIsSaving(false);
                        if(state?.prevLink === "/communications/emailogs") {
                            history.push("/communications/emailogs");
                        } else {
                            // history.push("/communications/smstemplates");
                            history.push({
                                pathname: `/communications/smstemplates`,
                                state: selectedTab,
                            });
                        }
                    } else if (res.data.status === 400) {
                        setIsSaving(false);
                        showNotification({
                            type: "error",
                            message: "error",
                            description: res.data.msg,
                        });
                    } else {
                        setIsSaving(false);
                    }
                })
                .catch((er) => {
                    console.log(er);
                    setIsSaving(false);
                });
        }
    }

    function handleFileInput(e) {
        setIsImporting(true);
        const { files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            setFile(file);
            const formData = new FormData();
            formData.append("marginFile", file);
            console.log(file);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            AxiosInstance(PORTS.CMS, "application/json")
                .post("/admin/bulkImportEmailTemplate", formData, config)
                .then((res) => {
                    if (res.data.status === '200' || res.data.status === 200) {
                        showNotification({
                            type: "success",
                            message: "Success",
                            description: upperFirst(file?.name + ' ' +lowerCase(res.data.msg)),
                        });
                        setIsSaving(false);
                        setIsImporting(false);
                    } else if (res.data.status === 400) {
                        setIsSaving(false);
                        showNotification({
                            type: "error",
                            message: "Error",
                            description: upperFirst(res.data.msg),
                        });
                        setIsImporting(false);
                    } else {
                        setIsSaving(false);
                        setIsImporting(false);
                    }
                })
                .catch((er) => {
                    console.log(er);
                    setIsSaving(false);
                    setIsImporting(false);
                });
        }
    }

    return (
        <EditSmsComponent 
            isLoading={isLoading}
            isImporting={isImporting}
            regionId={regionId}
            regions={regions}
            selectedUserGrpID={selectedUserGrpID}
            userGroups={userGroups}
            editForm={editForm}
            formErrors={formErrors}
            handleFormInput={handleFormInput}
            disabledDate={disabledDate}
            editorOptions={editorOptions}
            handleEditorUpdate={handleEditorUpdate}
            templates={templates}
            selectedTempId={selectedTempId}
            isInitialId={isInitialId}
            isFetchingTemp={isFetchingTemp}
            searchSelectOnChange={searchSelectOnChange}
            onSearchHandler={onSearchHandler}
            filterOnChange={filterOnChange}
            customerDetails={customerDetails}
            selectedUSers={selectedUSers}
            orderValue={orderValue}
            inputOnChange={inputOnChange}
            productList={productList}
            selectedPrdId={selectedPrdId}
            onChangeDateHandler={onChangeDateHandler}
            onChangeTimeHandler={onChangeTimeHandler}
            saveChanges={saveChanges}
            isSaving={isSaving}
            emailkeywordList={emailkeywordList}
            inputFileElRef={inputFileElRef}
            handleFileInput={handleFileInput}
            file={file}
            selectedTab={selectedTab}
        />
    );
}