import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { Select, notification } from "antd";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMove } from "react-movable";
import { useHistory } from "react-router";
import moment from "moment";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";

const { Option } = Select;

const DesirableHandpicked = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [regionId, setRegionId] = useState("");
  const [desirableData, setDesirableData] = useState();
  const [regionDesirableData, setRegionDesirableData] = useState({ title: "", subTitle: "" });
  const [desirableHandpickedErrors, setDesirableHandpickedErrors] = useState({ title: '', subTitle: '', product: '' });
  const [productList, setproductList] = useState([]);
  const [productOption, setProductOption] = useState([{ value: "", key: "", label: "" }]);
  const [galleryImgs, setGalleryImgs] = useState([{ value: "", key: "", label: "" }]);
  const [updatedDetail, setUpdatedDetail] = useState({ updatedAt: "", userName: "" });
  const [loading, setLoading] = useState({ details: false, update: false });
  const [confirmClear, setConfirmClear] = useState(false);

  useEffect(() => {
    getRegionWiseLanding()
    getProductList()
    if (!props.moduleName || props.moduleName === undefined) {
      setTimeout(() => {
        history.push("/Content")
      }, 200);
    }
  }, [])

  useEffect(() => {
    if (desirableData && Array.isArray(desirableData)) {
      for (const region of desirableData) {
        if (region && regionId === "" && region.regionId == null) {
          setRegionDesirableData(region)
          let productArray = []
          region?.product?.map((item) => {
            productArray.push({ value: item.feature_image, key: item._id, label: item.name })
          })
          setGalleryImgs(productArray);
          setProductOption(productArray)
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          break;
        } else if (region && regionId && region.regionId == regionId) {
          setRegionDesirableData(region)
          let productArray = []
          region?.product?.map((item) => {
            productArray.push({ value: item.feature_image, key: item._id, label: item.name })
          })
          setGalleryImgs(productArray);
          setProductOption(productArray)
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          break;
        } else {
          setRegionDesirableData({ title: "", subTitle: "" })
          setGalleryImgs();
          setProductOption([])
          setUpdatedDetail()
        }
      }
    }
  }, [desirableData, regionId]);

  function getRegionWiseLanding() {
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/getRegionLanding/${props.state}`)
      .then((res) => {
        if (res.data.status === 200) {
          setDesirableData(res.data.data[0].region);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });

  }


  function getProductList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/deals/getProductsOfCategory")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setproductList(res.data.data);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  function searchSelectOnChange(value, data) {
    const selected = {
      label: data.label,
      key: data.key,
      value: data.value
    }
    if (selected) {
      setDesirableHandpickedErrors((prevErrors) => ({
        ...prevErrors,
        product: ''
      }))
    } else {
      setDesirableHandpickedErrors((prevErrors) => ({
        ...prevErrors,
        product: 'Please select the Product'
      }))
    }
    if (galleryImgs && productOption) {
      setGalleryImgs([...galleryImgs, selected]);
      setProductOption([...productOption, selected]);
    } else {
      setGalleryImgs([selected]);
      setProductOption([selected]);
    }
  }

  function removeImages(img, img_indx) {
    let arr = galleryImgs?.filter((x, indx) => indx !== img_indx);
    setGalleryImgs(arr);
    setProductOption(arr)
  }

  const onSortEnd = (oldIndex, newIndex) => {
    console.log("oldIndex, newIndex",oldIndex, newIndex)
    const data = (array) => arrayMove(array, oldIndex, newIndex)
    setGalleryImgs(data);
    setProductOption(data)
  };

  const filterOnChangeforRegion = (e) => {
    const { name, value } = e.target;
    if (name === "regionId") {
      setRegionId(value);
      setDesirableHandpickedErrors({ title: '', subTitle: '', product: '' })
      setProductOption([])
    }
  };

  const handleFormInput = (name, data) => {
    switch (name) {
      case 'title':
        setDesirableHandpickedErrors((prevErrors) => ({
          ...prevErrors,
          title: data === "" ? 'Please enter the title' : ''
        }));
        setRegionDesirableData({ title: data, subTitle: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "" });
        break;
      case 'subTitle':
        setDesirableHandpickedErrors((prevErrors) => ({
          ...prevErrors,
          subTitle: data === "" ? 'Please enter the Sub title' : ''
        }));
        setRegionDesirableData({ title: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "", subTitle: data });
        break;
      default:
        setDesirableHandpickedErrors(() => ({
          title: '',
          subTitle: ''
        }));
        setRegionDesirableData({ title: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "", subTitle: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "" });
        break;
    }
  };

  function handleDeselect(value, option) {
    setGalleryImgs((prevImgs) => prevImgs.filter(item => item.value !== value));
    setProductOption((prevOptions) => prevOptions.filter(item => item.value !== value));
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function submit() {
    setDesirableHandpickedErrors((prevErrors) => ({
      ...prevErrors,
      title: regionDesirableData.title === "" ? 'Please enter the title' : '',
      subTitle: regionDesirableData.subTitle === "" ? 'Please enter the Sub Title' : '',
      product: productOption && productOption.length === 0 ? 'Please select the Products' : '',
    }));
    if (
      regionDesirableData.title === "" ||
      regionDesirableData.subTitle === "" ||
      (productOption && productOption.length === 0)
    ) {
      return;
    }
    let data = productOption && productOption?.map((item) => item.key);
    const finalData = {
      "id": props.state,
      "region": {
        "regionId": regionId,
        "title": regionDesirableData.title,
        "subTitle": regionDesirableData.subTitle,
        "product": data
      }
    };

    setIsLoading(true);

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/addEditRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        }else{
          showNotification({
            type: "Error",
            message: "Error",
            description: "Please try again",
          });
        }
      })
      .catch((er) => {
        console.log(er);
        showNotification({
          type: "Error",
          message: "Error",
          description: "Please try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function cancelClear(){
    setConfirmClear(false)
  }
  function clearRegionData(){
    setConfirmClear(false)
    const finalData = {
      "id": props.state,
      "regionId": regionId,
    }
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/clearRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        }else{
          showNotification({
            type: "Error",
            message: "Error",
            description: "Please try again",
          });
        }
      })
      .catch((er) => {
        console.log(er)
        showNotification({
          type: "Error",
          message: "Error",
          description: "Please try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

  }


  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          <span>{props.moduleName}</span>
        </h1>
      </div>
      <div className={`${styles.management_header} ${styles.banner_city_list}`}>
        <div className={`${styles.banner_citys}`}>
          <SelectComponent
            className="form-control-md"
            attributes={{
              name: "regionId",
              value: regionId,
              onChange: filterOnChangeforRegion,
            }}
          >
            {props.filterRegions?.length > 1 && <option value="">All Region</option>}
            {Array.isArray(props.filterRegions)
              ? props.filterRegions?.map((region) => (
                <option key={region._id} value={region._id}>
                  {region.name}
                </option>
              ))
              : null}
          </SelectComponent>
          {updatedDetail &&
            <div className={`${styles.banner_citys_update}`}>
              {updatedDetail.userName && (
                <p>
                  Last updated by: <span>{updatedDetail.userName}</span>
                </p>
              )}
              {updatedDetail.updatedAt && (
                <p>
                  Last update on:{" "}
                  <span>
                    {moment(updatedDetail.updatedAt).format(
                      "DD/MM/YYYY | hh:mm A"
                    )}
                  </span>
                </p>
              )}
            </div>
          }
        </div>
        <div className={styles.management_contents_upload}>
        <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => setConfirmClear(true),
            }}
          >
            Clear
          </ButtonComponent>
          <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => history.push(`/Content`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details_contents}>
        <label className={commonstyles.secondry_title}>Title<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
          *
        </span> </label>
        <InputComponent
          error={desirableHandpickedErrors.title}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type here",
            name: "title",
            maxLength: 100,
            value: regionDesirableData && regionDesirableData.title ? regionDesirableData.title : "",
            onChange: (e) =>
              handleFormInput("title", e.target.value),
          }}
        />
        <FormErrorText error={desirableHandpickedErrors.title} />
        <label className={commonstyles.secondry_title}>Sub Title<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
          *
        </span></label>
        <InputComponent
          error={desirableHandpickedErrors.subTitle}
          className="form-control-lg mb-3"
          formControl={{
            placeholder: "Type here",
            name: "subTitle",
            maxLength: 100,
            value: regionDesirableData && regionDesirableData.subTitle ? regionDesirableData.subTitle : "",
            onChange: (e) =>
              handleFormInput('subTitle', e.target.value),
          }}
        />
        <FormErrorText error={desirableHandpickedErrors.subTitle} />
        <div className="row">
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>Product<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
              *
            </span></label>
            <Select
              error={desirableHandpickedErrors.product}
              className="productSelect"
              mode="multiple"
              name="region"
              placeholder="Select products"
              optionLabelProp="label"
              value={productOption ? productOption : []}
              filterOption={(input, option) =>  
                option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onDeselect={(value, option) => handleDeselect(value, option)}
              onSelect={(value, option) => searchSelectOnChange(value, option)}
            >
              {productList?.map((product) => (
                <Option
                  value={product.feature_image}
                  label={product.name}
                  key={product._id}
                >
                  <div className="demo-option-label-item">{product.name}</div>
                </Option>
              ))}
            </Select>
            <FormErrorText error={desirableHandpickedErrors.product} />
          </div>
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>
              Preview Image Order
            </label>
            <SortableList
              onSortEnd={onSortEnd}
              className={styles.sortingimg_root}
            >
              <div className={commonstyles.banner_img_box_outline} style={{ paddingBottom: "20px" }}>
                {galleryImgs?.length > 0 &&
                  galleryImgs?.map((imgs, index) => (
                    <div className={commonstyles.banner_img_preview}>
                      <SortableItem key={imgs.key}>
                        <div>
                          <img
                            style={{ pointerEvents: "none" }}
                            className={commonstyles.previewImgs}
                            key={imgs.key}
                            src={imgs.value}
                            alt="profileImg"
                            imgProps={{ draggable: false }}
                          />
                          <div className={styles.imgpreview_index}>
                            {index + 1}
                          </div>
                          <img
                            onClick={() => removeImages(imgs, index)}
                            className={commonstyles.previewImgsdeleteicon}
                            src={bannerimgdelete}
                            alt="delete"
                          />
                        </div>
                      </SortableItem>
                    </div>
                  ))}
              </div>
            </SortableList>
          </div>
        </div>
      </div>
      <ConfirmationBox
        modal={{
          show: confirmClear,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelClear}
        handleConfirm={clearRegionData}
        title="Confirm the action"
        confirmText="Clear"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to clear?
            </p>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
};

export default DesirableHandpicked;
