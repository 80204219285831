import React, { useState, useEffect } from "react";
import styles from "../../../../styles/AddDiscount.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import styless from "../../../../styles/AddProduct.module.css";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import { notification, Space, Spin, Select } from "antd";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import { useHistory } from "react-router";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";

import {
  onlyNumbers,
  onlyThreeNumbers,
  minCharwithnospacecount,
  onlyAlphabetsWithSpace,
  phoneRegx,
} from "../../../../utils/RegularExp";

const { Option } = Select;

const initialState = {
  ruleStatus: true,
  taxName: "",
  regionId: [],
  percentage: "",
  startDate: "",
  endDate: "",
  enable: true,
  taxType: 0,
};

export default function OfferManagement() {
  const { id } = useParams();
  const history = useHistory();

  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taxDetails, setTaxDetails] = useState(initialState);
  const [editForm, setEditForm] = useState(initialState);
  const [currentMinAm, setCurrentMinAm] = useState(0);

  const [formErrors, setFormErrors] = useState({
    taxName: false,
    startDate: false,
    endDate: false,
    percentage: false,
    regionId: false,
  });

  const rules = {
    taxName: [(val, data) => (!val ? "Tax name " : true)],
    percentage: [(val, data) => (!val ? "Percentage " : true)],
    startDate: [(val, data) => (!val ? "Start date " : true)],
    endDate: [(val, data) => (!val ? "End date " : true)],
  };

  useEffect(() => {
    if (id) {
      getWarehouseDetails(id);
    } else {
      setLoading(false);
    }
    return (() => {
      history['isAdd'] = true;
    })
    /* getRegionList();  */
  }, []);

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            setRegions(res.data.daaata);
            setLoading(false);
          } else if (res.data.status === 400) {
            const errors = formatServerValidtionErrors(res.data.error);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    getRegionList();
  }, []);
  /**
   * @author Rajkumar
   * @param {id}
   * TO GET WAREHOUSE DETAILS
   */
  function getWarehouseDetails(id) {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/tax/taxdetail/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setLoading(false);
          setCurrentMinAm(val.taxType);
          setEditForm(val);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setLoading(false);
      });
  }
  /**
   * @author Rajkumar
   * @param {type, message, description}
   * SHOW NOTIFICATION USING ANTD
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function discountList() {
    history.push("/tax");
  }

  function handleRegionChange(e) {
    editForm.regionId = e;
    setEditForm({ ...editForm });
  }

  function handleOfferChange(e) {
    const { name, checked, value } = e.target;
    if (value == 0) {
      setCurrentMinAm(value);
      editForm.taxType = value;
    } else if (checked == "1") {
      setCurrentMinAm(value);
      editForm.taxType = value;
    }

    setEditForm({ ...editForm });
  }

  function handleTax(e) {
    editForm.taxName = e.target.value;
    setEditForm({ ...editForm });
  }

  function handlepercentage(e) {
    editForm.percentage = e.target.value;
    setEditForm({ ...editForm });
  }

  function handleenable(e) {
    editForm.enable = e.target.checked;
    setEditForm({ ...editForm });
  }

  function handlefirstdate(e) {
    editForm.startDate = moment(e.target.value).format("YYYY-MM-DD");
    setEditForm({ ...editForm });
  }

  function handlelastdate(e) {
    editForm.endDate = moment(e.target.value).format("YYYY-MM-DD");
    setEditForm({ ...editForm });
  }
  /**
   * @author Rajkumar
   * TO ADD DISCOUNT
   */
  function addDiscount() {
    const data = {
      id: id,
      taxName: editForm.taxName.trim(),
      enable: JSON.parse(editForm.enable),
      percentage: parseInt(editForm.percentage),
      startDate: moment(editForm.startDate).format("YYYY-MM-DD"),
      endDate: moment(editForm.endDate).format("YYYY-MM-DD"),
      regionId: editForm.regionId,
      taxType: parseInt(editForm.taxType) || parseInt(currentMinAm),
    };

    if (data.startDate == "Invalid date") {
      data.startDate = "";
    }
    if (data.endDate == "Invalid date") {
      data.endDate = "";
    }
    if (data.taxName.trim().length < 3) {
      data.taxName = "";
    }

    const result = runValidationChecks(rules, undefined, { ...data });

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }
    if (
      editForm.taxName.trim().length < 3 ||
      editForm.taxName.trim().length > 30
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description:
          "Tax name must be more than 2 characters and less than 30 characters",
      });
      return;
    }

    if (edate >= sdate) {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .post("admin/tax/updatetax", data)
        .then((res) => {
          if (res.data.status === 200) {
            showNotification({
              type: "success",
              message: "Success",
              description: "Tax details updated successfully",
            });
            history.push("/tax");
          } else if (res.data.status === 400) {
            showNotification({
              type: "warning",
              message: "There were few errors",
              description: res.data.msg,
            });
            const errors = formatServerValidtionErrors(res.data.error);
            return;

            // setFormErrors(errors);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("End date should be greater than start date.");
    }
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={discountList}
          />
          Edit Additional Tax
        </h1>
        <div>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleenable(e)}
              checked={editForm.enable}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/tax`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{ onClick: addDiscount }}
            style={{ cursor: "pointer" }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      {loading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching Tax details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={styles.management_details}>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.management_details_contents}>
                <div className={styles.management_details_contents_header}>
                  <h4>
                    Tax name
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </h4>
                </div>

                <div className={styles.management_details_form}>
                  <InputComponent
                    className="form-control-lg"
                    error={formErrors.taxName}
                    formControl={{
                      name: "taxName",
                      value: editForm.taxName,
                      onChange: (e) => {
                        if (!minCharwithnospacecount.test(e.target.value)) {
                          if (!formErrors.taxName) {
                            formErrors.taxName = {};
                          }
                          formErrors.taxName = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.taxName) {
                            formErrors.taxName = "";
                          }
                        }
                        handleTax(e);
                      },
                      maxLength: "30",
                    }}
                  />
                  <FormErrorText error={formErrors.taxName} />
                </div>
              </div>

              <div className={styles.management_details_contents}>
                <div className={styles.management_details_contents}>
                  <h4>
                    Percentage
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </h4>

                  <div className={styles.management_details_form}>
                    <InputComponent
                      className="form-control-lg"
                      formControl={{
                        maxlength: 3,
                        type: "text",
                        name: "percentage",
                        value: editForm.percentage,
                        onChange: (e) => {
                          if (!onlyNumbers.test(e.target.value)) {
                            if (!formErrors.percentage) {
                              formErrors.percentage = {};
                            }
                            formErrors.percentage = "Enter only numbers";
                          } else {
                            if (formErrors.percentage) {
                              formErrors.percentage = "";
                            }
                          }
                          if (!onlyThreeNumbers.test(e.target.value)) {
                            formErrors.percentage =
                              "Enter only 3 numbers within 100";
                          }

                          handlepercentage(e);
                        },
                      }}
                      error={formErrors.percentage}
                    />
                    <FormErrorText error={formErrors.percentage} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.management_details_contents}>
                <h4>Region</h4>
                <div className={styles.management_details_form}>
                  <Select
                    mode="multiple"
                    name="regionId"
                    value={editForm.regionId}
                    style={{ width: "100%" }}
                    placeholder="Select multiple state"
                    onChange={(e) => handleRegionChange(e)}
                    optionLabelProp="label"
                  >
                    {regions.map((region) => (
                      <Option value={region._id} label={region.name}>
                        <div className="demo-option-label-item">
                          {region.name}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={styles.management_details_contents}>
                <h4>
                  GST
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </h4>
                <div className={styles.management_details_form}>
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                  >
                    <input
                      type="radio"
                      id="none"
                      name="none"
                      className="custom-control-input"
                      checked={currentMinAm == 0}
                      value={0}
                      onChange={(e) => handleOfferChange(e)}
                    />
                    <label className="custom-control-label" for="none">
                      <span>Exclusive</span>
                    </label>
                  </div>
                  <div
                    className={`custom-control custom-radio ${styles.custom_radio} mb-3`}
                  >
                    <input
                      type="radio"
                      id="taxType"
                      name="taxType"
                      className="custom-control-input"
                      checked={currentMinAm > 0}
                      value="1"
                      onChange={(e) => handleOfferChange(e)}
                    />
                    <label className="custom-control-label" for="taxType">
                      <span>Inclusive</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.management_details_contents}>
                <h4>
                  Tax validity
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </h4>
                <div className={styles.management_details_form}>
                  <div className="row mb-2">
                    <div className={`col-lg-6 ${styles.input_img}`}>
                      <label>Start date</label>

                      <InputComponent
                        className="form-control-sm"
                        error={formErrors.startDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(new Date())
                          .add(1, "years")
                          .format("YYYY-MM-DD")}
                        formControl={{
                          name: "startDate",
                          // value: newCategory.name,
                          type: "date",

                          value: moment
                            .utc(editForm.startDate)
                            .format("YYYY-MM-DD"),
                          style: {
                            cursor: "pointer",
                            padding: "5px",
                            textTransform: "uppercase",
                          },
                          onChange: (e) => handlefirstdate(e),
                        }}
                      />

                      <FormErrorText
                        error={formErrors.startDate ? "Required" : ""}
                      />
                    </div>
                    <div className={`col-lg-6 ${styles.input_img}`}>
                      <label>End date</label>

                      <InputComponent
                        className="form-control-sm"
                        error={formErrors.endDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(new Date())
                          .add(1, "years")
                          .format("YYYY-MM-DD")}
                        formControl={{
                          name: "endDate",
                          type: "date",
                          value: moment
                            .utc(editForm.endDate)
                            .format("YYYY-MM-DD"),
                          style: {
                            cursor: "pointer",
                            padding: "5px",
                            textTransform: "uppercase",
                          },
                          onChange: (e) => handlelastdate(e),
                        }}
                      />
                      <FormErrorText
                        error={formErrors.endDate ? "Required" : ""}
                      />

                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {/* {errorMessage} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
