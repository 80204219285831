import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import { useParams } from "react-router-dom";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  addressRegx,
  alphaNum,
  noSpace,
  alphaNumwithSpace,
  alphanumericwithdashHyphen,
  minChar,
  minCharWithNoMaxLimit,
  onlyAlphabets,
  userNameWithSpace,
} from "../../../utils/RegularExp";
import commonstyles from "../../../styles/Management_Common.module.css";
import { runValidationChecks } from "../../../utils";
import { isObject, isString, isEqual } from "lodash";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";

import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {MAIN_SITE} from "../../../utils/apiService"

const { Option } = Select;
const initialFormState = {
  _id: "",
  title: "",
  slug: "",
  headerContent: "",
  footerContent: "",
  categoryId: [],
  regionId: "",
  metaTitle: "",
  metaDescription: "",
  metaKeywords: "",
  enable: true,
};

export default function LandingCategoryComponent() {
  const rules = {
    title: [(val, data) => (!val ? "Title " : true)],
    slug: [(val, data) => (!val ? "Slug " : true)],
    category: [
      (val, data) => (data.categoryId.length > 0 ? true : ""),
    ],
    regionId: [(val, data) => (data.regionId ? true : "Region ")],
    headerContent: [(val, data) => (!val ? "Header content " : true)],
    footerContent: [(val, data) => (!val ? "Footer content " : true)],
    metaTitle: [(val, data) => (!val ? "Meta title " : true)],
    metaKeywords: [(val, data) => (!val ? "Meta keywords " : true)],
    metaDescription: [(val, data) => (!val ? "Meta description " : true)],
  };

  const history = useHistory();

  const { state } = history.location;

  // const { id } = history?.location?.state ? history?.location?.state : "";
  const { id } = useParams();
  // const [loading, setLoading] = useState({ details: false, update: false });
  const [page, setPage] = useState(state);
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  // const [filterRegions, setFilterRegions] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [enable, setEnable] = useState(true);
  const [topContent, setTopContent] = useState("");
  // const { state } = history.location;
  // const [page, setPage] = useState(state);
  const [bannerDetail, setBannerDetail] = useState([]);
  const [editForm, setEditForm] = useState(
    JSON.parse(JSON.stringify(initialFormState))
  );
  const [category, setCategory] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [regionName, setRegionName] = useState("");

  let globalLinkToMainSite = "";
  if (id) {
    globalLinkToMainSite = `${MAIN_SITE}/landing/${editForm.slug}`;
  } else {
    globalLinkToMainSite = `${MAIN_SITE}/landing/${editForm.slug}-${regionName}`;
  }
    /**
   * @author Rajkumar
   * To get parent categories
   */
  function getParentCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setParentCategories(res.data.daaata);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.error("Failed to fetch parent categories");
      });
  }

  useEffect(() => {
    setPage(state ? state : 4);
    getParentCategories();
    getLandingList();
    return () => {
      history["isCategory"] = true;
    };
  }, [topContent]);
  /**
   * @author Rajkumar
   * To get landing list
   */
  function getLandingList() {
    setIsLoading(true);
    AxiosInstance(PORTS.CMS, "application/json")
      .get(`/categoryPage/${id}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const val =
            res.data.data[0] || JSON.parse(JSON.stringify(initialFormState));
          // console.log(val);
          // setEditForm({});
          // console.log(initialFormState);
          setEnable(val?.enable)
          setIsLoading(false);
          setTopContent(val?.headerContent);
          setEditForm(val);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInputSlug(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) {
      return false;
    } else {
      if (!alphanumericwithdashHyphen.test(event.target.value)) {
        formErrors.slug = "Enter only alphabet and number";
      }
      setEditForm({
        ...editForm,
        slug: event.target.value.toLowerCase().split(" ").join(""),
      });
    }
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    if (name === "metaDescription" && !minChar.test(e.target.value)) {
      if (!formErrors.metaDescription) {
        formErrors.metaDescription = {};
      }
      formErrors.metaDescription = "Enter minimum 3 characters";
    } else {
      if (formErrors.metaDescription) {
        formErrors.metaDescription = "";
      }
    }
    if (["title"].includes(name)) {
      if (noSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      if (!alphaNumwithSpace.test(value)) {
        formErrors.title = "Enter only alphabet and number";
      }
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaTitle"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaTitle = "Enter only alphabet and number";
      // }
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaKeywords"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaKeywords = "Enter only alphabet and number";
      // }
  
      setEditForm({ ...editForm, [name]: value });
    }

    if (["metaDescription"].includes(name)) {
      // if (noSpace.test(value)) {
      //   formErrors.metaDescription = "Enter only alphabet and number";
      // }
     
      setEditForm({ ...editForm, [name]: value });
    }

    const updatedValues = { ...editForm, [name]: value };

    if (name === "regionId") {
      regions?.forEach(item => {
        if (item._id === value) {
          formErrors.regionId = "";
          setRegionName(item.name);
        }
      })
      if (value === '') { 
        setRegionName("All Region");
      }
      console.log(editForm, value);
      editForm[name] = value;
      // setRegionId(value);
    }

    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
      setEnable(checked);
    } else {
      updatedValues[name] = value;
    }
    setEditForm(updatedValues);
    console.log(updatedValues);
  }

  function handleCategory(e) {
    if (e.length == []) {
      formErrors.category = "Category";
    } else {
      formErrors.category = "";
    }
    editForm.categoryId = e;
    setCategory({ ...editForm });
  }

  // *** SAVE CHANGES *** //
  function submit() {
    let data = JSON.parse(JSON.stringify(editForm));
    // data.regionId = regionId ? regionId : "";

    delete data.userName;
    delete data.updatedAt;
    // const result = validate(undefined, data);
    const result = runValidationChecks(rules, undefined, { ...data });

    // console.log(result);
    if (Object.keys(result).length > 0) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }

    if (noSpace.test(editForm.title)) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number for title",
      });
      return;
    }

    if (
      (!alphanumericwithdashHyphen.test(editForm.slug) ||
      !alphaNumwithSpace.test(editForm.slug)) && !id
    ) {
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Enter only alphabet and number",
      });
      return;
    }
    // setIsLoading(true);

    let edit = "/categoryPage/updatePage";
    let add = "/categoryPage/createPage";

    if (id == undefined) {
      delete data._id;
    }
    data.enable = enable;
    if (!id) {
      data.slug = data.slug + '-' + regionName;
    }
    // console.log(data);
    AxiosInstance(PORTS.CMS, "application/json")
      //   .post(`/landingPage/${state?._id ? "updatePage" : "addPage"}/`, data)
      .post(id ? edit : add, data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          // setIsLoading(false);
          getLandingList();
          showNotification({
            type: "success",
            message: "Success",
            description: id
              ? "Category wise landing page updated successfully "
              : "Category wise landing page added successfully ",
          });
          history.push("/Content");
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res?.data?.msg,
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            // let obj = { name: "All Region", value: "", _id: "" }
            // res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            if (res.data.daaata[0]) {
              setRegionId(res.data.daaata[0]?._id);
            }
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        });
    }
    getRegionList();
  }, []);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
              .post(`/admin/uploadImages`, body)
              .then((res) => {
                if (res.data.status === 200) {
                  resolve({
                    default: `${res.data?.data[0]?.link}`,
                  });
                } else if (res.data.status === 400) {
                  showNotification({
                    type: "error",
                    message: "Adding CMS failed",
                    description: res.data?.msg || "Error occured",
                  });
                }
              })
              .catch((er) => {
                showNotification({
                  type: "error",
                  message: "Adding CMS failed",
                  description: "Error occured",
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          Category-Wise Landing Page Management
        </h1>
      </div>
      {isLoading === true && id ? (
        <div
          style={{
            marginLeft: "50vh",
            marginTop: "10vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching category wise landing page details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div>
          <div className={`${styles.banner_citys_update}`}>
            {editForm?.userName && (
              <p style={{ textTransform: "capitalize" }}>
                Last updated by: <span>{editForm?.userName}</span>
              </p>
            )}
            {editForm?.updatedAt && (
              <p>
                Last updated on:{" "}
                <span>
                  {moment(editForm?.updatedAt).format("DD/MM/YYYY | hh:mm A")}
                </span>
              </p>
            )}
          </div>
          <div
            className={`${styles.management_header} ${styles.banner_city_list}`}
          >
            <div className={`${styles.banner_citys} landing_select_city `}>
              {/* <span
                className="text-danger product_wise_text-danger"
                style={{ fontSize: "20px" }}
              >
                *
              </span> */}
              <div className="d-flex flex-column error_msg_right add_category_error_text">
                <Select
                  className={"form-control " + styles.form_control}
                  // onChange={(e) => setRegion(e.target.value)}
                  // value={region}
                  error={formErrors.category}
                  mode="multiple"
                  placeholder="Select category"
                  name="category"
                  value={editForm.categoryId}
                  onChange={(e) => handleCategory(e)}
                  style={{ cursor: "pointer" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {parentCategories.map((category) => (
                    <>
                      <option value={category._id} label={category.name}>
                        {category.name}
                      </option>
                    </>
                  ))}
                </Select>
                <FormErrorText error={formErrors.category} />
              </div>
              {/* <span
                className="text-danger product_wise_text-danger"
                style={{ fontSize: "20px" }}
              >
                *
              </span> */}
              <div
                className={`${styles.add_category_input_white} add_category_error_text d-flex flex-column`}
              >
                <select
                  className={`${styles.form_control}`}
                  error={formErrors.regionId}
                  name="regionId"
                  value={editForm?.regionId}
                  placeholder="Select Region"
                  onChange={handleFormInput}
                  style={{ cursor: "pointer" }}
                  disabled={id ? true : false}
                >
                  <option value="">Select Region</option>
                  {regions.map((region) => (
                    <>
                      <option value={region._id} label={region.name}>
                        {region.name}
                      </option>
                    </>
                  ))}
                </select>
                <FormErrorText error={formErrors.regionId} />
              </div>
            </div>

            <div className="d-flex">
              <div className={styles.management_contents_upload}>
                <div
                  className={`${styles.onoffswitch} d-inline-block align-top`}
                >
                  <input
                    type="checkbox"
                    name="enable"
                    className={styles.onoffswitch_checkbox}
                    onChange={handleFormInput}
                    checked={enable}
                    id="statusSwitch"
                  />
                  <label
                    className={styles.onoffswitch_label}
                    htmlFor="statusSwitch"
                  >
                    <span className={styles.onoffswitch_inner}></span>
                    <span className={styles.onoffswitch_switch}></span>
                  </label>
                </div>
              </div>
              <div className={styles.management_contents_upload}>
                <ButtonComponent
                  className="rnd mr-3 outline_cancel_btn"
                  attributes={{
                    onClick: () => history.push(`/Content`),
                  }}
                >
                  Cancel
                </ButtonComponent>
              </div>
              <div className={styles.management_contents_upload}>
                <ButtonComponent
                  className="rnd"
                  attributes={{
                    // disabled: loading.update,
                    onClick: submit,
                  }}
                >
                  Save
                </ButtonComponent>
              </div>
            </div>
          </div>

          <div
            className={`${styles.management_details} ${styles.landing_category_management}`}
          >
            <div
              /* key={indx} */ className={styles.management_details_contents}
            >
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Page title
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.title}
                      formControl={{
                        placeholder: "",
                        name: "title",
                        value: editForm?.title,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.title) {
                              formErrors.title = {};
                            }
                            formErrors.title = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.title) {
                              formErrors.title = "";
                            }
                          }

                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.title} />
                  </div>
                </div>
                {/* Slug */}
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Slug
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                    <a href={globalLinkToMainSite} target="_blank">
                      {editForm.slug && globalLinkToMainSite}
                    </a>
                  </label>
                  <InputComponent
                    error={formErrors.slug}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type slug",
                      name: "slug",
                      value: editForm?.slug,
                      disabled: id? true : false,
                      onChange: (e) => {
                        if (!minChar.test(e.target.value)) {
                          if (!formErrors.slug) {
                            formErrors.slug = {};
                          }
                          formErrors.slug = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.slug) {
                            formErrors.slug = "";
                          }
                        }
                        handleInputSlug(e);
                      },
                    }}
                  />
                  <FormErrorText error={formErrors.slug} />
                </div>
                {/* Slug ends */}
                <div className="col-lg-12 mb-4">
                  <div
                    className={`${styles.management_details_form} rich_text_hidden`}
                  >
                    <label for="">
                      Top Content
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>

                    {/* {page?._id && editForm.content && ( */}
                    <CKEditor
                      error={formErrors.headerContent}
                      editor={ClassicEditor}
                      data={topContent || ""}
                      config={{ extraPlugins: [uploadPlugin] }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };
                        // console.log(data);
                        // console.log(values);
                        values.headerContent = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.headerContent) {
                            formErrors.headerContent = {};
                          }
                          formErrors.headerContent = "Header content required";
                        } else {
                          if (formErrors.headerContent) {
                            formErrors.headerContent = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />
                    {/* )} */}
                    {/*  {!(page?._id && editForm.content) && (
                                        <CKEditor
                                        editor={ ClassicEditor }
                                        data={editForm?.content || ""}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            const values = editForm;
                                            values.content = data;
                                            setEditForm(values);
                                        } }
                                    />
                                    )} */}

                    <FormErrorText error={formErrors.headerContent} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div
                    className={`${styles.management_details_form} rich_text_hidden`}
                  >
                    <label for="">
                      Bottom Content
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <CKEditor
                      error={formErrors.footerContent}
                      editor={ClassicEditor}
                      config={{ extraPlugins: [uploadPlugin] }}
                      data={editForm?.footerContent || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const values = { ...editForm };
                        // console.log(data);
                        // console.log(values);
                        values.footerContent = data;
                        if (!minCharWithNoMaxLimit.test(data)) {
                          if (!formErrors.footerContent) {
                            formErrors.footerContent = {};
                          }
                          formErrors.footerContent = "Footer content required";
                        } else {
                          if (formErrors.footerContent) {
                            formErrors.footerContent = "";
                          }
                        }
                        setEditForm(values);
                      }}
                    />
                    <FormErrorText error={formErrors.footerContent} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.management_details_contents}>
              <h4 className="theme-form-section-title">
                Search Engine Optimization
              </h4>
              <div className="row">
                {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">URL Key</label>
                            <InputComponent
                                className={`form-control form-control-lg ${styles.form_control}`}
                                // error={formErrors.urlkey}
                                formControl={{
                                placeholder: "",
                                name: "urlkey",
                                value: editForm?.urlkey,
                                onChange:handleFormInput,
                                }}
                            />
                            // <FormErrorText error={formErrors.urlkey} />
                            </div>
                        </div> */}
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Title
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaTitle}
                      formControl={{
                        placeholder: "",
                        name: "metaTitle",
                        value: editForm?.metaTitle,
                        error: formErrors.metaTitle,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaTitle) {
                              formErrors.metaTitle = {};
                            }
                            formErrors.metaTitle = "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaTitle) {
                              formErrors.metaTitle = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaTitle} />
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Keywords
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <InputComponent
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaKeywords}
                      formControl={{
                        placeholder: "",
                        name: "metaKeywords",
                        value: editForm?.metaKeywords,
                        error: formErrors.metaKeywords,
                        onChange: (e) => {
                          if (!minChar.test(e.target.value)) {
                            if (!formErrors.metaKeywords) {
                              formErrors.metaKeywords = {};
                            }
                            formErrors.metaKeywords =
                              "Enter minimum 3 characters";
                          } else {
                            if (formErrors.metaKeywords) {
                              formErrors.metaKeywords = "";
                            }
                          }
                          handleFormInput(e);
                        },
                      }}
                    />
                    <FormErrorText error={formErrors.metaKeywords} />
                  </div>
                </div>
                <div className="col-lg-12 mb-4">
                  <div className={styles.management_details_form}>
                    <label for="">
                      Meta Description
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <textarea
                      className={`form-control form-control-lg ${styles.form_control}`}
                      error={formErrors.metaDescription}
                      // formControl={{
                      placeholder=""
                      name="metaDescription"
                      value={editForm?.metaDescription}
                      onChange={(e) => handleFormInput(e)}
                      // maxLength="40"
                      // }}
                    />
                    <FormErrorText error={formErrors.metaDescription} />
                  </div>
                </div>
                {/* <div className="col-lg-6 mb-4">
                            <div className={styles.management_details_form}>
                            <label for="">Meta Description</label>
                                <select
                                    className={"form-control " + styles.form_control}
                                    name="regionId"
                                    value={regionId}
                                    onChange={handleFormInput}
                                    style={{ cursor: "pointer" }}
                                    >
                                    <option value="" disabled>All region</option>
                                    {regions.map((region) => (
                                        <>
                                        <option value={region._id} label={region.name}>
                                            {region.name}
                                        </option>
                                        </>
                                    ))}
                                </select>
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* )} */}
    </div>
  );
}
