import React, { useEffect, useState, useRef } from "react";
import styles from "../../../../styles/AddProductAttribute.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import { notification } from "antd";
import { ChromePicker } from "react-color";
import Search from "../../../../assets/images/search_icon.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import backIcon from "../../../../assets/images/black_left_arrow.svg";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import validationRules from "../../../../utils/validationRules";
import TextFieldAttributeOptions from "../../../modules/ProductManagement/ProductAttributes/TextFieldAttributeOptions";
import SwitchComponent from "../../../modules/ReusableComponent/SwitchComponent";
import { runValidationChecks } from "../../../../utils";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import SearchComponent from "../../SearchManagement/SearchComponent";
import ImgCrop from 'antd-img-crop';
import NoImage from "../../../../../src/assets/NoImage.svg"
import { Upload } from 'antd';
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";

const initialAttributeState = {
  name: "",
  attributeCode: "",
  filter: false,
  trending: false,
  optionType: 0,
  options: [
    {
      name: "",
      value: "",
    },
  ],

  catalogInputType: "text_field",

};

const rules = {
  name: [validationRules.required],
  // attributeCode: [validationRules.required],
  // options: [
  //   (val) => {
  //     if (Array.isArray(val)) {
  //       const errors = val.reduce(validateStockItem, {});
  //       if (Object.keys(errors).length) {
  //         return errors;
  //       }
  //       return true;
  //     }
  //   },
  // ],
};

function validateStockItem(accumulator, currentStock, indx) {
  const error = {};
  /* if (!currentStock?.region?.id) {
    error["region"] = "Requried";
  } */
  ["name"].forEach((field) => {
    if (!currentStock[field]) {
      error[field] = "Required";
    } /* else if (currentStock.oldPrice <= 0 && field !== "quantity") {
      error[field] = "Value should be greater than 0";
    } */
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

const colorOption = {
  name: "",
  value: "",
  option: 1
};

const color = JSON.parse(JSON.stringify(colorOption));

export default function ManageColor(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const [prdAttribute, setPrdAttribute] = useState(initialAttributeState);
  const [formErrors, setFormErrors] = useState({});
  const [addForm, setAddForm] = useState([color]);
  const [colorPick, setColorPick] = useState("#fff");
  const [showColorPicker, setShowColorPicker] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const fileRef = useRef();
  const [seletedIndx, setSeletedIndx] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [search, setSearch] = useState();
  const [allData, setAllData] = useState([]);

  useEffect(() => {

    //console.log(prdAttribute.name);
    getProductAttributeDetails();
  }, []);

  function getProductAttributeDetails(params, type) {
    let url;
    setIsLoading(true);
    const id = "60bdc54e97a14a2840b2e807";

    // const colorSearch = params?.search && JSON.stringify(JSON.parse(params));

    if (type) url = `/productOptionDetail/${id}?color=${params.search}`
    else url = `/productOptionDetail/${id}`;

    AxiosInstance(PORTS.CATALOG_SERVICE)
      .get(url)
      .then((res) => {
        //(res.data)
        if (res.data.status === 200) {
          setIsLoading(false);
          setPrdAttribute(res.data.data);
          res.data.data.filter = res?.data?.data?.filter ? "true" : "false";
          res?.data?.data?.options?.forEach(item => {
            item["option"] = item?.enable
          })
          setAddForm(res.data.data.options);
          if(!params?.search) {
            setAllData(res.data.data.options);
          }
        }
      })
      .catch((er) => {
        //console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    //console.log(addForm);
  }, [addForm]);

  function handleFormInput(e) {
    let { name, value } = e.target;
    // console.log(`${name}: ${value}`);

    if (name) {
      setPrdAttribute({ ...prdAttribute, [name]: value });
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function submit(e) {
    var newArr = allData.map(obj => addForm.find(o => o._id === obj._id) || obj);
    setIsSaving(true);
    setFormErrors({});
    let cloneAddForm = { ...newArr };
    newArr?.forEach(item => {
      // item['enable'] = item?.option ? parseFloat(item?.option) : 0
      item['enable'] = 1
      delete item?.option;
    })
    const data = {
      _id: prdAttribute._id,
      name: prdAttribute.name,
      options: newArr,
      filter: prdAttribute.filter === "true" ? true : false,
      attributeCode: prdAttribute.attributeCode,
      optionType: prdAttribute.optionType === "1" ? 1 : 0,
    };
    const result = runValidationChecks(rules, undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      setIsSaving(false);
      return;
    }

    // console.log(data);
    AxiosInstance(PORTS.CATALOG_SERVICE)
      .post(`/updateProductOption`, data)
      .then((res) => {

        if (res.data.status === 200 || res.data.status === "200") {
          // newArr?.forEach(item => {
          //   item["option"] = "color";
          // })
          getProductAttributeDetails();
          setIsSaving(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res?.data?.msg,
          });
          setAddForm([...cloneAddForm]);
          //console.log("Updated");
        }
      })
      .catch((er) => {
        //console.log(er);
        setIsSaving(false);
        return er;
      }).finally(() => {
        setIsSaving(false);
      })
  }

  /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * PUSH NEW JSON OBJECT TO MAIN OBJECT AND SET STATE
   */
  function addNew() {
    const updatedValue = JSON.parse(JSON.stringify(addForm));
    const newColor = JSON.parse(JSON.stringify(color));
    updatedValue.push(newColor);
    setAddForm(updatedValue);
  }

  /**
  * @author Mithun Prabhu
  * @param {type, message, description}
  * REMOVE/SPLICE PARTICULAR JSON OBJECT(INDEX) FROM MAIN OBJECT AND SET STATE
  */
  const [removeData, setRemoveData] = useState('');
  function removeColorOption(data, index) {
    // console.log("index");
    // console.log(index);
    // const updatedValue = addForm;
    // updatedValue.splice(index, 1);
    // setAddForm([...updatedValue]);
    setRemoveData(data);
  }

  const removeColor = () => {
    const body = {
      _id: prdAttribute._id,
      option_id: removeData._id
    };
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
    .post(`/deleteColor`, body)
    .then((res) => {
      if (res.data.status === 200) {
        getProductAttributeDetails();
        setRemoveData('');
        showNotification({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Color deleted successfully",
        });
      } else if (res.data.status === 400) {
        showNotification({
          type: "error",
          message: "Failed",
          description: res.data?.msg || "Error occured",
        });
      }
    })
    .catch((er) => {
      showNotification({
        type: "error",
        message: "Somthing wrong",
        description: "Error occured",
      });
    });
  }

  const cancelDelete = () => {
    setRemoveData('');
  }

  /**
    * @author Mithun Prabhu
    * @param {type, message, description}
    * ADDING NEW COLOUR FILLING FORM
    */
  function handleColorOptionInputChange(e, ind) {
    let { name, value } = e.target;
    // console.log(`name ${name} value ${value}`);
    if (name) {
      if (value.trim()) {
        value = value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        value = "";
      }
    }
    let currentColorOption = addForm[ind];
    currentColorOption[name] = value;
    //console.log(currentColorOption);
    const colorOption = addForm;
    colorOption[ind] = currentColorOption;
    //console.log(colorOption)
    setAddForm([...colorOption]);
  }

  function handleInputChange(e, ind) {
    let { name, value } = e.target;
    // console.log(`name ${name} value ${value}`);
    let currentColorOption = addForm[ind];
    currentColorOption.option = value;
    // currentColorOption.value = "";
    // currentColorOption.image_link = "";
    //console.log(currentColorOption);
    const colorOption = addForm;
    colorOption[ind] = currentColorOption;
    //console.log(colorOption)
    setAddForm([...colorOption]);
  }

  const handleImageInput = (file, ind) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";

    if (!isJpgOrPng) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload only jpg,jpeg and png format images",
      });
      return;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;

    if (!isLt3M) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
      return;
    }

    setFileList(file);
    const body = new FormData();
    body.append("files", file);
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post(`/admin/uploadImages`, body)
      .then((res) => {
        if (res.data.status === 200) {
          setIsUploading(false);
          let currentColorOption = addForm[ind];
          currentColorOption.image_link = res.data?.data[0]?.link;
          const colorOption = addForm;
          colorOption[ind] = currentColorOption;
          setAddForm([...colorOption]);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding CMS failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding CMS failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsUploading(false);
        setIsLoading(false);
      });

    return isJpgOrPng && isLt3M;
  }

  function handleImageInput1(info, ind) {

    let newFileList = [...info.fileList]; // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    newFileList = newFileList.slice(-2); // 2. Read from response and show file link

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }

      return file;
    });
    setFileList(newFileList);
  }

  function btnClick(ind) {
    if (ind === showColorPicker) {
      setShowColorPicker("");
    } else {
      setShowColorPicker(ind);
    }
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if(!value) {
      const params = {
        search: ""
      }
      getProductAttributeDetails(params, true);
    }
  }

  function searchClick() {
    const params = {
      search: search
    }

    getProductAttributeDetails(params, true);
  }

  const [fileList, setFileList] = useState();

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };


  return isLoading === true ? (
    <div
      style={{
        marginLeft: "70vh",
        marginTop: "40vh",
      }}
    >
      <tr className="bg-transparent">
        <td colSpan="100%">
          <LoadingIndicator loadingText="Fetching color details..." />
        </td>
      </tr>
    </div>
  ) : (
    <div className="col-lg-12">
      <div
        className={`${styles.management_contents} ${styles.management_contents_fullheight}`}
      >
        <SearchComponent />
        <div className={styles.management_header}>
          <h1>
            <img src="img/black_left_arrow.svg" alt="" />
            Colour
          </h1>
          <div className={styles.management_contents_upload}>
            {permissionsArr.length > 0 &&
              permissionsArr.find((x) => x === "Catalog-Manage Colour-edit") ===
              "Catalog-Manage Colour-edit" && (
                <ButtonComponent
                  className="rnd"
                  attributes={{
                    onClick: submit,
                  }}
                >
                  {isSaving ? "Saving" : "Save"}
                </ButtonComponent>
              )}
          </div>
        </div>
        <div className={styles.management_details}>
          <h4>Attribute</h4>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Catalog-Manage Colour-edit") ===
            "Catalog-Manage Colour-edit" && (
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_form}>
                      <label>
                        Name{" "}
                        <span
                          className="text-danger"
                          style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                        >
                          *
                        </span>
                      </label>
                      <InputComponent
                        className="form-control form-control-lg form_control"
                        error={formErrors.name}
                        formControl={{
                          disabled: true,
                          placeholder: "Type Default Label",
                          name: "name",
                          value: prdAttribute.name,
                          onChange: handleFormInput,
                        }}
                      />
                      <FormErrorText error={formErrors.name} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_form}>
                      <label for="">
                        Attribute Code{" "}
                        <span
                          className="text-danger"
                          style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                        >
                          *
                        </span>
                      </label>
                      <InputComponent
                        className="form-control form-control-lg form_control"
                        error={formErrors.attributeCode}
                        formControl={{
                          disabled: true,
                          placeholder: "Type Default Label",
                          name: "attributeCode",
                          value: prdAttribute.attributeCode,
                          onChange: handleFormInput,
                        }}
                      />
                      <FormErrorText error={formErrors.attributeCode} />
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-4">
                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_form}>
                      <label for="">Options Types</label>

                      <SelectComponent
                        className="form-control form-control-md form_control"
                        attributes={{
                          name: "optionType",
                          value: prdAttribute.optionType,
                          onChange: handleFormInput,
                        }}
                      >
                        <option value="0">Static</option>
                        <option value="1">Dynamic</option>
                      </SelectComponent>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4">
                  <div className={styles.management_details_contents}>
                    <div className={styles.management_details_form}>
                      <label for="">Filter</label>

                      <SelectComponent
                        className="form-control form-control-md form_control"
                        attributes={{
                          name: "filter",
                          value: prdAttribute.filter,
                          onChange: handleFormInput,
                        }}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </SelectComponent>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <h4>Manage Swatch (Values of Your Attribute)</h4>
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "Catalog-Manage Colour-edit") ===
            "Catalog-Manage Colour-edit" && (
              <div className={styles.management_details_contents}>
                <div className={styles.management_details_form}>
                  <div className="table-responsive">
                    <div
                      className={`${commonstyles.management_contents_search_bar} input-group`}
                      style={{ marginLeft: "40px" }}
                    >
                      <input
                        type="search"
                        className={`${commonstyles.form_control} form-control`}
                        aria-label="Search"
                        placeholder="Search color name, color value"
                        aria-describedby="search-addon"
                        value={search}
                        onChange={handleSearch}
                      />
                      <img src={Search} alt="" />
                      <ButtonComponent
                        className="theme-btn rnd pl-3 pr-4 ml-2"
                        attributes={{
                          onClick: () => {
                            searchClick();
                          },
                        }}
                      >
                        Search
                      </ButtonComponent>
                    </div>

                    <table className="table table-borderless table_attributes">

                      <thead>
                        <tr>
                          <th scope="col" class="th_radio_button"></th>
                          <th scope="col" className={styles.th_admin}>
                            Name{" "}
                          </th>
                          {/* <th scope="col" className={styles.th_store_view}>
                            Options
                          </th>
                          <th scope="col" className={styles.th_store_view}>
                            Value
                          </th>
                          <th scope="col" className={styles.th_store_view}>
                            Choose the color
                          </th>
                          <th scope="col" className={styles.th_store_view}>
                            Colour
                          </th> */}
                          <th scope="col" className={styles.th_store_view}>
                            {/* Upload Image (If not choosed the color) */}
                            Upload Image
                          </th>
                          <th scope="col" className={styles.th_store_view}>
                            Image
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {addForm.length > 0 ? addForm.map((color, ind) => (
                          <tr key={ind}>
                            <td>
                              <div className="color_selector">
                                <span className="color_picker color1"></span>
                                <img
                                  src="img/down_arrow.svg"
                                  className="down_arrow_img"
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              <InputComponent
                                className="form-control form_control"
                                error={
                                  formErrors.options &&
                                  formErrors.options[ind] &&
                                  formErrors.options[ind].name
                                }
                                formControl={{
                                  placeholder: "Name",
                                  name: "name",
                                  disabled: true,
                                  value: color.name,
                                  onChange: (e) => {
                                    handleColorOptionInputChange(e, ind);
                                  },
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.options &&
                                  formErrors.options[ind] &&
                                  formErrors.options[ind].name
                                }
                              />
                            </td>
                            {/* <td>
                              <select
                                className={`${styles.form_control} form-control`}
                                name="entityType"
                                value={color.option}
                                onChange={(e) => handleInputChange(e, ind)}
                              >
                                <option value={0}>Color</option>
                                <option value={1}>Image</option>
                              </select>
                            </td> */}
                            {/* <td>
                              <InputComponent
                                className="form-control form_control"
                                error={
                                  formErrors.options &&
                                  formErrors.options[ind] &&
                                  formErrors.options[ind].value
                                }
                                formControl={{
                                  placeholder: "Value",
                                  name: "value",
                                  value: color.value,
                                  onChange: (e) => {
                                    handleColorOptionInputChange(e, ind);
                                  },
                                }}
                              />
                              <FormErrorText
                                error={
                                  formErrors.options &&
                                  formErrors.options[ind] &&
                                  formErrors.options[ind].value
                                }
                              />
                            </td> */}
                            {/* <td
                              className="color-pickerwrapper"
                            >
                              <ButtonComponent
                                className="rnd"
                                attributes={{
                                  onClick: () => btnClick(ind),
                                }}
                              >
                                {showColorPicker === ind
                                  ? "Close color picker"
                                  : "Pick the color"}
                              </ButtonComponent>
                              {(showColorPicker === ind) ?
                                (
                                  <ChromePicker
                                    color={color.value}
                                    onChange={(updatedColor) => {
                                      addForm[ind].value = updatedColor.hex;
                                      setAddForm([...addForm]);
                                    }}
                                  />
                                ) : null
                              }
                            </td> */}
                            {/* <td>
                              <div style={{ background: color.value }} className={styles.square_box}>
                              </div>
                            </td> */}
                            <td
                              className="color-pickerwrapper"
                            >
                              <ImgCrop>
                                <Upload
                                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  beforeUpload={(info) => handleImageInput(info, ind)}
                                >
                                  {(seletedIndx === ind && isUploading) ? "Uploading..." : "Upload color image"}
                                </Upload>
                              </ImgCrop>
                            </td>
                            <td>

                              {color.image_link ?
                                (<img
                                  className={styles.square_box}
                                  src={color.image_link}
                                  alt={color.image_link}
                                />) : (<img
                                  src={NoImage}
                                  className={commonstyles.admin_table_img}
                                  alt="Product image"
                                />)}



                            </td>
                            <td>
                              {permissionsArr.length > 0 &&
                                permissionsArr.find(
                                  (x) => x === "Catalog-Manage Colour-add"
                                ) === "Catalog-Manage Colour-add" && (
                                  <ButtonComponent
                                    className="rnd"
                                    attributes={{
                                      onClick: () => {
                                        removeColorOption(color, ind);
                                      },
                                    }}
                                  >
                                    Remove
                                  </ButtonComponent>
                                )}
                            </td>
                          </tr>
                        ))
                          :
                          <>
                            <tr className="bg-transparent text-info">
                              <td colSpan="3">No data found!</td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                  <br />
                  {/* {permissionsArr.length > 0 &&
                    permissionsArr.find(
                      (x) => x === "Catalog-Manage Colour-add"
                    ) === "Catalog-Manage Colour-add" && (
                      <div className="text-right">
                        <button className="theme-btn rnd" onClick={addNew}>
                          Add Colour
                        </button>
                      </div>
                    )} */}
                </div>
              </div>
            )}
        </div>
      </div>
      <ConfirmationBox
        modal={{
          show: removeData? true : false,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeColor}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
    </div>
  );
}