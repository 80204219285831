import styles from "../../../styles/AddProduct.module.css";
import ownStyles from "../../../styles/EmbeddedProductsTable.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import Down_arrow from "../../../assets/images/down_arrow.svg";
import { convertNumberToReadableINDCurrency as convertToReadablePrice } from "../../../utils";
import { useEffect, useRef, useState } from "react";
import PaginateWrapperComponent from "../ReusableComponent/PaginateWrapperComponent";
import NoProductsTableRowComponent from "./NoProductsTableRowComponent";
import DecorChipComponent from "./DecorChipComponent";

const intialFilterState = {
  categoryId: "",
  regionId: "",
  searchKey: "",
  status: "",
  pagesize: 7,
  nextpage: 1,
};

export default function EmbeddedDecorTableComponent(props) {
  // const [decors, setProducts] = useState([]);
  const [filteredDecors, setfilteredDecors] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState);
  const [isMinimal, setIsMinimal] = useState(false);
  const timeoutId = useRef();

  const decorIds = Array.isArray(props.decors)
    ? props.decors.map((p) => p._id)
    : [];
  const filteredSelectedDecors = Array.isArray(props.selecteddecors)
    ? props.selecteddecors.filter((p) => decorIds.includes(p._id))
    : [];

  // useEffect(() => {
  //   // filterProducts(filter);
  //   // setfilteredDecors(Array.isArray(props.decors) ? props.decors : []);
  // }, [props.decors]);

  useEffect(() => {
    // getProducts(filter);
    filterDecors(filter);
  }, [filter.nextpage, props.decors]);

  function filterDecors(filter) {
    const { searchKey, pagesize, nextpage } = filter;
    let filtered = [];

    let prds = props.decors || [];

    if (searchKey) {
      let query = searchKey.toLowerCase();
      prds = prds.filter((p) => {
        if (p.title.toLowerCase().indexOf(query) > -1) {
          return p;
        }
        // return false;
      });
    }

    let offsetIndex = nextpage <= 1 ? 0 : (nextpage - 1) * pagesize;

    for (let i = offsetIndex; i < prds.length; i++) {
      if (filtered.length >= pagesize) {
        break;
      }
      filtered[filtered.length] = prds[i];
    }
    const paginationData = { ...pagination };
    paginationData.endPage = Math.ceil(prds.length / pagesize);

    setfilteredDecors(filtered);
    setPagination(paginationData);
  }

  function debouncedSearch(filter) {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      filterDecors(filter);
    }, 500);
  }

  function handleInput(e) {
    const { name, value } = e.target;
    const updated = { ...filter, [name]: value };

    if (name === "searchKey") {
      updated.nextpage = 1;
      setFilter(updated);

      debouncedSearch(updated);
    }
  }

  function updateSelection(e, decor) {
    const action = e.target.checked ? "add" : "remove";
    props.handleSelection(action, decor, props.formField);
  }

  function handlePaginationChanges(value, type) {
    if (type === "nextPage") {
      setFilter({ ...filter, nextpage: value.selected + 1 });
    }
  }

  function handleDecorChipComponent(decor) {
    if (filteredSelectedDecors.length === 1 && isMinimal) {
      setIsMinimal(false);
    }
    props.handleSelection("remove", decor, props.formField);
  }

  //
  const selectedDecorIDs = filteredSelectedDecors
    ? filteredSelectedDecors.map((pr) => pr._id)
    : [];
  const hideElementClass = isMinimal ? `fade ${ownStyles.hide}` : "";
  //

  return (
    <div
      className={`${commonstyles.management_details_form} ml-0 pr-0 pl-0 position-relative`}
    >
      <div
        className={`${ownStyles.toggle_icon}`}
        onClick={() => setIsMinimal(!isMinimal)}
      >
        {/* ${ selectedDecorIDs.length === 0 ? "fade" : ""} */}
        {isMinimal ? (
          <img src={Down_arrow} title="Expand" />
        ) : (
          <img
            src={Down_arrow}
            style={{ transform: "rotate(180deg)" }}
            title="Collapse"
          />
        )}
      </div>
      <div
        className={`${styles.management_contents_search} ${ownStyles.animatable_elements} ${hideElementClass} mb-4`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            aria-describedby="search-decors"
            placeholder="Search Decors"
            name="searchKey"
            value={filter.searchKey}
            onChange={handleInput}
          />
          <img src={Search} alt="" />
        </div>
      </div>

      <div className={styles.management_table_product}>
        {Array.isArray(filteredSelectedDecors) &&
          filteredSelectedDecors.map((decor) => (
            <DecorChipComponent
              key={decor._id}
              decor={decor}
              handleClose={handleDecorChipComponent}
            />
          ))}
      </div>

      <div
        className={`${commonstyles.management_table} ${ownStyles.animatable_elements} ${hideElementClass}  table-responsive`}
      >
        <table className={`${ownStyles.table} table table-borderless`}>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Image</th>
              <th scope="col">Product Name</th>
              <th scope="col">Product Categories</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredDecors) && filteredDecors.length ? (
              filteredDecors.map((decor) => (
                <tr key={decor._id} className={ownStyles.table_row}>
                  {/* Check */}
                  <td>
                    <div
                      className={` ${commonstyles.custom_control} ${commonstyles.custom_checkbox} custom-control custom-checkbox text-center`}
                    >
                      {/* input id as the given value to make unique */}
                      <input
                        className={` ${commonstyles.custom_control_input} custom-control-input`}
                        type="checkbox"
                        onChange={(e) => updateSelection(e, decor)}
                        checked={selectedDecorIDs.includes(decor._id)}
                        id={`${props.formField}checkBox${decor._id}`}
                      />
                      <label
                        className={`${commonstyles.custom_control_label} custom-control-label cursor-pointer`}
                        htmlFor={`${props.formField}checkBox${decor._id}`}
                      ></label>
                    </div>
                  </td>
                  {/* Checkbox ends */}
                  {/* Image */}
                  <td>
                    {decor.image ? (
                      <img
                        src={decor.image}
                        className={commonstyles.admin_table_img}
                        alt="Decor image"
                      />
                    ) : null}
                  </td>
                  {/* Image ends */}
                  {/* Name */}
                  <td>{decor.title}</td>
                  {/* MRP */}
                  <td>{decor.categories}</td>
                </tr>
              ))
            ) : (
              <NoProductsTableRowComponent
                products={filteredDecors}
                filter={filter}
              />
            )}
          </tbody>
        </table>
      </div>
      <div
        className={`${commonstyles.table_footer} ${ownStyles.animatable_elements} ${hideElementClass}`}
      >
        <PaginateWrapperComponent
          nextPage={filter.nextpage}
          data={pagination}
          handleChange={handlePaginationChanges}
        />

        <div className={commonstyles.table_pages}>
          <button
            type="button"
            className={`${commonstyles.btn} btn mr-3`}
            onClick={() => setIsMinimal(true)}
          >
            Collapse
          </button>
          {/* <button
            type="button"
            onClick={() => setIsMinimal(true)}
            className={`${commonstyles.btn_primary} ${commonstyles.btn} btn`}
          >
            Save
          </button> */}
        </div>
      </div>
    </div>
  );
}
