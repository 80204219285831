import React from "react";
import { Link } from "react-router-dom";
import ModalComponent from "../../modules/ReusableComponent/ModalComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import NewPasswordComponent from "./NewPasswordComponent";
import style from "../../../styles/Login.module.css";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import banner from "../../../assets/images/modal_banner.png";
import mainLogo from "../../../assets/images/main_logo.png";
import Spinner from 'react-bootstrap/Spinner'
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { Router } from "react-router";
import { useHistory } from "react-router";
// import Alert from "react-bootstrap/Alert";
import "antd/dist/antd.css";
import { notification } from "antd";
import { Alert } from "antd";
import { useState } from "react";


const ForgotComponent = (props) => {
  const history = useHistory();
  let [user, setUser] = useState("");

  let [user_error, setUser_error] = useState("");

  const [error, setError] = useState({
    message: null,
    type: null,
  });

  const HandleChange = (e) => {
    let nowName = e.target.name;
    let nowValue = e.target.value;

    if (
      nowValue.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setUser_error("");
     } else if (
      nowValue.match(
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      )
    ) {
      setUser_error("");
      } else {
      setUser_error("Please enter valid email or contact");
      setClickable(false)
      }  
    setUser(nowValue);
    };
    const removeErrorMsg = () => {
      setError({
        message: null,
        type: null,
      });
    };
 let [ clickable , setClickable ] = useState(false);

 
   /**
   * @author Mithun Prabhu
   * @param {Event}
   * SEND OTP FOR PARTICULAR AUTHORIZED TOKEN
   */
  const sendotp = (e) => {
    e.preventDefault();
    if (user){
      if(user !== "" && user_error == ""){
      setClickable(true)
      // }
      // else{
      //   setClickable(false)
      // }
    let data = {
      user_name: user,
    };
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/forgetPassword", data)
      .then((res) => {
        if (res.data.status === 200) {
          setError({
            type: "success",
            message: res.data.msg,
            });
            setTimeout(() => {
              removeErrorMsg();
            }, 3000);
          setClickable(false)
          // console.log(res.data);
          localStorage.setItem("user_name", user);
          // history.push("/Newpassword");
          } else if (res.data.status === 400) {
            console.log(res.data.status,"status")
            setError({
              type: "error",
              message: res.data.msg,
           });
           setTimeout(() => {
            removeErrorMsg();
            }, 3000);
              // console.log(res.data.msg ,"res")
            setClickable(false)
         }
         setUser("")
         })
      .catch((er) => {
        setError({
            type: "error",
             message: "Failed",
             description:"Error occured ",
           });
           setTimeout(() => {
            removeErrorMsg();
           }, 3000);
          setClickable(false)
          setUser("")
      });
      
     }
    }
     else{
      setUser_error("Please enter valid email or contact");
      setClickable(false)
    }
  
  };

  
  const closeModal = ()=>{
    setUser("")
    setUser_error("")
    props.onCloseModal()
  }
  return (
    <ModalComponent
      data={{
        show: props.show,
        onHide: closeModal,
        className: "forgot_form_modal",
        size: "lg",
      }}
    > <div className={`modal${style.forgot_form_modal}`}>
    {/* <div className={`modal-header ${style.modal_header} `}> */}
      <button
        type="button"
        onClick= {closeModal}
        className={style.close}
        data-dismiss="modal"
        aria-label="Close"
      >
        <span className={style.close_btn} aria-hidden="true">&times;</span>
      </button>
    {/* </div> */}
    <div>
     <h4 className={style.modal_title}>Forgot Password? Don't worry :)</h4>
      </div>
    <form data-test="login_form" onSubmit={sendotp}>
            <div className={style.input_row}>
              <div className={style.sub_text}>Just Enter your registered Email ID</div>
              {error && error.message ? (
              <Alert
                className="alert_message"
                closable
                message={error.message}
                type={error.type}
                showIcon
              />
            ) : (
              ""
            )}
              <div
                className={`${style.input_field} ${style.admin_input_field}`}
              >
                <InputComponent
                  formControl={{
                    name: "email",
                    placeholder: "Enter Email ID",
                    type: "text",
                    "data-test": "email",
                    onChange: HandleChange,
                    value: user,
                  }}
                />
              </div>
              <span className={style.error} data-test="email_error">
                {user_error ? user_error : null}
              </span>
            </div>

            <ButtonComponent
              data={{
                type: "submit",
                "data-test": "sign_in_btn",
                // className: "login_btn login_btn_admin",
              }}
              className={style.forgot_btn}
              // onClick={sendotp}
            >
              {clickable ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"  
                  aria-hidden="true"
                  className="spinner_btn"
                  /> : null }
              SEND
            </ButtonComponent>
          </form>
    </div>
    {/* <div className={`container-fluid ${style.admin_login_container}`}>
      <div className="row">
        <div className={`col-12 col-md-6 ${style.admin_login_banner}`}>
          <img src={banner} alt="" />
        </div>
        <div className={`col-12 col-md-6 ${style.admin_login_content}`}>
          <h1 className={style.main_logo}>
            {" "}
            <a href="">
              {" "}
              <img src={mainLogo} alt="" />{" "}
            </a>{" "}
          </h1>
          <form data-test="login_form" onSubmit={sendotp}>
            <div>
              <h4 className={style.admin_forgot_heading}>Forgot Password</h4>
            </div>

            <div className={style.input_row}>
              <div
                className={`${style.input_field} ${style.admin_input_field}`}
              >
                <InputComponent
                  formControl={{
                    name: "email",
                    placeholder: "Email address or Phone number",
                    type: "text",
                    "data-test": "email",
                    onChange: HandleChange,
                    value: user,
                  }}
                />
              </div>
              <span className={style.error} data-test="email_error">
                {user_error ? user_error : null}
              </span>
            </div>

            <ButtonComponent
              data={{
                type: "submit",
                "data-test": "sign_in_btn",
                className: "login_btn login_btn_admin",
              }}
              // onClick={sendotp}
            >
              {clickable ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"  
                  aria-hidden="true"
                  className="spinner_btn"
                  /> : null }
              Send OTP
            </ButtonComponent>
          </form>
        </div>
      </div>
    </div> */}
    </ModalComponent>
  );
};

export default ForgotComponent;
