import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import AddDiscount from "../DiscountManagement/AddDiscount";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import EditOrderComponent from "../Order Management/OrderDetails";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import { Checkbox } from "antd";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  message as massages,
  Select,
  notification,
  Empty,
  Switch,
  Modal
} from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import excel from "../../../assets/images/excel.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { filter, upperCase } from "lodash";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import {Tabs} from "antd";
import CityManagement from "./CityManagement";
import Compressor from 'compressorjs'; 
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg"

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Warehouse / State", value: "name" },
  { _id: 3, status: 1, name: "Company ID", value: "companyId" },
  { _id: 5, status: 1, name: "GST", value: "GST" },
  { _id: 6, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 7, status: 1, name: "Action", value: "Action" },
];
const citiesHeader = [
  // { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "City Name", value: "name" },
  { _id: 3, status: 1, name: "State", value: "state" },
  { _id: 6, status: 1, name: "Enable / Disable", value: "enable" },
  
  { _id: 7, status: 1, name: "Action", value: "Action" },
  { _id: 8, status: 1, name: "Sort Order", value: "Sort Order" },
];

const filters = [
  // { id: 1, isChecked: false, name: "Warehouse", value: "region" },
  { id: 2, isChecked: false, name: "Date", value: "date" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  // { _id: 8, name: "This Week", value: 2 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
const bulkAction = [
  { _id: 1, isChecked: true, name: "Enabled", value: true },
  { _id: 2, isChecked: true, name: "Disabled", value: false },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function RegionManagement() {
  const { TabPane } = Tabs;
  const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const history = useHistory();
  const pdfExportComponent = useRef(null);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [visible, setVisible] = useState(false);
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Warehouse");
  const [categoryId, setCategoryId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filteredResult, setFilteredResult] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [startQuantity, setStartQuantity] = useState("");
  const [endQuantity, setEndQuantity] = useState("");
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [sortOrder, setSortorder] = useState();
  const [isDeleteModule, setIsDeleteModule] = useState(false);
  const [seletedWareHuse, setSeletedWareHuse] = useState("");
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [tabName, setTabName] = useState("Warehouse")
  const [activeTab, setActiveTab] = useState()
  const [open, setOpen] = useState(false);
  const [commonTitleData, setCommonTitleData] = useState({
    maintitle: "",
    mainsubtitle: "",
    type:"",
    offer:"",
    offertype:"",
    viewallurl: "",
    duration:5,
    bannerimages:[]
  });
  const [formErrors, setFormErrors] = useState({

    maintitle:"",
    mainsubtitle:"",
    type:"",
    offer:"",
    offertype:"",
    viewallurl:"",
    duration:"",
    bannerimages:[]
  });
  const originColumn = [

    {
      title: "Warehouse / State",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.name}/{record.state}</td>
      ),
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Company ID",
      visible: false,
      key: "companyId",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.companyId}</td>
      ),
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "GST",
      visible: false,
      key: "GST",
      render: (text, record, index) => (
        <td style={{ textTransform: "uppercase" }}>{record.gstin}</td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-edit") ===
        "SiteSettings-Regions-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit warehouse"
            >
              {/* <Link to={`/warehouse/edit/${record._id}`}> */}
              <img
                src={adminProductEdit}
                className={`${commonstyles.admin_table_edit_img} mr-2`}
                onClick={() => editRegion(record)}
                alt="Edit action icon"
                // title="Edit region"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            {/* </Link> */}
            <Tooltip
              placement="topRight"
              title="Delete warehouse"
            > <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deleteRegion(record)}
                alt="Delete action icon"
                // title="Delete region"
                style={{ cursor: "pointer" }}
              /></Tooltip>

          </td>
        ),
    },
  ]
  const citiesColumn = [

    {
      title: "City Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.name}/{record.state}</td>
      ),
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "State",
      visible: false,
      key: "state",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.state}</td>
      ),
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Sort Order",
      visible: true,
      key: "Sort Order",
      render: (text, record, index) => (
        <td>{record?.sortValue}</td>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-edit") ===
        "SiteSettings-Regions-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit warehouse"
            >
              {/* <Link to={`/warehouse/edit/${record._id}`}> */}
              <img
                src={adminProductEdit}
                className={`${commonstyles.admin_table_edit_img} mr-2`}
                onClick={() => editRegion(record)}
                alt="Edit action icon"
                // title="Edit region"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            {/* </Link> */}
            <Tooltip
              placement="topRight"
              title="Delete warehouse"
            > <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deleteRegion(record)}
                alt="Delete action icon"
                // title="Delete region"
                style={{ cursor: "pointer" }}
              /></Tooltip>

          </td>
        ),
    },
  ]
  const [columns, setColumns] = useState(originColumn);
  const [sortActive, setSortActive] = useState(false);
  const [saveDragAndDrop, setSaveDragAndDrop] = useState(false)


  const [selectedOrder, setSelectedOrder] = useState({});


  useEffect(()=>{
    getCityList()
  },[])
  // modal
  const validateForm = () => {
    let isValid = true;
    const errors = {
      maintitle: "",
      mainsubtitle: "",
      type:"",
      offer:"",
      offertype:"",
      viewallurl: "",
      duration:"",
      bannerimages:[]
    };

    // Check main title
    if (!commonTitleData.maintitle || commonTitleData.maintitle.trim() === "") {
      errors.maintitle = "Main title is required";
      isValid = false;
    }

    // Check main subtitle
    if (!commonTitleData.mainsubtitle || commonTitleData.mainsubtitle.trim() === "") {
      errors.mainsubtitle = "Main subtitle is required";
      isValid = false;
    }
    // Check type
    if (!commonTitleData.type || commonTitleData.type.trim() === "") {
      errors.type = "Type(green color box) is required";
      isValid = false;
    } 
    // offer
    if (!commonTitleData.offer || commonTitleData.offer.trim() === "") {
      errors.offer = "Offer is required";
      isValid = false;
    } 
    // offertype
    if (!commonTitleData.offertype || commonTitleData.offertype.trim() === "") {
      errors.offertype = "Offer Type is required";
      isValid = false;
    } 
    // Check view all URL
    if (!commonTitleData.viewallurl || commonTitleData.viewallurl.trim() === "") {
      errors.viewallurl = "View all URL is required";
      isValid = false;
    } 
    console.log("commonTitleData.bannerimages.length",commonTitleData.bannerimages.length);
    
    if (!commonTitleData.bannerimages.length > 0) {
      errors.bannerimages = "Banner Image is required";
      isValid = false;
    } 


        // Check duration
      //   if (!commonTitleData.duration) {
      //     errors.duration = "Duration is required";
      //     isValid = false;
      // } else if (isNaN(commonTitleData.duration)) {
      //     errors.duration = "Duration must be a number";
      //     isValid = false;
      // } else if (commonTitleData.duration <= 0) {
      //     errors.duration = "Duration must be greater than 0";
      //     isValid = false;
      // }
      // feature use
  commonTitleData.duration=5
    setFormErrors(errors);
    return isValid;
  };
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    
    // Check if we already have an image
    if (commonTitleData?.bannerimages?.length >= 1) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Only one image allowed. Please remove existing image first.",
      });
      return;
    }

    // Take only the first file if multiple are selected
    const file = files[0];
    if (!file) return;

    const fileName = file.name.toLowerCase();
    
    // Validate file type
    if (!fileName.match(/\.(jpg|jpeg|png)$/)) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload only jpg, jpeg, and png format images",
      });
      return;
    }
    
    // Validate file size (3MB limit)
    if (file.size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please upload file less than 3MB size",
      });
      return;
    }

    // Compress and create preview
    new Compressor(file, {
      quality: 0.6,
      success: (compressedResult) => {
        const imageUrl = URL.createObjectURL(compressedResult);
        
        setCommonTitleData(prevForm => ({
          ...prevForm,
          bannerimages: [
            {
              key: `image-${Date.now()}-${0}`,
              url: imageUrl,
              order: 0,
              file: compressedResult
            }
          ]
        }));
console.log("bannerimages",commonTitleData);

        // Clear the input value to allow uploading the same file again after removal
        // event.target.value = '';
      },
      error: (err) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error processing image",
        });
        console.error(err);
      }
    });
  };
  const removeImage = (indexToRemove) => {
    setCommonTitleData(prevForm => {
      // Revoke object URL to prevent memory leaks
      if (prevForm.bannerimages[indexToRemove]?.url) {
        URL.revokeObjectURL(prevForm.bannerimages[indexToRemove].url);
      }
      
      return {
        ...prevForm,
        bannerimages: []
      };
    });
  };
  const handleFormInput = (e) => {
    const { name, value } = e.target;
    
    // Special handling for duration field
    if (name === 'duration') {
      // Convert string input to number and ensure it's within 2 digits
      const numericValue = value === '' ? '' : Number(value);
      if (!isNaN(numericValue) && String(numericValue).length <= 2) {
        setCommonTitleData(prev => ({
          ...prev,
          [name]: numericValue
        }));
      }
    } else {
      // Handle other fields normally
      setCommonTitleData(prev => ({
        ...prev,
        [name]: value
      }));
    }

    // Clear error when user types
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };
  function showModal(){
    setOpen(true);
  
   
  };
  function getCityList(){
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/cities?pagesize=10&nextpage=1`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
       console.log("res.data",res.data);
    
    setCommonTitleData({
      maintitle: res.data.data[0].maintitle,
      mainsubtitle: res.data.data[0].mainsubtitle,
      type: res.data.data[0].type,
      duration: res.data.data[0].duration/1000,
      bannerimages:res.data.data[0].bannerimages,
      viewallurl: res.data.data[0].viewallurl,
      offer: res.data.data[0].offer,
      offertype:res.data.data[0].offertype,
  });
console.log("recieve response",res.data.data[0]);

    // Set the extracted data using setCommonTitleData
 
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  console.log("common--->",commonTitleData);
  const handleOk = () => {
    if(!validateForm()){
      showNotification({
        type: "error",
        message: "Validation Error",
        description: "Please fill in all required fields correctly",
      });
      return;
    }
    const commontitle={
      maintitle:  commonTitleData?.maintitle,
      mainsubtitle:commonTitleData?.mainsubtitle,
      type:commonTitleData?.type,
      offer:commonTitleData?.offer,
      offertype:commonTitleData?.offertype,
      bannerimages:commonTitleData?.bannerimages,
      viewallurl: commonTitleData?.viewallurl,
      duration:commonTitleData?.duration*1000,
    }
       // Create FormData
       const formData = new FormData();
       formData.append('maintitle', commonTitleData.maintitle);
       formData.append('mainsubtitle', commonTitleData.mainsubtitle);
       formData.append('type', commonTitleData.type);
       formData.append('offer', commonTitleData.offer);
       formData.append('offertype', commonTitleData.offertype);
       formData.append('viewallurl', commonTitleData.viewallurl);
       formData.append('duration', commonTitleData.duration*1000);
   
       // Append image
       if (commonTitleData.bannerimages && commonTitleData.bannerimages.length >0 ) {
        console.log("commonTitleData.bannerimages",commonTitleData.bannerimages);
         const imageFile = commonTitleData.bannerimages[0].file != undefined ? commonTitleData.bannerimages[0].file:JSON.stringify(commonTitleData.bannerimages[0]); 
         formData.append('images', imageFile);
       }
     
       console.log("commonTitleData.bannerimages",commonTitleData.bannerimages);
       for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

     
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
    .post(`/cities`, formData,

      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .then((res) => {
      if (res.data.status === 200) {
        showNotification({
          type: "success",
          message: "Success",
          description: "Cities Title & Content Added Successfully",
        });
        // setIsUpdating(true);
        // setIsLoading(false);
        // history.push(`/Content/testimonial`);
        // setIsUpdating(false);
        getCityList()
      } else if (res.data.status === 400) {
        showNotification({
          type: "error",
          message: "Adding Cities Common Section Failed",
          description: res.data?.msg || "Error occured",
        });
      } else {
        // setIsUpdating(false);
      }
    })
    .catch((er) => {
      return er;
    })
    .finally(() => {
      // setIsLoading(false);
      // setIsUpdating(false);
    });

    setOpen(false);
  };

  const handleCancel = () => {
    const { maintitle, mainsubtitle, viewallurl,duration,type,offer,offertype,bannerimages } = commonTitleData;

    // Validation logic
    if (!maintitle || !mainsubtitle || !viewallurl|| !type ||!offer ||!bannerimages ||!offertype) {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please Fill All Required Fields Before Closing Title & Content",
      });
     
      return; // Prevent modal close
    }
  
    // If all fields are filled, close the modal
    setOpen(false);
  };

  // modal
  function handleTableChange(pagination, filters, sorter, extra) {

    let sortValue = sorter.order === "ascend" ? 0 : sorter.order === "descend" ? 1 : sorter.order == undefined && null;

    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortValue,
    };

    getWareHouseList(pageSize, 1, updatedFilter, true);
  }

  function showDeleteConfirmation(order) {
    setSelectedOrder(order);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  }

  // const [selectedOrder, setSelectedOrder] = useState([]);
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedOrder");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("orderList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedOrder")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedOrder"));
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem("selectedOrder", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("orderList", JSON.stringify(cloneOrderList));
    setOrderList(cloneOrderList);
  };

  // *** ADD ZONE *** //
  function addRegion() {
    if(tabName == "Warehouse"){
    history.push(`/site-settings/region/add`);
    }else{
      history.push(`/site-settings/city/add`);
    }
  }

  useEffect(() => {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: null,
    };
    if(history?.location?.state?.tab){
      setTabName("Cities")
      setHeaders(citiesHeader)
      setItems(citiesHeader)
      setActiveTab("2")
      setIsLoading(false)
    }else{  
      setActiveTab("1")
      set_new_header(originalHeaders)
    getWareHouseList(pageSize, nextPage, updatedFilter);
    }
    
    getRegionList();
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Warehouse')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Warehouse", value: "region" })
      }
    };
  }, [activeTab, tabName]);

  


  /**
   * @author Mithun Prabhu
   * @param {event, record}
   * To toggle between different status enable/disable
   */
  const toggleStatusOnChange = (e, record) => {
    if (localStorage.getItem("orderList")) {
      let orderListListClone = JSON.parse(localStorage.getItem("orderList"));
      orderListListClone.forEach((item) => {
        if (record._id === item._id) {
          item.enable = !item.enable;
          sentStatus(item);
        }
      });
      localStorage.setItem("orderList", JSON.stringify(orderListListClone));
      setOrderList(orderListListClone);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {showRoom}
   * To toggle between different status enable/disable call API
   */
  const sentStatus = (showRoom) => {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(
        `/admin/region/updatestatusRegion/${showRoom._id}/${showRoom.enable}`
      )
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
        }
      })
      .catch((er) => {
        return er;
      });
  };

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getWareHouseList(pageSize, nextPage, filterarr,type) {
    if(!type){
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filterDays);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    // if (filterarr && filterarr.sortOrder) {
    //   filterOptions.sortOrder = Number(filterarr.sortOrder);
    // } else if (sortOrder !== "") {
    //   if (filterarr && filterarr.sortOrder !== "") {
    //     filterOptions.sortOrder = Number(sortOrder);
    //   }
    // }

    if (filterarr.sortOrder !== null) {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else {
      delete filterOptions.sortOrder;
    }

setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/admin/region/regionList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("orderList", JSON.stringify(res.data.data));
            setOrderList(res.data.data);
          } else {
            setOrderList([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = {};
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getWareHouseList(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getWareHouseList(pageSize, value, filter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** EDIT REGION FUNCTION *** //
  function editRegion(region) {
    history.push({
      pathname: "/site-settings/region/add",
      state: region,
    });
  }

  // *** DELETE REGION FUNCTION *** //
  function deleteRegion(region) {
    setSeletedWareHuse(region._id);
    setIsDeleteModule(true);
  }

  function handleInput(e) {

    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getWareHouseList(pageSize, 1, filter,true);
    setFilteredResult(filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Warehouse", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
    };
    debounceSearch(updatedFilter);
  };

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    console.log("columns",columns);
    
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );


  /**
 * @author Mithun Prabhu
 * @param {e, data}
 * This is common filter handler for all filters
 */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // paymentMethod: paymentMethod,
      // orderStatus: orderStatus,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: sortOrder,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Warehouse");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }

        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
 * @author Mithun Prabhu
 * @param {e, data}
 * Export Warehouse here
 */
  const exportToCSV = async (e) => {

    // const filterOptions = {};

    // /* Search export filter */
    // if (filteredResult && filteredResult.search) {
    //   filterOptions.search = filteredResult.search;
    // } else if (search !== "") {
    //   if (filteredResult && filteredResult.search !== "") {
    //     filterOptions.search = search;
    //   }
    // }
    // /* Warehouse export filter */
    // if (filteredResult && filteredResult.regionId) {
    //   filterOptions.regionId = filteredResult.regionId;
    // } else if (regionId !== "") {
    //   if (filteredResult && filteredResult.regionId !== "") {
    //     filterOptions.regionId = regionId;
    //   }
    // }
    // if (filteredResult && filteredResult.filterDays) {
    //   filterOptions.filterDays = Number(filteredResult.filterDays);
    // } else if (filteredDate.filterDays !== "") {
    //   if (filteredResult && filteredResult.filterDays !== "") {
    //     filterOptions.filterDays = Number(filteredDate.filterDays);
    //   }
    // }
    // /* Start date export filter */
    // if (filteredResult && filteredResult.startDate) {
    //   filterOptions.startDate = filteredResult.startDate;
    // } else if (filteredDate.startDate !== "") {
    //   if (filteredResult && filteredResult.startDate !== "") {
    //     filterOptions.startDate = filteredDate.startDate;
    //   }
    // }
    // /* End date export filter */
    // if (filteredResult && filteredResult.endDate) {
    //   filterOptions.endDate = filteredResult.endDate;
    // } else if (filteredDate.endDate !== "") {
    //   if (filteredResult && filteredResult.endDate !== "") {
    //     filterOptions.endDate = filteredDate.endDate;
    //   }
    // }
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post(`/admin/region/exportRegionList`, params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".csv";
          const ws = XLSX.utils.json_to_sheet(und_res);

          /* upper case of header */
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");

          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Warehouse-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.csv`
          );
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      // window.open(`Warehouse Report for ${new Date().getFullYear()}`)
    }
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,

      filterDays: filterDays,

    };
    debounceSearch(updatedFilter);
  }

  function cancelDelete() {
    setSeletedWareHuse("");
    setIsDeleteModule(false);
  }

  function confirmDelete() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder: null,
    };
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/admin/region/delete/${seletedWareHuse}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: "Warehouse details deleted successfully",
          });
          getWareHouseList(pageSize, nextPage, updatedFilter);
        } else {
          cancelDelete();
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to delete warehouse",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  function callback(key) {
    if(history?.location?.state?.tab){
      history.location.state.tab =  null
    }
    setSortActive(false)
    if(key == 1){
      setTabName("Warehouse")
      setHeaders(originalHeaders)
      setItems(originalHeaders)
      setActiveTab("1")
    }else{
      setTabName("Cities")
      setHeaders(citiesHeader)
      setItems(citiesHeader)
      setActiveTab("2")
    }
  }
  const sortActiveChange = () =>{
    setSortActive(!sortActive);
    setSaveDragAndDrop(false);
    }
    const dragAndDropSave = () =>{
      setSaveDragAndDrop(true);
      setSortActive(false)
    }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      
      
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/site-settings")}
          />
          {tabName}
        </h1>
        {tabName &&  tabName !== "Cities"  && <div>
          <Tooltip
            placement="topRight"
            title="Download pdf"
          >
            <img
              className={commonstyles.export_icon}
              src={pdf_icon}
              style={{ cursor: "pointer" }}
              onClick={(e) => exportToPDF(e)}
            />
          </Tooltip>
          <span
            className={commonstyles.management_header_downloads_text}
            style={{ cursor: "pointer" }}
            onClick={(e) => exportToCSV(e)}
          >
            <Tooltip
              placement="topRight"
              title="Download excel"
            >
              <img className={commonstyles.export_icon} src={excel} />
            </Tooltip>
            <span className={commonstyles.management_header_downloads_text_ex}>
                    Export <img src={exportIcon} alt="icon" />
                  </span>
          </span>
        </div>}
       
      </div>
      <div className={commonstyles.management_header}>
        {/* <div class={commonstyles.management_contents_upload}> */}
          {permissionsArr.length > 0 &&
            permissionsArr.find((x) => x === "SiteSettings-Regions-add") ===
            "SiteSettings-Regions-add" && (<>
              <ButtonComponent
                className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
                attributes={{
                  onClick: addRegion,
                }}
              >
                Add {tabName}
              </ButtonComponent>
              {
              tabName &&  tabName === "Cities" &&
              <ButtonComponent
              className="rnd mr-3 outline_cancel_btn"
              attributes={{
                onClick:showModal
                // onClick: () => history.push(`/Content/testimonial`),
              }}
            >
          Title & Content          
            </ButtonComponent>
            }
              {tabName &&  tabName === "Cities"  &&<div>
              <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              attributes={{
                onClick: sortActiveChange
              }}
            >
              {/* <img src={plusIcon} className="mr-2" /> */}
              {!sortActive ? "Sort" : "Cancel"}
            </ButtonComponent>
            {sortActive &&  <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              
              attributes={{
               style: {backgroundColor:"#A39486", color:"#fff"},
                onClick: dragAndDropSave
              }}
            >
             Save
            </ButtonComponent>}
            </div>}
           
           
            
            </>
            )}
           
        {/* </div> */}
      </div>
      {activeTab &&
         <div className="product-tabsbox">
         <Tabs onChange={callback} defaultActiveKey={activeTab} type="card">
         <TabPane tab="Warehouse" key="1">
         {permissionsArr.length > 0 &&
           permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
           "SiteSettings-Regions-view" && (
             <div
               className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
             >
               <div
                 className={`${commonstyles.management_contents_search_bar} input-group`}
               >
                 <InputComponent
                   className="with-icon rnd theme-txt-xsm"
                   formControl={{
                     placeholder: "Search by Warehouse/State, Company ID...",
                     name: "search",
                     style: { height: "38px" },
                     value: search,
                     onChange: handleInput,
                     ["aria-label"]: "Search category",
                     ["aria-describedby"]: "search-category",
                   }}
                 />
                 <img src={Search} alt="" />
               </div>
               <ButtonComponent
                 className="theme-btn rnd pl-3 pr-4 ml-2"
                 attributes={{
                   onClick: () => {
                     searchClick();
                   },
                 }}
               >
                 Search
               </ButtonComponent>
               {/*<div className="action ml-3">
                           <div className="action_header">
                               Action
                               <img src={arrowDown} alt="image" />
                           </div>
                           <div className="action_contents">
                              {bulkActionFilter.length > 0 &&
                                   bulkActionFilter.map((item) => (  
                               <div
                                   className="action_contents_header"
                                   key={item._id}
                                   // onClick={() => bulkStatusClickHandler(item)}
                               >
                               <h2 className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"}`}>
                                   {item.name}
                               </h2>
                               {item.isChecked && (        
                                <span
                                   className="action_contents_btn"                
                                   // onClick={() => applyBulkActionHandler(item)}                
                                 >               
                                 Apply
                               </span>
                               )}
                               </div>
                           ))}
                           </div>
                       </div> */}
               <Dropdown
                 overlay={filterMenu}
                 onVisibleChange={handleVisibleChange} visible={visible}
                 className="usermanage_table_filter_columns ml-3"
               >
                 <div
                   className="ant-dropdown-link"
                   onClick={(e) => e.preventDefault()}
                 >
                   Filter <img alt="" src={arrowDown} width={10} height={20} />
                 </div>
               </Dropdown>
               <Dropdown
                 overlay={menu}
                 className="usermanage_table_filter_columns ml-3"
               >
                 <div
                   className="ant-dropdown-link"
                   onClick={(e) => e.preventDefault()}
                 >
                   Columns <img alt="" src={arrowDown} width={10} height={20} />
                 </div>
               </Dropdown>
             </div>
           )}
         {permissionsArr.length > 0 &&
           permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
           "SiteSettings-Regions-view" && (
             <PDFExport
               ref={pdfExportComponent}
               paperSize="auto"
               margin={40}
               fileName={`Warehouse Report for ${new Date().getFullYear()}`}
               author="Damro Team"
             >
               <div
                 className={`${commonstyles.management_table} table-responsive`}
               >
                 {/* {!isLoading && orderList.length > 0 && (
                       <input
                           type="checkbox"
                           className={`${commonstyles.parentCheckbox}`}
                       ></input>
                   )} */}
                 {isLoading === true ? (
                   <div
                     style={{
                       marginLeft: "70vh",
                       marginTop: "10vh",
                     }}
                   >
                     <tr className="bg-transparent">
                       <td colSpan="100%">
                         <LoadingIndicator loadingText="Fetching warehouse details..." />
                       </td>
                     </tr>
                   </div>
                 ) : (
                   <AntTableComponent
                     className="order_table"
                     tableProps={{
                       columns: columns,
                       dataSource: orderList,
                       pagination: false,
                       // onChange: handleTableChange,
                       onChange: handleTableChange,
                       components: {
                         header: {
                           row: (props) => renderHeader(props),
                         },
                         body: {
                           row: (props) => renderBody(props),
                         },
                       },
                     }}
                   />
                 )}
               </div>
             </PDFExport>
           )}
         {!isLoading && Array.isArray(orderList) && orderList.length
           ? permissionsArr.length > 0 &&
           permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
           "SiteSettings-Regions-view" && (
             <Pagination
               pageSizes={pageSizes}
               pageSize={pageSize}
               nextPage={nextPage}
               data={pagination}
               handlePaginationChanges={handlePaginationChanges}
             />
           )
           : null}
           </TabPane>
         
           <TabPane tab="Cities" key="2"> 
           <CityManagement sortActive={sortActive} saveDragAndDrop={saveDragAndDrop} activeTab={activeTab} ></CityManagement>
           </TabPane>
           
           </Tabs>
         </div>
      }
      
      <ConfirmationBox
        modal={{
          show: isDeleteModule,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>

      <Modal
      title="Title & Content"
      open={open}
      bodyStyle={{
        padding:"0px",
         borderRadius: '10px'
      }}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <ButtonComponent
        className="rnd mr-3 outline_cancel_btn"
        attributes={{
          onClick:handleCancel
        }}
      >
       Cancel
      </ButtonComponent>,

         <ButtonComponent
         className="rnd mr-3 outline_cancel_btn"
         attributes={{
           onClick:handleOk
         }}
       >
        Save
       </ButtonComponent>
        // <Button key="cancel" onClick={handleCancel}>
        //   Cancel
        // </Button>,
        // <Button key="save" type="primary" onClick={handleOk}>
        //   Save
        // </Button>,
      ]}
      closable={commonTitleData.maintitle?.trim() !== "" &&    
        commonTitleData.mainsubtitle?.trim() !== "" &&
            commonTitleData.viewallurl?.trim() !== "" &&
                // commonTitleData.duration !== "" &&
              commonTitleData?.offer?.trim() !==""
              
              } // Prevent closing the modal when clicking outside
        maskClosable={false}
    >
         <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                    Main Title
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.maintitle}
                    formControl={{
                      placeholder: "",
                      name: "maintitle",
                      value: commonTitleData?.maintitle,
                      onChange: (e) => {
                        // if (!minChar.test(e.target.value)) {
                        //   if (!formErrors.maintitle) {
                        //     formErrors.maintitle = {};
                        //   }
                        //   formErrors.maintitle = "Enter minimum 3 characters";
                        // } else {
                        //   if (formErrors.maintitle) {
                        //     formErrors.maintitle = "";
                        //   }
                        // }
                        handleFormInput(e);
                      },
                    }}
                  />
                  <FormErrorText
                    error={formErrors.maintitle}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                    Main SubTitle
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.mainsubtitle}
                    formControl={{
                      placeholder: "",
                      name: "mainsubtitle",
                      value: commonTitleData?.mainsubtitle,
                      onChange: (e) => {
                        // if (!minChar.test(e.target.value)) {
                        //   if (!formErrors.mainsubtitle) {
                        //     formErrors.mainsubtitle = {};
                        //   }
                        //   formErrors.mainsubtitle = "Enter minimum 3 characters";
                        // } else {
                        //   if (formErrors.mainsubtitle) {
                        //     formErrors.mainsubtitle = "";
                        //   }
                        // }
                        handleFormInput(e);
                      },
                    }}
                  />
                  <FormErrorText
                    error={formErrors.mainsubtitle}
                  />
                </div>
              </div>
              {/* green color box */}
              <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                  Type(Green color Box)
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.type}
                    formControl={{
                      placeholder: "",
                      name: "type",
                      value: commonTitleData?.type ,
                      onChange:(e)=>{
                        handleFormInput(e);
                      }
                
                     
                    }}
                  />
                  <FormErrorText
                    error={formErrors.type}
                  />
                </div>
              </div>
              {/* offer value */}
              <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                    Offer 
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.offer}
                    formControl={{
                      placeholder: "",
                      name: "offer",
                      value: commonTitleData?.offer ,
                      onChange:(e)=>{
                        handleFormInput(e);
                      }
                
                     
                    }}
                  />
                  <FormErrorText
                    error={formErrors.offer}
                  />
                </div>
              </div>
              {/* offer type */}
              <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                    Offer Type(Eg:GET,SALE)
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.offertype}
                    formControl={{
                      placeholder: "Type Offer Type",
                      name: "offertype",
                      value: commonTitleData?.offertype ,
                      onChange:(e)=>{
                        handleFormInput(e);
                      }
                
                     
                    }}
                  />
                  <FormErrorText
                    error={formErrors.offertype}
                  />
                </div>
              </div>
              {/* banner image */}
              <div className="col-lg-12 mb-4">
              <div className={commonstyles.management_details_form}>
            <label >
             Store Banner Image 
              <span
                className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="form-control"
              disabled={commonTitleData.bannerimages && commonTitleData.bannerimages.length > 0}
            />
              {commonTitleData.bannerimages && commonTitleData.bannerimages.length > 0 ? (
        <small className="text-muted">
            Please remove existing image to upload new ones
        </small>
    )
    :(
      <small className="text-muted">
      Please upload file less than 3MB size
  </small>
    )
    }
            {/* <FormErrorText error={currentForm.errors.images} /> */}
            </div>
          </div>

          <div className="col-lg-12 mb-4">
          <div className={commonstyles.management_details_form}>
            <label className={commonstyles.secondry_title}>Preview Images</label>
            <div className={commonstyles.banner_img_box_outline}>
              {commonTitleData?.bannerimages?.map((img, index) => (
                <div key={img.key} className={commonstyles.banner_img_preview}>
                  <img
                    src={img.url}
                    alt={`preview-${index}`}
                    className={commonstyles.previewImgs}
                  />
                  <img
                    src={bannerimgdelete}
                    alt="delete"
                    className={commonstyles.previewImgsdeleteicon}
                    onClick={() => removeImage(index)}
                  />
                  {/* <div className={styles.imgpreview_index}>{index + 1}</div> */}
                </div>
              ))}
            </div>
            </div>
          </div>
              <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                    View All Url
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.viewallurl}
                    formControl={{
                      placeholder: "",
                      name: "viewallurl",
                      value: commonTitleData?.viewallurl ,
                      onChange:(e)=>{
                        handleFormInput(e);
                      }
                
                     
                    }}
                  />
                  <FormErrorText
                    error={formErrors.viewallurl}
                  />
                </div>
              </div>
              {/* duration */}
              {/* <div className="col-lg-12 mb-4">
                <div className={commonstyles.management_details_form}>
                  <label for="">
                  Duration (in Seconds)
                    <span
                      className="text-danger"
                      style={{ fontSize:"20px",
                        // bottom:-8,
                        position:"relative" }}
                    >
                      *
                    </span>
                  </label>
                  <InputComponent
                    className={`form-control form-control-lg ${commonstyles.form_control}`}
                    error={formErrors.duration}
                    formControl={{
                      placeholder: "",
                      name: "duration",
                      value: commonTitleData?.duration >= 1000 ? commonTitleData.duration / 1000 : commonTitleData.duration || '', 
                      onChange:(e)=>{ 
                        const value = e.target.value; // Restrict input to only 2 characters
                        if (value.length > 2) {
                          e.target.value = value.slice(0, 2); // Trim the input to 2 characters if exceeded
                        }

                        // Validate numeric input
                        if (!/^\d*$/.test(value)) {  // Checks if the value contains only digits
                          if (!formErrors.duration) {
                            formErrors.duration = {};
                          }
                          formErrors.duration = "Enter only numbers";  // Set error if non-numeric input
                        } else {
                          if (formErrors.duration) {
                            formErrors.duration = "";  // Clear error if the input is valid
                          }
                        }
                        handleFormInput(e);
                      }
                
                     
                    }}
                  />
                  <FormErrorText
                    error={formErrors.duration}
                  />
                </div>
              </div> */}
    </Modal>
    </div>
  );
}
