import styles from "../../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../../assets/images/Download_icon.svg";
import adminProductDelete from "../../../../assets/images/admin_delete.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../../assets/images/excel_icon.svg";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useState, useRef } from "react";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
} from "antd";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import EditCustomerComponent from "../../CustomerManagement/EditCustomerComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import DefaultImage from "../../../../assets/images/profile_dummy.svg";
import { Link, useParams } from "react-router-dom";
import Plus from "../../../../assets/images/plus_white_icon.svg";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  // { _id: 2, status: 1, name: "Avatar", value: "avatar" },
  { _id: 2, status: 1, name: "Order ID", value: "orderId" },
  { _id: 3, status: 1, name: "Invoice", value: "Invoice" },
  { _id: 4, status: 1, name: "Shipments", value: "Shipments" },
  { _id: 5, status: 1, name: "Transaction", value: "Transaction" },
  { _id: 6, status: 1, name: "Credit memo", value: "CreditMemo" },
  { _id: 7, status: 1, name: "Status", value: "Status" },
  { _id: 8, status: 1, name: "Action", value: "action" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "Region" },
  { id: 2, isChecked: false, name: "Date", value: "Date" },
  { id: 3, isChecked: false, name: "Status", value: "Rating" },
];
const bulkAction = [
  { _id: 1, isChecked: true, name: "Download Invoice", value: 1 },
];
const ratingObj = [
  { _id: 3, name: "Cancelled", value: 3 },
  { _id: 2, name: "Completed", value: 2 },
  { _id: 1, name: "Processing", value: 1 },
];
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function DocumentManagement() {
  const accessregion = localStorage.getItem('accessregion');
  const pdfExportComponent = useRef(null);
  const [visible, setVisible] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [filterDays, setFilterDays] = useState("");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [regions, setRegions] = useState([]);
  const [rating, setRating] = useState("");
  const [ratingName, setRatingName] = useState("All");
  const [ratingArr, setRatingArr] = useState(ratingObj);
  // const [isLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);

  const [sortorder, setSortorder] = useState();
  const [invoiceOrder, setSortInvoice] = useState();
  const [shipmentsOrder, setSortShipment] = useState();
  const [transactionOrder, setSortTransaction] = useState();
  const [creditMemoOrder, setSortMemo] = useState();

  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [isParentCheckbox, setIsParentCheckbox] = useState(false);
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Order ID",
      visible: false,
      key: "orderId",
      sorter: true,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.orderId}</span>
      ),
    },
    {
      title: "Invoice",
      visible: false,
      key: "Invoice",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>
          {record?.invoiceId ? record.invoiceId : "-"}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Shipments",
      visible: false,
      key: "Shipments",
      sorter: true,
      render: (text, record, index) => <span>{record.shipments}</span>,
    },
    {
      title: "Transaction",
      visible: false,
      key: "Transaction",
      sorter: true,
      render: (text, record, index) => <span>{record.transactionId}</span>,
    },
    {
      title: "Credit memo",
      visible: false,
      key: "CreditMemo",
      sorter: true,
      render: (text, record, index) => (
        <span>{record?.creditMemo ? record.creditMemo : "-"}</span>
      ),
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <span
          className={
            record.status === "completed"
              ? commonstyles.status_active
              : record.status === "canceled"
                ? commonstyles.status_inactive
                : commonstyles.status_pending
          }
        >
          {upperFirst(record.status)}
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) => (
        <span>
          {/* <Link to={`/customerDetails/${record._id}`}> */}
          <img
            src={adminProductEdit}
            className={`${commonstyles.admin_table_edit_img} mr-2`}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_BASE_URL_PRD}:${PORTS.INVOICE_DOWNLOAD}/api/v1/orders/invoice/${record._id}/1`,
                "_self"
              );
            }}
            alt="Download action icon"
            title="Download"
            style={{ cursor: "pointer" }}
          />
          {/* </Link> */}
        </span>
      ),
    },
  ]);
  /**
   * @author Rajkumar
   * @param {event,record}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
    }
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked =
          checked && cloneSelectedOrder?.length < 5 ? true : false;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      if (checked) {
        if (cloneSelectedOrder?.length < 5) {
          cloneSelectedOrder.push(record._id);
        } else {
          showNotification({
            type: "warning",
            message: "Warning",
            description: "Please select maximum five orders",
          });
        }
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };
  /**
   * @author Rajkumar
   * @param {event}
   * To get state of checked and update each records directly with isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedCustomerr");
    const { checked } = e.target;
    setIsParentCheckbox(checked);
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    cloneOrderList.forEach((item, indx) => {
      if (indx < 5) {
        item.isChecked = checked;
      } else {
        item.isChecked = false;
      }
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneOrderList.forEach((item, indx) => {
          if (indx < 5) {
            cloneSelectedOrder.push(item._id);
          }
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item, indx) => {
        if (indx < 5) {
          cloneSelectedOrder.push(item._id);
        }
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getCustomers(pageSize, nextPage, "");
    getRegionList();
    // getRegionList();
    localStorage.removeItem("selectedCustomerr");
    setIsParentCheckbox(false);
    return () => {
      localStorage.removeItem("customerList");
      localStorage.removeItem("selectedCustomerr");
      setIsParentCheckbox(false);
      filters?.forEach(item => {
        item.isChecked = false;
      })
      setSelectFilter(filters);
    };
  }, []);
  /**
   * @author Rajkumar
   * To get region list
   */
  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if (res?.data?.daaata?.length > 1) {
          let obj = { name: "All Region", value: "", _id: "" };
            res.data.daaata.forEach((item) => {
              console.log(item);
              item.name = upperFirst(item?.name);
            });
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            if (!accessregion) {
              let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
              if (!checkDuplicates) {
                filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
              }
            };
          }
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getCustomers(pageSize, nextPage, searchNow, filterarr, type) {
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search: searchNow,
    };
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.rating) {
      filterOptions.status = Number(filterarr.rating);
    } else if (rating !== "") {
      if (filterarr && filterarr.rating !== "") {
        filterOptions.status = Number(rating);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && sortorder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (filterarr && filterarr.transactionOrder !== "") {
      filterOptions.transactionOrder = Number(filterarr.transactionOrder);
    } else if (filteredDate.transactionOrder !== "") {
      if (filterarr && transactionOrder !== "") {
        filterOptions.transactionOrder = Number(transactionOrder);
      }
    }
    if (filterarr && filterarr.shipmentsOrder !== "") {
      filterOptions.shipmentsOrder = Number(filterarr.shipmentsOrder);
    } else if (filteredDate.shipmentsOrder !== "") {
      if (filterarr && shipmentsOrder !== "") {
        filterOptions.shipmentsOrder = Number(shipmentsOrder);
      }
    }
    if (filterarr && filterarr.invoiceOrder !== "") {
      filterOptions.invoiceOrder = Number(filterarr.invoiceOrder);
    } else if (filteredDate.invoiceOrder !== "") {
      if (filterarr && invoiceOrder !== "") {
        filterOptions.invoiceOrder = Number(invoiceOrder);
      }
    }
    if (filterarr && filterarr.creditMemoOrder !== "") {
      filterOptions.creditMemoOrder = Number(filterarr.creditMemoOrder);
    } else if (filteredDate.creditMemoOrder !== "") {
      if (filterarr && creditMemoOrder !== "") {
        filterOptions.creditMemoOrder = Number(creditMemoOrder);
      }
    }
    if (filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    if (!type) {
      setIsLoading({ delete: false, list: true });
    }
    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("/documents/documentList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            setIsParentCheckbox(false);
            localStorage.setItem("customerList", JSON.stringify(res.data.data));
            setCustomerList(res.data.data);
          } else {
            setCustomerList([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortOrder: sortorder,
      shipmentsOrder: shipmentsOrder,
      transactionOrder: transactionOrder,
      creditMemoOrder: creditMemoOrder,
      invoiceOrder: invoiceOrder
      // sortOrder: sorter.order === "descend" ? 1 : 0,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCustomers(value, 1, search, updatedFilter, type);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCustomers(pageSize, value, search, updatedFilter, type);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function searchHandler(e) {
    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
      search: "",
      nextpage: 1,
      regionId: regionId,
      filterDays:filterDays,
      status:rating,
      creditMemoOrder:creditMemoOrder,
      sortorder:sortorder,
      invoiceOrder:invoiceOrder,
      shipmentsOrder:shipmentsOrder,
      transactionOrder:transactionOrder
      };
      getCustomers(pageSize, 1, e.target.value, updatedFilter,true);
    }
  }
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };
  /**
   * @author Rajkumar
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );
  /**
   * @author Rajkumar
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "Region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "Rating") {
          updatedFilter.rating = "";
          setRating("");
          setRatingName("All");
        }
        if (item.value === "Date") {
          const clonefilteredDate = { ...filteredDate };
          clonefilteredDate.startDate = "";
          clonefilteredDate.endDate = "";
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
          setFilteredDate(clonefilteredDate);
        }
        getCustomers(pageSize, nextPage, search, updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  function filterRegionChange(name, value) {

    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays:filterDays,
      rating: rating,
      sortorder:sortorder,
      invoiceOrder:invoiceOrder,
      shipmentsOrder:shipmentsOrder,
      transactionOrder:transactionOrder,
      creditMemoOrder:creditMemoOrder
    };

    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDaysName(value.name);
    }
    if (name === "rating") {
      updatedFilter.rating = parseInt(value.value);
      updatedFilter.status = value.value;
      setRating(value.value);
      setRatingName(value.name);
    }
    getCustomers(pageSize, nextPage, search, updatedFilter,true);
  }
  /**
   * @author Rajkumar
   * @param {date, dateString}
   * DATE RANGE ONCHANGE FUNCTION 
   */
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    getCustomers(pageSize, nextPage, search, updatedFilter);
  };
  /**
   * @author Rajkumar
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Rating") === "Rating" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {ratingName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(ratingArr)
                      ? ratingArr.map((rate) => (
                        <span
                          key={rate._id}
                          onClick={() => filterRegionChange("rating", rate)}
                        >
                          <label>{rate.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const exportToCSV = async (e) => {
    // const filterOptions = {};
    // if (search) {
    //   filterOptions.search = search;
    // }
    // if (regionId) {
    //   filterOptions.regionId = regionId;
    // }
    // if (rating) {
    //   filterOptions.status = parseFloat(rating);
    // }
    // if (filteredDate?.startDate) {
    //   filterOptions.startDate = filteredDate.startDate;
    // }
    // if (filteredDate?.endDate) {
    //   filterOptions.endDate = filteredDate.endDate;
    // }
    // if (sortorder || sortorder === 0) {
    //   filterOptions.sortOrder = sortorder;
    // }
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.ORDER_SERVICE, "application/json")
      .post("/documents/exportDocumentList", params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach(item => {
            if (item['Date']) {
              item['Date'] = moment(item['Date']).format("MMM Do YYYY, h:mm:ss A")
            }
          })
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"]?.v);
          ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `documents-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };
  /**
   * @author Rajkumar
   * @param {data}
   * BUILD STATUS CLICK HANDLER
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedCustomerr")) {
      let selectedCustomerr = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (selectedCustomerr.length > 0) {
        submitBulkAction(selectedCustomerr);
        // let data = ["622d9e050b07772108ce3e09", "622f40840b077783aece3e74", "623012830b0777d82ace3fca"]
        // submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    (async function () {
      for (let el of data) {
        await delay(5500);
        window.open(
          `${process.env.REACT_APP_BASE_URL_PRD}:${PORTS.INVOICE_DOWNLOAD}/api/v1/orders/invoice/${el}/1`,
          "_self"
        );
      }
    })();
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = false;
    });
    localStorage.removeItem("selectedCustomerr");
    setIsParentCheckbox(false);
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
  };

  // Set delay function
  const delay = (data) =>
    new Promise((res) => {
      setTimeout(() => {
        res();
      }, data);
    });

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    console.log(sorter.order);
    let updatedFilter = {
      search: search,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      // sortOrder: sorter.order === "descend" ? 1 : 0,
    };
    if (sorter?.columnKey === "orderId") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
      setSortTransaction();
      setSortShipment();
      setSortInvoice();
      setSortMemo();
    } else if (sorter?.columnKey === "Transaction") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.transactionOrder = sortValue;
      setSortTransaction(sortValue);
      setSortorder();
      setSortShipment();
      setSortInvoice();
      setSortMemo();
    } else if (sorter?.columnKey === "Shipments") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.shipmentsOrder = sortValue;
      setSortShipment(sortValue);
      setSortorder();
      setSortTransaction();
      setSortInvoice();
      setSortMemo();
    } else if (sorter?.columnKey === "Invoice") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.invoiceOrder = sortValue;
      setSortInvoice(sortValue);
      setSortorder();
      setSortTransaction();
      setSortShipment();
      setSortMemo();
    } else if (sorter?.columnKey === "CreditMemo") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.creditMemoOrder = sortValue;
      setSortMemo(sortValue);
      setSortorder();
      setSortTransaction();
      setSortShipment();
      setSortInvoice();
    }
    // setNextPage(1);
    getCustomers(pageSize, nextPage, search, updatedFilter, true);
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays:filterDays,
      status:rating,
      creditMemoOrder:creditMemoOrder,
      sortorder:sortorder,
      invoiceOrder:invoiceOrder,
      shipmentsOrder:shipmentsOrder,
      transactionOrder:transactionOrder
    };
    getCustomers(pageSize, nextPage, updatedFilter.search, updatedFilter,true);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Documents</h1>
        <div className="d-flex align-items-center">
          <div>
            <span className={commonstyles.management_header_downloads_text}>
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToPDF(e)}
              />
              <img
                src={excelIcon}
                className={commonstyles.management_header_downloads_icon}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              />
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
        </div>
      </div>

      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by Order ID...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: (e) => searchHandler(e),
              ["aria-label"]: "Search customers",
              ["aria-describedby"]: "search-customers",
            }}
          />
          <img src={Search} alt="" />
        </div>
        <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
        <div className="action ml-3">
          <div className="action_header">
            Action
            <img src={arrowDown} alt="image" />
          </div>
          <div className="action_contents">
            {bulkActionFilter.length > 0 &&
              bulkActionFilter.map((item) => (
                <div
                  className="action_contents_header"
                  key={item._id}
                  onClick={() => bulkStatusClickHandler(item)}
                >
                  <h2
                    className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                      }`}
                  >
                    {item.name}
                  </h2>
                  {item.isChecked &&
                    JSON.parse(localStorage.getItem("selectedCustomerr"))
                      ?.length > 0 && (
                      <span
                        className="action_contents_btn"
                        onClick={() => applyBulkActionHandler(item)}
                      >
                        {
                          JSON.parse(localStorage.getItem("selectedCustomerr"))
                            ?.length
                        }{" "}
                        {JSON.parse(localStorage.getItem("selectedCustomerr"))
                          ?.length > 1
                          ? "Files"
                          : "File"}
                      </span>
                    )}
                </div>
              ))}
          </div>
        </div>
        <Dropdown
          overlay={filterMenu}
          onVisibleChange={handleVisibleChange} visible={visible}
          className="usermanage_table_filter_columns ml-3"
        >
          <div className="ant-dropdown-link">
            Filter <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu}
          className="usermanage_table_filter_columns ml-3"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Columns <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
      </div>

      <div className={`${commonstyles.management_table} table-responsive`}>
        {IsLoading.list === true ? (
          <div
            style={{
              marginLeft: "70vh",
              marginTop: "10vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching customer details..." />
              </td>
            </tr>
          </div>
        ) : (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`DocumentList for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div>
              <input
                type="checkbox"
                checked={isParentCheckbox}
                className={`${commonstyles.parentCheckbox}`}
                onChange={parentCheckbox}
              ></input>
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: customerList,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </div>
          </PDFExport>
        )}
      </div>
      {!IsLoading.list && Array.isArray(customerList) && customerList.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}
    </div>
  );
}
