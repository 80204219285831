import { useEffect, useState } from "react";
import { Select } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
const { Option } = Select;

export default function RegionSearchSelectComponent(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if(products?.length === 0) {
            // getParentProducts();
        }
    }, []);

    function getParentProducts() {
        setIsLoading(true);
        AxiosInstance(PORTS.REGION_SERVICE, "application/json")
            .get("/admin/region/getRegionList")
            .then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    setProducts(res.data.daaata);
                }
            })
            .catch((er) => {
                console.log(er);
            })
            .finally(() => setIsLoading(false));
    }

    function onChange(value) {
        props.handleParentIdUpdate(value, props.id);
    }

    return (
        <Select
            // showSearch
            placeholder="Select warehouse"
            loading={props.isLoading}
            onChange={onChange}
            bordered={false}
            id={props.id}
            value={props.value}
            className={`${commonstyles.form_control}  ${props.error && "theme-error-input"
                } form-control form-control-lg p-1`}
        >
            {props.products?.length > 0 && props?.products.map((product) => (
                <Option value={product._id} key={product._id}>
                    {product?.name?.toUpperCase()}
                </Option>
            ))}
        </Select>
    );
}