import React, { useState, useEffect, useRef } from 'react'
import commonstyles from "../../../../styles/Management_Common.module.css";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { notification, Button
} from "antd";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import downloadIcon from '../../../../assets/reversal_download.svg'
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import moment from 'moment-timezone'
import { Select } from "antd";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];
const statusObj = [
  { _id: 1, name: "Error Document", value: 0 },
  { _id: 2, name: "Success", value: 1 },
  { _id: 3, name: "Updated in Tally", value: 2 }
];
const dateFormatList = ["YYYY-MM-DD", "YYYY-MM-DD"];

const dateObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

let date = new Date();

function ListStockReversal() {
  const [filterStatusArr, setFilterStatusArr] = useState(null);
  const [filterDayArr, setFilterDayArr] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [nextPage, setNextPage] = useState(1);
  const [customDateVisible, setCustomDateVisible] = useState(false)
  const [filterDays, setFilterDays] = useState(null)
  const [sortOrder, setSortOrder] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [statusValue, setStatusvalue] = useState(null)

  const [columns, setColumns] = useState([
    {
      title: "File Name",
      visible: true,
      key: "url",
      render: (text, record, index) => (
        <span>
          {getfileName(record.url)}
        </span>
      ),
    },
    {
      title: "Date Uploaded",
      visible: true,
      key: "createdAt",
      render: (text, record, index) => (
        <td>{moment(record.createdAt).format("DD-MM-YYYY")}</td>
      ),
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Tally Updated Date",
      visible: true,
      key: "updatedAt",
      render: (text, record, index) => (
        <td>{record.status === 2 ? moment(record.updatedAt).format("DD-MM-YYYY") : "-"}</td>
      ),
    },
    {
      title: "Download",
      visible: true,
      key: "url",
      render: (text, record, index) => (
        <td> <a href={record.url} download>
          <img src={downloadIcon} alt="Download" />
        </a></td>
      ),
    },
    {
      title: "Status",
      visible: true,
      key: "status",
      render: (text, record, index) => (
        <span class={
          record.status === 0
            ? commonstyles.status_inactive
            : record.status === 1
              ? commonstyles.status_pending
              : commonstyles.status_active
        }>{record.status === 0 ? "Error occured" : record.status === 1 ? "Success" : "Updated in Tally"}</span>
      ),
    }
  ]);

  useEffect(() => {
    getStockReversalProducts(pageSize, nextPage, filterDays, startDate, endDate, sortOrder, statusValue)
  }, []);

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };
  
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const getfileName = (url) => {
    const urlSegments = url?.split('/');
    const fileName = urlSegments[urlSegments.length - 1];
    return fileName 
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const getStockReversalProducts = (pageSize, nextPage, filterDays, startDate, endDate, sortOrder, statusValue) => {
    const data = {
      "pagesize": pageSize,
      "nextpage": nextPage,
      "filterDays": filterDays,
      "startDate": startDate,
      "endDate": endDate,
      "sortOrder": sortOrder,
      "status": parseInt(statusValue)
    }
    AxiosInstance(PORTS.CMS, "application/json")
      .post('/tallyReversal/getReversal', data)
      .then((res) => {
        if (res.data.status === 200) {
          setCategoryData(res.data.data)
          setPagination(res.data.pagination)
        } else {
          showNotification({
            type: "error",
            message: "Error Occured",
            description: res?.data?.msg,
          });
        }
      })
      .catch((er) => {
        // console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const data = new FormData()
      data.append('buffer', selectedFile)
      AxiosInstance(PORTS.CMS, "application/json")
        .post('/tallyReversal/uploadFile', data)
        .then((res) => {
          const response = res.data;
          if (response.status === 200) {
            showNotification({
              type: "success",
              message: response.msg
            });
          } else {
            showNotification({
              type: "error",
              message: "Error Occured",
              description: response?.msg,
            });
          }
        })
        .catch((er) => {
          return er;
        })
        .finally(() => {
          setIsLoading(false);
          setTimeout(() => {
          getStockReversalProducts(10, 1)
          }, 1000);
        });
    }
    fileInputRef.current.value = null;
  }

  function getCategories(pageSize, nextPage, filterDays, startDate, endDate, sortOrder, statusValue) {
    getStockReversalProducts(pageSize, nextPage, filterDays, startDate, endDate, sortOrder, statusValue)
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCategories(value, 1, filterDays, startDate, endDate, sortOrder, statusValue);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCategories(pageSize, value, filterDays, startDate, endDate, sortOrder, statusValue);
    }

  }

  const filterOnChangeStatusHandler = (value, name) => {
    if (name.key === "clear") {
      setFilterStatusArr();
      setStatusvalue(null)
      setNextPage(1);
      startDate()
      endDate()
      getStockReversalProducts(pageSize, 1, filterDays, null, null, sortOrder)
    } else {
      setStatusvalue(name.value)
      setFilterStatusArr(value);
      getStockReversalProducts(pageSize, 1, filterDays, startDate, endDate, sortOrder, name.value)
    }
  };


  const dayOptions = dateObj.map((date) => (
    <Select.Option key={date._id} value={date.value}>
      {date.name}
    </Select.Option>
  ));

  dayOptions.push(
    <Select.Option key="clear" value="" style={{ textDecoration: "underline", color: "#9A8776" }}>
      Clear
    </Select.Option>
  );


  const statusOptions = statusObj.map((date) => (
    <Select.Option key={date._id} value={date.value}>
      {date.name}
    </Select.Option>
  ));

  statusOptions.push(
    <Select.Option key="clear" value="" style={{ textDecoration: "underline", color: "#9A8776" }}>
      Clear
    </Select.Option>
  );

  const dateRangeOnchangeHandler = (date, dateString) => {
    if(dateString[0] &&  dateString[1]){
    setStartDate(dateString[0])
    setEndDate(dateString[1])
    getStockReversalProducts(pageSize, 1, filterDays, dateString[0], dateString[1], sortOrder, statusValue)
      setTimeout(() => {
        setCustomDateVisible(false)
      }, 500);
    }
  }

  const handleSelectChange = (value, name) => {
    if(name.key === 'clear'){
      setCustomDateVisible(false)
      setFilterDayArr(null)
      setNextPage(1);
      setStartDate()
      setEndDate()
      getStockReversalProducts(pageSize, 1, null, null, null, sortOrder, statusValue)
    }else if(name.value === 'custom'){
      setCustomDateVisible(true)
      setFilterDayArr(name.children)
    }else{
      setCustomDateVisible(false)
      setFilterDays(name.value)
      setFilterDayArr(name.children)
      setStartDate()
      setEndDate()
      getStockReversalProducts(pageSize, nextPage, name.value, null, null, sortOrder, statusValue)
    }
  };

  let fileInputRef = useRef(null);
  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sortValue = sorter.order === "ascend" ? 0 : 1;
    setSortOrder(sortValue);
    getStockReversalProducts(pageSize, nextPage, filterDays, startDate, endDate, sortValue, statusValue)

  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Stock Reversal Products
        </h1>
      </div>
      <div className={commonstyles.management_header}>
        <Button
          onClick={openFileDialog}
          style={{border:"1px solid #A39486", borderRadius:"5px", color:"#A39486", opacity: "1"}}
        >
         Excel Upload
        </Button>
        <input
          type="file"
          id="fileInput"
          accept=".csv, .xlsx, .xls"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </div>

      <div
        className={`${commonstyles.management_table} stock_reversal table-responsive`}
      >
        <div style={{float:"right" , display: "flex", alignItems: "center" }}>
          <Select
            className={`antd_filter_dashboard`}
            name="status"
            value={filterDayArr || "Date uploaded"}
            onChange={handleSelectChange}
            style={{ fontSize: "12px" }}
          >
            {dayOptions}

          </Select>
          <div style={{ marginLeft: "20px" }}></div>
          <Select
            className={`antd_filter_dashboard`}
            name="status"
            value={filterStatusArr || filterStatusArr === 0 ? filterStatusArr : "Status"}
            onChange={filterOnChangeStatusHandler}
            style={{ fontSize: "12px" , width:"200px"}}
          >
            {statusOptions}

          </Select>
        </div>
        {customDateVisible && (
        <div style={{marginTop:"60px"}}>
            <AntDateRangeSelectComponent
              selectProps={{
                onChange: dateRangeOnchangeHandler,
                name: "sessionDateRange",
                format: dateFormatList,
              }}
            />
        </div>
          )}
        <AntTableComponent
          className="order_table"
          tableProps={{
            columns: columns,
            dataSource: categoryData,
            pagination: false,
            onChange: handleTableChange,
            components: {
              header: {
                row: (props) => renderHeader(props),
              },
              body: {
                row: (props) => renderBody(props),
              },
            },
          }}
        />
        {categoryData && categoryData.length > 0 && 
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
       }
      </div>

    </div>
  )
}

export default ListStockReversal