import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory , useParams } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum , zipcodeRegx , phoneRegx , emailRegx , userNameWithSpace} from "../../../utils/RegularExp";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";



export default function EditDelivery() {
  const initialFormState = {
    firstname: "",
    lastname: "",
    email: "",
    phone:"",
    address: "",
    apartment: "",
    region: "",
    postalCode:"",
  };
  const history = useHistory();
  const { id } = useParams();
  const [addForm, setAddForm]= useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([])

  const rules = {
    firstName: [(val, data) => (!val ? "First Name " : true)],
    lastName: [(val, data) => (!val ? "Last Name " : true)],
    email: [(val, data) => (!val ? "Email " : true)],
    address: [(val, data) => (!val ? "Address " : true)],
    phone: [(val, data) => (!val ? "Phone " : true)],
    apartment: [(val, data) => (!val ? "Apartment" : true)],
    regionId: [(val, data) => (!val ? "Region" : true)],
    pincode: [(val, data) => (!val ? "Pincode " : true)],
  };


  useEffect(() => {
    getRegionList()
    getAgentDetails()
  },[])

    /**
   * @author RAJKUMAR
   * @param {}
   * GET AGENT DETAILS
   */
  const getAgentDetails = () => {
    // setIsLoading(true);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
    .get(`/deliveryAgent/agentDetail/${id}`)
    .then((res) => {
      if (res.data.status === 200) {

          let receivedData = res.data.data;

          let data = {
            firstname: receivedData.name.split("__")[0] ? receivedData.name.split("__")[0] : '',
            lastname: receivedData.name.split("__")[1] ? receivedData.name.split("__")[1] : '',
            email: receivedData.email ? receivedData.email : '',
            phone: receivedData.phone ? receivedData.phone : '',
            address: receivedData.address.split("__")[0] ? receivedData.address.split("__")[0] : '',
            apartment: receivedData.address.split("__")[1] ? receivedData.address.split("__")[1] : '',
            region: receivedData.regionId ? receivedData.regionId : '',
            pincode: receivedData.pincode ? receivedData.pincode : '',
          }

        setAddForm(data);
      } else if (res.data.status === 400) {
      }
    })
    .catch((er) => {
    //   setIsLoading(false);
      showNotification({
        type: "error",
        message: "Failed",
        description: "Error occured",
      });
    })
}

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
            if (res.status === 200 && res.data.status === 200) {
                setRegions(res.data.daaata);
            }
        })
        .catch((er) => {
            console.log(er);
        });
  }

  /**
   * @author RAJKUMAR
   * @param {Event}
   * HANDLE AGENT DETAILS CHANGES
   */
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    const updatedValues = { ...addForm,[name]:value };
    setAddForm(updatedValues);
  }


    /**
   * @author RAJKUMAR
   * @param {Event}
   * SUBMIT/UPDATE AGENT DETAILS
   */
function submit(e) {
  let data = {
      id : id,
      enable : true,
      firstName: addForm.firstname.trim(),
      lastName: addForm.lastname,
      email: addForm.email.trim(),
      address: addForm.address,
      phone: addForm.phone,
      apartment : addForm.apartment,
      regionId : addForm.region,
      pincode: parseInt(addForm.pincode)
    };

    const result = runValidationChecks(rules, undefined, {
      ...data,
    });

    if(Object.keys(formErrors).length > 0){
      return;
    }

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      return;
    }


    let formdata = new FormData();

    formdata.append('data', JSON.stringify(data));

    // console.log(formdata);

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post(`/deliveryAgent/updateAgent`,formdata)
      .then((resp) => {
          const response = resp && resp.data;
          if (
          response &&
          (response.status === "200" || response.status === 200)
          ) {
            showNotification({
              type: "success",
              message: "Success",
              description: response.msg,
            });
            history.push("/catalog/deliveryList/delivery")
          } else if (
          response &&
          (response.status === "400" || response.status === 400)
          ) {
          // return [];
          
            showNotification({
                type: "error",
                message: "Error",
                description: response.msg,
            });
          }
    })
    .catch((err) => {
        console.log(err);
    });
}


  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/catalog/deliveryList/delivery")}
          />
          Edit Delivery Person
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: () => history.push(`/catalog/deliveryList`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              onClick: submit,
            }}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
      <div className={styles.management_details}>
            <h4>Delivery Person Overview</h4>
              <div className={styles.management_details_contents}>
                <div className="row">
                  {/* First name  */}
                  <div className="col-lg-6 mb-4">
                    <div className={styles.management_details_form}>
                      <label for="">First name 
                      <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                          </span></label>
                      <InputComponent
                        error={formErrors.firstName}
                        className={`form-control form-control-lg ${styles.form_control}`}
                        formControl={{
                          placeholder: "eg. Antony",
                          name: "firstname",
                          value: addForm?.firstname,
                          onChange: (e) => { 
                            if(!userNameWithSpace.test(e.target.value)){
                              setFormErrors({firstName :'Please enter a minimum 3 characters first name'})
                            }else{
                              setFormErrors({})
                            }
                            handleFormInput(e)
                           },
                        }}
                      />
                      <FormErrorText error={formErrors.firstName} />
                    </div>
                  </div>
                  {/* First name end */}
                  {/* Last name  */}
                  <div className="col-lg-6 mb-4">
                    <div className={styles.management_details_form}>
                      <label for="">Last name 
                      <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                          </span></label>
                      <InputComponent
                        error={formErrors.lastName}
                        className={`form-control form-control-lg ${styles.form_control}`}
                        formControl={{
                          placeholder: "eg. John",
                          name: "lastname",
                          value: addForm?.lastname,
                          onChange: (e) => { 
                            if(!userNameWithSpace.test(e.target.value)){
                              setFormErrors({lastName :'Please enter a minimum 3 characters last name'})
                            }else{
                              setFormErrors({})
                            }
                            handleFormInput(e)
                           },
                        }}
                      />
                      
                      <FormErrorText error={formErrors.lastName} />
                    </div>
                  </div>
                  {/* Last name  end */}
                  {/* Email */}
                  <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Email
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <InputComponent
                        error={formErrors.email}
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "eg. Antony.john@gmail.com",
                        name: "email",
                        value: addForm.email,
                        onChange: (e) => { 
                          if(!emailRegx.test(e.target.value)){
                            setFormErrors({email :'Please enter a valid email'})
                          }else{
                            setFormErrors({})
                          }
                          handleFormInput(e)
                         },
                        }}
                    />
                    <FormErrorText error={formErrors.email} />
                    </div>
                    {/* Email ends */}
                  {/* Phone */}
                  <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Phone
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <div className={commonstyles.adduser_input_countycode}>  
                      <span>+91</span>
                    <InputComponent
                        error={formErrors.phone}
                        className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                        formControl={{
                        placeholder: "Type phone",
                        name: "phone",
                        value: addForm.phone,
                        onChange: (e) => { 
                          if(!phoneRegx.test(e.target.value)){
                            setFormErrors({phone :'Please enter a valid phone number'})
                          }else{
                            setFormErrors({})
                          }
                          handleFormInput(e)
                         },
                        }}
                    />
                    </div>
                    <FormErrorText error={formErrors.phone} />
                    </div>
                    {/* Phone ends */}
                </div>
              </div>
              {/* Address */}
              <h4>Address</h4>
              <div className={styles.management_details_contents}>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <div className={styles.management_details_form}>
                      <label for="">Address1
                      <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                          </span></label>
                      <InputComponent
                        error={formErrors.address}
                        className={`form-control form-control-lg ${styles.form_control}`}
                        formControl={{
                          placeholder: "",
                          name: "address",
                          value: addForm.address,
                          onChange: (e) => { 
                            if(e.target.value.length > 0 && e.target.value.length < 3){
                              setFormErrors({address :'Please enter a valid address'})
                            }else{
                              setFormErrors({})
                            }
                            handleFormInput(e)
                           },
                        }}
                      />
                      <FormErrorText error={formErrors.address} />
                    </div>
                  </div>
                  {/* Address end */}
                  {/* Apartment  */}
                  <div className="col-lg-6 mb-4">
                    <div className={styles.management_details_form}>
                      <label for="">Address2
                      <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                          </span></label>
                      <InputComponent
                        error={formErrors.apartment}
                        className={`form-control form-control-lg ${styles.form_control}`}
                        formControl={{
                          placeholder: "",
                          name: "apartment",
                          value: addForm.apartment,
                          onChange: (e) => { 
                            if(e.target.value.length > 0 && e.target.value.length < 3){
                              setFormErrors({apartment :'Please enter a valid apartment'})
                            }else{
                              setFormErrors({})
                            }
                            handleFormInput(e)
                           },
                        }}
                      />
                    <FormErrorText error={formErrors.apartment} />
                    </div>
                  </div>
                  {/* Apartment end  */}
                  {/* Country */}
                  <div className="col-lg-6 mb-4">
                        <label className="theme-form-label">
                            Warehouse <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative"}}>*</span>
                        </label>
                        <SelectComponent
                            error={formErrors.regionId}
                            className={`form-control form-control-lg mb-3${
                                commonstyles.form_control
                            }`}
                            attributes={{
                              name : "region",
                              value : addForm.region,
                              onChange : handleFormInput,
                            }}
                            >
                            <option value="" disabled>Select an option</option>
                            { Array.isArray(regions) && regions?.map((region) => {
                                  return(<option key={region._id} value={region._id}>
                                        {region.name}
                                    </option>)
                              })}

                        </SelectComponent>
                        <FormErrorText error={formErrors.regionId} />
                    </div>
                    {/* type Country */}
                  {/* Postal Code */}
                  <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Postal Code
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                        </span>
                    </label>
                    <InputComponent
                        error={formErrors.pincode}
                        className="form-control-lg mb-3"
                        formControl={{
                        placeholder: "Type postal code",
                        name: "pincode",
                        value: addForm.pincode,
                        onChange: (e) => { 
                          if(!zipcodeRegx.test(e.target.value)){
                            setFormErrors({pincode :'Please enter a valid pincode'})
                          }else{
                            setFormErrors({})
                          }
                          handleFormInput(e)
                         },
                        }}
                    />
                    <FormErrorText error={formErrors.pincode} />
                    </div>
                    {/* Postal Code ends */}
                </div>
              </div>
      </div>
    </div>
  );
}
