import React from "react";
import styles from "../../../styles/OrderCompleted.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import DeliveryGreen from "../../../assets/images/delivery_green.svg";
import ProcessingGreen from "../../../assets/images/processing_green.svg";
import ShippedGreen from "../../../assets/images/shipped_green.svg";

export default function OrderDetails() {
  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img src={BlackLeftArrow} alt="" style={{ cursor: "pointer" }} />
          #23425224466<span>Updated by Girish, June 18, 9:24 am</span>
        </h1>
      </div>
      <div className={styles.management_details}>
        <div className={styles.management_details_contents}>
          <div
            className={`${styles.management_details_form} ${styles.border_green} mb-4`}
          >
            <div className={styles.row}>
              <div className="col-lg-12">
                <div className={`media ${styles.media_card}`}>
                  {/* <img className="mr-3" src="img/green-tick.svg" alt="Generic placeholder image"> */}
                  <div className={`media-body ${styles.media_body}`}>
                    <h5 className="mt-1">Completed</h5>
                    Order created on Jun 17, 2021, 9:24 AM. You can view the{" "}
                    <a href="">View the Order</a> or{" "}
                    <a href="">Add a new order.</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <h4>Products</h4>
              <div
                className={`${styles.management_details_form} ${styles.product_details} mb-4`}
              >
                <div>
                  <h3 className={styles.secondry_title}>Product</h3>
                  {/* <img src="img/brown_sofa.png" alt=""> */}
                  <div className={styles.product_name}>
                    <a href="">Manila</a>
                    <span>Rs. 18,000</span>
                  </div>
                </div>
                <div>
                  <h3 className={styles.secondry_title}>Quantity</h3>
                  <span className={styles.product_value}>1</span>
                </div>
                <div>
                  <h3 className={styles.secondry_title}>Total</h3>
                  <span className={styles.product_value}>Rs. 18,000</span>
                </div>
              </div>
              <h4>Payment & Shipping Method</h4>
              <div className={`${styles.management_details_form} mb-5`}>
                <div className={styles.admin_pament_info}>
                  <div className={styles.admin_payment_invoice}>
                    <h3 className={styles.secondry_title}>
                      Payment Information
                    </h3>
                    <a href="">Invoice Download</a>
                  </div>
                  <span>Razorpay</span>
                  <span>The order was placed using INR.</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Sub Total</span>
                  <span>Rs. 26,998</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Discount</span>
                  <span>-Rs. 4,000</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Coupon Code</span>
                  <span>-Rs. 2,000</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Total Saving</span>
                  <span>Rs. 6000</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Shipping & Handling</span>
                  <span>Rs. 0</span>
                </div>
                <div className={styles.admin_payment}>
                  <span>Total</span>
                  <span>Rs.20,998</span>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <h4>Customer Details</h4>
              <div
                className={`${styles.management_details_form} ${styles.admin_customer} mb-4`}
              >
                <div
                  className={`${styles.admin_customer_details} ${styles.border_bottom}`}
                >
                  <h3 className={styles.secondry_title}>Full name</h3>
                  <span className="mb-3">Antony John</span>
                </div>
                <div
                  className={`${styles.admin_customer_details} ${styles.border_bottom}`}
                >
                  <h3 className={styles.secondry_title}>Contact Information</h3>
                  <span className="mb-2">+91 81234 56789</span>
                  <span className="mb-3">antony_john@gmail.com</span>
                </div>
                <div className={styles.admin_customer_details}>
                  <h3 className={styles.secondry_title}>Shipping Address</h3>
                  <span className="mb-3">
                    22 'A' main, 1st stage, 2nd cross, JP Nagar. near Guru Kula
                    apartments, Bengaluru - 560048, Karnataka,India, Mobile:
                    8892117378
                  </span>
                  <h3 className={styles.secondry_title}>Billing Address</h3>
                  <span>Same as Shipping address</span>
                </div>
              </div>
            </div>
            <h4>Time Line</h4>
            <div className={styles.order_progress_bar}>
              <div className={styles.order_progress_bar_values}>
                <div
                  className={`${styles.order_progress_bar_details} completed`}
                >
                  <img src={ProcessingGreen} />
                  <div className={styles.order_progres_bar_status}>
                    <div className={styles.order_progress_bar_date}>
                      <span>29 Jan 2021</span>
                    </div>
                    Shipped
                  </div>
                </div>
              </div>
              <div className={styles.order_progress_bar_values}>
                <div
                  className={`${styles.order_progress_bar_details} completed`}
                >
                  <img src={ShippedGreen} />
                  <div className={styles.order_progres_bar_status}>
                    <div className={styles.order_progress_bar_date}>
                      <span>06 Feb 2021</span>
                    </div>
                    Order{" "}
                    <span className={styles.grayish_orange}>#23425224466</span>{" "}
                    was Shipped
                  </div>
                </div>
              </div>
              <div className={styles.order_progress_bar_values}>
                <div className={`${styles.order_progress_bar_details} rm-line`}>
                  <img src={DeliveryGreen} />
                  <div
                    className={`${styles.order_progres_bar_status} text-success`}
                  >
                    <div className={styles.order_progress_bar_date}>
                      <span>10 Feb 2021</span>
                    </div>
                    Deliver Sucussfully
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
