import { useEffect, useState, useRef } from "react";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import styles from "../../../../styles/AddProduct.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";

import ProductRegionDetailFormComponent from "../../../modules/ProductManagement/ProductRegionDetailFormComponent";
import ProductDetailsFormComponent from "../../../modules/ProductManagement/ProductDetailsFormComponent";
// import StaticProductOptionFormComponent from "../../../modules/ProductManagement/StaticProductOptionFormComponent";
// import DynamicProductOptionFormComponent from "../../../modules/ProductManagement/DynamicProductOptionFormComponent";
import ProductMediaUploadForm from "../../../modules/ProductManagement/ProductMediaUploadForm";
import ProductCategoryTreeSelectComponent from "../../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import { notification } from "antd";
import validationRules from "../../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import { formatServerValidtionErrors } from "../../../../utils";
import FaqFormComponent from "../../../modules/ProductManagement/FaqFormComponent";
import ProductOptionsFormComponent from "../../../modules/ProductManagement/ProductOptionsFormComponent";
import RelatedProductSelectFormComponent from "../../../modules/ProductManagement/RelatedProductSelectFormComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import ImagePreviewChip from "../../../modules/ProductManagement/ImagePreviewChip";
import { addressRegx, alphaNum, checkForHexRegExp, gst, onlyNumbers, userName, alphanumericwithdashHyphen } from "../../../../utils/RegularExp";
// import usePrevious from "../../../hooks/usePrevious";
import SearchComponents from "../../SearchManagement/SearchComponent";

const initialaddFormState = () => {
  return {
    enable: true,
    name: "",
    classification: "",
    slug: "",
    most_desirable: false,
    handpicked: false,
    trending: false,
    description: "",
    seoMarkup:"",
    youmaylike: [],
    similar: [],
    boughtTogether: [],
    tallyId: "",
    tag: "",
    categoryId: [],
    productOption: [],
    gallery: [],
    hsn: "",
    lineTax: "",
    uom: "",
    feature_image: "",
    // staticProductOption: [],
    // dynamicProductOption: [],
    // productType "",
    faq: [],
    manufacturer:"",
    packedby:"",
    weight:"",
    numberofpacks:"",
    countryoforigin:"",
  };
};

const stock = {
  quantity: "",
  oldPrice: "",
  newPrice: "",
  shipin: "",
  // offer: "",
  notificationQuantity:"",
  region: {
    id: "",
  },
};

const initialProductDetailsForm = () => {
  return {
    keywords:"",
    overview: "",
    materialCare: "",
    sku: "",
    isParent: false,
    parentId: "",
    // deliveryInstallation: "",
    productFeature: "",
    packaging: "",
    termsConditions: "",
    // warranty: "",
    warrantyTerms: "",
  };
};

// const localFileData = {
//   files: [],
//   filesPreview: [],
// };

const firstStock = JSON.parse(JSON.stringify(stock));

const rules = {
  name: [validationRules.required],
  slug: [validationRules.required],
  // description: [validationRules.required],
  tallyId: [validationRules.required],
  // classification: [validationRules.required],
  productType: [validationRules.required],
  //slug: [validationRules.required],
  sku: [validationRules.required],
  // tag: [validationRules.required],
  lineTax: [validationRules.required],
  feature_image: [validationRules.required],
  gallery: [validationRules.required],

  stocks: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validateStockItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
  /* faq: [
    (val) => {
      if (Array.isArray(val)) {
        const errors = val.reduce(validateFaqItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ], */
  /* parentId: [(val, data) => (!data.isParent && !val ? "Required" : true)],
  productOption: [
    (val) => {
      if (Array.isArray(val)) {
        if (val.length === 0) {
          return { general: "Required at least one product option" };
        }
      }
    },
  ],
  tag: [
    (val) => ["New", "Best buy"].includes(val) || "Required to select a value",
  ], */
  // faq: [(val) => (Array.isArray(val) && val.length) || "Required"],
};

function validateStockItem(accumulator, currentStock, indx) {
  const error = {};
  if (!currentStock?.region?.id) {
    error["region"] = "Requried";
  }
  ["oldPrice", "quantity", "shipin", "newPrice","notificationQuantity"].forEach((field) => {
    if (!currentStock[field] && currentStock[field] !== 0) {
      error[field] = "Required";
    } /* else if (currentStock.oldPrice <= 0 && field !== "quantity") {
      error[field] = "Value should be greater than 0";
    } */
  });

  ["oldPrice", "quantity", "newPrice"].forEach((field) => {
    if (field < 0) {
      error[field] = "Value should be greater than 0";
    }
  });

  if (
    currentStock.oldPrice &&
    currentStock.newPrice &&
    currentStock.oldPrice < currentStock.newPrice
  ) {
    error.oldPrice = "Value should be greater than selling price";
  }

  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

/* function validateFaqItem(accumulator, currentFaq, indx) {
  const error = {};
  ["Question", "Answer"].forEach((field) => {
    if (validationRules.required(currentFaq[field]) !== true) {
      error[field] = "Required";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
} */

export default function AddProductComponent(props) {
  const [addForm, setAddForm] = useState(initialaddFormState());
  const [stocksForm, setStocksForm] = useState([firstStock]);
  const [productDetailsForm, setProductDetailsForm] = useState(
    initialProductDetailsForm()
  );
  const [regionalProducts, setRegionalProducts] = useState([]);
  const [localFileData, setLocalFileData] = useState({
    files: [],
    filesPreview: [],
  });
  const [productOptions, setProductOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [componentVisibility, setComponentVisibility] = useState({
    youmaylike: false,
    similar: false,
    boughtTogether: false,
  });
  const [isLoading, setIsLoading] = useState({ submit: false });
  const [formErrors, setFormErrors] = useState({});
  const [categoryNames,setCategoryNames] = useState([])
  const filterCategoryIds=Array.isArray(addForm.categoryId) ? addForm.categoryId.filter((item, index) => addForm.categoryId.indexOf(item) === index) : []; 
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  // const prevStock = usePrevious(stocksForm);
  const ServicesRef = useRef(null);
  const ServicesRefDim = useRef(null);
  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .get("/admin/catalog/getCategoryName/")
      .then((res) => {
        //console.log(res.data)
        if (res && res.data && res.status == "200") {
          // console.log(res.data.daaata);
          // const data = transformData(res.data.daaata);
          //setTreeData(data);
          setCategoryNames(res.data.daaata);
          //   setCategoryData(res.data.data);
          //   setPagination(res.data.pagination);
          
        }
      })
      .catch((er) => {
        console.log("Failed to get data on Category tree select");
        console.log(er);
      });
  }

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          // console.log(er);
          return er
        });
    }
    
    function getProductionOptions() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .post("/productOptionFilter")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setProductOptions(res.data.data);
          }
        })
        .catch((er) => {
          // console.log(er);
          return er
        });
    }

    getRegionList();
    getProductionOptions();
  }, []);

  function handleImageInput(e) {
    const { name, files } = e.target;
    //console.log(e.target.files[0]);
    if (files[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
    }else{
        setFile(e.target.files[0]);
        if (url) {
          URL.revokeObjectURL(url);
        }
        const generatedUrl = URL.createObjectURL(e.target.files[0]);
        setUrl(generatedUrl);
        addForm.feature_image = generatedUrl;
    }
    
  }

  function removeFile() {
    if (url) {
      addForm.feature_image = "";
      setFile(null);
      setUrl(null);
    }
  }

  function getProductListByRegionId(ids = []) {
    //This api will provies data to youmaylike, similar, boughtTogether
    //based on regions ids selected.
    // WIll trigger  when regionid exists, changed

    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getProductsList/", { regionId: ids })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegionalProducts(res.data.data);
        }
      })
      .catch((er) => {
        // console.log(er);
        return er
      });
  }

  useEffect(() => {
    /* const currentRegIds = stocksForm
      .filter((stock) => stock?.region?.id !== "")
      .map((stock) => stock.region.id); */

    getProductListByRegionId([]);
  },[]);

  

  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    //console.log(`Name=${name} Value=${value}`);
    /* if(name==="slug"){
    let k = window.event.keyCode;
    console.log(k)
        if (k === 32) {
          return false;
        }
    } */
    if(name==="name"){
      if (!e.target.value.match(addressRegx) || !e.target.value === "") {
        formErrors.name = "required";
      }else{
        formErrors.name = "";
      }
    }
    if(name==="slug"){
      value=value.trim();
      if (!e.target.value.match(alphanumericwithdashHyphen) || !e.target.value === "") {
        formErrors.slug = "Enter only alphabet and number";
      }else{
        formErrors.slug = "";
      }
    }
    if(name==="tallyId"){
      value=value.trim();
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        formErrors.tallyId = "Enter only alphanumeric, min 3 & max 50";
      }else{
        formErrors.tallyId = "";
      }
    }
    if(name==="uom"){
      if (!e.target.value.match(gst) || !e.target.value === "") {
        formErrors.uom = "Enter only number";
      }else{
        formErrors.uom = "";
      }
    }
    if(name==="tallyId"){
      value=value.trim();
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        formErrors.tallyId = "Enter only alphanumeric, min 3 & max 50";
      }else{
        formErrors.tallyId = "";
      }
    }
    if(name==="lineTax"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.lineTax = "Enter only number";
      }else if(e.target.value.length>2){
        formErrors.lineTax = "GST out of range";
      }
      else{
        formErrors.lineTax = "";
      }
    }
    if(name==="countryoforigin"){
      if (!e.target.value.match(userName) || !e.target.value === "") {
        formErrors.countryoforigin = "Enter only alphabets and minimum 3 letters";
      }else{
        formErrors.countryoforigin = "";
      }
    }
    if(name==="weight"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.weight = "Enter only number";
      }else{
        formErrors.weight = "";
      }
    }
    if(name==="numberofpacks"){
      if (!e.target.value.match(onlyNumbers) || !e.target.value === "") {
        formErrors.numberofpacks = "Enter only number";
      }else{
        formErrors.numberofpacks = "";
      }
    }
    const updatedValues = { ...addForm };
    
    if (["enable", "trending", "most_desirable", "handpicked"].includes(name)) {
      updatedValues[name] = checked;
      // } else if (name === "files") {
      //   const files = e.target.files;
      //   updatedValues[name] = files;
      //   console.log(e.target);
      // } else if (name === "categoryId") {
      // const category = value > -1 ? parentCategories[value] : null;
      // updatedValues[name] = category ? category._id : null;
    } else {
      updatedValues[name] = value;
    }

    setAddForm(updatedValues);
  }

  function updateSelectedProducts(action, product, formField) {
    // const maxLength = { youmaylike: 4, similar: 4, boughtTogether: 2 };

    const updatedValues = addForm[formField];
    if (["add", "remove"].includes(action)) {
      const index = updatedValues.findIndex((v) => v._id === product._id);
      if (action === "add" /* && updatedValues.length < maxLength[formField] */) {
        if (index === -1) {
          updatedValues.push(product);
          setAddForm({ ...addForm, [formField]: updatedValues });
        }
      } else {
        if (index > -1) {
          updatedValues.splice(index, 1);
          setAddForm({ ...addForm, [formField]: updatedValues });
        }
      }
    }
  }

  function addNewRegion() {
    const updatedValue = JSON.parse(JSON.stringify(stocksForm));
    const newStock = JSON.parse(JSON.stringify(stock));
    updatedValue.push(newStock);
    setStocksForm(updatedValue);
  }

  function removeRegion(index) {
    // console.log("index");
    // console.log(index);
    const updatedValue = stocksForm;
    updatedValue.splice(index, 1);
    setStocksForm([...updatedValue]);
  }

  function updateStock(stock, indx) {
    // console.log(`${stock} ${indx}`);
    const updatedValues = JSON.parse(JSON.stringify(stocksForm));
    updatedValues[indx] = stock;

    //Check changes in region id
    const oldRegionIds = stocksForm
      .filter((s) => s?.region?.id !== "")
      .map((s) => s.region.id);
    const updatedRegionIds = updatedValues
      .filter((s) => s?.region?.id !== "")
      .map((s) => s.region.id);

    //if (!isEqual(oldRegionIds, updatedRegionIds)) {
      //Trigger product API list based on region id
      //getProductListByRegionId(updatedRegionIds);
    //}

    setStocksForm([...updatedValues]);
  }

  function updateProductDetails(productDetails) {
    const updatedValues = JSON.parse(JSON.stringify(productDetails));
    // console.log(updatedValues);
    setProductDetailsForm(updatedValues);
  }

  function updateComponentVisibility(e) {
    const { name, checked } = e.target;
    const updatedValues = { ...componentVisibility };
    if (["youmaylike", "similar", "boughtTogether"].includes(name)) {
      updatedValues[name] = checked;
      // if (!checked) {
      //   setAddForm({ ...addForm, [name]: [] });
      // }
      setComponentVisibility(updatedValues);
    }
  }

  function handleMediaUpdate(fileDetails, form) {
    if (form === "gallery") {
      setAddForm({ ...addForm, gallery: fileDetails });
    } else if (form === "files") {
      setLocalFileData(fileDetails);
    }
  }

  function updateProductOption(productOptionsArray) {
    // console.log("productOptionsArray");
    // console.log(productOptionsArray);
    addForm.productOption = productOptionsArray;
    setAddForm({ ...addForm });
  }

  function createRequestData() {
    let data = JSON.parse(JSON.stringify(addForm));
    // console.log(data.gallery)
    data.name=data.name.trim();
    data.description=data.description.trim();
    data.stocks = JSON.parse(JSON.stringify(stocksForm));
    const details = JSON.parse(JSON.stringify(productDetailsForm));
    data = { ...data, ...details };

    data.slug = data.slug.trim();
    data.tallyId = data.tallyId.trim();
   // if (/* data.lineTax.trim() !== "" */ data.lineTax)
    if (data.lineTax) {
      const parsedlineTax = parseInt(data.lineTax);
      data.lineTax = !isNaN(parsedlineTax) ? parsedlineTax : data.lineTax;
    } else {
      delete data.lineTax;
    }
    if (data.weight) {
      const parsedweight = parseInt(data.weight);
      data.weight = !isNaN(parsedweight) ? parsedweight : data.weight;
    } else {
      delete data.weight;
    }
    if (data.numberofpacks) {
      const parsedPack = parseInt(data.numberofpacks);
      data.numberofpacks = !isNaN(parsedPack) ? parsedPack : data.numberofpacks;
    } else {
      delete data.numberofpacks;
    }
    if (data.uom) {
      const parsedPack = parseInt(data.uom);
      data.uom = !isNaN(parsedPack) ? parsedPack : data.uom;
    } else {
      delete data.uom;
    }
    if (data.hsn.trim() === "") {
      delete data.hsn;
    }

    data.youmaylike = Array.isArray(data.youmaylike)
      ? data.youmaylike.map((prdct) => prdct._id)
      : [];
    data.similar = Array.isArray(data.similar)
      ? data.similar.map((prdct) => prdct._id)
      : [];
    data.boughtTogether = Array.isArray(data.boughtTogether)
      ? data.boughtTogether.map((prdct) => prdct._id)
      : [];

    if (data.isParent) {
      delete data.parentId;
    }
    data.seoMarkup=JSON.stringify(addForm.seoMarkup)
    // if (data.gallery.length === 0) {
    //   delete data.gallery;
    // }

    data.productType = data.isParent
      ? "Configurable Product"
      : "Simple Product";

    /* CATEGORY */
    const selectedCategoryIdsType=Array.isArray(addForm.categoryId) ? typeof addForm.categoryId[0]==="string" ? "string" : typeof addForm.categoryId[0]==="object" ? "object" : null : null;

    const selectedCategoryIds=selectedCategoryIdsType==="string" ? addForm.categoryId :selectedCategoryIdsType==="object" ? addForm.categoryId.map((c)=>c.value) : [];
    //console.log(selectedCategoryIds);
    if(selectedCategoryIds.length>0)
    {
     const selectedCategories=categoryNames.filter((category)=>selectedCategoryIds.includes(category._id));
      //console.log(selectedCategories);
     selectedCategories.forEach((category)=>{
        if(category.level===1){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          }
        }else if(category.level===2){
          if(!selectedCategoryIds.includes(category.parentId)){
            selectedCategoryIds.push(category.parentId);
          //console.log(selectedCategoryIds);
          }
          const parentCategory=categoryNames.find(item=>item._id===category.parentId);
          //console.log(parentCategory)
          if(parentCategory){
            if(!selectedCategoryIds.includes(parentCategory.parentId)){
              selectedCategoryIds.push(parentCategory.parentId);
            }
          }
        }
      })
    }
    
    data.categoryId=selectedCategoryIds

    return data;
  }

  function addFaq() {
    const newFaq = { Question: "", Answer: "" };
    const faqs = [...addForm.faq, newFaq];
    setAddForm({ ...addForm, faq: faqs });
  }

  function removeFaq(indx) {
    let faqs = addForm.faq;
    if (faqs[indx]) {
      faqs.splice(indx, 1);
      setAddForm({ ...addForm, faq: faqs });
    }
  }

  function updateFaq(val, indx) {
    const faqs = addForm.faq;
    faqs[indx] = val;
    setAddForm({ ...addForm, faq: faqs });
  }

  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        // console.log("field");
        // console.log(field);
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }

    // for (const field in data) {
    //   if (rules[field]) {
    //     const fieldRules = rules[field];

    //     for (const rule of fieldRules) {
    //       let val = rule(data[field], data);
    //       if (typeof val === "string") {
    //         result[field] = val;
    //         break;
    //       }
    //     }
    //   }
    // }
    return result;
  }
  const gotoServices = () =>{
    // console.log(ServicesRef.current);
    ServicesRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }
    const gotoServicesDim = () =>{
      // console.log(ServicesRefDim.current);
      ServicesRefDim.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  function handleFormSubmit() {
    setFormErrors({});
    const data = createRequestData();
    //console.log(data);
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    } else {
      delete data.feature_image
    }
    if(data.parentId===""){
      delete data.parentId
    }
    //console.log(data);
    // return;
    let hasError = false;
    let hasErrorDime= false;
    data.productOption.forEach(option => {
    let foundedOption = productOptions.find(o => o._id === option.attributeId);
    // console.log(foundedOption);
      if(foundedOption.name === "Warranty"){
        // console.log(option);
        if(!checkForHexRegExp.test(option.optionId)){
          // console.log("error");
          // console.log(option);
          let datas=option.optionId.split(" ")
          if(datas[0]==="" || datas[1]===""){
            hasError=true;
          }
        }
      //create vaildation here if fails set hasError = true;
      // let getWarrenty=foundedOption.options.find(element => {
      //   if(option.optionId===element._id){
      //     return true;
      //   }
      // });
      // if(getWarrenty){
      //   let datas=getWarrenty.value.split(" ")
      //   if(datas[0]==="" || datas[1]===""){
      //     hasError=true;
      //   }
      // }
      }
      if(foundedOption.name === "Dimensions"){
        // console.log(option);
        if(!checkForHexRegExp.test(option.optionId)){
          // console.log("error");
          // console.log(option);
          let datas=option.optionId.split("&&");
          // console.log(datas);
          // console.log(datas.length);
          if(datas.length !=3 && datas[2] !=""){
            hasErrorDime=true;
          }
        }
      }
    })
    if(hasError){
      showNotification({
        type: "error",
        message: "Failed to update data",
        description: "Please fill the warranty",
      });
      // setFormErrors({...formErrors});
      gotoServices();
      return 
    }
    if(hasErrorDime){
      showNotification({
        type: "error",
        message: "Failed to update data",
        description: "Please fill the dimensions field",
      });
      // setFormErrors({...formErrors});
      gotoServicesDim();
      return 
    }

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("feature_image", file);
    //console.log(formData)
    for (let i = 0; i < localFileData.files.length; i++) {
      formData.append("files", localFileData.files[i]);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // accept: "*/*",
      },
    };
    setIsLoading({ ...isLoading, submit: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "multipart/form-data")
      .post("/admin/catalog/addProduct", formData, config)
      .then((res) => {
        //console.log(res.data);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Product details added successfully",
          });
          props.handleClose();
        } else if (res.data.status === 400) {
          const errors = formatServerValidtionErrors(res.data.error);
          setFormErrors(errors);
          showNotification({
            type: "error",
            message: "Adding product failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding product failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading({ ...isLoading, submit: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleTagChanges(target){
    //console.log(target);
    const {name,checked}=target;
    if(checked && addForm.tag !==name){
      setAddForm({ ...addForm, tag: name})
    }else{
      setAddForm({ ...addForm, tag: ""})
    }
    
  }

  const selectedRegionIds = stocksForm.map((stock) => stock.region.id);
  // const filteredRegions = regions.filter(
  //   (region) => !selectedRegionIds.includes(region._id)
  // );

  return (
    <div className={commonstyles.management_contents}>
      {/* Header */}
      <SearchComponents />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            onClick={props.handleClose}
            className="cursor-pointer"
            title="Back"
            alt=""
          />
          Add Product
        </h1>
        <div>
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={addForm.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          {/* <button
            className={`${commonstyles.btn} ${commonstyles.btn_primary} d-inline-block`}
            onClick={handleFormSubmit}
          ></button> */}
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: props.handleClose,
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: isLoading.submit,
              onClick: handleFormSubmit,
            }}
          >
            {isLoading.submit ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {/* Header ends */}

      <div className={commonstyles.management_details}>
        <div className={commonstyles.management_details_contents}>
          <div className="row">
            {/* Name */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Product Name{" "}
                <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                  *
                </span>
              </label>

              <InputComponent
                error={formErrors.name}
                className="form-control-lg"
                formControl={{
                  placeholder: "Type product name",
                  name: "name",
                  value: addForm.name,
                  onChange: handleFormInput,
                }}
              />
              {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
              <FormErrorText error={formErrors.name}/>
            </div>
            {/* Name ends */}
            
            {/* Slug */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Slug
                <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                  *
                </span>
              </label>
              <InputComponent
                error={formErrors.slug}
                className="form-control-lg"
                formControl={{
                  placeholder: "Type slug",
                  name: "slug",
                  value: addForm.slug,
                  onChange: handleFormInput,
                }}
              />
              <FormErrorText error={formErrors.slug} />
            </div>
            {/* Slug ends */}
            {/* Description */}
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>Description</label>
              <textarea
                className={`${commonstyles.form_control} form-control form-control-lg `}
                placeholder="Type product Description"
                rows="4"
                autoComplete="off"
                name="description"
                value={addForm.description}
                onChange={handleFormInput}
              />
              {/* <FormErrorText error={formErrors.description} /> */}
            </div>
            {/* Description ends */}
            {/* Schema Markup */}
            <div className="col-lg-12 mb-4">
              <label className={commonstyles.secondry_title}>Schema Markup</label>
              <textarea
                className={`${commonstyles.form_control} 
                form-control form-control-lg `}
                placeholder="Type product seo"
                rows="4"
                autoComplete="off"
                name="seoMarkup"
                value={addForm?.seoMarkup}
                onChange={handleFormInput}
              />
             {/*  <FormErrorText error={formErrors.description} /> */}
            </div>
            {/* Schema Markup ends */}
            {/* Classification */}
            <div className="col-lg-6 mb-4">
              <label className={commonstyles.secondry_title}>
                Classification
              </label>

              <InputComponent
                error={formErrors.classification}
                className="form-control-lg"
                formControl={{
                  placeholder: "Type classification",
                  name: "classification",
                  value: addForm.classification,
                  onChange: handleFormInput,
                }}
              />
              {/* <FormErrorText error={formErrors.classification} /> */}
            </div> 
            {/* Classification ends */}
            
            
            <div className="col-lg-6 mb-4">
            <div className={commonstyles.management_details_contents}>
              <div className={styles.upload_img_header}>
                <label className={commonstyles.secondry_title}>
                  Feature Image
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
              </div>

              <div className={commonstyles.management_details_contents}>
                <div className={styles.upload_img_header}></div>
                <div
                  className={`${commonstyles.management_details_form} ${styles.add_featureImage_card}`}
                >
                  {url ? (
                    <>
                      <ImagePreviewChip
                        url={url}
                        className={styles.added_image}
                        width="60px"
                        height="60px"
                        handleClose={removeFile}
                      />
                    </>
                  ) : (
                    <div className={` ${styles.upload_img} text-center`}>
                      <div>
                        <label
                          htmlFor="categoryFileInput"
                          className={`${styles.btn} px-2 `}
                        >
                          Add file
                          <InputComponent
                            className="form-control-lg"
                            formControl={{
                              type: "file",
                              name: "url",
                              style:{cursor: "pointer" },
                              accept: "image/jpeg, image/png, image/svg+xml",
                              id: "categoryFileInput",
                              onChange: (e) => handleImageInput(e),
                            }}
                            error={formErrors.feature_image}
                          />
                          <FormErrorText
                            error={formErrors.feature_image ? "Required" : ""}
                          />
                        </label>
                      </div>

                      <span>or drop a file to Upload</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>
            
            {/* Image upload */}
            <ProductMediaUploadForm
              localFileData={localFileData}
              gallery={addForm.gallery}
              updateMediaForm={handleMediaUpdate}
              // formErrors={formErrors.gallery}
            />
            {/* Image upload ends */}
            {/*  */}
            <div className={`${styles.mt_32} col-lg-6`}>
              <div className="row">
                {/* Tally ID */}
                <div className="col-lg-6">
                  <div className={`${commonstyles.management_details_form} mb-4`}>
                    <label htmlFor="" className={commonstyles.secondry_title}>
                      Tally ID
                      <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                      </span>
                    </label>
                    <InputComponent
                      error={formErrors.tallyId}
                      className="form-control-lg"
                      formControl={{
                        placeholder: "Type tally Id",
                        name: "tallyId",
                        value: addForm.tallyId,
                        onChange: handleFormInput,
                      }}
                    />
                    <FormErrorText error={formErrors.tallyId} />
                  </div>
                </div>
                {/* Tally ID ends*/}
                {/* Most desirable */}
                {/* <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label htmlFor="" className={commonstyles.secondry_title}>
                      Most Desirable
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="most_desirable"
                        className={styles.onoffswitch_checkbox}
                        id="switchMostDesirable"
                        onChange={handleFormInput}
                        checked={addForm.most_desirable}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchMostDesirable"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* Most desirable ends*/}
                {/* Handpicked*/}
                {/* <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label className={commonstyles.secondry_title} htmlFor="">
                      Handpicked
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="handpicked"
                        className={styles.onoffswitch_checkbox}
                        id="switchHandpicked"
                        onChange={handleFormInput}
                        checked={addForm.handpicked}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchHandpicked"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* Handpicked ends*/}
                {/* Trending*/}
                {/* <div className="col-lg-6">
                  <div
                    className={`${commonstyles.management_details_form} ${styles.label_toggle}`}
                  >
                    <label className={commonstyles.secondry_title} htmlFor="">
                      Trending
                    </label>
                    <div className={`${styles.onoffswitch} ${styles.yes_no}`}>
                      <input
                        type="checkbox"
                        name="trending"
                        className={styles.onoffswitch_checkbox}
                        id="switchTrending"
                        onChange={handleFormInput}
                        checked={addForm.trending}
                      />
                      <label
                        className={styles.onoffswitch_label}
                        htmlFor="switchTrending"
                      >
                        <span className={styles.onoffswitch_inner}></span>
                        <span className={styles.onoffswitch_switch}></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* Trending ends*/}
              </div>
            </div>
            {/*  */}

            {/* Stocks */}
            <div className="col-lg-12 mb-4">
              <h4 className="theme-form-section-title">Inventory</h4>
              <div className={`${commonstyles.management_details_form} ml-0`}>
                {Array.isArray(stocksForm) &&
                  stocksForm.map((stock, indx, arr) => (
                    <ProductRegionDetailFormComponent
                      key={indx}
                      regions={regions}
                      selectedRegions={selectedRegionIds}
                      form={stock}
                      formErrors={formErrors.stocks?.[indx] || {}}
                      handleFormUpdate={(stock) => updateStock(stock, indx)}
                      isLastItem={arr.length === 1}
                      // handleNewForm={addNewRegion}
                      handleRemoveForm={() => removeRegion(indx)}
                    />
                  ))}
                <div className="col-lg-12 text-right add_text">
                  <span className="theme-btn-link" onClick={addNewRegion}>
                    Add Warehouse
                  </span>
                </div>
              </div>
            </div>

            {/* Stocks ends*/}

            {/*Product  details */}
            <ProductDetailsFormComponent
              form={productDetailsForm}
              formErrors={formErrors}
              handleProductFormUpdate={updateProductDetails}
            />
            {/*Product  details ends*/}

            {/* Product options */}
            <ProductOptionsFormComponent
              refsDim={ServicesRefDim}
              refs={ServicesRef}
              options={productOptions}
              handleUpdate={updateProductOption}
              //formErrors={formErrors.productOption || {}}
            />
            {/* Product options ends */}

            {/* Categories */}
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">
                  Categories
                  {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span> */}
                </label>
                <ProductCategoryTreeSelectComponent
                  //value={addForm.categoryId}
                  value={filterCategoryIds}
                  category={categoryNames}
                  handleChange={(v) => {
                    setAddForm({ ...addForm, categoryId: v });
                  }}
                />
              </div>
            </div>
            {/* Categories ends */}

            {/* Tag */}
            <div className="col-lg-6 mb-4">
              <div
                className={`${commonstyles.management_details_form} ${
                  formErrors.tag ? "theme-error-blk" : ""
                } pt-5  inline_radio h-100`}
              >
                <label className="ml-3 mr-3 theme-form-label">
                  Tag:{" "}
                 {/*  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span> */}
                </label>
                <div className="custom-control custom-radio custom-control-inline mr-4">
                  <input
                    type="radio"
                    id="rd_1"
                    name="New"
                    className={`custom-control-input`}
                    value="New"
                    checked={addForm.tag === "New"}
                    /* onChange={(e) =>
                      e.target.checked &&
                      setAddForm({ ...addForm, tag: "New" })
                    } */
                    onClick={(e)=>{handleTagChanges(e.target)}}
                  />
                  <label
                    className={`custom-control-label theme-form-label pt-1`}
                    htmlFor="rd_1"
                  >
                    <span>New</span>
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline mr-4">
                  <input
                    type="radio"
                    id="rd_2"
                    name="Best buy"
                    className={`custom-control-input`}
                    value="Best Bug"
                    checked={addForm.tag === "Best buy"}
                    /* onChange={(e) =>
                      e.target.checked &&
                      setAddForm({ ...editForm, tag: "Best buy" })
                    } */
                    onClick={(e)=>{handleTagChanges(e.target)}}
                  />
                  <label
                    className={`custom-control-label theme-form-label pt-1`}
                    htmlFor="rd_2"
                  >
                    <span>Best Buy</span>
                  </label>
                </div>
                {/* <FormErrorText error={formErrors.tag} /> */}
              </div>
            </div>
            {/* Tag ends */}

            {/* Manufacturer */}
            <div className="col-lg-6">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Manufacturer</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.manufacturer}
                  formControl={{
                    placeholder: "Type Manufacturer",
                    name: "manufacturer",
                    value: addForm?.manufacturer,
                    onChange: handleFormInput,
                  }}
                />
                {<FormErrorText error={formErrors.manufacturer} />}
              </div>
            </div>
            {/* Manufacturer ends */}
            {/* Packedby (GST) */}
            <div className="col-lg-6">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Packedby</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.packedby}
                  formControl={{
                    //type:"number",
                    placeholder: "Type Packed",
                    name: "packedby",
                    value: addForm?.packedby,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.packedby} />
              </div>
            </div>
            {/* Packedby ends */}

            {/* weight */}
            <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Weight</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.weight}
                  formControl={{
                    placeholder: "Type Weight",
                    name: "weight",
                    value: addForm?.weight,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.weight} />
              </div>
            </div>
            {/* weight ends */}
            {/* Number of packs (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Number of packs</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.numberofpacks}
                  formControl={{
                    //type:"number",
                    placeholder: "Type Number of packs",
                    name: "numberofpacks",
                    value: addForm?.numberofpacks,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.numberofpacks} />
              </div>
            </div>
            {/* Number of packs ends */}
            
            {/* Country of origin (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Country of origin</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.countryoforigin}
                  formControl={{
                    //type:"number",
                    placeholder: "Type country",
                    name: "countryoforigin",
                    value: addForm?.countryoforigin,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.countryoforigin} />
              </div>
            </div>
            {/* Country of origin ends */}

            {/* HSN */}
            <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">HSN</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.hsn}
                  formControl={{
                    placeholder: "Type HSN",
                    name: "hsn",
                    value: addForm?.hsn,
                    onChange: handleFormInput,
                  }}
                />
                {/* <FormErrorText error={formErrors.hsn} /> */}
              </div>
            </div>
            {/* HSN ends */}
            {/* LineTax (GST) */}
            <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">GST
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.lineTax}
                  formControl={{
                    //type:"number",
                    placeholder: "Type GST",
                    name: "lineTax",
                    value: addForm?.lineTax,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.lineTax} />
              </div>
            </div>
            {/* LineTax (GST) ends */}
            {/* unit of measure */}
            {/* <div className="col-lg-6 mt-4">
              <div className={commonstyles.management_details_form}>
                <label className="theme-form-label">Unit of measure</label>
                <InputComponent
                  className="form-control-lg"
                  error={formErrors.uom}
                  formControl={{
                    placeholder: "Type unit of measure",
                    name: "uom",
                    value: addForm.uom,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.uom} />
              </div>
            </div> */}
            {/* unit of measure ends */}
            {/* Faq  */}
            <div className="col-lg-12 mt-4 mb-5">
              <h4 className="theme-form-section-title">FAQ</h4>
              <div className={commonstyles.management_details_form}>
                {Array.isArray(addForm.faq) &&
                  addForm.faq.map((faq, indx) => (
                    <FaqFormComponent
                      form={faq}
                      key={indx}
                      //formErrors={formErrors.faq?.[indx] || {}}
                      number={indx + 1}
                      handleRemove={() => removeFaq(indx)}
                      handleUpdate={(val) => updateFaq(val, indx)}
                    />
                  ))}
                <div className="col-lg-12 text-right add_text theme-btn-link">
                  <span onClick={addFaq}>Add</span>
                </div>
              </div>
            </div>
            {/* Faq ends */}
            {/* You may like */}
            <RelatedProductSelectFormComponent
              title="You may also like"
              formField="youmaylike"
              isVisible={componentVisibility.youmaylike}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={addForm.youmaylike}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* You may like ends*/}

            {/* Similar Products */}
            <RelatedProductSelectFormComponent
              title="Similar Products"
              formField="similar"
              isVisible={componentVisibility.similar}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={addForm.similar}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* Similar Products ends*/}

            {/* Bought together */}
            <RelatedProductSelectFormComponent
              title="Bought together"
              formField="boughtTogether"
              isVisible={componentVisibility.boughtTogether}
              handleComponentVisibility={updateComponentVisibility}
              products={regionalProducts}
              selectedProducts={addForm.boughtTogether}
              updateSelectedProducts={updateSelectedProducts}
            />
            {/* Bought together ends*/}
          </div>
        </div>
      </div>
    </div>
  );
}
