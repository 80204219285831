import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import { Link } from "react-router-dom";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import dots from "../../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import moment from "moment";
import { upperCase, upperFirst } from "lodash";
import {
  Dropdown,
  Menu,
  message as massages,
  notification,
  Tooltip,
} from "antd";
import { arrayMove } from "react-movable";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import adminProductDelete from "../../../../assets/images/admin_delete.svg";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import styles from "../../../../styles/PaymentNew.module.css";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import * as XLSX from "xlsx";
import Tabs from "./Tabs";
const Tabs_ = [
  {
    id: 1,
    key: 1,
    name: "Email Templates",
    link: "communications",
    isActive: true,
  },
  {
    id: 2,
    key: 2,
    name: "Email Logs",
    link: "communications/emailogs",
    isActive: false,
  },
  {
    id: 3,
    key: 3,
    name: "Newsletter Templates",
    link: "communications/newstemplate",
    isActive: false,
  },
  {
    id: 4,
    key: 4,
    name: "Newsletter Subscribers",
    link: "communications/newssubs",
    isActive: false,
  },
  {
    id: 5,
    key: 5,
    name: "SMS Templates",
    link: "communications/smstemplates",
    isActive: false,
  },
  // {
  //   id: 6,
  //   key: 6,
  //   name: "SMS Logs",
  //   link: "smslogs",
  //   isActive: false,
  // },
];
const pageSizes = [10, 20, 50];
const defaultPageSize = 10;

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Template", value: "template" },
  { _id: 3, status: 1, name: "Start Date", value: "Start Date" },
  { _id: 4, status: 1, name: "Added Date", value: "Added Date" },
  { _id: 5, status: 1, name: "Updated Date", value: "Updated Date" },
  { _id: 6, status: 1, name: "Subject", value: "subject" },
  { _id: 7, status: 1, name: "Action", value: "action" },
];

const filters = [
  { id: 2, isChecked: false, name: "Added Date", value: "date" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function OrderManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();
  const { state } = history.location;
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [pagination, setPagination] = useState({});
  // const [regionId, setRegionId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const pdfExportComponent = useRef(null);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [buttons, setButtons] = useState(Tabs_);
  const [activeBtnName, setActiveBtnName] = useState("Email Templates");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [visible, setVisible] = useState(false);
  const [sortByDate, setSortorder] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [columns, setColumns] = useState(
    Tabs.map((tab) => tab.id == 2) && [
      // {
      //   visible: false,
      //   key: "select",
      //   render: (text, record, index) => (
      //     <input
      //       style={{ cursor: "pointer" }}
      //       type="checkbox"
      //       // checked={record.isChecked}
      //       // onChange={(e) => childCheckbox(e, record)}
      //     ></input>
      //   ),
      // },
      {
        title: "Template",
        visible: false,
        key: "template",
        render: (text, record, index) => (
          <span>{upperFirst(record.title)}</span>
        ),
      },
      {
        title: "Start Date",
        visible: false,
        key: "Start Date",
        render: (text, record, index) => (
          <span>{moment(record.startDate).format("DD-MM-YYYY | hh:mm A")}</span>
        ),
        sorter: true,
      },
      {
        title: "Added Date",
        visible: false,
        key: "Added Date",
        render: (text, record, index) => (
          <span>{moment(record.createdAt).format("DD-MM-YYYY | hh:mm A")}</span>
        ),
        sorter: true,
      },
      {
        title: "Updated Date",
        visible: false,
        key: "Updated Date",
        render: (text, record, index) => (
          <span>{moment(record.updatedAt).format("DD-MM-YYYY | hh:mm A")}</span>
        ),
      },
      {
        title: "Subject",
        visible: false,
        key: "subject",
        render: (text, record, index) => (
          <span>{upperFirst(record.subject)}</span>
        ),
      },
      {
        title: "Action",
        visible: false,
        key: "action",
        render: (text, record, index) =>
          permissionsArr.length > 0 &&
          ( permissionsArr.find((x) => x === "Marketing-Communication-view") === "Marketing-Communication-view" ||
          permissionsArr.find((x) => x === "Marketing-Communication-edit") === "Marketing-Communication-edit" ) && (
            <span>
              <Tooltip
                placement="topRight"
                title="Edit newsletter"
              // className="tooltip_list"
              >
                <img
                  src={adminProductEdit}
                  className={commonstyles.admin_table_edit_img}
                  onClick={() => edit(record)}
                  alt="Edit action icon"
                  // title="Edit Newsletter"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
              { permissionsArr.find((x) => x === "Marketing-Communication-edit") === "Marketing-Communication-edit" && <Tooltip
                placement="topRight"
                title="Delete newsletter"
              // className="tooltip_list"
              >
                <img
                  src={adminProductDelete}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                  onClick={() => showdelete(record)}
                  alt="Delete order action icon"
                // title="Delete Newsletter"
                />
              </Tooltip> }
            </span>
          ),
      },
    ]
  );

  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedEmailTemp, setSelectedEamilTemp] = useState([]);

  const edit = (list) => {
    history.push({
      pathname: `/communications/addtemplate`,
      state: list,
    });
    console.log(list);
  };

  const showdelete = (record) => {
    setSelectedEamilTemp(record);
    setDeletePopup(true);
  };

  function cancelDelete() {
    setSelectedEamilTemp([]);
    setDeletePopup(false);
  }
  /**
   * @author Rajkumar
   * To remove order
   */
  const removeOrder = () => {
    AxiosInstance(PORTS.INVOICE, "application/json")
      .get(`/admin/deleteMailTemplate/${selectedEmailTemp._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getOrderDetails(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => { });
  };

  const childCheckbox = (e, record) => { };

  const parentCheckbox = (e) => { };

  useEffect(() => {
    set_new_header(headers);
    getOrderDetails(pageSize, nextPage, "");
    // getRegionList();
    if (state?.id) {
      Tabs_?.forEach((x) => {
        if (x.id === state.id) {
          x.isActive = true;
        } else {
          x.isActive = false;
        }
      });
      console.log(Tabs_);
      setButtons(Tabs_);
      setActiveBtnName(state.name);
    }
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };
  /**
   * @author Rajkumar
   * @param {pageSize, nextPage, filterarr, type}
   * To get order details
   */
  function getOrderDetails(pageSize, nextPage, filterarr, type) {
    if (!type) {
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      type: 1,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (search !== "") {
      if (filterarr && filterarr.search !== "") {
        filterOptions.search = search;
      }
    }
    if (filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else {
      filterOptions.filterDays = null;
    }
    if(type==true && filterarr?.tableFilter==true){
      filterOptions.filterDays = (filterDays && filterDays!="custom")?filterDays:null
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortByDate !== "") {
      filterOptions.sortOrder = Number(filterarr.sortByDate);
    } else if (filteredDate.sortByDate !== "") {
      if (filterarr && sortByDate !== "") {
        filterOptions.sortOrder = Number(sortByDate);
      }
    }
    // if (filterarr && filterarr.filterDays) {
    //   filterOptions.filterDays = Number(filterarr.filterDays);
    // } else if (filteredDate.filterDays !== "") {
    //   if (filterarr && filterarr.filterDays !== "") {
    //     filterOptions.filterDays = Number(filterDays);
    //   }
    // }

    AxiosInstance(8003, "application/json")
      .post("/admin/listTemplate ", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem("orderList", JSON.stringify(res.data.data));
          setOrderList(res.data.data);

          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    let filter = { sortOrder: 2 };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getOrderDetails(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getOrderDetails(pageSize, value, filter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function handleInput(e, type) {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1,
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getOrderDetails(pageSize, 1, filter);
  }
  /**
   * @author Rajkumar
   * To get region list
   */
  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function filterOnChange(name, value) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
    };
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      // regionId: regionId,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: null
      // sortByDate: sortByDate,
    };
    debounceSearch(updatedFilter);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    console.log("items", itemClone);
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      // regionId: regionId,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      // sortByDate: sortByDate,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        // if (item.value === "region") {
        //   updatedFilter.regionId = "";
        //   setRegionId("");
        // }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {/* {localStorage.getItem("role") == "super_admin" &&
              selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && (
                <div className="card_dropdown show col-md-6">
                  <select
                    className="form_control"
                    name="regionId"
                    value={regionId}
                    onChange={filterOnChange}
                  >
                    <option value="">All Region</option>
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <option key={region._id} value={region._id}>
                            {region.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              )} */}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                      className="form_control"
                      name="filterDays"
                      value={filterDays}
                      onChange={filterOnChange}
                    >
                      <option value="">Order Date</option>
                      {Array.isArray(filterdayArr)
                        ? filterdayArr.map((day) => (
                            <option key={day._id} value={day.value}>
                              {day.name}
                            </option>
                          ))
                        : null}
                    </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() => filterOnChange("filterDays", day)}
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      // regionId: regionId,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortByDate: sorter.order === "descend" ? 1 : 0,
      tableFilter:true
    };
    setSortorder(sorter.order === "descend" ? 1 : 0);
    setNextPage(1);
    getOrderDetails(pageSize, nextPage, updatedFilter, true);
  };
  const selectedBtn = (tab) => {
    console.log(tab);
    const cloneButtons = [...buttons];
    cloneButtons?.forEach((x) => {
      if (x.id === tab.id) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
    });
    setButtons(cloneButtons);
    setActiveBtnName(tab.name);
    // history.push(`/${tab.link}`);
    history.push({
      pathname: `/${tab.link}`,
      state: tab,
    });
  };
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const exportToCSV = async (e) => {
    let filterOptions = {
      type: 1,
    };
    if (!filterOptions.search) delete filterOptions.search;
    if (filterOptions.sortOrder === null || filterOptions?.sortOrder === 2)
      delete filterOptions.sortOrder;
    if (!filterOptions.startDate) delete filterOptions.startDate;
    if (!filterOptions.endDate) delete filterOptions.endDate;
    AxiosInstance(8003, "application/json")
      .post("admin/exportTemplate", filterOptions)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          let data = response.data;
          data?.forEach((item) => {
            if (item["updated_Date"]) {
              item["updated_Date"] = moment(item["updated_Date"]).format(
                "MMM Do YYYY, h:mm:ss A"
              );
            }
          });
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"]?.v);
          ws["B1"].v = upperCase(ws["B1"]?.v);
          ws["C1"].v = upperCase(ws["C1"]?.v);
          ws["D1"].v = upperCase(ws["D1"]?.v);
          ws["E1"].v = upperCase(ws["E1"]?.v);
          // ws["F1"].v = upperCase(ws["F1"]?.v);
          //   ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `newsletter-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
        }
      })
      .catch((err) => {
        return err;
      });
  };

  function searchClick() {
    let updatedFilter = {};
    debounceSearch(updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Communications
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Marketing-Communication-view") ===
          "Marketing-Communication-view" && (
            <div >
            <span className={commonstyles.management_header_downloads_text}>
              <Tooltip
                placement="topRight"
                title="Download pdf"
              >
                <img className={commonstyles.export_icon} src={pdf_icon} style={{ cursor: "pointer" }} onClick={(e) => exportToPDF(e)} />
              </Tooltip>
              <Tooltip
                placement="topRight"
                title="Download excel"
              >
                <img src={excelIcon} className={commonstyles.management_header_downloads_icon} alt="icon" style={{ cursor: "pointer" }} onClick={(e) => exportToCSV(e)} />
              </Tooltip>
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
          )}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Communication-add") ===
        "Marketing-Communication-add" && (
          <div className={commonstyles.management_header}>
            <ButtonComponent
              className="rnd pl-3 pr-4 outline_btn"
              attributes={{
                onClick: () => {
                  history.push(`/communications/addtemplate`);
                },
              }}
            >
              Add Template Information
            </ButtonComponent>
          </div>
        )}
      <div
        className={`${commonstyles.management_header_tabs} ${commonstyles.communication_tabs_btn} justify-content-start`}
      >
        {buttons?.length > 0 &&
          buttons.map((tab) => (
            <button
              key={tab.id}
              className={`${commonstyles.sidebarButton} 
              ${tab.isActive && commonstyles.sidebarButton_active} 
              d-inline-block mb-0 mr-2`}
              onClick={() => selectedBtn(tab)}
            >
              {tab.name}
            </button>
          ))}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Communication-view") ===
        "Marketing-Communication-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by template name...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: handleInput,
                  ["aria-label"]: "Search category",
                  ["aria-describedby"]: "search-category",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <Dropdown
              overlay={filterMenu}
              className="usermanage_table_filter_columns ml-3"
              onVisibleChange={handleVisibleChange} 
              visible={visible}
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Communication-view") ===
        "Marketing-Communication-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            {/* {!isLoading && orderList.length > 0 && (
          <input
            type="checkbox"
            className={`${commonstyles.parentCheckbox}`}
            // onChange={parentCheckbox}
          ></input>
        )} */}
            {isLoading === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching news letter template details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`Newsletter for ${new Date().getFullYear()}`}
                author="Damro Team"
              >
                <AntTableComponent
                  className="order_table"
                  tableProps={{
                    columns: columns,
                    dataSource: orderList,
                    pagination: false,
                    onChange: handleTableChange,
                    components: {
                      header: {
                        row: (props) => renderHeader(props),
                      },
                      body: {
                        row: (props) => renderBody(props),
                      },
                    },
                  }}
                />
              </PDFExport>
            )}
          </div>
        )}
      {!isLoading && Array.isArray(orderList) && orderList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Marketing-Communication-view") ===
        "Marketing-Communication-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
      {/* EMAIL TEMPLATE delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: deletePopup,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeOrder}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
      {/* EMAIL TEMPLATE delete confirmation modal ends*/}
    </div>
  );
}
