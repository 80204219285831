import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import FormErrorText from "./FormErrorText";

export default function FaqFormComponent(props) {
  const { form, formErrors } = props;

  function handleChange(e) {
    const { name, value } = e.target;
    const updated = { ...props.form };
    if (name) {
      updated[name] = value;
      props.handleUpdate(updated);
    }
  }

  return (
    <div className="row mb-4">
      <div className="col-lg-12 mb-2">
        <label className={commonstyles.secondry_title}>
          Question {props.number}
        </label>
        <input
          type="text"
          /* className={`${commonstyles.form_control}
          ${formErrors.Question && "theme-error-input"}
          form-control form-control-lg `} */
          //className={`${commonstyles.form_control} form-control form-control-lg `}
          className={props.isErrorFAQ==="FAQ"? `${commonstyles.form_control}
                  ${formErrors.Question && "theme-error-input"}
                  form-control form-control-lg ` : `${commonstyles.form_control} form-control form-control-lg `}
          placeholder="Type Question"
          autoComplete="off"
          name="Question"
          value={form.Question || form.question}
          onChange={handleChange}
        />
        {props.isErrorFAQ==="FAQ"? <FormErrorText error={formErrors.Question} /> :""}
      </div>

      <div className="col-lg-12">
        <label className={commonstyles.secondry_title}>Answer</label>
        <textarea
          rows="2"
          /* className={`${commonstyles.form_control}
          ${formErrors.Answer && "theme-error-input"}
          form-control form-control-lg `} */
          // className={`${commonstyles.form_control} form-control form-control-lg `}
          className={props.isErrorFAQ==="FAQ"? `${commonstyles.form_control}
                  ${formErrors.Answer && "theme-error-input"}
                  form-control form-control-lg ` : `${commonstyles.form_control} form-control form-control-lg `}
          placeholder="Type Question"
          autoComplete="off"
          name="Answer"
          value={form.Answer || form.answer}
          onChange={handleChange}
        />
        {props.isErrorFAQ==="FAQ"?<FormErrorText error={formErrors.Answer} /> :""}
      </div>
      <div className="col-lg-12 text-right add_text mt-3 theme-btn-link text-danger">
        <span onClick={props.handleRemove}>Remove</span>
      </div>
    </div>
  );
}
