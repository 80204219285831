import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonComponent from "./ButtonComponent";
import styles from "../../../styles/globals.css";

export default function ConfirmationBox(props) {
  return (
    <Modal {...props.modal} onHide={props.handleCancel} contentClassName="custom_content" dialogClassName="custom_dialog">
      <Modal.Header style={{alignSelf:"center",borderBottom:"none",padding:"inherit"}}>
        <Modal.Title style={{alignSelf:"center"}}>
          <span className="theme-form-section-title">{props.title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ><span style={{whiteSpace:"nowrap"}}>{props.children || props.body}</span></Modal.Body>
      <Modal.Footer style={{justifyContent:"center",borderTop:"none"}}>
        <ButtonComponent
          className="btn-sm theme-btn-outlined modal_cancel_button"
          attributes={{ onClick: props.handleCancel }}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          className="modal_success_button custom-clear-all-btn"
          attributes = {{ 
            onClick: props.handleConfirm,
          }}
        >
          {props.confirmText || "Confirm"}
        </ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}
