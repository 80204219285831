import { React, useState, useEffect, useRef } from "react";
import commonstyles from "../../../../styles/Management_Common.module.css";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  Switch,
  message as massages,
  Select,
  notification,
  Empty,
} from "antd";
import { arrayMove } from "react-movable";
import deleteIcon from "../../../../assets/images/trash.svg";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import Search from "../../../../assets/images/search_icon.svg";
import styles from "../../../../styles/CustomerManagement.module.css";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SearchComponent from "../../SearchManagement/SearchComponent";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import excel from "../../../../assets/images/excel.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Title", value: "title" },
  { _id: 3, status: 1, name: "Product Name", value: "productname" },
  { _id: 4, status: 1, name: "Catagory", value: "categoryName" },
  // { _id: 5, status: 1, name: "Region", value: "region" },
  { _id: 7, status: 1, name: "Operate", value: "operate" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const filters = [
  { id: 2, isChecked: false, name: "Date", value: "date" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

function StoreManagement() {
const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [productList, setProductList] = useState([]);
  const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Warehouse");
  const [statuus, setStatuus] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [startStock, setStartStock] = useState("");
  const [endStock, setEndStock] = useState("");
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filteredResult, setFilteredResult] = useState("");

  const history = useHistory();
  const [sortOrder, setSortorder] = useState(0);

  const [productNameOrder, setSortProductName] = useState();
  const [categoryNameOrder, setSortCategoryName] = useState();
  const [regionOrder,setSortRegion] = useState();

  const [filterDays, setFilterDays] = useState("");
  const [filterDaysDefault, setFilterDaysDefault] = useState("Date");
  const [startDates, setStartDates]=useState("")
  const [endDates, setEndDates]=useState("")

  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });

  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [tabSelected, setTabSelected] = useState("1");
  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Title",
      visible: false,
      key: "title",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>
          {record.title ? record.title : "-"}
        </td>
      ),
      sorter: (a, b) => a.title - b.title,
    },
    {
      title: "Product Name",
      visible: false,
      key: "productname",
      render: (text, record, index) => (
        <td>
          {record.productsName.length === 1 && record.productsName[0]}
          {record.productsName.length === 2 &&
            record.productsName[0] + "," + record.productsName[1]}
          {record.productsName.length === 3 &&
            record.productsName[0] +
            "," +
            record.productsName[1] +
            "," +
            record.productsName[2]}
          {record.productsName.length > 3 &&
            record.productsName[0] +
            "," +
            record.productsName[1] +
            "," +
            record.productsName[2] +
            ",..."}
        </td>
      ),
      sorter:true
    },
    {
      title: "Category Name",
      visible: false,
      key: "categoryName",
      sorter:true,
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>
          {record.categories ? record.categories : "-"}
        </td>
      ),
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      sorter:true,
      render: (text, record, index) => (
        <span>
          {record.regionsName[0]}{" "}
          {/* {',' +  record?.regionsName[1]? record?.regionsName[1]:''} ... */}
        </span>
      ),
    },
    {
      title: "Operate",
      visible: false,
      key: "operate",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Collection-edit") ===
        "Catalog-Collection-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit decor"
            // className="tooltip_list"
            >
              <Link to={`/catalog/decors/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                  // onClick={() => editProduct(product)}
                  alt="Edit action icon"
                  action="Edit decor"
                // title="Edit decor"
                />
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete decor"
            >
              <img
                src={deleteIcon}
                className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                // onClick={() => showdelete(record)}
                onClick={() => showDeleteConfirmation(record)}
                alt="Delete decor action icon"
              // title="Delete decor"
              />
            </Tooltip>
          </td>
        ),
    },
  ]);
  useEffect(() => {
    set_new_header(headers);
    let searchNow = "";
    getCollection(pageSize, nextPage, searchNow);
    getRegionList();
    localStorage.removeItem("selectedCollection");
    return () => {
      localStorage.removeItem("collectionList");
      localStorage.removeItem("selectedCollection");
    };
  }, []);

  function showDeleteConfirmation(decorInfo) {
    setSelectedCustomer(decorInfo);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  function cancelDelete() {
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  /**
 * @author Mithun Prabhu
 * @param {}
 * To delete collection calling delete API
 */
  function deleteDecor() {
    // console.log(record);
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`admin/catalog/deleteDecor/${selectedCustomer._id}`)
      .then((res) => {
        if (res.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getCollection(pageSize, nextPage, "");
        } else if (res.data.status === 400) {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Delete not possible",
          });
        }
      })
      .finally(() => {
        cancelDelete();
      });
  }


  useEffect(() => {
    localStorage.setItem("productList", JSON.stringify(productList));
    return () => {
      localStorage.removeItem("productList");
    };
  }, [productList]);


  /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("collectionList"));
    let cloneSelectedOrder = [];
    cloneOrderList?.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCollection")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCollection")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCollection",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("collectionList", JSON.stringify(cloneOrderList));
    setProductList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };


  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("collectionList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    localStorage.setItem("collectionList", JSON.stringify(cloneOrderList));
    setProductList(cloneOrderList);
  };

  function searchHandler(e) {
   
      const { value } = e.target;
      setSearch(value);

      if(!value){
        const updatedFilter = {
          search: value || "",
          nextpage: 1,
          filterDays:filterDays,
          sortOrder:sortOrder,
          regionId:regionId,
          regionOrder:regionOrder,
          productNameOrder:productNameOrder,
          categoryNameOrder:categoryNameOrder
        };
        debounceSearch(updatedFilter);
      }
  }



  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr}
   * CALLING GET COUPON  RECORDS
   */
  function getCollection(pageSize, nextPage, filterarr,type) {

    if (!type) {
      setIsLoading(true);
    }

    const filterOptions = {
      search: search,
      pagesize: pageSize,
      nextpage: nextPage,
    };

    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    } else if (!filterarr.search) {
      delete filterOptions.search;
    }
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else {
      delete filterarr.regionId;
    }
    if (filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else {
      filterOptions.filterDays = null;
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(
          filteredDate.sortOrder ? filteredDate.sortOrder : 0
        );
      }
    }

    if (filterarr && filterarr.productNameOrder !== "") {
      filterOptions.productNameOrder = Number(filterarr.productNameOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.productNameOrder = Number(sortOrder);
      }
    }
    if (filterarr && filterarr.categoryNameOrder !== "") {
      filterOptions.categoryNameOrder = Number(filterarr.categoryNameOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.categoryNameOrder = Number(sortOrder);
      }
    }

    if (filterarr && filterarr.regionOrder !== "") {
      filterOptions.regionOrder = Number(filterarr.regionOrder);
    } else if (sortOrder !== "" && sortOrder !== null) {
      if (filterarr && sortOrder !== "") {
        filterOptions.regionOrder = Number(sortOrder);
      }
    }

    const newStartDate = startDates.split("-").reverse().join("-");
    const newEndDate = endDates.split("-").reverse().join("-");
    if(filterarr && filterarr?.filterDays==="custom"){
      filterOptions.startDate = newStartDate?newStartDate:"";
      filterOptions.endDate = newEndDate?newEndDate:"";
    }
    setFilterOptionsGlobal(filterOptions);

  

    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("admin/catalog/getDecorList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          // if (res.data?.data?.length > 0) {
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem("collectionList", JSON.stringify(res.data.data));
          setProductList(res.data.data);
          setPagination(res.data.pagination);
          // setPagination({ ...pagination, total: reslt.totalProducts });
        } else {
          setProductList([]);
          setPagination({});
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Warehouse", value: "", _id: "" }
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            if (!accessregion) {
              let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
              if (!checkDuplicates) {
                filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
              }
            };
          };
        }
      })
      .catch((er) => {
        return er;
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
     * @author Mithun Prabhu
     * @param {props}
     * TO SHOW TABLE DATA BASED ON HEADER STATUS
     */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };


  /**
  * @author Mithun Prabhu
  * @param {oldIndex, newIndex}
  * This is for rearranging array of columns items according to checking nd unchecking
  */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };


  /**
  * @author Mithun Prabhu
  * @param {arr, oldIndex, newIndex}
  * This is for rearranging array of columns items according to checking nd unchecking
  */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
  * @author Mithun Prabhu
  * @param {items}
  * This is for fetching new items after checking and unchecking columns
  */
  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  /**
  * @author Mithun Prabhu
  * @param {e, id}
  * This is component for checking and unchecking columns in list page
  */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };


  /**
  * @author Mithun Prabhu
  * @param {Menu}
  * This is component for removing/adding columns with onClick event
  */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  function debounceSearch(filter) {
  
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getCollection(pageSize, 1, filter,true);
    setFilteredResult(filter);
    // }, 500);
  }

  function filterRegionChange(name, value) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder:sortOrder,
      categoryNameOrder:categoryNameOrder,
      productNameOrder:productNameOrder,
      regionOrder:regionOrder
    };
  
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }

    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      //   setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }

  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      sortOrder: sortOrder,
    };
    if (name === "filterDays") {
      updatedFilter.filterDays = value;
      setFilterDays(value);
    }
    if (name === "regionId") {
      updatedFilter.regionId = value;
      setRegionId(value);
      setRegionName(value?.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
      debounceSearch(updatedFilter);
    }
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      sortOrder:sortOrder,
      productNameOrder:productNameOrder,
      categoryNameOrder:categoryNameOrder,
      regionOrder:regionOrder
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCollection(value, 1, updatedFilter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCollection(pageSize, value, updatedFilter,true);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function addWareHousePage() {
    history.push(`/catalog/decors/add`);
  }

  /**
    * @author Mithun Prabhu
    * @param {date, dateString}
    * DATE RANGE ONCHANGE FUNCTION
    */
  const dateRangeOnchangeHandler = (date, dateString) => {
    setStartDates(dateString[0])
    setEndDates(dateString[1])
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,

      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
        sortOrder:sortOrder,
        regionOrder:regionOrder,
        productNameOrder:productNameOrder,
        categoryNameOrder:categoryNameOrder
    };
    debounceSearch(updatedFilter);
  };


  /**
   * @author Mithun Prabhu
   * @param {e, data}
   * This is common filter handler for all filters
   */
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
    };
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Warehouse");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysDefault("Date");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };


  /**
  * @author Mithun Prabhu
  * @param {filterMenu}
  * This is card component created for filter dropdown
  */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                   <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div> 
                   <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                        <span
                          key={region._id}
                          onClick={() =>
                            filterRegionChange("regionId", region)
                          }
                        >
                          <label>{region.name}</label>
                        </span>
                      ))
                      : null}
                  </div> 
                </div>
              )}

            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="filterDays"
                    value={filterDays}
                    onChange={filterOnChange}
                  >
                    <option value="">Date</option>
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <option key={day._id} value={day.value}>
                          {day.name}
                        </option>
                      ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysDefault}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span
                          key={day._id}
                          onClick={() =>
                            filterRegionChange("filterDays", day)
                          }
                        >
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {/* <div className={`${commonstyles.card_dropdown} ${commonstyles.show} col-md-6`}>
                      <div className={`${commonstyles.filter_heading} ${commonstyles.card_dropdown_heading}`}>
                        Select Filter <img src={arrowDown} width={10} height={20} />
                      </div>
                      <div className={`${commonstyles.card_dropdown_contents}`}>
                        <span>Region</span>
                        <span>Date</span>
                        <span>Payment Status</span>
                        <span>Delivery Status</span>
                      </div>
                  </div> */}
          </div>
        </div>
      </div>
    </Menu>
  );

  /**
    * @author Mithun Prabhu
    * @param {event}
    * TO CALL EXPORT CSV API
    */
  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post(`/admin/catalog/exportDecorList`, params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach(item => {
            if (item['createdAt']) {
              item['createdAt'] = moment(item['createdAt']).format("MMM Do YYYY, h:mm:ss A")
            }
          })
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".csv";
          const ws = XLSX.utils.json_to_sheet(und_res);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");

          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Collection-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.csv`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {pagination, filters, sorter}
   * To give updatedObjects for API call
   */
  function handleTableChange(pagination, filters, sorter, extra) {
  
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
    };
    if (sorter?.columnKey === "title") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue);
      setSortProductName();
      setSortCategoryName();
      setSortRegion();
    }
    else if (sorter?.columnKey === "productname") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.productNameOrder = sortValue;
      setSortProductName(sortValue);
      setSortorder();
      setSortCategoryName();
      setSortRegion();
    } else if (sorter?.columnKey === "categoryName") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.categoryNameOrder = sortValue;
      setSortCategoryName(sortValue);
      setSortorder();
      setSortProductName();
      setSortRegion();
    }else if (sorter?.columnKey === "region") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.regionOrder = sortValue;
      setSortRegion(sortValue);
      setSortorder();
      setSortProductName();
      setSortCategoryName();
    }
    getCollection(pageSize, 1, updatedFilter, true);
  }

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      sortOrder:sortOrder,
      productNameOrder:productNameOrder,
      categoryNameOrder:categoryNameOrder,
      regionOrder:regionOrder
    };
    debounceSearch(updatedFilter);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Collections</h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Collection-view") ===
          "Catalog-Collection-view" && (
            <div>
              {/* <img
            className={commonstyles.export_icon}
            src={pdf_icon}
            style={{ cursor: "pointer" }}
            onClick={(e) => exportToPDF(e)}
          /> */}

              <span
                className={commonstyles.management_header_downloads_text}
                style={{
                  cursor: "pointer",
                }} /* onClick={ (e) => exportToCSV(e)} */
              >
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
                <span
                  className={commonstyles.management_header_downloads_text}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => exportToCSV(e)}
                >
                  <Tooltip placement="topRight" title="Download excel">
                    <img className={commonstyles.export_icon} src={excel} />
                  </Tooltip>
                  <span className={commonstyles.management_header_downloads_text_ex}>
                    Export <img src={exportIcon} alt="icon" />
                  </span>
                </span>
              </span>
            </div>
          )}
        {/* <div className={commonstyles.management_header}>
          <ButtonComponent
            className="rnd pl-3 pr-4 outline_btn"
            attributes={{
              onClick: addWareHousePage,
            }}
          >
            <img className={commonstyles.export_icon} src={excel} />
            Export
            <img src={exportIcon} alt="icon" />
          </ButtonComponent>
        </div> */}
      </div>

      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Collection-add") ===
        "Catalog-Collection-add" && (
          <div className={commonstyles.management_header}>
            <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              attributes={{
                onClick: addWareHousePage,
              }}
            >
              {/* <img src={plusIcon} className="mr-2" /> */}
              Add Collection
            </ButtonComponent>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Collection-view") ===
        "Catalog-Collection-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Title...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: (e) => searchHandler(e),
                  ["aria-label"]: "Search customers",
                  ["aria-describedby"]: "search-customers",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            // style={{ marginLeft: "100px" }}
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>

            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            // style={{ marginRight: "100px" }}
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Column <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Collection-view") ===
        "Catalog-Collection-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Collection Report for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {!IsLoading && productList?.length > 0 && (
                <input
                  type="checkbox"
                  className={`${commonstyles.parentCheckbox}`}
                  onChange={parentCheckbox}
                ></input>
              )}
              {IsLoading === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching collection details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                permissionsArr.length > 0 &&
                permissionsArr.find((x) => x === "Catalog-Collection-view") ===
                "Catalog-Collection-view" && (
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: productList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                )
              )}
            </div>
          </PDFExport>
        )}
      {!IsLoading && Array.isArray(productList) && productList.length
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Collection-view") ===
        "Catalog-Collection-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteDecor}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Category delete confirmation modal ends*/}
    </div>
  );
}

export default StoreManagement;
