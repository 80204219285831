import { useEffect, useRef, useState } from "react";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Switch,
  Tooltip,
} from "antd";
import moment from "moment";
import plusLynchIcon from "../../../../assets/images/plus_lynch_icon.svg";
import AddCategoryComponent from "./AddCategoryComponent";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import plusIcon from "../../../../assets/images/plus_white_icon.svg";
import excel from "../../../../assets/images/excel.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../../assets/images/export_icon.svg";
import deleteIcon from "../../../../assets/images/trash.svg";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import EditCategoryComponent from "./EditCategoryComponent";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import SearchComponent from "../../SearchManagement/SearchComponent";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { clone, upperCase, upperFirst } from "lodash";
// import { get } from "draft-js/lib/DefaultDraftBlockRenderMap";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import { fil } from "date-fns/locale";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];
const initialFilterState = { search: "", pagesize: 10, nextpage: 1 };

function getReadableDate(date) {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
  };
  return new Date(date).toLocaleString("en-US" /* , options */);
}

const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Category", value: "Category" },
  { _id: 3, status: 1, name: "Category Level", value: "Category Level" },
  { _id: 4, status: 1, name: "Trending", value: "Trending" },
  { _id: 5, status: 1, name: "Our Collection", value: "Our Collection" },
  { _id: 7, status: 1, name: "Sort Order", value: "Sort Order" },
  { _id: 6, status: 1, name: "Created At", value: "Created At" },

  // { _id: 7, status: 1, name: "Last updated At", value: "lastupdatedat" },
  // { _id: 8, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 9, status: 1, name: "Operate", value: "operate" },
];

const original_columns = [
  "categoryname",
  "categorylevel",
  "trending",
  "ourcollection",
  "Sort Order",
  "createdat",
  "lastupdatedat",
  "enable",
  "operate",
];
const filters = [{ id: 1, isChecked: false, name: "Date", value: "date" }];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: true },
  { _id: 2, isChecked: false, name: "Disable", value: false },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

export default function CategoryManagementComponent() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const [categoryData, setCategoryData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(initialFilterState);
  const [IsLoading, setIsLoading] = useState(true);
  const [visibility, setVisibility] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [originalColumn, setOriginalColumn] = useState(original_columns);
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [headers, setHeaders] = useState(originalHeaders);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [items, setItems] = useState(originalHeaders);
  const container = useRef(null);
  const [visible, setVisible] = useState(false);
  const pdfExportComponent = useRef(null);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [sortOrder, setSortorder] = useState(0);
  const [newsortOrder, setnewSortorder] = useState(0);
  const [createdAtOrder, setCreatedAtOrder] = useState();
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [sortConfig, setSortConfig] = useState({
    key: 'sortValue',
    direction: 'ascending'
  });
  
  const handleSortOrder = () => {
    // Toggle sort direction
    const newDirection = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    
    // Create a copy of the current category data and sort
    const sortedData = [...categoryData].sort((a, b) => {
      // Handle potential undefined or null values
      const valueA = a.sortValue ?? 0;
      const valueB = b.sortValue ?? 0;
      
      if (newDirection === 'ascending') {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    });
  
    // Update the category data and sort configuration
    setCategoryData(sortedData);
    setSortConfig({
      key: 'sortValue',
      direction: newDirection
    });
  };
  
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Category",
      visible: false,
      key: "Category",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}><Tooltip
        placement="topLeft"
        title={record.name ? record.name : "-"}
        // className="tooltip_list"
      >{record.name}</Tooltip></span>
      ),
      sorter: (a, b) => a.orderId - b.orderId,
    },
    {
      title: "Category Level",
      visible: false,
      key: "Category Level",
      render: (text, record, index) => (
        <span>
          {record.level === 0
            ? "Parent category"
            : record.level === 1
            ? "Sub category"
            : "Second sub category"}
        </span>
      ),
    },
    {
      title: "Trending",
      visible: false,
      key: "Trending",
      render: (text, record, index) => (
        <td>{record.trending ? "Yes" : "No"}</td>
      ),
    },
    {
      title: "Our Collection",
      visible: false,
      key: "Our Collection",
      render: (text, record, index) => (
        <td>{record.ourcollection ? "Yes" : "No"}</td>
      ),
    },
    {
      title: "Sort Order",
  
      visible: false,
      key: "Sort Order",
      render: (text, record, index) => (
        <td>
        {record.sortValue !== null &&
         record.sortValue !== undefined &&
         record.sortValue !== Number.MAX_VALUE
          ? record.sortValue
          : "-"}
      </td>
      
    //     <td>
    //     {record.sortValue === Number.MAX_VALUE || record.sortValue === null || record.sortValue === undefined
    //         ? "-"
    //         : record.sortValue}
    // </td>
        // <td>{record.sortValue && record.sortValue !== Number.MAX_VALUE ? record.sortValue : "-"}</td>

        // <span>{record.sortValue }</span>
      ),
      sorter:true
    
  
    },
    {
      title: "Created At",
      visible: false,
      key: "Created At",
      render: (text, record, index) => (
        <span>{getReadableDate(record.createdAt)}</span>
      ),
      sorter:true
    },
  
    {
      title: "Operate",
      visible: false,
      key: "operate",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Categories-edit") ===
          "Catalog-Categories-edit" && (
          <span>
            <Tooltip
              placement="topRight"
              title="Edit category"
              // className="tooltip_list"
            >
              <Link to={`/catalog/category/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} cursor-pointer`}
                  // onClick={() => editCategory(category)}
                  alt="Edit category"
                  // title="Edit category"
                />
              </Link>
            </Tooltip>
            <Tooltip
              placement="topRight"
              title="Delete category"
            >
            <img
              src={deleteIcon}
              className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
              onClick={() => showdelete(record)}
              alt="Delete category action icon"
              // title="Delete category"
            />
            </Tooltip>
          </span>
        ),
    },
  ]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

    /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneCatagoryList = JSON.parse(localStorage.getItem("categoryList"));
    let cloneSelectedOrder = [];
    cloneCatagoryList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("categoryList", JSON.stringify(cloneCatagoryList));
    setCategoryData(cloneCatagoryList);
    // setSelectedOrder(cloneSelectedOrder);
  };


  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    const { checked } = e.target;
    const cloneCatagoryList = JSON.parse(localStorage.getItem("categoryList"));
    cloneCatagoryList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneCatagoryList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneCatagoryList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("categoryList", JSON.stringify(cloneCatagoryList));
    setCategoryData(cloneCatagoryList);
  };

  useEffect(() => {
    set_new_header(headers);
    setIsLoading(true);
    getCategories(pageSize, nextPage, filter);
    localStorage.removeItem("selectedCustomerr");
    return () => {
      localStorage.removeItem("categoryList");
      localStorage.removeItem("selectedCustomerr");
    };
  }, []);

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function searchHandler(e) {
    const { value } = e.target;
    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: value || "",
        nextpage: 1,
        filterDays:filterDays,
        sortOrder:sortOrder,
       newsortOrder:newsortOrder,
        createdAtOrder:createdAtOrder
      };
      getCategories(pageSize, 1, updatedFilter,true);
    }
  }

   /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };


   /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCategory(values);
    }
  };


  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewCategory = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCategory(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );


   /**
   * @author Mithun Prabhu
   * @param {item}
   * This is for checking through different action objects and calling API for submit
   */
  const applyBulkActionHandler = (item) => {
    // console.log(item);
    if (localStorage.getItem("selectedCustomerr")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedCustomerr"));
      if (selectedOrder.length > 0) {
        let data = {
          categoryIds: selectedOrder,
          status: item.value == 0 ? 2 : 1,
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };


    /**
   * @author Mithun Prabhu
   * @param {data}
   * API call for bulk delete and bulk update coupons.
   */
  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/category/bulkUpdateStatus", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          getCategories(pageSize, nextPage, filter);
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedCustomerr");
          const cloneCatagoryList = JSON.parse(
            localStorage.getItem("categoryList")
          );
          cloneCatagoryList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem(
            "categoryList",
            JSON.stringify(cloneCatagoryList)
          );
          setCategoryData(cloneCatagoryList);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading(false);
      });
  };

 /**
   * @author Mithun Prabhu
   * @param {data}
   * This is for checking through different action objects and calling API for submit
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  function debounceSearch(filter) {
    setNextPage(1);
    filter.nextpage = 1;
    getCategories(pageSize, 1, filter,true);
  }

  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      sortOrder:sortOrder,
      newsortOrder:newsortOrder,
      createdAtOrder:createdAtOrder
    };
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      filterDays: filterDays,
      sortOrder:sortOrder,
      newsortOrder:newsortOrder,
      createdAtOrder:createdAtOrder
    };
    debounceSearch(updatedFilter);
  };

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };


  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                 
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterRegionChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );


   /**
   * @author Mithun Prabhu
   * @param {pagination, filters, sorter}
   * To give updatedObjects for API call
   */
  const handleTableChange = (pagination, filters, sorter) => {
    
    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
    };
    
    if (sorter?.columnKey === "Created At") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.createdAtOrder = sortValue;
      setCreatedAtOrder(sortValue); 
      setSortorder();
      setnewSortorder();
    }else if (sorter?.columnKey === "Category") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortorder(sortValue); 
      setnewSortorder();
      setCreatedAtOrder();
    }
    else if (sorter?.columnKey === "Sort Order") {
      // debugger;
      let sortValue;
      // let sortValue = sorter.order === "ascend" ? 0 : 1;
      if(sorter.order === "ascend"){
        sortValue=0
      }
      if(sorter.order === "descend"){
      sortValue=1
      }
   
      
      updatedFilter.newsortOrder = sortValue;
      console.log("sortValue",sortValue);
      
      setnewSortorder(sortValue); 
      setSortorder();
      setCreatedAtOrder();
    }
  
    // setNextPage(1);
    getCategories(pageSize, nextPage, updatedFilter,true);
  };


  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * CALLING GET CATEGORY  RECORDS
   */
  function getCategories(pageSize, nextPage, filterarr,type) {
    console.log("filterarr",filterarr);
    
    if(!type){
      setIsLoading(true);
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if (filterarr && filterarr.search) {
      filterOptions.search = filterarr.search;
    }
    // else if (search !== "") {
    //   if (filterarr && filterarr.search !== "") {
    //     filterOptions.search = filterarr.search;
    //   }

    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.filterDays) {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterarr && filterarr.filterDays !== "") {
        filterOptions.filterDays = Number(filteredDate.filterDays);
      }
    }
    if (filterarr && filterarr.sortOrder!== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
      console.log("filterOptions",filterOptions);
      
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(
          filteredDate.sortOrder ? filteredDate.sortOrder : 0
        );
      }
    }

    if (filterarr && filterarr.createdAtOrder !== "") {
      filterOptions.createdAtOrder = Number(filterarr.createdAtOrder);
    } else {
        delete filterOptions.createdAtOrder
    }
    if (filterarr && filterarr.newsortOrder !== "") {
      filterOptions.newsortOrder = Number(filterarr.newsortOrder);
    } else {
        delete filterOptions.newsortOrder
    }
    setFilterOptionsGlobal(filterOptions);


    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getCategoryList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          res.data.data.forEach((item, indx) => {
            item["key"] = indx + 1;
            item["isChecked"] = false;
          });
          localStorage.setItem("categoryList", JSON.stringify(res.data.data));
          setCategoryData(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          const { status } = er.response;
          // if(status === 403) {
          data.message = "Error";
          data.description = "Failed to get data";
          // } else if (status === 400){
          //   data.message
          // }
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handlePaginationChanges(value, type) {
    var tempFilter = filter;
    if (type === "pageSize") {
      setPageSize(value);
      tempFilter.pagesize = value;
      setNextPage(1);
      tempFilter.nextpage = 1;
      // setIsLoading(true);
      tempFilter.sortOrder = sortOrder
      tempFilter.newsortOrder=newsortOrder;
      tempFilter.createdAtOrder = createdAtOrder;
      getCategories(value, 1, filter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      // setIsLoading(true);
      tempFilter.nextpage = value;
      tempFilter.sortOrder = sortOrder
      tempFilter.newsortOrder=newsortOrder;
      tempFilter.createdAtOrder = createdAtOrder;
      getCategories(pageSize, value, filter,true);
    }
   
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function updateVisibility(type, value, success) {
    setVisibility({ ...visibility, [type]: value });
    if (success) {
      setIsLoading(true);
      getCategories(pageSize, 1, initialFilterState);
    }
  }

  function editCategory(category) {
    setSelectedCategory(category);
    setVisibility({ ...visibility, edit: true });
    window.scrollTo(0, 0);
  }

  function showdelete(category) {
    setSelectedCategory(category);
    updateVisibility("delete", true, undefined);
  }

  function cancelDelete() {
    setSelectedCategory({});
    updateVisibility("delete", false, undefined);
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  function removeCategory() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteCategory/${selectedCategory._id}`)
      .then((res) => {
   
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          setIsLoading(false);
          getCategories(pageSize, 1, initialFilterState);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete category",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete category",
        });
      })
      .finally(() => {
        updateVisibility("delete", false, undefined);
      });
  }
  if (visibility.add) {
    return (
      <AddCategoryComponent
        handleClose={(success) => updateVisibility("add", false, success)}
      />
    );
  } else if (visibility.edit) {
    return (
      <EditCategoryComponent
        category={selectedCategory}
        handleClose={(success) => updateVisibility("edit", false, success)}
      />
    );
  }

  /* Export here */
  const exportToCSV = async (e) => {
    // const filterOptions = {
    // };
    // if (search) {
    //   filterOptions.search = search;
    // }
    // if (filteredDate.startDate) {
    //   filterOptions.startDate = filteredDate.startDate;
    // }
    // if (filteredDate.endDate) {
    //   filterOptions.endDate = filteredDate.endDate;
    // }
    // if (filteredDate.filterDays) {
    //   filterOptions.filterDays = Number(filteredDate.filterDays);
    // }
    // if (sortOrder !== "") {
    //   filterOptions.sortOrder = Number(sortOrder);
    // }

    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;
  
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/exportCetegory", params)
      .then((resp) => {
        // console.log(resp);
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          /* data.map((item) => {
              item["Module Name"] = capitalize(item["Module Name"]);
            }); */
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          // console.log(ws);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"].v);
          /*  ws["G1"].v = upperCase(ws["G1"].v);
          ws["H1"].v = upperCase(ws["H1"].v); */

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Category-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportToPDF = () => {

    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    /* let element = container.current || document.body;
      savePDF(element, {
        paperSize: "auto",
        margin: 40,
        fileName: `Report for ${new Date().getFullYear()}`,
      }); */
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      sortOrder:sortOrder,
      newsortOrder:newsortOrder,
      createdAtOrder:createdAtOrder
    };
    debounceSearch(updatedFilter);
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Category Management
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Categories-view") ===
            "Catalog-Categories-view" && (
            <div>
              <Tooltip placement="topRight" title="Download pdf">
                <img
                  className={commonstyles.export_icon}
                  src={pdf_icon}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => exportToPDF(e)}
                />
              </Tooltip>
              <span
                className={commonstyles.management_header_downloads_text}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              >
                <Tooltip placement="topRight" title="Download excel">
                  <img className={commonstyles.export_icon} src={excel} />
                </Tooltip>
              <span className={commonstyles.management_header_downloads_text_ex}>
                Export <img src={exportIcon} alt="icon" />
              </span>
              </span>
            </div>
          )}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Categories-add") ===
          "Catalog-Categories-add" && (
          <div className={commonstyles.management_header}>
            <ButtonComponent
              className="rnd pl-3 pr-4 outline_btn"
              attributes={{
                onClick: () => {
                  updateVisibility("add", true);
                  window.scrollTo(0, 0);
                },
              }}
            >
              {/* <img src={plusIcon} className="mr-2" /> */}
              Add Category
            </ButtonComponent>
          </div>
        )}
      {/* <h1 className={ `${commonstyles.sample1} ${style.sample} sample`} >Sample</h1> */}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Categories-view") ===
          "Catalog-Categories-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              {/* <InputComponent
            className="with-icon rnd theme-txt-sm"
            aria-label="Search"
            formControl={{
              placeholder: "Search categories",
              name: "search",
              style: { height: "38px" },
              value: filter.search,
              onChange: handleInput,
            }}
          /> */}
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Category Name...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: searchHandler,
                  ["aria-label"]: "Search category",
                  ["aria-describedby"]: "search-category",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <div className="action ml-3">
              <div className="action_header">
                Action
                <img src={arrowDown} alt="image" />
              </div>
              <div className="action_contents">
                {bulkActionFilter.length > 0 &&
                  bulkActionFilter.map((item) => (
                    <div
                      className="action_contents_header"
                      key={item._id}
                      onClick={() => bulkStatusClickHandler(item)}
                    >
                      <h2
                        className={`action_contents_header_h2 ${
                          item.isChecked && "action_contents_header_h2_active"
                        }`}
                      >
                        {item.name}
                      </h2>
                      {item.isChecked && (
                        <span
                          className="action_contents_btn"
                          onClick={() => applyBulkActionHandler(item)}
                        >
                          Apply
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      <div>
        {/* <ButtonComponent
            className="theme-btn-outlined rnd mr-3"
            attributes={{
              onClick: () => {},
            }}
          >
            Export
          </ButtonComponent> */}
        {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: (e) => {
                exportToCSV(e);
                // setFilter(intialFilterState);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Export
          </ButtonComponent> */}
      </div>
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Categories-view") ===
          "Catalog-Categories-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Category Report for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {IsLoading === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching category details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                permissionsArr.length > 0 &&
                permissionsArr.find((x) => x === "Catalog-Categories-view") ===
                  "Catalog-Categories-view" && (
                  <div>
                    <input
                      type="checkbox"
                      className={`${commonstyles.parentCheckbox}`}
                      onChange={parentCheckbox}
                    ></input>
                    <AntTableComponent
                      className="order_table"
                      tableProps={{
                        columns: columns,
                        dataSource: categoryData,
                        pagination: false,
                        onChange: handleTableChange,
                        components: {
                          header: {
                            row: (props) => renderHeader(props),
                          },
                          body: {
                            row: (props) => renderBody(props),
                          },
                        },
                      }}
                    />
                  </div>
                )
              )}
            </div>

            {!IsLoading && Array.isArray(categoryData) && categoryData.length
              ? permissionsArr.length > 0 &&
                permissionsArr.find((x) => x === "Catalog-Categories-view") ===
                  "Catalog-Categories-view" && (
                  <Pagination
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    nextPage={nextPage}
                    data={pagination}
                    handlePaginationChanges={handlePaginationChanges}
                  />
                )
              : null}

            <ConfirmationBox
              modal={{
                show: visibility.delete,
                size: "md",
                centered: true,
              }}
              handleCancel={cancelDelete}
              handleConfirm={removeCategory}
              title="Confirm the action"
              confirmText="Delete"
            >
              <div className="container-fluid">
                <div className="row">
                  <p className="theme-txt-detail">
                  Are you sure want to delete?<br></br>
                  <span style={{color:'red'}}><b>Note:</b> It may affect the products under this category.</span>
                  </p>
                </div>
              </div>
            </ConfirmationBox>
            {/* Category delete confirmation modal ends*/}
          </PDFExport>
        )}
    </div>
  );
}
