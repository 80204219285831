import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import dots from "../../../assets/images/column_dots.svg";
import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import Pagination from "../../modules/ReusableComponent/Pagination";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import {
  Dropdown,
  Menu,
  Tooltip,
  message as massages,
  notification,
  Switch,
  Table
} from "antd";
import { arrayMove } from "react-movable";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import { PDFExport } from "@progress/kendo-react-pdf";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove as arraMoving,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const citiesHeader = [
  { _id: 1, status: 1, name: "City Name", value: "name" },
  { _id: 2, status: 1, name: "State", value: "state" },
  { _id: 3, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 4, status: 1, name: "Action", value: "Action" },
];

const filters = [
  { id: 1, isChecked: false, name: "State", value: "state" },
  // { id: 2, isChecked: false, name: "Date", value: "date" },
];






export default function CityManagement(props) {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const history = useHistory();
  const pdfExportComponent = useRef(null);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [headers, setHeaders] = useState(citiesHeader);
  const [items, setItems] = useState(citiesHeader);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filteredResult, setFilteredResult] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortOrder, setSortorder] = useState(null);
  const [isDeleteModule, setIsDeleteModule] = useState(false);
  const [seletedWareHuse, setSeletedWareHuse] = useState("");
  const [tabName, setTabName] = useState("Warehouse")
  const [states, setStates] = useState([])
  const [stateName, setStateName] = useState("All State")
  const citiesColumn = [

    {
      title: "City Name",
      visible: false,
      key: "name",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.name}</td>
      ),
      sorter: true
    },
    {
      title: "State",
      visible: false,
      key: "state",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.state}</td>
      ),
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "Action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-edit") ===
        "SiteSettings-Regions-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit city"
            >
              {/* <Link to={`/warehouse/edit/${record._id}`}> */}
              <img
                src={adminProductEdit}
                className={`${commonstyles.admin_table_edit_img} mr-2`}
                onClick={() => editRegion(record)}
                alt="Edit action icon"
                // title="Edit region"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
            {/* </Link> */}
            <Tooltip
              placement="topRight"
              title="Delete city"
            > <img
                src={adminProductDelete}
                className={commonstyles.admin_table_edit_img}
                onClick={() => deleteRegion(record)}
                alt="Delete action icon"
                // title="Delete region"
                style={{ cursor: "pointer" }}
              /></Tooltip>

          </td>
        ),
    },
  ]
  const [columns, setColumns] = useState(citiesColumn);






  function handleTableChange(pagination, filters, sorter, extra) {

    let sortValue = sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : sorter.order == undefined && null;

    setSortorder(sortValue);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: sortValue,
      stateFilter: stateName === "All State" ? "" : stateName,
    };

    getCityList(pageSize, 1, updatedFilter, true);
  }

  useEffect(() => {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: sortOrder,
      stateFilter: stateName === "All State" ? "" : stateName,
    };
    getCityList(pageSize, nextPage, updatedFilter);
    set_new_header(headers);
    // getWareHouseList(pageSize, nextPage, updatedFilter);
    getRegionList();  
  }, [props.activeTab]);
  useEffect(()=>{
    if(props?.saveDragAndDrop){
      const updateSortData = orderList.map((city,index) => {return {_id:city._id, sortValue:index+1}})
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .put(`/cities/sort`, {data:updateSortData})
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === "200" || response.status === 200)
          ) {
            showNotification({
              type: "success",
              message: "Success",
              description: response.msg,
            });
            setPageSize(10)
          } else if (
            response &&
            (response.status === "400" || response.status === 400)
          ) {
            showNotification({
              type: "error",
              message: "Error",
              description: response.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if(props?.sortActive){
      setPageSize(50)
      setNextPage(1)
      let data = {
        sortOrder: 2
      }
      getCityList(150, 1, data)
    }else{
      setPageSize(10)
      setNextPage(1)
      let data = {
        sortOrder: 2
      }
      getCityList(10, 1, data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props?.saveDragAndDrop, props?.sortActive])

  function getCityList(pageSize, nextPage, filterarr,type){
      if(!type){
        setIsLoading(true);
      }
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get(`/cities?pagesize=${pageSize}&nextpage=${nextPage}&search=${filterarr?.search || ""}&stateFilter=${filterarr?.stateFilter || ""}&sort=${filterarr?.sortOrder || ""}`)
        .then((res) => {
          if (res && res.data && res.status == "200") {
            setIsLoading(false);
            if (res.data?.data?.length > 0) {
              res.data.data.forEach((item, indx) => {
                item["key"] = indx + 1;
                item["isChecked"] = false;
              });
              setOrderList(res.data.data);
              const key = 'state';
              const uniqueValuesSet = new Set();
              res.data.filter.forEach(obj => {
                const value = obj[key];
                uniqueValuesSet.add(value);
              });
              const uniqueValuesArray = Array.from(uniqueValuesSet);
              setStates(uniqueValuesArray)
            } else {
              setOrderList([]);
            }
            setPagination(res.data.pagination);
          }
        })
        .catch((er) => {
          const data = { type: "error" };
          if (er.response) {
            data.message = "Error";
            data.description = "Failed to get data";
            setIsLoading(false);
            showNotification(data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
  }

  const toggleStatusOnChange = (e, showRoom) => {
          AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .put(
        `/cities/status`, {id: showRoom._id, enable: !showRoom.enable}
      )
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let updateFilter = {
            sortOrder: sortOrder,
            stateFilter: stateName === "All State" ? "" : stateName,
            search: search
          }
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getCityList(pageSize, nextPage, updateFilter)
        }
      })
      .catch((er) => {
        return er;
      });
  };

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };


  function handlePaginationChanges(value, type) {
    let filter = {
      search: search,
      sortOrder: sortOrder,
      stateFilter: stateName === "All State" ? "" : stateName,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCityList(value, 1, filter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCityList(pageSize, value, filter, true);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** EDIT REGION FUNCTION *** //
  function editRegion(region) {
    region = {...region, regions:regions}
    history.push({
      pathname: `/site-settings/city/add`,
      state: region,
    });
  }

  // *** DELETE REGION FUNCTION *** //
  function deleteRegion(region) {
    setSeletedWareHuse(region._id);
    setIsDeleteModule(true);
  }

  function handleInput(e) {

    const { value } = e.target;

    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        nextpage: 1
      };
      debounceSearch(updatedFilter);
    }
  }

  function debounceSearch(filter) {
    setNextPage(1);
    getCityList(pageSize, 1, filter,true);
    setFilteredResult(filter);
  }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Warehouse", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function filterRegionChange(name, value) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: sortOrder,
      stateFilter: stateName === "All State" ? "" : stateName,
    };
    setStateName(value)
    if (name === "state") {
      updatedFilter.stateFilter = value;
      setStateName(value);
    }
    debounceSearch(updatedFilter);
  }

  //TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  // * TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No results found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewOrder(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewOrder = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 1 ? 1 : 0;
    setItems(itemClone);
    fetchNewOrder(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );
  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: sortOrder,
      stateFilter: (!e.target.checked) ? "" : (stateName === "All State" ? "" : stateName)
      // stateFilter: stateName === "All State" ? "" : stateName,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "state") {
          updatedFilter.state = "";
          setStateName("All State");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter{" "}
                <img alt="" src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "state") === "state" && (
                <div className="card_dropdown show col-md-6">

                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {stateName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(states)
                      ? states.map((state) => (
                        <span
                          onClick={() =>
                            filterRegionChange("state", state)
                          }
                        >
                          <label>{state}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      stateFilter: stateName === "All State" ? "" : stateName,
    };
    debounceSearch(updatedFilter);
  }

  function cancelDelete() {
    setSeletedWareHuse("");
    setIsDeleteModule(false);
  }

  function confirmDelete() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      sortOrder: null,
      stateFilter: stateName === "All State" ? "" : stateName,
    };
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .delete(`/cities/${seletedWareHuse}`)
      .then((res) => {
        if (res.data.status === 200) {
          cancelDelete();
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getCityList(pageSize, nextPage, updatedFilter);
        } else {
          cancelDelete();
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to delete warehouse",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      });
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        },
      ),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      // eslint-disable-next-line no-unused-expressions
      setOrderList((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arraMoving(prev, activeIndex, overIndex);
      });
    
    }
  };

 

  return (
    <>
    {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
        "SiteSettings-Regions-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale} ${props.sortActive && commonstyles.read_only_container}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by City Name/State",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: handleInput,
                  ["aria-label"]: "Search category",
                  ["aria-describedby"]: "search-category",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
        "SiteSettings-Regions-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Warehouse Report for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {isLoading === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching warehouse details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                
                <div className={`${props.sortActive && commonstyles.hide_sort_time_edit}`}>
                   {!props.sortActive ?
                  <AntTableComponent
                    className={`order_table ${commonstyles.hide_sort}` }
                    tableProps={{
                      columns: columns,
                      dataSource: orderList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />:
                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      // rowKey array
                      items={orderList.map((i) => i.key)}
                      strategy={verticalListSortingStrategy}
                    >
                  <Table
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={orderList}
                    pagination={false}
                  />
                  </SortableContext>
                  </DndContext>}</div>
              )}
            </div>
          </PDFExport>
        )}
      {!isLoading && Array.isArray(orderList) && orderList.length && !props?.sortActive
        ? permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "SiteSettings-Regions-view") ===
        "SiteSettings-Regions-view" && (
          <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
          />
        )
        : null}
        
      <ConfirmationBox
        modal={{
          show: isDeleteModule,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={confirmDelete}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <span className="theme-txt-subtitle">
              Are you sure want to delete?
              </span>
            </div>
          </div>
        </div>
      </ConfirmationBox>
    </>
  );
}
