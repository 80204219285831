import React from 'react';
import { Table} from "antd";
import styles from "../../../styles/ProductManagement.module.css"
const AntTableComponent = (props) => {
    console.log("tableprops",props);
    console.log("Full table props:", props);

    console.log("Full table props:", props);
    console.log("Styles object:", styles);
    const rowClassName = (record, index) => {
        // Logging for debugging
        // console.log(`Row ${index} record:`, record);
    
        // Check if the 'stocks' key is present
        const hasStocksKey = !record.hasOwnProperty('stocks');
    
        // If 'stocks' key is present, no class should be applied
        if (hasStocksKey) {
            console.log(`Row ${index} has 'stocks' key. No class applied.`);
            return '';
        }
    
        // Multiple checks for empty slug
        const isEmptySlug = 
            record.slug === '' || 
            record.slug === undefined || 
            record.slug === null ||
            (typeof record.slug === 'string' && record.slug.trim() === '');
    
        // Apply dimmed-row class if slug is empty
        if (isEmptySlug) {
            console.log(`Row ${index} has an empty slug. Applying 'dimmed-row' class.`);
            return styles.dimmedRow || 'dimmed-row';
        }
    
        // Default case: no class
        return '';
    };
    return (
        <Table {...props.tableProps}  rowClassName={rowClassName} className={`${props.className || ''} ${styles.customTable || ''}`}/>
    );
}
 
export default AntTableComponent;