import React, { useEffect, useState } from 'react'
import styles from "../../../styles/PaymentNew.module.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import { Select, notification } from "antd";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMove } from "react-movable";
import { useHistory } from "react-router";
import moment from "moment";
import ConfirmationBox from '../../modules/ReusableComponent/ConfirmationBox';

const { Option } = Select;


const Trending = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({ details: false, update: false });
  const [regionId, setRegionId] = useState("");
  const [regionTrendingData, setRegionTrendingData] = useState([])
  const [updatedDetail, setUpdatedDetail] = useState({ updatedAt: "", userName: "" });
  const [trendingData, setTrendingData] = useState({ title: "", subTitle: "" });
  const [categoriesData, setCategoriesData] = useState([{ product: [], categoryId: "", categoryName: "", sort: "" }]);
  const [trendingErrors, setTrendingErrors] = useState({ title: "", subTitle: "" });
  const [categoriesErrors, setCategoriesErrors] = useState([{ product: [], categoryId: "", categoryName: "", sort: "" }]);
  const [categoriesListData, setCategoriesListData] = useState([]);
  const [productList, setproductList] = useState([]);
  const [indexValue, setIndexValue] = useState("")
  const [categoryIdList, setCategoryIdList] = useState([])
  const [confirmClear, setConfirmClear] = useState(false);

  useEffect(() => {
    getRegionWiseLanding()
    getCategoriesList()
    if (!props.moduleName || props.moduleName === undefined) {
      setTimeout(() => {
        history.push("/Content")
      }, 200);
    }
  }, [])

  useEffect(() => {
    if (regionTrendingData && Array.isArray(regionTrendingData)) {
      for (const region of regionTrendingData) {
        if (region && regionId === "" && region.regionId == null) {
          setTrendingData({ title: region.title, subTitle: region.subTitle })
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          region.category && region.category.length > 0 && region.category.forEach((item) => {
            let productArray = [];
            if (item.product && item.product.length > 0) {
              item.product.forEach((productItem) => {
                productArray.push({ value: productItem.feature_image, key: productItem._id, label: productItem.name });
              });
            }
            setCategoriesData([{ product: productArray, categoryId: item.categoryId, sort: item.sort }]);
          });
          break;
        } else if (region && regionId && region.regionId == regionId) {
          setTrendingData({ title: region.title, subTitle: region.subTitle })
          setUpdatedDetail({ updatedAt: region.updatedAt, userName: region.updatedBy })
          let updatedCategoriesData = [];
          region.category && region.category.length > 0 && region.category.forEach((item) => {
            let productArray = [];
            if (item.product && item.product.length > 0) {
              item.product.forEach((productItem) => {
                productArray.push({ value: productItem.feature_image, key: productItem._id, label: productItem.name });
              });
            }
            if (item.categoryId) {
              updatedCategoriesData.push({ product: productArray, categoryId: item.categoryId, categoryName: item.categoryName, sort: item.sort });
            }
          });
          setCategoriesData([...updatedCategoriesData]);
          break;
        } else {
          setTrendingData({ title: "", subTitle: "" })
          setUpdatedDetail()
          setCategoriesData([{ product: [], categoryId: "", sort: "" }])
        }
      }
    }
  }, [regionTrendingData, regionId]);



  const filterOnChangeforRegion = (e) => {
    const { name, value } = e.target;
    if (name === "regionId") {
      setRegionId(value);
    }
  };


  function getRegionWiseLanding() {
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .get(`/getTrendingRegionLanding/${props.state}`)
      .then((res) => {
        if (res.data.status === 200) {
          setRegionTrendingData(res.data.data[0].region);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });

  }
  function getCategoriesList() {
    setIsLoading(true);
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getCategoryList", { "pagesize": 50, "nextpage": 1, "enable": true })
      .then((res) => {
        if (res.data.status === 200) {
          setCategoriesListData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        return er;
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  const handleFormInputTrending = (name, data) => {
    switch (name) {
      case 'title':
        setTrendingErrors((prevErrors) => ({
          ...prevErrors,
          title: data === "" ? 'Please enter the title' : ''
        }));
        setTrendingData({ title: data, subTitle: trendingData && trendingData.subTitle ? trendingData.subTitle : "" });
        break;
      case 'subTitle':
        setTrendingErrors((prevErrors) => ({
          ...prevErrors,
          subTitle: data === "" ? 'Please enter the Sub title' : ''
        }));
        setTrendingData({ title: trendingData && trendingData.title ? trendingData.title : "", subTitle: data });
        break;
      default:
        setTrendingErrors(() => ({
          title: '',
          subTitle: ''
        }));
        setTrendingData({ title: trendingData && trendingData.title ? trendingData.title : "", subTitle: trendingData && trendingData.subTitle ? trendingData.subTitle : "" });
        break;
    }
  };

  function getProductList(id) {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/deals/getProductsOfCategory", { categoryId: id })
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setproductList(res.data.data);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const addCategories = () => {
    const updatedCategoriesListData = categoriesListData.filter(category => !categoryIdList.includes(category._id));
    setCategoriesListData(updatedCategoriesListData);
    setCategoriesData((prevCategoriesData) => [
      ...prevCategoriesData,
      { product: [], categoryId: "", categoryName: "", sort: "", product: [] },
    ]);
  };

  const removeCategories = (index) => {
    const removedCategory = categoriesData[index];
    const removedCategoryId = removedCategory.categoryId;
    setCategoriesData((prevCategoriesData) =>
      prevCategoriesData.filter((_, i) => i !== index)
    );
    setCategoryIdList((prevCategoryIdList) => {
      const updatedCategoryIdList = prevCategoryIdList.filter(
        (categoryId) => categoryId != removedCategoryId
      );
      return updatedCategoryIdList;
    });
  };

  const categoriesHandler = (value, label, option, index) => {
    getProductList(value);
    const updatedCategories = [...categoriesData];
    updatedCategories[index].categoryId = value;
    updatedCategories[index].categoryName = label;
    setCategoriesData(updatedCategories);
    setCategoryIdList([...categoryIdList, value])
  };

  const sortHandler = (value, index) => {
    const updatedCategories = [...categoriesData];
    updatedCategories[index].sort = value;
    setCategoriesData(updatedCategories);
  };

  const handleDeselect = (value, option, index) => {
    const updatedCategories = [...categoriesData];
    updatedCategories[index].product = updatedCategories[index].product.filter(
      (item) => item.value !== value
    );
    setCategoriesData(updatedCategories);
  };

  const searchSelectOnChange = (value, data, index) => {
    const selectedArray = categoriesData[index].product
      ? [...categoriesData[index].product]
      : [];

    const selected = {
      label: data.label,
      key: data.key,
      value: data.value,
    };

    const valueIndex = selectedArray.findIndex(
      (item) => item.value === selected.value
    );

    if (valueIndex === -1) {
      selectedArray.push(selected);
    } else {
      selectedArray.splice(valueIndex, 1);
    }

    const updatedCategories = [...categoriesData];
    updatedCategories[index].product = selectedArray;
    setCategoriesData(updatedCategories);
  };

  const removeImages = (img_indx, index) => {
    const updatedCategories = [...categoriesData];
    const updatedProductArray = [...updatedCategories[indexValue].product];
    updatedProductArray.splice(img_indx, 1);
    updatedCategories[indexValue].product = updatedProductArray;
    setCategoriesData(updatedCategories);
  };




  const onSortEnd = (oldIndex, newIndex) => {
    const updatedCategories = [...categoriesData];
    const updatedProductArray = [...updatedCategories[indexValue].product];
    const movedItem = updatedProductArray.splice(oldIndex, 1)[0];
    updatedProductArray.splice(newIndex, 0, movedItem);
    updatedCategories[indexValue].product = updatedProductArray;
    setCategoriesData(updatedCategories);
  };

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  const setErrorsForSet = (setIndex, setValues) => {
    setCategoriesErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      newErrors[setIndex] = {
        product: setValues.product && setValues.product.length === 0 ? `Please select at least one product` : '',
        categoryId: setValues.categoryId.trim() === "" ? `Please enter the Category ID` : '',
        sort: setValues.sort === "" ? `Please enter the Sort` : '',
      };

      return newErrors;
    });
  };

  function submit() {
    setTrendingErrors((prevErrors) => ({
      ...prevErrors,
      title: trendingData.title === undefined || trendingData.title === "" ? 'Please enter the title' : '',
      subTitle: trendingData.subTitle === undefined || trendingData.subTitle === "" ? 'Please enter the Sub Title' : ''
    }));
    categoriesData.forEach((category, index) => {
      setErrorsForSet(index, {
        product: category.product,
        categoryId: category.categoryId,
        sort: category.sort,
      });
    });

    const extractedData = categoriesData.map(item => ({
      categoryId: item.categoryId,
      product: item.product && item.product.length > 0 && item.product.map(productItem => productItem.key),
      sort: item.sort
    }));

    console.log(extractedData);

    const finalData = {
      "id": props.state,
      "region": {
        "regionId": regionId,
        "title": trendingData.title,
        "subTitle": trendingData.subTitle,
        "category": extractedData
      }
    };

    setIsLoading(true);

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/addEditRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        }
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function cancelClear(){
    setConfirmClear(false)
  }
  function clearRegionData(){
    setConfirmClear(false)
    const finalData = {
      "id": props.state,
      "regionId": regionId,
    }
    setIsLoading(true);
    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post(`/clearRegionLanding`, finalData)
      .then((res) => {
        if (res.data.status === 200) {
          getRegionWiseLanding();
          showNotification({
            type: "success",
            message: "Success",
            description: "Updated successfully",
          });
        }else{
          showNotification({
            type: "Error",
            message: "Error",
            description: "Please try again",
          });
        }
      })
      .catch((er) => {
        console.log(er)
        showNotification({
          type: "Error",
          message: "Error",
          description: "Please try again",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

  }


  return (
    <>
      <div className={styles.management_contents}>
        <div className={styles.management_header}>
          <h1>
            <img
              src={BlackLeftArrow}
              alt=""
              className="cursor-pointer"
              onClick={() => history.push("/Content")}
            />
            <span>{props.moduleName}</span>
          </h1>
        </div>
        <div className={`${styles.management_header} ${styles.banner_city_list}`}>
          <div className={`${styles.banner_citys}`}>
            <SelectComponent
              className="form-control-md"
              attributes={{
                name: "regionId",
                value: regionId,
                onChange: filterOnChangeforRegion,
              }}
            >
              {props.filterRegions?.length > 1 && <option value="">All Region</option>}
              {Array.isArray(props.filterRegions)
                ? props.filterRegions.map((region) => (
                  <option key={region._id} value={region._id}>
                    {region.name}
                  </option>
                ))
                : null}
            </SelectComponent>
            {updatedDetail &&
              <div className={`${styles.banner_citys_update}`}>
                {updatedDetail.userName && (
                  <p>
                    Last updated by: <span>{updatedDetail.userName}</span>
                  </p>
                )}
                {updatedDetail.updatedAt && (
                  <p>
                    Last update on:{" "}
                    <span>
                      {moment(updatedDetail.updatedAt).format(
                        "DD/MM/YYYY | hh:mm A"
                      )}
                    </span>
                  </p>
                )}
              </div>
            }
          </div>
          <div className={styles.management_contents_upload}>
          <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => setConfirmClear(true),
            }}
          >
            Clear
          </ButtonComponent>
            <ButtonComponent
              className="rnd mr-3 outline_cancel_btn"
              attributes={{
                onClick: () => history.push(`/Content`),
              }}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              className="rnd"
              attributes={{
                disabled: loading.update,
                onClick: submit,
              }}
            >
              {loading.update ? "Saving" : "Save"}
            </ButtonComponent>
          </div>
        </div>
        <div className={styles.management_details_contents}>
          <label className={commonstyles.secondry_title}>Title<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
            *
          </span> </label>
          <InputComponent
            error={trendingErrors.title}
            className="form-control-lg mb-3"
            formControl={{
              placeholder: "Type here",
              name: "title",
              value: trendingData?.title,
              onChange: (e) =>
                handleFormInputTrending("title", e.target.value),
            }}
          />
          <FormErrorText error={trendingErrors.title} />
          <label className={commonstyles.secondry_title}>Sub Title<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
            *
          </span> </label>
          <InputComponent
            error={trendingErrors.subTitle}
            className="form-control-lg mb-3"
            formControl={{
              placeholder: "Type here",
              name: "subTitle",
              value: trendingData?.subTitle,
              onChange: (e) =>
                handleFormInputTrending("subTitle", e.target.value),
            }}
          />
          <FormErrorText error={trendingErrors.subTitle} />
        </div>
        {categoriesData && categoriesData?.map((category, index) =>
          <>
            <div className={styles.management_details_contents}>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>Categories<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <Select
                    error={categoriesErrors.categoryId}
                    style={{ width: "100%" }}
                    name="categories"
                    placeholder="Select Categories"
                    optionLabelProp="categories"
                    value={category ? category.categoryName : ""}
                    onChange={(value, label, option) => categoriesHandler(value, label, option, index)}
                  >
                    {categoriesListData && categoriesListData.length > 0 && categoriesListData.map((categories) => (
                      <Option
                        value={categories._id}
                        label={categories.name}
                        key={categories._id}
                      >
                        <div className="demo-option-label-item">{categories.name}</div>
                      </Option>
                    ))}
                  </Select>
                  <FormErrorText error={categoriesErrors[index]?.categoryId} />
                </div>

                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>Sort<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <Select
                    error={categoriesErrors.sort}
                    style={{ width: "100%" }}
                    // mode="multiple"
                    name="sort"
                    placeholder="Select Sort"
                    //  className="productSelect"
                    optionLabelProp="label"
                    value={category?.sort}
                    onChange={(value) => sortHandler(value, index)}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </Select>
                  <FormErrorText error={categoriesErrors[index]?.sort} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>Product<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <Select
                    error={categoriesErrors.product}
                    className="productSelect"
                    mode="multiple"
                    name="region"
                    placeholder="Select products"
                    optionLabelProp="label"
                    value={category.product ? category.product : []}
                    filterOption={(input, option) =>  
                      option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                      || option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onDeselect={(value, option) => handleDeselect(value, option, index)}
                    onSelect={(value, option) => {
                      searchSelectOnChange(value, option, index);
                      setIndexValue(index);
                    }}
                  >
                    {productList && productList.length > 0 && productList.map((product) => (
                      <Option
                        value={product.feature_image}
                        label={product.name}
                        key={product._id}
                      >
                        <div className="demo-option-label-item">{product.name}</div>
                      </Option>
                    ))}
                  </Select>
                  <FormErrorText error={categoriesErrors[index]?.product} />
                </div>
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Preview Image Order
                  </label>
                  <SortableList
                    onSortEnd={onSortEnd}
                    className={styles.sortingimg_root}
                  >
                    <div className={commonstyles.banner_img_box_outline} style={{ paddingBottom: "20px" }}>
                      {category?.product?.length > 0 &&
                        category.product.map((imgs, index) => (

                          <div className={commonstyles.banner_img_preview}>
                            <SortableItem key={imgs.key}>
                              <div>
                                <img
                                  style={{ pointerEvents: "none" }}
                                  className={commonstyles.previewImgs}
                                  key={imgs.key}
                                  src={imgs.value}
                                  alt="profileImg"
                                  imgProps={{ draggable: false }}
                                />
                                <div className={styles.imgpreview_index}>
                                  {index + 1}
                                </div>
                                <img
                                  onClick={() => removeImages(imgs, index)}
                                  className={commonstyles.previewImgsdeleteicon}
                                  src={bannerimgdelete}
                                  alt="delete"
                                />
                              </div>
                            </SortableItem>
                          </div>
                        ))}
                    </div>
                  </SortableList>
                </div>
              </div>
              <div className={`row ${styles.catgory_row}`}>
                <div className="col-lg-6">
                  <button
                    className={styles.category_add}
                    onClick={addCategories}
                  >
                    + Add Categories
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    className={styles.category_remove}
                    onClick={() => removeCategories(index)}
                  >
                    Remove Categories
                  </button>
                </div>
              </div>


            </div>
          </>
        )}
      </div>
      <ConfirmationBox
        modal={{
          show: confirmClear,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelClear}
        handleConfirm={clearRegionData}
        title="Confirm the action"
        confirmText="Clear"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to clear?
            </p>
          </div>
        </div>
      </ConfirmationBox>
    </>
  );
};

export default Trending