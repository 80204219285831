import React from "react";
import { useState, useRef, useEffect } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { notification } from "antd";
import { useHistory } from "react-router";
import validationRules from "../../../utils/validationRules";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";

function General() {
  const initialFormState = {
    _id: "",
    from_email: "",
    nodemailer_host: "",
    nodemailer_port: "",
    nodemailer_user: "",
    nodemailer_pass: "",
    msg91_authKey: "",
    s3_accessKeyId: "",
    s3_secretAccessKey: "",
    S3_BUCKET: "",
  };
  const rules = {
    from_email: [validationRules.required],
    nodemailer_host: [validationRules.required],
    nodemailer_port: [validationRules.required],
    nodemailer_user: [validationRules.required],
    nodemailer_pass: [validationRules.required],
    msg91_authKey: [validationRules.required],
    s3_accessKeyId: [validationRules.required],
    s3_secretAccessKey: [validationRules.required],
    S3_BUCKET: [validationRules.required],
  };
  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });

  useEffect(() => {
    getGeneralSettingsDetails();
  }, []);

  // *** FETCHING GENERAL SETTINGS DETIALS *** //
  function getGeneralSettingsDetails() {
    setLoading({ details: true, update: false });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/config/getDetails/")
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEditForm(val);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    const { name, value } = e.target;
    const updatedValues = { ...editForm };
    updatedValues[name] = value.trim();
    setEditForm(updatedValues);
  }

  // *** SHOW NOTIFICATION FUNCTION *** //
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  // *** VALIDATE FUNCTION *** //
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }

  // *** SAVE CHANGES *** //
  function onSubmite() {
    let data = JSON.parse(JSON.stringify(editForm));
    const result = validate(undefined, data);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }
    delete data.__v;
    delete data.createdAt;
    delete data.updatedAt;
    setLoading({ ...loading, update: true });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/admin/config/updateDetails/", data)
      .then((res) => {
        if (res.data.status === 200) {
          setEditForm(initialFormState);
          showNotification({
            type: "success",
            message: "Success",
            description: "General settings updated successfully",
          });
          setTimeout(() => {
            history.push("/site-settings");
          }, 1000);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/site-settings")}
          />
          General Setting
        </h1>
        <div className={commonstyles.management_contents_upload}>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: onSubmite,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      <div className={commonstyles.management_details}>
        <div className={commonstyles.management_details_contents}>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  From Email{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.from_email}
                  formControl={{
                    placeholder: "Eg, ankith_kumar@gmail.com",
                    name: "from_email",
                    value: editForm?.from_email,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.from_email} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  Node Emailer Host{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.nodemailer_host}
                  formControl={{
                    placeholder: "Eg, ankith_kumar@gmail.com",
                    name: "nodemailer_host",
                    value: editForm?.nodemailer_host,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.nodemailer_host} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  Node Emailer Port{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.nodemailer_port}
                  formControl={{
                    placeholder: "Eg, ankith_kumar@gmail.com",
                    name: "nodemailer_port",
                    value: editForm?.nodemailer_port,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.nodemailer_port} />
              </div>
            </div>

            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  Node Emailer Password{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.nodemailer_pass}
                  formControl={{
                    placeholder: "Type here",
                    name: "nodemailer_pass",
                    value: editForm?.nodemailer_pass,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.nodemailer_pass} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  Node Emailer User{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.nodemailer_user}
                  formControl={{
                    placeholder: "Type here",
                    name: "nodemailer_user",
                    value: editForm?.nodemailer_user,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.nodemailer_user} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  S3 Access Key Id{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.s3_accessKeyId}
                  formControl={{
                    placeholder: "Type here",
                    name: "s3_accessKeyId",
                    value: editForm?.s3_accessKeyId,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.s3_accessKeyId} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  Msg91 Auth Key{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.msg91_authKey}
                  formControl={{
                    placeholder: "Type here",
                    name: "msg91_authKey",
                    value: editForm?.msg91_authKey,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.msg91_authKey} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  S3 Bucket{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.S3_BUCKET}
                  formControl={{
                    placeholder: "Type here",
                    name: "S3_BUCKET",
                    value: editForm?.S3_BUCKET,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.S3_BUCKET} />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_form}>
                <label for="">
                  S3 Secret Access Key{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  className="form-control form-control-lg form_control"
                  error={formErrors.s3_secretAccessKey}
                  formControl={{
                    placeholder: "Type here",
                    name: "s3_secretAccessKey",
                    value: editForm?.s3_secretAccessKey,
                    onChange: handleFormInput,
                  }}
                />
                <FormErrorText error={formErrors.s3_secretAccessKey} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default General;
