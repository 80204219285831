import styles from "../../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../../styles/Management_Common.module.css";
import Search from "../../../../assets/images/search_icon.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../../assets/images/admin_product_edit_icon.svg";
import AddCategoryComponent from "./AddCategoryComponent";
import exportIcon from "../../../../assets/images/export_icon.svg";
import pdf_icon from "../../../../assets/images/pdf_icon.svg";
import EditCategoryComponent from "./EditCategoryComponent";
import excelIcon from "../../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useState, useRef } from "react";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import dots from "../../../../assets/images/column_dots.svg";
import MovableListComponent from "../../../modules/ReusableComponent/MovableListComponent";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
} from "antd";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import Pagination from "../../../modules/ReusableComponent/Pagination";
import EditCustomerComponent from "../../CustomerManagement/EditCustomerComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../../modules/ReusableComponent/ConfirmationBox";
import deleteIcon from "../../../../assets/images/trash.svg";
import { Link, useParams } from "react-router-dom";
// import BulkUploadComponent from "../BulkUploadComponent";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../../modules/ReusableComponent/AntTableComponent";
import AntDateRangeSelectComponent from "../../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../../SearchManagement/SearchComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Category", value: "Category" },
  { _id: 3, status: 1, name: "Category Level", value: "Category Level" },
  { _id: 4, status: 1, name: "Trending", value: "Trending" },
  { _id: 5, status: 1, name: "Our Collection", value: "Our Collection" },
  { _id: 6, status: 1, name: "Created At", value: "Created At" },
  // { _id: 7, status: 1, name: "Last updated At", value: "lastupdatedat" },
  // { _id: 8, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 9, status: 1, name: "Operate", value: "operate" },
];
const filters = [{ id: 1, isChecked: false, name: "Date", value: "Date" }];
const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable Category", value: true },
  { _id: 2, isChecked: false, name: "Disable Category", value: false },
];
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];

function getReadableDate(date) {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
  };
  return new Date(date).toLocaleString("en-US" /* , options */);
}

const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
export default function CustomerManagementComponent() {
  const pdfExportComponent = useRef(null);
  const [categoryData, setCategoryData] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [filterDays, setFilterDays] = useState("");
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [visibility, setVisibility] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [regionId, setRegionId] = useState("");
  const [regions, setRegions] = useState([]);
  const [rating, setRating] = useState("");

  // const [isLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [sortorder, setSortorder] = useState(0);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Category",
      visible: false,
      key: "Category",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.name}</span>
      ),
      sorter: (a, b) => a.orderId - b.orderId,
    },
    {
      title: "Category Level",
      visible: false,
      key: "Category Level",
      render: (text, record, index) => (
        <span>
          {record.level === 0
            ? "Parent category"
            : record.level === 1
              ? "Sub category"
              : "Second sub category"}
        </span>
      ),
    },
    {
      title: "Trending",
      visible: false,
      key: "Trending",
      render: (text, record, index) => (
        <td>{record.trending ? "Yes" : "No"}</td>
      ),
    },
    {
      title: "Our Collection",
      visible: false,
      key: "Our Collection",
      render: (text, record, index) => (
        <td>{record.ourcollection ? "Yes" : "No"}</td>
      ),
    },
    {
      title: "Created At",
      visible: false,
      key: "Created At",
      render: (text, record, index) => (
        <td>{getReadableDate(record.createdAt)}</td>
      ),
    },
    // {
    //   title: "Last updated At",
    //   visible: false,
    //   key: "lastupdatedat",
    //   render: (text, record, index) => (
    //     <td>{getReadableDate(record.updatedAt)}</td>
    //   ),
    // },
    // {
    //   title: "Enable / Disable",
    //   visible: false,
    //   key: "enable",
    //   render: (text, record, index) => (
    //     <Switch
    //       checked={record?.enable}
    //       onChange={(e) => toggleStatusOnChange(e, record)}
    //       checkedChildren="Active"
    //       unCheckedChildren="Inactive"
    //     />
    //   ),
    // },
    {
      title: "Operate",
      visible: false,
      key: "operate",
      render: (text, record, index) => (
        <span>
          <Link to={`/catalog/category/${record._id}`}>
            <img
              src={adminProductEdit}
              className={`${commonstyles.admin_table_edit_img} cursor-pointer`}
              // onClick={() => editCategory(category)}
              alt="Edit category"
              title="Edit category"
            />
          </Link>
          <img
            src={deleteIcon}
            className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
            onClick={() => showdelete(record)}
            alt="Delete category action icon"
            title="Delete category"
          />
        </span>
      ),
    },
  ]);

  function showdelete(category) {
    setSelectedCategory(category);
    updateVisibility("delete", true, undefined);
  }

  function cancelDelete() {
    setSelectedCategory({});
    updateVisibility("delete", false, undefined);
  }

  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("categoryList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("categoryList", JSON.stringify(cloneOrderList));
    setCategoryData(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  const parentCheckbox = (e) => {
    localStorage.removeItem("categoryList");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("categoryList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("categoryList", JSON.stringify(cloneOrderList));
    setCategoryData(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getCategories(pageSize, nextPage, "");
    getRegionList();
    // getRegionList();
    localStorage.removeItem("selectedCustomerr");
    return () => {
      localStorage.removeItem("categoryList");
      localStorage.removeItem("selectedCustomerr");
    };
  }, []);

  // function showdelete(category) {
  //   setSelectedCategory(category);
  //   updateVisibility("delete", true, undefined);
  // }

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  function removeCategory() {
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteCategory/${selectedCategory._id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          setIsLoading(false);
          getCategories(pageSize, nextPage, "");
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete category",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete category",
        });
      })
      .finally(() => {
        updateVisibility("delete", false, undefined);
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  function getCategories(pageSize, nextPage, searchNow, filterarr, type) {
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search: searchNow,
    };
    // if (filterarr && filterarr.regionId) {
    //   filterOptions.regionId = filterarr.regionId;
    // } else if (regionId !== "") {
    //   if (filterarr && filterarr.regionId !== "") {
    //     filterOptions.regionId = regionId;
    //   }
    // }

    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.sortOrder!== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if (!type) {
      setIsLoading({ delete: false, list: true });
    }
    AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
      .post("/admin/catalog/getCategoryList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("categoryList", JSON.stringify(res.data.data));
            setCategoryData(res.data.data);
          } else {
            setCategoryData([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      sortOrder: sortorder,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCategories(value, 1, search, updatedFilter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCategories(pageSize, value, search, updatedFilter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function updateVisibility(type, value, success) {
    setVisibility({ ...visibility, [type]: value });
    if (success) {
      setIsLoading(true);
      getCategories(pageSize, nextPage, "");
    }
  }

  if (visibility.add) {
    return (
      <AddCategoryComponent
        handleClose={(success) => updateVisibility("add", false, success)}
      />
    );
  } else if (visibility.edit) {
    return (
      <EditCategoryComponent
        category={selectedCategory}
        handleClose={(success) => updateVisibility("edit", false, success)}
      />
    );
  }

  function closeEditProduct(success) {
    if (success) {
      getCategories(pageSize, nextPage, "");
    }
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  // if (showAddCustomerVisibility) {
  //   return (
  //     <AddCustomer
  //       changeAddCustomerVisibility={() => setshowAddCustomerVisibility(false)}
  //     />
  //   );
  // }

  function searchHandler(e) {
    setSearch(e.target.value);
    setNextPage(1);
    getCategories(pageSize, 1, e.target.value);
  }

  // TO SHOW TABLE HEADERS BASED ON STATUS
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  //TO SHOW TABLE DATA BASED ON HEADER STATUS
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };

  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        console.log(item.value);
        if (item.value === "Date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
        }
        // if (item.value === "date") {
        //   const clonefilteredDate = { ...filteredDate };
        //   clonefilteredDate.startDate = "";
        //   clonefilteredDate.endDate = "";
        //   updatedFilter.startDate = "";
        //   updatedFilter.endDate = "";
        //   setFilteredDate(clonefilteredDate);
        // }
        getCategories(pageSize, nextPage, search, updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
    };
    if (name === "filterDays") {
      updatedFilter.filterDays = value;
      setFilterDays(value);
    }

    getCategories(pageSize, nextPage, search, updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
    };
    getCategories(pageSize, nextPage, search, updatedFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                    <span key={filter._id}>
                      <input
                        type="checkbox"
                        checked={filter.isChecked}
                        onChange={(e) => commonFilterHandler(e, filter)}
                      ></input>
                      <label>{filter.name}</label>
                    </span>
                  ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && (
                <div className="card_dropdown show col-md-6">
                  <select
                    className="form_control"
                    name="filterDays"
                    value={filterDays}
                    onChange={filterOnChange}
                  >
                    <option value="">Date</option>
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <option key={day._id} value={day.value}>
                          {day.name}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  function showDeleteConfirmation(customer) {
    setSelectedCustomer(customer);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  function cancelDelete() {
    setSelectedCategory({});
    updateVisibility("delete", false, undefined);
  }

  // if (childComponentVisibility.addProduct) {
  //   return (
  //     <AddCustomer
  //       handleClose={() =>
  //         setChildComponentVisibility({
  //           ...childComponentVisibility,
  //           addProduct: false,
  //         })
  //       }
  //     />
  //   );
  // } else if (childComponentVisibility.editProduct) {
  //   return (
  //     <EditCustomerComponent
  //       customer={selectedCustomer}
  //       handleClose={closeEditProduct}
  //     />
  //   );
  // }

  //bulk
  // if (childComponentVisibility.bulkUpload) {
  //   return (
  //     <BulkUploadComponent
  //       region="region"
  //       handleClose={() => {
  //         setChildComponentVisibility({
  //           ...childComponentVisibility,
  //           bulkUpload: false,
  //         });
  //         getCustomers(pageSize, nextPage, "");
  //       }}
  //     />
  //   );
  // }
  const exportToPDF = () => {
    // console.log(pdfExportComponent);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    // let element = container.current || document.body;
    // savePDF(element, {
    //   paperSize: "auto",
    //   margin: 40,
    //   fileName: `Report for ${new Date().getFullYear()}`,
    // });
  };
  const exportToCSV = async (e) => {
    const filterOptions = {};
    if (search) {
      filterOptions.search = search;
    }
    if (regionId) {
      filterOptions.regionId = regionId;
    }
    if (rating) {
      filterOptions.rating = parseFloat(rating);
    }
    if (filteredDate?.startDate) {
      filterOptions.startDate = filteredDate.startDate;
    }
    if (filteredDate?.endDate) {
      filterOptions.endDate = filteredDate.endDate;
    }
    if (sortorder || sortorder === 0) {
      filterOptions.sortOrder = sortorder;
    }
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("user/exportUsers", filterOptions)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          // data.map((item) => {
          //   item["Module Name"] = capitalize(item["Module Name"]);
          // });
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"]?.v);
          ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `customer-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  // *** BUILD STATUS CLICK HANDLER *** //
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedCustomerr")) {
      let selectedCustomerr = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (selectedCustomerr.length > 0) {
        let data = {
          customerIds: selectedCustomerr,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("customer/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedCustomerr");
          const cloneOrderList = JSON.parse(
            localStorage.getItem("categoryList")
          );
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("categoryList", JSON.stringify(cloneOrderList));
          setCategoryData(cloneOrderList);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      filterDays: filterDays,
      // orderStatus: orderStatus,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortOrder: sorter.order === "descend" ? 1 : 0,
    };
    setSortorder(sorter.order === "descend" ? 1 : 0);
    // setNextPage(1);
    getCategories(pageSize, nextPage, search, updatedFilter, true);
  };

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Category</h1>
        <div className="d-flex align-items-center">
          <div>
            {/* <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3 "
              attributes={{
                onClick: () => {
                  // setFilter(intialFilterState);
                  setChildComponentVisibility({
                    ...childComponentVisibility,
                    bulkUpload: true,
                  });
                  window.scrollTo(0, 0);
                },
              }}
            >
              <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
              Bulk Upload
            </ButtonComponent> */}
            {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: (e) => {
                exportToCSV(e);
                // setFilter(intialFilterState);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Export
          </ButtonComponent> */}
          </div>
          <div>
            <span className={commonstyles.management_header_downloads_text}>
              <img
                className={commonstyles.export_icon}
                src={pdf_icon}
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToPDF(e)}
              />
              <img
                src={excelIcon}
                className={commonstyles.management_header_downloads_icon}
                alt="icon"
                style={{ cursor: "pointer" }}
                onClick={(e) => exportToCSV(e)}
              />
            </span>
            <span className={commonstyles.management_header_downloads_text_ex}>
              Export <img src={exportIcon} alt="icon" />
            </span>
          </div>
        </div>
      </div>
      <div className={commonstyles.management_header}>
        <ButtonComponent
          className="rnd pl-3 pr-4 outline_btn"
          attributes={{
            onClick: () => {
              updateVisibility("add", true);
              window.scrollTo(0, 0);
            },
          }}
        >
          {/* <img src={plusIcon} className="mr-2" /> */}
          Add Category
        </ButtonComponent>
      </div>

      <div
        className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
      >
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <InputComponent
            className="with-icon rnd theme-txt-xsm"
            formControl={{
              placeholder: "Search by Category Name...",
              name: "search",
              style: { height: "38px" },
              value: search,
              onChange: (e) => searchHandler(e),
              ["aria-label"]: "Search customers",
              ["aria-describedby"]: "search-customers",
            }}
          />
          <img src={Search} alt="" />
        </div>

        {/* <Dropdown
            // overlay={filterMenu}
            className="usermanage_table_filter_columns ml-3"
          >
            <div
              className="ant-dropdown-link"
              onTouchMove={(e) => e.preventDefault()}
            >
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown> */}
        <div className="action ml-3">
          <div className="action_header">
            Action
            <img src={arrowDown} alt="image" />
          </div>
          <div className="action_contents">
            {bulkActionFilter.length > 0 &&
              bulkActionFilter.map((item) => (
                <div
                  className="action_contents_header"
                  key={item._id}
                  onClick={() => bulkStatusClickHandler(item)}
                >
                  <h2
                    className={`action_contents_header_h2 ${item.isChecked && "action_contents_header_h2_active"
                      }`}
                  >
                    {item.name}
                  </h2>
                  {item.isChecked && (
                    <span
                      className="action_contents_btn"
                      onClick={() => applyBulkActionHandler(item)}
                    >
                      Apply
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Dropdown
          overlay={filterMenu}
          className="usermanage_table_filter_columns ml-3"
        >
          <div className="ant-dropdown-link">
            Filter <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
        <Dropdown
          overlay={menu}
          className="usermanage_table_filter_columns ml-3"
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            Columns <img src={arrowDown} width={10} height={20} />
          </div>
        </Dropdown>
      </div>

      <div className={`${commonstyles.management_table} table-responsive`}>
        {IsLoading.list === true ? (
          <div
            style={{
              marginLeft: "70vh",
              marginTop: "10vh",
            }}
          >
            <tr className="bg-transparent">
              <td colSpan="100%">
                <LoadingIndicator loadingText="Fetching category details..." />
              </td>
            </tr>
          </div>
        ) : (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`CustomerList for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div>
              <input
                type="checkbox"
                className={`${commonstyles.parentCheckbox}`}
                onChange={parentCheckbox}
              ></input>
              <AntTableComponent
                className="order_table"
                tableProps={{
                  columns: columns,
                  dataSource: categoryData,
                  pagination: false,
                  onChange: handleTableChange,
                  components: {
                    header: {
                      row: (props) => renderHeader(props),
                    },
                    body: {
                      row: (props) => renderBody(props),
                    },
                  },
                }}
              />
            </div>
          </PDFExport>
        )}
      </div>
      {!IsLoading.list && Array.isArray(categoryData) && categoryData.length ? (
        <Pagination
          pageSizes={pageSizes}
          pageSize={pageSize}
          nextPage={nextPage}
          data={pagination}
          handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: visibility.delete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={removeCategory}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Category delete confirmation modal ends*/}
    </div>
  );
}
