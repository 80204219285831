import React from 'react'
import ProfileImage from "../../../assets/images/profile_dummy.svg"
import commonstyles from "../../../styles/Management_Common.module.css";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { notification } from "antd";
import styles from "../../../styles/EditUser.module.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import validationRules from "../../../utils/validationRules";
import adminEdit from "../../../assets/images/admin_product_edit_icon.svg";
import {
  onlyAlphabetsWithSpace,
  phoneRegx,
  passwordReg,
  minChar,
} from "../../../utils/RegularExp";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import { Link, useParams } from "react-router-dom";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { upperCase, upperFirst } from "lodash";

const EditModularComponent = ({ Detail , setDetails }) => {
    const { id } = useParams();
    const history = useHistory()
    const [ editState , setEditState ] = useState(true)
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState({ details: false, update: false });
    const [enquiryDetail, setEnquiryDetail] = useState([]);

    function showNotification({ type, message, description }) {
        let options = {
          message: message || "Message",
          description: description,
          duration: 5,
        };
        notification[type](options);
      }

    useEffect(() => {
        if (id) {
            getEnquiryDetails(id);
        } else {
            setIsLoading(false);
        }

        if(enquiryType == 1){
          history['isBulk'] = true;
        }else{
          history['isBulk'] = false;
        }
        
      }, []);
    
/**
   * @author MITHUN PRABHU
   * @param {id}
   * GET ENQUIRY DETAILS
   */

  let enquiryType = history.location.state;

  let bulkDetails  = `/admin/getBulkEnquiryDetails/${id}`
  let enquiryDetails = `/admin/getenquiryDetails/${id}`
      function getEnquiryDetails(id) {
        setLoading({ details: true, update: false });
        AxiosInstance(PORTS.INVOICE, "application/json")
          .get(enquiryType == 1 ? bulkDetails : enquiryDetails)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 200) {
              const val = res.data.data;
              setEnquiryDetail(val);
              setIsLoading(false);
            } else {
              showNotification({
                type: "Error",
                message: "Error",
                description: "Couldn't fetch required data",
              });
            }
          })
          .catch((er) => {
            return er;
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
            setLoading({ details: false, update: false });
          });
      }

    return(
        <>
        {isLoading === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching modular enquiry details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={commonstyles.management_contents}>
        {/* Header */}
        {/* <button onClick={gotoServices}>click </button> */}
        <div className={commonstyles.management_header}>
          <h1 className={commonstyles.management_header_heading}>
            <img
              src={BlackLeftArrow}
              onClick={() => history.push("/site-settings/modular")}
              alt=""
              className="cursor-pointer"
              title="back"
            />
            Enquiry
          </h1>
          </div>
            <div className={commonstyles.profile_display}>
               <div >
                
                <div className= { ` row ${commonstyles.profile_details}`}>
                    
                    <div className={`col-md-12 ${commonstyles.profile_detail} pl-5 pr-5 pb-1`}>
                        <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-2">
                            <span>User name :</span> 
                            </div>
                            <div className="col-md-10">
                            <div>{enquiryDetail.name ? upperFirst(enquiryDetail.name) :"-"}</div> 
                            </div>
                        </div>
                        <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-2">
                            <span>Email :</span> 
                            </div>
                            <div className="col-md-10">
                                    <div >{enquiryDetail.email ?enquiryDetail.email :"-"}</div> 
                            </div>
                        </div>
                        <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-2">
                            <span> Phone :</span> 
                            </div>
                            <div className="col-md-10">
                                    <div >+91 {enquiryDetail.phone ? enquiryDetail.phone :"-"}</div> 
                            </div>
                        </div>
                        <div className= {` ${commonstyles.list} row` }>
                            <div className="col-md-2">
                               <span>Address : </span> 
                            </div>
                            <div className="col-md-10">
                                    <div >{enquiryDetail.address ? enquiryDetail.address: enquiryDetail?.regionId.name ? enquiryDetail?.regionId.name : "-"}</div> 
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-lg-12 pl-5 pr-5">
                    <hr/>
                    </div>
                    
                    <div className= {` ${commonstyles.query_message} col-lg-12 pl-5 pr-5` }>
                        <h4>Query</h4>
                        <div class="p-4 my-3 border br-7">
                            <p>{enquiryDetail.query ? upperFirst(enquiryDetail.query) : enquiryDetail.description ? upperFirst(enquiryDetail.description) : "-"}</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
             )}
        </>
    )
}

export default EditModularComponent