import styles from "../../../styles/Footer.module.css";
export default function FooterComponent() {
  return (
    <div className={styles.admin_sidebar_footer}>
      {/* <h4>@Damro.2021</h4> */}

      <span style={{ fontSize: "10px" }}>
        Copyright © Damro Furnitures Pvt. Ltd.
      </span>
      <span style={{ fontSize: "10px" }}>All rights reserved</span>
    </div>
  );
}
