import { notification, Space, Spin, Alert } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import { formatServerValidtionErrors } from "../../../utils";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import customerProfile from "../../../assets/images/customer_profile.svg";
import defaultImg from "../../../assets/images/default_profile.png";
import editIcon from "../../../assets/images/admin_product_edit_icon.svg";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import arrowDown from "../../../assets/images/arrow_down.svg";
import brownSofa from "../../../assets/images/brown_sofa.png";
import { Rating } from 'react-simple-star-rating'
import { Accordion, Card, Button } from "react-bootstrap";
import styless from "../../../styles/AddProduct.module.css";
import { Link, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

export default function CustomerDetails(props) {
  const [ruleStatus, setRuleStatus] = useState(true);
  const [customer, setCustomer] = useState({});
  const [banner, setBanner] = useState(null);
  const [file, setFile] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [customerDetail, setCustomerDetail] = useState([]);
  const [currentLoyaltyPoints, setCurrentLoyaltyPoints] = useState('');
  const [savedAddress, setSavedAddress] = useState([]);

  function handleInput(e) {
    const { name, value, files, checked } = e.target;
    if (["ruleStatus"].includes(name)) {
      setRuleStatus(checked);
      toggleSave(checked);
    }
    if (name === "profileImg") {
      // removeImagePreview();
      setFile(files[0]);
      if (banner) {
        URL.revokeObjectURL(banner);
      }
      const generatedUrl = URL.createObjectURL(files[0]);
      setBanner(generatedUrl);
    } else if (name === "phone") {
      const parsed = parseInt(value);
      if (!isNaN(parsed)) {
        setCustomer({ ...customer, [name]: String(parsed) });
      } else if (isNaN(parsed) && !value) {
        setCustomer({ ...customer, [name]: "" });
      }
    } else if (name === "name") {
      if (value.length == 1) {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please enter name more than 1 letter",
        });
        setCustomer({ ...customer, [name]: "" });
      }
      setCustomer({ ...customer, [name]: value });
    } else if (name === "email") {
      setCustomer({ ...customer, [name]: value });
    }
  }

  function toggleSave(item) {
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
    .get(`customer/${id}/${item}`)
    .then((res) => {
      if (res.data.status === 200) {
        setIsLoading(false);
        showNotification({
          type: "success",
          message: "Success",
          description: res.data.msg
        });
      } else if (res.data.status === 400) {
        setIsLoading(false);
      }
    })
    .catch((er) => {
      setIsLoading(false);
      showNotification({
        type: "error",
        message: "Failed",
        description: "Error occured",
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  useEffect(() => {
    getOrderDetails();
    viewCustomerDetails();
  }, [])

  function getOrderDetails() {
    setIsLoading(true);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`user/orders/${id}`)
      // .get(`user/orders/61a606bc088bc1b1f922e89c`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data;
          if(data?.length > 0) {
            data.forEach(item => {
              item.createdAt = moment(item.createdAt).format("DD/MM/YYYY | hh:mm:ss A")
            })
            setOrderList(data);
          } else {
            setOrderList([]);
          }
        } else if (res.data.status === 400) {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * @author Mithun Prabhu
   * @param {type, message, description}
   * SHOW CUSTOMER DETAILS
   */
  function viewCustomerDetails() {
    setIsLoading(true);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`viewCustomerDetails/${id}`)
      // .get(`viewCustomerDetails/61a606bc088bc1b1f922e89c`)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          let data = res?.data?.data[0];
          setCustomerDetail(data);
          if(data?.orders?.length > 0) {
            setSavedAddress(data.orders[0]);
          }
          setRuleStatus(data?.status === 1? true:false);
          setCurrentLoyaltyPoints(res?.data?.currentLoyaltyPoints)
        } else if (res.data.status === 400) {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        setIsLoading(false);
        showNotification({
          type: "error",
          message: "Failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div className={styles.management_contents}>
      {/* Header */}
      <div
        className={`${styles.management_header} d-flex justify-content-between`}
      >
        <h1 className={styles.management_header_heading}>
          <Link to={`/customer`}>
            <img
              src={BlackLeftArrow}
              alt=""
              className="cursor-pointer"
              title="back"
            />
          </Link>
          Customer Detail
        </h1>
        <div className="management_contents_upload">
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="ruleStatus"
              className={styless.onoffswitch_checkbox}
              onChange={(e) => handleInput(e)}
              checked={ruleStatus}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
        </div>
      </div>
      {/* Header ends*/}

      {isLoading ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "30vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching customer details..." />
            </td>
          </tr>
        </div>
      ) : (
        <>
          <div className={styles.management_details}>
            <h4 className={commonstyles.medium_title}>Customer Detail</h4>
            <div className={styles.management_details_contents}>
              <div className="row">
                <div className="col-lg-2">
                  <img
                    style={{ width: "100%" }}
                    src={customerDetail?.profileImg? customerDetail?.profileImg : defaultImg}
                    className={commonstyles.admin_table_img}
                    alt=""
                  />
                </div>
                <div className="col-lg-8">
                  <div className={commonstyles.customer_detail}>
                    <span className={commonstyles.customer_detail_title}>User Name:</span>
                    <span className={commonstyles.customer_detail_desc}>{capitalize(customerDetail?.name)}</span>
                  </div>
                  <div className={commonstyles.customer_detail}>
                    <span className={commonstyles.customer_detail_title}>Email:</span>
                    <span className={commonstyles.customer_detail_desc}>{customerDetail?.email}</span>
                  </div>
                  {customerDetail?.phone&&<div className={commonstyles.customer_detail}>
                    <span className={commonstyles.customer_detail_title}>Phone:</span>
                    <span className={commonstyles.customer_detail_desc}>+91 {customerDetail?.phone}</span>
                  </div>}
                </div>
                <div className="col-lg-2 text-right" onClick={() => history.push(`/customer/${id}`)}>
                  <img src={editIcon} alt="edit" />
                </div>
              </div>
              <div className={commonstyles.border_top}>
                <div className="row">
                  <div className="col-md-2">

                  </div>
                  <div className="col-md-10 p-0">
                   
                    {savedAddress?._id&&<h4 className={`${commonstyles.medium_title} ml-3`}>Recent Address</h4>}
                    {savedAddress?.shippingAddress?.name&&<div className={`${commonstyles.loyalty_card} row`}>
                      <div className={`col-md-3`}>
                        <div className={commonstyles.address_title}>Shipping Address:</div>
                      </div>
                      <div className={`col-md-9`}>
                        <div className={`${commonstyles.address_details} mb-3`}>
                          {`${capitalize(savedAddress?.shippingAddress?.name)}`} <br />
                          {`${savedAddress?.shippingAddress?.address}`} <br />
                          {`${savedAddress?.shippingAddress?.city}`} <br />
                          {`${savedAddress?.shippingAddress?.state}, ${savedAddress?.shippingAddress?.pincode}`}{" "}
                          <br />
                          {`${savedAddress?.shippingAddress?.phone}`} <br />
                          {`${savedAddress?.shippingAddress?.email}`}
                        </div>
                      </div>
                      <div className={`col-md-3`}>
                        <div className={commonstyles.address_title}>Billing Address:</div>
                      </div>
                      <div className={`col-md-9`}>
                        <div className={`${commonstyles.address_details} mb-3`}>
                          <span className="mb-3">
                            {`${capitalize(savedAddress?.billingAddress?.name)}`} <br />
                            {`${savedAddress?.billingAddress?.address}`} <br />
                            {`${savedAddress?.billingAddress?.city}`} <br />
                            {`${savedAddress?.billingAddress?.state}, ${savedAddress?.billingAddress?.pincode}`}{" "}
                            <br />
                            {`${savedAddress?.billingAddress?.phone}`} <br />
                            {`${savedAddress?.billingAddress?.email}`}
                          </span>
                        </div>
                      </div>
                    </div>}
                    <h4 className={`${commonstyles.medium_title} ml-3`}>Saved Address</h4>
                    <div className={`${commonstyles.loyalty_card} row`}>
                      <div className={`col-md-3`}>
                        <div className={commonstyles.address_title}>Saved Address:</div>
                      </div>
                      <div className={`col-md-9`}>
                        {customerDetail?.savedAddress?.length > 0 && customerDetail?.savedAddress.map((addre, index) => (
                        <div key={addre._id} className={`${commonstyles.address_details} mb-3`}>
                          {/* <div>Ankith Kumar</div>
                          <div>22 'A' main, 1st stage, 2nd cross, JP Nagar. near Guru Kula apartments,</div>
                          <div>Bengaluru - 560048, Karnataka,India,</div>
                          <div>Mobile: 8892117378</div> */}
                          <span className="mb-3">
                            {`${capitalize(addre?.name)}`} <br />
                            {`${addre?.address}`} <br />
                            {`${addre?.city}`} <br />
                            {`${addre?.state}, ${addre?.pincode}`}{" "}
                            <br />
                            {`${addre?.phone}`} <br />
                            {`${addre?.email}`}
                          </span>
                        </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className={commonstyles.medium_title}>Over All Rating</h4>
            <div className={styles.management_details_contents}>
              <div className={`${commonstyles.customer_rating} row`}>
                <div className={`${commonstyles.border_right} col-md-3 d-flex align-items-center justify-content-center text-center`}>
                  <div className={`${commonstyles.customer_rating_value}`}>{customerDetail.rating?.rating?customerDetail.rating?.rating?.toFixed(2):0}</div>
                  <Rating initialValue={customerDetail.rating?.rating?customerDetail.rating?.rating?.toFixed(2):0} readonly={true} size={25} />
                </div>
                <div className={`${commonstyles.border_right} col-md-3 text-center`}>
                  <div className={`${commonstyles.customer_rating_title}`}>Price</div>
                  <Rating initialValue={customerDetail.rating?.price?customerDetail.rating?.price?.toFixed(2):0} readonly size={25} />
                </div>
                <div className={`${commonstyles.border_right} col-md-3 text-center`}>
                  <div className={`${commonstyles.customer_rating_title}`}>Quality</div>
                  <Rating initialValue={customerDetail.rating?.quality?customerDetail.rating?.quality?.toFixed(2):0} readonly size={25} />
                </div>
                <div className={`${commonstyles.border_right} col-md-3 text-center`}>
                  <div className={`${commonstyles.customer_rating_title}`}>Durability</div>
                  <Rating initialValue={customerDetail.rating?.durability?customerDetail.rating?.durability?.toFixed(2):0} readonly size={25} />
                </div>
              </div>
            </div>
            <h4 className={commonstyles.medium_title}>Orders History</h4>
            <h4 style={{ textAlign: 'right' }} className={commonstyles.medium_title}>Total Items: {orderList?.length}</h4>
            {orderList?.length > 0 && orderList.map((item, index) => (
            <div key={item._id} id="accordion" className={`${commonstyles.customer_rating_accordion}`}>
              <Accordion defaultActiveKey="0">
                <Card className={`${commonstyles.accordion_card}`}>
                      <Accordion.Toggle as={Button} variant="link" eventKey={item._id} >
                        <Card.Header className={`${commonstyles.accordion_card_header}`}>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div>
                              <span className={`${commonstyles.order_id}`} onClick={() => history.push(`/order/${item._id}`)}>Order ID {item?.orderId}</span>
                              {/* <span className={`${commonstyles.order_date}`}>10/01/2021 | 07:34:12</span> */}
                              <span className={`${commonstyles.order_date}`}>{item?.createdAt}</span>
                            </div>
                            <img src={arrowDown} className={`${commonstyles.accordion_arrow}`} alt='arrow' />
                          </div>
                          <div className={`row ${commonstyles.accorion_row}`}>
                            <div className={`${commonstyles.order_image_detail} col-md-4`}>
                              <img src={item.products[0]?.feature_image} className={`${commonstyles.order_image}`} />
                              <div className={``}>
                                <h3 className={`${commonstyles.order_image_detail_name}`}>{item.products[0].productName}</h3>
                                <div>
                                  <span className={`${commonstyles.order_image_detail_title}`}>Order Status: </span>
                                  <span className={`${commonstyles.order_image_detail_desc}`}>
                                  {item.products[0].status === 1
                                  ? "Processing"
                                  : item.products[0].status === 2
                                    ? "Shipped"
                                    : item.products[0].status === 3
                                      ? "Delivered"
                                      : item.products[0].status === 4
                                        ? "Cancelled"
                                        : item.products[0].status === 5
                                          ? "Failed"
                                          : item.products[0].status === 6
                                          ? "On-Hold"
                                          : item.products[0].status === 7
                                          ? "Out For Delivery"
                                            : "Delivery attempted"}  
                                  </span>
                                </div>
                                <div>
                                  <span className={`${commonstyles.order_image_detail_title}`}>Quantity:</span>
                                  <span className={`${commonstyles.order_image_detail_desc}`}>{item.products[0].quantity}</span>
                                </div>
                                <div className={`${commonstyles.order_image_detail_amount}`}>Rs. {item.products[0]?.sellPrice? item.products[0].sellPrice:item.products[0].actPrice}</div>
                              </div>
                            </div>
                            <div className={`col-md-3`}>
                              <h3 className={`${commonstyles.order_address_title}`}>Shipping Address</h3>
                              <div className={`${commonstyles.order_address_detail}`}>
                              <span className="mb-3">
                                {`${capitalize(item?.shippingAddress?.name)}`} <br />
                                {`${item?.shippingAddress?.address}`} <br />
                                {`${item?.shippingAddress?.city}`} <br />
                                {`${item?.shippingAddress?.state}, ${item?.shippingAddress?.pincode}`}{" "}
                                <br />
                                {`${item?.shippingAddress?.phone}`} <br />
                                {`${item?.shippingAddress?.email}`}
                              </span>
                              </div>
                            </div>
                            <div className={`col-md-3`}>
                              <h3 className={`${commonstyles.order_address_title}`}>Billing Address</h3>
                              <div className={`${commonstyles.order_address_detail}`}>
                              <span className="mb-3">
                                {`${capitalize(item?.billingAddress?.name)}`} <br />
                                {`${item?.billingAddress?.address}`} <br />
                                {`${item?.billingAddress?.city}`} <br />
                                {`${item?.billingAddress?.state}, ${item?.billingAddress?.pincode}`}{" "}
                                <br />
                                {`${item?.billingAddress?.phone}`} <br />
                                {`${item?.billingAddress?.email}`}
                              </span>
                              </div>
                            </div>
                            <div className={`col-md-2 ${commonstyles.order_status_details}`}>
                              {/* use order_status_completed class for order complete staus*/}
                              <span className={`${commonstyles.order_status} ${item.products[0].status === 3? commonstyles.order_status_completed : commonstyles.order_status_pending}`}>
                              {item.products[0].status === 1
                              ? "Processing"
                              : item.products[0].status === 2
                                ? "Shipped"
                                : item.products[0].status === 3
                                  ? "Delivered"
                                  : item.products[0].status === 4
                                    ? "Cancelled"
                                    : item.products[0].status === 5
                                      ? "Failed"
                                      : item.products[0].status === 6
                                      ? "On-Hold"
                                      : item.products[0].status === 7
                                      ? "Out For Delivery"
                                        : "Delivery attempted"}
                              </span>
                              <a className={`btn ${commonstyles.order_btn}`} onClick={() => history.push(`/order/${item._id}`)}>View Detail</a>
                            </div>
                          </div>
                          {item.products[0]?.reviews?._id&&<div className={`row ${commonstyles.accordion_rating_card}`}>
                            <div className={`col-md-1 ${commonstyles.accordion_rating_card_heading}`}>
                              Rating
                            </div>
                            <div className={`col-md-11`}>
                              <h3 className={`${commonstyles.accordion_rating_card_title}`}>
                                Summary Rating
                                {item.products[0]?.reviews?._id&&<img 
                                  onClick={() => {
                                    if(item.products[0]?.reviews?._id) {
                                      history.push({
                                        pathname: `/rating/${item.products[0]?.reviews?._id}/${index}`,
                                        state: item.products[0]?.reviews,
                                      })
                                    }
                                  }} 
                                  src={editIcon} alt='edit_icon' 
                                />}
                              </h3>
                              <Rating initialValue={item.products[0]?.reviews?.rating?item.products[0]?.reviews?.rating:0} readonly={true} size={25} />
                              <p className={`${commonstyles.accordion_rating_card_des}`}>
                                {item.products[0]?.reviews?.description}
                              </p>
                            </div>
                          </div>}
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={item._id} >
                        <Card.Body className={`${commonstyles.accordion_card_body}`}>
                          {item?.products?.length > 1 && item?.products.map((subItem, indx) => (
                            <div key={subItem._id}>
                            {indx > 0 && (
                            <div>
                            <div className={`row ${commonstyles.accorion_row}`}>
                              <div className={`${commonstyles.order_image_detail} col-md-4`}>
                                <img src={subItem?.feature_image} className={`${commonstyles.order_image}`} />
                                <div className={``}>
                                  <h3 className={`${commonstyles.order_image_detail_name}`}>{subItem.productName}</h3>
                                  <div>
                                    <span className={`${commonstyles.order_image_detail_title}`}>Order Status:</span>
                                    <span className={`${commonstyles.order_image_detail_desc}`}>
                                    {subItem.status === 1
                                      ? "Processing"
                                      : subItem.status === 2
                                        ? "Shipped"
                                        : subItem.status === 3
                                          ? "Delivered"
                                          : subItem.status === 4
                                            ? "Cancelled"
                                            : subItem.status === 5
                                              ? "Failed"
                                              : subItem.status === 6
                                              ? "On-Hold"
                                              : subItem.status === 7
                                              ? "Out For Delivery"
                                                : "Delivery attempted"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={`${commonstyles.order_image_detail_title}`}>Quantity:</span>
                                    <span className={`${commonstyles.order_image_detail_desc}`}>{subItem.quantity}</span>
                                  </div>
                                  <div className={`${commonstyles.order_image_detail_amount}`}>Rs. {subItem?.sellPrice? subItem.sellPrice:subItem.actPrice}</div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <h3 className={`${commonstyles.order_address_title}`}>Shipping Address</h3>
                                <div className={`${commonstyles.order_address_detail}`}>
                                <span className="mb-3">
                                  {`${capitalize(item?.shippingAddress?.name)}`} <br />
                                  {`${item?.shippingAddress?.address}`} <br />
                                  {`${item?.shippingAddress?.city}`} <br />
                                  {`${item?.shippingAddress?.state}, ${item?.shippingAddress?.pincode}`}{" "}
                                  <br />
                                  {`${item?.shippingAddress?.phone}`} <br />
                                  {`${item?.shippingAddress?.email}`}
                                </span>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <h3 className={`${commonstyles.order_address_title}`}>Billing Address</h3>
                                <div className={`${commonstyles.order_address_detail}`}>
                                <span className="mb-3">
                                  {`${capitalize(item?.billingAddress?.name)}`} <br />
                                  {`${item?.billingAddress?.address}`} <br />
                                  {`${item?.billingAddress?.city}`} <br />
                                  {`${item?.billingAddress?.state}, ${item?.billingAddress?.pincode}`}{" "}
                                  <br />
                                  {`${item?.billingAddress?.phone}`} <br />
                                  {`${item?.billingAddress?.email}`}
                                </span>
                                </div>
                              </div>
                              <div className={`col-md-2 ${commonstyles.order_status_details}`}>
                                {/* use order_status_completed class for order completed staus*/}
                                <span className={`${commonstyles.order_status} ${commonstyles.order_status_completed}`}>
                                {subItem.status === 1
                                  ? "Processing"
                                  : subItem.status === 2
                                    ? "Shipped"
                                    : subItem.status === 3
                                      ? "Delivered"
                                      : subItem.status === 4
                                        ? "Cancelled"
                                        : subItem.status === 5
                                          ? "Failed"
                                          : subItem.status === 6
                                          ? "On-Hold"
                                          : subItem.status === 7
                                          ? "Out For Delivery"
                                            : "Delivery attempted"}
                                </span>
                                <a className={`btn ${commonstyles.order_btn}`} onClick={() => history.push(`/order/${item._id}`)}>View Detail</a>
                              </div>
                            </div>
                            {subItem?.reviews?._id&&<div className={`row ${commonstyles.accordion_rating_card}`}>
                              <div className={`col-md-1 ${commonstyles.accordion_rating_card_heading}`}>
                                Rating
                              </div>
                              <div className={`col-md-11`}>
                                {subItem?.reviews?._id&&<h3 className={`${commonstyles.accordion_rating_card_title}`}>
                                  Summary Rating
                                  <img 
                                    onClick={() => {
                                      if(subItem?.reviews?._id) {
                                        history.push({
                                          pathname: `/rating/${subItem?.reviews?._id}/${index}`,
                                          state: subItem?.reviews,
                                        })
                                      }
                                    }} 
                                    src={editIcon} alt='edit_icon' 
                                  />
                                </h3>}
                                <Rating initialValue={subItem?.reviews?.rating?subItem?.reviews?.rating:0} readonly={true} size={25} />
                                <p className={`${commonstyles.accordion_rating_card_des}`}>
                                  {ReactHtmlParser(subItem?.reviews?.description)?.val[0]?.props?.children[0]}
                                </p>
                              </div>
                            </div>}
                            </div>)}
                            </div>
                          ))}
                        </Card.Body>
                      </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>))}
          </div>
        </>
      )}
    </div>
  );
}