import React, { useState, useEffect, useRef } from "react";
import styles from "../../../../styles/PaymentNew.module.css";
import { useHistory, useLocation } from "react-router";
import { AxiosInstance, PORTS } from "../../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Accordion, Card, Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import { isObject, isString, isEqual } from "lodash";
import commonstyles from "../../../../styles/Management_Common.module.css";
import {
  onlyAlphabetsWithSpace,
  phoneRegx,
  passwordReg,
  minChar,
  onlyNumbers,
  minCharwithnospacecount,
  onlyAlphabets,
} from "../../../../utils/RegularExp";
import validationRules from "../../../../utils/validationRules";
import {
  formatServerValidtionErrors,
  runValidationChecks,
} from "../../../../utils";
import TextArea from "../../../modules/ReusableComponent/TextArea";
import BulkUploadModulestyles from "../../../../styles/Bulkupload.module.css";

const reviewData_ = {
  description: "",
  // gallery: [],
  individual: { price: null, quality: null, durability: null },
  rating: null,
  status: 1,
  title: "",
  userName: "",
  adminApprovalStatus: 1,
  // createdAt: new Date(),
};

let stttatus;

export default function PaymentNew() {
  const history = useHistory();
  const { state } = history?.location;
  const [reviewData, setReviewData] = useState(state);
  const [pricerating, setPriceRating] = useState(null);
  const [qualityrating, setQualityRating] = useState(null);
  const [durabilityrating, setDurabilityRating] = useState(null);
  const [summaryrating, setSummaryRating] = useState(null);
  const [ratingsForm, setRatingsForm] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [productName, setProductName] = useState("");
  const [ratingPrice, setRatingPrice] = useState(state?.individual?.price);
  const [ratingQuality, setratingQuality] = useState(state?.individual?.quality);
  const [ratingDurability, setratingDurability] = useState(state?.individual?.durability);
  const overallRating = (parseInt(state?.individual?.price) + parseInt(state?.individual?.quality) + parseInt(state?.individual?.durability)) / 3;
  const [ratingSummary, setRatingSummary] = useState(overallRating);

  const [hover, setHover] = useState(null);
  let { id } = useParams();
  let location = useLocation();

  const rules = {
    userName: [(val, data) => (!val ? "Username " : true)],
    status: [(val, data) => (!val ? "Status " : true)],
    title: [(val, data) => (!val ? "Summary " : true)],
    description: [(val, data) => (!val || val.length < 3 ? "Description " : true)],
  };

  useEffect(() => {
    getReviewDetail();
  }, []);

  function submit(e) {

    let data = {
      checkReviewer: 1,
      productId: id,
      reviews: [{ _id: reviewData._id, status: reviewData.status }],
    };

    const apiEndPoint = "products/bulkUpdateReviews";

    AxiosInstance(PORTS.INVOICE)
      .post(apiEndPoint, data)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Reviews updated successfully",
          });
          history.push(`/rating`);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description:
              "Convert all review status from requested to accepted/rejected state",
          });
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /**
   * @author Rajkumar
   * @param {}
   * GET ALL REVIEW DETAILS AND SETTING STATE SEPERATELY
   */
  function getReviewDetail() {
    AxiosInstance(PORTS.INVOICE, "application/json")
      .get(`products/reviewDetailofProduct/${id}`)
      .then((res) => {
        // setReviewId(res.data.data.map((review) => review.reviews[0]._id));
        if (res && res.data && res.status == "200") {
          setProductName(res.data.data[0].name);
          setRatingsForm(res.data.data[0].reviews);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => { });
  }

  /**
   * @author Rajkumar
   * @param {EVENT}
   * CHANGES IN VALUES AND SETTINGS ERROR
   */
  function reviewHandler(e) {
    const { value, name } = e.target;
    const cloneReviewData = { ...reviewData };
    //sread all formErrors and set in cloneformErrors in the end
    const cloneformErrors = { ...formErrors };
    if (name === "userName" && !minChar.test(value)) {
      cloneformErrors.userName = "Enter minimum 3 characters";
    } else if (name === "userName") {
      cloneformErrors.userName = "";
    }

    if (name === "title" && !minChar.test(value)) {
      cloneformErrors.title = "Enter minimum 3 characters";
    } else if (name === "title") {
      cloneformErrors.title = "";
    }

    if (name === "description" && !minChar.test(value)) {
      cloneformErrors.description = "Enter minimum 3 characters";
    } else if (name === "description") {
      cloneformErrors.description = "";
    }

    if (["description"].includes(name) && value?.trim()) {
      cloneReviewData[name] = value;
    } else if (name === "description") {
      cloneReviewData[name] = "";
    }

    if (["title", "userName"].includes(name) && value?.trim()) {
      cloneReviewData[name] = value;
    } else if (name === "title" || name === "userName") {
      cloneReviewData[name] = "";
    }

    setReviewData(cloneReviewData);
    if (["status"].includes(name)) {
      reviewData[name] = parseInt(value);
      stttatus = parseInt(value);
    }
    setFormErrors(cloneformErrors);
  }

  function handleReviewHandler(e) {
    let { name, value } = e.target;
    const cloneReviewData = { ...reviewData };
    if (["status"].includes(name)) {
      cloneReviewData.status = parseInt(value);
    }
    setReviewData(cloneReviewData);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push(`/rating/${id}`)}
          />
          View/Edit Review
        </h1>
        <div className={styles.management_contents_upload}>
        </div>
      </div>

      <div className={styles.management_details}>
        <div className={styles.management_details_contents}>
          <label className="mb-4">
            Product
            <a className={styles.management_product_link}>{productName}</a>
          </label>
          <div className="row">
            <div className="col-lg-6 mb-4">
                <div className={styles.management_details_form}>
                  <label for="">
                    Status
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <SelectComponent
                    className={`form-control form-control-md ${styles.form_control}`}
                    attributes={{
                      name: "status",
                      value: reviewData?.status,
                      onChange: (e) => handleReviewHandler(e),
                    }}
                  >
                    <option>Change Status</option>
                    <option value={1}>Accept</option>
                    <option value={2}>Reject</option>
                  </SelectComponent>
                  <FormErrorText />
                </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Username
                  <span
                    className="text-danger top-10"
                    style={{ fontSize: "20px" }}
                  >
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.userName}
                  formControl={{
                    name: "userName",
                    value: reviewData?.userName,
                    onChange: (e) => reviewHandler(e),
                    maxLength: "30",
                    disabled: true
                  }}
                />
                <FormErrorText error={formErrors.userName} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Author
                  <span
                    className="text-danger top-10"
                    style={{ fontSize: "20px" }}
                  >
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  formControl={{
                    placeholder: "",
                    name: "author",
                    value: localStorage.getItem("roleName"),
                    disabled: true
                    // onChange: (e) => reviewHandler(e),
                  }}
                />
                <FormErrorText />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Summary of review
                  <span
                    className="text-danger top-10"
                    style={{ fontSize: "20px" }}
                  >
                    *
                  </span>
                </label>
                <InputComponent
                  className={`form-control form-control-lg ${styles.form_control}`}
                  error={formErrors.title}
                  formControl={{
                    placeholder: "",
                    name: "title",
                    value: reviewData?.title,
                    onChange: reviewHandler,
                    maxLength: "30",
                    disabled: true
                  }}
                />
                <FormErrorText error={formErrors.title} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div id="accordion">
                <Accordion defaultActiveKey="0">
                  <Card
                    className={`${styles.accordion_rating_rating_card} br-10`}
                  >
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <Card.Header
                        className={`${styles.accordion_rating_card_header}`}
                      >
                        <div className={styles.management_details_form}>
                          <label
                            for=""
                            className={`${styles.accordion_rating_title}`}
                          >
                            Summary Rating
                            <img
                              src={arrowDown}
                              className={`${styles.accordion_rating_arrow}`}
                              alt="arrow"
                            />
                          </label>

                          {[...Array(1)].map((star, indx) => {
                            return (
                              <Rating
                                readonly={true}
                                size={30}
                                name="rating"
                                initialValue={
                                  ratingSummary
                                }
                              />
                            );
                          })}
                        </div>
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body
                        className={`${styles.accordion_rating_card_body}`}
                      >
                        <div
                          className={`${styles.management_details_form} mb-4`}
                        >
                          <label for="">Price </label>
                          {[...Array(1)].map((star, indx) => {
                            return (
                              <Rating
                                size={30}
                                readonly={true}
                                name="price"
                                initialValue={ratingPrice} /* Available Props */
                              />
                            );
                          })}
                        </div>

                        <div
                          className={`${styles.management_details_form} mb-4`}
                        >
                          <label for="">Quality </label>
                          {[...Array(1)].map((star, indx) => {
                            return (
                              <Rating
                                size={30}
                                readonly={true}
                                name="quality"
                                initialValue={
                                  ratingQuality
                                } /* Available Props */
                              />
                            );
                          })}
                        </div>
                        <div
                          className={`${styles.management_details_form} mb-4`}
                        >
                          <label for="">Durability </label>
                          {[...Array(1)].map((star, indx) => {
                            return (
                              <Rating
                                size={30}
                                readonly={true}
                                name="durability"
                                initialValue={
                                  ratingDurability
                                } /* Available Props */
                              />
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className={`${BulkUploadModulestyles.management_details_form} `}>
                <h4>Images</h4>
                <div className={BulkUploadModulestyles.management_details_contents}>
                  <div className={`${BulkUploadModulestyles.management_details_form} ${BulkUploadModulestyles.bulkuploadimageheight}`}>
                    <div className={BulkUploadModulestyles.bulk_img_list}>
                      {reviewData?.gallery?.length > 0 && reviewData?.gallery.map((item, index) => (
                          <img 
                            class={BulkUploadModulestyles.added_image} 
                            key={index}
                            src={item?.link} 
                            alt={index}
                          />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className={styles.management_details_form}>
                <label for="">
                  Description
                  <span
                    className="text-danger top-10"
                    style={{ fontSize: "20px" }}
                  >
                    *
                  </span>
                </label>
                <TextArea
                  className={`${commonstyles.form_control} form-control form-control-lg `}
                  error={formErrors.description}
                  formControl={{
                    name: "description",
                    value: reviewData?.description,
                    placeholder: "Type your description",
                    rows: 4,
                    disabled: true,
                    onChange: (e) => reviewHandler(e),
                  }}
                />
                <FormErrorText error={formErrors.description} />
              </div>
            </div>
            <div className={`${styles.btn_section} col-lg-12`}>
              <a className={`${styles.rating_btn} btn`} onClick={submit}>
                Save review
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}