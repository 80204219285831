import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import stylles from "../../../styles/AddDiscount.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { lte } from "lodash";
import { runValidationChecks } from "../../../utils";
import Exclusion from "../../../assets/images/exclusion.svg";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import TextArea from "../../modules/ReusableComponent/TextArea";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import {
  alphaNum,
  floatingZero,
  onlyTenNumbers,
  minChar,
  pinCode,
  userNameWithSpace,
  telephoneNumber,
  phoneRegx,
  telePhoneRegex,
} from "../../../utils/RegularExp";
import ProductMediaUploadForm from "../../modules/ProductManagement/ProductMediaUploadForm";
import styless from "../../../styles/AddProduct.module.css";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import FaqFormComponent from "../../modules/ProductManagement/FaqFormComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import { isObject, isString, isEqual } from "lodash";
import ImagePreviewChip from "../../modules/ProductManagement/ImagePreviewChip";
import { Link, useParams } from "react-router-dom";
// import TimePicker from "react-time-picker";
import { TimePicker } from "antd";
import moment from "moment";

const { Option } = Select;

export default function AddBannerComponent() {
  const { id } = useParams();
  const initialFormState = {
    state: "",
    location: "",
    regionId: "",
    cityId:"",
    telephone: "",
    landLine: "",
    latitude: "",
    longitude: "",
    workingStartDay: "",
    workingEndDay: "",
    startTime: "",
    endTime: "",
    pincode: "",
    address: "",
    enable: true,
    gallery: [] || localFileData.filesPreview,
    feature_image: "",
  };

  const history = useHistory();

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState([]);

  const [localFileData, setLocalFileData] = useState({
    files: [],
    filesPreview: [],
  });

  //file
  const [files, setFiles] = useState({
    feature_image: null,
  });

  const [filePreview, setFilePreview] = useState({
    feature_image: null,
  });

  const [editForm, setEditForm] = useState(initialFormState);

  const [startTime, setStartTime] = useState("");

  const [endTime, setEndTime] = useState("");
  const [cities, setCities] = useState([])

  const rules = {
    state: [(val, data) => (!val ? "State " : true)],
    location: [(val, data) => (!val ? "Location " : true)],
    latitude: [(val, data) => (!val ? "Latitude " : true)],
    longitude: [(val, data) => (!val ? "Longitude " : true)],
    workingStartDay: [(val, data) => (!val ? "Working start day " : true)],
    workingEndDay: [(val, data) => (!val ? "Working end day " : true)],
    startTime: [(val, data) => (!startTime ? "Start time " : true)],
    endTime: [(val, data) => (!endTime ? "End time " : true)],
    // image: [(val, data) => !file && "Required"],
    feature_image: [
      (val, data) =>
        !editForm.feature_image && !files.feature_image
          ? "Feature image"
          : true,
    ],
    regionId: [(val, data) => (!val ? "Region " : true)],
    cityId: [(val, data) => (!val ? "City " : true)],
    pincode: [(val, data) => (!val ? "Pincode " : true)],
    address: [(val, data) => (!val ? "Address " : true)],
  };

  useEffect(() => {
    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("admin/region/getRegionList")
        .then((res) => {
          if (res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: "Error occured",
          });
        });
    }
    function getCityList(){
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get(`/cities?pagesize=10&nextpage=1`)
        .then((res) => {
          if (res && res.data && res.status == "200") {
            if (res.data?.dropDown?.length > 0) {
              setCities(res.data?.dropDown)
            } else {
              setCities([]);
            }
          }
        })
        .catch((er) => {
          const data = { type: "error" };
          if (er.response) {
            data.message = "Error";
            data.description = "Failed to get data";
            setIsLoading(false);
            showNotification(data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    getCityList()
    getRegionList();
  }, []);

  function handleRegionChange(e) {
    editForm.regionId = e;
    setEditForm({ ...editForm });
  }

  // *** ONCHANGE HANDLER FOR TIME *** //
  const onChangeTimeHandlerStart = (time, type) => {
    const startTime = moment(type, "HH:mm").format("hh:mm:00 A");
    setStartTime(startTime);
    setFormErrors({ ...formErrors, startTime: "" });
  };

  const onChangeTimeHandlerEnd = (time, type) => {
    const endTime = moment(type, "HH:mm").format("hh:mm:00 A");
    setEndTime(endTime);
    setFormErrors({ ...formErrors, endTime: "" });
  };

  function handleImageInput(e) {
    const { name, files: inputFiles } = e.target;

    if (inputFiles[0].size > 1024 * 1024 * 3) {
      showNotification({
        type: "error",
        message: "There were few errors",
        description: "Please upload file less than 3MB size",
      });
      return;
    } else {
      if (filePreview[name]) {
        URL.revokeObjectURL(filePreview[name]);
      }
      const url = URL.createObjectURL(inputFiles[0]);
      setFiles({ ...files, [name]: inputFiles[0] });
      setFilePreview({ ...filePreview, [name]: url });
    }
  }

  function removeFile(name) {
    if (filePreview[name]) {
      URL.revokeObjectURL(filePreview[name]);
    }
    setFiles({ ...files, [name]: null });
    setFilePreview({ ...filePreview, [name]: null });
  }

  useEffect(() => {
    if (id) {
      getWarehouseDetails(id);
    } else {
      setIsLoading(false);
    }
    /* getRegionList();  */
  }, []);

  const [editZoneId, setEditZoneId] = useState("");

  /**
   * @author Mithun Prabhu
   * @param {ID}
   * To get WAREHOUSE DETAILS BY CALLING SHOWRROMDETAILS API
   */
  function getWarehouseDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/admin/shworoom/getshowroomDetails/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEditForm(val);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }

  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * INPUT HANDLE CHANGE
   */
  function handleFormInput(e) {
    let { name, value, checked } = e.target;


    if (name === "address") {
      if (!value.match(userNameWithSpace) || !value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };
    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * SUBMIT FORM WITH ALL VALUES AND UPDATE SHOWROOM
   */
  function submit(e) {
    setLoading({ details: false, update: true });
    if (formErrors.latitude || formErrors.latitude || formErrors.address) {
      setLoading({ details: false, update: false })
      return;
    }
    console.log(formErrors.telePhone,formErrors.landLine,"formErrors.landLine")
    if((formErrors?.telePhone && formErrors?.telePhone !== "") ||
     (formErrors?.landLine && formErrors?.landLine !== "")){
       setLoading({ details: false, update: false });
       return showNotification({
        type: "warning",
        message: "Please enter a valid input(s)",
      })
    }
    let data = {
      state: editForm.state,
      location: editForm.location,
      regionId: editForm.regionId,
      cityId: editForm.cityId,
      telephone: editForm.telephone,
      landLine: editForm.landLine,
      latitude: Number(editForm.latitude),
      longitude: Number(editForm.longitude),
      startTime: startTime,
      endTime: endTime,
      workingStartDay: editForm.workingStartDay,
      workingEndDay: editForm.workingEndDay,
      pincode: editForm.pincode,
      enable: editForm.enable,
      address: editForm.address,
    };

    if (editForm.description) {
      data.description = editForm.description;
    }

    //validation
    if (data.state) {
      if (!userNameWithSpace.test(data.state) || !data.state === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, state: "" });
      }
    }

    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("feature_image", files.feature_image);

    for (let i = 0; i < localFileData.files.length; i++) {
      formData.append("gallery", localFileData.files[i]);
    }

    // ["feature_image"].forEach((field) => {
    //   if (files[field]) {
    //     formData.append(field, files[field]);
    //   }
    // });

    // const result = validate(undefined, data);
    const result = runValidationChecks(rules, undefined, {
      ...data,
      files,
    });

    if (Object.keys(result).length) {
      setFormErrors({...formErrors , result});
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: Object.values(result) + "is required",
      });
      setLoading({ details: false, update: false })
      return;
    }

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      //    .post("/banner/createBanner", formData)
      .post(
        `/admin/${id ? "shworoom/updateShowroom" : "addShowroom"}`,
        formData
      )
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: id
              ? "Store updated successfully"
              : "Store added successfully",
          });
          //setFilter(intialState());
          history.push(`/catalog/store`);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Adding store failed",
            description: res.data?.msg || "Error occured",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Adding store failed",
          description: "Error occured",
        });
      })
      .finally(() => {
        setLoading({ details: false, update: false });
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  /**
   * @author Mithun Prabhu
   * @param {fileDetails, form}
   * UPLOADING MULTIPLE IMAGES AND SET IN STATE
   */
  function handleMediaUpdate(fileDetails, form) {
    if (form === "gallery") {
      setEditForm({ ...editForm, gallery: fileDetails });
    } else if (form === "files") {
      setLocalFileData(fileDetails);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {lat}
   * pattern for validate latitude and longitude
   */
  function validateLatLng(lat) {
    let pattern = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");

    return pattern.test(lat);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/catalog/store")}
          />
          {id ? "Store Details" : "Add Store"}
        </h1>
        <div className={styless.management_contents_upload}>
          <div className={`${styless.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styless.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm.enable}
              id="statusSwitch"
            />
            <label className={styless.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styless.onoffswitch_inner}></span>
              <span className={styless.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/catalog/store`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: submit,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching banner details..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>

          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Title{" "}
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>

                <InputComponent
                  error={formErrors.state}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type store title",
                    name: "state",
                    value: editForm?.state,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.state) {
                          formErrors.state = {};
                        }
                        formErrors.state = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.state) {
                          formErrors.state = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
                <FormErrorText error={formErrors.state} />
              </div>

              <div className="col-lg-6 mb-4">
                <label>
                  Warehouse
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.regionId}
                  className="form-control-md"
                  attributes={{
                    name: "regionId",
                    value: editForm.regionId,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.regionId) {
                          formErrors.regionId = {};
                        }
                        formErrors.regionId = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.regionId) {
                          formErrors.regionId = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select Warehouse
                  </option>
                  {Array.isArray(regions) && regions.length
                    ? regions.map((region) => (
                      <option key={region._id} value={region._id}>
                        {region.name}
                      </option>
                    ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.regionId} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Phone
                </label>
                <div className={commonstyles.adduser_input_countycode}>
                  <span>+91</span>
                  <InputComponent
                    error={formErrors.telephone}
                    className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                    formControl={{
                      placeholder: "Type phone number",
                      name: "telephone",
                      value: editForm?.telephone,
                      onChange: (e) => {
                        if(e.target.value.trim() === ""){
                          setFormErrors({...formErrors,telephone:""})
                        }
                        else if (
                          !phoneRegx.test(e.target.value)
                        ) {
                          if (!formErrors.telephone) {
                            formErrors.telephone = {};
                            setFormErrors({...formErrors,telephone:""})
                          }
                          formErrors.telephone =
                            setFormErrors({...formErrors,telephone:"Enter valid phone number"})
                        } else {
                          if (formErrors.telephone) {
                            formErrors.telephone = "";
                            setFormErrors({...formErrors,telephone:""})
                          }
                        }
                        handleFormInput(e);
                      },
                    }}
                  />
                  <FormErrorText error={formErrors.telephone} />
                </div>
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Telephone
                </label>
                <div className={commonstyles.adduser_input_countycode}>
                  <InputComponent
                    error={formErrors.landLine}
                    className={`form-control-lg ${commonstyles.adduser_input_countycode_form}`}
                    style={{"padding":"0.5rem 1rem"}}
                    formControl={{
                      placeholder: "Type telephone number",
                      name: "landLine",
                      value: editForm?.landLine
                      .replace(/ /g,'')
                              .replace(/^(.{6})/,"$1 ")
                              .replace(/^(.{3})/,"$1 ").trim(),
                      onChange: (e) => {
                        if(e.target.value.trim() === ""){
                          setFormErrors({...formErrors,landLine:""})
                        }
                        else if (
                          !telephoneNumber.test(e.target.value.replace(/\s/g, '')) &&
                          !telePhoneRegex.test(e.target.value.replace(/\s/g, '')) 
                        ) {
                          if (!formErrors.landLine) {
                            formErrors.landLine = {};
                            setFormErrors({...formErrors,landLine:""})
                          }
                            setFormErrors({...formErrors,landLine:"Enter valid telephone number"})
                        } else {
                          if (formErrors.landLine) {
                            setFormErrors({...formErrors,landLine:""})
                          }
                        }
                        handleFormInput(e);
                      },
                    }}
                  />
                </div>
                <FormErrorText error={formErrors.landLine} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Pincode
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.pincode}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type pincode",
                    name: "pincode",
                    value: editForm?.pincode,
                    onChange: (e) => {
                      if (
                        !pinCode.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.pincode) {
                          formErrors.pincode = {};
                        }
                        formErrors.pincode = "Enter valid pincode";
                      } else {
                        if (formErrors.pincode) {
                          formErrors.pincode = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.pincode} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Location
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.location}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type location",
                    name: "location",
                    value: editForm?.location,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.location) {
                          formErrors.location = {};
                        }
                        formErrors.location = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.location) {
                          formErrors.location = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.location} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Latitude
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.latitude}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type latitude",
                    name: "latitude",
                    // value: editForm?.latitude,
                    onChange: (e) => {
                      if (validateLatLng(e.target.value)) {
                        formErrors.latitude = "";
                      } else {
                        formErrors.latitude = "Enter valid latitude";
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.latitude} />
              </div>

              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Longitude
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <InputComponent
                  error={formErrors.longitude}
                  className="form-control-lg"
                  formControl={{
                    placeholder: "Type longitude",
                    name: "longitude",
                    value: editForm?.longitude,
                    onChange: (e) => {
                      if (validateLatLng(e.target.value)) {
                        formErrors.longitude = "";
                      } else {
                        formErrors.longitude = "Enter valid longitude";
                      }
                      handleFormInput(e);
                    },
                  }}
                />
                <FormErrorText error={formErrors.longitude} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Work start day
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.workingStartDay}
                  className="form-control-md"
                  attributes={{
                    name: "workingStartDay",
                    value: editForm.workingStartDay,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.workingStartDay) {
                          formErrors.workingStartDay = {};
                        }
                        formErrors.workingStartDay =
                          "Enter minimum 3 characters";
                      } else {
                        if (formErrors.workingStartDay) {
                          formErrors.workingStartDay = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select start day
                  </option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </SelectComponent>
                <FormErrorText error={formErrors.workingStartDay} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Work end day
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.workingEndDay}
                  className="form-control-md"
                  attributes={{
                    name: "workingEndDay",
                    value: editForm.workingEndDay,
                    onChange: (e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.workingEndDay) {
                          formErrors.workingEndDay = {};
                        }
                        formErrors.workingEndDay = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.workingEndDay) {
                          formErrors.workingEndDay = "";
                        }
                      }
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select end day
                  </option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </SelectComponent>
                <FormErrorText error={formErrors.workingEndDay} />
              </div>
              <div className="col-lg-6 mb-4">
                <div className="row store-timepicker">
                  <div className="col-lg-6">
                    <label className={`${commonstyles.secondry_title}`}>
                      Start time
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <TimePicker
                      style={!formErrors.startTime ? {
                        borderColor: "none"
                      } : { borderColor: "red" }}
                      // use12Hours
                      name="startTime"
                      format="HH:mm"
                      onChange={onChangeTimeHandlerStart}
                      // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      value={startTime && moment(startTime, "HH:mm:ss A")}
                    />
                    <FormErrorText error={formErrors.startTime} />
                  </div>
                  <div className="col-lg-6">
                    <label className={commonstyles.secondry_title}>
                      End time
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>
                    <TimePicker
                      style={!formErrors.startTime ? {
                        borderColor: "none"
                      } : { borderColor: "red" }}
                      name="endTime"
                      format="HH:mm"
                      // use12Hours
                      onChange={onChangeTimeHandlerEnd}
                      // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      value={endTime && moment(endTime, "HH:mm:ss A")}
                    />
                    <FormErrorText error={formErrors.endTime} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <label>
                  City
                  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span>
                </label>
                <SelectComponent
                  error={formErrors.cityId}
                  className="form-control-md"
                  attributes={{
                    name: "cityId",
                    value: editForm.cityId,
                    onChange: (e) => {
                      handleFormInput(e);
                    },
                  }}
                >
                  <option value="" disabled>
                    Select city
                  </option>
                  {Array.isArray(cities) && cities.length
                    ? cities.map((city) => (
                      <option key={city._id} value={city._id}>
                        {city.name}
                      </option>
                    ))
                    : null}
                </SelectComponent>
                <FormErrorText error={formErrors.cityId} />
              </div>
              <div className="col-lg-12 mb-4">
                <div
                  className={stylles.management_details_contents}
                // style={{ marginRight: "270px" }}
                >
                  <label className={commonstyles.secondry_title}>
                    Address
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <TextArea
                    className={`${commonstyles.form_control} form-control form-control-lg `}

                    error={formErrors.address}
                    formControl={{
                      name: "address",
                      value: editForm?.address,
                      placeholder: "Type address",

                      rows: 4,
                      onChange: (e) => handleFormInput(e),
                    }}
                  />
                </div>
                <FormErrorText error={formErrors.address} />
              </div>
              <div className="col-lg-12 mb-4">
                <div
                  className={stylles.management_details_contents}
                // style={{ marginRight: "270px" }}
                >
                  <label className={commonstyles.secondry_title}>
                    Description
                  </label>
                  <textarea
                    className={`${commonstyles.form_control} form-control form-control-lg `}
                    placeholder="Type description"
                    rows="4"
                    cols="20"
                    value={editForm.description}
                    autoComplete="off"
                    name="description"

                    onChange={(e) => {
                      if (
                        !minChar.test(e.target.value) &&
                        e.target.value.trim() !== " "
                      ) {
                        if (!formErrors.description) {
                          formErrors.description = {};
                        }
                        formErrors.description = "Enter minimum 3 characters";
                      } else {
                        if (formErrors.description) {
                          formErrors.description = "";
                        }
                      }
                      handleFormInput(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row store-addimages">
            <div className="col-lg-6 mb-4">
              <div className={commonstyles.management_details_contents}>
                <div className={styless.upload_img_header}>
                  <label className={commonstyles.secondry_title}>
                    Feature image
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                </div>

                <div className={commonstyles.management_details_contents}>
                  <div className={styless.upload_img_header}></div>
                  <div
                    className={`${commonstyles.management_details_form} ${styless.add_featureImage_card}`}
                  >
                    {filePreview.feature_image || id ? (
                      <>
                        <ImagePreviewChip
                          url={
                            id
                              ? editForm.feature_image
                              : filePreview.feature_image
                          }
                          className={styless.added_image}
                          width="60px"
                          height="60px"
                          handleClose={() => removeFile("feature_image")}
                        />
                      </>
                    ) : (
                      <div
                        className={` ${styless.upload_img} ${styless.storeaddupload_img} text-center`}
                      >
                        <img src={Exclusion} alt="" />
                        <div>
                          <label
                            htmlFor="categoryFileInput"
                            className={`${styless.btn} ${styless.storeupload_img} px-2 `}
                          >
                            Add file
                            <InputComponent
                              error={formErrors.feature_image}
                              className="form-control-lg"
                              formControl={{
                                type: "file",
                                name: "feature_image",
                                style: { cursor: "pointer" },
                                accept: "image/jpeg, image/png, image/svg+xml",
                                id: "categoryFileInput",
                                onChange: (e) => handleImageInput(e),
                              }}
                            />
                            <FormErrorText error={formErrors.feature_image} />
                          </label>
                        </div>
                        <div className={styless.upload_drop_file}>
                          <span>or drop a file to Upload</span>
                        </div>
                        {/* <span>or drop a file to Upload</span> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ProductMediaUploadForm
              localFileData={localFileData}
              gallery={editForm.gallery}
              updateMediaForm={handleMediaUpdate}
            />
          </div>

        </div>
      )}
      {/*Add Add Warehouse start */}
      <div style={{ display: "none" }}>
        <div className={styles.management_contents}>
          <div className={styles.management_header}>
            <h1>
              <img
                src={BlackLeftArrow}
                alt=""
                className="cursor-pointer"
                onClick={() => history.push("/warehouse")}
              />
              {id ? "Store Details" : "Add Store"}
            </h1>
            <div className={styles.management_contents_upload}>
              <ButtonComponent
                className="rnd"
                attributes={{
                  disabled: loading.update,
                  onClick: submit,
                }}
              >
                {loading.update ? "Saving" : "Save"}
              </ButtonComponent>
            </div>
          </div>
        </div>
        {isLoading === true ? (
          <tr className="bg-transparent d-flex justify-content-center">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching banner details..." />
            </td>
          </tr>
        ) : (
          <div className={styles.management_details}>

            <div className={styles.management_details_contents}>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <label>
                    Warehouse

                  </label>
                  <SelectComponent
                    error={formErrors.regionId}
                    className="form-control-md"
                    attributes={{
                      name: "regionId",
                      value: editForm.regionId,
                      onChange: (e) => {
                        if (
                          !minChar.test(e.target.value) &&
                          e.target.value.trim() !== " "
                        ) {
                          if (!formErrors.regionId) {
                            formErrors.regionId = {};
                          }
                          formErrors.regionId = "Enter minimum 3 characters";
                        } else {
                          if (formErrors.regionId) {
                            formErrors.regionId = "";
                          }
                        }
                        handleFormInput(e);
                      },
                    }}
                  >
                    <option value="" disabled>
                      Select Warehouse
                    </option>
                    {Array.isArray(regions) && regions.length
                      ? regions.map((region) => (
                        <option key={region._id} value={region._id}>
                          {region.name}
                        </option>
                      ))
                      : null}
                  </SelectComponent>
                  <FormErrorText error={formErrors.regionId} />
                </div>

                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    City{" "}

                  </label>

                  <InputComponent
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type Slug",
                      name: "city",
                    }}
                  />
                  {/* <FormErrorText error={formErrors.name ? "Required" : ""}/> */}
                  <FormErrorText error={formErrors.state} />
                </div>
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Store Name
                    {/* <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                        *
                      </span> */}
                  </label>
                  <InputComponent
                    error={formErrors.telephone}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type Title",
                      name: "typetitle",
                    }}
                  />
                </div>

                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Phone Number
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    error={formErrors.location}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Phone Number",
                      name: "location",
                    }}
                  />
                  <FormErrorText error={formErrors.location} />
                </div>

                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Latitude
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control-lg"
                    formControl={{
                      placeholder: "Type latitude",
                    }}
                  />
                </div>

                <div className="col-lg-12 mb-4">
                  <div
                    className={stylles.management_details_contents}
                  // style={{ marginRight: "270px" }}
                  >
                    <label className={commonstyles.secondry_title}>
                      Address
                      <span
                        className="text-danger"
                        style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                      >
                        *
                      </span>
                    </label>

                    <textarea
                      className={`${commonstyles.form_control} form-control form-control-lg `}
                      error="form-control theme-form-control theme-error-input"
                      rows="3"
                      cols="20"
                      placeholder="Type address"
                      name="address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div
                  className={`${styles.management_details_contents} col-lg-6 mb-4`}
                >
                  <label className={commonstyles.secondry_title}>
                    Assign Admin
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <SelectComponent
                    error={formErrors.workingEndDay}
                    className="form-control-md"
                  >
                    <option value="" disabled>
                      Select end day
                    </option>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </SelectComponent>
                  <p className={`${styles.addadminextra} mb-0 mt-2`}>
                    + Add Admin
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*Add Add Warehouse end */}
    </div>
  );
}
