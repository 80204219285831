import React, { useState, useRef } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
// import commonstyles from "../../../../styles/Management_Common.module.css";
import styles from "../../../styles/Bulkupload.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import CloseIconSmall from "../../../assets/images/close_icon_small.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { Alert, notification } from "antd";

export default function BulkUploadComponent(props) {
  const [entityType, setEntityType] = useState("product");
  const [file, setFile] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [response, setResponse] = useState({});
  const inputFileElRef = useRef(null);
  const { handleClose } = props;
  // console.log(props);
  const APIendPoints = {
    customer: "/admin/customer/bulkImportCustomer",
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (name === "entityType") {
      setEntityType(value);
      removeFile();
      if ("key" in response) {
        setResponse({});
      }
    }
  }

  function handleFileInput(e) {
    const { files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      console.log(file);
      setFile(file);
      if ("key" in response) {
        setResponse({});
      }
    }
  }

  function removeFile() {
    inputFileElRef.current.value = null;
    setFile(null);
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  /**
   * @author Mithun Prabhu
   * @param {}
   * CUSTOMER BULK UPLOAD API
   */
  function submitBulkUpload() {
    setIsloading(true);
    setResponse({});
    const url = APIendPoints[entityType];

    if (props.region === "region") {
      const formData = new FormData();
      formData.append("marginFile", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AxiosInstance(PORTS.CMS, undefined)
        .post("/admin/customer/bulkImportCustomer", formData, config)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            setResponse({
              type: "success",
              message: res.data.msg,
              key: Date.now(),
            });
            removeFile();
            setTimeout(() => {
              setResponse({});
            }, 10000);
          } else if (res.data.status === 400) {
            setResponse({
              type: "error",
              message: res.data.msg,
              key: Date.now(),
            });
          } else {
            showNotification({
              type: "error",
              message: "Failed",
              description: res?.data?.msg || "Failed to upload data",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: er?.message || "Error occured",
          });
        })
        .finally(() => setIsloading(false));
    } else {
      const formData = new FormData();
      formData.append("marginFile", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AxiosInstance(PORTS.CMS, undefined)
        .post(url, formData, config)
        .then((res) => {
          if (res.data.status === 200) {
            setResponse({
              type: "success",
              message: res.data.msg,
              key: Date.now(),
            });
            removeFile();
            setTimeout(() => {
              setResponse({});
            }, 10000);
          } else if (res.data.status === 400) {
            setResponse({
              type: "error",
              message: res.data.msg,
              key: Date.now(),
            });
          } else {
            showNotification({
              type: "error",
              message: "Failed",
              description: res?.data?.msg || "Failed to upload data",
            });
          }
        })
        .catch((er) => {
          showNotification({
            type: "error",
            message: "Failed",
            description: er?.message || "Error occured",
          });
        })
        .finally(() => setIsloading(false));
    }
  }
  console.log(props);
  return (
    <div className={styles.management_contents}>
      {/* Header */}
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt="Click to go back img"
            onClick={handleClose}
            className="cursor-pointer"
          />
          Bulk Upload
        </h1>
        <div className={styles.management_contents_upload}>
          {/* <button
            className={`${styles.btn} ${styles.btn_filled} text-decoration-none cursor-pointer`}
            disabled={!file}
          >
            Save
          </button> */}
          <ButtonComponent
            className="rnd"
            attributes={{ disabled: !file, onClick: submitBulkUpload }}
          >
            Upload
          </ButtonComponent>
        </div>
      </div>
      {/* Header ends*/}

      <div className={styles.management_details}>
        <div className="row mb-4">
          <div className="col-lg-6">
            {/* Select Entity type */}

            {!props.region === "region" || props.product === "product" ? (
              <>
                <h4>Import</h4>
                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_form}>
                    <label htmlFor="">Entity Type</label>
                    <select
                      className={`${styles.form_control} form-control`}
                      name="entityType"
                      value={entityType}
                      onChange={handleInputChange}
                    >
                      <option value="product">Product</option>
                      <option value="inventoryAndPrice">
                        Inventory & price
                      </option>
                      <option value="faq">FAQ</option>
                    </select>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* Select Entity type ends*/}

            {/* File input controls */}
            {props.region === "region" || props.product === "product" ? (
              <>
                <h4>File to Import</h4>
                <div className={styles.management_details_contents}>
                  <div
                    className={`${styles.management_details_form} ${styles.file_import}`}
                  >
                    <label htmlFor="fileInput">Select File to Import</label>
                    <div>
                      <label htmlFor="fileInput" className="cursor-pointer">
                        <span className={styles.choose_file}>Choose file</span>
                      </label>

                      {file?.name ? (
                        <>
                          <span className={styles.choosed_file_name}>
                            {file.name || ""}
                          </span>

                          <img
                            src={CloseIconSmall}
                            className={styles.close_icon + " cursor-pointer"}
                            alt="Remove icon"
                            title="Remove"
                            onClick={removeFile}
                          />
                        </>
                      ) : null}
                    </div>
                    <input
                      ref={inputFileElRef}
                      type="file"
                      id="fileInput"
                      accept=".csv"
                      className="d-none"
                      onInput={handleFileInput}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* File input controls ends */}
          </div>

          <div className="col-lg-6">
            {response.key ? (
              <Alert
                key={response.key}
                className="alert_message"
                closable
                message={response.message}
                type={response.type}
                showIcon
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
