import React from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification, Select } from "antd";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import validationRules from "../../../utils/validationRules";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import {
  alphaNum,
  userName,
  userNameWithSpace,
  vehicleNumber,
  gst,
  pinCode,
  phoneRegx,
  emailRegx,
  telePhoneRegex
} from "../../../utils/RegularExp";
import arrowDown from "../../../assets/images/arrow_down.svg";
import TextArea from "../../modules/ReusableComponent/TextArea";
import { onlyNumbers, onlyAlphabets } from "../../../utils/RegularExp";

const { Option } = Select;

// const colorOption = {
let vehiccle = "";
// };

const vehicle = JSON.parse(JSON.stringify(vehiccle));

const initialStateCodes = {
  "JAMMU AND KASHMIR": 1,
  "HIMACHAL PRADESH": 2,
  "PUNJAB": 3,
  "CHANDIGARH": 4,
  "UTTARAKHAND": 5,
  "HARYANA": 6,
  "DELHI": 7,
  "RAJASTHAN": 8,
  "UTTAR PRADESH": 9,
  "BIHAR": 10,
  "SIKKIM": 11,
  "ARUNACHAL PRADESH": 12,
  "NAGALAND": 13,
  "MANIPUR": 14,
  "MIZORAM": 15,
  "TRIPURA": 16,
  "MEGHALAYA": 17,
  "ASSAM": 18,
  "WEST BENGAL": 19,
  "JHARKHAND": 20,
  "ODISHA": 21,
  "CHATTISGARH": 22,
  "MADHYA PRADESH": 23,
  "GUJARAT": 24,
  "DADRA AND NAGAR HAVELI": 26, // "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)": 26,
  "MAHARASHTRA": 27,
  // "ANDHRA PRADESH": 28, //"ANDHRA PRADESH(BEFORE DIVISION)": 28,
  "KARNATAKA": 29,
  "GOA": 30,
  "LAKSHADWEEP": 31,
  "KERALA": 32,
  "TAMIL NADU": 33,
  "PUDUCHERRY": 34,
  "ANDAMAN AND NICOBAR ISLANDS": 35,
  "TELANGANA": 36,
  "ANDHRA PRADESH": 37,//  "ANDHRA PRADESH (NEW)": 37,
  "LADAKH (NEWLY ADDED)": 38,
  "OTHER TERRITORY": 97,
  "CENTRE JURISDICTION": 99

}

function AddRegion() {
  const initialFormState = {
    name: "",
    companyId: "",
    state: "",
    enable: true,
    gstin: "",
    address: "",
    address1:"",
    address2:"",
    vehicle: [],
    pincode: "",
    state_code: ""
  };
  const rules = {
    name: [validationRules.required],
    companyId: [validationRules.required],
    pincode: [validationRules.required],
    state: [validationRules.required],
    state_code: [validationRules.required],
    gstin: [validationRules.required],
    address: [validationRules.required],
    address1: [validationRules.required],
    address2: [validationRules.required]
  };
  const history = useHistory();
  const [editForm, setEditForm] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState({ details: false, update: false });
  const { state } = history.location;
  const [isLoading, setIsLoading] = useState(true);
  // const [states, setStates] = useState(initialStates);
  const [stateCode, setStateCode] = useState(initialStateCodes);
  const [code, setCode] = useState("");

  const [addForm, setAddForm] = useState([vehicle]);
  const [vehicleUpdate, setVehicleupdate] = useState("");

  useEffect(() => {
    const { state } = history.location;
    if (state?._id) {
      getRegionDetails(state._id);
    } else {
      setIsLoading(false);
    }
    setAddForm([...editForm.vehicle]);
  }, []);

  // *** FETCHING REGION DETIALS *** //
  function getRegionDetails(id) {
    setLoading({ details: true, update: false });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/admin/region/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          const val = res.data.data;
          setEditForm(val);
          setAddForm(val.vehicle);
          setIsLoading(false);
        } else {
          showNotification({
            type: "Error",
            message: "Error",
            description: "Couldnt fetch required data",
          });
        }
      })
      .catch((er) => {
        return er;
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setLoading({ details: false, update: false });
      });
  }
 
  // *** INPUT HANDLE CHANGE *** //
  function handleFormInput(e) {
    let { name, value, checked } = e.target;
    if (name && name != "state_code") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    //Validation
    /* if(!value){
      setFormErrors({...formErrors,[name] : "Required"})
    }else{
      setFormErrors({...formErrors,[name] : ""})
    } */

    if (name === "name") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "companyId") {
      if (!e.target.value.match(alphaNum) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphanumeric and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }



    if (name === "gstin") {
      if (!e.target.value.match(gst) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter valid GST",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    if (name === "address") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "address1") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "address2") {
      if (!e.target.value.match(userNameWithSpace) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
    if (name === "pincode") {
      if (!e.target.value.match(pinCode) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter valid pincode",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    if (name === "vehicle") {
      if (!e.target.value.match(vehicleNumber) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter valid vehicle No. ",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    if (name === "phone") {
      if ((!e.target.value.match(phoneRegx) && !e.target.value.match(telePhoneRegex)) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter valid phone number",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    if (name === "email") {
      if (!e.target.value.match(emailRegx) || !e.target.value === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter valid email-id ",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }

    const updatedValues = { ...editForm };

    if (["enable"].includes(name)) {
      updatedValues[name] = checked;
    } else {
      updatedValues[name] = value;
    }
    // updatedValues[name] = value;
    setEditForm(updatedValues);
  }

  function addNew() {
    const updatedValue = JSON.parse(JSON.stringify(addForm));
    const lastValue = updatedValue[updatedValue?.length - 1];
    const newVehicle = JSON.parse(JSON.stringify(vehicle));
    if (lastValue?.length === 10) {
      const splittedValue = lastValue.split('');
      const firstTwo = splittedValue[0] + splittedValue[1];
      const secondTwo = (splittedValue[2] + splittedValue[3]);
      const thirdTwo = splittedValue[4] + splittedValue[5];
      const lastFour = (splittedValue[6] + splittedValue[7] + splittedValue[8] + splittedValue[9]);
      if (onlyAlphabets.test(firstTwo) && onlyNumbers.test(secondTwo) && onlyAlphabets.test(thirdTwo) && onlyNumbers.test(lastFour)) {
        updatedValue.push(newVehicle);
        setAddForm(updatedValue);
      } else {
        showNotification({
          type: "warning",
          message: "Warning",
          description: "Please enter valid vehicle no. to continue",
        });
      }
    }else if (lastValue?.length === 9) {
      const splittedValue = lastValue.split('');
      const firstTwo = splittedValue[0] + splittedValue[1];
      const secondTwo = (splittedValue[2] + splittedValue[3]);
      const thirdTwo = splittedValue[4];
      const lastFour = (splittedValue[5] + splittedValue[6] + splittedValue[7] + splittedValue[8]);
      if (onlyAlphabets.test(firstTwo) && onlyNumbers.test(secondTwo) && onlyAlphabets.test(thirdTwo) && onlyNumbers.test(lastFour)) {
        updatedValue.push(newVehicle);
        setAddForm(updatedValue);
      } else {
        showNotification({
          type: "warning",
          message: "Warning",
          description: "Please enter valid vehicle no. to continue",
        });
      }
    } else if (!lastValue && updatedValue?.length === 0) {
      updatedValue.push(newVehicle);
      setAddForm(updatedValue);
    } else {
      showNotification({
        type: "warning",
        message: "Warning",
        description: "Please enter valid vehicle no. to continue",
      });
    }
  }

  function removeColorOption(index) {
    const updatedValue = addForm;
    updatedValue.splice(index, 1);
    setAddForm([...updatedValue]);
  }


  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * SHOW NOTIFICATION FUNCTION
   */
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }


  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * VALIDATE FUNCTION
   */
  function validate(name, data) {
    data = data || {};
    const result = {};
    //Validate only field
    if (name) {
      const fieldRules = rules[name];
      if (Array.isArray(fieldRules)) {
        for (const rule in fieldRules) {
          const val = rule(data[name]);
          if (isString(val) || isObject(val)) {
            setFormErrors({ ...formErrors, [name]: val });
          } else if (name in formErrors) {
            const { name, ...restOfErros } = formErrors;
            setFormErrors(restOfErros);
          }
        }
      }
      //all data validation
    } else {
      for (const field in rules) {
        for (const rule of rules[field]) {
          const val = rule(data[field], data);
          if (typeof val === "string" || isObject(val)) {
            result[field] = val;
            break;
          }
        }
      }
    }
    return result;
  }


  /**
   * @author Mithun Prabhu
   * @param {}
   * SAVE CHANGES
   */
  function onSubmite() {
    let data = JSON.parse(JSON.stringify(editForm));

    // let finalVehicleDetai  ls = [];

    // addForm.map((vehicle) => finalVehicleDetails.push(vehicle.vehicle.toString()))
    // console.log(data.name);

    if (data.name) {
      if (!userNameWithSpace.test(data.name) || !data.name === "") {
        setFormErrors({
          ...formErrors,
          name: "Enter only alphabet and minimum 3",
        });

        return false;
      } else {
        setFormErrors({ ...formErrors, name: "" });
      }
    }
    if (data.companyId) {
      if (!alphaNum.test(data.companyId) || !data.companyId === "") {
        setFormErrors({
          ...formErrors,
          companyId: "Enter only alphanumeric and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, companyId: "" });
      }
    }

    if (data.pincode) {
      if (!pinCode.test(data.pincode) || !data.pincode === "") {
        setFormErrors({
          ...formErrors,
          pincode: "Enter valid pincode",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, pincode: "" });
      }
    }

    if (data.phone) {
      if (!phoneRegx.test(data.phone) || !data.phone === "") {
        setFormErrors({
          ...formErrors,
          phone: "Enter valid phone number",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, phone: "" });
      }
    }

    if (data.gstin) {
      if (!gst.test(data.gstin) || !data.gstin === "") {
        setFormErrors({
          ...formErrors,
          gstin: "Enter valid GST",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, gstin: "" });
      }
    }

    if (data.address) {
      if (!userNameWithSpace.test(data.address) || !data.address === "") {
        setFormErrors({
          ...formErrors,
          address: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, address: "" });
      }
    }
    if (data.address1) {
      if (!userNameWithSpace.test(data.address1) || !data.address1 === "") {
        setFormErrors({
          ...formErrors,
          address1: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, address1: "" });
      }
    }
    if (data.address2) {
      if (!userNameWithSpace.test(data.address2) || !data.address2 === "") {
        setFormErrors({
          ...formErrors,
          address2: "Enter only alphabet and minimum 3",
        });
        return false;
      } else {
        setFormErrors({ ...formErrors, address2: "" });
      }
    }



    data.pincode = parseInt(data.pincode);
    data.state_code = data.state_code.toString();
    data.vehicle = [...addForm];
    // if (!data.address) {
    //   showNotification({
    //     type: "warning",
    //     message: "There were few errors",
    //     description: "Please fill all mandatory fields",
    //   });
    //   return;
    // }

    const result = validate(undefined, data);

    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        description: "Resolve the errors and try again",
      });
      return;
    }

    let vehicleNoErr = false;

    if (data?.vehicle?.length > 0) {
      data?.vehicle?.forEach(vehicleNo => {
        if (vehicleNo?.length === 10) {
          const splittedValue = vehicleNo.split('');
          const firstTwo = splittedValue[0] + splittedValue[1];
          const secondTwo = (splittedValue[2] + splittedValue[3]);
          const thirdTwo = splittedValue[4] + splittedValue[5];
          const lastFour = (splittedValue[6] + splittedValue[7] + splittedValue[8] + splittedValue[9]);
          if (onlyAlphabets.test(firstTwo) && onlyNumbers.test(secondTwo) && onlyAlphabets.test(thirdTwo) && onlyNumbers.test(lastFour)) {
            
          } else {
            vehicleNoErr = true;
          }
        }else if (vehicleNo?.length === 9) {
          const splittedValue = vehicleNo.split('');
          const firstTwo = splittedValue[0] + splittedValue[1];
          const secondTwo = (splittedValue[2] + splittedValue[3]);
          const thirdTwo = splittedValue[4];
          const lastFour = (splittedValue[5] + splittedValue[6] + splittedValue[7] + splittedValue[8]);
          if (onlyAlphabets.test(firstTwo) && onlyNumbers.test(secondTwo) && onlyAlphabets.test(thirdTwo) && onlyNumbers.test(lastFour)) {
            
          } else {
            vehicleNoErr = true;
          }
        }else{
          vehicleNoErr = true;
        }
        
      })
    }

    data.phone = parseInt(data.phone);

    if (!vehicleNoErr) {
      setLoading({ ...loading, update: true });
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .post(`/admin/region/${state?._id ? "updateRegion" : "addRegion"}/`, data)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === 200) {
            // console.log(res.data.msg)
            showNotification({
              type: "success",
              message: "Success",
              description: state?._id
                ? "Warehouse updated successfully"
                : "Warehouse added successfully",
            });
            setTimeout(() => {
              history.push("/site-settings/region");
              setEditForm(initialFormState);
            }, 1000);
          } else {
            // console.log(res.data.status);
            //alert(res.data.msg)
            showNotification({
              type: "error",
              message: "Error",
              description:
                res.data?.msg || "Warehouse name / Company Id already exists",
            });
          }
        })
        .catch((er) => {
          // console.log(er);
          return er;
        })
        .finally(() => {
          setLoading({ details: false, update: false });
        });
    } else {
      showNotification({
        type: "warning",
        message: "Warning",
        description: "Please enter valid vehicle no. to continue",
      });
    }
  }


  /**
   * @author Mithun Prabhu
   * @param {EVENT}
   * HANDLE CHANGES FOR STATE PARAM CHANGES
   */
  function handleInput(e, name) {

    let codeValue = null;

    Object.entries(initialStateCodes).map((state) => {
      if (state[0] === e) {

        setCode(state[1]);
        codeValue = state[1];
      }
    });

    if (name === "state") {
      if (!e.match(userNameWithSpace) || !e === "") {
        setFormErrors({
          ...formErrors,
          [name]: "Enter only alphabet and minimum 3",
          state_code: "Enter only alphabet and minimum 3",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "", state_code: "" });
      }
    }

    const updatedValues = { ...editForm };
    updatedValues.state = e;
    updatedValues.state_code = codeValue;
    setEditForm(updatedValues);
  }

  const [errorIndx, setErrorIndx] = useState(false);

  function handleVehicleOptionInputChange(e, ind) {
    let { name, value } = e.target;
    const splittedValue = value?.split('');
    const length = splittedValue?.length

    if (length === 10 || length === 9) {
      const firstTwo = splittedValue[0] + splittedValue[1];
      const secondTwo = (splittedValue[2] + splittedValue[3]);
      const thirdTwo = length === 9? splittedValue[4] : splittedValue[4] + splittedValue[5];
      const lastFour = length === 9? (splittedValue[5] + splittedValue[6] + splittedValue[7] + splittedValue[8]) : (splittedValue[6] + splittedValue[7] + splittedValue[8] + splittedValue[9]);
      if (!onlyAlphabets.test(firstTwo) || !onlyNumbers.test(secondTwo) || !onlyAlphabets.test(thirdTwo) || !onlyNumbers.test(lastFour) || value.length > 10 || value.length < 9) {
        setErrorIndx(ind);
      } else {
        setErrorIndx(false);
      }
    } else {
      setErrorIndx(ind);
    }

    let spaceRemovedValue = value.replace(' ', '');
    // console.log(`name ${name} value ${value}`);
    // if (name == "vehicle") {
    //     if(value.trim()) {
    //       value = value.charAt(0).toUpperCase() + value.slice(1);
    //     } else {
    //       value = "";
    //     }
    // }
    setVehicleupdate(spaceRemovedValue);
    let currentVehicleOption = addForm[ind];
    currentVehicleOption = spaceRemovedValue;
    //console.log(currentColorOption);
    const vehicleOption = addForm;
    vehicleOption[ind] = currentVehicleOption;
    //console.log(colorOption)
    setAddForm([...vehicleOption]);
  }

  return (
    <div className={commonstyles.management_contents}>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          <img
            src={BlackLeftArrow}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/site-settings/region")}
          />
          {state?._id ? "Edit Warehouse" : "Add Warehouse "}
        </h1>
        <div className={styles.management_contents_upload}>
          <div className={`${styles.onoffswitch} d-inline-block align-top`}>
            <input
              type="checkbox"
              name="enable"
              className={styles.onoffswitch_checkbox}
              onChange={handleFormInput}
              checked={editForm?.enable}
              id="statusSwitch"
            />
            <label className={styles.onoffswitch_label} htmlFor="statusSwitch">
              <span className={styles.onoffswitch_inner}></span>
              <span className={styles.onoffswitch_switch}></span>
            </label>
          </div>
          <ButtonComponent
            className="rnd outline_cancel_btn mr-3"
            attributes={{
              onClick: () => history.push(`/site-settings/region`),
            }}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            className="rnd"
            attributes={{
              disabled: loading.update,
              onClick: onSubmite,
            }}
          >
            {loading.update ? "Saving" : "Save"}
          </ButtonComponent>
        </div>
      </div>
      {isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching warehouse details..." />
          </td>
        </tr>
      ) : (
        <div className={commonstyles.management_details}>
          <div
            className={`${commonstyles.management_details_contents} ${commonstyles.management_details_form}`}
          >
            <div className="row">
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    Name
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.name}
                    formControl={{
                      placeholder: "Eg, Karnataka",
                      name: "name",
                      value: editForm?.name,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.name} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    companyID
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.companyId}
                    formControl={{
                      placeholder: "Eg, 1234535",
                      name: "companyId",
                      value: editForm?.companyId,
                      maxLength: 10,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.companyId} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    Email
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.email}
                    formControl={{
                      placeholder: "Eg, xyz@gmail.com",
                      name: "email",
                      value: editForm?.email,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.email} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    Phone
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.phone}
                    formControl={{
                      placeholder: "Eg, 9180989768",
                      name: "phone",
                      value: editForm?.phone,
                      maxLength: 12,
                      minLength: 8,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.phone} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    Pincode
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.pincode}
                    formControl={{
                      placeholder: "Eg, 581343",
                      name: "pincode",
                      value: editForm?.pincode,
                      maxLength: 6,
                      minLength: 6,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.pincode} />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label className={styles.label_title}>
                    State
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>

                  <Select className="input_width_100 font_semibold br-10"
                    value={editForm?.state}
                    onChange={(value) => handleInput(value, "state")}
                    style={{
                      cursor: "pointer",
                      border: formErrors.state ? "#ff7575" : "1px solid #d9d9d9",
                      boxShadow: formErrors.state ? "0 0 3px 1px #ff7575" : ''
                    }}
                  >
                    <option value="" disabled selected>Select state</option>
                    {/* <option value="">Select state</option> */}
                    {Object.entries(stateCode).sort().map((state) => (
                      <>

                        <option value={state[0]} label={state}  >
                          {state[0]}
                        </option>
                      </>
                    ))}
                  </Select>
                  <FormErrorText error={formErrors.state} />
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <label className={styles.label_title}>
                    State code
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>

                  <Select defaultValue="state" className="input_width_100 font_semibold br-10"
                    value={code || editForm?.state_code}
                    //  onChange={(e) => handleCode(e)}
                    disabled
                    style={{
                      cursor: "pointer",
                      border: formErrors.state_code ? "#ff7575" : "1px solid #d9d9d9",
                      boxShadow: formErrors.state_code ? "0 0 3px 1px #ff7575" : ''
                    }}
                  >
                    <option value="">Select state code</option>
                    {Object.keys(stateCode).sort().map((state) => (
                      <>
                        <option value={code} label={state}>
                          {code}
                        </option>
                      </>
                    ))}
                  </Select>
                  <FormErrorText error={formErrors.state_code} />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div>
                  <label for="">
                    GST
                    <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                      *
                    </span>
                  </label>
                  <InputComponent
                    className="form-control form-control-lg form_control"
                    error={formErrors.gstin}
                    formControl={{
                      placeholder: "Eg, 22AABCU9603R1ZX",
                      name: "gstin",
                      value: editForm?.gstin,
                      // maxLength: 10,
                      onChange: handleFormInput,
                    }}
                  />
                  <FormErrorText error={formErrors.gstin} />
                </div>
              </div>
              <div className="col-lg-12">
                <div className={`{styles.management_details_contents} mb-4`}>
                  <label>Address  <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <TextArea
                    className="form-control theme-form-control form-control-lg"
                    error={formErrors.address}
                    formControl={{
                      name: "address",
                      value: editForm?.address,
                      placeholder: "Type address",
                       maxLength: 200,
                      onChange: handleFormInput,
                    }}


                  // name="address"
                  // rows="3"
                  // value={editForm?.address}
                  // onChange={handleFormInput}

                  // maxLength="50"
                  />
                  <FormErrorText error={formErrors.address} />
                </div>
                <div className={`{styles.management_details_contents} mb-4`}>
                  <h6>Clear Tax Address</h6>
                  <label>Address 1<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <TextArea
                    className="form-control theme-form-control form-control-lg"
                    error={formErrors.address1}
                    formControl={{
                      name: "address1",
                      value:  editForm?.address1,
                     placeholder: "Type address",
                     maxLength: 100,
                      onChange: handleFormInput,
                    }}
                    


                  // name="address"
                  // rows="3"
                  // value={editForm?.address}
                  // onChange={handleFormInput}

                  // maxLength="50"
                  />
                  <FormErrorText error={formErrors.address1} />
                </div>
                <div className={styles.management_details_contents}>
                  <label>Address 2<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                    *
                  </span></label>
                  <TextArea
                    className="form-control theme-form-control form-control-lg"
                    error={formErrors.address2}
                      formControl={{
                      name: "address2",
                      value: editForm?.address2,
                      placeholder: "Type address",
                      maxLength: 100,
                      onChange: handleFormInput,
                    }}


                  // name="address"
                  // rows="3"
                  // value={editForm?.address}
                  // onChange={handleFormInput}

                  // maxLength="50"
                  />
                  <FormErrorText error={formErrors.address2} />
                </div>


                <div className={styles.management_details_contents}>
                  <div className={styles.management_details_form}>
                    <div className="table-responsive">
                      <table className="table table-borderless table_attributes">
                        <thead>
                          <tr>
                            {/* <th scope="col" class="th_radio_button"></th> */}
                            <th scope="col" className={styles.th_admin}>
                              {addForm.length > 0 && "Vehicle No."}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {addForm.length > 0 ? addForm.map((vehicle, ind) => (
                            <tr key={ind}>
                              <td>
                                <InputComponent
                                  className="form-control form_control"
                                  error={
                                    formErrors.options &&
                                    formErrors.options[ind] &&
                                    formErrors.options[ind].vehicle
                                  }
                                  formControl={{
                                    placeholder: "Vehicle No.",
                                    name: "vehicle",
                                    value: addForm[ind],
                                    onChange: (e) => {
                                      handleVehicleOptionInputChange(e, ind);
                                    },
                                  }}
                                />
                                <FormErrorText
                                  error={
                                    errorIndx===ind?"Please enter valid vehicle no.":""
                                  }
                                />
                              </td>

                              {/*  <p>you picked {colorPick}</p> */}
                              <td>
                                {/* {permissionsArr.length > 0 &&
                                permissionsArr.find(
                                  (x) => x === "Catalog-Manage Colour-add"
                                ) === "Catalog-Manage Colour-add" && ( */}
                                <ButtonComponent
                                  className="rnd"
                                  attributes={{
                                    onClick: () => {
                                      removeColorOption(ind);
                                    },
                                  }}
                                >
                                  Remove
                                </ButtonComponent>
                                {/* )} */}
                              </td>
                            </tr>
                          )) : "No vehicle number"}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    {/* {permissionsArr.length > 0 &&
                    permissionsArr.find(
                      (x) => x === "Catalog-Manage Colour-add"
                    ) === "Catalog-Manage Colour-add" && ( */}
                    <div className="text-right">
                      <button className="theme-btn rnd" onClick={addNew}>
                        Add vehicle no.
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddRegion;
