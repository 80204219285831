import styles from "../../../styles/Header.module.css";
import BrandLogo from "../../../assets/images/header/logo.png";
import BellIcon from "../../../assets/images/header/admin_product_bell_icon.svg";
import ProfileIcon from "../../../assets/images/header/admin_product_profile_icon.svg";
import SettingsIcon from "../../../assets/images/header/admin_product_setting_icon.svg";

import { logout } from "../../../utils";

import { Popover } from "antd";
import { useHistory } from "react-router";

export default function HeaderComponent() {
  const history = useHistory();
  function redirect() {
    history.push("/editprofile");
  }

  return (
    <>
    {/* <div className={styles.management_header_logo}>
      <img src={BrandLogo} alt="Damro Logo" />

      <div className={styles.management_info_img} style={{ cursor: "pointer" }}>
        <img src={BellIcon} />
        <img src={SettingsIcon} />

        <Popover
          placement="bottomRight"
          content={
            <div>
              <div className="cursor-pointer" onClick={redirect}>
                Profile
              </div>
              <div className="cursor-pointer" onClick={logout}>
                Logout
              </div>
            </div>
          }
          trigger="click"
        >
          <span className="d-inline-block">
            <img src={ProfileIcon} />
          </span>
        </Popover>
      </div>
    </div> */}
    </>
  );
}