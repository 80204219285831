import { React, useState } from "react";
// import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
// import InputComponent from "../../modules/ReusableComponent/InputComponent";
import ForgotComponent from "./ForgotComponent";
import styles from "../../../styles/NewLogin.module.css";
import eyeOpen from "../../../assets/images/eye.svg";
import eyeClose from "../../../assets/images/eye_close.svg";
import banner from "../../../assets/login_banner_img.png";
import mainLogo from "../../../assets/logo.svg";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { useHistory, useLocation } from "react-router";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

// import Alert from "react-bootstrap/Alert";
import "antd/dist/antd.css";
import { Alert } from "antd";
import { Button } from "react-bootstrap";

const LoginComponent = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [loginFormError, setloginFormError] = useState({
    email: "",
    password: "",
  });
  const [isLoginErrorEmpty, setIsLoginErrorEmpty] = useState(false);
  const [isLoginEmpty, setisLoginEmpty] = useState(true);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState({
    message: null,
    type: null,
  });
  const [isAuthenticate, setAuthenticate] = useState(false);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [ showforgot,setForgotShow ] = useState(false);

  const [ cap , setcap ] =useState(false);

  const [ recap , setrecap ] =useState(false);
  const [tokenValue,setTockenValue]=useState('');

 /**
   * @author Mithun Prabhu
   * @param {recaptchavalue}
   * SETTING FORGOT MODAL TO SHOW
   */
  const onForgotModalHandler = (e) => {
      e.preventDefault();
      setForgotShow(true);
  };


  const onCloseModal = () => {
    setForgotShow(false);
  };

   /**
   * @author Mithun Prabhu
   * @param {recaptchavalue}
   * SETTING REPACAPTCHA TO TRUE AND SET STATE
   */
  function onChange(recaptchavalue) {
    setTockenValue(recaptchavalue);
    setcap(true)
    setrecap(false)
  }
 
  return (
    <div className={`${styles.login_bg} row m-0`}>
      <div className={`${styles.login_bg_box} p-0`} >
        <div className={styles.login}>
          <div className={styles.logo_img}>
            <img src={mainLogo} alt="logo" />
          </div>
          {/* <h1 className={styles.title_text}>Log In</h1> */}
          <p className={styles.welcome_text_welcome}>Welcome back!</p>
          <p className={styles.welcome_text}>Please login to your account.</p>
          <div>
            {props.error && props.error.message ? (
              <Alert
                className="alert_message"
                closable
                message={props.error.message}
                type={props.error.type}
                showIcon
              />
            ) : (
              ""
            )}
          </div>
          <form onSubmit={(e) => {
          e.preventDefault(); cap ? props.onLoginForm(e,tokenValue) : setrecap(true) }}>
            <div className={`form-group ${styles.form_group}`}>
              <input
                type="email"
                name="email"
                className={`form-control ${styles.form_control}`}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email*"
                onChange={props.onInputChange}
                value={props.loginForm && props.loginForm.email}
              />
              <span className={`{styles.error_text}`}>
                {props.loginFormError && props.loginFormError.email !== "" ? (
                  <p style={{ color: "red" }}>{props.loginFormError.email}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div
              className={`form-group ${styles.form_group} ${styles.password_icon}`}
            >
              <input
                name="password"
                type={props.visible ? "text" : "password"}
                onChange={props.onInputChange}
                className={`form-control ${styles.form_control}`}
                id="exampleInputPassword1"
                placeholder="Password*"
                value={props.loginForm && props.loginForm.password}
              />
              <span id="password" onClick={props.onPasswordVisible}>
                <img
                  src={props.visible ? eyeClose : eyeOpen}
                  alt="visible"
                  onClick={props.onPasswordVisible}
                  className={styles.eyeIcon}
                />
              </span>
              <span className={`{styles.error_text}`}>
                {props.loginFormError &&
                props.loginFormError.password !== "" ? (
                  <p style={{ color: "red" }}>{props.loginFormError.email}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
             
            <button className={`${styles.btn} ${styles.btn_login}`}  >
              
              {props.clickable ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="spinner_btn"
                />
              ) : null}
              LOGIN
            </button>
          
          </form> 
          <div className={styles.forgot_password}>
            <a onClick={onForgotModalHandler}>Forgot Password</a>
          </div>
         <div className={styles.recaptcha}>
        <ReCAPTCHA
        name="token"
        className={ recap ? styles.recap : ''}
        onChange={onChange}
        sitekey={process.env.REACT_APP_ADMIN_RECAPTCHA_SITEKEY}
      />
    </div>
        </div>
      </div>
      <ForgotComponent
     show={showforgot}
     onCloseModal={onCloseModal}
     onForgotModalHandler={onForgotModalHandler}
   />
    </div>
  );
};

export default LoginComponent;
