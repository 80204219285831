import styles from "../../../styles/CustomerManagement.module.css";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import { useHistory } from "react-router-dom";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import pdfIcon from "../../../assets/images/excel_icon.svg";
import excelIcon from "../../../assets/images/excel_icon.svg";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useState, useRef } from "react";
import AddCustomer from "./AddCustomer";
import dots from "../../../assets/images/column_dots.svg";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import {
  notification,
  Space,
  Spin,
  Dropdown,
  Menu,
  message as massages,
  Select,
  Tooltip,
} from "antd";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { clone, upperCase, upperFirst } from "lodash";
import * as XLSX from "xlsx";
import arrowDown from "../../../assets/images/arrow_down.svg";
import Pagination from "../../modules/ReusableComponent/Pagination";
import EditCustomerComponent from "../CustomerManagement/EditCustomerComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import DefaultImage from "../../../assets/images/profile_dummy.svg";
import { Link, useParams } from "react-router-dom";
import Plus from "../../../assets/images/plus_white_icon.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import BulkUploadComponent from "./BulkUploadComponent";
import { arrayMove } from "react-movable";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import SearchComponent from "../SearchManagement/SearchComponent";

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  // { _id: 2, status: 1, name: "Avatar", value: "avatar" },
  { _id: 2, status: 1, name: "Name", value: "name" },
  { _id: 3, status: 1, name: "Rating", value: "Rating" },
  { _id: 4, status: 1, name: "Email", value: "email" },
  // { _id: 5, status: 1, name: "Mobile", value: "mobile" },
  { _id: 6, status: 1, name: "Region", value: "Region" },
  { _id: 7, status: 1, name: "Status", value: "Status" },
  { _id: 8, status: 1, name: "Action", value: "action" },
];
const filters = [
  // { id: 1, isChecked: false, name: "Region", value: "Region" },
  { id: 2, isChecked: false, name: "Date", value: "Date" },
  { id: 3, isChecked: false, name: "Rating", value: "Rating" },
];
const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];
const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: 1 },
  { _id: 2, isChecked: false, name: "Disable", value: 2 },
];
const ratingObj = [
  { _id: 1, name: 1, value: 1 },
  { _id: 2, name: 2, value: 2 },
  { _id: 3, name: 3, value: 3 },
  { _id: 4, name: 4, value: 4 },
  { _id: 5, name: 5, value: 5 },
];
let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];
export default function CustomerManagementComponent() {
const accessregion = localStorage.getItem('accessregion');
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
  const pdfExportComponent = useRef(null);
  const [customerList, setCustomerList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddCustomerVisibility, setshowAddCustomerVisibility] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    bulkUpload: false,
  });
  const [weekplanexport, setWeekPlanExport] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectFilter, setSelectFilter] = useState(filters);
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Region");
  const [regions, setRegions] = useState([]);
  const [rating, setRating] = useState("");
  const [ratingName, setRatingName] = useState("All");
  const [ratingArr, setRatingArr] = useState(ratingObj);
  // const [isLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const [sortorder, setSortorder] = useState(null);
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [visible, setVisible] = useState(false);
  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();
  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Avatar",
      visible: false,
      key: "avatar",
      render: (text, record, index) => (
        <td>
          {record.profileImg ? (
            <img
              src={record.profileImg}
              className={`${styles.admin_table_img} rounded-circle`}
              alt="Profile Image"
              width="40px"
              height="40px"
            />
          ) : (
            <img
              src={DefaultImage}
              className={`${styles.admin_table_img} rounded-circle`}
              alt="Profile Image"
              width="40px"
              height="40px"
            />
          )}
        </td>
      ),
    },
    {
      title: "Name",
      visible: false,
      key: "name",
      sorter: true,
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>{record.name}</span>
      ),
    },
    {
      title: "Rating",
      visible: false,
      key: "Rating",
      render: (text, record, index) => (
        <Rating initialValue={record.rating} readonly={true} size={25} />
      ),
    },
    {
      title: "Email",
      visible: false,
      key: "email",
      render: (text, record, index) => <span>{record.email}</span>,
    },
    {
      title: "Status",
      visible: false,
      key: "Status",
      render: (text, record, index) => (
        <span
          className={
            record.status == 1
              ? commonstyles.status_active
              : commonstyles.status_inactive
          }
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "Region",
      visible: false,
      key: "Region",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.regions.length === 1 && record.regions[0]}
          {record?.regions?.length === 0 && '-'}
          {record.regions.length === 2 &&
            record.regions[0] + "," + record.regions[1]}
          {record.regions.length === 3 &&
            record.regions[0] +
              "," +
              record.regions[1] +
              "," +
              record.regions[2]}
        </span>
      ),
    },
    {
      title: "Action",
      visible: false,
      key: "action",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Customer-Customer-edit") ===
          "Customer-Customer-edit" && (
          <span>
            <Tooltip
              placement="topRight"
              title="Edit customer"
              // className="tooltip_list"
            >
              <Link to={`/customer/customerDetails/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} mr-2`}
                  // onClick={() => editCustomer(customer)}
                  alt="Edit action icon"
                  // title="Edit customer"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </Tooltip>
            {/* <img
            src={adminProductDelete}
            className={commonstyles.admin_table_edit_img}
            onClick={() => showDeleteConfirmation(record)}
            alt="Delete action icon"
            title="Delete"
            style={{ cursor: "pointer" }}
          /> */}
          </span>
        ),
    },
  ]);


    /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const childCheckbox = (e, record) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };


    /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    localStorage.removeItem("selectedCustomerr");
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("customerList"));
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    let cloneSelectedOrder = [];
    if (localStorage.getItem("selectedCustomerr")) {
      cloneSelectedOrder = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (checked) {
        cloneOrderList.forEach((item) => {
          cloneSelectedOrder.push(item._id);
        });
      } else {
        cloneSelectedOrder = [];
      }
    } else if (checked) {
      cloneOrderList.forEach((item) => {
        cloneSelectedOrder.push(item._id);
      });
    }
    localStorage.setItem(
      "selectedCustomerr",
      JSON.stringify(cloneSelectedOrder)
    );
    localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
    setCustomerList(cloneOrderList);
  };

  useEffect(() => {
    set_new_header(headers);
    getCustomers(pageSize, nextPage, "");
    getRegionList();
    // getRegionList();
    localStorage.removeItem("selectedCustomerr");
    if (!accessregion) {
      let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
      if (!checkDuplicates) {
        filters.push({ id: 1, isChecked: false, name: "Region", value: "Region" })
      }
    };
    return () => {
      localStorage.removeItem("customerList");
      localStorage.removeItem("selectedCustomerr");
    };
  }, []);

  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          let obj = { name: "All Region", value: "", _id: "" };
          res.data?.daaata?.unshift(obj);
          setRegions(res.data.daaata);
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };


  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * CALLING GET CUSTOMER  RECORDS
   */
  function getCustomers(pageSize, nextPage, searchNow, filterarr, type) {
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search: searchNow,
    };
    if (localStorage.getItem('accessregion')) {
      filterOptions.regionId = localStorage.getItem('accessregion');
    } else if (filterarr && filterarr.regionId) {
      filterOptions.regionId = filterarr.regionId;
    } else if (regionId !== "") {
      if (filterarr && filterarr.regionId !== "") {
        filterOptions.regionId = regionId;
      }
    }
    if (filterarr && filterarr.rating) {
      filterOptions.rating = Number(filterarr.rating);
    } else if (rating !== "") {
      if (filterarr && filterarr.rating !== "") {
        filterOptions.rating = Number(rating);
      }
    }
    if (filterarr && filterarr.startDate) {
      filterOptions.startDate = filterarr.startDate;
    } else if (filteredDate.startDate !== "") {
      if (filterarr && filterarr.startDate !== "") {
        filterOptions.startDate = filteredDate.startDate;
      }
    }
    if (filterarr && filterarr.endDate) {
      filterOptions.endDate = filterarr.endDate;
    } else if (filteredDate.endDate !== "") {
      if (filterarr && filterarr.endDate !== "") {
        filterOptions.endDate = filteredDate.endDate;
      }
    }
    if (filterarr && filterarr.filterDays !== "") {
      filterOptions.filterDays = Number(filterarr.filterDays);
    } else if (filteredDate.filterDays !== "") {
      if (filterDays !== "") {
        filterOptions.filterDays = Number(filterDays);
      }
    }
    if (filterarr && filterarr.sortOrder !== "") {
      filterOptions.sortOrder = Number(filterarr.sortOrder);
    } else if (filteredDate.sortOrder !== "") {
      if (filterarr && sortorder !== "") {
        filterOptions.sortOrder = Number(sortorder);
      }
    }
    if(filterOptions?.sortOrder === 2) delete filterOptions.sortOrder;
    if (!type) {
      setIsLoading({ delete: false, list: true });
    }
    if(!filterOptions.filterDays) delete filterOptions.filterDays;
    setFilterOptionsGlobal(filterOptions);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("/getCustomerList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("customerList", JSON.stringify(res.data.data));
            setCustomerList(res.data.data);
          } else {
            setCustomerList([]);
          }
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      sortOrder: 2,
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getCustomers(value, 1, search, updatedFilter);
    } else if (type === "nextPage") {
      setNextPage(value);
      getCustomers(pageSize, value, search, updatedFilter);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

   /**
   * @author Mithun Prabhu
   * @param {onClick}
   * function for coming back list page when we cluck cancel button in editdiscount page
   */
  function closeEditProduct(success) {
    if (success) {
      getCustomers(pageSize, nextPage);
    }
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
  }

  if (showAddCustomerVisibility) {
    return (
      <AddCustomer
        changeAddCustomerVisibility={() => setshowAddCustomerVisibility(false)}
      />
    );
  }

  function searchHandler(e) {
    const { value } = e.target;
    setSearch(value);
    if (!value) {
      const updatedFilter = {
        search: "",
        
      };
      getCustomers(pageSize,nextPage,"",updatedFilter);
    }
  }

    /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */

  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };


  /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };


   /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };


  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };


  /**
   * @author Mithun Prabhu
   * @param {e, id}
   * This is component for checking and unchecking columns in list page
   */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };

  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = [...selectFilter];
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
    };

    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "Region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "Rating") {
          updatedFilter.rating = "";
          setRating("");
          setRatingName("All");
        }
        if (item.value === "Date") {
          const clonefilteredDate = { ...filteredDate };
          clonefilteredDate.startDate = "";
          clonefilteredDate.endDate = "";
          updatedFilter.startDate = "";
          updatedFilter.endDate = "";
          updatedFilter.filterDays = null;
          setFilteredDate(clonefilteredDate);
        }
        getCustomers(pageSize, nextPage, search, updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };
  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      rating: rating,
      filterDays: filterDays,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (name === "rating") {
      updatedFilter.rating = parseInt(value.value);
      setRating(value.value);
      setRatingName(value.name);
    }
    getCustomers(pageSize, 1, search, updatedFilter, "");
  }
  function filterOnChange(e) {
    const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
    };
    if (name === "regionId") {
      updatedFilter.regionId = value;
      setRegionId(value);
      setRegionName(value?.name);
    }
    if (name === "rating") {
      updatedFilter.rating = value;
      setRating(value);
    }
    getCustomers(pageSize, nextPage, search, updatedFilter);
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      // orderStatus: orderStatus,
      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
        filterDays:""
    };
    getCustomers(pageSize, nextPage, search, updatedFilter);
  };

  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Region") === "Region" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <span
                            key={region._id}
                            onClick={() =>
                              filterRegionChange("regionId", region)
                            }
                          >
                            <label>{region.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
               {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="filterDays"
                    value={filterDays}
                    onChange={filterOnChange}
                  >
                    <option value="">Order Date</option>
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <option key={day._id} value={day.value}>
                            {day.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  <div style={{ width: "100%" }} className="filter_heading card_dropdown_heading">
                    {filterDaysName}<img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                        <span key={day._id} onClick={() => filterRegionChange("filterDays", day)}>
                          <label>{day.name}</label>
                        </span>
                      ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Date") === "Date" && filterDays === "custom" &&(
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "Rating") === "Rating" && (
                <div className="card_dropdown show col-md-6">
                  {/* <select
                    className="form_control"
                    name="rating"
                    value={rating}
                    onChange={filterOnChange}
                  >
                    <option value="">All</option>
                    {Array.isArray(ratingArr)
                      ? ratingArr.map((rate) => (
                        <option key={rate._id} value={rate.value}>
                          {rate.name}
                        </option>
                      ))
                      : null}
                  </select> */}
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {ratingName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(ratingArr)
                      ? ratingArr.map((rate) => (
                          <span
                            key={rate._id}
                            onClick={() => filterRegionChange("rating", rate)}
                          >
                            <label>{rate.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </Menu>
  );

  function showDeleteConfirmation(customer) {
    setSelectedCustomer(customer);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  function deleteCustomer() {
    // const sureDelete = window.confirm("Are you sure want to delete???");
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .get(`deleteCustomer/${selectedCustomer._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Customer deleted successfully",
          });
          getCustomers(pageSize, nextPage);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to remove customer",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  function cancelDelete() {
    setSelectedCustomer({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  if (childComponentVisibility.addProduct) {
    return (
      <AddCustomer
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
      <EditCustomerComponent
        customer={selectedCustomer}
        handleClose={closeEditProduct}
      />
    );
  }

  //bulk
  if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
        region="region"
        handleClose={() => {
          setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          });
          getCustomers(pageSize, nextPage, "");
        }}
      />
    );
  }
  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const exportToCSV = async (e) => {
 
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;

    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("user/exportUsers", params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach(item => {
            if(item['Joined on']) {
              item['Joined on'] = moment(item['Joined on'], "DD-MM-YYYY HH:mm:ss").format("MMM Do YYYY, h:mm:ss A")
            }
          })
          // data.map((item) => {
          //   item["Module Name"] = capitalize(item["Module Name"]);
          // });
          setWeekPlanExport(data);
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          const ws = XLSX.utils.json_to_sheet(und_res);
          ws["A1"].v = upperCase(ws["A1"].v);
          ws["A1"].bold = true;
          ws["B1"].v = upperCase(ws["B1"].v);
          ws["C1"].v = upperCase(ws["C1"].v);
          ws["D1"].v = upperCase(ws["D1"].v);
          ws["E1"].v = upperCase(ws["E1"].v);
          ws["F1"].v = upperCase(ws["F1"]?.v);
          ws["G1"].v = upperCase(ws["G1"]?.v);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");
          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `customer-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.xlsx`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          setWeekPlanExport([]);
          // return [];
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  // *** BUILD STATUS CLICK HANDLER *** //
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedCustomerr")) {
      let selectedCustomerr = JSON.parse(
        localStorage.getItem("selectedCustomerr")
      );
      if (selectedCustomerr.length > 0) {
        let data = {
          customerIds: selectedCustomerr,
          status: Number(item.value),
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the orders below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the orders below",
      });
    }
  };

  const submitBulkAction = (data) => {
    setIsLoading(true);
    AxiosInstance(PORTS.USER_SERVICE, "application/json")
      .post("customer/bulkUpdate", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          setIsLoading(false);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedCustomerr");
          const cloneOrderList = JSON.parse(
            localStorage.getItem("customerList")
          );
          cloneOrderList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem("customerList", JSON.stringify(cloneOrderList));
          setCustomerList(cloneOrderList);
          getCustomers(pageSize, nextPage, "");
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @author Rajkumar
   * @param { sorting  pagination, pagination} value
   *   User List sort filter  functionality
   */
  const handleTableChange = (pagination, filters, sorter) => {
    let updatedFilter = {
      search: search,
      regionId: regionId,
      // orderStatus: orderStatus,
      filterDays : filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      sortOrder: sorter.order === "descend" ? 1 : 0,
    };
    setSortorder(sorter.order === "descend" ? 1 : 0);
    // setNextPage(1);
    getCustomers(pageSize, nextPage, search, updatedFilter, true);
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
  
    };
    getCustomers(pageSize,nextPage,updatedFilter.search,updatedFilter)
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  
  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Customer</h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Customer-Customer-view") ===
            "Customer-Customer-view" && (
            <div className="d-flex align-items-center">
              <div>
                {/* <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3 "
              attributes={{
                onClick: () => {
                  // setFilter(intialFilterState);
                  setChildComponentVisibility({
                    ...childComponentVisibility,
                    bulkUpload: true,
                  });
                  window.scrollTo(0, 0);
                },
              }}
            >
              <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
              Bulk Upload
            </ButtonComponent> */}
                {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: (e) => {
                exportToCSV(e);
                // setFilter(intialFilterState);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Export
          </ButtonComponent> */}
              </div>
              <div>
                <span className={commonstyles.management_header_downloads_text}>
                  <Tooltip placement="topRight" title="Download pdf">
                    <img
                      className={commonstyles.export_icon}
                      src={pdf_icon}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => exportToPDF(e)}
                    />
                  </Tooltip>
                  <Tooltip placement="topRight" title="Download excel">
                    <img
                      src={excelIcon}
                      className={commonstyles.management_header_downloads_icon}
                      alt="icon"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => exportToCSV(e)}
                    />
                  </Tooltip>
                </span>
                <span
                  className={commonstyles.management_header_downloads_text_ex}
                >
                  Export <img src={exportIcon} alt="icon" />
                </span>
              </div>
            </div>
          )}
      </div>
      {/* <div className={commonstyles.management_header}>
        <div className={` ${commonstyles.management_contents_upload} ${commonstyles.customer_add_btn} `}>
            <Link to="/customer/add">
              <span style={{ cursor: "pointer" }}>
                <img src={Plus} alt="" />
                Add Customer
              </span>
            </Link>
          </div>
      </div> */}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Customer-Customer-view") ===
          "Customer-Customer-view" && (
          <div
            className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale}`}
          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Name,Email...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: (e) => searchHandler(e),
                  ["aria-label"]: "Search customers",
                  ["aria-describedby"]: "search-customers",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
          className="theme-btn rnd pl-3 pr-4 ml-2"
          attributes={{
            onClick: () => {
              searchClick();
            },
          }}
        >
          Search
        </ButtonComponent>
            {/* <Dropdown
            // overlay={filterMenu}
            className="usermanage_table_filter_columns ml-3"
          >
            <div
              className="ant-dropdown-link"
              onTouchMove={(e) => e.preventDefault()}
            >
              Filter <img src={arrowDown} width={10} height={20} />
            </div>
          </Dropdown> */}
            <div className="action ml-3">
              <div className="action_header">
                Action
                <img src={arrowDown} alt="image" />
              </div>
              <div className="action_contents">
                {bulkActionFilter.length > 0 &&
                  bulkActionFilter.map((item) => (
                    <div
                      className="action_contents_header"
                      key={item._id}
                      onClick={() => bulkStatusClickHandler(item)}
                    >
                      <h2
                        className={`action_contents_header_h2 ${
                          item.isChecked && "action_contents_header_h2_active"
                        }`}
                      >
                        {item.name}
                      </h2>
                      {item.isChecked && (
                        <span
                          className="action_contents_btn"
                          onClick={() => applyBulkActionHandler(item)}
                        >
                          Apply
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
            >
              <div className="ant-dropdown-link">
                Filter <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Columns <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Customer-Customer-view") ===
          "Customer-Customer-view" && (
          <div className={`${commonstyles.management_table} table-responsive`}>
            {IsLoading.list === true ? (
              <div
                style={{
                  marginLeft: "70vh",
                  marginTop: "10vh",
                }}
              >
                <tr className="bg-transparent">
                  <td colSpan="100%">
                    <LoadingIndicator loadingText="Fetching customer details..." />
                  </td>
                </tr>
              </div>
            ) : (
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`CustomerList for ${new Date().getFullYear()}`}
                author="Damro Team"
              >
                <div>
                  <input
                    type="checkbox"
                    className={`${commonstyles.parentCheckbox}`}
                    onChange={parentCheckbox}
                  ></input>
                  <AntTableComponent
                    className="order_table"
                    tableProps={{
                      columns: columns,
                      dataSource: customerList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />
                </div>
              </PDFExport>
            )}
          </div>
        )}
      {!IsLoading.list && Array.isArray(customerList) && customerList.length
        ? permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Customer-Customer-view") ===
            "Customer-Customer-view" && (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          )
        : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Category delete confirmation modal ends*/}
    </div>
  );
}
