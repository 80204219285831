// import styles from "../../styles/ProductManagement.module.css";
import { useState, useRef, useEffect } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom"
import AddBannerComponent from "./AddBannerComponent";
//import NoProductsTableRowComponent from "../../../modules/ProductManagement/NoProductsTableRowComponent";
// import EditProductComponent from "./EditProductComponent";
// import BulkUploadComponent from "./BulkUploadComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
import Search from "../../../assets/images/search_icon.svg";
import plusIcon from "../../../assets/images/plus_white_icon.svg";
import deleteIcon from "../../../assets/images/trash.svg";
import plusLynchIcon from "../../../assets/images/plus_lynch_icon.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import SearchComponent from "../../pages/SearchManagement/SearchComponent"
import { useHistory } from "react-router";

const defaultPageSize = 10;
const pageSizes = [10, 20, 50];

export default function BannerManagementComponent() {
  const [parentCategories, setParentCategories] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  /* const [regions, setRegions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState(intialFilterState); */
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [nextPage, setNextPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({
    addProduct: false,
    editProduct: false,
    bulkUpload: false,
    delete: false,
  });
  const [isLoader, setIsLoader] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [selectedProduct, setSelectedProduct] = useState({});
  const timeoutId = useRef();

  const history = useHistory();

  useEffect(() => {
    getBannerList(pageSize, nextPage);
  }, [search,nextPage,pageSize]);

   /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr, type}
   * CALLING GET BANNER RECORDS
   */
  function getBannerList() {
    // setIsLoading(false);
    setIsLoading({ delete: false, list: true });
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
      search,
    };

    AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
      .post("/banner/getBanner", filterOptions)
      .then((res) => {
        //   console.log(res.data);
        if (res.status === 200 && res.data.status === 200) {
          // setIsLoading(false);
          setIsLoader(false);
          setIsLoading({ delete: false, list: false });
          setBannerList(res.data.data);
          setPagination(res.data.pagination);
        }
      })
      .catch((er) => {
        return er;
      })
      .finally(() => {
        // setIsLoading(false);
        setIsLoading({ delete: false, list: false });
      });
  }

  function handleSeach(e) {
    const { value } = e.target;

    setSearch(value);
  }

  function handlePaginationChanges(value, type) {
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      setBannerList(value, 1);
    } else if (type === "nextPage") {
      setNextPage(value);
      setBannerList(pageSize, value);
    }
  }
  
  /* useState(() => {
    function getParentCategories() {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json")
        .get("/admin/catalog/getParentCategory")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setParentCategories(res.data.data);
          }
        })
        .catch((er) => {
          const error = {
            type: "error",
            message: "Error",
            description: "Failed to get data",
          };
          showNotification(error);
        });
    }

    function showNotification({ type, message, description }) {
      let options = {
        message: message || "Message",
        description: description,
        duration: 5,
      };
      notification[type](options);
    }

    function getRegionList() {
      AxiosInstance(PORTS.REGION_SERVICE, "application/json")
        .get("/admin/region/getRegionList")
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            setRegions(res.data.daaata);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
    getParentCategories();
    getRegionList();
    // getProducts(filter);
  }, []); */

  /* function getProducts(filterParam) {
    setIsLoading({ ...IsLoading, list: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .post("/admin/catalog/productList/", filterParam)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          // console.log(res.data.data.products);
          // const reslt = res.data.data[0];
          setProducts(res.data.data.products);
          setPagination(res.data.pagination);
          // setPagination({ ...pagination, total: reslt.totalProducts });
        }
      })
      .catch((er) => {
        console.log("getProducts error");
        console.log(er);
        const error = {
          type: "error",
          message: "Error",
          description: "Failed to get product data",
        };
        showNotification(error);
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, list: false });
      });
  } */

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  

  /* function editProduct(product) {
    console.log(product)
    setFilter(intialFilterState);
    const selectedItem = JSON.parse(JSON.stringify(product));
    setSelectedProduct(selectedItem);
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: true,
    });
    window.scrollTo(0, 0);
  } */

  /* function closeEditProduct(isEdited) {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      editProduct: false,
    });
    if (isEdited) {
      getProducts(filter);
    }
  }
 */
  /* function showdelete(product) {
    setSelectedProduct(product);
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: true,
    });
  } */

  /* function cancelDelete() {
    setSelectedProduct({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      delete: false,
    });
  }
 */
  /* function removeProduct() {
    setIsLoading({ ...IsLoading, delete: true });
    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
      .delete(`/admin/catalog/deleteProduct/${selectedProduct._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          setFilter(intialFilterState);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          getProducts(filter);
        } else {
          showNotification({
            type: "error",
            message: "Error",
            description: res.data?.msg || "Failed to delete product",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: er.message || "Failed to delete product",
        });
      })
      .finally(() => {
        setIsLoading({ ...IsLoading, delete: false });
        setChildComponentVisibility({
          ...childComponentVisibility,
          delete: false,
        });
      });
  } */

  /*if (childComponentVisibility.addProduct) {
    return (
      <AddBannerComponent
        handleClose={() =>
          setChildComponentVisibility({
            ...childComponentVisibility,
            addProduct: false,
          })
        }
      />
    );
  } else if (childComponentVisibility.editProduct) {
    return (
        <></>
       <EditProductComponent
        product={selectedProduct}
        handleClose={closeEditProduct}
      /> 
    );
  }  else if (childComponentVisibility.bulkUpload) {
    return (
      <BulkUploadComponent
      product="product"
        handleClose={() =>
          {setChildComponentVisibility({
            ...childComponentVisibility,
            bulkUpload: false,
          })
          getProducts()}
        }
      />
    );
  } */
  function addBanner() {
    history.push(`/banner/add`);
  } 

  function editBanner(banner) {
    // console.log(banner);
    history.push({
      pathname: `/banner/edit`,
      state: banner,
    });
  }

  // *** DELETE Banner FUNCTION *** //
  function deleteFAQ(banner) {
    const sureDelete = window.confirm("Are you sure want to delete???");
    if (sureDelete === true) {
      if (banner?._id) {
        AxiosInstance(PORTS.DASHBOARD_SERVICE, "multipart/form-data")
          .get(`/banner/bannerRemove/${banner._id}`)
          .then((res) => {
            if (res.data.status === 200) {
              showNotification({
                type: "success",
                message: "Success",
                description: "Banner deleted successfully",
              });
              getBannerList();
            } else if (res.data.status === 400) {
              showNotification({
                type: "error",
                message: "Deleting banner failed",
                description: res.data?.msg || "Error occured",
              });
            }
          })
          .catch((er) => {
            showNotification({
              type: "error",
              message: "Deleting banner failed",
              description: "Error occured",
            });
          });
      }
    }
  }

  return (
    <div className={commonstyles.management_contents}>
        <SearchComponent/>
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>
          Banner Management
        </h1>
      </div>
      <div className={commonstyles.management_contents_search}>
        <div
          className={`${commonstyles.management_contents_search_bar} input-group`}
        >
          <input
            type="search"
            className={`${commonstyles.form_control} form-control`}
            aria-label="Search"
            placeholder="Search by Name...."
            aria-describedby="search-addon"
            value={search}
            onChange={(e) => handleSeach(e)}
          />
          <img src={Search} alt="Search icon" />
        </div>
        <div className={commonstyles.management_contents_upload}>
          {/* <ButtonComponent
            className="theme-btn-outlined rnd px-3 mr-3"
          >
            Export
          </ButtonComponent> */}
          {/* <ButtonComponent
            className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
            attributes={{
              onClick: () => {
                setFilter(intialFilterState);
                setChildComponentVisibility({
                  ...childComponentVisibility,
                  bulkUpload: true,
                });
                window.scrollTo(0, 0);
              },
            }}
          >
            <img src={plusLynchIcon} className="mr-2" alt="Bulk upload" />
            Bulk Upload
          </ButtonComponent> */}
        
          <span style={{ cursor: "pointer" }} onClick={addBanner}>
            <img src={Plus} alt="" />
            Add Banner
          </span>
        </div>
      </div>
      <div className={`${commonstyles.management_table} table-responsive`}>
        <table className="table table-borderless">
          <thead>
            <tr>
              {/* <th scope="col"></th> 
              <th scope="col">ID</th>*/}
              <th scope="col">Name</th>
              <th scope="col">Image</th>
              <th scope="col">Discount</th>
              <th scope="col">Status</th>

              <th scope="col">Operate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
             {IsLoading.list === true ? (
              <tr className="bg-transparent">
                <td colSpan="100%">
                  <LoadingIndicator loadingText="Fetching banner details" />
                </td>
              </tr>
            ) : Array.isArray(bannerList) && bannerList.length > 0 ? (
                bannerList.map((banner, indx, arr) => ( 
                <tr key={banner._id} >
                  <td style={{ textTransform: "capitalize" }}>{banner.name}</td>
                  <td>
                    {banner.banner ? (
                      <img
                        src={banner.banner}
                        className={commonstyles.admin_table_img}
                        alt="banner image"
                      />
                    ) : '-'}
                  </td>
                  <td>{banner.discount}%</td>
                  <td className={!banner.status===1 ? "theme-error-txt" : ""}>
                    {banner.status===1 ? "Enabled" : "Disabled"}
                  </td>
                  <td>
                    {/* <Link to={`/catalog/products/edit/${product.slug}`}> */}
                    <img
                      src={adminProductEdit}
                      className={`${commonstyles.admin_table_edit_img} cursor-pointer mr-2`}
                      onClick={() => editBanner(banner)}
                      alt="Edit action icon"
                      title="Edit banner"
                    />
                    {/* </Link> */}
                    

                    <img
                      src={deleteIcon}
                      className={`${commonstyles.admin_table_edit_img} cursor-pointer ml-2`}
                      onClick={() => deleteFAQ(banner)}
                      alt="Delete product action icon"
                      title="Delete banner"
                    />
                  </td>
                  {/* Edit button ends*/}
                </tr>
               ))
            ) : (
                <tr className="bg-transparent">
                      <td style={{ padding: "0px", textAlign: "left" }}>
                        No data found!
                      </td>
                    </tr>
            )}
          </tbody>
        </table>
      </div>
      {!IsLoading.list && Array.isArray(bannerList) && bannerList.length ? (
        <Pagination
            pageSizes={pageSizes}
            pageSize={pageSize}
            nextPage={nextPage}
            data={pagination}
            handlePaginationChanges={handlePaginationChanges}
        />
      ) : null}
    </div>
  );
}
