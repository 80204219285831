import { React, useState, useEffect, useRef } from "react";
import commonstyles from "../../../styles/Management_Common.module.css";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import {
  Button,
  Dropdown,
  Menu,
  Slider,
  Tooltip,
  Switch,
  message as massages,
  Select,
  notification,
  Empty,
  Table
} from "antd";
import { arrayMove } from "react-movable";
import dots from "../../../assets/images/column_dots.svg";
import MovableListComponent from "../../modules/ReusableComponent/MovableListComponent";
import arrowDown from "../../../assets/images/arrow_down.svg";
import AntDateRangeSelectComponent from "../../modules/ReusableComponent/AntDateRangeSelectComponent";
import Pagination from "../../modules/ReusableComponent/Pagination";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import Search from "../../../assets/images/search_icon.svg";
import styles from "../../../styles/CustomerManagement.module.css";
import styless from "../../../styles/AddProduct.module.css";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import DefaultImage from "../../../assets/images/profile_dummy.svg";
import Plus from "../../../assets/images/plus_white_icon.svg";
import adminProductEdit from "../../../assets/images/admin_product_edit_icon.svg";
import adminProductDelete from "../../../assets/images/admin_delete.svg";
// import EditWarehouseComponent from "../CustomerManagement/EditCustomerComponent";
import AntTableComponent from "../../modules/ReusableComponent/AntTableComponent";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SearchComponent from "../SearchManagement/SearchComponent";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import excel from "../../../assets/images/excel.svg";
import pdf_icon from "../../../assets/images/pdf_icon.svg";
import exportIcon from "../../../assets/images/export_icon.svg";
import * as XLSX from "xlsx";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove as arraMoving,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const pageSizes = [10, 20, 50];
const defaultPageSize = 10;
const originalHeaders = [
  { _id: 1, status: 1, name: "Select", value: "select" },
  { _id: 2, status: 1, name: "Title", value: "Title" },
  { _id: 3, status: 1, name: "Region", value: "region" },
  { _id: 4, status: 1, name: "City", value: "city" },
  { _id: 5, status: 1, name: "Location", value: "location" },
  { _id: 6, status: 1, name: "Pincode", value: "pincode" },
  { _id: 7, status: 1, name: "Enable / Disable", value: "enable" },
  { _id: 8, status: 1, name: "Action", value: "Operate" },
];

const daysObj = [
  { _id: 1, name: "Today", value: 1 },
  { _id: 2, name: "Last 7 Days", value: 7 },
  { _id: 3, name: "Last 30 Days", value: 30 },
  { _id: 4, name: "Last 70 Days", value: 70 },
  { _id: 5, name: "Last 90 Days", value: 90 },
  { _id: 6, name: "Last 12 Months", value: 365 },
  { _id: 7, name: "Custom", value: "custom" },
];

const bulkAction = [
  { _id: 1, isChecked: true, name: "Enable", value: true },
  { _id: 2, isChecked: false, name: "Disable", value: false },
];

const filters = [
  { id: 2, isChecked: false, name: "Date", value: "date" },
  { id: 3, isChecked: false, name: "City", value: "city" },
];

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const placeholder = [
  moment(firstDay).format("DD-MM-YYYY"),
  moment(lastDay).format("DD-MM-YYYY"),
];
const dateFormatList = ["DD-MM-YYYY", "DD-MM-YYYY"];

function StoreManagement() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const [warehouseList, setWarehoseList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextPage, setNextPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({});
  const [showAddWarehouseVisiblity, setshowAddWarehouseVisiblity] =
    useState(false);
  const [regionId, setRegionId] = useState("");
  const [regionName, setRegionName] = useState("All Warehouse");
  const [cityName, setCityName] = useState("All city");
  const [cityId, setCityId] = useState("All city");
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [childComponentVisibility, setChildComponentVisibility] = useState({});
  // const [isLoading, setIsLoading] = useState(true);
  const [IsLoading, setIsLoading] = useState({ delete: false, list: false });
  const [selectFilter, setSelectFilter] = useState(filters);
  const [filterDays, setFilterDays] = useState("");
  const [filterDaysName, setFilterDaysName] = useState("Date");
  const [filteredResult, setFilteredResult] = useState("");
  const [filteredDate, setFilteredDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filterdayArr, setFilterdayArr] = useState(daysObj);
  const [headers, setHeaders] = useState(originalHeaders);
  const [items, setItems] = useState(originalHeaders);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const history = useHistory();
  const [bulkActionFilter, setBulkActionFilter] = useState(bulkAction);
  const pdfExportComponent = useRef(null);

  const [sortOrder, setSortOrder] = useState();
  const [regionOrder, setSortRegion] = useState();
  const [pincodeOrder, setSortPincode] = useState();

  const [filterOptionsGlobal,setFilterOptionsGlobal] = useState();

  const [columns, setColumns] = useState([
    {
      visible: false,
      key: "select",
      render: (text, record, index) => (
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={record.isChecked}
          className={`${commonstyles.childCheckbox}`}
          onChange={(e) => childCheckbox(e, record)}
        ></input>
      ),
    },
    {
      title: "Title",
      visible: false,
      key: "Title",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}><Tooltip
        placement="topRight"
        title={record.state}
      >{record.state}</Tooltip></td>
      ),
      sorter:true
    },
    {
      title: "Region",
      visible: false,
      key: "region",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.regionName}</td>
      ),
      sorter:true
    },
    {
      title: "City",
      visible: false,
      key: "city",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.cityName}</td>
      ),
      sorter:true
    },
    {
      title: "Location",
      visible: false,
      key: "location",
      render: (text, record, index) => (
        <td style={{ textTransform: "capitalize" }}>{record.location}</td>
      ),
    },
    {
      title: "Pincode",
      visible: false,
      key: "pincode",
      sorter:true,
      render: (text, record, index) => <td>{record.pincode}</td>,
    },
    {
      title: "Enable / Disable",
      visible: false,
      key: "enable",
      render: (text, record, index) => (
        <Switch
          checked={record?.enable}
          onChange={(e) => toggleStatusOnChange(e, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },

    {
      title: "Action",
      visible: false,
      key: "Operate",
      render: (text, record, index) =>
        permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Store-edit") ===
          "Catalog-Store-edit" && (
          <td>
            <Tooltip
              placement="topRight"
              title="Edit store"
              // className="tooltip_list"
            >
              <Link to={`/catalog/store/edit/${record._id}`}>
                <img
                  src={adminProductEdit}
                  className={`${commonstyles.admin_table_edit_img} mr-2`}
                  // onClick={() => editWarehouse(record)}
                  alt="Edit action icon"
                  // title="Edit store"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </Tooltip>
            <img
            src={adminProductDelete}
            className={commonstyles.admin_table_edit_img}
            onClick={() => showDeleteConfirmation(record)}
            alt="Delete action icon"
            title="Delete store"
            style={{ cursor: "pointer" }}
          />
          </td>
        ),
    },
  ]);
  const [sortActive, setSortActive] = useState(false);
  const [saveDrag, setSaveDrag] = useState(false)

  useEffect(() => {
    set_new_header(headers);
    let searchNow = "";
    setSelectFilter(filters);
    getStore(pageSize, nextPage, searchNow);
    getRegionList();
    getCityList();
    localStorage.removeItem("selectedStore");
    return () => {
      localStorage.removeItem("storeList");
      localStorage.removeItem("selectedStore");
    };
  }, []);
  function getCityList(){
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/cities?pagesize=10&nextpage=1`)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          if (res.data?.dropDown?.length > 0) {
            setCities(res.data?.dropDown)
          } else {
            setCities([]);
          }
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          setIsLoading(false);
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * @author Mithun Prabhu
   * @param {e, record}
   * CHANGE STATUS OF EACH RECORDS
   */
  const toggleStatusOnChange = (e, record) => {
    if (localStorage.getItem("warehouseList")) {
      let warehouseListClone = JSON.parse(
        localStorage.getItem("warehouseList")
      );
      warehouseListClone.forEach((item) => {
        if (record._id === item._id) {
          item.enable = !item.enable;
          sentStatus(item);
        }
      });
      localStorage.setItem("warehouseList", JSON.stringify(warehouseListClone));
      setWarehoseList(warehouseListClone);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {showRoom}
   * CHANGE STATUS OF EACH RECORD ND CALL UPDATE API
   */
  const sentStatus = (showRoom) => {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`/admin/shworoom/${showRoom._id}/${showRoom.enable}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };

  /**
   * @author Mithun Prabhu
   * @param {showRoom}
  // *** BUILD STATUS CLICK HANDLER *** //
   */
  const bulkStatusClickHandler = (data) => {
    const cloneBulkActionFilter = [...bulkActionFilter];
    cloneBulkActionFilter.forEach((item) => {
      if (item._id === data._id) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    setBulkActionFilter(cloneBulkActionFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {item}
   * This is for checking through different action objects and calling API for submit
   */
  const applyBulkActionHandler = (item) => {
    if (localStorage.getItem("selectedStore")) {
      let selectedOrder = JSON.parse(localStorage.getItem("selectedStore"));
      if (selectedOrder.length > 0) {
        let data = {
          showroomId: selectedOrder,
          status: item.value,
        };
        submitBulkAction(data);
      } else {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please select the store below",
        });
      }
    } else {
      showNotification({
        type: "error",
        message: "Error",
        description: "Please select the store below",
      });
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {data}
   * API call for bulk delete and bulk update coupons.
   */
  const submitBulkAction = (data) => {
    setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("/admin/shworoom/bulkUpdateShowroomStatus", data)
      .then((res) => {
        if (res && (res.data.status === "200" || res.data.status === 200)) {
          getStore(pageSize, nextPage, search);
          showNotification({
            type: "success",
            message: "Success",
            description: res.data.msg,
          });
          localStorage.removeItem("selectedStore");
          const cloneCatagoryList = JSON.parse(
            localStorage.getItem("warehouseList")
          );
          cloneCatagoryList.forEach((item) => {
            item.isChecked = false;
          });
          localStorage.setItem(
            "warehouseList",
            JSON.stringify(cloneCatagoryList)
          );
          setWarehoseList(cloneCatagoryList);
          setIsLoading(false);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  };

  useEffect(() => {
    localStorage.setItem("warehouseList", JSON.stringify(warehouseList));
    return () => {
      localStorage.removeItem("warehouseList");
    };
  }, [warehouseList]);

  /**
   * @author Mithun Prabhu
   * @param {event,record}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const childCheckbox = (e, record) => {
    console.log(record);
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("storeList"));
    let cloneSelectedOrder = [];
    cloneOrderList.forEach((item) => {
      if (record._id === item._id) {
        item.isChecked = checked;
      }
    });
    if (localStorage.getItem("selectedStore")) {
      cloneSelectedOrder = JSON.parse(localStorage.getItem("selectedStore"));
      if (checked) {
        cloneSelectedOrder.push(record._id);
      } else {
        cloneSelectedOrder = cloneSelectedOrder.filter(
          (item) => record._id !== item
        );
      }
    } else if (checked) {
      cloneSelectedOrder.push(record._id);
    }
    localStorage.setItem("selectedStore", JSON.stringify(cloneSelectedOrder));
    localStorage.setItem("storeList", JSON.stringify(cloneOrderList));
    setWarehoseList(cloneOrderList);
    // setSelectedOrder(cloneSelectedOrder);
  };

  /**
   * @author Mithun Prabhu
   * @param {event}
   * To get state of checked and update each records directly woth isChecked parameter
   */
  const parentCheckbox = (e) => {
    const { checked } = e.target;
    const cloneOrderList = JSON.parse(localStorage.getItem("storeList"));
    console.log(cloneOrderList);
    cloneOrderList.forEach((item) => {
      item.isChecked = checked;
    });
    localStorage.setItem("storeList", JSON.stringify(cloneOrderList));
    setWarehoseList(cloneOrderList);
  };

  function searchHandler(e) {
    
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      const updatedFilter = {
        search: value || "",
        nextpage: 1,
        regionId: regionId,
        filterDays: filterDays,
        startDate: filteredDate.startDate,
        endDate: filteredDate.endDate,
        sortOrder:sortOrder,
        pincodeOrder:pincodeOrder,
        regionOrder:regionOrder,
        cityId: cityId !="All city" ? cityId:""
      };
      debounceSearch(updatedFilter);
    }
  }

  /**
   * @author Mithun Prabhu
   * @param {pageSize, nextPage, filterarr}
   * CALLING GET STORE RECORDS
   */
  function getStore(pageSize, nextPage, filterarr,type, sort_value) {
    if (!type) {
      setIsLoading({list:true});
    }
    const filterOptions = {
      pagesize: pageSize,
      nextpage: nextPage,
    };
    if(sort_value){
      if (filterarr && filterarr.cityId) {
        filterOptions.cityId = filterarr.cityId;
      } else if (cityId !== "") {
        if (filterarr && filterarr.cityId !== "") {
          filterOptions.cityId = cityId;
        }
      }
      filterOptions.sortValueOrder = 0
    }else{
      filterOptions.search = search
      if (filterarr && filterarr.search) {
        filterOptions.search = filterarr.search;
      } else if (!filterarr.search) {
        delete filterOptions.search;
      }
      if (filterarr && filterarr.regionId) {
        filterOptions.regionId = filterarr.regionId;
      } else if (regionId !== "") {
        if (filterarr && filterarr.regionId !== "") {
          filterOptions.regionId = regionId;
        }
      }
      if (filterarr && filterarr.cityId != 'All city') {
        filterOptions.cityId = filterarr.cityId;
      } else if (cityId !== "All city") {
          filterOptions.cityId = cityId;
      }
      if (filterarr && filterarr.startDate) {
        filterOptions.startDate = filterarr.startDate;
      } else if (filteredDate.startDate !== "") {
        if (filterarr && filterarr.startDate !== "") {
          filterOptions.startDate = filteredDate.startDate;
        }
      }
      if (filterarr && filterarr.endDate) {
        filterOptions.endDate = filterarr.endDate;
      } else if (filteredDate.endDate !== "") {
        if (filterarr && filterarr.endDate !== "") {
          filterOptions.endDate = filteredDate.endDate;
        }
      }
      if (filterarr && filterarr.filterDays) {
        filterOptions.filterDays = Number(filterarr.filterDays);
      } else if (filteredDate.filterDays !== "") {
        if (filterarr && filterarr.filterDays !== "") {
          filterOptions.filterDays = Number(filteredDate.filterDays);
        }
      }
  
      if (filterarr && filterarr.sortOrder !== "") {
        filterOptions.sortOrder = Number(filterarr.sortOrder);
      } else if (sortOrder !== "" && sortOrder !== null) {
        if (filterarr && sortOrder !== "") {
          filterOptions.sortOrder = Number(sortOrder);
        }
      }
      if (filterarr && filterarr.pincodeOrder !== "") {
        filterOptions.pincodeOrder = Number(filterarr.pincodeOrder);
      } else if (sortOrder !== "" && sortOrder !== null) {
        if (filterarr && sortOrder !== "") {
          filterOptions.pincodeOrder = Number(sortOrder);
        }
      }
      if (filterarr && filterarr.regionOrder !== "") {
        filterOptions.regionOrder = Number(filterarr.regionOrder);
      } else if (sortOrder !== "" && sortOrder !== null) {
        if (filterarr && sortOrder !== "") {
          filterOptions.regionOrder = Number(sortOrder);
        }
      }
    }
    
   
    setFilterOptionsGlobal(filterOptions);
    
    setIsLoading({ delete: false, list: false });
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post("admin/shworoom/getShowroomList", filterOptions)
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setIsLoading(false);
          if (res.data?.data?.length > 0) {
            res.data.data.forEach((item, indx) => {
              item["key"] = indx + 1;
              item["isChecked"] = false;
            });
            localStorage.setItem("storeList", JSON.stringify(res.data.data));
            console.log(res.data.data,"res.data.data")
            setWarehoseList(res.data.data);
            setPagination(res.data.pagination);
          } else {
            setWarehoseList([]);
            setPagination({});
          }
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";
          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }


  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if(res.data.daaata?.length > 1) {
            let obj = { name: "All Warehouse", value: "", _id: "" }
            res.data?.daaata?.unshift(obj);
            setRegions(res.data.daaata);
            let checkDuplicates = filters.filter(item => item.name === 'Region')?.length > 0 ? true : false;
            if (!checkDuplicates) {
              filters.push({ id: 1, isChecked: false, name: "Region", value: "region" })
            }
          };
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }

  const set_new_header = (response) => {
    var newArr = [];
    const data = response;
    for (let i = 0; i < data.length; i++) {
      const columnsClone = [...columns];
      let objIndex = columnsClone.findIndex((obj) => obj.key === data[i].value);
      if (columnsClone[objIndex] !== undefined) {
        columnsClone[objIndex].visible = data[i].status === 1 ? true : false;
        newArr.push(columnsClone[objIndex]);
      }
    }
    setColumns(newArr);
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE HEADERS BASED ON STATUS
   */
  const renderHeader = (props) => {
    return (
      <tr>
        {columns.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
      </tr>
    );
  };

  /**
   * @author Mithun Prabhu
   * @param {props}
   * TO SHOW TABLE DATA BASED ON HEADER STATUS
   */
  const renderBody = (props) => {
    if (props.children.length > 0) {
      return (
        <tr className={props.className}>
          {columns.map((item, idx) => {
            if (item.visible) {
              return props.children[idx];
            }
          })}
        </tr>
      );
    } else {
      return (
        <tr className="bg-transparent text-info">
          <td colSpan="3">No data found!</td>
        </tr>
      );
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange = (oldIndex, newIndex) => {
    oldIndex = oldIndex === 0 ? 2 : oldIndex;
    newIndex = newIndex === 0 ? 2 : newIndex;
    if (oldIndex !== 1 && newIndex !== 1) {
      setItems(arrayMove(items, oldIndex, newIndex));
      const itemClone = [...items];
      let values = rearrange_array(itemClone, oldIndex, newIndex);
      fetchNewCustomer(values);
    }
  };

  /**
   * @author Mithun Prabhu
   * @param {arr, oldIndex, newIndex}
   * This is for rearranging array of columns items according to checking nd unchecking
   */
  const rearrange_array = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let i = newIndex - arr.length + 1;
      while (i--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  /**
   * @author Mithun Prabhu
   * @param {items}
   * This is for fetching new items after checking and unchecking columns
   */
  const fetchNewCustomer = async (items) => {
    let newStatus = [];
    items.map((item, index) => {
      let obj = {};
      obj.order = index;
      obj.status = item.status;
      obj.value = item.value;
      obj._id = item._id;
      newStatus.push(obj);
    });
    setItems(newStatus);
    set_new_header(newStatus);
  };

  /**
   * @author Mithun Prabhu
   * @param {e, id}
   * This is component for checking and unchecking columns in list page
   */
  const on_field_check_handler = (e, id) => {
    const itemClone = [...items];
    let objIndex = items.findIndex((obj) => obj._id === id);
    itemClone[objIndex].status = e.target.checked ? 1 : id === 2 ? 1 : 0;
    setItems(itemClone);
    fetchNewCustomer(items);
  };

  /**
   * @author Mithun Prabhu
   * @param {Menu}
   * This is component for removing/adding columns with onClick event
   */
  const menu = (
    <Menu className="columns_list">
      <MovableListComponent
        listProps={{
          values: items,
          onChange: ({ oldIndex, newIndex }) => rearrange(oldIndex, newIndex),
          renderList: ({ children, props }) => (
            <ul {...props} style={{ listStyleType: "none" }}>
              {children}
            </ul>
          ),
          renderItem: ({ value, props }) => (
            <li {...props} key={value._id} className="columns_list_item">
              {value.value !== "select" && (
                <>
                  <input
                    type="checkbox"
                    checked={value.status === 1 ? true : false}
                    onChange={(event) =>
                      on_field_check_handler(event, value._id)
                    }
                  />
                  <span class="checkmark" style={{ cursor: "move" }}></span>
                  <span>
                    {value.value
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                    <img src={dots} alt="image" />
                  </span>
                </>
              )}
            </li>
          ),
        }}
      />
    </Menu>
  );

  function debounceSearch(filter) {
    // clearTimeout(timeoutId.current);
    setNextPage(1);
    // timeoutId.current = setTimeout(() => {
    getStore(pageSize, 1, filter,true);
    setFilteredResult(filter);
    // }, 500);
  }

  function filterRegionChange(name, value) {
    // const { name, value } = e.target;
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder:sortOrder,
      pincodeOrder:pincodeOrder,
      regionOrder:regionOrder,
      cityId: cityId !="All city" ? cityId:""
    };
    if (name === "regionId") {
      updatedFilter.regionId = value._id;
      setRegionId(value._id);
      setRegionName(value?.name);
    }
    if (name === "cityId") {
      updatedFilter.cityId = value._id;
      setCityId(value._id);
      setCityName(value?.name);
    }
    if (name === "filterDays") {
      updatedFilter.filterDays = value.value;
      setFilterDays(value.value);
      setFilterDaysName(value.name);
    }
    if (value !== "custom") {
      const clonefilteredDate = { ...filteredDate };
      clonefilteredDate.startDate = "";
      clonefilteredDate.endDate = "";
      updatedFilter.startDate = "";
      updatedFilter.endDate = "";
      setFilteredDate(clonefilteredDate);
    }
    debounceSearch(updatedFilter);
  }

  function handlePaginationChanges(value, type) {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder:sortOrder,
      pincodeOrder:pincodeOrder,
      regionOrder:regionOrder,
      cityId: cityId !="All city" ? cityId:""
    };
    if (type === "pageSize") {
      setPageSize(value);
      setNextPage(1);
      getStore(value, 1, updatedFilter,true);
    } else if (type === "nextPage") {
      setNextPage(value);
      getStore(pageSize, value, updatedFilter,true);
    }
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

  function showDeleteConfirmation(customer) {
    setSelectedWarehouse(customer);
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: true,
    });
  }

  function deleteCustomer() {
    // const sureDelete = window.confirm("Are you sure want to delete???");
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get(`admin/shworoom/delete/${selectedWarehouse._id}`)
      .then((res) => {
        if (res.data.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: "Store deleted successfully",
          });
          getStore(pageSize, nextPage, search);
        } else if (res.data.status === 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Failed to remove customer",
          });
        }
      })
      .catch((er) => {
        showNotification({
          type: "error",
          message: "Error",
          description: "Error occured",
        });
      })
      .finally(() => {
        cancelDelete();
      });
  }

  function cancelDelete() {
    setSelectedWarehouse({});
    setChildComponentVisibility({
      ...childComponentVisibility,
      confirmDelete: false,
    });
  }

  function addWareHousePage() {
    history.push("/catalog/store/add");
  }

  function editWarehouse(warehouse) {
    // console.log(warehouse);
    history.push({
      pathname: `/store/edit`,
      state: warehouse,
    });
  }

  // *** DATE RANGE ONCHANGE FUNCTION *** //
  const dateRangeOnchangeHandler = (date, dateString) => {
    const clonefilteredDate = { ...filteredDate };
    clonefilteredDate.startDate = dateString[0]
      ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    clonefilteredDate.endDate = dateString[1]
      ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
      : "";
    setFilteredDate(clonefilteredDate);
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      cityId: cityId !="All city" ? cityId:"",

      startDate: dateString[0]
        ? moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      endDate: dateString[1]
        ? moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD")
        : "",
      sortOrder:sortOrder,
      pincodeOrder:pincodeOrder,
      regionOrder:regionOrder,
    };
    debounceSearch(updatedFilter);
  };

  const commonFilterHandler = (e, data) => {
    const { checked } = e.target;
    let cloneSelectedFilters = [...selectedFilters];
    const cloneSelectFilter = JSON.parse(JSON.stringify([...selectFilter]));
    let updatedFilter = {
      search: search,
      nextpage: 1,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      cityId: cityId !="All city" ? cityId:""
    };
    cloneSelectFilter.forEach((item) => {
      if (item.id === data.id) {
        item.isChecked = checked;
        if (checked) {
          cloneSelectedFilters.push(item.value);
        } else {
          cloneSelectedFilters = cloneSelectedFilters.filter(
            (item) => data.value !== item
          );
        }
      }
      if (!item.isChecked && item.id === data.id) {
        if (item.value === "region") {
          updatedFilter.regionId = "";
          setRegionId("");
          setRegionName("All Region");
        }
        if (item.value === "city") {
          updatedFilter.cityId = "";
          setCityId("");
          setCityName("All city");
        }
        if (item.value === "date") {
          updatedFilter.startDate = "";
          updatedFilter.filterDays = "";
          updatedFilter.endDate = "";
          setFilterDays("");
          setFilterDaysName("Date");
        }
        debounceSearch(updatedFilter);
      }
    });
    setSelectedFilters(cloneSelectedFilters);
    setSelectFilter(cloneSelectFilter);
  };

  /**
   * @author Mithun Prabhu
   * @param {filterMenu}
   * This is card component created for filter dropdown
   */
  const filterMenu = (
    <Menu className="filter_dropdown">
      <div className="card">
        <div className="card_contents">
          <div className="card_info">Filter by</div>
          <div className="row no-gutters">
            <div className="card_dropdown col-md-6">
              <div className="filter_heading card_dropdown_heading">
                Select Filter <img src={arrowDown} width={10} height={20} />
              </div>
              <div className="card_dropdown_contents">
                {Array.isArray(selectFilter)
                  ? selectFilter.map((filter) => (
                      <span key={filter._id}>
                        <input
                          type="checkbox"
                          checked={filter.isChecked}
                          onChange={(e) => commonFilterHandler(e, filter)}
                        ></input>
                        <label>{filter.name}</label>
                      </span>
                    ))
                  : null}
              </div>
            </div>
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "region") === "region" && regions?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {regionName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(regions)
                      ? regions.map((region) => (
                          <span
                            key={region._id}
                            onClick={() =>
                              filterRegionChange("regionId", region)
                            }
                          >
                            <label>{region.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "city") === "city" && cities?.length > 1 && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {cityName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(cities)
                      ? cities.map((city) => (
                          <span
                            key={city._id}
                            onClick={() =>
                              filterRegionChange("cityId", city)
                            }
                          >
                            <label>{city.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" && (
                <div className="card_dropdown show col-md-6">
                  <div
                    style={{ width: "100%" }}
                    className="filter_heading card_dropdown_heading"
                  >
                    {filterDaysName}
                    <img alt="" src={arrowDown} width={10} height={20} />
                  </div>
                  <div className="card_dropdown_contents card_slect_hover card_dropdown_no_padding">
                    {Array.isArray(filterdayArr)
                      ? filterdayArr.map((day) => (
                          <span
                            key={day._id}
                            onClick={() =>
                              filterRegionChange("filterDays", day)
                            }
                          >
                            <label>{day.name}</label>
                          </span>
                        ))
                      : null}
                  </div>
                </div>
              )}
            {selectedFilters.length > 0 &&
              selectedFilters.find((x) => x === "date") === "date" &&
              filterDays === "custom" && (
                <div className="card_dropdown show col-md-12">
                  <AntDateRangeSelectComponent
                    selectProps={{
                      placeholder: placeholder,
                      onChange: dateRangeOnchangeHandler,
                      name: "sessionDateRange",
                      format: dateFormatList,
                    }}
                  />
                </div>
              )}
            {/* <div className={`${commonstyles.card_dropdown} ${commonstyles.show} col-md-6`}>
                      <div className={`${commonstyles.filter_heading} ${commonstyles.card_dropdown_heading}`}>
                        Select Filter <img src={arrowDown} width={10} height={20} />
                      </div>
                      <div className={`${commonstyles.card_dropdown_contents}`}>
                        <span>Region</span>
                        <span>Date</span>
                        <span>Payment Status</span>
                        <span>Delivery Status</span>
                      </div>
                  </div> */}
          </div>
        </div>
      </div>
    </Menu>
  );

  /* Export here */
  const exportToCSV = async (e) => {
    let params = filterOptionsGlobal;
    delete params.nextpage;
    delete params.pagesize;

    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .post(`/admin/shworoom/exportShowroomList`, params)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const data = response.data;
          data?.forEach((item) => {
            if (item["createdAt"]) {
              item["createdAt"] = moment(item["createdAt"]).format(
                "MMM Do YYYY, h:mm:ss A"
              );
            }
          });
          let und_res = data;
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".csv";
          const ws = XLSX.utils.json_to_sheet(und_res);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "People");

          /* generate an XLSX file */
          XLSX.writeFile(
            wb,
            `Store-export-${moment(new Date())
              .format("YYYY-MM-DD")
              .split("-")
              .join("")}.csv`
          );
          // FileSaver.saveAs(data, "WeeklyPlans" + fileExtension);
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          // return [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportToPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  function searchClick() {
    let updatedFilter = {
      search: search,
      nextpage: 1,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate.startDate,
      endDate: filteredDate.endDate,
      sortOrder:sortOrder,
      pincodeOrder:pincodeOrder,
      regionOrder:regionOrder,
      cityId: cityId !="All city" ? cityId:""
    };
    debounceSearch(updatedFilter);
  }

  const handleTableChange = (pagination, filters, sorter) => {

    let updatedFilter = {
      search: search,
      nextpage: nextPage,
      regionId: regionId,
      filterDays: filterDays,
      startDate: filteredDate?.startDate,
      endDate: filteredDate?.endDate,
      cityId: cityId !="All city" ? cityId:""
    };
    if (sorter?.columnKey === "Title") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.sortOrder = sortValue;
      setSortOrder(sortValue);
      setSortRegion();
      setSortPincode();
    } else if (sorter?.columnKey === "region") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.regionOrder = sortValue;
      setSortRegion(sortValue);
      setSortOrder();
      setSortPincode();
    } else if (sorter?.columnKey === "pincode") {
      let sortValue = sorter.order === "ascend" ? 0 : 1;
      updatedFilter.pincodeOrder = sortValue;
      setSortPincode(sortValue);
      setSortOrder();
      setSortRegion();
    } 
    getStore(pageSize, nextPage, updatedFilter, true);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        },
      ),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      // eslint-disable-next-line no-unused-expressions
      setWarehoseList((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arraMoving(prev, activeIndex, overIndex);
      });
    
    }
  };
  const sortActiveChange = () =>{
  setSortActive(!sortActive);
    let updatedFilter = {
      search: search,
      nextpage: 1, 
      regionId: regionId,
      cityId: cityId != "All city" ? cityId : ""
    };
    if(!sortActive === true){
    setPageSize(50)
    getStore(50, 1, updatedFilter,true, true)
    }else{
     setPageSize(10)
     getStore(10, 1, updatedFilter,true);
    }
  }
  const dragAndDropSave = () =>{
    const updateSortData = warehouseList.map((warehouse,index) => {return {_id:warehouse._id, sortValue:index+1}})
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .put(`/admin/shworoom/updateOrder`, {data:updateSortData})
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          showNotification({
            type: "success",
            message: "Success",
            description: response.msg,
          });
          setPageSize(10)
          setSortActive(false)
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          showNotification({
            type: "error",
            message: "Error",
            description: response.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    
  }

  return (
    <div className={commonstyles.management_contents}>
      <SearchComponent />
      <div className={commonstyles.management_header}>
        <h1 className={commonstyles.management_header_heading}>Store</h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Store-view") ===
            "Catalog-Store-view" && (
            <div>
              <span
                className={commonstyles.management_header_downloads_text}
                style={{
                  cursor: "pointer",
                }} /* onClick={ (e) => exportToCSV(e)} */
              >
                <Tooltip placement="topRight" title="Download pdf">
                  <img
                    className={commonstyles.export_icon}
                    src={pdf_icon}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => exportToPDF(e)}
                  />
                </Tooltip>
                <span
                  className={commonstyles.management_header_downloads_text}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => exportToCSV(e)}
                >
                  <Tooltip placement="topRight" title="Download excel">
                    <img className={commonstyles.export_icon} src={excel} />
                  </Tooltip>
                  <span className={commonstyles.management_header_downloads_text_ex}>
                    Export <img src={exportIcon} alt="icon" />
                  </span>
                </span>
              </span>
            </div>
          )}
      </div>

      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Store-add") ===
          "Catalog-Store-add" && (
          <div className={commonstyles.management_header}>
            <ButtonComponent
              className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
              attributes={{
                onClick: addWareHousePage,
              }}
            >
              {/* <img src={plusIcon} className="mr-2" /> */}
              Add Store
            </ButtonComponent>
            {cityName && cityName != "All city" &&
            <div>
               <ButtonComponent
               className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
               attributes={{
                 onClick: sortActiveChange
               }}
             >
               {/* <img src={plusIcon} className="mr-2" /> */}
               {!sortActive ? "Sort" : "Cancel"}
             </ButtonComponent>
             {sortActive &&  <ButtonComponent
               className="theme-btn-outlined rnd pl-3 pr-4 mr-3"
               
               attributes={{
                style: {backgroundColor:"#A39486", color:"#fff"},
                 onClick: dragAndDropSave
               }}
             >
              Save
             </ButtonComponent>}
             </div>
            }
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Store-view") ===
          "Catalog-Store-view" && (
          <div
          className={`${commonstyles.management_contents_search} ${commonstyles.management_contents_search_sale} ${sortActive && commonstyles.read_only_container}`}

          >
            <div
              className={`${commonstyles.management_contents_search_bar} input-group`}
            >
              <InputComponent
                className="with-icon rnd theme-txt-xsm"
                formControl={{
                  placeholder: "Search by Title, Location...",
                  name: "search",
                  style: { height: "38px" },
                  value: search,
                  onChange: (e) => searchHandler(e),
                  ["aria-label"]: "Search customers",
                  ["aria-describedby"]: "search-customers",
                }}
              />
              <img src={Search} alt="" />
            </div>
            <ButtonComponent
              className="theme-btn rnd pl-3 pr-4 ml-2"
              attributes={{
                onClick: () => {
                  searchClick();
                },
              }}
            >
              Search
            </ButtonComponent>
            <div className="action ml-3">
              <div className="action_header">
                Action
                <img src={arrowDown} alt="image" />
              </div>
              <div className="action_contents">
                {bulkActionFilter.length > 0 &&
                  bulkActionFilter.map((item) => (
                    <div
                      className="action_contents_header"
                      key={item._id}
                      onClick={() => bulkStatusClickHandler(item)}
                    >
                      <h2
                        className={`action_contents_header_h2 ${
                          item.isChecked && "action_contents_header_h2_active"
                        }`}
                      >
                        {item.name}
                      </h2>
                      {item.isChecked && (
                        <span
                          className="action_contents_btn"
                          onClick={() => applyBulkActionHandler(item)}
                        >
                          Apply
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={handleVisibleChange} visible={visible}
              className="usermanage_table_filter_columns ml-3"
              // style={{ marginLeft: "100px" }}
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Filter <img alt="" src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>

            <Dropdown
              overlay={menu}
              className="usermanage_table_filter_columns ml-3"
              // style={{ marginRight: "100px" }}
            >
              <div
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Column <img src={arrowDown} width={10} height={20} />
              </div>
            </Dropdown>
          </div>
        )}
      {permissionsArr.length > 0 &&
        permissionsArr.find((x) => x === "Catalog-Store-view") ===
          "Catalog-Store-view" && (
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Store Report for ${new Date().getFullYear()}`}
            author="Damro Team"
          >
            <div
              className={`${commonstyles.management_table} table-responsive`}
            >
              {!IsLoading.list && warehouseList.length > 0 && (
                <input
                  type="checkbox"
                  className={`${commonstyles.parentCheckbox}`}
                  onChange={parentCheckbox}
                ></input>
              )}
              {IsLoading.list === true ? (
                <div
                  style={{
                    marginLeft: "70vh",
                    marginTop: "10vh",
                  }}
                >
                  <tr className="bg-transparent">
                    <td colSpan="100%">
                      <LoadingIndicator loadingText="Fetching store details..." />
                    </td>
                  </tr>
                </div>
              ) : (
                permissionsArr.length > 0 &&
                permissionsArr.find((x) => x === "Catalog-Store-view") ===
                  "Catalog-Store-view" && (
                    <div className={`${sortActive && commonstyles.hide_sort_time_edit}`}>
                     {!sortActive ?
                  <AntTableComponent
                  className={`order_table ${commonstyles.hide_sort}` }
                    tableProps={{
                      columns: columns,
                      dataSource: warehouseList,
                      pagination: false,
                      onChange: handleTableChange,
                      components: {
                        header: {
                          row: (props) => renderHeader(props),
                        },
                        body: {
                          row: (props) => renderBody(props),
                        },
                      },
                    }}
                  />: <DndContext onDragEnd={onDragEnd}>
                  <SortableContext
                    // rowKey array
                    items={warehouseList.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                  >
                <Table
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  rowKey="key"
                  columns={columns}
                  dataSource={warehouseList}
                  pagination={false}
                />
                </SortableContext>
                </DndContext>}</div>
                )
              )}
            </div>
          </PDFExport>
        )}
      {!IsLoading.list && Array.isArray(warehouseList) && warehouseList.length
        ? permissionsArr.length > 0 &&
          permissionsArr.find((x) => x === "Catalog-Store-view") ===
            "Catalog-Store-view" && (
            <Pagination
              pageSizes={pageSizes}
              pageSize={pageSize}
              nextPage={nextPage}
              data={pagination}
              handlePaginationChanges={handlePaginationChanges}
            />
          )
        : null}

      {/* Category delete confirmation modal  */}
      <ConfirmationBox
        modal={{
          show: childComponentVisibility.confirmDelete,
          size: "md",
          centered: true,
        }}
        handleCancel={cancelDelete}
        handleConfirm={deleteCustomer}
        title="Confirm the action"
        confirmText="Delete"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
            Are you sure want to delete?
            </p>
          </div>
        </div>
      </ConfirmationBox>
      {/* Category delete confirmation modal ends*/}
    </div>
  );
}

export default StoreManagement;
