import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import { runValidationChecks } from "../../../utils";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import { alphaNum, onlyNumbers } from "../../../utils/RegularExp";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";

const refundNew = {
  regionList: "",
  // logisticsCharge: "",
  refundCharges: "",
};

const refund = JSON.parse(JSON.stringify(refundNew));

const rules = {
  refundform: [
    (val) => {
      if (Array.isArray(val)) {
        // console.log(val);
        const errors = val.reduce(validateAvenueformItem, {});
        if (Object.keys(errors).length) {
          return errors;
        }
        return true;
      }
    },
  ],
};

function validateAvenueformItem(accumulator, refundItem, indx) {
  const error = {};
  ["regionId", "refundCharges" /* "logisticsCharge" */].forEach((field) => {
    if (!refundItem[field]) {
      error[field] = field + " ";
    }
    if (!field === "regionId" || !alphaNum.test(refundItem[field])) {
      error[field] = field + " ";
    }
  });
  if (Object.keys(error).length) {
    accumulator[indx] = error;
  }
  return accumulator;
}

export default function PaymentNew() {
  let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];

  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const [refundform, setRefundForm] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [isLoading, setIsLoading] = useState({ delete: false, list: true });
  const [selectedValue, setSelectedValue] = useState([]);
  const [removeIndx, setRemoveIndx] = useState(null);

  const selectedRegionIds = Array.isArray(refundform)
    ? refundform.map((stock) => stock.regionId)
    : [];
  // console.log(selectedRegionIds)
  useEffect(() => {
    getRegionList();
    getRefundChargeList();
  }, [refundform?._id]);


   /**
   * @author Mithun Prabhu
   * @param {pageSize,nextPage,searchNow}
   * GET ALL REFUND DETAILS OF DIFFERENT LOCATIONS AND DISPLAY IN FORM
   */
  function getRefundChargeList(pageSize, nextPage, searchNow) {
    setIsLoading({ delete: false, list: true });
    AxiosInstance(PORTS.INVOICE, "application/json")
      .get("orders/refundCharge/listCharge")
      .then((res) => {
        if (res && res.data && res.status == "200") {
          setRefundForm(res.data.data);
        }
      })
      .catch((er) => {
        const data = { type: "error" };
        if (er.response) {
          data.message = "Error";
          data.description = "Failed to get data";

          showNotification(data);
        }
      })
      .finally(() => {
        setIsLoading({ delete: false, list: false });
      });
  }

   /**
   * @author Mithun Prabhu
   * @param {}
   * GET ALL REGION LIST WITH ID
   */
  function getRegionList() {
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.data.status === 200) {
          res.data.daaata.unshift({
            name: "",
            _id: "",
          });
          setRegionList(res.data.daaata);
        }
      })
      .catch((er) => {
        return er;
      });
  }

   /**
   * @author Mithun Prabhu
   * @param {event}
   * SUBMIT ALL FORM CHANGES AND CALL SUBMIT API
   */
  function submit(e) {
    let data;
    data = refundform.map((item) => {
      return {
        _id: item._id,
        regionId: item.regionId,
        // logisticsCharge: parseInt(item.logisticsCharge),
        refundCharges: parseInt(item.refundCharges),
      };
    });

    const result = runValidationChecks(
      { refundform: rules.refundform },
      undefined,
      { refundform: data }
    );
    // console.log(result);
    if (Object.keys(result).length) {
      setFormErrors(result);
      showNotification({
        type: "warning",
        message: "There were few errors",
        // description: Object.values(result.refundform[0]) + "is required",
        description: "Resolve the errors and try again",
      });
      return;
    }

    const apiEndPoint = "orders/refundCharge/addupdateCharge";

    AxiosInstance(PORTS.INVOICE)
      .post(apiEndPoint, { data })
      .then((res) => {
        if (res.data.status === 200) {
          let last = regionList.pop();
          setRegionList([last]);
          showNotification({
            type: "success",
            message: "Success",
            description: "Refund charge updated successfully",
          });
          history.push(`site-settings`);
        } else if (res && res.data.status == 400) {
          showNotification({
            type: "error",
            message: "Error",
            description: "Warehouse added already exists",
          });
        }
      })
      .catch((er) => {
        return er;
      });
  }

  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }

   /**
   * @author Mithun Prabhu
   * @param {event,indx}
   * CHANGE FORM VALUES IN ALEADY EXISTING AS WELL AS NEWLY CREATED FORM
   */
  function handleAvenueFormChange(e, indx) {
    let { name, value, checked } = e.target;
    value = value.trim();
    if (["refundCharges", "regionId" /* "logisticsCharge" */].includes(name)) {
      refundform[indx][name] = value;
      setRefundForm([...refundform]);

      if (name == "regionId") {
        if (regionList.map((item) => item._id == value.toString())) {
          let filtered = regionList.filter(function (item, index, array) {
            return value.toString() != item._id;
          });
          setSelectedValue(filtered);
        }
      }
      if (!name === "regionId") {
        if (!formErrors.refundform) {
          formErrors.refundform = {};
        }
        if (formErrors.refundform && !formErrors.refundform[indx]) {
          formErrors.refundform[indx] = {};
        }
        formErrors.refundform[indx][name] = "Enter max 5 digits";
      } else {
        if (
          // !value &&
          formErrors.refundform &&
          formErrors.refundform[indx] &&
          formErrors.refundform[indx][name]
        ) {
          formErrors.refundform[indx][name] = "";
        }
      }
    }
  }

   /**
   * @author Mithun Prabhu
   * @param {}
   * ADD NEW OBJECT OF NEW FORM AND PUSH TO ALREADY EXISTING ARRAY OF OBJECTS
   */
  function addNew() {
    //already updated refund data form
    const updatedValue = JSON.parse(JSON.stringify(refundform));
    //this is new object created for as refund data form
    const newAvenue = JSON.parse(JSON.stringify(refund));
    //pushing new object with already updated record i.e refund form data
    updatedValue.push(newAvenue);
    //then set old and new record to refund form
    setRefundForm(updatedValue);
  }

  const removeRenfundHandler = (RemovedIndx) => {
    setRemoveIndx(RemovedIndx);
  }

  function cancelDelete() {
    setRemoveIndx(null);
  }

  function deleteDecor() {
    const cloneRefundform = [...refundform];
    cloneRefundform.splice(removeIndx, 1);
    setRefundForm(cloneRefundform);
    setRemoveIndx(null);
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/site-settings")}
          />
          Reversal Charge
        </h1>
        {permissionsArr.length > 0 &&
          permissionsArr.find(
            (x) => x === "SiteSettings-ReversalCharge-add"
          ) === "SiteSettings-ReversalCharge-add" && (
            <div className={styles.management_contents_upload}>
              {/* <button className={`btn ${styles.btn_filled}`}>Save</button> */}
              <ButtonComponent
                className="rnd outline_cancel_btn mr-3"
                attributes={{
                  onClick: () => history.push(`/site-settings`),
                }}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className="rnd"
                attributes={{
                  onClick: submit,
                }}
              >
                Save
              </ButtonComponent>
            </div>
          )}
      </div>

      {isLoading.list === true ? (
        <div
          style={{
            marginLeft: "70vh",
            marginTop: "40vh",
          }}
        >
          <tr className="bg-transparent">
            <td colSpan="100%">
              <LoadingIndicator loadingText="Fetching reversal charge details..." />
            </td>
          </tr>
        </div>
      ) : (
        <div className={styles.management_details}>
          {permissionsArr.length > 0 &&
            permissionsArr.find(
              (x) => x === "SiteSettings-ReversalCharge-add"
            ) === "SiteSettings-ReversalCharge-add" &&
            refundform.map((refund, indx) => (
              <React.Fragment key={indx}>
                <div className={styles.management_details_contents}>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <div className={styles.management_details_form}>
                        <label for="">
                        Warehouse ID
                          <span
                            className="text-danger"
                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                          >
                            *
                          </span>
                        </label>
                        <SelectComponent
                          className={`form-control form-control-md ${styles.form_control}`}
                          error={
                            formErrors.refundform &&
                            formErrors.refundform[indx] &&
                            formErrors.refundform[indx].regionId
                          }
                          attributes={{
                            name: "regionId",
                            value: refund.regionId,
                            onChange: (e) => handleAvenueFormChange(e, indx),
                          }}
                        >
                          <option disabled>Choose a region</option>
                          {regionList.map((region) => (
                            <option
                              key={region._id}
                              value={region._id}
                              disabled={selectedRegionIds.includes(region._id)}
                            >
                              {region.name}
                            </option>
                          ))}
                        </SelectComponent>
                        <FormErrorText
                          error={
                            formErrors.refundform &&
                            formErrors.refundform[indx] &&
                            formErrors.refundform[indx].regionId
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <div className={styles.management_details_form}>
                        <label for="">
                          Add reversal charge
                          <span
                            className="text-danger"
                            style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                          >
                            *
                          </span>
                        </label>
                        <InputComponent
                          className={`form-control form-control-lg ${styles.form_control}`}
                          error={
                            formErrors.refundform &&
                            formErrors.refundform[indx] &&
                            formErrors.refundform[indx].refundCharges
                          }
                          formControl={{
                            placeholder: "",
                            name: "refundCharges",
                            maxLength: 5,
                            value: refund.refundCharges,
                            onChange: (e) => handleAvenueFormChange(e, indx),
                          }}
                        />
                        <FormErrorText
                          error={
                            formErrors.refundform &&
                            formErrors.refundform[indx] &&
                            formErrors.refundform[indx].refundCharges
                          }
                        />
                      </div>
                    </div>
                    {refundform?.length > 1 && <div onClick={() => removeRenfundHandler(indx)} className="col-lg-4 ml-auto align-self-end mb-2 text-right add_text theme-btn-link text-danger">
                      <span>
                        Remove Refund
                      </span>
                    </div>}

                    {/* <div className="col-lg-6 mb-4">
                    <div className={styles.management_details_form}>
                      <label for="">
                        Logistics Charge
                        <span
                          className="text-danger"
                          style={{ fontSize:"20px",bottom:-8,position:"relative" }}
                        >
                          *
                        </span>
                      </label>
                      <InputComponent
                        className={`form-control form-control-lg ${styles.form_control}`}
                        error={
                          formErrors.refundform &&
                          formErrors.refundform[indx] &&
                          formErrors.refundform[indx].logisticsCharge
                        }
                        formControl={{
                          placeholder: "",
                          name: "logisticsCharge",
                          value: refund.logisticsCharge,
                          maxLength: 5,
                          onChange: (e) => handleAvenueFormChange(e, indx),
                        }}
                      />
                      <FormErrorText
                        error={
                          formErrors.refundform &&
                          formErrors.refundform[indx] &&
                          formErrors.refundform[indx].logisticsCharge
                        }
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
              </React.Fragment>
            ))}
          {permissionsArr.length > 0 &&
            permissionsArr.find(
              (x) => x === "SiteSettings-ReversalCharge-add"
            ) === "SiteSettings-ReversalCharge-add" && (
              <div className={styles.payment_btn}>
                <button className={`btn ${styles.btn_filled}`} onClick={addNew}>
                  Add Refund
                </button>
              </div>
            )}
            <ConfirmationBox
              modal={{
                show: removeIndx===null? false:true,
                size: "md",
                centered: true,
              }}
              handleCancel={cancelDelete}
              handleConfirm={deleteDecor}
              title="Confirm the action"
              confirmText="Delete"
            >
              <div className="container-fluid">
                <div className="row">
                  <p className="theme-txt-detail">
                    Are you sure want to Remove?
                  </p>
                </div>
              </div>
            </ConfirmationBox>
        </div>
      )}
    </div>
  );
}
