import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonComponent from "./ButtonComponent";
import styles from "../../../styles/ConfirmationBox.module.css"

export default function ConfirmationBox(props) {
  return (
    <Modal {...props.modal} onHide={props.handleCancel}>
      <Modal.Header>
        <Modal.Title className={styles.manage_lineHeight}>
          <span className="theme-form-section-title">{props.title}</span><br></br>
          <span className={styles.manage_delete_text}>Are you sure you want to delete?</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children || props.body}</Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          className="btn-sm theme-btn-outlined"
          attributes={{ onClick: props.handleCancel }}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent attributes={{ onClick: props.handleConfirm }}>
          {props.confirmText || "Confirm"}
        </ButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}
