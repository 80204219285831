export const AllModules_ = [
    {
        name: "Dashboard",
        value: "Dashboard",
        data: [
            {
                "_id": 1,
                name: "Revenue",
                value: "Revenue",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Total Sales",
                value: "TotalSales",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Average Order Value",
                value: "AverageOrderValue",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Total Number of Orders",
                value: "TotalNumberofOrders",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Stats Overview",
                value: "StatsOverview",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Activities",
                value: "Activities",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Quick Details",
                value: "QuickDetails",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Top Selling Categories",
                value: "TopSellingCategories",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Most Viewed Products",
                value: "MostViewedProducts",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Top Searched",
                value: "TopSearched",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Latest Searched",
                value: "LatestSearched",
                add: false,
                edit: false,
                view: false
            },
        ]
    },
    {
        name: "Sales",
        value: "Sales",
        data: [
            { 
                "_id": 1, 
                name: 'Orders', 
                value: 'Orders', 
                add: false,
                edit: false,
                view: false
            },
            { 
                "_id": 2, 
                name: 'Transactions',
                value: 'Transactions',
                add: false,
                edit: false,
                view: false
            },
            { 
                "_id": 3, 
                name: 'Refunds',
                value: 'Refunds',
                add: false,
                edit: false,
                view: false
            },
            { 
                "_id": 4, 
                name: 'Cancelled Orders',
                value: 'CancelledOrders',
                add: false,
                edit: false,
                view: false
            },
            { 
                "_id": 5, 
                name: 'Tax',
                value: 'Tax',
                add: false,
                edit: false,
                view: false
            },
            { 
                "_id": 6, 
                name: 'Documents',
                value: 'Documents',
                add: false,
                edit: false,
                view: false
            },
        ]
    },
    {
        name: "Marketing",
        value: "Marketing",
        data: [
            { 
                _id: 1, 
                name: 'Abandoned',
                value: 'Abandoned',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 2, 
                name: 'Communication',
                value: 'Communication',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 3, 
                name: 'Rating',
                value: 'Rating',
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Discount",
        value: "Discount",
        data: [
            {
                "_id": 1,
                name: "Discount",
                value: "Discount",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Customer",
        value: "Customer",
        data: [
            {
                "_id": 1,
                name: "Customer",
                value: "Customer",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Catalog",
        value: "Catalog",
        data: [
            { 
                _id: 1, 
                name: 'Products',
                value: 'Products',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 2, 
                name: 'Categories',
                value: 'Categories',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 3, 
                name: 'Collection',
                value: 'Collection',
                add: false,
                edit: false,
                view: false
            },
            // { 
            //     _id: 4, 
            //     name: 'Warehouse',
            //     value: 'Warehouse',
            //     add: false,
            //     edit: false,
            //     view: false
            // },
            { 
                _id: 5, 
                name: 'Store',
                value: 'Store',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 6, 
                name: 'Delivery',
                value: 'Delivery',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 7, 
                name: 'Manage Colour',
                value: 'Manage Colour',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 8, 
                name: 'Stock Reversal Products',
                value: 'Stock Reversal Products',
                add: false,
                edit: false,
                view: false
            },
        ]
    },
    {
        name: "Reports",
        value: "Reports",
        data: [
            {
                "_id": 1,
                name: "Reports",
                value: "Reports",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Modular",
        value: "Modular",
        data: [
            {
                "_id": 1,
                name: "Modular",
                value: "Modular",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Analytics",
        value: "Analytics",
        data: [
            {
                "_id": 1,
                name: "Analytics",
                value: "Analytics",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    // {
    //     name: "CMS page",
    //     value: "CMSPage",
    //     data: [
    //         {
    //             "_id": 1,
    //             name: "CMS page",
    //             value: "CMSPage",
    //             add: false,
    //             edit: false,
    //             view: false
    //         }
    //     ]
    // },
    {
        name: "FAQ",
        value: "FAQ",
        data: [
            {
                "_id": 1,
                name: "FAQ",
                value: "FAQ",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    // {
    //     name: "Banner",
    //     value: "Banner",
    //     data: [
    //         {
    //             "_id": 1,
    //             name: "Banner",
    //             value: "Banner",
    //             add: false,
    //             edit: false,
    //             view: false
    //         }
    //     ]
    // },
    {
        name: "Delivery",
        value: "Delivery",
        data: [
            {
                "_id": 1,
                name: "Delivery",
                value: "Delivery",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Content",
        value: "Content",
        data: [
            {
                "_id": 1,
                name: "CMS page",
                value: "CMSPage",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 2,
                name: "Banner",
                value: "Banner",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 3,
                name: 'CategoryWise',
                value: 'CategoryWise',
                add: false,
                edit: false,
                view: false
            },{
                "_id": 4,
                name: "ProductWise",
                value: "ProductWise",
                add: false,
                edit: false,
                view: false
            },{
                "_id": 5,
                name: "StoreWise",
                value: "StoreWise",
                add: false,
                edit: false,
                view: false
            },{
                "_id": 6,
                name: "Hotdeal",
                value: "Hotdeal",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 7,
                name: "Promotional",
                value: "Promotional",
                add: false,
                edit: false,
                view: false
            },
            {
                "_id": 8,
                name: "Testimonial",
                value: "Testimonial",
                add: false,
                edit: false,
                view: false
            },{
                "_id": 9,
                name: "HomePage",
                value: "HomePage",
                add: false,
                edit: false,
                view: false
            }
        ]
    },
    {
        name: "Site settings",
        value: "SiteSettings",
        data: [
            { 
                _id: 1, 
                name: 'General',
                value: 'General',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 2, 
                name: 'Regions',
                value: 'Regions',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 3, 
                name: 'Zone',
                value: 'Zone',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 4, 
                name: 'Payments',
                value: 'Payments',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 5, 
                name: 'Reversal Charge',
                value: 'ReversalCharge',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 6, 
                name: 'User and Permission',
                value: 'UserandPermission',
                add: false,
                edit: false,
                view: false
            },
            { 
                _id: 7, 
                name: 'Modular Enquiries',
                value: 'ModularEnquiries',
                add: false,
                edit: false,
                view: false
            }
        ]
    },
];

export const buttons = [
    {
        "id": 1, name: "Dashboard", value: "Dashboard", active: true
    },
    {
        "id": 2, name: "Sales", value: "Sales", active: false
    },
    {
        "id": 3, name: "Marketing", value: "Marketing", active: false
    },
    {
        "id": 4, name: "Discount", value: "Discount", active: false
    },
    {
        "id": 5, name: "Customer", value: "Customer", active: false
    },
    {
        "id": 6, name: "Catalog", value: "Catalog", active: false
    },
    {
        "id": 7, name: "Reports", value: "Reports", active: false
    },
    {
        "id": 8, name: "Modular", value: "Modular", active: false
    },
    {
        "id": 9, name: "Analytics", value: "Analytics", active: false
    },
    // {
    //     "id": 9, name: "CMS page", value: "CMSPage", active: false
    // },
    {
        "id": 10, name: "FAQ", value: "FAQ", active: false
    },
    // {
    //     "id": 11, name: "Banner", value: "Banner", active: false
    // },
    // {
    //     "id": 12, name: "Delivery", value: "Delivery", active: false
    // },
    {
        "id": 11, name: "Content", value: "Content", active: false
    },
    {
        "id": 13, name: "Site Settings", value: "SiteSettings", active: false
    }
]

export const treeData_ = [
    {
      title: 'Dashboard',
      key: 'Dashboard',
      children: [
        {
            title: "Revenue",
            key: "Revenue",

        },
        {
            title: "Total Sales",
            key: "TotalSales",

        },
        {
            title: "Average Order Value",
            key: "AverageOrderValue",

        },
        {
            title: "Total Number of Orders",
            key: "TotalNumberofOrders",

        },
        {
            title: "Stats Overview",
            key: "StatsOverview",

        },
        {
            title: "Activities",
            key: "Activities",

        },
        {
            title: "Quick Details",
            key: "QuickDetails",

        },
        {
            title: "Top Selling Categories",
            key: "TopSellingCategories",

        },
        {
            title: "Most Viewed Products",
            key: "MostViewedProducts",

        },
        {
            title: "Top Searched",
            key: "TopSearched",

        },
        {
            title: "Latest Searched",
            key: "LatestSearched",

        },
      ],
    },
    {
        title: "Sales",
        key: "Sales",
        children: [
            { 

                title: 'Orders', 
                key: 'Orders', 

            },
            { 

                title: 'Transactions',
                key: 'Transactions',

            },
            { 

                title: 'Refunds',
                key: 'Refunds',

            },
            { 

                title: 'Cancelled Orders',
                key: 'CancelledOrders',

            },
            {
                title: 'Tax',
                key: 'Tax',
            },
            { 
                title: 'Documents',
                key: 'Documents',
            }
        ]
    },
    {
        title: "Marketing",
        key: "Marketing",
        children: [
            {  
                title: 'Abandoned',
                key: 'Abandoned',
            },
            {  
                title: 'Communication',
                key: 'Communication',
            },
            {  
                title: 'Rating',
                key: 'Rating',

            }
        ]
    },
    {
        title: "Discount",
        key: "Discount",
    },
    {
        title: "Customer",
        key: "Customer",
    },
    {
        title: "Catalog",
        key: "Catalog",
        children: [
            {  
                title: 'Products',
                key: 'Products',

            },
            {  
                title: 'Categories',
                key: 'Categories',

            },
            {  
                title: 'Collection',
                key: 'Collection',

            },
            // {  
            //     title: 'Warehouse',
            //     key: 'Warehouse',

            // },
            {  
                title: 'Store',
                key: 'Store',

            },
            {  
                title: 'Delivery',
                key: 'Delivery',

            },
            {  
                title: 'Manage Colour',
                key: 'Manage Colour',

            },
            {
                title: 'Stock Reversal Products',
                key: 'Stock Reversal Products'
            },
        ]
    },
    {
        title: "Reports",
        key: "Reports",
    },
    {
        title: "Analytics",
        key: "Analytics",
    },
    // {
    //     title: "CMSPage",
    //     key: "CMSPage",
    // },
    {
        title: "FAQ",
        key: "FAQ",
    },
    // {
    //     title: "Banner",
    //     key: "Banner",
    // },
    {
        title: "Delivery",
        key: "Delivery_",
    },
    {
        title: "Content",
        key: "Content",
        children: [
            {  
                title: 'CMSPage',
                key: 'CMSPage',

            },
            {  
                title: 'Banner',
                key: 'Banner',

            },
            {  
                title: 'CategoryWise',
                key: 'CategoryWise',

            },
            {  
                title: 'ProductWise',
                key: 'ProductWise',

            },
            {  
                title: 'StoreWise',
                key: 'StoreWise',

            },
            {
                title:"Hotdeal",
                key:'Hotdeal'
            },
            {  
                title: 'Promotional',
                key: 'Promotional',

            },
            {
                title:"Testimonial",
                key:'Testimonial',
            },
             {  
                title: 'HomePage',
                key: 'HomePage',

            }
           
        ]
    },
    {
        title: "SiteSettings",
        key: "Site Settings",
        children: [
            {  
                title: 'General',
                key: 'General',

            },
            {  
                title: 'Regions',
                key: 'Regions',

            },
            {  
                title: 'Zone',
                key: 'Zone',

            },
            {  
                title: 'Payments',
                key: 'Payments',

            },
            {  
                title: 'Reversal Charge',
                key: 'ReversalCharge',

            },
            {  
                title: 'User and Permission',
                key: 'UserandPermission',

            },
            {  
                title: 'Modular Enquiries',
                key: 'ModularEnquiries',

            }
        ]
    },
];