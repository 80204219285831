import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { notification, Select } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";
import CommonBanner from "../CMSManagement/CommonBanner";

export default function MadeForBanner(props) {
  return (
    <div>
      {props?.madeForBanner?.length > 0 &&
        props?.madeForBanner.map((item, indx) => (
          <div key={indx} className={styles.management_details_contents}>
            <p className={styles.management_header_heading}>
              {props?.page === 1 ? "Small Banner" : "Forgot password Banner"}
            </p>
            <CommonBanner
              regions={props.regions}
              searchSelectOnChange={props.searchSelectOnChange}
              remainingRegions={props?.remainingRegions?.madeForBanner}
              mainLeftBannerErrors={props.mainLeftBannerErrors}
              handleFormInput={props.handleFormInput}
              categoryNames={props.categoryNames}
              handleImageInput={props.handleImageInput}
              addBanners={props.addBanners}
              removeBanners={props.removeBanners}
              regionId={props.regionId}
              item={item}
              indx={indx}
              page={props?.page}
              bannerName={"madeForBanner"}
              removeImages={props.removeImages}
              handleCheckboxChange={props.handleCheckboxChange}
            />
          </div>
        ))}
    </div>
  );
}
