import { useEffect, /* useRef, */ useState } from "react";
import { Select } from "antd";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import commonstyles from "../../../styles/Management_Common.module.css";
const { Option } = Select;

export default function ProductSearchSelectComponent(props) {
  // console.log(props);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  useEffect(() => {
  //  getProducts(filter);
  if(props.type !== 'decor') {
    getParentProducts();
  }
   }, []);

   function getParentProducts() {
    setIsLoading(true);

    // console.log(props)

    let apiUrl = props.type && props.type == 'decor' ? "/admin/catalog/getSimpleProductList/" : "/admin/catalog/getParrentList/";

    AxiosInstance(PORTS.CATALOG_SERVICE, "applicaton/json")
    .get(apiUrl)
       .then((res) => {
         if (res.status === 200 && res.data.status === 200) {
          //  console.log(res.data.data);
           setProducts(res.data.data)
          }
        })
        .catch((er) => {
          console.log("getParentProducts error", er);
      })
      .finally(() => setIsLoading(false));
  }

  function onChange(value) {
    props.handleParentIdUpdate(value,props.id);
  }

  // function onSearch(val) {
  //   const filters = { ...filter, searchKey: val };
  //   setFilter(filters);
  //   debouncedSearch(filters);
  // }
// console.log(products)
  return (
    <Select
      showSearch
      placeholder="Search parent product"
      //   optionFilterProp="children"
      loading={props.isLoading}
      onChange={onChange}
      bordered={false}
      id={props.id}
      value={props.value}
      // onSearch={onSearch}
      className={`${commonstyles.form_control}  ${
        props.error && "theme-error-input"
      } form-control form-control-lg p-1`}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {/* <Option value=""></Option> */}
      {/* {props?.products?.length>0&&props.products.map((product) => (
        <Option value={product._id} key={product._id}>
          {product.name}
        </Option>
      ))} */}
      {props.type === 'decor'&&props?.products?.length>0&&props?.products.map((product) => (
        <Option value={product._id} key={product._id}>
          {product?.name?.toUpperCase()}
        </Option>
      ))}
      {props.type !== 'decor'&&products?.length>0&&products.map((product) => (
        <Option value={product._id} key={product._id}>
          {product?.name?.toUpperCase()}
        </Option>
      ))}
    </Select>
  );
}
