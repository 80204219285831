import React, { useState } from "react";
import styles from "../../../styles/Sidebar.module.css";
import { menus } from "./menus";
import FooterComponent from "./FooterComponent";
import { NavLink } from "react-router-dom";
import BrandLogo from "../../../assets/images/header/logo1.svg";
import arrowDown from "../../../assets/images/arrow_down.svg";
import arrowUp from "../../../assets/arrow_Up.svg";
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import {
  useHistory
} from "react-router-dom";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import { notification } from "antd";

export default function SidebarComponent() {
  const history = useHistory();

  const [sideMenu, setSideMenu] = useState(menus || []);
  
  const activateTab = (e, choosenMenu) => {
    const cloneSideMenu = [...sideMenu];
    cloneSideMenu.forEach(menu => {
      if(menu.id === choosenMenu.id) {
        menu.isActive = !menu.isActive
      }
    })
    setSideMenu(cloneSideMenu)
  };

  function buildSubLevelMenu(childMenus, subModule) {
    let newchildMenus = [];
    if (Array.isArray(childMenus) && childMenus.length > 0 && subModule.length > 0) {
      childMenus.forEach((menu) => {
        subModule.forEach(subMenu => {
          if (subMenu === menu.title) {
            newchildMenus.push(menu);
          }
        })
      });
      // console.log(newchildMenus)
      if (Array.isArray(newchildMenus)) {
        const subMenus = newchildMenus.map((menu) => {
            return (
              <NavLink
                to={menu.route}
                className={`list-group-item ${styles.list_group_sub_item} ${styles.ellipsis}`}
                key={menu.title}
                activeClassName={styles.menu_active}
              >
                {menu.title==="CancelledOrders"?"Cancelled Orders":menu.title}
              </NavLink>
            );
        });
        // console.log(subMenus);
        return (
          <ul
            className={` ${styles.list_group_flush} list-group list-group-flush `}
          >
            {subMenus}
          </ul>
        );
      }
    }
    return null;
  }

  const menuItems = sideMenu.map((menu) => {
    let permissionsArr = JSON.parse(localStorage.getItem("permissions")) || [];
    let allpermissions = ["Dashboard"];
    let allsubModule = [];
    permissionsArr.push('clearCache');
    if (permissionsArr?.length > 0) {
      permissionsArr?.forEach(item => {
        allpermissions.push(item?.split('-')[0]);
        allsubModule.push(item?.split('-')[1]);
      })
    }
    let permissions = [...new Set(allpermissions)];
    let subModule = [...new Set(allsubModule)];
    // console.log(permissions);
    const hasSubMenu = Array.isArray(menu.child);

    const slidestyle = {
      padding: "0",
      backgroundColor: "#fff",
      borderBottom: "none",

    };
    const SlideStyleCard = {
      width: "auto",
      border: "none",
    };
    const slidestylebtn = {
      padding: "0",
      width: "100%",
      textAlign: "inherit",
    };
    const slidesubmenu = {
      padding: "10px",
    };

    function showNotification({ type, message, description }) {
      let options = {
          message: message || "Message",
          description: description,
          duration: 5,
      };
      notification[type](options);
    }

    const clearCache = () => {
      AxiosInstance(PORTS.CATALOG_SERVICE, "application/json").get(`clearCache`).then((res) => {
        if (res.status === 200) {
          showNotification({
            type: "success",
            message: "Success",
            description: res.data,
          });
        }
      })
    }

    return hasSubMenu ? (
      permissions.length > 1 ? (
        permissions.map((subMenu, indx) => {
          if (subMenu === menu.id) {
            return (
              <div id="accordion">
                <Accordion>
                  <Card className="acc_card"
                    style={SlideStyleCard}
                  >
                    <Card.Header
                      className={styles.acc_card_header}
                      onClick={(e) => activateTab(e, menu)}
                      style={slidestyle}
                    >
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={indx}
                        style={slidestylebtn}
                      >
                        <li
                          className={`list-group-item list-group-item ${styles.list_group_item}`}
                          key={indx}
                        >
                          <img
                            src={menu.img}
                            alt={menu.imgAlt}
                            className={`${styles.sb_image}`}
                          />
                          <img
                            src={menu.imgActive}
                            alt={menu.imgAlt}
                            className={`${styles.sb_image_active}`}
                          />
                          {menu.title}
                          {menu.isActive ? (
                            <img
                              src={arrowUp}
                              alt="arrow"
                              className={`${styles.sb_arrow}`}
                            />
                          ) : (
                            <img
                              src={arrowDown}
                              alt="arrow"
                              className={`${styles.sb_arrow}`}
                            />
                          )}
                        </li>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={indx} className="">
                      <Card.Body className="" style={slidesubmenu}>
                        {buildSubLevelMenu(menu.child, subModule)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            );
          }
        })
      ) : (
        <div key={menu.title}>
          <li
            className={`list-group-item list-group-item ${styles.list_group_item}`}
            key={menu.title}
          >
            <img src={menu.img} alt={menu.imgAlt} />
            {menu.title}
          </li>
          {buildSubLevelMenu(menu.child, subModule)}
        </div>
      )
    ) : permissions.length > 1 ? (
      permissions.map((subMenu) => {
        if (subMenu === menu.id) {
          return (
            <NavLink
              // exact
              to={menu.id !== 'clearCache'&&menu.route}
              className={`list-group-item list-group-item ${styles.list_group_item}`}
              key={menu.title}
              activeClassName={styles.menu_active}
              onClick={menu.id === 'clearCache'&&clearCache}
            >
              <img
                src={menu.img}
                alt={menu.imgAlt}
                className={`${styles.sb_image}`}
              />
              <img
                src={menu.imgActive}
                alt={menu.imgAlt}
                className={`${styles.sb_image_active}`}
              />
              {menu.title}
            </NavLink>
          );
        }
      })
    ) : (
      <NavLink
        // exact
        to={menu.id !== 'clearCache'&&menu.route}
        className={`list-group-item list-group-item ${styles.list_group_item}`}
        key={menu.title}
        activeClassName={styles.menu_active}
        onClick={menu.id === 'clearCache'&&clearCache}
      >
        <img
          src={menu.img}
          alt={menu.imgAlt}
          className={`${styles.sb_image}`}
        />
        <img
          src={menu.imgActive}
          alt={menu.imgAlt}
          className={`${styles.sb_image_active}`}
        />
        {menu.title}
      </NavLink>
    );
  });

  const moveToHome = () => {
    history.push("/dashboard")
  }

  return (
    <div className={`col-lg-2 px-0 ${styles.management_sidebar}`} >
      <Accordion >
        <div className={` ${styles.logo}`}>
          <img src={BrandLogo} alt="logo" style={{cursor:"pointer"}} onClick={moveToHome}/>
        </div>
        <ul className={`list-group list-group-flush ${styles.list_group} ${styles.ellipsis}`} >
          {menuItems}
        </ul>
        <FooterComponent />
      </Accordion>
    </div>
  );
}
