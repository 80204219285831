import React, { useState, useEffect } from "react";
import styles from "../../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { DatePicker, notification, Select } from "antd";
import ButtonComponent from "../../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../../modules/ReusableComponent/InputComponent";
import SelectComponent from "../../../modules/ReusableComponent/SelectComponent";
import FormErrorText from "../../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../../styles/Management_Common.module.css";
import { TimePicker } from "antd";
import moment from "moment";
import WYSIWYGEditor from "../../../modules/ReusableComponent/WYSIWYGEditor";

const format = "h:mm a";

export default function EditSmsComponent(props) {
  const history = useHistory();
  const { state } = history.location;

  const route = () => {
    if(state?.prevLink === "/communications/emailogs") {
      history.push("/communications/emailogs");
    } else {
        history.push({
          pathname: `/communications/smstemplates`,
          state: props.selectedTab,
        });
    }
  }

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => route()}
          />
          {state?._id ? "Edit" : "Add"} SMS Information
        </h1>
        {/* <div className={styles.management_contents_upload}>
          <div>
            <label htmlFor="fileInput" className="cursor-pointer">
              <span className={styles.choose_file}>{props.isImporting? "Uploading...":"Import Template"}</span>
            </label>
            <input
              ref={props.inputFileElRef}
              type="file"
              id="fileInput"
              accept=".csv"
              className="d-none"
              value=""
              onInput={props.handleFileInput}
            />
          </div>
        </div> */}
      </div>
      {props.isLoading === true ? (
        <tr className="bg-transparent d-flex justify-content-center">
          <td colSpan="100%">
            <LoadingIndicator loadingText="Fetching email template details..." />
          </td>
        </tr>
      ) : (
        <div className={styles.management_details}>
          <div className={styles.management_details_contents}>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Select SMS Template
                </label>
                <Select
                  showSearch
                  dropdownMatchSelectWidth={false}
                  placeholder="Search SMS Template"
                  loading={props.isFetchingTemp}
                  bordered={false}
                  name="selectedTempId"
                  value={props.isInitialId&&props.selectedTempId}
                  onChange={(value) =>
                    props.searchSelectOnChange(value, "selectedTempId")
                  }
                  // onSearch={(value) =>
                  //   props.onSearchHandler(value, "selectedTempId")
                  // }
                  className={`${commonstyles.form_control} ${commonstyles.productlist_input} form-control form-control-lg p-1`}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.templates?.length > 0 &&
                    props.templates.map((template) => (
                      <option key={template._id} value={template._id}>
                        {template.templateName}
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Select User Group
                </label>
                <select
                  className={`${styles.email_template_select} form-control-lg br-10`}
                  name="selectedUserGrpID"
                  value={props.selectedUserGrpID}
                  onChange={props.filterOnChange}
                >
                  <option value={3}>All Users</option>
                  <option value={2}>New Registered</option>
                  {Array.isArray(props.userGroups)
                    ? props.userGroups.map((user) => (
                        <option key={user.id} value={user.value}>
                          {user.name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              {props.selectedUserGrpID === "0" && (
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Order Amount
                  </label>
                  <InputComponent
                    error={props.formErrors.telephone}
                    className="form-control-lg"
                    formControl={{
                      placeholder: "0",
                      value: props.orderValue,
                      name: "orderValue",
                      onChange: props.inputOnChange,
                    }}
                  />
                </div>
              )}
              {props.selectedUserGrpID === "1" && (
                <div className="col-lg-6 mb-4">
                  <label className={commonstyles.secondry_title}>
                    Product List
                  </label>
                  <Select
                    showSearch
                    dropdownMatchSelectWidth={false}
                    filterOption={false}
                    placeholder="Search Products"
                    loading={props.isFetchingTemp}
                    bordered={false}
                    name="selectedPrdId"
                    value={props.selectedPrdId}
                    onChange={(value) =>
                      props.searchSelectOnChange(value, "selectedPrdId")
                    }
                    onSearch={(value) =>
                      props.onSearchHandler(value, "selectedPrdId")
                    }
                    className={`${commonstyles.form_control} form-control form-control-lg p-1`}
                  >
                    {props.productList?.length > 0 &&
                      props.productList.map((prd) => (
                        <option key={prd.productId} value={prd.productId}>
                          {prd.productName}
                        </option>
                      ))}
                  </Select>
                </div>
              )}
              {((props.selectedUserGrpID.toString())!=='3') &&<div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Select Users
                <span className={`${commonstyles.asterik} text-danger`}>
                  *
                </span>
                </label>
                <Select
                  mode="multiple"
                  showSearch={false}
                  dropdownMatchSelectWidth={false}
                  filterOption={false}
                  placeholder="All User"
                  loading={props.isFetchingTemp}
                  bordered={false}
                  name="selectedUSers"
                  value={props.editForm.customersPhone}
                  onChange={(value) =>
                    props.searchSelectOnChange(value, "selectedUSers")
                  }
                  className={`${commonstyles.form_control} height-auto form-control form-control-lg p-0`}
                  style={{ 
                    border: props.formErrors.customersPhone? "#ff7575":"1px solid #d9d9d9",
                    boxShadow: props.formErrors.customersPhone?"0 0 3px 1px #ff7575":''
                  }} 
                >
                  {props.customerDetails?.length > 0 &&
                    props.customerDetails.map((user) => (
                      <option key={user?._id} value={user?._id}>
                        {user?.name}
                      </option>
                    ))}
                </Select>
                <FormErrorText error={props.formErrors.customersPhone} />
              </div>}
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Template Name
                  <span className={`${commonstyles.asterik} text-danger`}>
                  *
                </span>
                </label>
               
                <InputComponent
                  error={props.formErrors.templateName}
                  className="form-control-lg br-10"
                  formControl={{
                    placeholder: "Welcome SMS",
                    name: "templateName",
                    value: props.editForm?.templateName,
                    onChange: props.handleFormInput,
                  }}
                />
                <FormErrorText error={props.formErrors.templateName} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Start Date
                  <span className={`${commonstyles.asterik} text-danger`}>
                  *
                  </span>
                </label>
                <DatePicker
                  disabledDate={props.disabledDate}
                  value={
                    props.editForm?.startDate
                      ? moment(props.editForm?.startDate, "YYYY-MM-DD")
                      : ""
                  }
                  onChange={(dateString) =>
                    props.onChangeDateHandler(dateString, "startDate")
                  }
                  className="form-control-lg br-10 height-auto"
                  style={{ 
                    width: "100%",
                    border: props.formErrors.startDate? "#ff7575":"1px solid #d9d9d9",
                    boxShadow: props.formErrors.startDate?"0 0 3px 1px #ff7575":''
                  }} 
                />
                <FormErrorText error={props.formErrors.startDate} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Start Time
                  <span className={`${commonstyles.asterik} text-danger`}>
                  *
                </span>
                </label>
               
                <TimePicker
                  placeholder="01:00"
                  value={
                    props.editForm?.startTime 
                    && moment(props.editForm?.startTime, "h:mm a")
                  }
                  onChange={props.onChangeTimeHandler}
                  format={format}
                  className="form-control-lg br-10 height-auto"
                  style={{ 
                    width: "100%",
                    border: props.formErrors.startTime? "#ff7575":"1px solid #d9d9d9",
                    boxShadow: props.formErrors.startTime?"0 0 3px 1px #ff7575":''
                  }} 
                />
                <FormErrorText error={props.formErrors.startTime} />
              </div>
              <div className="col-lg-6 mb-4">
                <label className={commonstyles.secondry_title}>
                  Search SMS Keywords
                </label>
                <Select
                  showSearch
                  dropdownMatchSelectWidth={false}
                  filterOption={false}
                  placeholder="Search Email Template"
                  loading={props.isFetchingTemp}
                  bordered={false}
                  name="searchEamilKeyWords"
                  value=""
                  onChange={(value) =>
                    props.searchSelectOnChange(value, "searchEamilKeyWords")
                  }
                  onSearch={(value) =>
                    props.onSearchHandler(value, "searchEamilKeyWords")
                  }
                  className={`${commonstyles.form_control} form-control form-control-lg p-1`}
                >
                  {props.emailkeywordList?.length > 0 &&
                    props.emailkeywordList.map((template) => (
                      <option key={template._id} value={template.key}>
                        {template.key}
                        <button>Copy</button>
                      </option>
                    ))}
                </Select>
              </div>
              <div className="col-lg-12 mb-4">
                <label className={commonstyles.secondry_title}>
                  SMS Content
                  <span className={`${commonstyles.asterik} text-danger`}>
                  *
                </span>
                </label>
               
                {(!state?._id || (state?._id && props?.editForm?.message)) && (
                  <div className={`${commonstyles.management_details_form} ml-0 pr-0 pl-0`}>
                      <InputComponent
                        error={props.formErrors.message}
                        className="form-control-lg br-10"
                        formControl={{
                          placeholder: "Welcome SMS",
                          name: "message",
                          value: props?.editForm?.message ? props?.editForm?.message : "",
                          onChange: props.handleFormInput,
                        }}
                      />
                    <FormErrorText error={props.formErrors.message} />
                  </div>
                )}
              </div>
              <div className={`${styles.email_template_admin} col-lg-12 text-right pr-3 pl-3`}>
                <span className={styles.email_template_btn_span}>
                  <span>
                    <button
                      onClick={props.saveChanges}
                      className='theme-btn'
                    >
                      Save Changes
                    </button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}