import React, { useEffect, useState } from 'react'
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import styles from "../../../styles/PaymentNew.module.css";
import DesirableHandpicked from "./DesirableHandpicked"
import Trending from "./Trending"
import SEO from './SEO'
import Storelocator from './Storelocator'
import { useHistory } from "react-router";
import DesirableHandpickedbanner from './DesirableHandpickedbanner';
import ModularKitchen from './ModularKitchen';
import OurCollection from './OurCollection';
import SpecialCategoryExplore from './SpecialCategoryExplore';
import CategorySpaces from './CategorySpaces';
import SpecialOfferCategoryBanners from './SpecialOfferCategoryBanners';

const RegionWiseHomepage = () => {
  const history = useHistory();
  const { state, moduleName } = history.location;
  const [filterRegions, setFilterRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    getRegionList()
    return () => {
      history["isHomePage"] = true;
    };
  }, [])

  function getRegionList(idss) {
    setIsLoading(true);
    AxiosInstance(PORTS.REGION_SERVICE, "application/json")
      .get("/admin/region/getRegionList")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          const data = res.data.daaata;
          setFilterRegions(data)
        } else {
          setIsLoading(false);
        }
      })
      .catch((er) => {
        console.log(er);
        setIsLoading(false);
      });
  }


  return (
    <div className={styles.management_contents}>
      <div className={styles.management_details}>
        {moduleName === "Most Desirable" || moduleName === "Handpicked for you" ? (
          <DesirableHandpicked
            state={state}
            moduleName={moduleName}
            filterRegions={filterRegions}
          />
        ) : moduleName === "Trending" ? (
          <div>
            <Trending
              state={state}
              moduleName={moduleName}
              filterRegions={filterRegions}
            />
          </div>
        ) :moduleName==="Store Locator" ?(
            <div>
              <Storelocator
              state={state}
              moduleName={moduleName}
              filterRegions={filterRegions} 
             />
            </div>
        ) :moduleName==="Search Engine Optimization (SEO)" ?(
          <div>
            <SEO
            state={state}
            moduleName={moduleName}
            filterRegions={filterRegions} 
           />
          </div>
      ):
      
      // moduleName && moduleName.replace(/\s+/g, '').toLowerCase().includes("banner")?(
      moduleName ==="Special Offer Banner" ?(
        <DesirableHandpickedbanner
             state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
        />
     
      ):
      // special category explore
      moduleName ==="Special Category Explore Banner" ?(
        <SpecialCategoryExplore
             state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
        />
     
      ):
      moduleName ==="Special Offer Category Banners" ?(
        <SpecialOfferCategoryBanners
             state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
        />
     
      )
      :moduleName && moduleName.replace(/\s+/g, '').toLowerCase().includes("kitchen")?(
       <ModularKitchen
        state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
      />
      )
      :moduleName == "ourCollection" ?(
        <OurCollection
        state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
      />
      ):
      moduleName == "Category Spaces" ?(
        <CategorySpaces
        state={state}
        moduleName={moduleName}
        filterRegions={filterRegions}
      />
      ):
      
      (
          <div>
           {history.push('/content')};
          </div>
        )}

      </div>
    </div>
  );
}

export default RegionWiseHomepage